import axios from '../../config/axios';

const apiARGetProvince = async () => {
  try {
    const result = await axios.get('/province/dropdown')
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

export {
  apiARGetProvince
}