import React, { useState, useEffect } from 'react'
import { Table } from 'antd';
import '../../Table.css'
// import XLSX from "xlsx";

// import moment from 'moment';
// import 'moment/locale/th'

import { apiDBGetShareBySiteId } from '../../../../services/apis/dashboard'
import { numberWithCommas } from '../../../../utils/function'

const TableShare = props => {
  const [isLoading, setIsLoading] = useState(false)
  const [tableData, setTableData] = useState([])

  useEffect(() => {
    setIsLoading(true)
    const getTableData = async () => {
      let data = await apiDBGetShareBySiteId(props.startDate, props.endDate, props.siteId)
      if (data && data.status) {
        setIsLoading(false)
        setTableData(data.result || [])
      }
    }
    getTableData()
  }, [props.searchButton])

  const columns = [
    {
      title: '#',
      ellipsis: true,
      align: 'center',
      width: '5%',
      render: (data, record, index) => <div>{index + 1}</div>
    },
    {
      title: 'รายการ',
      ellipsis: true,
      render: (data) => <div>{data.name || '-'}</div>
    },
    {
      title: 'จำนวน ( บาท )',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{numberWithCommas(data.value || 0, 2)}</div>
    },
  ];

  // const toCSVFile = async () => {
  //   let csvData = [];

  //   tableData.map((data, idx) => {
  //     let resultRow = {}
  //     resultRow['ช่วงเวลาที่เลือก'] = `${moment(props.startDate).add(543, 'years').format('D MMM YYYY')} - ${moment(props.endDate).add(543, 'years').format('D MMM YYYY')}`
  //     resultRow['#'] = idx + 1
  //     resultRow['รายการ'] = data.name || '-'
  //     resultRow['จำนวน ( บาท )'] = data.value || 0
  //     csvData.push(resultRow);
  //   })

  //   const toCSV = XLSX.utils.json_to_sheet(csvData);
  //   const wb = XLSX.utils.book_new();

  //   const fileName = 'SHARE'
  //   XLSX.utils.book_append_sheet(wb, toCSV, fileName);
  //   XLSX.writeFile(wb, fileName + ".csv");
  // }

  return (
    <div className="dash-chart-box-container">
      <div className="dash-space-between">
        <div className="h-d-t-g">
          <div className="f-c-c"><i className="bi bi-journal-text" style={{ lineHeight: '0' }}></i></div>
          <div className="f-s-c">ส่วนแบ่งรายได้</div>
          {/* <div className="f-c-c d-b-t-n-s d-bg-c-t table-export-btn-width table-export-btn-responsive" onClick={() => toCSVFile()}>Export CSV</div> */}
        </div>

        <div className="dash-table-container">
          <Table bordered
            size="small"
            rowKey={record => record.id}
            loading={isLoading}
            dataSource={tableData}
            columns={columns}
            pagination={false}
            scroll={{ x: true }}
          />
        </div>

      </div>
    </div>
  )
}

export default TableShare
