import axios from '../../config/axios';

const apiWGetNews = async (get_all, cur_page, per_page, keyword) => {
  try {
    const result = await axios.get(`/washer/news/list?get_all=${get_all}&cur_page=${cur_page}&per_page=${per_page}&keyword=${keyword}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiWAddNews = async (data) => {
  try {
    const result = await axios.post('/washer/news', data)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiWGetNewsById = async (news_id) => {
  try {
    const result = await axios.get(`/washer/news?news_id=${news_id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiWEditNewsById = async (data) => {
  try {
    const result = await axios.put('/washer/news', data)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiWDeleteNewsById = async (news_id) => {
  try {
    const result = await axios.delete(`/washer/news?news_id=${news_id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

export {
  apiWGetNews,
  apiWAddNews,
  apiWGetNewsById,
  apiWEditNewsById,
  apiWDeleteNewsById,
}