import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { Input, Table, Pagination, Tooltip, Modal, Avatar } from "antd";
import "./AdminManagement.css";
import XLSX from "xlsx";
import { LoadingOutlined } from "@ant-design/icons";

import { openNotification, getSearchParams } from "../../utils/function";
import {
  apiAMGetAdminList,
  apiAMDeleteAdminById,
} from "../../services/apis/admin";

const AdminList = () => {
  const location = useLocation();
  const history = useHistory();
  const { siteId } = useParams();

  const params = new URLSearchParams(location.search);
  const p = params.get("p");
  const ttl = params.get("ttl");
  const sw = params.get("sw");

  const menuList = (localStorage.getItem("list") || "").split(",");
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(Number(p) || 1);
  const [perPage, setPerPage] = useState(Number(ttl) || 20);
  const [tableData, setTableData] = useState(undefined);
  const [searchWord, setSearchWord] = useState(sw || "");
  const [searchButton, setSearchButton] = useState(false);
  const [waitingForCSV, setWaitingForCSV] = useState(false);

  const [visible, setVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalId, setModalId] = useState(undefined);
  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false);

  useEffect(() => {
    getTableData();
    history.push(
      `${location.pathname}${getSearchParams(
        ["p", "ttl", "sw"],
        [currentPage, perPage, searchWord]
      )}`
    );
  }, [currentPage, perPage, searchButton]);

  const getTableData = async () => {
    setIsLoading(true);
    let controller = await apiAMGetAdminList(
      siteId ? true : false,
      currentPage,
      perPage,
      searchWord
    );
    if (controller && controller.status) {
      setIsLoading(false);
      if (siteId) {
        let newData = [];
        const _data = { ...controller?.result };
        if (controller?.result?.data_list) {
          controller.result.data_list.map((list) => {
            if (list?.admin_branch == siteId) {
              newData.push(list);
            }
          });
        }
        setTableData({
          cur_page: _data?.cur_page,
          max_total_rows: _data?.max_total_rows,
          data_list: newData,
          per_page: _data?.per_page,
        });
      } else {
        setTableData(controller.result);
      }
    }
  };

  const columns = [
    {
      title: "#",
      ellipsis: true,
      align: "center",
      width: "5%",
      render: (data, record, index) => (
        <div>{index + 1 + (currentPage - 1) * perPage}</div>
      ),
    },
    {
      title: "Name",
      ellipsis: true,
      render: (data) => <div>{data.admin_name || "-"}</div>,
    },
    {
      title: "Username",
      ellipsis: true,
      render: (data) => <div>{data.admin_user || "-"}</div>,
    },
    {
      title: "E-mail",
      ellipsis: true,
      render: (data) => <div>{data.admin_email || "-"}</div>,
    },
    {
      title: "Phone",
      ellipsis: true,
      render: (data) => <div>{data.admin_tel || "-"}</div>,
    },
    {
      title: "สาขา",
      ellipsis: true,
      render: (data) => <div>{data.admin_branch || "-"}</div>,
    },
    {
      title: "Manage",
      ellipsis: true,
      align: "center",
      fixed: "right",
      render: (data) =>
        menuList.includes("ADMINMAN") ? (
          <div className="f-c-c tb-cl-a">
            <Tooltip placement="top" title="Edit">
              <div
                className="f-c-c d-b-t-n-s b-c-o-i"
                style={{ backgroundColor: "#00ADEF" }}
                onClick={(e) => {
                  e.stopPropagation();
                  history.push(
                    `/admin-management/list-admin/edit/${
                      data.admin_id
                    }${getSearchParams(
                      ["p", "ttl", "sw"],
                      [currentPage, perPage, searchWord]
                    )}`
                  );
                }}
              >
                <i className="bi bi-pencil-fill"></i>
              </div>
            </Tooltip>
            <Tooltip placement="top" title="Delete">
              <div
                className="f-c-c d-b-t-n-s b-c-o-i"
                style={{ backgroundColor: "#ED2228" }}
                onClick={(e) => {
                  e.stopPropagation();
                  setModalId(data.admin_id);
                  setModalTitle(data.admin_name);
                  setVisible(true);
                }}
              >
                <i className="fas fa-trash"></i>
              </div>
            </Tooltip>
          </div>
        ) : (
          <div>-</div>
        ),
    },
  ];

  const deleteData = async () => {
    if (!modalId) {
      openNotification("error", "Failed !", "Failed to delete this admin.");
      return;
    }

    setIsLoadingConfirm(true);
    let action = await apiAMDeleteAdminById(modalId);
    if (action && action.status) {
      openNotification("success", "Success !", "Delete this admin successful.");
      setVisible(false);
      setIsLoadingConfirm(false);
      getTableData();
    } else {
      openNotification("error", "Failed !", "Failed to delete this admin.");
      setIsLoadingConfirm(false);
    }
  };

  const toCSVFile = async () => {
    setWaitingForCSV(true);
    let data = await apiAMGetAdminList(true, "", "", searchWord);
    let csvData = [];

    if (data && data.status) {
      (data.result.data_list || []).map((data, idx) => {
        let resultRow = {};
        resultRow["#"] = idx + 1;
        resultRow["Name"] = data.admin_name || "-";
        resultRow["Username"] = data.admin_user || "-";
        resultRow["E-mail"] = data.admin_email || "-";
        resultRow["Phone"] = data.admin_tel || "-";
        resultRow["สาขา"] = data.admin_branch || "-";
        csvData.push(resultRow);
      });

      const toCSV = XLSX.utils.json_to_sheet(csvData);
      const wb = XLSX.utils.book_new();

      const fileName = "ADMIN_LIST";
      XLSX.utils.book_append_sheet(wb, toCSV, fileName);
      XLSX.writeFile(wb, fileName + ".csv");

      setWaitingForCSV(false);
    }
  };

  return (
    <div>
      <div className="mg-bt-20 h-d-t-p">Admin List</div>

      <div
        className={`pro-reward-header-grid pro-reward-header-grid-column-${!menuList.includes(
          "ADMINMAN"
        )}`}
      >
        <div
          className={`pro-reward-header-grid-search-${!menuList.includes(
            "ADMINMAN"
          )}`}
        >
          <Input
            placeholder="search .."
            value={searchWord}
            onChange={(e) => setSearchWord(e.target.value)}
            onPressEnter={() => {
              setCurrentPage(1);
              setSearchButton(!searchButton);
            }}
          />
        </div>
        <div
          className="f-c-c d-b-t-n-s d-bg-c-t"
          onClick={() => {
            setCurrentPage(1);
            setSearchButton(!searchButton);
          }}
        >
          <i className="bi bi-search" style={{ lineHeight: "0" }}></i>
        </div>
        {menuList.includes("ADMINMAN") && (
          <Link to="/admin-management/add-admin">
            <div
              className="f-c-c d-b-t-n-s d-bg-c-t h-32"
              style={{ height: "100%" }}
            >
              + Add
            </div>
          </Link>
        )}
        <div
          className={`f-c-c d-b-t-n-s d-bg-c-t h-32 pro-reward-header-grid-export-${!menuList.includes(
            "ADMINMAN"
          )}`}
          style={{ pointerEvents: waitingForCSV ? "none" : null }}
          onClick={() => toCSVFile()}
        >
          {waitingForCSV ? <LoadingOutlined /> : "Export CSV"}
        </div>
      </div>

      <Table
        bordered
        size="small"
        rowKey={(record) => record.admin_id}
        loading={isLoading}
        dataSource={(tableData && tableData.data_list) || []}
        columns={columns}
        pagination={false}
        scroll={{ x: true }}
      />

      <div className="pgnt-st">
        <Pagination
          size="small"
          current={currentPage}
          onChange={(page) => setCurrentPage(page)}
          total={tableData && tableData.max_total_rows}
          pageSize={perPage}
          showSizeChanger={false}
        />
      </div>

      <Modal
        title=""
        visible={visible}
        closable={false}
        footer={false}
        centered
      >
        <div className="m-t-i-c" style={{ color: "#FFCB05" }}>
          <i className="bi bi-exclamation-triangle-fill"></i>
        </div>
        <div className="m-t-t-q">Do you want to delete '{modalTitle}' ?</div>
        <div className="m-b-y-n-g">
          <div
            className="f-c-c d-b-t-n-s b-c-o-i"
            style={{ backgroundColor: "#cccccc" }}
            onClick={() => setVisible(false)}
          >
            Cancel
          </div>
          <div
            className="f-c-c d-b-t-n-s b-c-o-i"
            style={{
              backgroundColor: "#3f4d67",
              pointerEvents: isLoadingConfirm ? "none" : null,
            }}
            onClick={() => deleteData()}
          >
            {isLoadingConfirm ? <LoadingOutlined /> : "Confirm"}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AdminList;
