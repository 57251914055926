import React, { useState, useEffect } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { DatePicker, Table, Input, Pagination } from 'antd';
import './Transaction.css'
import XLSX from "xlsx";
import { LoadingOutlined } from '@ant-design/icons';

import moment from 'moment';
import 'moment/locale/th'

import { numberWithCommas, getSearchParams } from '../../utils/function'
import { apiWTRSTGetTransaction } from '../../services/apis/washerTransaction'

const { RangePicker } = DatePicker;

const Transaction = () => {
  const { siteId } = useParams();
  const history = useHistory();
  const location = useLocation();

  const params = new URLSearchParams(location.search)
  const p = params.get('p')
  const ttl = params.get('ttl')
  const start = params.get('start')
  const end = params.get('end')
  const sw = params.get('sw')

  const menuList = (localStorage.getItem('list') || '').split(',')
  const role = (localStorage.getItem('user_role') || '').toUpperCase()

  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(Number(p) || 1)
  const [perPage, setPerPage] = useState(Number(ttl) || 20)
  const [startDate, setStartDate] = useState(start ? moment(start).startOf('day').format('YYYY-MM-DD HH:mm:ss') : moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'))
  const [endDate, setEndDate] = useState(end ? moment(end).endOf('day').format('YYYY-MM-DD HH:mm:ss') : moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'))
  const [tableData, setTableData] = useState(undefined)
  const [searchWord, setSearchWord] = useState(sw || '')
  const [searchButton, setSearchButton] = useState(false)
  const [waitingForCSV, setWaitingForCSV] = useState(false)

  const [sortData, setSortData] = useState({ machine: undefined, date: undefined }) // ascend, descend

  useEffect(() => {
    getTableData()
    history.push(`${location.pathname}${getSearchParams(['p', 'ttl', 'start', 'end', 'sw'], [currentPage, perPage, startDate, endDate, searchWord])}`)
  }, [currentPage, perPage, searchButton])

  const getTableData = async () => {
    setIsLoading(true)
    let report = await apiWTRSTGetTransaction(false, startDate, endDate, currentPage, perPage, searchWord)
    console.log(report)
    if (report && report.status) {
      setIsLoading(false)
      setTableData(report.result)
    }
  }

  const columns = [
    {
      title: '#',
      ellipsis: true,
      align: 'center',
      width: '5%',
      render: (data, record, index) => <div>{(index + 1) + ((currentPage - 1) * perPage)}</div>
    },
    {
      title: 'Bag Size',
      ellipsis: true,
      render: (data) => <div>{data.bag_size || '-'}</div>,
    //   key: 'bagsize',
    //   sorter: true,
    //   sortOrder: sortData.bagsize,
    },
    {
      title: 'Bag Name',
      ellipsis: true,
      render: (data) => <div>{data.bag_name || '-'}</div>
    },
    {
      title: 'Customer',
      ellipsis: true,
      render: (data) => <div>{data.customer_name || '-'}</div>
    },
    {
        title: 'Site',
        ellipsis: true,

        render: (data) => <div>{data.site_name || '-'}</div>
      },
    {
      title: 'ราคา',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{numberWithCommas(data.price || 0, 2)}</div>
    },
    {
        title: 'Trendy Maid',
        ellipsis: true,

        render: (data) => <div>{data.washer_name || '-'}</div>
      },
      {
        title: 'Status',
        ellipsis: true,

        render: (data) => <div>{data.status_name || '-'}</div>
      },
    {
      title: 'วันที่',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{data.create_date ? moment(data.create_date).add(543, 'years').format('D MMM YYYY HH:mm:ss') : '-'}</div>,
      key: 'date',
      sorter: true,
      sortOrder: sortData.date,
    },
  ];

  const toCSVFile = async () => {
    setWaitingForCSV(true)
    let data = await apiWTRSTGetTransaction(false, startDate, endDate, currentPage, perPage, searchWord) // only super admin
    let csvData = [];

    if (data && data.status) {
      (data.result.data_list || []).map((data, idx) => {
        let resultRow = {}
        resultRow['ช่วงเวลาที่เลือก'] = `${moment(startDate).add(543, 'years').format('D MMM YYYY')} - ${moment(endDate).add(543, 'years').format('D MMM YYYY')}`
        resultRow['#'] = idx + 1
        resultRow['เครื่อง'] = data.machine_name || '-'
        resultRow['ลูกค้า'] = data.customer_name || '-'
        resultRow['ชำระโดย'] = data.action_by || '-'
        resultRow['ราคา'] = data.price || 0
        resultRow['วันที่'] = data.start_time || '-'
        csvData.push(resultRow);
      })

      const toCSV = XLSX.utils.json_to_sheet(csvData);
      const wb = XLSX.utils.book_new();

      const fileName = 'SITE_REPORT'
      XLSX.utils.book_append_sheet(wb, toCSV, fileName);
      XLSX.writeFile(wb, fileName + ".csv");

      setWaitingForCSV(false)
    }
  }

  const sortDataTable = (pagination, filters, sorter) => {
    setSortData({ ...sortData, [sorter.columnKey]: sorter.order })
  }

  return (
    <div>
      <div className="mg-bt-20 h-d-t-p">Transaction</div>

      <div className={`report-container-grid report-container-grid-${role.toLowerCase()}-${!menuList.includes('EXPORT')}`}>
        <div className={`report-container-grid-${role.toLowerCase()}-${!menuList.includes('EXPORT')}-date`}>
          <RangePicker
            style={{ width: '100%' }}
            format={date => moment(date).add(543, 'years').format('D MMM YYYY HH:mm')}
            allowClear={false}
            showTime={{ format: 'HH:mm' }}
            value={[moment(startDate, 'YYYY-MM-DD HH:mm:ss'), moment(endDate, 'YYYY-MM-DD HH:mm:ss')]}
            onChange={date => {
              setStartDate(moment(date[0]).startOf('minute').format('YYYY-MM-DD HH:mm:ss'))
              setEndDate(moment(date[1]).endOf('minute').format('YYYY-MM-DD HH:mm:ss'))
            }}
            disabledDate={current => role === 'BRANCHADMIN' ? current && current < moment('2021-06-25').startOf('day') : null}
          />
        </div>
        <div>
          <Input
            placeholder="search .."
            value={searchWord}
            onChange={e => setSearchWord(e.target.value)}
            onPressEnter={() => {
              setCurrentPage(1)
              setSearchButton(!searchButton)
            }}
          />
        </div>
        <div className="f-c-c d-b-t-n-s d-bg-c-t dash-select-date-search"
          onClick={() => setSearchButton(!searchButton)}>
          <i className="bi bi-search" style={{ lineHeight: '0' }}></i>
        </div>
        {(role === 'SUPERADMIN' || menuList.includes('EXPORT')) && <div className={`f-c-c d-b-t-n-s d-bg-c-t h-32 report-container-grid-${role.toLowerCase()}-${!menuList.includes('EXPORT')}-export`}
          style={{ pointerEvents: waitingForCSV ? 'none' : null }}
          onClick={() => toCSVFile()}
        >
          {waitingForCSV ? <LoadingOutlined /> : 'Export CSV'}
        </div>}
      </div>

      <Table bordered
        size="small"
        loading={isLoading}
        rowKey={record => record.history_id}
        dataSource={tableData && tableData.data_list || []}
        columns={columns}
        pagination={false}
        scroll={{ x: true }}
        onChange={(pagination, filters, sorter) => sortDataTable(pagination, filters, sorter)}
      />

      <div className="pgnt-st">
        <Pagination
          size="small"
          current={currentPage}
          onChange={page => setCurrentPage(page)}
          total={tableData && tableData.max_total_rows}
          pageSize={perPage}
          showSizeChanger={false}
        />
      </div>

    </div>
  )
}

export default Transaction
