import axios from '../../config/axios';

const apiWGetBag = async (get_all, cur_page, per_page, keyword) => {
  try {
    const result = await axios.get(`/washer/bag/list?get_all=${get_all}&cur_page=${cur_page}&per_page=${per_page}&keyword=${keyword}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}


const apiSetCusBag = async (bag_id, customer_id) => {
    try {
      const result = await axios.put(`/washer/bag/set_user?bag_id=${bag_id}&customer_id=${customer_id}`)
      return result && result.data
    } catch (error) {
      return error && error.response && error.response.data
    }
  }



export {
    apiWGetBag,
    apiSetCusBag
}