import axios from '../../config/axios';

const apiWTRSTGetTransaction = async (get_all, start_date, end_date, cur_page, per_page, keyword) => {
  try {
    const result = await axios.get(`/washer/transaction/list?get_all=${get_all}&start_date=${start_date}&end_date=${end_date}&cur_page=${cur_page}&per_page=${per_page}&keyword=${keyword}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

export {
  apiWTRSTGetTransaction
}