import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import { DatePicker } from 'antd';
import '../Dashboard.css'

import moment from 'moment';
import 'moment/locale/th'

import { getSearchParams } from '../../../utils/function'
import { apiDBGetReportSuperAdmin } from '../../../services/apis/dashboard'

import Report from './Report'
import ChartColumn from '../../../components/Tools/Chart/ChartColumn'
import ChartColumnStack from '../../../components/Tools/Chart/ChartColumnStack'
import ChartPie from '../../../components/Tools/Chart/ChartPie'
import TableNewUser from './Table/TableNewUser'
import TableActiveUsers from './Table/TableActiveUsers'
import TableMaximumUsage from './Table/TableMaximumUsage'
import TableInactiveUsers from './Table/TableInactiveUsers'
import TableMachineAverage from './Table/TableMachineAverage'

const { RangePicker } = DatePicker;

const DashboardSuperAdmin = () => {
  const history = useHistory();
  const location = useLocation();

  const params = new URLSearchParams(location.search)
  const start = params.get('start')
  const end = params.get('end')

  const [isLoading, setIsLoading] = useState(false)
  const [startDate, setStartDate] = useState(start ? moment(start).startOf('day').format('YYYY-MM-DD HH:mm:ss') : moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'))
  const [endDate, setEndDate] = useState(end ? moment(end).endOf('day').format('YYYY-MM-DD HH:mm:ss') : moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'))
  const [report, setReport] = useState(undefined)
  const [chartReport, setChartReport] = useState(undefined)
  const [searchButton, setSearchButton] = useState(false)

  useEffect(() => {
    setReport(undefined)
    setChartReport(undefined)
    getReport()
    history.push(`${location.pathname}${getSearchParams(['start', 'end'], [startDate, endDate])}`)
  }, [searchButton])

  const getReport = async () => {
    setIsLoading(true)
    let report = await apiDBGetReportSuperAdmin(startDate, endDate)
    if (report && report.status) {
      setIsLoading(false)
      console.log(report.result.summary)
      console.log(report.result.graph)
      setReport(report.result.summary)
      setChartReport(report.result.graph)
    }
  }

  return (
    <div>
      <div className="mg-bt-20 h-d-t-p">Dashboard - รายได้ / การใช้งาน</div>

      <div className="dash-select-date-container-grid">
        <div className="f-c-c">เลือกวันที่ :</div>
        <div>
          <RangePicker
            style={{ width: '100%' }}
            format={date => moment(date)
              //.add(543, 'years')
              .format('D MMM YYYY HH:mm')}
            allowClear={false}
            showTime={{ format: 'HH:mm' }}
            value={[moment(startDate, 'YYYY-MM-DD HH:mm:ss'), moment(endDate, 'YYYY-MM-DD HH:mm:ss')]}
            onChange={date => {
              setStartDate(moment(date[0]).startOf('minute').format('YYYY-MM-DD HH:mm:ss'))
              setEndDate(moment(date[1]).endOf('minute').format('YYYY-MM-DD HH:mm:ss'))
            }}
          />
        </div>
        <div className="f-c-c d-b-t-n-s d-bg-c-t dash-select-date-search"
          onClick={() => setSearchButton(!searchButton)}>
          <i className="bi bi-search" style={{ lineHeight: '0' }}></i>
        </div>
      </div>

      <Report report={report} isLoading={isLoading} />

      <div className="dash-duo-chart-grid">
        <ChartColumn
          isLoading={isLoading}
          chartReport={chartReport && chartReport.graph_users || []}
          chartTitle='* กราฟแสดงข้อมูลผู้ใช้งาน'
          nameSeries='จำนวนผู้ใช้งาน'
          dataLabels={true}
          valueData='value'
          tooltipEnabled={false}
        />
        <ChartPie
          isLoading={isLoading}
          chartReport={chartReport && chartReport.graph_percent_payment_bath || []}
          chartTitle='กราฟแสดงเปอร์เซนต์ประเภทการจ่าย ( บาท )'
        />
      </div>

      <ChartColumn
        isLoading={isLoading}
        chartReport={chartReport && chartReport.graph_usage_last_year || []}
        chartTitle='กราฟแสดงการใช้งาน 1 ปีย้อนหลัง'
        nameSeries='จำนวนผู้ใช้งาน'
        dataLabels={true}
        valueData='value'
        tooltipEnabled={false}
      />

      <ChartColumnStack
        isLoading={isLoading}
        chartReport={chartReport && chartReport.graph_usage || []}
        chartTitle='กราฟแสดงการใช้งาน'
        dataLabels={false}
        stackLabels={false}
        tooltipEnabled={true}
        digits={2}
      />

      <ChartColumn
        isLoading={isLoading}
        chartReport={chartReport && chartReport.graph_usage_last_month_bath || []}
        chartTitle='กราฟแสดงการใช้งานแบ่งตามวัน 1 เดือนย้อนหลัง (บาท)'
        nameSeries='จำนวนเงิน'
        dataLabels={true}
        valueData='value'
        tooltipEnabled={false}
      />

      <ChartColumn
        isLoading={isLoading}
        chartReport={chartReport && chartReport.graph_usage_last_month_count || []}
        chartTitle='กราฟแสดงการใช้งานแบ่งตามวัน 1 เดือนย้อนหลัง (ครั้ง)'
        nameSeries='จำนวนครั้ง'
        dataLabels={true}
        valueData='value'
        tooltipEnabled={false}
      />

      <ChartColumn
        isLoading={isLoading}
        chartReport={chartReport && chartReport.graph_percent_each_use || []}
        chartTitle='กราฟแสดงเปอร์เซนต์การใช้แต่ละสถานที่'
        nameSeries='%'
        dataLabels={false}
        valueData='percent'
        tooltipEnabled={true}
      />

      <TableNewUser startDate={startDate} endDate={endDate} searchButton={searchButton} />

      <TableMaximumUsage startDate={startDate} endDate={endDate} searchButton={searchButton} />

      <TableActiveUsers />

      <TableInactiveUsers />

      <TableMachineAverage startDate={startDate} endDate={endDate} searchButton={searchButton} />

    </div>
  )
}

export default DashboardSuperAdmin
