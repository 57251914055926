import React, { useState, useEffect } from 'react'
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { Input, InputNumber, Select, DatePicker, TimePicker, Checkbox, Avatar } from 'antd';
import './BannerWasher.css'
import { LoadingOutlined } from '@ant-design/icons';

import moment from 'moment';
import 'moment/locale/th'

import { checkImageSize, openNotification, checkReqFieldWithId, getSearchParams, checkIfIdEqualToZero } from '../../utils/function'
import { apiSGetSite } from '../../services/apis/site'
import { apiBWAddBanner, apiBWGetBannerById, apiBWEditBannerById } from '../../services/apis/washerBanner';

const { Option } = Select;
const { RangePicker } = DatePicker;

const BannerAddEditWasher = () => {
  const { bannerType, bannerId } = useParams();
  const location = useLocation();
  const history = useHistory();

  const params = new URLSearchParams(location.search)
  const p = params.get('p')
  const ttl = params.get('ttl')
  const s = params.get('s')
  const sw = params.get('sw')

  const [isLoading, setIsLoading] = useState(false)
  const [sites, setSites] = useState([])
  const [banner, setBanner] = useState(undefined)

  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  const [isAlertTitle, setIsAlertTitle] = useState(false)
  const [isAlertImage, setIsAlertImage] = useState(false)
  const [isAlertLink, setIsAlertLink] = useState(false)
  const [isAlertSiteName, setIsAlertSiteName] = useState(false)
  const [isAlertPeriodPromotion, setIsAlertPeriodPromotion] = useState(false)
  const [isAlertOrder, setIsAlertOrder] = useState(false)

  useEffect(() => {
    if (bannerId) getData()
    else setBanner({
      ...banner, period_time: [
        {
          start_time: '00:00:00',
          end_time: '00:00:00'
        },
        {
          start_time: '00:00:00',
          end_time: '00:00:00'
        },
        {
          start_time: '00:00:00',
          end_time: '00:00:00'
        },
        {
          start_time: '00:00:00',
          end_time: '00:00:00'
        },
        {
          start_time: '00:00:00',
          end_time: '00:00:00'
        },
        {
          start_time: '00:00:00',
          end_time: '00:00:00'
        },
        {
          start_time: '00:00:00',
          end_time: '00:00:00'
        },
      ]
    })
  }, [bannerId])

  const getData = async () => {
    let data = await apiBWGetBannerById(bannerId)
    if (data && data.status) {
      let banner = { ...data.result }
      let link = data.result.banner_link || ''

      if (link.substring(0, 7) === 'http://') banner.banner_link = link.substring(7)
      else if (link.substring(0, 8) === 'https://') banner.banner_link = link.substring(8)

      banner.period_banner = {}
      banner.period_banner.start_date = data.result.banner_startdate_datetime
      banner.period_banner.end_date = data.result.banner_enddate_datetime
      banner.period_time = [
        {
          start_time: data.result.sunday_start_time,
          end_time: data.result.sunday_end_time
        },
        {
          start_time: data.result.monday_start_time,
          end_time: data.result.monday_end_time
        },
        {
          start_time: data.result.tuesday_start_time,
          end_time: data.result.tuesday_end_time
        },
        {
          start_time: data.result.wednesday_start_time,
          end_time: data.result.wednesday_end_time
        },
        {
          start_time: data.result.thursday_start_time,
          end_time: data.result.thursday_end_time
        },
        {
          start_time: data.result.friday_start_time,
          end_time: data.result.friday_end_time
        },
        {
          start_time: data.result.saturday_start_time,
          end_time: data.result.saturday_end_time
        },
      ]
      setBanner(banner)
    }
  }

  useEffect(() => {
    if (bannerType !== 'home' && bannerType !== 'site') history.push('/banner/home')

    const getStaticDropdown = async () => {
      let site = await apiSGetSite()
      if (site && site.status) setSites(site.result || [])
    }
    getStaticDropdown()
  }, [])

  const addEditData = async () => {
    let passIMF = checkReqFieldWithId(bannerId, banner && banner.image_file)

    if (
      !(banner && banner.banner_title) ||
      !passIMF ||
      !(banner && banner.banner_link) ||
      !(banner && banner.period_banner) ||
      (bannerType === 'site' && !(checkIfIdEqualToZero(banner && banner.site_id))) ||
      (bannerId && isNaN(banner && banner.order_index))
    ) {
      if (!(banner && banner.banner_title)) setIsAlertTitle(true)
      if (!passIMF) setIsAlertImage(true)
      if (!(banner && banner.banner_link)) setIsAlertLink(true)
      if (!(banner && banner.period_banner)) setIsAlertPeriodPromotion(true)
      if (bannerType === 'site' && !(checkIfIdEqualToZero(banner && banner.site_id))) setIsAlertSiteName(true)
      if (bannerId && isNaN(banner && banner.order_index)) setIsAlertOrder(true)

    } else {
      setIsLoading(true)

      let data = new FormData()
      if (bannerId) data.append('banner_id', bannerId)
      data.append('banner_title', banner.banner_title)
      data.append('banner_desciption', banner.banner_desciption || '')
      if (banner.image_file) data.append('banner_img', banner.image_file)
      data.append('banner_link', `https://${banner.banner_link}`)
      data.append('site_id', banner.site_id)
      data.append('banner_startdate_datetime', banner.period_banner.start_date)
      data.append('banner_enddate_datetime', banner.period_banner.end_date)
      data.append('sunday_start_time', banner.period_time[0].all_day ? '00:00:00' : banner.period_time[0].start_time)
      data.append('sunday_end_time', banner.period_time[0].all_day ? '23:59:59' : banner.period_time[0].end_time)
      data.append('monday_start_time', banner.period_time[1].all_day ? '00:00:00' : banner.period_time[1].start_time)
      data.append('monday_end_time', banner.period_time[1].all_day ? '23:59:59' : banner.period_time[1].end_time)
      data.append('tuesday_start_time', banner.period_time[2].all_day ? '00:00:00' : banner.period_time[2].start_time)
      data.append('tuesday_end_time', banner.period_time[2].all_day ? '23:59:59' : banner.period_time[2].end_time)
      data.append('wednesday_start_time', banner.period_time[3].all_day ? '00:00:00' : banner.period_time[3].start_time)
      data.append('wednesday_end_time', banner.period_time[3].all_day ? '23:59:59' : banner.period_time[3].end_time)
      data.append('thursday_start_time', banner.period_time[4].all_day ? '00:00:00' : banner.period_time[4].start_time)
      data.append('thursday_end_time', banner.period_time[4].all_day ? '23:59:59' : banner.period_time[4].end_time)
      data.append('friday_start_time', banner.period_time[5].all_day ? '00:00:00' : banner.period_time[5].start_time)
      data.append('friday_end_time', banner.period_time[5].all_day ? '23:59:59' : banner.period_time[5].end_time)
      data.append('saturday_start_time', banner.period_time[6].all_day ? '00:00:00' : banner.period_time[6].start_time)
      data.append('saturday_end_time', banner.period_time[6].all_day ? '23:59:59' : banner.period_time[6].end_time)
      if (bannerId) data.append('order_index', banner.order_index)

      let newData = bannerId ? await apiBWEditBannerById(data) : await apiBWAddBanner(data)
      if (newData && newData.status) {
        setIsLoading(false)
        openNotification('success', 'Success !', `${bannerId ? 'Edit' : 'Add new'} banner successful.`)
        setTimeout(() => history.push(`/washer/banner${getSearchParams(['p', 'ttl', 's', 'sw'], [p, ttl, s, sw])}`), 1000);
      } else {
        setIsLoading(false)
        openNotification('error', 'Failed !', `Failed to ${bannerId ? 'edit' : 'add new'} banner.`)
      }
    }
  }

  return (
    <div>
      <div className="mg-bt-20 h-d-t-p">{bannerType === 'home' ? 'Home' : 'Site'} Banner - {bannerId ? 'Edit' : 'Add'}</div>

      <div className="t-m-c-i-p-g">
        <div className="f-s-c">Title</div>
        <div>
          <Input
            style={{ borderColor: isAlertTitle ? '#EC1C24' : null }}
            placeholder="Title"
            value={banner && banner.banner_title || ''}
            onChange={e => {
              setIsAlertTitle(false)
              setBanner({ ...banner, banner_title: e.target.value })
            }}
          />
        </div>

        <div className="f-s-c">Description</div>
        <div>
          <Input.TextArea
            autoSize={{ minRows: 3 }}
            placeholder="Description"
            value={banner && banner.banner_desciption || ''}
            onChange={e => setBanner({ ...banner, banner_desciption: e.target.value })}
          />
        </div>

        <div className="f-s-c">Image (ขนาด 405*240)</div>
        <div>
          <input id="banner-image" accept="image/*" type="file" style={{ display: 'none' }}
            onChange={e => {
              setIsAlertImage(false)
              let passSize = checkImageSize(true, e.target.files[0])
              if (passSize) {
                const reader = new FileReader()
                reader.readAsDataURL(e.target.files[0])
                reader.onload = () => setBanner({
                  ...banner,
                  image_file: e.target.files[0],
                  banner_img: reader.result
                })
              }
            }} />
          <label htmlFor="banner-image">
            <div className={`antd-avt-al ${isAlertImage && 'antd-avt-al-bd' || ''}`}>
              <Avatar shape="square" size={150} className="cs-pt" src={banner && banner.banner_img || ''} icon={<i className="bi bi-image"></i>} />
            </div>
          </label>
        </div>

        <div className="f-s-c">Link</div>
        <div className={isAlertLink && 'antd-ip-g-al-bd' || ''}>
          <Input
            placeholder="Link ex. www.google.com or google.com"
            addonBefore="https://"
            value={banner && banner.banner_link || ''}
            onChange={e => {
              setIsAlertLink(false)
              setBanner({ ...banner, banner_link: e.target.value })
            }}
          />
        </div>

        {bannerType === 'site' && <div className="f-s-c">Site</div>}
        {bannerType === 'site' && <div className={isAlertSiteName && 'antd-sl-al-bd' || ''}>
          <Select style={{ width: '100%' }}
            placeholder="Site"
            showSearch
            value={banner && banner.site_id}
            onChange={value => {
              setIsAlertSiteName(false)
              setBanner({ ...banner, site_id: value })
            }}
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {sites.map((site, idx) => <Option key={idx} value={site.site_id}>{site.site_name}</Option>)}
          </Select>
        </div>}

        <div className="f-s-c">Period Banner</div>
        <div>
          <RangePicker
            style={{ width: '100%', borderColor: isAlertPeriodPromotion ? '#EC1C24' : null }}
            placeholder={["Period Start", "Period End"]}
            format={date => moment(date).add(543, 'years').format('D MMM YYYY')}
            allowClear={false}
            value={[
              banner && banner.period_banner && banner.period_banner.start_date && moment(banner.period_banner.start_date, 'YYYY-MM-DD HH:mm:ss'),
              banner && banner.period_banner && banner.period_banner.end_date && moment(banner.period_banner.end_date, 'YYYY-MM-DD HH:mm:ss')
            ]}
            onChange={date => {
              setIsAlertPeriodPromotion(false)

              let newBanner = { ...banner }
              if (!newBanner.period_banner) newBanner.period_banner = {}

              newBanner.period_banner.start_date = moment(date[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss')
              newBanner.period_banner.end_date = moment(date[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss')
              setBanner(newBanner)
            }}
          />
        </div>

        <div className="f-s-c">Period Time</div>
        <div>
          {days.map((day, idx) =>
            <div key={idx} className="site-pro-discount-period-time-grid">
              <div className="site-pro-discount-period-time-day-grid site-pro-discount-period-time-grid-day site-pro-discount-period-time-order-1">
                <div className="f-s-c">
                  <i className="bi bi-calendar-range-fill"></i>
                </div>
                <div className="f-s-c">{day}</div>
              </div>
              <div className="site-pro-discount-period-time-grid-time site-pro-discount-period-time-order-3">
                <TimePicker.RangePicker
                  style={{ width: '100%' }}
                  placeholder={["Period Time Start", "Period Time End"]}
                  format={'HH:mm'}
                  allowClear={false}
                  value={banner && banner.period_time && banner.period_time[idx] && banner.period_time[idx].all_day
                    ? [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')]
                    : [
                      banner && banner.period_time && banner.period_time[idx] && banner.period_time[idx].start_time && moment(banner.period_time[idx].start_time, 'HH:mm:ss'),
                      banner && banner.period_time && banner.period_time[idx] && banner.period_time[idx].end_time && moment(banner.period_time[idx].end_time, 'HH:mm:ss')
                    ]}
                  onChange={date => {
                    let newBanner = { ...banner }
                    if (!newBanner.period_time) newBanner.period_time = []
                    if (!newBanner.period_time[idx]) newBanner.period_time[idx] = {}

                    let st = moment(date[0]).startOf('minute').format('HH:mm:ss')
                    let ed = moment(date[1]).endOf('minute').format('HH:mm:ss')

                    newBanner.period_time[idx].start_time = st
                    newBanner.period_time[idx].end_time = ed
                    newBanner.period_time[idx].all_day = st === '00:00:00' && ed === '23:59:59'
                    setBanner(newBanner)
                  }}
                />
              </div>
              <div className="f-s-c antd-chb-fz site-pro-discount-all-day site-pro-discount-period-time-order-2">
                <Checkbox
                  checked={banner && banner.period_time && banner.period_time[idx] && banner.period_time[idx].all_day}
                  onChange={e => {
                    let newBanner = { ...banner }
                    if (!newBanner.period_time) newBanner.period_time = []
                    if (!newBanner.period_time[idx]) newBanner.period_time[idx] = {}

                    newBanner.period_time[idx].all_day = e.target.checked
                    setBanner(newBanner)
                  }}
                >24 Hours</Checkbox>
              </div>
            </div>
          )}
        </div>

        {bannerId && <div className="f-s-c">Order</div>}
        {bannerId && <div>
          <InputNumber min={0}
            step={1}
            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
            style={{ width: '100%', borderColor: isAlertOrder ? '#EC1C24' : null }}
            placeholder="Order"
            value={banner && banner.order_index}
            onChange={value => {
              setIsAlertOrder(false)
              setBanner({ ...banner, order_index: Math.round(value) || 0 })
            }}
          />
        </div>}
      </div>

      <div className="f-c-c s-e-b">
        <div className="f-c-c d-b-t-n-s d-bg-c-t"
          style={{ width: '180px', pointerEvents: isLoading ? 'none' : null }}
          onClick={() => addEditData()}
        >{isLoading ? <LoadingOutlined /> : `${bannerId ? 'Edit' : 'Add New'} Banner`}</div>
      </div>

    </div>
  )
}

export default BannerAddEditWasher
