import axios from "../../config/axios";
import _axios from "axios";
import { baseURL2 } from "../../config/config";

const axios2 = _axios.create({ baseURL: baseURL2 });

const apiTRSTGetTransaction = async (
  get_all,
  start_date,
  end_date,
  cur_page,
  per_page,
  type,
  keyword
) => {
  try {
    const result = await axios.get(
      `/transaction/list?get_all=${get_all}&start_date=${start_date}&end_date=${end_date}&cur_page=${cur_page}&per_page=${per_page}&type=${type}&keyword=${keyword}`
    );
    return result && result.data;
  } catch (error) {
    return error && error.response && error.response.data;
  }
};

const apiTRSTGetReconcile = async (get_all, start_date, end_date) => {
  try {
    const result = await axios.get(
      `/transaction/reconcile?get_all=${get_all}&start_date=${start_date}&end_date=${end_date}`
    );
    return result && result.data;
  } catch (error) {
    return error && error.response && error.response.data;
  }
};

const apiTRSTDel = async (trst_id, admin_id) => {
  try {
    const result = await axios.delete(
      `/super/transaction?transaction_id=${trst_id}&admin_id=${admin_id}`
    );
    return result && result.data;
  } catch (error) {
    return error && error.response && error.response.data;
  }
};

const apiSCBTransactionId = async (tnx_id) => {
  try {
    const result = await axios2.get(`/search_scb_tnxid.php?id=${tnx_id}`);
    return result;
  } catch (error) {
    return error && error.response && error.response.data;
  }
};

export {
  apiTRSTGetTransaction,
  apiTRSTGetReconcile,
  apiTRSTDel,
  apiSCBTransactionId,
};
