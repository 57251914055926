import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { Input, Table, Pagination, Tooltip, Modal, Avatar } from "antd";
import "./AdminManagement.css";
import XLSX from "xlsx";
import { LoadingOutlined } from "@ant-design/icons";
import { openNotification, getSearchParams } from "../../utils/function";
import { QueryGraphQL, GET_ADMIN } from "../../resolvers/Query";
import usePagination from "../../hooks/usePagination";
import { useMutation } from "@apollo/client";
import { DELETE_ADMIN } from "../../resolvers/Mutation";

const AdminListV2 = () => {
  const location = useLocation();
  const history = useHistory();
  const { siteId } = useParams();

  const params = new URLSearchParams(location.search);
  const p = params.get("p");
  const ttl = params.get("ttl");
  const sw = params.get("sw");

  const menuList = (localStorage.getItem("list") || "").split(",");
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(Number(p) || 1);
  const [perPage, setPerPage] = useState(Number(ttl) || 20);
  const [searchWord, setSearchWord] = useState(sw || "");
  const [waitingForCSV, setWaitingForCSV] = useState(false);

  const [visible, setVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalId, setModalId] = useState(undefined);
  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false);

  const { data } = QueryGraphQL(GET_ADMIN);
  const [dataList, setDataList] = useState([]);
  const dataAdmin = data?.getAdmins;
  const checkDataPage = usePagination(dataAdmin, currentPage, perPage);
  const [deleteAdmin, dataDeleteAdmin] = useMutation(DELETE_ADMIN);
  const [searchTotalPage, setSearchTotalPage] = useState(20);
  const [dataSearchList, setDataSearchList] = useState([]);
  const dataSearch = usePagination(dataSearchList, currentPage, perPage);
  //console.log("🚀 ~ file: AdminList-v2.js:AdminListV2 ~ dataSearch", data);
   //console.log("data get adminList--👽", data, dataAdmin, dataList);
  useEffect(() => {
    if (siteId) {
      if (dataAdmin?.length > 0) {
        let dataArr = [];
        dataAdmin.map((item) => {
          if (item?.site_id?.length > 0) {
            item.site_id.map((site) => {
              if (site == siteId) {
                dataArr.push(item);
              }
            });
          }
        });
        setDataList(dataArr ?? []);
      }
    } else {
      setDataList(checkDataPage ?? []);
    }
  }, [dataAdmin, data, siteId]);

  useEffect(() => {
    if (dataSearchList?.length > 0) {
      let dataNew = [];
      dataSearchList.map((item, index) => {
        if (
          index + 1 >= currentPage * perPage - perPage + 1 &&
          index + 1 <= currentPage * perPage
        ) {
          dataNew.push(item);
        }
      });
      setDataList(dataNew);
    }
  }, [currentPage, perPage]);

  useEffect(() => {
    if (dataSearchList) {
      setDataList(dataSearch ?? []);
    }
  }, [dataSearchList]);

  useEffect(() => {
    if (dataDeleteAdmin && isLoadingConfirm) {
      if (dataDeleteAdmin?.error) {
        openNotification("error", "Failed !", "Failed to delete this admin.");
        setIsLoadingConfirm(false);
      }
      if (dataDeleteAdmin?.data?.deleteAdmin) {
        openNotification(
          "success",
          "Success !",
          "Delete this admin successful."
        );
        setVisible(false);
        setIsLoadingConfirm(false);
        window.location.reload(false);
      }
    }
  }, [dataDeleteAdmin]);

  const searchData = () => {
    if (searchWord) {
      let dataTmp = dataAdmin?.filter((item) => {
        return (
          item?.admin_branch
            ?.toLowerCase()
            .includes(searchWord.toLowerCase()) ||
          item?.admin_name?.toLowerCase().includes(searchWord.toLowerCase()) ||
          item?.admin_user?.toLowerCase().includes(searchWord.toLowerCase())
        );
      });

      setDataSearchList(dataTmp);
      setSearchTotalPage(dataTmp?.length ?? 20);
    } else {
      setDataSearchList(checkDataPage ?? []);
      setSearchTotalPage(checkDataPage?.length ?? 20);
    }
  };

  const columns = [
    {
      title: "#",
      ellipsis: true,
      align: "center",
      width: "5%",
      render: (data, record, index) => (
        <div>{index + 1 + (currentPage - 1) * perPage}</div>
      ),
    },
    {
      title: "Name",
      ellipsis: true,
      render: (data) => <div>{data.admin_name || "-"}</div>,
    },
    {
      title: "Username",
      ellipsis: true,
      render: (data) => <div>{data.admin_user || "-"}</div>,
    },
    {
      title: "E-mail",
      ellipsis: true,
      render: (data) => <div>{data.admin_email || "-"}</div>,
    },
    {
      title: "Phone",
      ellipsis: true,
      render: (data) => <div>{data.admin_tel || "-"}</div>,
    },
    {
      title: "สาขา",
      ellipsis: true,
      // render: (data) => {
      //   return data?.admin_branch?.length > 0 ? (
      //     data?.admin_branch?.map((item, index) => {
      //       return <div key={index}>{item}</div>;
      //     })
      //   ) : (
      //     <div>-</div>
      //   );
      // },
      render: (data) => <div>{data.admin_branch || "-"}</div>,
    },
    {
      title: "Manage",
      ellipsis: true,
      align: "center",
      fixed: "right",
      render: (data) =>
        menuList.includes("ADMINMAN") ? (
          <div className="f-c-c tb-cl-a">
            <Tooltip placement="top" title="Edit">
              <div
                className="f-c-c d-b-t-n-s b-c-o-i"
                style={{ backgroundColor: "#00ADEF" }}
                onClick={(e) => {
                  e.stopPropagation();
                  history.push(
                    `/admin-management/list-admin/edit-v2/${
                      data.id
                    }${getSearchParams(
                      ["p", "ttl", "sw"],
                      [currentPage, perPage, searchWord]
                    )}`
                  );
                }}
              >
                <i className="bi bi-pencil-fill"></i>
              </div>
            </Tooltip>
            <Tooltip placement="top" title="Delete">
              <div
                className="f-c-c d-b-t-n-s b-c-o-i"
                style={{ backgroundColor: "#ED2228" }}
                onClick={(e) => {
                  e.stopPropagation();
                  setModalId(data.id);
                  setModalTitle(data.admin_name);
                  setVisible(true);
                }}
              >
                <i className="fas fa-trash"></i>
              </div>
            </Tooltip>
          </div>
        ) : (
          <div>-</div>
        ),
    },
  ];

  const deleteData = async () => {
    if (!modalId) {
      openNotification("error", "Failed !", "Failed to delete this admin.");
      return;
    }

    setIsLoadingConfirm(true);
    deleteAdmin({
      variables: {
        adminId: modalId,
      },
    });
  };

  const toCSVFile = async () => {
    setWaitingForCSV(true);
    let csvData = [];

    if (dataAdmin?.length > 0) {
      dataAdmin.map((data, idx) => {
        let resultRow = {};
        // let branchName = "";
        // data?.admin_branch?.map((i, idx) => {
        //   branchName += i + ",";
        // });
        resultRow["#"] = idx + 1;
        resultRow["Name"] = data?.admin_name || "-";
        resultRow["Username"] = data?.admin_user || "-";
        resultRow["E-mail"] = data?.admin_email || "-";
        resultRow["Phone"] = data?.admin_tel || "-";
        resultRow["สาขา"] = data?.admin_branch || "-";
        csvData.push(resultRow);
      });

      const toCSV = XLSX.utils.json_to_sheet(csvData);
      const wb = XLSX.utils.book_new();

      const fileName = "ADMIN_LIST";
      XLSX.utils.book_append_sheet(wb, toCSV, fileName);
      XLSX.writeFile(wb, fileName + ".csv");

      setWaitingForCSV(false);
    }
  };

  return (
    <div>
      <div className="mg-bt-20 h-d-t-p">Admin List </div>

      <div
        className={`pro-reward-header-grid pro-reward-header-grid-column-${!menuList.includes(
          "ADMINMAN"
        )}`}
      >
        <div
          className={`pro-reward-header-grid-search-${!menuList.includes(
            "ADMINMAN"
          )}`}
        >
          <Input
            placeholder="search .."
            value={searchWord}
            onChange={(e) => setSearchWord(e.target.value)}
            onPressEnter={() => {
              setCurrentPage(1);
            }}
          />
        </div>
        <div
          className="f-c-c d-b-t-n-s d-bg-c-t"
          onClick={() => {
            setCurrentPage(1);
            searchData();
          }}
        >
          <i className="bi bi-search" style={{ lineHeight: "0" }}></i>
        </div>
        {menuList.includes("ADMINMAN") && (
          <Link to="/admin-management/add/v2">
            <div
              className="f-c-c d-b-t-n-s d-bg-c-t h-32"
              style={{ height: "100%" }}
            >
              + Add
            </div>
          </Link>
        )}
        <div
          className={`f-c-c d-b-t-n-s d-bg-c-t h-32 pro-reward-header-grid-export-${!menuList.includes(
            "ADMINMAN"
          )}`}
          style={{ pointerEvents: waitingForCSV ? "none" : null }}
          onClick={() => toCSVFile()}
        >
          {waitingForCSV ? <LoadingOutlined /> : "Export CSV"}
        </div>
      </div>

      <Table
        bordered
        size="small"
        rowKey={(record) => record.id}
        loading={isLoading}
        dataSource={dataList || []}
        columns={columns}
        pagination={false}
        scroll={{ x: true }}
      />

      <div className="pgnt-st">
        <Pagination
          size="small"
          current={currentPage}
          onChange={(page) => setCurrentPage(page)}
          total={
            searchWord ? searchTotalPage : siteId ? dataList : dataAdmin?.length
          }
          pageSize={perPage}
          showSizeChanger={false}
        />
      </div>

      <Modal
        title=""
        visible={visible}
        closable={false}
        footer={false}
        centered
      >
        <div className="m-t-i-c" style={{ color: "#FFCB05" }}>
          <i className="bi bi-exclamation-triangle-fill"></i>
        </div>
        <div className="m-t-t-q">Do you want to delete '{modalTitle}' ?</div>
        <div className="m-b-y-n-g">
          <div
            className="f-c-c d-b-t-n-s b-c-o-i"
            style={{ backgroundColor: "#cccccc" }}
            onClick={() => setVisible(false)}
          >
            Cancel
          </div>
          <div
            className="f-c-c d-b-t-n-s b-c-o-i"
            style={{
              backgroundColor: "#3f4d67",
              pointerEvents: isLoadingConfirm ? "none" : null,
            }}
            onClick={() => deleteData()}
          >
            {isLoadingConfirm ? <LoadingOutlined /> : "Confirm"}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AdminListV2;
