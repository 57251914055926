import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { Avatar, Modal } from 'antd';
import './Maintenance.css'

import { apiMTNGetTicketByMaintenanceId } from '../../services/apis/maintenance'

const HistoryView = () => {
  const { maintenanceId } = useParams();
  const [maintenance, setMaintenance] = useState(undefined)

  const [modalImageIdx, setModalImageIdx] = useState(0)
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (maintenanceId) getData()
  }, [maintenanceId])

  const getData = async () => {
    let data = await apiMTNGetTicketByMaintenanceId(maintenanceId)
    if (data && data.status) {
      let newData = { ...data.result }
      newData.machine = data.result.machine.split(',')
      newData.maintainer = data.result.maintainer.split(',')

      setMaintenance(newData)
    }
  }

  return (
    <div>
      <div className="mg-bt-20 h-d-t-p">Maintenance - History View</div>

      <div className="customer-view-info">
        <div className="f-s-c">Site</div>
        <div>{maintenance && maintenance.site_name || '-'}</div>
        <div className="f-s-c">Machine</div>
        <div>{(maintenance && maintenance.machine || []).map((list, idx) => <div key={idx}>{list}</div>)}</div>
        <div className="f-s-c">Ticket Type</div>
        <div>{maintenance && maintenance.maintenance_type || '-'}</div>
        <div className="f-s-c">Assignee</div>
        <div>{(maintenance && maintenance.maintainer || []).map((list, idx) => <div key={idx}>{list}</div>)}</div>
        <div className="f-s-c">อาการ</div>
        <div>{maintenance && maintenance.detail || '-'}</div>
        <div className="f-s-c">หมายเหตุ</div>
        <div>{maintenance && maintenance.remark || '-'}</div>
        <div className="f-s-c">Status</div>
        <div>{maintenance && maintenance.status || '-'}</div>

        <div className="f-s-c">Image</div>
        <div className="dpl-im-l-a-ip-f">
          {(maintenance && maintenance.image_list || []).map((image, idx) => <div key={idx}
            className="pst-rlt"
            onClick={() => {
              setVisible(true)
              setModalImageIdx(idx)
            }}
          >
            <Avatar shape="square" size={110} className="cs-pt" src={image || ''} icon={<i className="bi bi-image"></i>} />
          </div>
          )}
        </div>

        <div className="f-s-c">Close By</div>
        <div>{maintenance && maintenance.admin_close || '-'}</div>
      </div>

      <Modal
        title=""
        visible={visible}
        closable={false}
        footer={false}
        centered
      >
        <div className="md-ctn-crs-im-l">
          <div className="f-c-c md-ctn-crs-im-ar" onClick={() => modalImageIdx > 0 ? setModalImageIdx(modalImageIdx - 1) : null}>
            <i className="fas fa-chevron-left"
              style={modalImageIdx > 0 ? null : { opacity: '0.7', cursor: 'not-allowed' }}
            ></i>
          </div>
          <div className="f-c-c md-ctn-crs-im">
            <img src={maintenance && maintenance.image_list && maintenance.image_list.length > 0 && maintenance.image_list[modalImageIdx] || ''} alt="" />
          </div>
          <div className="f-c-c md-ctn-crs-im-ar"
            onClick={() => modalImageIdx < (maintenance && maintenance.image_list && maintenance.image_list.length) - 1 ? setModalImageIdx(modalImageIdx + 1) : null}
          >
            <i className="fas fa-chevron-right"
              style={modalImageIdx < (maintenance && maintenance.image_list && maintenance.image_list.length) - 1 ? null : { opacity: '0.7', cursor: 'not-allowed' }}
            ></i>
          </div>
        </div>

        <div className="f-c-c d-b-t-n-s d-bg-c-t md-btn-ol-cc"
          onClick={() => setVisible(false)}
        >Cancel</div>
      </Modal>

    </div>
  )
}

export default HistoryView
