import axios from '../../config/axios';
import axiostw from '../../config/axios-tw';



const apiaddV1Machine = async (data) => {
  try {
    const result = await axiostw.post('/machine/addV1Machine', data)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}


const apiMCHGetMachine = async (get_all, cur_page, per_page, keyword, machine_type_id, site_id) => {
  try {
    const result = await axios.get(`/machine/list?get_all=${get_all}&cur_page=${cur_page}&per_page=${per_page}&keyword=${keyword}&machine_type_id=${machine_type_id}&site_id=${site_id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiMCHAddMachine = async (data) => {
  try {
    const result = await axios.post('/machine', data)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiMCHEditMachineById = async (data) => {
  try {
    const result = await axios.put('/machine', data)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiMCHDeleteMachine = async (machine_id) => {
  try {
    const result = await axios.delete(`/machine?machine_id=${machine_id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiMCHGetMachineById = async (machine_id) => {
  try {
    const result = await axios.get(`/machine?machine_id=${machine_id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiMCHGetMachineHistoryGraphById = async (start_date, end_date, machine_id) => {
  try {
    const result = await axios.get(`/machine/graph?start_date=${start_date}&end_date=${end_date}&machine_id=${machine_id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiMCHGetMachineHistoryById = async (get_all, start_date, end_date, cur_page, per_page, keyword, machine_id) => {
  try {
    const result = await axios.get(`/machine/report?get_all=${get_all}&start_date=${start_date}&end_date=${end_date}&cur_page=${cur_page}&per_page=${per_page}&keyword=${keyword}&machine_id=${machine_id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiMCHGetType = async (get_all, cur_page, per_page) => {
  try {
    const result = await axios.get(`/machine/type/list?get_all=${get_all}&cur_page=${cur_page}&per_page=${per_page}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiMCHGetTypeBySiteId = async (site_id) => {
  try {
    const result = await axios.get(`/machine/type/dropdown?site_id=${site_id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiMCHAddType = async (data) => {
  try {
    const result = await axios.post('/machine/type', data)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiMCHGetTypeById = async (machine_type_id) => {
  try {
    const result = await axios.get(`/machine/type?machine_type_id=${machine_type_id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiMCHEditTypeById = async (data) => {
  try {
    const result = await axios.put('/machine/type', data)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiMCHDeleteTypeById = async (machine_type_id) => {
  try {
    const result = await axios.delete(`/machine/type?machine_type_id=${machine_type_id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiMCHGetSubTypeByTypeId = async (machine_type_id) => {
  try {
    const result = await axios.get(`/machine/subtype/dropdown?machine_type_id=${machine_type_id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiMCHGetSubTypeListByTypeId = async (machine_type_id) => {
  try {
    const result = await axios.get(`/machine/subtype/list?machine_type_id=${machine_type_id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiMCHGetSubTypeById = async (machine_sub_type_id) => {
  try {
    const result = await axios.get(`/machine/subtype?machine_sub_type_id=${machine_sub_type_id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiMCHAddSubType = async (data) => {
  try {
    const result = await axios.post('/machine/subtype', data)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiMCHEditSubTypeById = async (data) => {
  try {
    const result = await axios.put('/machine/subtype', data)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiMCHDeleteSubTypeById = async (machine_sub_type_id) => {
  try {
    const result = await axios.delete(`/machine/subtype?machine_sub_type_id=${machine_sub_type_id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiMCHGetBrand = async () => {
  try {
    const result = await axios.get('/machine/brand')
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiMCHGetModel = async (brand_id) => {
  try {
    const result = await axios.get(`/machine/model?brand_id=${brand_id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiMCHGetControllerList = async (get_all, cur_page, per_page, keyword) => {
  try {
    const result = await axios.get(`/machine/controller/list?get_all=${get_all}&cur_page=${cur_page}&per_page=${per_page}&keyword=${keyword}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiMCHGetControllerMachine = async (machine_id) => {
  try {
    const result = await axios.get(`/machine/dropdown?machine_id=${machine_id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiMCHGetController = async (machine_id) => {
  try {
    const result = await axios.get(`/machine/controller/dropdown?machine_id=${machine_id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiMCHAddController = async (data) => {
  try {
    const result = await axios.post('/machine/controller', data)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiMCHGetControllerById = async (controller_id) => {
  try {
    const result = await axios.get(`/machine/controller?controller_id=${controller_id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiMCHEditControllerById = async (data) => {
  try {
    const result = await axios.put('/machine/controller', data)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiMCHDeleteControllerById = async (controller_id) => {
  try {
    const result = await axios.delete(`/machine/controller?controller_id=${controller_id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiMCHActionMachine = async (data) => {
  try {
    const result = await axios.post('/mobile/machine_action.php', data)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiMCHGetNameBySiteId = async (site_id, machine_type_id) => {
  try {
    const result = await axios.get(`/machine/site/dropdown?site_id=${site_id}&machine_type_id=${machine_type_id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

export {
  apiMCHGetMachine,
  apiMCHAddMachine,
  apiMCHEditMachineById,
  apiMCHDeleteMachine,
  apiMCHGetMachineById,
  apiMCHGetMachineHistoryGraphById,
  apiMCHGetMachineHistoryById,

  apiMCHGetType,
  apiMCHGetTypeBySiteId,
  apiMCHAddType,
  apiMCHGetTypeById,
  apiMCHEditTypeById,
  apiMCHDeleteTypeById,

  apiMCHGetSubTypeByTypeId,
  apiMCHGetSubTypeListByTypeId,
  apiMCHGetSubTypeById,
  apiMCHAddSubType,
  apiMCHEditSubTypeById,
  apiMCHDeleteSubTypeById,

  apiMCHGetBrand,
  apiMCHGetModel,

  apiMCHGetControllerList,
  apiMCHGetControllerMachine,
  apiMCHGetController,
  apiMCHAddController,
  apiMCHGetControllerById,
  apiMCHEditControllerById,
  apiMCHDeleteControllerById,

  apiMCHActionMachine,
  apiMCHGetNameBySiteId,
  apiaddV1Machine
}