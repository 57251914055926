import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Table,
  DatePicker,
  Select,
  Input,
  Pagination,
  Tooltip,
  Modal,
} from "antd";
import "./Transaction.css";
import XLSX from "xlsx";
import { LoadingOutlined } from "@ant-design/icons";

import moment from "moment";

import {
  numberWithCommas,
  getSearchParams,
  openNotification,
} from "../../utils/function";
import {
  apiTRSTGetTransaction,
  apiTRSTDel,
} from "../../services/apis/transaction";
import { QueryGraphQLByPeriod, GET_Transactions } from "../../resolvers/Query";
import usePagination from "../../hooks/usePagination";
import { useMutation } from "@apollo/client";
import { DELETE_Transaction } from "../../resolvers/Mutation";

const { RangePicker } = DatePicker;
const { Option } = Select;

const TransactionCenterList = () => {
  const history = useHistory();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const p = params.get("p");
  const ttl = params.get("ttl");
  const pmt = params.get("pmt");
  const start = params.get("start");
  const end = params.get("end");
  const sw = params.get("sw");

  const menuList = (localStorage.getItem("list") || "").split(",");

  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(Number(p) || 1);
  // const [perPage, setPerPage] = useState(Number(ttl) || 20);
  const perPage = 20;
  const [startDate, setStartDate] = useState(
    start
      ? moment(start).startOf("day").format("YYYY-MM-DD HH:mm:ss")
      : moment().startOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [endDate, setEndDate] = useState(
    end
      ? moment(end).endOf("day").format("YYYY-MM-DD HH:mm:ss")
      : moment().endOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [filterOption, setFilterOption] = useState(pmt || "");
  const [searchWord, setSearchWord] = useState(sw || "");
  const [waitingForCSV, setWaitingForCSV] = useState(false);
  const [waitingForDel, setWaitingForDel] = useState(false);
  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false);

  const [visible, setVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalId, setModalId] = useState(undefined);
  const { data } = QueryGraphQLByPeriod(GET_Transactions, startDate, endDate);
  console.log(startDate, endDate)
  const [dataList, setDataList] = useState([]);
  const [dataSearchList, setDataSearchList] = useState([]);
  const [searchTotalPage, setSearchTotalPage] = useState(20);
  const dataHistory = data?.getTransactions;
  const checkDataPage = usePagination(dataHistory, currentPage, perPage);
  const dataSearch = usePagination(dataSearchList, currentPage, perPage);
  const [deleteHistory, dataDeleteHistory] = useMutation(DELETE_Transaction);

   console.log("data--TransactionListV2-🐳🐳-", dataList, data);

  useEffect(() => {
    if (searchWord || filterOption) {
      if (dataSearchList?.length > 0) {
        setDataList(dataSearch ?? []);
      } else if (dataSearchList == null) {
        setDataList([]);
      } else {
        setDataList(checkDataPage ?? []);
      }
    }
  }, [dataSearchList]);

  useEffect(() => {
    setDataList(checkDataPage);
  }, [dataHistory]);

  useEffect(() => {
    
    const dataArr = dataSearchList?.length > 0 ? dataSearchList : dataHistory;
    if (dataArr?.length > 0) {
      let dataNew = [];
      dataArr.map((item, index) => {
        if (
          index + 1 >= currentPage * perPage - perPage + 1 &&
          index + 1 <= currentPage * perPage
        ) {
          dataNew.push(item);
        }
      });
      setDataList(dataNew);
    }
  }, [currentPage, perPage]);

  useEffect(() => {
    if (dataDeleteHistory && isLoadingConfirm) {
      if (dataDeleteHistory?.error) {
        openNotification("error", "Failed !", "Failed to delete this machine.");
        setIsLoadingConfirm(false);
      }
      if (dataDeleteHistory?.data?.deleteTransaction) {
        openNotification(
          "success",
          "Success !",
          "Delete this machine successful."
        );
        setVisible(false);
        setIsLoadingConfirm(false);
        setTimeout(() => window.location.reload(false), 500);
      }
    }
  }, [dataDeleteHistory, isLoadingConfirm]);

  useEffect(() => {
    if (dataDeleteHistory && waitingForDel) {
      if (dataDeleteHistory?.error) {
        openNotification("error", "Failed !", "Failed to delete this machine.");
        setWaitingForDel(false);
      }
      if (dataDeleteHistory?.data?.deleteTransaction) {
        setWaitingForDel(false);
        setTimeout(() => window.location.reload(false), 500);
      }
    }
  }, [dataDeleteHistory, waitingForDel]);

  const searchData = () => {
    let dataTmp = [];
    if (filterOption || searchWord) {
      dataTmp = dataHistory?.filter((item) => {
        if (filterOption && searchWord) {
          return (
            item?.action_by[0]
              ?.toLowerCase()
              .includes(filterOption.toLowerCase()) &&
            (item?.machine_no.includes(searchWord.toLowerCase()) ||
              item?.machine_type
                ?.toLowerCase()
                .includes(searchWord.toLowerCase()) ||
              item?.site_name?.toLowerCase().includes(searchWord.toLowerCase()))
          );
        } else {
          if (filterOption) {
            return item?.action_by[0]
              ?.toLowerCase()
              .includes(filterOption.toLowerCase());
          }
          if (searchWord) {
            return (
              item?.machine_no.includes(searchWord.toLowerCase()) ||
              item?.machine_type
                ?.toLowerCase()
                .includes(searchWord.toLowerCase()) ||
              item?.site_name?.toLowerCase().includes(searchWord.toLowerCase())
            );
          }
        }
      });
      setSearchTotalPage(dataTmp?.length ?? 20);
      setDataSearchList(dataTmp?.length == 0 ? null : dataTmp);
    } else {
      setDataList(checkDataPage ?? []);
      setDataSearchList([]);
    }
  };

  const selectDelete = [];
  const handleOnChange = (event) => {
    if (selectDelete.indexOf(event.id) > -1) {
      selectDelete.splice(selectDelete.indexOf(event.id), 1);
    } else {
      selectDelete.push(event.id);
    }
    console.log("Selected : " + selectDelete.length);
  };

  const deleteData = async () => {
    if (!modalId) {
      openNotification("error", "Failed !", "Failed to delete this machine.");
      return;
    }

    setIsLoadingConfirm(true);
    deleteHistory({
      variables: {
        historyId: modalId,
      },
    });
  };

  const columnsGraphQL = [
    {
      title: "",
      ellipsis: true,
      align: "center",
      width: "5%",
      render: (data, index) => (
        <div>
          <input
            type="checkbox"
            id={`select-${data.id}`}
            name="checkDel"
            onClick={() => handleOnChange(index)}
          />
        </div>
      ),
    },
    {
      title: "#",
      ellipsis: true,
      align: "center",
      width: "5%",
      render: (data, record, index) => (
        <div>{index + 1 + (currentPage - 1) * perPage}</div>
      ),
    },
    {
      title: "วันที่กลาง",
      ellipsis: true,
      align: "center",
      render: (data) => (
        <div>
          {data?.device_startAt}
        </div>
      ),
    },
    // {
    //   title: "วันที่",
    //   ellipsis: true,
    //   align: "center",
    //   render: (data) => (
    //     <div>
    //       {data.time}
    //       {/* {data?.device_startAt ? moment(data.device_startAt).add(543, "years").format("D MMM YYYY"): "-"} */}
    //     </div>
    //   ),
    // },
    {
      title: "วัน-เวลา",
      ellipsis: true,
      align: "center",
      render: (data) => (
        <div>
          {/* //{data.device_startAt ? moment(data.device_startAt).add(543, "years").format("HH:mm:ss") : "-"}  */}
          {data.device_startAt ? moment(data.device_startAt).add(543, "years").format("DD MMM YYYY HH:mm:ss") : "-"} 
        </div>
      ),
    },
    {
      title: "สถานที่",
      ellipsis: true,
      render: (data) => <div>{data.site_name || "-"}</div>,
    },
    {
      title: "เครื่อง",
      ellipsis: true,
      render: (data) => (
        <div>
          No.{data.machine_no || "-"} {data.machine_type || "-"}
        </div>
      ),
    },
    {
      title: "ลูกค้า",
      ellipsis: true,
      className: "cs-pt",
      render: (data) => (
        <div onClick={() => window.open(`/customer/list/${data.id}`, "_blank")}>
          {data?.customer_name?.length ? data?.customer_name : "-"}
        </div>
      ),
    },
    {
      title: "ช่องทาง",
      ellipsis: true,
      align: "center",
      render: (data) => (
        <div>{data.action_by == "NOP" ? "Coin" : data.action_by}</div>
      ),
    },
    {
      title: "จำนวนเงิน",
      ellipsis: true,
      align: "center",
      render: (data) => <div>{numberWithCommas(data.run_value || 0, 2)}</div>,
    },
    // {
    //   title: "สถานะ",
    //   ellipsis: true,
    //   align: "center",
    //   render: (data) => (
    //     <div>
    //       {data.device_status
    //         ? data.device_status == 0
    //           ? "Online"
    //           : data.device_status == 1
    //           ? "Working"
    //           : data.device_status == -1
    //           ? "Offline"
    //           : data.device_status
    //         : "-"}
    //     </div>
    //   ),
    // },
    // {
    //   title: "Action",
    //   ellipsis: true,
    //   align: "center",
    //   render: (data) =>
    //     menuList.includes("TRANSACMAN") && (
    //       <Tooltip placement="top" title="Delete">
    //         <div
    //           className="f-c-c d-b-t-n-s b-c-o-i"
    //           style={{ backgroundColor: "#ED2228" }}
    //           onClick={(e) => {
    //             e.stopPropagation();
    //             setModalId(data.id);
    //             setModalTitle(data.id);
    //             setVisible(true);
    //           }}
    //         >
    //           <i className="fas fa-trash"></i>
    //         </div>
    //       </Tooltip>
    //     ),
    // },
  ];

  const deleteSelected = () => {
    setWaitingForDel(true);

    selectDelete.forEach((value) => {
      deleteHistory({
        variables: {
          historyId: value,
        },
      });
    });
  };

  const toCSVFile = async () => {
    setWaitingForCSV(true);
    let csvData = [];
    const _data = searchWord || filterOption ? dataSearchList : dataHistory;
    if (_data?.length > 0) {
      _data.map((data, idx) => {
        let resultRow = {};
        resultRow["วันที่กลาง"] =  data.device_startAt;
        resultRow["วัน-เวลา"] =  data.device_startAt ? moment(data.device_startAt).add(543, "years").format("DD MMM YYYY HH:mm:ss") : "-";
        // resultRow["วัน"] = data?.device_startAt ? moment(data.device_startAt.split(".")[0]).format("D")
        //   : "-";
        // resultRow["เดือน"] = data?.device_startAt
        //   ? moment(data.device_startAt.split(".")[0]).format("MMM")
        //   : "-";
        // resultRow["ปี"] = data?.device_startAt
        //   ? moment(data.device_startAt.split(".")[0])
        //       //.add(543, "years")
        //       .format("YYYY")
        //   : "-";
        // resultRow["เวลา"] = data?.device_startAt ? moment(data.device_startAt).add(543, "years").format("HH:mm:ss") : "-";
        resultRow["รหัสสถานที่"] = `${data.side_type ?? ""} ${
          data.province_id ?? ""
        } ${data.zone ?? ""} ${data.site_sub_type ?? ""} ${
          data.siteCode ?? ""
        }`;
        resultRow["siteCode"] = data.siteCode || "-";
        resultRow["side_type"] = data.side_type || "-";
        resultRow["สถานที่"] = data.site_name || "-";
        resultRow["เครื่อง"] = `${data.machine_no || ""}`;
        resultRow["ประเภท"] = `${data.machine_type || ""}`;
        
        resultRow["ลูกค้า"] =
          data?.customer_name?.length > 0 ? data?.customer_name[0] : "-";
        resultRow["ช่องทาง"] =
          data?.action_by?.length > 0
            ? data?.action_by[0] == "NOP"
              ? "Coin"
              : data.action_by[0]
            : "";
        resultRow["จำนวนเงิน"] = numberWithCommas(data.run_value || 0, 2);
        resultRow["สถานะ"] = data.device_status
          ? data.device_status == 0
            ? "Online"
            : data.device_status == 1
            ? "Working"
            : data.device_status == -1
            ? "Offline"
            : data.device_status
          : "-";
        csvData.push(resultRow);
      });

      const toCSV = XLSX.utils.json_to_sheet(csvData);
      const wb = XLSX.utils.book_new();

      const fileName = "TRANSACTION_LIST";
      XLSX.utils.book_append_sheet(wb, toCSV, fileName);
      XLSX.writeFile(wb, fileName + ".csv");

      setWaitingForCSV(false);
    }
  };

  return (
    <div>
      <div className="mg-bt-20 h-d-t-p">Transaction Center List</div>

      <div className="h-d-t-g-w-d-d-s">
        <div className="h-d-t-g-w-d-d-s-order-2">
          <Select
            value={filterOption}
            style={{ width: "100%" }}
            onChange={(value) => {
              setCurrentPage(1);
              setFilterOption(value);
            }}
          >
            <Option value="">All Payment Type</Option>
            <Option value="coin">Coin</Option>
            <Option value="mobile">Mobile</Option>
            <Option value="tw_pay">TW Pay</Option>
          </Select>
        </div>
        <div className="h-d-t-g-w-d-d-s-order-1">
          <RangePicker
            style={{ width: "100%" }}
            //format={(date) => moment(date).add(543, "years").format("D MMM YYYY HH:mm") }
            allowClear={false}
            showTime={{ format: "HH:mm" }}
            value={[
              moment(startDate, "YYYY-MM-DD HH:mm:ss"),
              moment(endDate, "YYYY-MM-DD HH:mm:ss"),
            ]}
            onChange={(date) => {
              setCurrentPage(1);
              setStartDate(
                moment(date[0]).startOf("minute").format("YYYY-MM-DD HH:mm:ss")
              );
              setEndDate(
                moment(date[1]).endOf("minute").format("YYYY-MM-DD HH:mm:ss")
              );
            }}
            disabledDate={(current) =>
              current && current >= moment().endOf("day")
            }
          />
        </div>
        <div className="h-d-t-g-w-d-d-s-order-3">
          <Input
            placeholder="search .."
            value={searchWord}
            onChange={(e) => setSearchWord(e.target.value)}
            onPressEnter={() => {
              setCurrentPage(1);
            }}
          />
        </div>
        <div
          className="f-c-c d-b-t-n-s d-bg-c-t h-d-t-g-w-d-d-s-order-4"
          onClick={() => {
            setCurrentPage(1);
            searchData();
          }}
        >
          <i className="bi bi-search" style={{ lineHeight: "0" }}></i>
        </div>
        <div
          className="f-c-c d-b-t-n-s d-bg-c-t h-32 h-d-t-g-w-d-d-s-order-5"
          style={{ pointerEvents: waitingForCSV ? "none" : null }}
          onClick={() => toCSVFile()}
        >
          {waitingForCSV ? <LoadingOutlined /> : "Export CSV"}
        </div>
        <div
          className="f-c-c d-b-t-n-s d-bg-c-t h-32 h-d-t-g-w-d-d-s-order-5"
          style={{ pointerEvents: waitingForDel ? "none" : null }}
          onClick={() => deleteSelected()}
        >
          {waitingForDel ? <LoadingOutlined /> : "Delete Select"}
        </div>
      </div>

      <Table
        bordered
        size="small"
        loading={isLoading}
        rowKey={(record) => record.id}
        dataSource={dataList}
        columns={columnsGraphQL}
        pagination={false}
        scroll={{ x: true }}
      />

      <div className="pgnt-st">
        <Pagination
          size="small"
          current={currentPage}
          onChange={(page) => setCurrentPage(page)}
          total={
            dataHistory?.length
          }
          pageSize={perPage}
          showSizeChanger={false}
        />
      </div>

      <Modal
        title=""
        visible={visible}
        closable={false}
        footer={false}
        centered
      >
        <div className="m-t-i-c" style={{ color: "#FFCB05" }}>
          <i className="bi bi-exclamation-triangle-fill"></i>
        </div>
        <div className="m-t-t-q">Do you want to delete '{modalTitle}' ?</div>
        <div className="m-b-y-n-g">
          <div
            className="f-c-c d-b-t-n-s b-c-o-i"
            style={{ backgroundColor: "#cccccc" }}
            onClick={() => setVisible(false)}
          >
            Cancel
          </div>
          <div
            className="f-c-c d-b-t-n-s b-c-o-i"
            style={{
              backgroundColor: "#3f4d67",
              pointerEvents: isLoadingConfirm ? "none" : null,
            }}
            onClick={() => deleteData()}
          >
            {isLoadingConfirm ? <LoadingOutlined /> : "Confirm"}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TransactionCenterList;
