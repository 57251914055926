import React, { useState, useEffect } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { Input, Select, Checkbox } from "antd";
import "./AdminManagement.css";
import { LoadingOutlined } from "@ant-design/icons";

import {
  openNotification,
  checkReqFieldWithId,
  getSearchParams,
} from "../../utils/function";
import { useMutation } from "@apollo/client";
import { ADD_ADMIN, EDIT_ADMIN } from "../../resolvers/Mutation";
import { QueryGraphQL, GET_ADMIN, GET_SITES } from "../../resolvers/Query";
import { compose } from "@reduxjs/toolkit";

const { Option } = Select;

const AdminAddEditV2 = () => {
  const { adminId, siteId } = useParams();
  const location = useLocation();
  const history = useHistory();

  const params = new URLSearchParams(location.search);
  const p = params.get("p");
  const ttl = params.get("ttl");
  const sw = params.get("sw");
  const [isLoading, setIsLoading] = useState(false);
  const [admin, setAdmin] = useState(undefined);
  console.log(admin)
  const [branch, setBranch] = useState([]);

  const menus = [{ key: "export", value: "export" }];

  const [isAlertAdminName, setIsAlertAdminName] = useState(false);
  const [isAlertUsername, setIsAlertUsername] = useState(false);
  const [isAlertPassword, setIsAlertPassword] = useState(false);
  const [addAdmin, dataAddAdmin] = useMutation(ADD_ADMIN);
  const [editAdmin, dataEditAdmin] = useMutation(EDIT_ADMIN);

  const { data } = QueryGraphQL(GET_ADMIN);
  const dataAdmin = data?.getAdmins;
  const dataGetSite = QueryGraphQL(GET_SITES);
  const dataSites = dataGetSite?.data?.getSites;
  //console.log("dataAddAdmin--🦑", siteId, admin, dataSites);

  useEffect(() => {
    if (adminId) getData();
    else {
      setAdmin(undefined);
      setIsAlertAdminName(false);
      setIsAlertUsername(false);
      setIsAlertPassword(false);
    }
    if (dataSites?.length > 0) {
      console.log(dataSites)
      setBranch(dataSites);

      // let dataNew = dataSites?.filter((item) => {
      //   return item?.siteCode.includes(siteId);
      // });
      // if (dataNew == null) {
      //   setAdmin({
      //     ...admin,
      //     site_id_list: dataNew[0]?.id,
      //     site_name_list: dataNew[0]?.site_name,
      //   });
      // }

    }
  }, [adminId, dataSites]);

  useEffect(() => {
    if (isLoading) {
      if (adminId) {
        if (dataEditAdmin?.error) {
          setIsLoading(false);
          openNotification("error", "Failed !", `Failed to edit admin.`);
        }
        if (dataEditAdmin?.data?.updateAdmin) {
          setIsLoading(false);
          openNotification("success", "Success !", `Edit admin successful.`);
          setTimeout(
            () =>
              history.push(
                `/admin-management/list/v2${getSearchParams(
                  ["p", "ttl", "sw"],
                  [p, ttl, sw]
                )}`
              ),
            1000
          );
        }
      } else {
        if (dataAddAdmin?.error) {
          setIsLoading(false);
          openNotification("error", "Failed !", `Failed to add new admin.`);
        }
        if (dataAddAdmin?.data?.addAdmin) {
          setIsLoading(false);
          openNotification("success", "Success !", `Add new admin successful.`);
          setTimeout(
            () =>
              history.push(
                `/admin-management/list/v2${getSearchParams(
                  ["p", "ttl", "sw"],
                  [p, ttl, sw]
                )}`
              ),
            1000
          );
        }
      }
    }
  }, [dataAddAdmin, dataEditAdmin]);

  const getData = async () => {
    if (dataAdmin?.length > 0) {
      let siteId = [];
      dataAdmin?.map((item) => {
        let dataItem = { ...item };

        if (item?.id == adminId) {

          // console.log(dataSites)
          // if (dataSites?.length > 0 && item?.admin_branch?.length > 0) {
          //   dataSites.map((b) => {
          //       item.admin_branch.map((ab) => {
          //        console.log(item.admin_branch)
          //          console.log( b.site_name)

          //      if (b?.site_name == item.admin_branch) {
          //        siteId.push(b?.id);
          //      }
          //            });
          //   });
          // }
          siteId = dataItem.site_id
          dataItem.site_id_list = siteId;
          dataItem.site_name_list = item.admin_branch;
          setAdmin(dataItem);
        }
      });
    }
  };

  const addEditData = async () => {
    let passPW = checkReqFieldWithId(adminId, admin && admin.admin_password);

    if (
      !(admin && admin.admin_name) ||
      !(admin && admin.admin_user) ||
      !passPW
    ) {
      if (!(admin && admin.admin_name)) setIsAlertAdminName(true);
      if (!(admin && admin.admin_user)) setIsAlertUsername(true);
      if (!passPW) setIsAlertPassword(true);
    } else {
      setIsLoading(true);
      if (adminId) {
        editAdmin({
          variables: {
            adminId: adminId ?? admin?.id,
            name: admin?.admin_name ?? "",
            branch: admin?.site_id_list ?? [""],
            username: admin?.admin_user ?? "",
            password: admin?.admin_password ?? "",
            confirmPassword: admin?.admin_password ?? "",
            email: admin?.admin_email ?? "",
            tel: admin?.admin_tel ?? "",
            list: admin?.menu_list ?? [""]
          },
        });
      } else {
        addAdmin({
          variables: {
            name: admin?.admin_name ?? "",
            branch: admin?.site_id_list ?? [""],
            username: admin?.admin_user ?? "",
            password: admin?.admin_password ?? "",
            confirmPassword: admin?.admin_password ?? "",
            email: admin?.admin_email ?? "",
            tel: admin?.admin_tel ?? "",
            list: admin?.menu_list ?? [""]
          },
        });
      }
    }
  };

  return (
    <div>
      <div className="mg-bt-20 h-d-t-p">Admin - {adminId ? "Edit" : "Add"}</div>

      <div className="t-m-c-i-p-g">
        <div className="f-s-c">ชื่อเจ้าของสาขา</div>
        <div>
          <Input
            style={{ borderColor: isAlertAdminName ? "#EC1C24" : null }}
            placeholder="ชื่อเจ้าของสาขา"
            value={(admin && admin.admin_name) || ""}
            onChange={(e) => {
              setIsAlertAdminName(false);
              setAdmin({ ...admin, admin_name: e.target.value });
            }}
          />
        </div>

        <div className="f-s-c">อีเมลเจ้าของสาขา</div>
        <div>
          <Input
            placeholder="อีเมลเจ้าของสาขา"
            value={(admin && admin.admin_email) || ""}
            onChange={(e) =>
              setAdmin({ ...admin, admin_email: e.target.value })
            }
          />
        </div>

        <div className="f-s-c">เบอร์โทรเจ้าของสาขา</div>
        <div>
          <Input
            placeholder="เบอร์โทรเจ้าของสาขา"
            value={(admin && admin.admin_tel) || ""}
            onChange={(e) =>
              setAdmin({
                ...admin,
                admin_tel: e.target.value.replace(/[^0-9]+/g, ""),
              })
            }
          />
        </div>

        <div className="f-s-c">Username</div>
        <div>
          <Input
            style={{ borderColor: isAlertUsername ? "#EC1C24" : null }}
            placeholder="Username"
            value={(admin && admin.admin_user) || ""}
            onChange={(e) => {
              setIsAlertUsername(false);
              setAdmin({ ...admin, admin_user: e.target.value });
            }}
          />
        </div>

        <div className="f-s-c">Password</div>
        <div>
          <Input.Password
            style={{ borderColor: isAlertPassword ? "#EC1C24" : null }}
            placeholder="Password"
            value={(admin && admin.admin_password) || ""}
            onChange={(e) => {
              setIsAlertPassword(false);
              setAdmin({ ...admin, admin_password: e.target.value });
            }}
          />
        </div>

        <div className="f-s-c">Branch</div>
        <div>
          <Select
            style={{ width: "100%" }}
            placeholder="Branch"
            mode="multiple"
            optionFilterProp="children"
            value={admin && admin.site_name_list}
            onChange={(value, data) => {
              setAdmin({
                ...admin,
                site_id_list: value,
                site_name_list: data[0]?.name,
              });
            }}
          >
            {branch.map((branch, idx) => (
              <Option key={idx} value={branch.id} name={branch.site_name}>
                {branch.site_name}
              </Option>
            ))}
          </Select>
        </div>
        {/* <div className="f-s-c">Branch</div>
        <div>
          <Select
            style={{ width: "100%" }}
            placeholder="Branch"
            mode="multiple"
            showArrow
            value={admin && admin.site_name}
            onChange={(value) => setAdmin({
              ...admin, site_name_list: value,
              site_id_list: data?.name,
            })}
            optionFilterProp="children"
          // filterOption={(input, option) =>
          //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          // }
          >
            {branch.map((branch, idx) => (
              <Option key={idx} value={branch.id}>
                {branch.site_name}
              </Option>
            ))}
          </Select>
        </div> */}
        <div className="f-s-c">Menu</div>
        <div>
          {menus.map((menu, idx) => (
            <div key={idx} className="f-s-c antd-chb-fz">
              <Checkbox
                checked={((admin && admin.menu_list) || []).includes(menu.key)}
                onChange={(e) => {
                  let newAdmin = { ...admin };
                  if (!newAdmin.menu_list) newAdmin.menu_list = [];
                  console.log(e.target.checked);
                  if (e.target.checked) newAdmin.menu_list = [...newAdmin.menu_list, menu.key];
                  else
                    newAdmin.menu_list = admin.menu_list.filter(
                      (list) => list !== menu.key
                    );
                  setAdmin(newAdmin);
                }}
              >
                {menu.value}
              </Checkbox>
            </div>
          ))}
        </div>
      </div>

      <div className="f-c-c s-e-b">
        <div
          className="f-c-c d-b-t-n-s d-bg-c-t"
          style={{ width: "160px", pointerEvents: isLoading ? "none" : null }}
          onClick={() => addEditData()}
        >
          {isLoading ? (
            <LoadingOutlined />
          ) : (
            `${adminId ? "Edit" : "Add New"} Admin`
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminAddEditV2;
