import { useMutation, gql } from "@apollo/client";

// ## API สำหรับกดสั่งงาน
export const ADD_START_DEVICES = gql`
  mutation startDevice(
    $deviceName: String!
    $customer_id: String
    $customer_name: String
    $action_price: String!
    $action_via: String!
    $promotionId: String
    $mode: String
    $option: String
    $price_default: String!
  ) {
    startDevice(
      deviceName: $deviceName
      customer_id: $customer_id
      customer_name: $customer_name
      action_price: $action_price
      action_via: $action_via
      promotionId: $promotionId
      mode: $mode
      option: $option
      price_default: $price_default
    )
  }
`;

// ## API สำหรับ Add Site
export const ADD_SITE = gql`
mutation addSite(
  $admin_list: [String]
  $site_name: String
  $site_name_en: String
  $payment_method: String
  $pay_amount: String
  $side_type: String
  $site_sub_type: String
  $group_site_id: String
  $unit_size: String
  $province_id: String
  $zone: String
  $water_amount: String
  $water_type: String
  $elec_amount: String
  $elec_type: String
  $contact_end: String
  $contact_start: String
  $latitude: String
  $longitude: String
  $site_address: String
  $site_status: String
  $comment: String
  $tier: String
  $version: String
) {
  addSite(
      admin_list: $admin_list
      site_name: $site_name
      site_name_en: $site_name_en
      payment_method: $payment_method
      pay_amount: $pay_amount
      side_type: $side_type
      site_sub_type: $site_sub_type
      group_site_id: $group_site_id
      unit_size: $unit_size
      province_id: $province_id
      zone: $zone
      water_amount: $water_amount
      water_type: $water_type
      elec_amount: $elec_amount
      elec_type: $elec_type
      contact_end: $contact_end
      contact_start: $contact_start
      latitude: $latitude
      longitude: $longitude
      site_address: $site_address
      site_status: $site_status
      comment: $comment
      tier: $tier
      version: $version
  ) {
      admin_list
      site_washer_count
      site_dryer_count
      site_distance
      siteCode
      site_name
      site_name_en
      latitude
      longitude
      tier
      side_type
      site_sub_type
      group_site_id
      img_site
      site_address
      payment_method
      site_status
      comment
      contact_end
      contact_start
      elec_amount
      elec_type
      pay_amount
      province_id
      unit_size
      water_amount
      water_type
      zone
      createdAt
      id
      tier
      version
  }
}
`;

// ## API สำหรับ Edit Site
export const UPDATE_SITE = gql`
  mutation updateSite(
    $siteId: ID!
    $admin_list: [String]
    $site_name: String
    $site_name_en: String
    $payment_method: String
    $pay_amount: String
    $side_type: String
    $site_sub_type: String
    $group_site_id: String
    $unit_size: String
    $province_id: String
    $zone: String
    $water_amount: String
    $water_type: String
    $elec_amount: String
    $elec_type: String
    $contact_end: String
    $contact_start: String
    $latitude: String
    $longitude: String
    $site_address: String
    $site_status: String
    $comment: String
    $tier: String
    $version: String
  ) {
    updateSite(
      siteId: $siteId
      admin_list: $admin_list
      site_name: $site_name
      site_name_en: $site_name_en
      payment_method: $payment_method
      pay_amount: $pay_amount
      side_type: $side_type
      site_sub_type: $site_sub_type
      group_site_id: $group_site_id
      unit_size: $unit_size
      province_id: $province_id
      zone: $zone
      water_amount: $water_amount
      water_type: $water_type
      elec_amount: $elec_amount
      elec_type: $elec_type
      contact_end: $contact_end
      contact_start: $contact_start
      latitude: $latitude
      longitude: $longitude
      site_address: $site_address
      site_status: $site_status
      comment: $comment
      tier: $tier
      version: $version
    ) {
      id
      admin_list
      site_washer_count
      site_dryer_count
      site_distance
      siteCode
      site_name
      site_name_en
      latitude
      longitude
      side_type
      site_sub_type
      group_site_id
      img_site
      site_address
      payment_method
      site_status
      comment
      contact_end
      contact_start
      elec_amount
      elec_type
      pay_amount
      province_id
      unit_size
      water_amount
      water_type
      zone
      createdAt
      tier
      version
    }
  }
`;

// ## API สำหรับ Delete Site
export const DELETE_SITE = gql`
  mutation deleteSite($siteId: ID!) {
    deleteSite(siteId: $siteId)
  }
`;

// ## API สำหรับ Add Machine
export const ADD_MACHINE = gql`
  mutation addMachine(
    $siteId: ID!
    $deviceName: String!
    $machine_name: String
    $brand: String
    $machine_model: String
    $machine_group: String
    $machine_sub_group: String
    $machine_no: String
    $price_default: String
    $time_default: String
    $coin_get: [String]
    $start_period: String
    $end_warranty: String
    $circuit: String
    $machine_run_mode: [String]
    $machine_pic: String
    $machine_order: String
  ) {
    addMachine(
      siteId: $siteId
      deviceName: $deviceName
      machine_name: $machine_name
      brand: $brand
      machine_model: $machine_model
      machine_group: $machine_group
      machine_sub_group: $machine_sub_group
      machine_no: $machine_no
      price_default: $price_default
      time_default: $time_default
      coin_get: $coin_get
      start_period: $start_period
      end_warranty: $end_warranty
      circuit: $circuit
      machine_run_mode: $machine_run_mode
      machine_pic: $machine_pic
      machine_order: $machine_order
    ) {
      id
      siteId
      deviceName
      machine_group
      machine_sub_group
      machine_model
      machine_name
      site_name
      customer_name
      time_default
      price_default
      location_history
      machine_pic
      machine_no
      machine_order
      promotion_type
      brand
      coin_get
      start_period
      end_warranty
      circuit
      device_status
      device_updateAt
      machine_run_mode
      createdAt
      machine_group_id
      machine_sub_group_id
    }
  }
`;

// ## API สำหรับ Edit Machine
// export const UPDATE_MACHINE = gql`
// mutation updateMachine(
//   $machineId: ID!
//   $siteId: ID!
//   $deviceName: String!
//   $machine_name: String!
//   $brand: String!
//   $machine_model: String!
//   $machine_group: String!
//   $machine_sub_group: String!
//   $machine_no: String!
//   $price_default: String!
//   $time_default: String!
//   $coin_get: [String!]
//   $start_period: String!
//   $end_warranty: String!
//   $circuit: String!
//   $machine_run_mode: [String!]
//   $machine_pic: String!
//   $machine_order: String!
//   $machine_sub_group_id : String!
//   $machine_group_id: String!
//   $isLGFlow: Boolean
// ) {
//   updateMachine(
//       machineId: $machineId
//       siteId: $siteId
//       deviceName: $deviceName
//       machine_name: $machine_name
//       brand: $brand
//       machine_model: $machine_model
//       machine_group: $machine_group
//       machine_sub_group: $machine_sub_group
//       machine_no: $machine_no
//       price_default: $price_default
//       time_default: $time_default
//       coin_get: $coin_get
//       start_period: $start_period
//       end_warranty: $end_warranty
//       circuit: $circuit
//       machine_run_mode: $machine_run_mode
//       machine_pic: $machine_pic
//       machine_order: $machine_order
//       machine_sub_group_id:$machine_sub_group_id
//       machine_group_id: $machine_group_id
//       isLGFlow: $isLGFlow
//   ) {
//       id
//       siteId
//       deviceName
//       machine_group
//       machine_sub_group
//       machine_model
//       machine_name
//       site_name
//       customer_name
//       time_default
//       price_default
//       location_history
//       machine_run_mode
//       machine_pic
//       machine_no
//       machine_order
//       promotion_type
//       brand
//       coin_get
//       start_period
//       end_warranty
//       circuit
//       device_status
//       device_updateAt
//       createdAt
//       machine_group_id
//       machine_sub_group_id
//       lg_device {
//         isLGFlow
//       }
//   }
// }
// `;
export const UPDATE_MACHINE = gql`
mutation updateMachine(
  $machineId: ID!
  $siteId: ID!
  $deviceName: String!
  $machine_name: String!
  $brand: String!
  $machine_model: String!
  $machine_group: String!
  $machine_sub_group: String!
  $machine_no: String!
  $price_default: String!
  $time_default: String!
  $coin_get: [String!]
  $start_period: String!
  $end_warranty: String!
  $circuit: String!
  $machine_run_mode: [String!]
  $machine_pic: String!
  $machine_order: String!
  $machine_sub_group_id : String!
  $machine_group_id: String!
  $isLGFlow: Boolean
) {
  updateMachine(
      machineId: $machineId
      siteId: $siteId
      deviceName: $deviceName
      machine_name: $machine_name
      brand: $brand
      machine_model: $machine_model
      machine_group: $machine_group
      machine_sub_group: $machine_sub_group
      machine_no: $machine_no
      price_default: $price_default
      time_default: $time_default
      coin_get: $coin_get
      start_period: $start_period
      end_warranty: $end_warranty
      circuit: $circuit
      machine_run_mode: $machine_run_mode
      machine_pic: $machine_pic
      machine_order: $machine_order
      machine_sub_group_id:$machine_sub_group_id
      machine_group_id: $machine_group_id
      isLGFlow: $isLGFlow
  ) {
      id
      siteId
      deviceName
      machine_group
      machine_sub_group
      machine_model
      machine_name
      site_name
      customer_name
      time_default
      price_default
      location_history
      machine_run_mode
      machine_pic
      machine_no
      machine_order
      promotion_type
      brand
      coin_get
      start_period
      end_warranty
      circuit
      device_status
      device_updateAt
      createdAt
      machine_group_id
      machine_sub_group_id
  }
}
`;
// ## API สำหรับ Delete Machine
export const DELETE_MACHINE = gql`
  mutation deleteMachine($machineId: ID!) {
    deleteMachine(machineId: $machineId)
  }
`;

// ## API สำหรับ Add Admin
export const ADD_ADMIN = gql`
  mutation addAdmin(
    $name: String!
    $branch: [String]
    $username: String!
    $password: String!
    $confirmPassword: String!
    $email: String!
    $tel: String!
    $list: [String]
  ) {
    addAdmin(
      name: $name
      branch: $branch
      username: $username
      password: $password
      confirmPassword: $confirmPassword
      email: $email
      tel: $tel
      list: $list
    ) {
      id
      admin_branch
      admin_email
      admin_name
      admin_tel
      admin_user
      admin_password
      admin_role
      admin_token
      last_active
      createdAt
      menu_list
    }
  }
`;

// ## API สำหรับ Edit Admin
export const EDIT_ADMIN = gql`
  mutation updateAdmin(
    $adminId: ID!
    $name: String!
    $branch: [String]
    $username: String!
    $password: String!
    $email: String!
    $tel: String!
    $list: [String]
  ) {
    updateAdmin(
      adminId: $adminId
      name: $name
      branch: $branch
      username: $username
      password: $password
      email: $email
      tel: $tel
      list: $list
    ) {
      id
      admin_branch
      admin_email
      admin_name
      admin_tel
      admin_user
      last_active
      createdAt
      menu_list
    }
  }
`;

// ## API สำหรับ Delete Admin
export const DELETE_ADMIN = gql`
  mutation deleteAdmin($adminId: String!) {
    deleteAdmin(adminId: $adminId)
  }
`;

export const addTransaction = gql`
mutation addTransaction(
    $deviceName: String
    $action_by: String
    $run_time: String
    $run_mode: String
    $run_value: String
    $customer_name: String
    $time: String
    $device_startAt: String
    $device_stopAt: String
    $createAt: String
) {
    addTransaction(
        deviceName: $deviceName
        action_by: $action_by
        run_time: $run_time
        run_mode: $run_mode
        run_value: $run_value
        customer_name: $customer_name
        time: $time
        device_startAt: $device_startAt
        device_stopAt: $device_stopAt
        createAt: $createAt
    ) {
        id
        machineId
        site_name
        siteCode
        siteId
    }
}
`;
// ## API สำหรับ Login Admin
export const LOGIN_ADMIN = gql`
  mutation login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      id
      admin_branch
      site_id
      menu_list
      admin_email
      admin_name
      admin_user
      admin_token
      last_active
      createdAt
    }
  }
`;

// ## API สำหรับ Add Customer
export const ADD_CUSTOMER = gql`
  mutation addCustomer(
    $name: String
    $tel: String
    $email: String
    $bday: String
    $gender: String
    $username: String
    $password: String
  ) {
    addCustomer(
      name: $name
      tel: $tel
      email: $email
      bday: $bday
      gender: $gender
      username: $username
      password: $password
    )
  }
`;

// ## API สำหรับ Edit Customer
export const EDIT_CUSTOMER = gql`
  mutation update(
    $customerId: String!
    $name: String
    $tel: String
    $email: String
    $bday: String
    $gender: String
    $username: String
    $password: String
  ) {
    updateCustomer(
      customerId: $customerId
      name: $name
      tel: $tel
      email: $email
      bday: $bday
      gender: $gender
      username: $username
      password: $password
    ) {
      id
      credit
      customer_name
      customer_tel
      customer_email
      customer_bday
      customer_gender
      customer_username
      customer_password
      last_active
    }
  }
`;

// ## API สำหรับ Delete Customer
export const DELETE_CUSTOMER = gql`
  mutation deleteCustomer($customerId: String) {
    deleteCustomer(customerId: $customerId)
  }
`;

// ## API สำหรับ Delete History
export const DELETE_HISTORY = gql`
  mutation deleteHistory($historyId: ID!) {
    deleteHistory(historyId: $historyId)
  }
`;
// ## API สำหรับ Delete transation
export const DELETE_Transaction = gql`
  mutation deleteTransaction($historyId: ID!) {
    deleteTransaction(historyId: $historyId)
  }
`;
// ## API สำหรับ setConfigMachine
export const SET_CONFIG_MACHINE = gql`
mutation SetConfigMachine( $deviceName: String!
  $value: String!
  $option: String!) {
  setConfigMachine(
      deviceName: $deviceName,
      value: $value,
      option: $option
  )
}
`;

// ## API สำหรับ Update lG Washer Settings
export const UPDATE_LG_WASHER_SETTINGS = gql`
  mutation Mutation(
    $lgMachineId: ID!,
    $washingTime: [Int]!,
    $waterLevel: [Int]!,
    $rinsingTime: [Int]!,
    $rinsingCount: [Int]!,
    $spinSpeed: [String]!,
    $dropCount: Int!,
    $twinSpray: String!,
    $price: [Int]!,
    $coin: Int!,
    $addSuperWash: Int!,
    $nonStopRinsing: Boolean!
  ) {
    updateLGWasherSettings(
      lgMachineId: $lgMachineId,
      washingTime: $washingTime,
      waterLevel: $waterLevel,
      rinsingTime: $rinsingTime,
      rinsingCount: $rinsingCount,
      spinSpeed: $spinSpeed,
      dropCount: $dropCount,
      twinSpray: $twinSpray,
      price: $price,
      coin: $coin,
      addSuperWash: $addSuperWash,
      nonStopRinsing: $nonStopRinsing
    )
  }
`;

// ## API สำหรับ Update lG Dryer Settings
export const UPDATE_LG_DRYER_SETTINGS = gql`
  mutation Mutation(
    $lgMachineId: ID!,
    $dryCycleTime: [Int]!,
    $topOffTime: [Int]!,
    $topOff: String!,
    $sensingDry: String!,
    $regularPrice: [Int]!,
    $topOffPrice: [Int]!,
    $coin1: String!
  ) {
    updateLGDryerSettings(
      lgMachineId: $lgMachineId,
      dryCycleTime: $dryCycleTime,
      topOffTime: $topOffTime,
      topOff: $topOff,
      sensingDry: $sensingDry,
      regularPrice: $regularPrice,
      topOffPrice: $topOffPrice,
      coin1: $coin1
    )
  }
`;

export const MutationGraphQLStartDevice = (
  query,
  deviceName,
  siteId,
  action_price
) => {
  const queryData = useMutation(query, {
    variables: {
      deviceName,
      siteId,
      action_price,
    },
  });
  return queryData;
};
