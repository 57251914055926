import axios from "../../config/axios";
import axiostw from '../../config/axios-tw';
const apiRPGetReportBySiteId = async (
  get_all,
  start_date,
  end_date,
  cur_page,
  per_page,
  keyword,
  site_id
) => {
  try {
    const result = await axios.get(
      `/report/site?get_all=${get_all}&start_date=${start_date}&end_date=${end_date}&cur_page=${cur_page}&per_page=${per_page}&keyword=${keyword}&site_id=${site_id}`
    );
    //console.log(result.data);
    return result && result.data;
  } catch (error) {
    return error && error.response && error.response.data;
  }
};

const apiRPGetReportNewBySiteId = async (
  get_all,
  start_date,
  end_date,
  cur_page,
  per_page,
  keyword,
  site_id
) => {
  try {
    const result = await axios.get(
      `/report_new/site?get_all=${get_all}&start_date=${start_date}&end_date=${end_date}&cur_page=${cur_page}&per_page=${per_page}&keyword=${keyword}&site_id=${site_id}`
    );
    return result && result.data;
  } catch (error) {
    return error && error.response && error.response.data;
  }
};
const getPaymentSCB = async (data) => {
  try {
    const result = await axiostw.post(
      `/Report_Pg/getPayment-SCB`,data
    );
    console.log(data);
    console.log(result.data);
    return result && result.data;
  } catch (error) {
    return error && error.response && error.response.data;
  }
};
const getPaymentSCB_groupsite = async (data) => {
  try {
    const result = await axiostw.post(
      `/Report_Pg/getPayment-SCB-site`,data
    );
    console.log(result.data);
    return result && result.data;
  } catch (error) {
    return error && error.response && error.response.data;
  }
};
const UploadPaymentSCB = async (data) => {
  try {
    const result = await axiostw.post(
      `/Report_Pg/upload_scbfile`,data
    );
    //console.log(result.data);
    return result && result.data;
  } catch (error) {
    return error && error.response && error.response.data;
  }
};
const getTransationcenter = async (data) => {
  try {
    const result = await axiostw.post(
      `/Report_Pg/Trensation_center`,data
    );
   // console.log(result.data);
    return result && result.data;
  } catch (error) {
    return error && error.response && error.response.data;
  }
};
const deleteTransationcenter = async (data) => {
  try {
    const result = await axiostw.post(
      `/Report_Pg/deleteTrensation_center`,data
    );
   // console.log(result.data);
    return result && result.data;
  } catch (error) {
    return error && error.response && error.response.data;
  }
};
const getTransationcenterReportSite = async (data) => {
  try {
    const result = await axiostw.post(
      `/Report_Pg/Trensation_center_Report`,data
    );
   // console.log(result.data);
    return result && result.data;
  } catch (error) {
    return error && error.response && error.response.data;
  }
};
export { apiRPGetReportBySiteId, apiRPGetReportNewBySiteId,deleteTransationcenter, getPaymentSCB, getPaymentSCB_groupsite , UploadPaymentSCB 
  , getTransationcenter , getTransationcenterReportSite };
