import axios from '../../config/axios';
import _axios from "axios";
import { baseURL2 } from "../../config/config";
const axios2 = _axios.create({ baseURL: baseURL2 });
const apiPRDGetProductByMachineSubTypeId = async (machine_type_id, machine_sub_type_id) => {
  try {

    const result = await axios.get(`/product/dropdown?machine_type_id=${machine_type_id}&machine_sub_type_id=${machine_sub_type_id}`)
    //console.log(result)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}
const apiPRDGetProductByMachineSaveV2 = async (machine_type_id, machine_sub_type_id) => {
  try {
    const result = await axios2.get(`product.php?machine_type_id=${machine_type_id}&machine_sub_type_id=${machine_sub_type_id}`)
    //console.log(result)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}
export {
  apiPRDGetProductByMachineSubTypeId,
  apiPRDGetProductByMachineSaveV2
}