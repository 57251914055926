import React, { useState, useEffect } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { DatePicker, Table, Input, Pagination } from "antd";
import "./Report.css";
import XLSX from "xlsx";
import { LoadingOutlined } from "@ant-design/icons";

import moment from "moment";
import "moment/locale/th";

import { numberWithCommas, getSearchParams } from "../../utils/function";
import { apiRPGetReportBySiteId } from "../../services/apis/report";
import {
  QueryGraphQL,
  QueryGraphQLById,
  QueryGraphQLBySiteId,
  GET_HISTORY_BY_SITE,
  GET_LIVE_MACHINE_BY_SITE,
  GET_SITES,
} from "../../resolvers/Query";
import usePagination from "../../hooks/usePagination";
import { useSelector } from "react-redux";

const { RangePicker } = DatePicker;

const ReportV2 = () => {
  const { siteId } = useParams();
  const history = useHistory();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const p = params.get("p");
  const ttl = params.get("ttl");
  const start = params.get("start");
  const end = params.get("end");
  const sw = params.get("sw");

  const menuList = (localStorage.getItem("list") || "").split(",");
  const role = (localStorage.getItem("user_role") || "").toUpperCase();

  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(Number(p) || 1);
  const [perPage, setPerPage] = useState(Number(ttl) || 20);
  const [startDate, setStartDate] = useState(
    start
      ? moment(start).startOf("day").format("YYYY-MM-DD HH:mm:ss")
      : moment().startOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [endDate, setEndDate] = useState(
    end
      ? moment(end).endOf("day").format("YYYY-MM-DD HH:mm:ss")
      : moment().endOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [searchWord, setSearchWord] = useState(sw || "");
  const [searchButton, setSearchButton] = useState(false);
  const [waitingForCSV, setWaitingForCSV] = useState(false);

  const [sortData, setSortData] = useState({
    machine: undefined,
    date: undefined,
  }); // ascend, descend
  const { data } = QueryGraphQLById(
    GET_HISTORY_BY_SITE,
    siteId,
    startDate,
    endDate
  );
  const dataWorking = QueryGraphQLBySiteId(GET_LIVE_MACHINE_BY_SITE, siteId);
  const [dataList, setDataList] = useState([]);
  const [machineWorkingList, setMachineWorkingList] = useState([]);
  const dataHistory = data?.getHistoryBySite;
  const checkDataPage = usePagination(dataHistory, currentPage, perPage);
  const site_name = localStorage.getItem("site_name");
  const site_code = localStorage.getItem("site_code");
  const dataGetSite = QueryGraphQL(GET_SITES);
  const dataSites = dataGetSite?.data?.getSites;
  const [siteName, setSiteName] = useState("");
  const [siteCode, setSiteCode] = useState("");

  console.log("checkDataPage---🐧🐧", dataList, machineWorkingList);
  useEffect(() => {
    setDataList(checkDataPage ?? []);
  }, [currentPage, perPage, dataHistory]);

  useEffect(() => {
    if (siteId && dataSites?.length > 0) {
      dataSites.map((item) => {
        if (item?.id === siteId) {
          setSiteCode(item?.siteCode ?? "");
          setSiteName(item?.site_name ?? "");
        }
      });
    }
  }, [siteId, dataSites]);

  useEffect(() => {
    if (dataWorking?.data?.getLiveMachineBySite) {
      setMachineWorkingList(dataWorking?.data?.getLiveMachineBySite);
    }
  }, [dataWorking]);

  const columnsGraphQL = [
    {
      title: "#",
      ellipsis: true,
      align: "center",
      width: "5%",
      render: (data, record, index) => (
        <div>{index + 1 + (currentPage - 1) * perPage}</div>
      ),
    },
    {
      title: "เครื่อง",
      ellipsis: true,
      render: (data) => <div>{data.machine_name || "-"}</div>,
      key: "machine",
      sorter: true,
      sortOrder: sortData.machine,
    },
    {
      title: "ลูกค้า",
      ellipsis: true,
      render: (data) => (
        <div>{data?.customer_name?.length ? data?.customer_name : "-"}</div>
      ),
    },
    {
      title: "ชำระโดย",
      ellipsis: true,
      align: "center",
      render: (data) => (
        <div>{data.action_by == "NOP" ? "Coin" : data.action_by || "-"}</div>
      ),
    },
    {
      title: "ราคา",
      ellipsis: true,
      align: "center",
      render: (data) => <div>{numberWithCommas(data.run_value || 0, 2)}</div>,
    },
    {
      title: "วันที่",
      ellipsis: true,
      align: "center",
      render: (data) => (
        <div>
          {data.device_stopAt
            ? moment(data.device_stopAt)
                //.add(543, "years")
                .format("D MMM YYYY HH:mm:ss")
            : "-"}
        </div>
      ),
      key: "date",
      sorter: true,
      sortOrder: sortData.date,
    },
  ];

  const columnsMachineWorking = [
    {
      title: "#",
      ellipsis: true,
      align: "center",
      width: "5%",
      render: (data, record, index) => (
        <div>{index + 1 + (currentPage - 1) * perPage}</div>
      ),
    },
    {
      title: "เครื่อง",
      ellipsis: true,
      render: (data) => <div>{data.machine_name || "-"}</div>,
      key: "machine",
      sorter: true,
      sortOrder: sortData.machine,
    },
    {
      title: "ลูกค้า",
      ellipsis: true,
      render: (data) => (
        <div>{data?.customer_name?.length ? data?.customer_name : "-"}</div>
      ),
    },
    {
      title: "ชำระโดย",
      ellipsis: true,
      align: "center",
      render: (data) => (
        <div>{data.action_by == "NOP" ? "Coin" : data.action_by || "-"}</div>
      ),
    },
    {
      title: "ราคา",
      ellipsis: true,
      align: "center",
      render: (data) => (
        <div>{numberWithCommas(data.price_default || 0, 2)}</div>
      ),
    },
    {
      title: "วันที่",
      ellipsis: true,
      align: "center",
      render: (data) => (
        <div>
          {data.device_startAt
            ? moment(data.device_startAt)
                //.add(543, "years")
                .format("D MMM YYYY HH:mm:ss")
            : "-"}
        </div>
      ),
      key: "date",
      sorter: true,
      sortOrder: sortData.date,
    },
    {
      title: "",
      ellipsis: true,
      align: "center",
      render: (data) => <div>{data ? "กำลังทำงานอยู่" : ""}</div>,
      key: "date",
      sorter: true,
      sortOrder: sortData.date,
    },
  ];

  const toCSVFile = async () => {
    setWaitingForCSV(true);
    let csvData = [];

    if (dataHistory) {
      dataHistory?.map((data, idx) => {
        let resultRow = {};
        resultRow["ช่วงเวลาที่เลือก"] = `${moment(startDate)
         //.add(543, "years")
          .format("D MMM YYYY")} - ${moment(endDate)
          //.add(543, "years")
          .format("D MMM YYYY")}`;
        resultRow["#"] = idx + 1;
        resultRow["id"] = data.id;
        resultRow["เครื่อง"] = data.machine_name || "-";
        resultRow["เลขเครื่อง"] = data.deviceName || "-";
        resultRow["ลูกค้า"] = data?.customer_name?.length
          ? data?.customer_name
          : "-";
        resultRow["ชำระโดย"] =
          data.action_by[0] == "NOP" ? "Coin" : data.action_by[0] || "-" || "-";
        resultRow["ราคา"] = data.run_value || 0;
        resultRow["วันที่"] = data.createdAt
          ? moment(data.createdAt)
             // .add(543, "years")
              .format("D MMM YYYY HH:mm:ss")
          : "-";
        csvData.push(resultRow);
      });

      const toCSV = XLSX.utils.json_to_sheet(csvData);
      const wb = XLSX.utils.book_new();

      const fileName = "SITE_REPORT";
      XLSX.utils.book_append_sheet(wb, toCSV, fileName);
      XLSX.writeFile(wb, fileName + ".csv");

      setWaitingForCSV(false);
    }
  };

  const sortDataTable = (pagination, filters, sorter) => {
    setSortData({ ...sortData, [sorter.columnKey]: sorter.order });
  };

  return (
    <div>
      <div className="mg-bt-20 h-d-t-p">
        Report{" "}
        {site_name || siteName
          ? `: ${siteName ? siteName : site_name ?? ""} (${
              siteCode ? siteCode : site_code ?? ""
            })`
          : ""}
      </div>

      <div
        className={`report-container-grid report-container-grid-${role.toLowerCase()}-${!menuList.includes(
          "EXPORT"
        )}`}
      >
        <div
          className={`report-container-grid-${role.toLowerCase()}-${!menuList.includes(
            "EXPORT"
          )}-date`}
        >
          <RangePicker
            style={{ width: "100%" }}
            format={(date) =>
              moment(date)
              //.add(543, "years")
              .format("D MMM YYYY HH:mm")
            }
            allowClear={false}
            showTime={{ format: "HH:mm" }}
            value={[
              moment(startDate, "YYYY-MM-DD HH:mm:ss"),
              moment(endDate, "YYYY-MM-DD HH:mm:ss"),
            ]}
            onChange={(date) => {
              setStartDate(
                moment(date[0]).startOf("minute").format("YYYY-MM-DD HH:mm:ss")
              );
              setEndDate(
                moment(date[1]).endOf("minute").format("YYYY-MM-DD HH:mm:ss")
              );
            }}
            disabledDate={(current) =>
              role === "BRANCHADMIN"
                ? current && current < moment("2021-06-25").startOf("day")
                : null
            }
          />
        </div>
        <div>
          <Input
            placeholder="search .."
            value={searchWord}
            onChange={(e) => setSearchWord(e.target.value)}
            onPressEnter={() => {
              setCurrentPage(1);
              setSearchButton(!searchButton);
            }}
          />
        </div>
        <div
          className="f-c-c d-b-t-n-s d-bg-c-t dash-select-date-search"
          onClick={() => setSearchButton(!searchButton)}
        >
          <i className="bi bi-search" style={{ lineHeight: "0" }}></i>
        </div>
        {(role === "SUPERADMIN" || menuList.includes("EXPORT")) && (
          <div
            className={`f-c-c d-b-t-n-s d-bg-c-t h-32 report-container-grid-${role.toLowerCase()}-${!menuList.includes(
              "EXPORT"
            )}-export`}
            style={{ pointerEvents: waitingForCSV ? "none" : null }}
            onClick={() => toCSVFile()}
          >
            {waitingForCSV ? <LoadingOutlined /> : "Export CSV"}
          </div>
        )}
      </div>

      <div className="dash-big-title">รายละเอียดในการใช้งาน</div>

      <Table
        bordered
        size="small"
        loading={isLoading}
        rowKey={(record) => record.history_id}
        dataSource={dataList}
        columns={columnsGraphQL}
        pagination={false}
        scroll={{ x: true }}
        onChange={(pagination, filters, sorter) =>
          sortDataTable(pagination, filters, sorter)
        }
      />

      <div className="pgnt-st">
        <Pagination
          size="small"
          current={currentPage}
          onChange={(page) => setCurrentPage(page)}
          total={dataHistory?.length}
          // pageSize={dataList?.length ?? perPage}
          pageSize={perPage}
          showSizeChanger={false}
        />
      </div>

      <div className="dash-big-title">เครื่องกำลังทำงานอยู่ (Working)</div>
      <Table
        bordered
        size="small"
        loading={isLoading}
        rowKey={(record) => record.history_id}
        dataSource={machineWorkingList}
        columns={columnsMachineWorking}
        pagination={false}
        scroll={{ x: true }}
        onChange={(pagination, filters, sorter) =>
          sortDataTable(pagination, filters, sorter)
        }
      />
    </div>
  );
};

export default ReportV2;
