import React, { useState, useEffect } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Input, Table, Pagination, Tooltip, Modal } from 'antd';
import './Customer.css'
import XLSX from "xlsx";
import { LoadingOutlined } from '@ant-design/icons';

import moment from 'moment';
import 'moment/locale/th'

import { numberWithCommas, openNotification, getSearchParams } from '../../utils/function'
import { apiCTMGetCustomer, apiCTMDeleteCustomerById } from '../../services/apis/customer'

const CustomerList = () => {
  const history = useHistory();
  const location = useLocation();

  const params = new URLSearchParams(location.search)
  const p = params.get('p')
  const ttl = params.get('ttl')
  const sw = params.get('sw')

  const menuList = (localStorage.getItem('list') || '').split(',')
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(Number(p) || 1)
  const [perPage, setPerPage] = useState(Number(ttl) || 20)
  const [tableData, setTableData] = useState(undefined)
  const [searchWord, setSearchWord] = useState(sw || '')
  const [searchButton, setSearchButton] = useState(false)
  const [waitingForCSV, setWaitingForCSV] = useState(false)

  const [visible, setVisible] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalId, setModalId] = useState(undefined)
  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false)

  useEffect(() => {
    getTableData()
    history.push(`${location.pathname}${getSearchParams(['p', 'ttl', 'sw'], [currentPage, perPage, searchWord])}`)
  }, [currentPage, perPage, searchButton])

  const getTableData = async () => {
    setIsLoading(true)
    let customer = await apiCTMGetCustomer(false, currentPage, perPage, searchWord)
    if (customer && customer.status) {
      setIsLoading(false)
      setTableData(customer.result)
    }
  }

  const columns = [
    {
      title: '#',
      ellipsis: true,
      align: 'center',
      width: '5%',
      render: (data, record, index) => <div>{(index + 1) + ((currentPage - 1) * perPage)}</div>
    },
    {
      title: 'Name',
      ellipsis: true,
      render: (data) => <div>{data.customer_name || '-'}</div>
    },
    // {
    //   title: 'E-mail',
    //   ellipsis: true,
    //   render: (data) => <div>{data.customer_email || '-'}</div>
    // },
    {
      title: 'Phone',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{data.customer_tel || '-'}</div>
    },
    {
      title: 'Credit',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{numberWithCommas(data.credit || 0, 2)}</div>
    },
    {
      title: 'Last Active',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{data.last_active ? moment(data.last_active).add(543, 'years').format('D MMM YYYY HH:mm:ss') : '-'}</div>
    },
    {
      title: 'Manage',
      ellipsis: true,
      align: 'center',
      fixed: 'right',
      render: (data) => <div className="f-c-c tb-cl-a">
        <Tooltip placement="top" title="View">
          <div className="f-c-c d-b-t-n-s b-c-o-i" style={{ backgroundColor: '#FFCB05' }}
            onClick={e => {
              e.stopPropagation()
              history.push(`/customer/list/${data.customer_id}`)
            }}
          ><i className="fas fa-address-card"></i></div>
        </Tooltip>
        {menuList.includes('CUSTOMERMAN') && <Tooltip placement="top" title="Edit">
          <div className="f-c-c d-b-t-n-s b-c-o-i" style={{ backgroundColor: '#00ADEF' }}
            onClick={e => {
              e.stopPropagation()
              history.push(`/customer/list/edit/${data.customer_id}${getSearchParams(['p', 'ttl', 'sw'], [currentPage, perPage, searchWord])}`)
            }}
          ><i className="bi bi-pencil-fill"></i></div>
        </Tooltip>}
        {menuList.includes('CUSTOMERMAN') && <Tooltip placement="top" title="Delete">
          <div className="f-c-c d-b-t-n-s b-c-o-i" style={{ backgroundColor: '#ED2228' }}
            onClick={(e) => {
              e.stopPropagation()
              setModalId(data.customer_id)
              setModalTitle(data.customer_name)
              setVisible(true)
            }}
          ><i className="fas fa-trash"></i></div>
        </Tooltip>}
      </div>
    },
  ];

  const deleteData = async () => {
    if (!modalId) {
      openNotification('error', 'Failed !', 'Failed to delete this user.')
      return
    }

    setIsLoadingConfirm(true)
    let action = await apiCTMDeleteCustomerById(modalId)
    if (action && action.status) {
      openNotification('success', 'Success !', 'Delete this user successful.')
      setVisible(false)
      setIsLoadingConfirm(false)
      getTableData()
    } else {
      openNotification('error', 'Failed !', 'Failed to delete this user.')
      setIsLoadingConfirm(false)
    }
  }

  const toCSVFile = async () => {
    setWaitingForCSV(true)
    let data = await apiCTMGetCustomer(true, '', '', searchWord)
    let csvData = [];

    if (data && data.status) {
      (data.result.data_list || []).map((data, idx) => {
        let resultRow = {}
        resultRow['#'] = idx + 1
        resultRow['Name'] = data.customer_name || '-'
        resultRow['E-mail'] = data.customer_email || '-'
        resultRow['Phone'] = data.customer_tel || '-'
        resultRow['Credit'] = data.credit || '-'
        resultRow['Last Active'] = data.last_active || '-'
        csvData.push(resultRow);
      })

      const toCSV = XLSX.utils.json_to_sheet(csvData);
      const wb = XLSX.utils.book_new();

      const fileName = 'CUSTOMER_LIST'
      XLSX.utils.book_append_sheet(wb, toCSV, fileName);
      XLSX.writeFile(wb, fileName + ".csv");

      setWaitingForCSV(false)
    }
  }

  return (
    <div>
      <div className="mg-bt-20 h-d-t-p">Customer List</div>

      <div className={`pro-reward-header-grid pro-reward-header-grid-column-${!menuList.includes('CUSTOMERMAN')}`}>
        <div className={`pro-reward-header-grid-search-${!menuList.includes('CUSTOMERMAN')}`}>
          <Input
            placeholder="search .."
            value={searchWord}
            onChange={e => setSearchWord(e.target.value)}
            onPressEnter={() => {
              setCurrentPage(1)
              setSearchButton(!searchButton)
            }}
          />
        </div>
        <div className="f-c-c d-b-t-n-s d-bg-c-t"
          onClick={() => {
            setCurrentPage(1)
            setSearchButton(!searchButton)
          }}>
          <i className="bi bi-search" style={{ lineHeight: '0' }}></i>
        </div>
        {menuList.includes('CUSTOMERMAN') && <Link to="/customer/add">
          <div className="f-c-c d-b-t-n-s d-bg-c-t h-32" style={{ height: '100%' }}>Create</div>
        </Link>}
        <div className={`f-c-c d-b-t-n-s d-bg-c-t h-32 pro-reward-header-grid-export-${!menuList.includes('CUSTOMERMAN')}`}
          style={{ pointerEvents: waitingForCSV ? 'none' : null }}
          onClick={() => toCSVFile()}
        >
          {waitingForCSV ? <LoadingOutlined /> : 'Export CSV'}
        </div>
      </div>

      <Table bordered
        size="small"
        rowKey={record => record.customer_id}
        loading={isLoading}
        dataSource={tableData && tableData.data_list || []}
        columns={columns}
        pagination={false}
        scroll={{ x: true }}
      />

      <div className="pgnt-st">
        <Pagination
          size="small"
          current={currentPage}
          onChange={page => setCurrentPage(page)}
          total={tableData && tableData.max_total_rows}
          pageSize={perPage}
          showSizeChanger={false}
        />
      </div>

      <Modal
        title=""
        visible={visible}
        closable={false}
        footer={false}
        centered
      >
        <div className="m-t-i-c" style={{ color: '#FFCB05' }}>
          <i className="bi bi-exclamation-triangle-fill"></i>
        </div>
        <div className="m-t-t-q">Do you want to delete '{modalTitle}' ?</div>
        <div className="m-b-y-n-g">
          <div className="f-c-c d-b-t-n-s b-c-o-i" style={{ backgroundColor: '#cccccc' }} onClick={() => setVisible(false)}>Cancel</div>
          <div className="f-c-c d-b-t-n-s b-c-o-i"
            style={{ backgroundColor: '#3f4d67', pointerEvents: isLoadingConfirm ? 'none' : null }}
            onClick={() => deleteData()}
          >{isLoadingConfirm ? <LoadingOutlined /> : 'Confirm'}</div>
        </div>
      </Modal>

    </div>
  )
}

export default CustomerList
