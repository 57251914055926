import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import { Input, Table, Pagination, Tooltip, Modal } from 'antd';
import './Promotion.css'
import XLSX from "xlsx";
import { LoadingOutlined } from '@ant-design/icons';

import { numberWithCommas, openNotification, getSearchParams } from '../../utils/function'
import { apiPMTGetRedeem, apiPMTApproveRejectRedeem } from '../../services/apis/promotion'

const PromotionRedeemPage = () => {
  const history = useHistory();
  const location = useLocation();

  const params = new URLSearchParams(location.search)
  const p = params.get('p')
  const ttl = params.get('ttl')
  const sw = params.get('sw')

  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(Number(p) || 1)
  const [perPage, setPerPage] = useState(Number(ttl) || 20)
  const [tableData, setTableData] = useState(undefined)
  const [searchWord, setSearchWord] = useState(sw || '')
  const [searchButton, setSearchButton] = useState(false)
  const [waitingForCSV, setWaitingForCSV] = useState(false)

  const [visible, setVisible] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalId, setModalId] = useState(undefined)
  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false)

  useEffect(() => {
    getTableData()
    history.push(`${location.pathname}${getSearchParams(['p', 'ttl', 'sw'], [currentPage, perPage, searchWord])}`)
  }, [currentPage, perPage, searchButton])

  const getTableData = async () => {
    
    setIsLoading(true)
    let redeem = await apiPMTGetRedeem(false, currentPage, perPage, searchWord)
    if (redeem && redeem.status) {
      setIsLoading(false)
      setTableData(redeem.result)
    }
  }

  const columns = [
    {
      title: '#',
      ellipsis: true,
      align: 'center',
      width: '5%',
      render: (data, record, index) => <div>{(index + 1) + ((currentPage - 1) * perPage)}</div>
    },
    {
      title: 'Customer',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{data.customer || '-'}</div>
    },
    {
      title: 'Point',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{numberWithCommas(data.point || 0, 0)}</div>
    },
    {
      title: 'Type',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{data.type || '-'}</div>
    },
    {
      title: 'Reward',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{data.reward || '-'}</div>
    },
    {
      title: 'Piece',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{data.piece || '0'}</div>
    },
    {
      title: 'Name',
      ellipsis: true,
      render: (data) => <div>{data.first_name&&data.last_name ?  data.first_name+" " + data.last_name : '-'}</div>
    },
    {
      title: 'Tel',
      ellipsis: true,
      render: (data) => <div>{data.tel || '-'}</div>
    },
    {
      title: 'Address',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{data.address || '-'}</div>
    },
    {
      title: 'Status',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{data.status || '-'}</div>
    },
    {
      title: 'Manage',
      ellipsis: true,
      align: 'center',
      fixed: 'right',
      render: (data) => (data.status || '').toUpperCase() === 'APPROVE' || (data.status || '').toUpperCase() === 'REJECT'
        ? <div>-</div>
        : <div className="f-c-c tb-cl-a">
          <Tooltip placement="top" title="Approve">
            <div className="f-c-c d-b-t-n-s b-c-o-i" style={{ backgroundColor: '#00ADEF' }}
              onClick={(e) => {
                e.stopPropagation()
                setModalId(data._id)
                setModalTitle('Approve')
                setVisible(true)
              }}
            ><i className="bi bi-check-circle"></i></div>
          </Tooltip>
          <Tooltip placement="top" title="Reject">
            <div className="f-c-c d-b-t-n-s b-c-o-i" style={{ backgroundColor: '#ED2228' }}
              onClick={(e) => {
                e.stopPropagation()
                setModalId(data._id)
                setModalTitle('Reject')
                setVisible(true)
              }}
            ><i className="bi bi-x-circle"></i></div>
          </Tooltip>
        </div>
    },
  ];

  const approveReject = async () => {
    if (!modalId || !modalTitle) {
      openNotification('error', 'Failed !', 'Failed to action this redeem.')
      return
    }

    let data = {
      redemption_id: modalId,
      status: modalTitle.toUpperCase()
    }

    setIsLoadingConfirm(true)
    let action = await apiPMTApproveRejectRedeem(data)
    if (action && action.status) {
      openNotification('success', 'Success !', 'Action this redeem successful.')
      setVisible(false)
      setIsLoadingConfirm(false)
      getTableData()
    } else {
      openNotification('error', 'Failed !', 'Failed to action this redeem.')
      setIsLoadingConfirm(false)
    }
  }

  const toCSVFile = async () => {
    setWaitingForCSV(true)
    let data = await apiPMTGetRedeem(true, '', '', '')
    let csvData = [];

    if (data && data.status) {
      (data.result.data_list || []).map((data, idx) => {
        let resultRow = {}
        resultRow['#'] = idx + 1
        resultRow['Customer'] = data.customer || '-'
        resultRow['Point'] = data.point || 0
        resultRow['Reward'] = data.reward || '-'
        resultRow['Status'] = data.status || '-'
        resultRow['Type'] = data.type || '-'
        csvData.push(resultRow);
      })

      const toCSV = XLSX.utils.json_to_sheet(csvData);
      const wb = XLSX.utils.book_new();

      const fileName = 'REDEEM'
      XLSX.utils.book_append_sheet(wb, toCSV, fileName);
      XLSX.writeFile(wb, fileName + ".csv");

      setWaitingForCSV(false)
    }
  }

  return (
    <div>
      <div className="mg-bt-20 h-d-t-p">Promotion - Redeem</div>

      <div className="pro-reward-header-grid pro-reward-header-grid-column-true">
        <div className="pro-reward-header-grid-search-true">
          <Input
            placeholder="search .."
            value={searchWord}
            onChange={e => setSearchWord(e.target.value)}
            onPressEnter={() => {
              setCurrentPage(1)
              setSearchButton(!searchButton)
            }}
          />
        </div>
        <div className="f-c-c d-b-t-n-s d-bg-c-t"
          onClick={() => {
            setCurrentPage(1)
            setSearchButton(!searchButton)
          }}>
          <i className="bi bi-search" style={{ lineHeight: '0' }}></i>
        </div>
        <div className="f-c-c d-b-t-n-s d-bg-c-t h-32 pro-reward-header-grid-export-true"
          style={{ pointerEvents: waitingForCSV ? 'none' : null }}
          onClick={() => toCSVFile()}
        >
          {waitingForCSV ? <LoadingOutlined /> : 'Export CSV'}
        </div>
      </div>

      <Table bordered
        size="small"
        rowKey={record => record._id}
        loading={isLoading}
        dataSource={tableData && tableData.data_list || []}
        columns={columns}
        pagination={false}
        scroll={{ x: true }}
      />

      <div className="pgnt-st">
        <Pagination
          size="small"
          current={currentPage}
          onChange={page => setCurrentPage(page)}
          total={tableData && tableData.max_total_rows}
          pageSize={perPage}
          showSizeChanger={false}
        />
      </div>

      <Modal
        title=""
        visible={visible}
        closable={false}
        footer={false}
        centered
      >
        <div className="m-t-i-c" style={{ color: '#FFCB05' }}>
          <i className="bi bi-exclamation-triangle-fill"></i>
        </div>
        <div className="m-t-t-q">Confirm to {modalTitle} ?</div>
        <div className="m-b-y-n-g">
          <div className="f-c-c d-b-t-n-s b-c-o-i" style={{ backgroundColor: '#cccccc' }} onClick={() => setVisible(false)}>Cancel</div>
          <div className="f-c-c d-b-t-n-s b-c-o-i"
            style={{ backgroundColor: '#3f4d67', pointerEvents: isLoadingConfirm ? 'none' : null }}
            onClick={() => approveReject()}
          >{isLoadingConfirm ? <LoadingOutlined /> : 'Confirm'}</div>
        </div>
      </Modal>

    </div>
  )
}

export default PromotionRedeemPage
