import React, { useState, useEffect } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { Input, InputNumber, Select, DatePicker, Divider } from "antd";
import "./Machine.css";
import { LoadingOutlined } from "@ant-design/icons";

import moment from "moment";
import "moment/locale/th";

import {
  openNotification,
  getSearchParams,
  checkIfIdEqualToZero,
} from "../../utils/function";
import {
  apiMCHGetTypeBySiteId,
  apiMCHGetSubTypeByTypeId,
  apiMCHAddMachine,
  apiMCHEditMachineById,
  apiMCHGetMachineById,
  apiMCHGetBrand,
  apiMCHGetModel,
  apiMCHGetController,
} from "../../services/apis/machine";
import { apiPRDGetProductByMachineSubTypeId } from "../../services/apis/product";
import { apiSGetSite, apiSGetZoneBySiteId } from "../../services/apis/site";
import { ADD_MACHINE, UPDATE_MACHINE } from "../../resolvers/Mutation";
import { useMutation } from "@apollo/client";
import { QueryGraphQL, GET_SITES } from "../../resolvers/Query";

const { Option } = Select;

const MachineAddEditPage = () => {
  const { machineId } = useParams();
  const location = useLocation();
  const history = useHistory();

  const params = new URLSearchParams(location.search);
  const p = params.get("p");
  const ttl = params.get("ttl");
  const mcht = params.get("mcht");
  const s = params.get("s");
  const sw = params.get("sw");

  const [isLoading, setIsLoading] = useState(false);
  const [machine, setMachine] = useState(undefined);
  const [machineTypes, setMachineTypes] = useState([]);
  const [machineSubTypes, setMachineSubTypes] = useState([]);
  const [machineProducts, setMachineProducts] = useState([]);
  const [sites, setSites] = useState([]);
  const [zones, setZones] = useState([]);
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [controllers, setControllers] = useState([]);
  const [newSite, setNewSite] = useState("");
  const [productList, setProductList] = useState([]);

  const moneyAcceptable = [1, 2, 5, 10, 20, 50, 100, 500, 1000];

  const [isAlertName, setIsAlertName] = useState(false);
  const [isAlertType, setIsAlertType] = useState(false);
  const [isAlertBrand, setIsAlertBrand] = useState(false);
  const [isAlertModel, setIsAlertModel] = useState(false);
  const [isAlertSubType, setIsAlertSubType] = useState(false);
  const [isAlertMacAddress, setIsAlertMacAddress] = useState(false);
  const [isAlertPrice, setIsAlertPrice] = useState(false);
  const [isAlertMoneyAccept, setIsAlertMoneyAccept] = useState(false);
  const [isAlertMachineTime, setIsAlertMachineTime] = useState(false);
  const [isAlertSetUpDate, setIsAlertSetUpDate] = useState(false);
  const [isAlertOpenDate, setIsAlertOpenDate] = useState(false);

  //GraphQL
  const [addMachine, dataAddMachine] = useMutation(ADD_MACHINE);
  const [editMachine, dataEditMachine] = useMutation(UPDATE_MACHINE);
  const dataSiteList = QueryGraphQL(GET_SITES);
  const dataSites = dataSiteList?.data?.getSites;
  const [siteID, setSiteID] = useState("");

  useEffect(() => {
    if (machineId) getData();
    else {
      setMachine(undefined);
      setNewSite("");
      setIsAlertName(false);
      setIsAlertType(false);
      setIsAlertBrand(false);
      setIsAlertModel(false);
      setIsAlertSubType(false);
      setIsAlertMacAddress(false);
      setIsAlertPrice(false);
      setIsAlertMoneyAccept(false);
      setIsAlertMachineTime(false);
      setIsAlertSetUpDate(false);
      setIsAlertOpenDate(false);
    }
  }, [machineId]);

  const getData = async () => {
    let data = await apiMCHGetMachineById(machineId);
    if (data && data.status) {
      let machine = { ...data.result };
      machine.machine_value_acceptable = data.result.machine_value_acceptable
        .split(",")
        .map((list) => Number(list));
      machine.machine_time = data.result.machine_time / 60;
      setMachine(machine);
    }
  };

  useEffect(() => {
    const getStaticDropdown = async () => {
      let type = await apiMCHGetTypeBySiteId("");
      if (type && type.status) setMachineTypes(type.result || []);

      let site = await apiSGetSite();
      if (site && site.status) setSites(site.result || []);

      let brand = await apiMCHGetBrand();
      if (brand && brand.status) setBrands(brand.result || []);
    };
    getStaticDropdown();
  }, []);

  useEffect(() => {
    const getControllers = async () => {
      let controller = await apiMCHGetController(machineId || "");
      if (controller && controller.status)
        setControllers(controller.result || []);
    };
    getControllers();
  }, [machineId]);

  useEffect(() => {
    if (machine && machine.machine_type_id) {
      const getMachineSubTypes = async () => {
        let subType = await apiMCHGetSubTypeByTypeId(machine.machine_type_id);
        if (subType && subType.status) setMachineSubTypes(subType.result || []);
      };
      getMachineSubTypes();
    }
  }, [machine && machine.machine_type_id]);

  useEffect(() => {
    if (machine && machine.machine_type_id && machine.machine_sub_type) {
      const getMachineProducts = async () => {
        let product = await apiPRDGetProductByMachineSubTypeId(
          machine.machine_type_id,
          machine.machine_sub_type
        );
        if (product && product.status) {
          setMachineProducts(product.result || []);

          let newData = { ...machine };
          let newArrProList = [];
          let newArrProId = [];
          ((newData && newData.product_list) || []).map((pdl) =>
            (product.result || []).map((mp) => {
              if (pdl.product_id === mp.product_id) {
                newArrProList.push({
                  product_id: mp.product_id,
                  product_name: mp.product_name,
                  product_price: pdl.product_price,
                  machine_type_id: mp.machine_type_id,
                  machine_sub_type_id: mp.machine_sub_type_id,
                });
                newArrProId.push(mp.product_id);
              }
            })
          );
          newData.product_list = newArrProList;
          setMachine(newData);
          setProductList(newArrProId); // for display
        }
      };
      getMachineProducts();
    }
  }, [machine && machine.machine_type_id && machine.machine_sub_type]);

  useEffect(() => {
    if (checkIfIdEqualToZero(machine && machine.site_id)) {
      const getZone = async () => {
        let zone = await apiSGetZoneBySiteId(machine.site_id);
        if (zone && zone.status) setZones(zone.result || []);
      };
      getZone();
    }
  }, [machine && machine.site_id]);

  useEffect(() => {
    if (machine && machine.brand_id) {
      const getModel = async () => {
        let model = await apiMCHGetModel(machine.brand_id);
        if (model && model.status) setModels(model.result || []);
      };
      getModel();
    }
  }, [machine && machine.brand_id]);

  useEffect(() => {
    if (machine?.site_name && dataSites?.length > 0) {
      let idSite = "";
      dataSites.map((item) => {
        if (machine?.site_name == item?.site_name) {
          idSite = item.id;
        }
      });
      setSiteID(idSite);
    }
  }, [machine]);

  const addEditMachine = async () => {
    if (
      !(machine && machine.machine_name) ||
      !(machine && machine.machine_type_id) ||
      !(machine && machine.brand_id) ||
      !(machine && machine.model_id) ||
      !(machine && machine.machine_sub_type) ||
      !(machine && machine.machine_serial_no) ||
      isNaN(machine && machine.machine_price) ||
      !(machine && machine.machine_value_acceptable) ||
      isNaN(machine && machine.machine_time) ||
      !(machine && machine.machine_setup_date) ||
      !(machine && machine.machine_warranty_expire)
    ) {
      if (!(machine && machine.machine_name)) setIsAlertName(true);
      if (!(machine && machine.machine_type_id)) setIsAlertType(true);
      if (!(machine && machine.brand_id)) setIsAlertBrand(true);
      if (!(machine && machine.model_id)) setIsAlertModel(true);
      if (!(machine && machine.machine_sub_type)) setIsAlertSubType(true);
      if (!(machine && machine.machine_serial_no)) setIsAlertMacAddress(true);
      if (isNaN(machine && machine.machine_price)) setIsAlertPrice(true);
      if (!(machine && machine.machine_value_acceptable))
        setIsAlertMoneyAccept(true);
      if (isNaN(machine && machine.machine_time)) setIsAlertMachineTime(true);
      if (!(machine && machine.machine_setup_date)) setIsAlertSetUpDate(true);
      if (!(machine && machine.machine_warranty_expire))
        setIsAlertOpenDate(true);
    } else {
      setIsLoading(true);

      let data = { ...machine };
      data.machine_value_acceptable = machine.machine_value_acceptable.join();
      data.machine_setup_date = moment(machine.machine_setup_date).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      data.machine_warranty_expire = moment(
        machine.machine_warranty_expire
      ).format("YYYY-MM-DD HH:mm:ss");
      data.machine_time = machine.machine_time * 60;

      let newData = machineId
        ? await apiMCHEditMachineById(data)
        : await apiMCHAddMachine(data);

      if (machineId) {
        editMachine({
          variables: {
            machineId: machineId,
            siteId: siteID,
            deviceName: data.controller_name || "",
            machine_name: data.machine_name || "",
            brand: data.brand_name || "",
            machine_model: data.model_name || "",
            machine_group: data.machine_type_name || "",
            machine_sub_group: data.machine_sub_type_name || "",
            machine_no: data.machine_serial_no || "",
            price_default: data.machine_price + "",
            time_default: data.machine_time + "",
            coin_get: data.machine_value_acceptable || [""],
            start_period: data.machine_setup_date || "",
            end_warranty: data.machine_warranty_expire || "",
            circuit: data.circuit || "",
            machine_run_mode: data.machine_run_mode || [""],
            machine_pic: data.machine_pic || "",
          },
        });
      } else {
        addMachine({
          variables: {
            siteId: siteID ?? "",
            deviceName: data.controller_name + "" || "",
            machine_name: data.machine_name || "",
            brand: data.brand_name || "",
            machine_model: data.model_name || "",
            machine_group: data.machine_type_name || "",
            machine_sub_group: data.machine_sub_type_name || "",
            machine_no: data.machine_serial_no || "",
            price_default: data.machine_price + "",
            time_default: data.machine_time + "",
            coin_get: data.machine_value_acceptable || [""],
            start_period: data.machine_setup_date || "",
            end_warranty: data.machine_warranty_expire || "",
            circuit: data?.circuit || "",
            machine_run_mode: data.machine_run_mode || [""],
            machine_pic: data.machine_pic || "",
          },
        });
      }

      if (newData && newData.status) {
        setIsLoading(false);
        openNotification(
          "success",
          "Success !",
          `${machineId ? "Edit" : "Add new"} machine successful.`
        );
        setTimeout(
          () =>
            history.push(
              `/machine/list${getSearchParams(
                ["p", "ttl", "mcht", "s", "sw"],
                [p, ttl, mcht, s, sw]
              )}`
            ),
          1000
        );
      } else {
        setIsLoading(false);
        openNotification(
          "error",
          "Failed !",
          `Failed to ${machineId ? "edit" : "add new"} machine.`
        );
      }
    }
  };

  return (
    <div>
      <div className="mg-bt-20 h-d-t-p">
        Machine List - {machineId ? "Edit" : "Add"}
      </div>

      <div className="machine-add-container-grid-with-two">
        <div className="machine-add-container-grid-with-two-order-1">
          <div className="machine-add-container-grid">
            <div className="f-s-c">ชื่อเครื่อง</div>
            <div>
              <Input
                style={{ borderColor: isAlertName ? "#EC1C24" : null }}
                placeholder="ชื่อเครื่อง"
                value={(machine && machine.machine_name) || ""}
                onChange={(e) => {
                  setIsAlertName(false);
                  setMachine({ ...machine, machine_name: e.target.value });
                }}
              />
            </div>
          </div>
        </div>

        <div className="machine-add-container-grid-with-two-order-3">
          <div className="machine-add-brand-model-grid">
            <div className="f-s-c">Brand</div>
            <div className={(isAlertBrand && "antd-sl-al-bd") || ""}>
              <Select
                style={{ width: "100%" }}
                placeholder="Brand"
                value={machine && machine.brand_id}
                onChange={(value, data) => {
                  setIsAlertBrand(false);
                  setMachine({
                    ...machine,
                    brand_id: value,
                    brand_name: data.brandName,
                    model_id: undefined,
                  });
                }}
              >
                {brands.map((brand, idx) => (
                  <Option key={idx} value={brand._id} brandName={brand.name}>
                    {brand.name}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>

        <div className="machine-add-container-grid-with-two-order-2">
          <div className="machine-add-container-grid">
            <div className="f-s-c">ประเภทเครื่อง</div>
            <div className={(isAlertType && "antd-sl-al-bd") || ""}>
              <Select
                style={{ width: "100%" }}
                placeholder="ประเภทเครื่อง"
                value={machine && machine.machine_type_id}
                onChange={(value, data) => {
                  setIsAlertType(false);
                  setMachine({
                    ...machine,
                    machine_type_id: value,
                    machine_type_name: data.machineTypeName,
                    machine_sub_type: undefined,
                    product_list: [],
                  });
                }}
              >
                {machineTypes.map((type, idx) => (
                  <Option
                    key={idx}
                    value={type.machine_type_id}
                    machineTypeName={type.machine_type_name}
                  >
                    {type.machine_type_name}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>

        <div className="machine-add-container-grid-with-two-order-4">
          <div className="machine-add-brand-model-grid">
            <div className="f-s-c">Model</div>
            <div className={(isAlertModel && "antd-sl-al-bd") || ""}>
              <Select
                style={{ width: "100%" }}
                placeholder="Model"
                disabled={!(machine && machine.brand_id)}
                value={machine && machine.model_id}
                onChange={(value, data) => {
                  setIsAlertModel(false);
                  setMachine({
                    ...machine,
                    model_id: value,
                    model_name: data.modelName,
                  });
                }}
              >
                {models.map((model, idx) => (
                  <Option key={idx} value={model._id} modelName={model.name}>
                    {model.name}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
      </div>

      <div className="machine-add-container-grid">
        <div className="f-s-c">ประเภทเครื่อง - ย่อย</div>
        <div className={(isAlertSubType && "antd-sl-al-bd") || ""}>
          <Select
            style={{ width: "100%" }}
            placeholder="ประเภทเครื่อง - ย่อย"
            disabled={!(machine && machine.machine_type_id)}
            value={(machine && machine.machine_sub_type) || undefined}
            onChange={(value, data) => {
              setIsAlertSubType(false);
              setMachine({
                ...machine,
                machine_sub_type: value,
                machine_sub_type_name: data.machineSubTypeName,
                product_list: [],
              });
            }}
          >
            {machineSubTypes.map((subType, idx) => (
              <Option
                key={idx}
                value={subType.machine_sub_type_id}
                machineSubTypeName={subType.machine_sub_type_name}
              >
                {subType.machine_sub_type_name}
              </Option>
            ))}
          </Select>
        </div>

        <div className="f-s-c">รหัสเครื่อง ( SN )</div>
        <div>
          <Input
            style={{ borderColor: isAlertMacAddress ? "#EC1C24" : null }}
            placeholder="รหัสเครื่อง ( SN )"
            value={(machine && machine.machine_serial_no) || ""}
            onChange={(e) => {
              setIsAlertMacAddress(false);
              setMachine({ ...machine, machine_serial_no: e.target.value });
            }}
          />
        </div>

        <div className="f-s-c">ราคาเครื่อง ( เริ่มต้น )</div>
        <div>
          <InputNumber
            min={0}
            step={0.01}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            style={{
              width: "100%",
              borderColor: isAlertPrice ? "#EC1C24" : null,
            }}
            placeholder="ราคาเครื่อง ( เริ่มต้น )"
            value={machine && machine.machine_price}
            onChange={(value) => {
              setIsAlertPrice(false);
              setMachine({
                ...machine,
                machine_price: Math.round(value * 100) / 100 || 0,
              });
            }}
          />
        </div>

        <div className="f-s-c">เงินที่รับ</div>
        <div
          className={`pst-rlt ${(isAlertMoneyAccept && "antd-sl-al-bd") || ""}`}
        >
          <Select
            style={{ width: "100%" }}
            placeholder="เงินที่รับ"
            mode="multiple"
            showArrow
            value={(machine && machine.machine_value_acceptable) || []}
            onChange={(value) => {
              setIsAlertMoneyAccept(false);
              setMachine({
                ...machine,
                machine_value_acceptable: value.sort((a, b) => a - b),
              });
            }}
          >
            {moneyAcceptable.map((money, idx) => (
              <Option key={idx} value={money}>
                {money}
              </Option>
            ))}
          </Select>
          <div className="s-f-o-i-p">บาท</div>
        </div>

        <div className="f-s-c">เวลาเครื่องทำงาน</div>
        <div className="pst-rlt">
          <InputNumber
            min={0}
            step={1}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            style={{
              width: "100%",
              borderColor: isAlertMachineTime ? "#EC1C24" : null,
            }}
            placeholder="เวลาเครื่องทำงาน"
            value={machine && machine.machine_time}
            onChange={(value) => {
              setIsAlertMachineTime(false);
              setMachine({ ...machine, machine_time: Math.round(value) || 0 });
            }}
          />
          <div className="s-f-o-i-p">นาที</div>
        </div>

        <div className="f-s-c">ลำดับการแสดง</div>
        <div>
          <InputNumber
            min={1}
            step={1}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            style={{ width: "100%" }}
            placeholder="ลำดับการแสดง"
            value={machine && machine.machine_order}
            onChange={(value) =>
              setMachine({ ...machine, machine_order: Math.round(value) || 1 })
            }
          />
        </div>

        <div className="f-s-c">วันที่รับเครื่อง</div>
        <div>
          <DatePicker
            style={{
              width: "100%",
              borderColor: isAlertSetUpDate ? "#EC1C24" : null,
            }}
            placeholder="วันที่รับเครื่อง"
            value={
              machine &&
              machine.machine_setup_date &&
              moment(machine.machine_setup_date)
            }
            onChange={(date) => {
              setIsAlertSetUpDate(false);
              setMachine({ ...machine, machine_setup_date: date });
            }}
            format={(date) =>
              moment(date).add(543, "years").format("D MMM YYYY")
            }
            allowClear={false}
          />
        </div>

        <div className="f-s-c">วันที่สิ้นสุดรับประกัน</div>
        <div>
          <DatePicker
            style={{
              width: "100%",
              borderColor: isAlertOpenDate ? "#EC1C24" : null,
            }}
            placeholder="วันที่สิ้นสุดรับประกัน"
            value={
              machine &&
              machine.machine_warranty_expire &&
              moment(machine.machine_warranty_expire)
            }
            onChange={(date) => {
              setIsAlertOpenDate(false);
              setMachine({ ...machine, machine_warranty_expire: date });
            }}
            format={(date) =>
              moment(date).add(543, "years").format("D MMM YYYY")
            }
            allowClear={false}
          />
        </div>

        <div className="f-s-c">แผนควบคุม</div>
        <div>
          <Select
            style={{ width: "100%" }}
            placeholder="แผนควบคุม"
            showSearch
            value={machine && machine.controller_id}
            onChange={(value, data) =>
              setMachine({
                ...machine,
                controller_id: value,
                controller_name: data.machine_no,
              })
            }
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {controllers.map((controller, idx) => (
              <Option
                key={idx}
                value={controller.controller_id}
                machine_no={controller.machine_no}
              >
                {controller.machine_no}
              </Option>
            ))}
          </Select>
        </div>

        <div className="f-s-c">สถานที่</div>
        <div>
          <Select
            style={{ width: "100%" }}
            showSearch
            dropdownRender={(menu) => (
              <div>
                {menu}
                <Divider style={{ margin: "4px 0" }} />
                <div className="machine-add-dropdown-add-new-grid">
                  <div>
                    <Input
                      placeholder="New Site"
                      value={newSite}
                      onChange={(e) => setNewSite(e.target.value)}
                      onPressEnter={(e) => {
                        e.stopPropagation();
                        setSites([
                          ...sites,
                          { site_id: newSite, site_name: newSite },
                        ]);
                      }}
                    />
                  </div>
                  <div
                    className="f-c-c d-b-t-n-s d-bg-c-t"
                    onClick={() =>
                      setSites([
                        ...sites,
                        {
                          site_id: newSite,
                          site_name: newSite,
                          site_new: true,
                        },
                      ])
                    }
                  >
                    + Add
                  </div>
                </div>
              </div>
            )}
            placeholder="สถานที่"
            value={machine && machine.site_id}
            onChange={(value, data) =>
              setMachine({
                ...machine,
                site_id: value,
                zone_id: undefined,
                site_name: data.site_name,
              })
            }
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {sites.map((site, idx) => (
              <Option key={idx} value={site.site_id} site_name={site.site_name}>
                {site.site_name}
              </Option>
            ))}
          </Select>
        </div>

        <div className="f-s-c">โซน</div>
        <div>
          <Select
            style={{ width: "100%" }}
            placeholder="โซน"
            disabled={!checkIfIdEqualToZero(machine && machine.site_id)}
            value={machine && machine.zone_id}
            onChange={(value) => setMachine({ ...machine, zone_id: value })}
          >
            {zones.map((zone, idx) => (
              <Option key={idx} value={zone.zone_id}>
                {zone.zone_name}
              </Option>
            ))}
          </Select>
        </div>

        <div className="f-s-c">สินค้า</div>
        <div>
          <Select
            style={{ width: "100%" }}
            placeholder="สินค้า"
            disabled={
              !(machine && machine.machine_type_id && machine.machine_sub_type)
            }
            mode="multiple"
            showArrow
            value={productList || undefined}
            onChange={(value) => {
              setProductList(value); // for display

              let productListCopy = [...machine.product_list];
              let productListChange = [];
              let productList = [];

              value.map((pid) =>
                machineProducts.map((mp) =>
                  pid === mp.product_id ? productListChange.push(mp) : ""
                )
              );

              productListChange.map((plch) => {
                let found = productListCopy.find(
                  (plcp) => plcp.product_id === plch.product_id
                );
                if (!found) {
                  plch.product_price = 0;
                  productList.push(plch);
                } else productList.push(found);
              });

              setMachine({ ...machine, product_list: productList });
            }}
          >
            {machineProducts.map((product, idx) => (
              <Option key={idx} value={product.product_id}>
                {product.product_name}
              </Option>
            ))}
          </Select>
        </div>

        <div></div>
        <div>
          {((machine && machine.product_list) || []).map((product, idx) => (
            <div key={idx} className="machine-add-product-grid">
              <div className="f-s-c">{product.product_name}</div>
              <div>
                <InputNumber
                  min={0}
                  step={1}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  style={{ width: "100%" }}
                  placeholder="ราคา"
                  value={product.product_price || 0}
                  onChange={(value) => {
                    let newMachine = { ...machine };
                    newMachine.product_list[idx].product_price =
                      Math.round(value);
                    setMachine(newMachine);
                  }}
                />
              </div>
              <div className="f-s-c">บาท</div>
              <div
                className="f-c-c machine-add-product-icon"
                onClick={() => {
                  let filterProductList = productList.filter(
                    (pl) => pl !== product.product_id
                  );
                  setProductList(filterProductList);

                  let filterProductListMachine = machine.product_list.filter(
                    (mpl) => mpl.product_id !== product.product_id
                  );
                  let machineCopy = { ...machine };
                  machineCopy.product_list = filterProductListMachine;
                  setMachine(machineCopy);
                }}
              >
                <i className="bi bi-x-circle-fill"></i>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="f-c-c s-e-b">
        <div
          className="f-c-c d-b-t-n-s d-bg-c-t"
          style={{ width: "170px", pointerEvents: isLoading ? "none" : null }}
          onClick={() => addEditMachine()}
        >
          {isLoading ? (
            <LoadingOutlined />
          ) : (
            `${machineId ? "Edit" : "Add New"} Machine`
          )}
        </div>
      </div>
    </div>
  );
};

export default MachineAddEditPage;
