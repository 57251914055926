import React, { useState, useEffect } from 'react'
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { Input, InputNumber, Select, DatePicker } from 'antd';
import './Broadcast.css'
// import { LoadingOutlined } from '@ant-design/icons';

import moment from 'moment';
import 'moment/locale/th'

import { getGenderList, getCareerList, getOSList } from '../../config/config'
import { numberWithCommas, openNotification, getSearchParams } from '../../utils/function'
import { apiBRCGetBroadcastById } from '../../services/apis/broadcast'
// import { apiSGetSite } from '../../services/apis/site'

const { Option } = Select;

const BroadcastView = () => {
  const { broadcastId } = useParams();
  const location = useLocation();
  const history = useHistory();

  const params = new URLSearchParams(location.search)
  const p = params.get('p')
  const ttl = params.get('ttl')
  const start = params.get('start')
  const end = params.get('end')
  const sw = params.get('sw')

  const [broadcast, setBroadcast] = useState(undefined)

  useEffect(() => {
    if (broadcastId) getData()
  }, [broadcastId])

  const getData = async () => {
    let data = await apiBRCGetBroadcastById(broadcastId)
    if (data && data.status) setBroadcast(data.result)
  }

  return (
    <div>
      <div className="mg-bt-20 h-d-t-p">Broadcast - View</div>

      <div className="customer-view-info">
        <div className="f-s-c">เวลาประกาศ</div>
        <div>{broadcast && broadcast.boardcast_date ? moment(broadcast.boardcast_date).add(543, 'years').format('D MMM YYYY HH:mm:ss') : '-'}</div>

        <div className="f-s-c">เพศ</div>
        <div>{broadcast && broadcast.gender_list && broadcast.gender_list.length
          ? (broadcast.gender_list || []).map((list, idx) => <div key={idx}>{getGenderList(list)}</div>)
          : '-'}</div>

        <div className="f-s-c">อายุ</div>
        {console.log(broadcast)}
        <div>{numberWithCommas(broadcast && broadcast.age_start || 0, 0)} ถึง {numberWithCommas(broadcast && broadcast.age_end || 0, 0)}</div>

        <div className="f-s-c">อาชีพ</div>
        <div>{broadcast && broadcast.career_list && broadcast.career_list.length
          ? (broadcast.career_list || []).map((list, idx) => <div key={idx}>{getCareerList(list)}</div>)
          : '-'}</div>

        <div className="f-s-c">สถานที่เคยใช้</div>
        <div>{broadcast && broadcast.site_name && broadcast.site_name.length
          ? (broadcast.site_name || []).map((list, idx) => <div key={idx}>{list || '-'}</div>)
          : '-'}</div>

        <div className="f-s-c">แอพ</div>
        <div>{broadcast && broadcast.os_list && broadcast.os_list.length
          ? (broadcast.os_list || []).map((list, idx) => <div key={idx}>{getOSList(list)}</div>)
          : '-'}</div>

        <div className="f-s-c">Potential Count</div>
        <div>{numberWithCommas(broadcast && broadcast.potential || 0, 0)}</div>
      </div>

    </div>
  )
}

export default BroadcastView
