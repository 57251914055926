import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Select, Checkbox } from 'antd';
import { Link, useHistory, useLocation } from "react-router-dom";
import './Modal.css'
import { LoadingOutlined } from '@ant-design/icons';

import { numberWithCommas, openNotification } from '../../utils/function'
import { changeModalMachineAction, changeReloadStatus } from '../../redux/machine'
import { apiCTMGetAllCustomer } from '../../services/apis/customer'
import { apiMCHActionMachine } from '../../services/apis/machine'
import { apiSGetSite } from "../../services/apis/site";
const { Option } = Select;

const ModalNewMachineSetsite = props => {
  const dispatch = useDispatch();
  const machine = useSelector(state => state.machine);
  const [customers, setCustomers] = useState([])
  const [modalActionData, setModalActionData] = useState(undefined)
  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false)
  const [initPrice, setInitPrice] = useState(0)
  const [isAlertCustomer, setIsAlertCustomer] = useState(false)
  const [calTimeDryer, setCalTimeDryer] = useState(0)
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const s = params.get("s");
  const role = (localStorage.getItem("user_role") || "").toUpperCase();
  const [sites, setSites] = useState([]);
  const [site, setSite] = useState(
    Number(s) ||
      (role === "SUPERADMIN" ? "" : localStorage.getItem("site_id")) ||
      ""
  );
  useEffect(() => {
    if (machine.isModalMachineAction) {
      let initCalTimeDryer = 0
      let propsData = props.modalActionData
      propsData.machine_price = Number(props.modalActionData.machine_price || 0)
      propsData.action_time = Number(props.modalActionData.action_time || 0)

      setCalTimeDryer(initCalTimeDryer)
      setInitPrice(propsData.machine_price)
      setModalActionData({ ...propsData })
      setIsAlertCustomer(false)
    }
  }, [machine.isModalMachineAction])

  useEffect(() => {
    const getStaticDropdown = async () => {
      let customer = await apiCTMGetAllCustomer('')
      if (customer && customer.status) setCustomers(customer.result || [])
      let site = await apiSGetSite();
      if (site && site.status) setSites(site.result || []);
    }
    getStaticDropdown()
  }, [])
  useEffect(() => {
    if (modalActionData) {
      if (modalActionData) return
    }
  }, [modalActionData])

  const actionData = async () => {
    console.log(site)
    console.log(modalActionData.machine_mac_address)
    // if (modalActionData && modalActionData._action_by === 'mobile' && !(modalActionData && modalActionData._customer_id)) setIsAlertCustomer(true)
    // else {
    //   let data = new FormData()
    //   data.append('no', modalActionData.machine_mac_address)
    //   data.append('action', 1)
    //   data.append('actionby', modalActionData._action_by)
    //   data.append('customer_id', modalActionData._customer_id || 1)
    //   data.append('actiontime', modalActionData.action_time || 0)
    //   data.append('price', modalActionData.machine_price || 0)
    //   data.append('mode', modalActionData._mode)
    //   data.append('option', modalActionData._options)

    //   let newData = await apiMCHActionMachine(data)
    //   if (newData && newData.status) {
    //     dispatch(changeReloadStatus())
    //     dispatch(changeModalMachineAction(false))
    //     setIsLoadingConfirm(false)
    //     openNotification('success', 'Success !', newData.message && `Success with status : ${newData.message}.` || 'Action machine successful.')
    //   } else {
    //     setIsLoadingConfirm(false)
    //     openNotification('error', 'Failed !', newData.message && `Failed with status : ${newData.message}.` || 'Failed to action machine.')
    //   }
    // }
  }

  return (
    <div>
      <Modal
        title=""
        visible={machine.isModalMachineAction}
        closable={false}
        footer={false}
        centered
      >
        <div className="m-t-t-q">Set Site Machine : {modalActionData && modalActionData.machine_mac_address || ''}</div>
        <div className="t-m-c-i-p-g mg-t-10">
          <div className="f-s-c">Site Name</div>
          <div>
            <div className={isAlertCustomer && 'antd-sl-al-bd' || ''}>
              <Select style={{ width: '100%' }}
                placeholder="Customer"
                showSearch
                value={site}
                onChange={(value) => setSite(value)}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
               <Option value="">All Site</Option>
              {sites.map((site, idx) => (
                <Option key={idx} value={site.site_id}>
                  {site.site_name}
                </Option>
              ))}
              </Select>
            </div>
          </div>
        </div>
        <div className="m-b-y-n-g">
          <div className="f-c-c d-b-t-n-s b-c-o-i" style={{ backgroundColor: '#cccccc' }} onClick={() => dispatch(changeModalMachineAction(false))}>Cancel</div>
          <div className="f-c-c d-b-t-n-s b-c-o-i"
            style={{ backgroundColor: '#3f4d67', pointerEvents: isLoadingConfirm ? 'none' : null }}
            onClick={() => actionData()}
          >{isLoadingConfirm ? <LoadingOutlined /> : 'Confirm'}</div>
        </div>
      </Modal>
    </div >
  )
}

export default ModalNewMachineSetsite
