import React, { useState, useEffect } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { Empty } from 'antd';

import Loading from '../../Loading/Loading'
import { numberWithCommas } from '../../../utils/function'
import { colors } from '../../../config/config'

Highcharts.setOptions({ lang: { thousandsSep: ',' } })

const ChartColumnStackDashboardNew = props => {
  const [chartTitle, setChartTitle] = useState('')
  const [categories, setCategories] = useState([])
  const [dataSeries, setDataSeries] = useState([])
  const [dataLabels, setDataLabels] = useState(true)
  const [stackLabels, setStackLabels] = useState(true)
  const [tooltipEnabled, setTooltipEnabled] = useState(false)
  const [digits, setDigits] = useState(0)

  useEffect(() => {
    
    let categories = [];
    let series = {}
    let data = [];
    props.chartReport.map(key => {
      categories.push(key.name)
      key.data.map((value => {
        if (!series[value.name]) series[value.name] = []
        series[value.name].push(value.value)
      }))
    })
    for (const property in series) {
      data.push({
        name: property,
        data: series[property]
      })
    }
    setCategories(categories)
    setDataSeries(data)
    setChartTitle(props.chartTitle)
    setDataLabels(props.dataLabels)
    setStackLabels(props.stackLabels)
    setTooltipEnabled(props.tooltipEnabled)
    setDigits(props.digits)
  }, [props.chartReport])

  const options = {
    chart: {
      type: 'column',
      backgroundColor: 'transparent',
      zoomType: 'x'
    },
    colors: colors.pastel,
    title: { text: '' },
    xAxis: {
      allowDecimals: true,
      categories: categories,
    },
    yAxis: {
      min: 0,
      title: {
        text: ''
      },
      stackLabels: {
        enabled: stackLabels,
      }
    },
    credits: {
      enabled: false
    },
    tooltip: {
      enabled: tooltipEnabled,
      formatter: function () {
        var currentPoint = this,
          currentSeries = currentPoint.series,
          chart = currentSeries.chart,
          stackValues = '';

        chart.series.forEach(function (series) {
          series.points.forEach(function (point) {
            if (currentSeries.userOptions.stack === series.userOptions.stack && currentPoint.key === point.category) {
              stackValues += `<span style="color:${series.color}">●</span> ${series.name} : ${numberWithCommas(point.y, digits)}<br/>`
            }
          })
        });

        return `<b>${this.x}</b><br />${stackValues}<b>Total : ${numberWithCommas(this.point.stackTotal, digits)}</b>`
      },
    },
    plotOptions: {
      column: {
        stacking: 'normal',
      },
      series: {
        dataLabels: {
          enabled: dataLabels,
          color: 'black',
          style: {
            textOutline: 'transparent'
          },
        },
        pointPadding: 0,
        groupPadding: 0,
        showInLegend: true,
      }
    },
    series: dataSeries
  }

  return (
    <div className="dash-chart-box-container">
      <div className="pst-rlt-h-100pc">
        {props.isLoading && <Loading />}
        <div className="dash-space-between">
          <div className="dash-chart-title">{chartTitle}</div>
          {dataSeries.length > 0 ?
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
            />
            : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ margin: '100px 0' }} />
          }
        </div>
      </div>
    </div>
  )
}

export default ChartColumnStackDashboardNew
