import React from 'react'
import './Loading.css'
import { LoadingOutlined } from '@ant-design/icons';

const Loading = () => {
  return (
    <div className="loading-container">
      <div className="loading-relative">
        <div className="loading-icon"><LoadingOutlined /></div>
        <div className="loading-bg-drop"></div>
      </div>
    </div>
  )
}

export default Loading
