import React, { useState, useEffect } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { Table, Pagination, DatePicker, Input } from "antd";
import "./Machine.css";
import XLSX from "xlsx";
// import { LoadingOutlined } from '@ant-design/icons';

import Loading from "../../components/Loading/Loading";
import ChartColumnStack from "../../components/Tools/Chart/ChartColumnStack";
// import ProgressPercent from '../../components/Tools/ProgressPercent'

import moment from "moment";
import "moment/locale/th";

import { numberWithCommas, getSearchParams } from "../../utils/function";
import {
  apiMCHGetMachineHistoryById,
  apiMCHGetMachineHistoryGraphById,
} from "../../services/apis/machine";
import {
  QueryGraphQLByPeriod,
  QueryGraphQLById,
  GET_HISTORY,
  GET_HISTORY_BY_MACHINE,
} from "../../resolvers/Query";
import usePagination from "../../hooks/usePagination";
import { useSelector } from "react-redux";

const { RangePicker } = DatePicker;

const MachineHistoryPageV2 = () => {
  const { machineId } = useParams();
  const history = useHistory();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const start = params.get("start");
  const end = params.get("end");

  const role = (localStorage.getItem("user_role") || "").toUpperCase();
  const [isLoadingGraph, setIsLoadingGraph] = useState(false);
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 20;
  const [startDate, setStartDate] = useState(
    start
      ? moment(start).startOf("day").format("YYYY-MM-DD HH:mm:ss")
      : moment().startOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [endDate, setEndDate] = useState(
    end
      ? moment(end).endOf("day").format("YYYY-MM-DD HH:mm:ss")
      : moment().endOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [paymentData, setPaymentData] = useState(undefined);
  const [searchWord, setSearchWord] = useState("");
  const [searchButtonDate, setSearchButtonDate] = useState(false);

  const dataHistory = QueryGraphQLByPeriod(GET_HISTORY, startDate, endDate);
  const { data } = QueryGraphQLById(
    GET_HISTORY_BY_MACHINE,
    machineId,
    startDate,
    endDate
  );
  const [coin, setCoin] = useState("0.00");
  const [mobile, setMobile] = useState("0.00");
  const [qrCode, setQrCode] = useState("0.00");
  const [runTime, setRunTime] = useState(0);
  const [dataList, setDataList] = useState([]);
  const [dataHistoryAll, setDataHistoryAll] = useState(
    dataHistory?.data?.getHistory
  );

  const checkDataPage = usePagination(dataHistoryAll, currentPage, perPage);
  // const dataMachineItem = useSelector((state) => state.dataV2.dataMachineItem);
  const machine_name = localStorage.getItem("machine_name");
  const device_name = localStorage.getItem("device_name");

  console.log("data--GET_HISTORY-🐳🐳-", dataHistoryAll, dataList);
  useEffect(() => {
    setDataList(checkDataPage ?? []);
  }, [currentPage, perPage, dataHistoryAll]);

  useEffect(() => {
    if (machineId) {
      const dataArr = data?.getHistoryByMachine ?? [];
      setDataHistoryAll(dataArr);
    } else {
      setDataHistoryAll(dataHistory?.data?.getHistory ?? []);
    }
  }, [machineId, data, dataHistory]);

  useEffect(() => {
    if (dataList?.length > 0) {
      let coinValue = 0;
      let mobileValue = 0;
      let qrCodeValue = 0;
      dataList.map((item) => {
        if (item?.action_by == "NOP") {
          if (item?.run_value) {
            coinValue += parseFloat(item?.run_value);
          }
          setCoin(numberWithCommas(coinValue, 2));
        }
        if (item?.action_by == "Mobile") {
          if (item?.run_value) {
            mobileValue += parseFloat(item?.run_value);
          }
          setMobile(numberWithCommas(mobileValue, 2));
        }
        if (item?.action_by == "QR") {
          if (item?.run_value) {
            qrCodeValue += parseFloat(item?.run_value);
          }
          setQrCode(numberWithCommas(qrCodeValue, 2));
        }
        setRunTime(dataList?.length);
      });
    }
  }, [dataList, dataHistoryAll]);

  useEffect(() => {
    if (searchWord) {
      let dataTmp = dataHistoryAll?.filter((item) => {
        return (
          item?.customer_name.includes(searchWord) ||
          item?.action_by.includes(searchWord) ||
          checkMode(item?.run_mode).includes(searchWord)
        );
      });
      setDataList(dataTmp);
    } else {
      setDataList(checkDataPage);
    }
  }, [searchWord]);

  const checkMode = (mode) => {
    if (mode == 1) {
      return "wash_cold (น้ำเย็น)";
    } else if (mode == 2) {
      return "wash_warm (น้ำอุ่น)";
    } else if (mode == 3) {
      return "wash_hot (น้ำร้อน)";
    } else if (mode == 4) {
      return "wash_blankets_cold (ผ้าห่มน้ำเย็น)";
    } else if (mode == 5) {
      return "wash_delicate_warm (ผ้าบางน้ำอุ่น)";
    } else if (mode == 6) {
      return "wash_delicate_cold (ผ้าบางน้ำร้อน)";
    } else if (mode == 7) {
      return "clean (ล้างถังซัก)";
    } else {
      return mode ? (mode == 0 ? "-" : mode) : "-";
    }
  };

  const checkOption = (option) => {
    if (option == 0) {
      return "No option (ไม่มีการเลือกดพิ่มเติม)";
    } else if (option == 1) {
      return "wash_extra_rinse (เพิ่มรอบล้างน้ำ)";
    } else if (option == 2) {
      return "wash_extra_wash (เพิ่มรอบซัก)";
    } else if (option == 3) {
      return "wash_extra_rinse + wash_extra_wash (เพิ่มรอบล้างน้ำ + เพิ่มรอบซัก)";
    } else {
      return option ?? "-";
    }
  };

  const columnsGraphQL = [
    {
      title: "#",
      ellipsis: true,
      align: "center",
      width: "5%",
      render: (data, record, index) => (
        <div>{index + 1 + (currentPage - 1) * perPage}</div>
      ),
    },
    {
      title: "Customer Name",
      ellipsis: true,
      render: (data) => (
        <div>{data?.customer_name?.length ? data?.customer_name : "-"}</div>
      ),
    },
    {
      title: "Action By",
      ellipsis: true,
      align: "center",
      render: (data) => (
        <div>{data.action_by == "NOP" ? "Coin" : data.action_by || "-"}</div>
      ),
    },
    {
      title: "Mode",
      ellipsis: true,
      align: "center",
      render: (data) => <div>{checkMode(data.run_mode)}</div>,
    },
    {
      title: "Option",
      ellipsis: true,
      align: "center",
      render: (data) => <div>{checkOption(data?.machine_option) || "-"}</div>,
    },
    {
      title: "Price",
      ellipsis: true,
      align: "center",
      render: (data) => <div>{numberWithCommas(data.run_value || 0, 2)}</div>,
    },
    {
      title: "Date",
      ellipsis: true,
      align: "center",
      render: (data) => (
        <div>
          {data.device_stopAt
            ? moment(data.device_stopAt)
                .add(543, "years")
                .format("D MMM YYYY HH:mm:ss")
            : "-"}
        </div>
      ),
    },
  ];

  const toCSVFile = async () => {
    let csvData = [];
    let index = "";

    if (dataHistoryAll?.length > 0) {
      dataHistoryAll.map((data, idx) => {
        let resultRow = {};
        index = idx;
        resultRow["ช่วงเวลาที่เลือก"] = `${moment(startDate)
          .add(543, "years")
          .format("D MMM YYYY")} - ${moment(endDate)
          .add(543, "years")
          .format("D MMM YYYY")}`;
        resultRow["#"] = idx + 1;
        resultRow["Customer Name"] = data?.customer_name?.length
          ? data?.customer_name
          : "-" || "-";
        resultRow["Action By"] =
          data.action_by == "NOP" ? "Coin" : data.action_by || "-";
        resultRow["Mode"] = checkMode(data.run_mode) || "-";
        resultRow["Option"] = checkOption(data?.machine_option) || "-";
        resultRow["Price"] = numberWithCommas(data.run_value || 0, 2);
        resultRow["Date"] = data.createdAt
          ? moment(data.createdAt)
              .add(543, "years")
              .format("D MMM YYYY HH:mm:ss")
          : "-";
        csvData.push(resultRow);
      });
    }

    const toCSV = XLSX.utils.json_to_sheet(csvData);
    const wb = XLSX.utils.book_new();

    const fileName = `MACHINE_${index}_HISTORY`;
    XLSX.utils.book_append_sheet(wb, toCSV, fileName);
    XLSX.writeFile(wb, fileName + ".csv");
  };

  return (
    <div>
      <div className="mg-bt-20 h-d-t-p">
        Machine History : {machine_name ?? ""} ({device_name ?? ""})
      </div>

      <div className="h-d-i-b">
        <div>
          <RangePicker
            style={{ width: "100%" }}
            format={(date) =>
              moment(date).add(543, "years").format("D MMM YYYY")
            }
            allowClear={false}
            value={[
              moment(startDate, "YYYY-MM-DD HH:mm:ss"),
              moment(endDate, "YYYY-MM-DD HH:mm:ss"),
            ]}
            onChange={(date) => {
              setStartDate(
                moment(date[0]).startOf("day").format("YYYY-MM-DD HH:mm:ss")
              );
              setEndDate(
                moment(date[1]).endOf("day").format("YYYY-MM-DD HH:mm:ss")
              );
            }}
            disabledDate={(current) =>
              role === "BRANCHADMIN"
                ? current && current < moment("2021-06-25").startOf("day")
                : null
            }
          />
        </div>
        <div
          className="f-c-c d-b-t-n-s d-bg-c-t"
          onClick={() => setSearchButtonDate(!searchButtonDate)}
        >
          <i className="bi bi-search" style={{ lineHeight: "0" }}></i>
        </div>
      </div>

      <div className="c-c-t-s mg-t-20" style={{ maxWidth: "500px" }}>
        <div className="pst-rlt">
          {isLoadingGraph && <Loading />}
          <div className="dash-report-card-padding">
            <div className="dash-report-title">
              จำนวนใช้งานทั้งหมด ( ครั้ง )
            </div>
            <div className="dash-report-total-credit">{runTime}</div>
          </div>
        </div>
      </div>

      <div className="dash-big-title">ช่องทางการชำระเงิน</div>
      <div className="dash-report-card-payment-grid">
        <div className="c-c-t-s">
          <div className="pst-rlt-h-100pc">
            {isLoadingGraph && <Loading />}
            <div className="p-d-p-ch-10-20">
              <div className="dash-report-card-payment-detail-grid">
                <div className="f-c-c">
                  <i className="bi bi-layers"></i>
                </div>
                <div>เหรียญ</div>
                <div>{coin}</div>
              </div>
            </div>
          </div>
        </div>

        <div className="c-c-t-s">
          <div className="pst-rlt-h-100pc">
            {isLoadingGraph && <Loading />}
            <div className="p-d-p-ch-10-20">
              <div className="dash-report-card-payment-detail-grid">
                <div className="f-c-c">
                  <i className="bi bi-phone"></i>
                </div>
                <div>App</div>
                <div>{mobile}</div>
              </div>
            </div>
          </div>
        </div>

        <div className="c-c-t-s">
          <div className="pst-rlt-h-100pc">
            {isLoadingGraph && <Loading />}
            <div className="p-d-p-ch-10-20">
              <div className="dash-report-card-payment-detail-grid">
                <div className="f-c-c">
                  <i className="bi bi-upc-scan"></i>
                </div>
                <div>QR-Code</div>
                <div>{qrCode}</div>
              </div>
              {/* <div>
                <ProgressPercent value={paymentData && paymentData.payment_all && paymentData.payment_all.qr_code_percent || 0} />
              </div> */}
            </div>
          </div>
        </div>
      </div>

      {/* <ChartColumnStack
        isLoading={isLoadingGraph}
        chartReport={(paymentData && paymentData.result) || []}
        chartTitle="กราฟแสดงช่วงเวลาที่มีการใช้งาน"
        dataLabels={false}
        stackLabels={false}
        tooltipEnabled={true}
        digits={0}
      /> */}

      <div className="dash-chart-box-container">
        <div className="dash-space-between">
          <div className="h-d-t-g">
            <div className="f-c-c">
              <i className="bi bi-basket" style={{ lineHeight: "0" }}></i>
            </div>
            <div className="f-s-c">รายละเอียดการใช้งาน</div>
          </div>

          <div className="pro-reward-header-grid pro-reward-header-grid-column-true">
            <div className="pro-reward-header-grid-search-true">
              <Input
                placeholder="search .."
                value={searchWord}
                onChange={(e) => setSearchWord(e.target.value)}
                onPressEnter={() => {
                  setCurrentPage(1);
                }}
              />
            </div>
            <div className="f-c-c d-b-t-n-s d-bg-c-t">
              <i className="bi bi-search" style={{ lineHeight: "0" }}></i>
            </div>
            {role === "SUPERADMIN" && (
              <div
                className="f-c-c d-b-t-n-s d-bg-c-t h-32 pro-reward-header-grid-export-true"
                onClick={() => toCSVFile()}
              >
                Export CSV
              </div>
            )}
          </div>

          <div className="dash-table-container">
            <Table
              bordered
              size="small"
              loading={isLoadingTable}
              rowKey={(record) => record._id}
              dataSource={dataList}
              columns={columnsGraphQL}
              pagination={false}
              scroll={{ x: true }}
            />
          </div>

          <div className="pgnt-st">
            <Pagination
              size="small"
              current={currentPage}
              onChange={(page) => setCurrentPage(page)}
              total={dataHistoryAll?.length}
              // pageSize={dataList?.length ?? perPage}
              pageSize={perPage}
              showSizeChanger={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MachineHistoryPageV2;
