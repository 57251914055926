// import { Switch, Route, Redirect } from 'react-router-dom';
import './App.css';
import './style.css'

import Router from './routes'

function App() {
  return (
    <div>
      <Router />
    </div>
  );
}

export default App;
