import React, { useEffect } from "react";


const useFilterMachine = () => {

    useEffect(() => {
      
    }, [])
    
    const getFilterMachine = (masterArray = [], selectedIds) => {
        return masterArray.filter(item => {
            return selectedIds.includes(item.deviceName)
        });
    };

    const getMapProgram = (machineArray = []) => {
        const modeTemp = [];
        const mapMode  = [];
         machineArray.map((mItem, i) => {
            // console.log("mode item ", i,  mItem);
            if (mItem.machine_run_mode.length > 0) {
                // console.log("mode ", i,  mItem.machine_run_mode.find((nItem, j) => j == 0));
                const machineMode = mItem.machine_run_mode.find((nItem, j) => j == 0);
                if (machineMode.trim().length > 0) {
                    // console.log("parse", JSON.parse(machineMode))
                    JSON.parse(machineMode).map(oItem => {
                        if (!modeTemp.includes(oItem.mode)) {
                            modeTemp.push(oItem.mode);
                            mapMode.push(oItem);
                        }
                    })
                }
            }
        });
        return mapMode;
      };
  
    return {
        getFilterMachine,
        getMapProgram
    };
  };
  
  export default useFilterMachine;
  
  