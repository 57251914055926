import React, { useState, useEffect } from 'react'
import { useParams, useLocation, useHistory } from 'react-router-dom';
import QrReader from 'react-qr-reader'
import { Input, Modal, Select } from 'antd';
import './CoinCollection.css'
import { LoadingOutlined } from '@ant-design/icons';

import { openNotification, getSearchParams } from '../../utils/function'
import { apiCGetCoinBoxStatus, apiCGetCoinBoxById, apiCEditCoinBoxById, apiCAddCoinBox } from '../../services/apis/coin'

const { Option } = Select;

const CoinBoxAddEdit = () => {
  const { coinId } = useParams();
  const location = useLocation();
  const history = useHistory();

  const params = new URLSearchParams(location.search)
  const p = params.get('p')
  const ttl = params.get('ttl')
  const sw = params.get('sw')

  const [isLoading, setIsLoading] = useState(false)
  const [statuses, setStatuses] = useState([])
  const [coin, setCoin] = useState(undefined)

  const [visible, setVisible] = useState(false)

  const [isAlertBoxCode, setIsAlertBoxCode] = useState(false)

  useEffect(() => {
    const getStaticDropdown = async () => {
      let status = await apiCGetCoinBoxStatus()
      if (status && status.status) setStatuses(status.result || [])
    }
    getStaticDropdown()
  }, [])

  useEffect(() => {
    if (coinId) getData()
  }, [coinId])

  const getData = async () => {
    let data = await apiCGetCoinBoxById(coinId)
    if (data && data.status) setCoin(data.result)
  }

  const addEditData = async () => {
    if (!(coin && coin.coin_box_code)) setIsAlertBoxCode(true)
    else {
      setIsLoading(true)

      let data = { ...coin }

      let newData = coinId ? await apiCEditCoinBoxById(data) : await apiCAddCoinBox(data)
      if (newData && newData.status) {
        setIsLoading(false)
        openNotification('success', 'Success !', `${coinId ? 'Edit' : 'Add new'} coin box successful.`)
        setTimeout(() => history.push(`/coin/box${getSearchParams(['p', 'ttl', 'sw'], [p, ttl, sw])}`), 1000);
      } else {
        setIsLoading(false)
        openNotification('error', 'Failed !', `Failed to ${coinId ? 'edit' : 'add new'} coin box.`)
      }
    }
  }

  const handleScan = value => {
    if (value) {
      setCoin({ ...coin, 'coin_box_code': value })
      setIsAlertBoxCode(false)
      setVisible(false)
    }
  }

  return (
    <div>
      <div className="mg-bt-20 h-d-t-p">Coin Collection - {coinId ? 'Edit' : 'Add'} Box</div>

      <div className="coin-collect-add-site-grid">
        <div className="f-s-c">Box Code</div>
        <div className="coin-collect-text-qr">
          <div>
            <Input
              style={{ borderColor: isAlertBoxCode ? '#EC1C24' : null }}
              placeholder="Box Code"
              value={coin && coin.coin_box_code || ''}
              onChange={e => {
                setIsAlertBoxCode(false)
                setCoin({ ...coin, coin_box_code: e.target.value })
              }}
            />
          </div>
          <div className="f-c-c d-b-t-n-s d-bg-c-t coin-collect-btn-scan"
            onClick={() => setVisible(true)}
          ><i className="fas fa-qrcode"></i></div>
        </div>

        {coinId && <div className="f-s-c">Status</div>}
        {coinId && <div>
          <Select style={{ width: '100%' }}
            placeholder="Status"
            value={coin && coin.status || undefined} // number
            onChange={value => setCoin({ ...coin, status: value })}
          >
            {statuses.map((status, idx) => <Option key={idx} value={status.status}>{status.status_detail}</Option>)}
          </Select>
        </div>}

        <div className="f-s-c">Comment</div>
        <div>
          <Input.TextArea
            autoSize={{ minRows: 3 }}
            placeholder="Comment"
            value={coin && coin.comment || ''}
            onChange={e => setCoin({ ...coin, comment: e.target.value })}
          />
        </div>
      </div>

      <div className="f-c-c s-e-b">
        <div className="f-c-c d-b-t-n-s d-bg-c-t"
          style={{ width: '100px', pointerEvents: isLoading ? 'none' : null }}
          onClick={() => addEditData()}
        >{isLoading ? <LoadingOutlined /> : 'Submit'}</div>
      </div>

      <Modal
        title=""
        visible={visible}
        closable={false}
        footer={false}
        centered
      >
        <div className="f-c-c">
          <QrReader
            className="coin-collect-qr-reader"
            delay={500}
            onScan={value => handleScan(value)}
            onError={err => console.error('scan with error :', err.message)}
            facingMode="environment"
          />
        </div>
        <div className="f-c-c d-b-t-n-s d-bg-c-t md-btn-ol-cc"
          onClick={() => setVisible(false)}
        >Cancel</div>
      </Modal>

    </div>
  )
}

export default CoinBoxAddEdit
