import React, { useState, useEffect } from 'react'
import { Table, Pagination, Select } from 'antd';
import '../../Table.css'
import XLSX from "xlsx";
import { LoadingOutlined } from '@ant-design/icons';

import moment from 'moment';
import 'moment/locale/th'

import { apiDBGetMachineAverage } from '../../../../services/apis/dashboard'
import { numberWithCommas } from '../../../../utils/function'

const { Option } = Select;

const TableMachineAverage = props => {
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [perPage, setPerPage] = useState(20)
  const [filterOption, setFilterOption] = useState('')
  const [tableData, setTableData] = useState(undefined)
  const [waitingForCSV, setWaitingForCSV] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    const getTableData = async () => {
      let data = await apiDBGetMachineAverage(false, props.startDate, props.endDate, currentPage, perPage, filterOption)
      if (data && data.status) {
        setIsLoading(false)
        setTableData(data.result)
      }
    }
    getTableData()
  }, [props.searchButton, currentPage, perPage, filterOption])

  const columns = [
    {
      title: '#',
      ellipsis: true,
      align: 'center',
      width: '5%',
      render: (data, record, index) => <div>{(index + 1) + ((currentPage - 1) * perPage)}</div>
    },
    {
      title: 'สถานที่',
      ellipsis: true,
      render: (data) => <div>{data.site_name || '-'}</div>
    },
    {
      title: 'จำนวนครั้งทั้งหมด',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{numberWithCommas(data.total_used || 0, 0)}</div>
    },
    {
      title: 'จำนวนเครื่อง',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{numberWithCommas(data.machine_count || 0, 0)}</div>
    },
    {
      title: 'จำนวนครั้งเฉลี่ย',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{numberWithCommas(data.avg_used || 0, 0)}</div>
    },
  ];

  const toCSVFile = async () => {
    setWaitingForCSV(true)
    let data = await apiDBGetMachineAverage(true, props.startDate, props.endDate, '', '', '')
    let csvData = [];

    if (data && data.status) {
      (data.result.data_list || []).map((data, idx) => {
        let resultRow = {}
        resultRow['ช่วงเวลาที่เลือก'] = `${moment(props.startDate).add(543, 'years').format('D MMM YYYY')} - ${moment(props.endDate).add(543, 'years').format('D MMM YYYY')}`
        resultRow['#'] = idx + 1
        resultRow['สถานที่'] = data.site_name || '-'
        resultRow['จำนวนครั้งทั้งหมด'] = data.total_used || 0
        resultRow['จำนวนเครื่อง'] = data.machine_count || 0
        resultRow['จำนวนครั้งเฉลี่ย'] = data.avg_used || 0
        csvData.push(resultRow);
      })

      const toCSV = XLSX.utils.json_to_sheet(csvData);
      const wb = XLSX.utils.book_new();

      const fileName = 'MACHINE_AVG'
      XLSX.utils.book_append_sheet(wb, toCSV, fileName);
      XLSX.writeFile(wb, fileName + ".csv");

      setWaitingForCSV(false)
    }
  }

  return (
    <div className="dash-chart-box-container">
      <div className="dash-space-between">
        <div className="h-d-t-g">
          <div className="f-c-c"><i className="bi bi-building" style={{ lineHeight: '0' }}></i></div>
          <div className="f-s-c">ค่าเฉลี่ยการใช้งานเครื่อง ( ในช่วงวันที่เลือก )</div>
        </div>

        <div className="table-filter-export-grid">
          <div>
            <Select value={filterOption} style={{ width: '100%' }}
              onChange={value => {
                setCurrentPage(1)
                setFilterOption(value)
              }}>
              <Option value="">ทั้งหมด</Option>
              <Option value="0">0</Option>
              <Option value="1">1</Option>
              <Option value="2">2</Option>
              <Option value="3">3</Option>
              <Option value="4">4</Option>
              <Option value="5">≥ 5</Option>
            </Select>
          </div>
          <div className="f-c-c d-b-t-n-s d-bg-c-t table-export-btn-width" style={{ pointerEvents: waitingForCSV ? 'none' : null }} onClick={() => toCSVFile()}>
            {waitingForCSV ? <LoadingOutlined /> : 'Export CSV'}
          </div>
        </div>

        <div className="dash-table-container">
          <Table bordered
            size="small"
            loading={isLoading}
            rowKey={record => record.site_id}
            dataSource={tableData && tableData.data_list || []}
            columns={columns}
            pagination={false}
            scroll={{ x: true }}
          />
        </div>

        <div className="pgnt-st">
          <Pagination
            size="small"
            current={currentPage}
            onChange={page => setCurrentPage(page)}
            total={tableData && tableData.max_total_rows}
            pageSize={perPage}
            showSizeChanger={false}
          />
        </div>

      </div>
    </div>
  )
}

export default TableMachineAverage
