import axios from '../../config/axios';

const apiPMGetPayment = async (get_all, start_date, end_date, cur_page, per_page, type, keyword) => {
  try {
    const result = await axios.get(`/payment/list?get_all=${get_all}&start_date=${start_date}&end_date=${end_date}&cur_page=${cur_page}&per_page=${per_page}&type=${type}&keyword=${keyword}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiPMApproveRejectPayment = async (data) => {
  try {
    const result = await axios.put('/payment/status', data)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

export {
  apiPMGetPayment,
  apiPMApproveRejectPayment
}