import { createAction, createReducer } from "@reduxjs/toolkit";

const initialState = {
  dataMachineList: [],
  dataSiteItem: null,
  dataMachineItem: null,
};

export const getDataMachine = createAction("MACHINE_BY_ID");
export const saveDataSiteItem = createAction("DATA_SITE_BY_ID");
export const saveDataMachineItem = createAction("DATA_MACHINE_BY_ID");

export default createReducer(initialState, {
  [getDataMachine]: (state, action) => {
    state.dataMachineList = action.payload;
    return state;
  },
  [saveDataSiteItem]: (state, action) => {
    // console.log("saveDataSiteItem-action--🤟🤟", action);
    localStorage.setItem("site_name", action.payload?.site_name);
    localStorage.setItem("site_code", action.payload?.siteCode);
    state.dataSiteItem = action.payload;
    return state;
  },
  [saveDataMachineItem]: (state, action) => {
    state.dataMachineItem = action.payload;
    localStorage.setItem("machine_name", action.payload?.machine_name);
    localStorage.setItem("device_name", action.payload?.deviceName);
    return state;
  },
});
