import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom';
import QrReader from 'react-qr-reader'
import { Modal, Select } from 'antd';
import './CoinCollection.css'
import { LoadingOutlined } from '@ant-design/icons';

import { openNotification, checkIfIdEqualToZero } from '../../utils/function'
import { apiSGetSite } from '../../services/apis/site'
import { apiCGetCoinBoxCode, apiCGetMachineBySiteId, apiCAddSite, apiCCheckOut, apiCNGetCoinByBoxCode } from '../../services/apis/coin'

const { Option } = Select;

const CoinAddSiteCheckOut = () => {
  const history = useHistory();
  const { coinType } = useParams();
  const [isLoading, setIsLoading] = useState(false)
  const [coins, setCoins] = useState([])
  const [sites, setSites] = useState([])
  const [machines, setMachines] = useState([])
  const [coin, setCoin] = useState(undefined)

  const [visible, setVisible] = useState(false)
  const [typeData, setTypeData] = useState('')

  const [isAlertBoxCode, setIsAlertBoxCode] = useState(false)
  const [isAlertSite, setIsAlertSite] = useState(false)
  const [isAlertMachine, setIsAlertMachine] = useState(false)

  useEffect(() => {
    if (coinType !== 'add-site' && coinType !== 'check-out') history.push('/coin/history')

    const getStaticDropdown = async () => {
      let site = await apiSGetSite()
      if (site && site.status) setSites(site.result || [])

      let coin = await apiCGetCoinBoxCode()
      if (coin && coin.status) setCoins(coin.result || [])
    }
    getStaticDropdown()
  }, [])

  useEffect(() => {
    if (checkIfIdEqualToZero(coin && coin.site_id)) {
      const getMachine = async () => {
        let machine = await apiCGetMachineBySiteId(coin && coin.site_id)
        if (machine && machine.result) setMachines(machine.result || [])
      }
      getMachine()
    }
  }, [coin && coin.site_id])

  const getDataByBoxCode = async code => {
    setIsAlertBoxCode(false)
    setIsAlertSite(false)
    setIsAlertMachine(false)
    let data = await apiCNGetCoinByBoxCode(code)
    if (data && data.status) setCoin(data.result)
  }

  const addEditData = async () => {
    if (!(coin && coin.coin_box_code) ||
      !(checkIfIdEqualToZero(coin && coin.site_id)) ||
      !(coin && coin.machine_id)
    ) {
      if (!(coin && coin.coin_box_code)) setIsAlertBoxCode(true)
      if (!(checkIfIdEqualToZero(coin && coin.site_id))) setIsAlertSite(true)
      if (!(coin && coin.machine_id)) setIsAlertMachine(true)

    } else {
      setIsLoading(true)

      let data = { ...coin }
      data.admin_id = localStorage.getItem('user_id');

      let newData = coinType === 'add-site' ? await apiCAddSite(data) : await apiCCheckOut(data)
      if (newData && newData.status) {
        setIsLoading(false)
        openNotification('success', 'Success !', 'Add new coin successful.')
        setTimeout(() => history.push('/coin/history'), 1000);
      } else {
        setIsLoading(false)
        openNotification('error', 'Failed !', 'Failed to add new coin.')
      }
    }
  }

  const handleScan = value => {
    if (value) {
      if (typeData === 'coin_box_code') getDataByBoxCode(value)
      else if (typeData === 'site_id') {
        setIsAlertSite(false)
        setCoin({
          ...coin,
          site_id: value,
          machine_id: undefined,
        })
      } else if (typeData === 'machine_id') {
        setIsAlertMachine(false)
        setCoin({ ...coin, machine_id: value })
      }

      setVisible(false)
    }
  }

  return (
    <div>
      <div className="mg-bt-20 h-d-t-p">Coin Collection - {coinType === 'add-site' ? 'Add Site' : 'Check Out'}</div>

      <div className="coin-collect-add-site-grid">
        <div className="f-s-c">Box Code</div>
        <div className="coin-collect-text-qr">
          <div className={isAlertBoxCode && 'antd-sl-al-bd ovf-hd' || 'ovf-hd'}>
            <Select style={{ width: '100%' }}
              placeholder="Box Code"
              showSearch
              value={coin && coin.coin_box_code || undefined}
              onChange={value => {
                setCoin({ ...coin, coin_box_code: value })
                getDataByBoxCode(value)
              }}
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {coins.map((coin, idx) => <Option key={idx} value={coin.coin_box_code}>{coin.coin_box_code}</Option>)}
            </Select>
          </div>
          <div className="f-c-c d-b-t-n-s d-bg-c-t coin-collect-btn-scan"
            onClick={() => {
              setTypeData('coin_box_code')
              setVisible(true)
            }}
          ><i className="fas fa-qrcode"></i></div>
        </div>

        <div className="f-s-c">Site</div>
        <div className="coin-collect-text-qr">
          <div className={isAlertSite && 'antd-sl-al-bd ovf-hd' || 'ovf-hd'}>
            <Select style={{ width: '100%' }}
              placeholder="Site"
              showSearch
              value={coin && coin.site_id}
              onChange={value => {
                setIsAlertSite(false)
                setCoin({
                  ...coin,
                  site_id: value,
                  machine_id: undefined,
                })
              }}
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {sites.map((site, idx) => <Option key={idx} value={site.site_id}>{site.site_name}</Option>)}
            </Select>
          </div>
          <div className="f-c-c d-b-t-n-s d-bg-c-t coin-collect-btn-scan"
            onClick={() => {
              setTypeData('site_id')
              setVisible(true)
            }}
          ><i className="fas fa-qrcode"></i></div>
        </div>

        <div className="f-s-c">Machine</div>
        <div className="coin-collect-text-qr">
          <div className={isAlertMachine && 'antd-sl-al-bd ovf-hd' || 'ovf-hd'}>
            <Select style={{ width: '100%' }}
              placeholder="Machine"
              disabled={!(checkIfIdEqualToZero(coin && coin.site_id))}
              value={coin && coin.machine_id}
              onChange={value => {
                setIsAlertMachine(false)
                setCoin({ ...coin, machine_id: value })
              }}
            >
              {machines.map((machine, idx) => <Option key={idx} value={machine.machine_id}>{machine.machine_name}</Option>)}
            </Select>
          </div>
          <div className="f-c-c d-b-t-n-s d-bg-c-t coin-collect-btn-scan"
            style={{ cursor: !(checkIfIdEqualToZero(coin && coin.site_id)) ? 'not-allowed' : null }}
            onClick={() => {
              if (!(checkIfIdEqualToZero(coin && coin.site_id))) return
              setTypeData('machine_id')
              setVisible(true)
            }}
          ><i className="fas fa-qrcode"></i></div>
        </div>
      </div>

      <div className="f-c-c s-e-b">
        <div className="f-c-c d-b-t-n-s d-bg-c-t"
          style={{ width: '100px', pointerEvents: isLoading ? 'none' : null }}
          onClick={() => addEditData()}
        >{isLoading ? <LoadingOutlined /> : 'Submit'}</div>
      </div>

      <Modal
        title=""
        visible={visible}
        closable={false}
        footer={false}
        centered
      >
        <div className="f-c-c">
          <QrReader
            className="coin-collect-qr-reader"
            delay={500}
            onScan={value => handleScan(value)}
            onError={err => console.error('scan with error :', err.message)}
            facingMode="environment"
          />
        </div>
        <div className="f-c-c d-b-t-n-s d-bg-c-t md-btn-ol-cc"
          onClick={() => {
            setTypeData('')
            setVisible(false)
          }}
        >Cancel</div>
      </Modal>

    </div>
  )
}

export default CoinAddSiteCheckOut
