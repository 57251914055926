import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  Input,
  Table,
  Pagination,
  Tooltip,
  Modal,
  Avatar,
  Checkbox,
  Select,
} from "antd";
import "./SiteManagement.css";
import XLSX from "xlsx";
import { useDispatch } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";

import { openNotification, getSearchParams } from "../../utils/function";
import { apiSetWasher } from "../../services/apis/site";
import { QueryGraphQL, GET_SITES } from "../../resolvers/Query";
import usePagination from "../../hooks/usePagination";
import { saveDataSiteItem } from "../../redux/data-v2";
import { useMutation } from "@apollo/client";
import { DELETE_SITE } from "../../resolvers/Mutation";

const { Option } = Select;

const SiteManagementListV2 = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const params = new URLSearchParams(location.search);
  const p = params.get("p");
  const ttl = params.get("ttl");
  const sw = params.get("sw");

  const menuList = (localStorage.getItem("list") || "").split(",");
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(Number(p) || 1);
  // const [perPage, setPerPage] = useState(Number(ttl) || 20);
  const perPage = 20;
  const [searchWord, setSearchWord] = useState(sw || "");
  const [waitingForCSV, setWaitingForCSV] = useState(false);

  const [visible, setVisible] = useState(false);
  const [isModalWasher, setIsModalWasher] = useState(false);
  const [isWasher, setIsWasher] = useState(undefined);
  const [isBag, setIsBag] = useState([]);
  const [modalTitle, setModalTitle] = useState("");
  const [modalId, setModalId] = useState(undefined);
  const [modalWasherId, setModalWasherId] = useState(undefined);
  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false);
  const [isLoadingWasher, setIsLoadingWasher] = useState(false);
  const { data } = QueryGraphQL(GET_SITES);
  const [dataList, setDataList] = useState([]);
  const dataSites = data?.getSites;
  const checkDataPage = usePagination(dataSites, currentPage, perPage);
  const [deleteSite, dataDelete] = useMutation(DELETE_SITE);
  const [searchTotalPage, setSearchTotalPage] = useState(20);
  const [dataSearchList, setDataSearchList] = useState([]);
  const dataSearch = usePagination(dataSearchList, currentPage, perPage);
  //console.log("data--GET_SITES-🐳🐳-", data, dataList, dataSearchList);

  useEffect(() => {
    check_sitestatus(checkDataPage ?? [])
    // console.log(checkDataPage)
    // setDataList(checkDataPage ?? []);
  }, [dataSites]);

  useEffect(() => {
    const dataArr = dataSearchList?.length > 0 ? dataSearchList : dataSites;
    //console.log(dataArr)
    if (dataArr?.length >= 0) {
      check_sitestatus(dataArr)

    }
  }, [currentPage, perPage]);

  useEffect(() => {
    if (dataSearchList?.length > 0) {
      setDataList(dataSearch ?? []);
    }
  }, [dataSearchList]);

  useEffect(() => {
    if (dataDelete && isLoadingConfirm) {
      if (dataDelete?.error) {
        openNotification(
          "error",
          "Failed !",
          "Failed to delete this controller."
        );
        setIsLoadingConfirm(false);
      }
      if (dataDelete?.data?.deleteSite) {
        openNotification(
          "success",
          "Success !",
          "Delete this controller successful."
        );
        setVisible(false);
        setIsLoadingConfirm(false);
        window.location.reload(false);
      }
    }
  }, [dataDelete]);
  const check_sitestatus = (dataArr) => {

    let dataNew = [];
    dataArr.map((item, index) => {
      if (
        index + 1 >= currentPage * perPage - perPage + 1 &&
        index + 1 <= currentPage * perPage
      ) {
        if (item.site_status === "golive") { item = { ...item, site_name_status: "กำลังใช้งาน" } }
        if (item.site_status === "systemtest") { item = { ...item, site_name_status: "เทสระบบ" } }
        if (item.site_status === "installing") { item = { ...item, site_name_status: "กำลังติดตั้งสาขา" } }
        if (item.site_status === "cancel") { item = { ...item, site_name_status: "ยกเลิก" } }
        dataNew.push(item);
      }
    });
    //console.log(dataNew);
    setDataList(dataNew);
  }
  const searchData = () => {
    console.log(dataSites)
    if (searchWord) {
      let dataTmp4 = dataSites?.filter((item) => {
        //console.log(item)
        return item?.site_name
          ?.toLowerCase()
          .includes(searchWord.toLowerCase());

      });
      if (dataTmp4.length === 0) {
        let dataTmp5 = dataSites?.filter((item) => {
          return item?.siteCode
            ?.toLowerCase()
            .includes(searchWord.toLowerCase());

        });
        check_sitestatus(dataTmp5)
        //setDataSearchList(dataTmp);
        setSearchTotalPage(dataTmp5?.length ?? 20);
        if (dataTmp5.length === 0) {

          let dataTmp6 = dataSites?.filter((item) => {
            return item?.site_name_en
              ?.toLowerCase()
              .includes(searchWord.toLowerCase());

          });
          check_sitestatus(dataTmp6)
          //setDataSearchList(dataTmp);
          setSearchTotalPage(dataTmp6?.length ?? 20);
        }
        else {
          check_sitestatus(dataTmp5)
          //setDataSearchList(dataTmp);
          setSearchTotalPage(dataTmp5?.length ?? 20);
        }
      } else {
        check_sitestatus(dataTmp4)
        //setDataSearchList(dataTmp);
        setSearchTotalPage(dataTmp4?.length ?? 20);
      }

    } else {
      setDataSearchList(checkDataPage ?? []);
      setSearchTotalPage(checkDataPage?.length ?? 20);
    }
  };

  const columnsGraphQL = [
    {
      title: "#",
      ellipsis: true,
      align: "center",
      width: "10%",
      render: (data, record, index) => (
        <div>{index + 1 + (currentPage - 1) * perPage}</div>
      ),
    },
    {
      title: "รูป",
      ellipsis: true,
      align: "center",
      width: "15%",
      render: (data) => (
        <Avatar
          shape="square"
          size={80}
          src={data.img_site || ""}
          icon={<i className="bi bi-image"></i>}
        />
      ),
    },
    {
      title: "รหัสสาขา",
      ellipsis: true,
      width: "15%",
      render: (data) => <div>{data.qr_code || "-"}</div>,
    },
    {
      title: "ชื่อสาขา",
      ellipsis: true,
      width: "20%",
      render: (data) => <div>{data.site_name || "-"}</div>,
    },
    // {
    //   title: "จำนวนเครื่อง",
    //   ellipsis: true,
    //   width: "10%",
    //   render: (data) => <div>{data?.machinesNo || "-"}</div>,
    // },
    {
      title: "สถานะ",
      ellipsis: true,
      align: "center",
      width: "10%",
      render: (data) => <div>{data.site_name_status || "-"}</div>,
    },
    {
      title: "Version",
      ellipsis: true,
      align: "center",
      width: "10%",
      render: (data) => <div>{data.version || "-"}</div>,
    },
    {
      title: "Manage",
      ellipsis: true,
      align: "center",
      fixed: "right",
      width: "20%",
      render: (data) => (
        <div className="f-c-c">
          <div className="tb-cl-a-g tb-cl-a-g-rp-3">
            <Tooltip placement="top" title="Dashboard">
              <div
                className="f-c-c d-b-t-n-s b-c-o-i"
                style={{ backgroundColor: "#45D88E" }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (data?.version == 1) {
                    history.push(`/dashboard/${data.siteCode}`);
                  } else {
                    history.push(`/dashboard/v2/${data.id}`);
                    dispatch(saveDataSiteItem(data));
                  }
                }}
              >
                <i className="bi bi-bar-chart-line"></i>
              </div>
            </Tooltip>
            <Tooltip placement="top" title="Report">
              <div
                className="f-c-c d-b-t-n-s b-c-o-i"
                style={{ backgroundColor: "#FFCB05" }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (data?.version == 1) {
                    history.push(
                      `/site-management/list/report/${data.siteCode}`
                    );
                  } else {
                    history.push(`/site-management/list/report/v2/${data.id}`);
                    dispatch(saveDataSiteItem(data));
                  }
                }}
              >
                <i className="bi bi-file-earmark-ruled-fill"></i>
              </div>
            </Tooltip>
            <Tooltip placement="top" title="Machine">
              <div
                className="f-c-c d-b-t-n-s b-c-o-i"
                style={{ backgroundColor: "#000000" }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (data?.version == 1) {
                    history.push(
                      `/machine/list${getSearchParams(
                        ["p", "ttl", "s"],
                        [1, 20, parseInt(data?.siteCode)]
                      )}`
                    );
                  } else {
                    history.push(
                      `/machine/list/v2${getSearchParams(
                        ["p", "ttl", "s"],
                        [1, 20, data.id]
                      )}`
                    );
                    dispatch(saveDataSiteItem(data));
                  }
                }}
              >
                <i className="bi bi-gear"></i>
              </div>
            </Tooltip>
            {menuList.includes("SITEMAN") && (
              <Tooltip placement="top" title="QR-Code">
                <div
                  className="f-c-c d-b-t-n-s b-c-o-i"
                  style={{ backgroundColor: "#000080" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    history.push(
                      `/site-management/list/gen-qr/${data.siteCode}`
                    );
                  }}
                >
                  <i className="bi bi-upc-scan"></i>
                </div>
              </Tooltip>
            )}
            {menuList.includes("SITEMAN") && (
              <Tooltip placement="top" title="Edit">
                <div
                  className="f-c-c d-b-t-n-s b-c-o-i"
                  style={{ backgroundColor: "#00ADEF" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (data?.version == 11) {
                      history.push(
                        `/site-management/list/edit/${data.siteCode
                        }${getSearchParams(
                          ["p", "ttl", "sw"],
                          [currentPage, perPage, searchWord]
                        )}`
                      );
                    } else {
                      history.push(
                        `/site-management/v2/list/edit/${data.id
                        }${getSearchParams(
                          ["siteID", "adminID"],
                          [data.id, data.admin_list[0]]
                        )}`
                      );
                      dispatch(saveDataSiteItem(data));
                    }
                  }}
                >
                  <i className="bi bi-pencil-fill"></i>
                </div>
              </Tooltip>
            )}
            {menuList.includes("SITEMAN") && (
              <Tooltip placement="top" title="Delete">
                <div
                  className="f-c-c d-b-t-n-s b-c-o-i"
                  style={{ backgroundColor: "#ED2228" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setModalId(data.id);
                    setModalTitle(data.site_name);
                    setVisible(true);
                  }}
                >
                  <i className="fas fa-trash"></i>
                </div>
              </Tooltip>
            )}
            {menuList.includes("WASHERMAN") && (
              <Tooltip placement="top" title="Washer">
                <div
                  className="f-c-c d-b-t-n-s b-c-o-i"
                  style={{
                    backgroundColor: data.is_washer ? "#FF7D87" : "#686767",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setModalWasherId(data.id);
                    setIsBag(data.bag_list);
                    setIsWasher(data.is_washer);
                    setIsModalWasher(true);
                  }}
                >
                  <i className="bi bi-bag-fill"></i>
                </div>
              </Tooltip>
            )}
            {menuList.includes("SITEMAN") && (
              <Tooltip placement="top" title="Upload">
                <div
                  className="f-c-c d-b-t-n-s b-c-o-i"
                  style={{ backgroundColor: "#900DF7" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    history.push(`/slip/${data.id}`);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-coin"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9H5.5zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518l.087.02z" />
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11zm0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12z" />
                  </svg>
                </div>
              </Tooltip>
            )}
            {menuList.includes("SITEMAN") && (
              <Tooltip placement="top" title="Add Admin">
                <div
                  className="f-c-c d-b-t-n-s b-c-o-i"
                  style={{ backgroundColor: "#f75e00" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (data?.version == 1) {
                      history.push(
                        `/admin-management/list-admin/${data.site_name}`
                      );
                    } else {
                      history.push(
                        `/admin-management/list/v2/${data.id}`
                      );
                    }
                  }}
                >
                  <i className="fas fa-user-alt"></i>
                </div>
              </Tooltip>
            )}
          </div>
        </div>
      ),
    },
  ];

  const deleteData = async () => {
    if (!modalId) {
      openNotification(
        "error",
        "Failed !",
        "Failed to delete this controller."
      );
      return;
    }

    setIsLoadingConfirm(true);
    deleteSite({
      variables: {
        siteId: modalId,
      },
    });
  };

  const serviceWasher = async () => {
    if (!modalWasherId) {
      openNotification(
        "error",
        "Failed !",
        "Failed to set Washer this controller."
      );
      return;
    }

    let data = {
      site_id: modalWasherId,
      is_washer: isWasher,
      bag_list: isBag,
    };
    setIsLoadingWasher(true);
    let washer = await apiSetWasher(data);
    if (washer && washer.status) {
      openNotification("success", "Success !", "Set washer successful.");
      setIsModalWasher(false);
      setIsLoadingWasher(false);
      setIsWasher(undefined);
      setIsBag([]);
      // getTableData();
    } else {
      openNotification("error", "Failed !", "Failed to set washer.");
      setIsLoadingWasher(false);
    }
  };

  const toCSVFile = async () => {
    setWaitingForCSV(true);
    let csvData = [];

    if (dataSites?.length > 0) {
      dataSites.map((data, idx) => {
        let resultRow = {};
        // let str = data.site_qrcode;
        // const myArr = str.split(" ");
        resultRow["#"] = idx + 1;
        // resultRow['รูป'] = data.img_site || '-'
        resultRow["รหัสสาขา"] = `${data.side_type ?? ""} ${data.province_id ?? ""
          } ${data.zone ?? ""} ${data.site_sub_type ?? ""} ${data.siteCode ?? ""
          }`;
        resultRow["ชื่อสาขา"] = data.site_name || "-";
        resultRow["สถานะ"] = data.site_status || "-";

        resultRow["Version"] = data.version || "-";
        // resultRow["ประเภทสาขา"] = myArr[0] || "-";
        // resultRow["ประเภทสาขาย่อย"] = myArr[3] || "-";
        // resultRow["จังหวัด"] = myArr[1] || "-";
        // resultRow["เขตพื้นที่"] = myArr[2] || "-";
        csvData.push(resultRow);
      });

      const toCSV = XLSX.utils.json_to_sheet(csvData);
      const wb = XLSX.utils.book_new();

      const fileName = "SITE_MANAGEMENT";
      XLSX.utils.book_append_sheet(wb, toCSV, fileName);
      XLSX.writeFile(wb, fileName + ".csv");

      setWaitingForCSV(false);
    }
  };

  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
    setIsWasher(e.target.checked);
  };

  const handleChangeSelect = (value) => {
    console.log(`selected ${value}`);
    setIsBag(value);
  };

  return (
    <div>
      <div className="mg-bt-20 h-d-t-p">Site Management</div>

      <div
        className={`pro-reward-header-grid pro-reward-header-grid-column-${!menuList.includes(
          "SITEMAN"
        )}`}
      >
        <div
          className={`pro-reward-header-grid-search-${!menuList.includes(
            "SITEMAN"
          )}`}
        >
          <Input
            placeholder="search .."
            value={searchWord}
            onChange={(e) => setSearchWord(e.target.value)}
            onPressEnter={() => {
              setCurrentPage(1);
            }}
          />
        </div>
        <div
          className="f-c-c d-b-t-n-s d-bg-c-t"
          onClick={() => {
            setCurrentPage(1);
            searchData();
          }}
        >
          <i className="bi bi-search" style={{ lineHeight: "0" }}></i>
        </div>
        {menuList.includes("SITEMAN") && (
          <Link to="/site-management/add/v2">
            <div
              className="f-c-c d-b-t-n-s d-bg-c-t h-32"
              style={{ height: "100%" }}
            >
              + Add
            </div>
          </Link>
        )}
        <div
          className={`f-c-c d-b-t-n-s d-bg-c-t h-32 pro-reward-header-grid-export-${!menuList.includes(
            "SITEMAN"
          )}`}
          style={{ pointerEvents: waitingForCSV ? "none" : null }}
          onClick={() => toCSVFile()}
        >
          {waitingForCSV ? <LoadingOutlined /> : "Export CSV"}
        </div>
      </div>

      <Table
        bordered
        size="small"
        rowKey={(record) => record.site_id}
        loading={isLoading}
        dataSource={dataList}
        columns={columnsGraphQL}
        pagination={false}
        scroll={{ x: true }}
      />

      <div className="pgnt-st">
        <Pagination
          size="small"
          current={currentPage}
          onChange={(page) => setCurrentPage(page)}
          total={searchWord ? searchTotalPage : dataSites?.length}
          // pageSize={dataList?.length ?? perPage}
          pageSize={perPage}
          showSizeChanger={false}
        />
      </div>

      <Modal
        title=""
        visible={visible}
        closable={false}
        footer={false}
        centered
      >
        <div className="m-t-i-c" style={{ color: "#FFCB05" }}>
          <i className="bi bi-exclamation-triangle-fill"></i>
        </div>
        <div className="m-t-t-q">Do you want to delete '{modalTitle}' ?</div>
        <div className="m-b-y-n-g">
          <div
            className="f-c-c d-b-t-n-s b-c-o-i"
            style={{ backgroundColor: "#cccccc" }}
            onClick={() => setVisible(false)}
          >
            Cancel
          </div>
          <div
            className="f-c-c d-b-t-n-s b-c-o-i"
            style={{
              backgroundColor: "#3f4d67",
              pointerEvents: isLoadingConfirm ? "none" : null,
            }}
            onClick={() => deleteData()}
          >
            {isLoadingConfirm ? <LoadingOutlined /> : "Confirm"}
          </div>
        </div>
      </Modal>

      <Modal
        title=""
        visible={isModalWasher}
        closable={false}
        footer={false}
        centered
      >
        <div className="check-washer">
          <Checkbox checked={isWasher} onChange={onChange}>
            บริการ ซัก อบ พับ
          </Checkbox>
        </div>
        <div className="size-bag">
          <Select
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
            placeholder="Please select"
            value={isBag ? isBag : []}
            onChange={handleChangeSelect}
          >
            <Option key="S">S</Option>
            <Option key="M">M</Option>
            <Option key="L">L</Option>
          </Select>
        </div>

        <div className="m-b-y-n-g">
          <div
            className="f-c-c d-b-t-n-s b-c-o-i"
            style={{ backgroundColor: "#cccccc" }}
            onClick={() => setIsModalWasher(false)}
          >
            Cancel
          </div>
          <div
            className="f-c-c d-b-t-n-s b-c-o-i"
            style={{
              backgroundColor: "#3f4d67",
              pointerEvents: isLoadingWasher ? "none" : null,
            }}
            onClick={() => serviceWasher()}
          >
            {isLoadingWasher ? <LoadingOutlined /> : "Confirm"}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SiteManagementListV2;
