import React, { useState, useEffect } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { Input, Checkbox } from "antd";
import "./AdminManagement.css";
import { LoadingOutlined } from "@ant-design/icons";

import {
  openNotification,
  checkReqFieldWithId,
  getSearchParams,
} from "../../utils/function";
import {
  apiAMAddSupAdminList,
  apiAMGetSupAdminById,
  apiAMEditSupAdminById,
} from "../../services/apis/admin";

const SupAdminAddEdit = () => {
  const { adminId } = useParams();
  const location = useLocation();
  const history = useHistory();

  const params = new URLSearchParams(location.search);
  const p = params.get("p");
  const ttl = params.get("ttl");
  const sw = params.get("sw");

  const [isLoading, setIsLoading] = useState(false);
  const [admin, setAdmin] = useState(undefined);

  const menus = [
    { key: "DASHBOARD", value: "Dashboard" },
    { key: "TRANSACTION", value: "Transaction" },
    { key: "PAYMENT", value: "Payment" },

    { key: "MACHINEVIEW", value: "Machine View" },
    { key: "MACHINEMAN", value: "Machine Management" },

    { key: "CUSTOMERVIEW", value: "Customer View" },
    { key: "CUSTOMERMAN", value: "Customer Management" },
    { key: "CUSTOMERCREDIT", value: "Customer Credit" },

    { key: "SITEVIEW", value: "Site View" },
    { key: "SITEMAN", value: "Site Management" },

    { key: "ADMINVIEW", value: "Admin View" },
    { key: "ADMINMAN", value: "Admin Management" },
    { key: "SUPERADMINVIEW", value: "Superadmin View" },
    { key: "SUPERADMINMAN", value: "Superadmin Management" },
    { key: "BOARDCAST", value: "Boardcast" },
    { key: "SITEPROMO", value: "Site Promotion" },
    { key: "ALLPROMO", value: "Promotion" },

    { key: "MANTENANCE", value: "Operation & Maintainance" },
    { key: "CALLSUPPORT", value: "Call Support" },
    { key: "COINCOLLECT", value: "Coin Collection" },
    { key: "COINRECONCILE", value: "Coin Reconcile" },
    { key: "COINMAN", value: "Coin Management" },
    { key: "ANNOUNCE", value: "Announcement" },
    { key: "BANNER", value: "Banner" },
    { key: "STOCK", value: "Stock Management" },
    { key: "EXPORT", value: "Export" },
    { key: "CHECK", value: "Check" },
    { key: "WASHER", value: "Washer" },
    { key: "TRANSACMAN", value: "Transaction Management" },
    { key: "WASHERMAN", value: "Washer Management" },
  ];

  // Add boardcast

  const [isAlertAdminName, setIsAlertAdminName] = useState(false);
  const [isAlertUsername, setIsAlertUsername] = useState(false);
  const [isAlertPassword, setIsAlertPassword] = useState(false);

  useEffect(() => {
    if (adminId) getData();
    else {
      setAdmin(undefined);
      setIsAlertAdminName(false);
      setIsAlertUsername(false);
      setIsAlertPassword(false);
    }
  }, [adminId]);

  const getData = async () => {
    let data = await apiAMGetSupAdminById(adminId);
    if (data && data.status) setAdmin(data.result);
  };

  const addEditData = async () => {
    let passPW = checkReqFieldWithId(adminId, admin && admin.admin_pass);

    if (
      !(admin && admin.admin_name) ||
      !(admin && admin.admin_user) ||
      !passPW
    ) {
      if (!(admin && admin.admin_name)) setIsAlertAdminName(true);
      if (!(admin && admin.admin_user)) setIsAlertUsername(true);
      if (!passPW) setIsAlertPassword(true);
    } else {
      setIsLoading(true);

      let data = { ...admin };

      let newData = adminId
        ? await apiAMEditSupAdminById(data)
        : await apiAMAddSupAdminList(data);
      if (newData && newData.status) {
        setIsLoading(false);
        openNotification(
          "success",
          "Success !",
          `${adminId ? "Edit" : "Add new"} superadmin successful.`
        );
        setTimeout(
          () =>
            history.push(
              `/admin-management/list-superadmin${getSearchParams(
                ["p", "ttl", "sw"],
                [p, ttl, sw]
              )}`
            ),
          1000
        );
      } else {
        setIsLoading(false);
        openNotification(
          "error",
          "Failed !",
          `Failed to ${adminId ? "edit" : "add new"} superadmin.`
        );
      }
    }
  };

  return (
    <div>
      <div className="mg-bt-20 h-d-t-p">
        Superadmin - {adminId ? "Edit" : "Add"}
      </div>

      <div className="t-m-c-i-p-g">
        <div className="f-s-c">ชื่อ</div>
        <div>
          <Input
            style={{ borderColor: isAlertAdminName ? "#EC1C24" : null }}
            placeholder="ชื่อ"
            value={(admin && admin.admin_name) || ""}
            onChange={(e) => {
              setIsAlertAdminName(false);
              setAdmin({ ...admin, admin_name: e.target.value });
            }}
          />
        </div>

        <div className="f-s-c">อีเมล</div>
        <div>
          <Input
            placeholder="อีเมล"
            value={(admin && admin.admin_email) || ""}
            onChange={(e) =>
              setAdmin({ ...admin, admin_email: e.target.value })
            }
          />
        </div>

        <div className="f-s-c">เบอร์โทร</div>
        <div>
          <Input
            placeholder="เบอร์โทร"
            value={(admin && admin.admin_tel) || ""}
            onChange={(e) =>
              setAdmin({
                ...admin,
                admin_tel: e.target.value.replace(/[^0-9]+/g, ""),
              })
            }
          />
        </div>

        <div className="f-s-c">Username</div>
        <div>
          <Input
            style={{ borderColor: isAlertUsername ? "#EC1C24" : null }}
            placeholder="Username"
            value={(admin && admin.admin_user) || ""}
            onChange={(e) => {
              setIsAlertUsername(false);
              setAdmin({ ...admin, admin_user: e.target.value });
            }}
          />
        </div>

        <div className="f-s-c">Password</div>
        <div>
          <Input.Password
            style={{ borderColor: isAlertPassword ? "#EC1C24" : null }}
            placeholder="Password"
            value={(admin && admin.admin_pass) || ""}
            onChange={(e) => {
              setIsAlertPassword(false);
              setAdmin({ ...admin, admin_pass: e.target.value });
            }}
          />
        </div>

        <div className="f-s-c">Menu</div>
        <div>
          <div className="f-s-c antd-chb-fz">
            <Checkbox
              checked={
                ((admin && admin.menu_list) || []).length === menus.length
              }
              onChange={(e) => {
                let newAdmin = { ...admin };
                if (!newAdmin.menu_list) newAdmin.menu_list = [];

                if (e.target.checked)
                  newAdmin.menu_list = menus.map((list) => list.key);
                else newAdmin.menu_list = [];

                setAdmin(newAdmin);
              }}
            >
              All
            </Checkbox>
          </div>
          {menus.map((menu, idx) => (
            <div key={idx} className="f-s-c antd-chb-fz">
              <Checkbox
                checked={((admin && admin.menu_list) || []).includes(menu.key)}
                onChange={(e) => {
                  let newAdmin = { ...admin };
                  if (!newAdmin.menu_list) newAdmin.menu_list = [];

                  if (e.target.checked) newAdmin.menu_list.push(menu.key);
                  else
                    newAdmin.menu_list = admin.menu_list.filter(
                      (list) => list !== menu.key
                    );

                  setAdmin(newAdmin);
                }}
              >
                {menu.value}
              </Checkbox>
            </div>
          ))}
        </div>
      </div>

      <div className="f-c-c s-e-b">
        <div
          className="f-c-c d-b-t-n-s d-bg-c-t"
          style={{ width: "190px", pointerEvents: isLoading ? "none" : null }}
          onClick={() => addEditData()}
        >
          {isLoading ? (
            <LoadingOutlined />
          ) : (
            `${adminId ? "Edit" : "Add New"} Superadmin`
          )}
        </div>
      </div>
    </div>
  );
};

export default SupAdminAddEdit;
