import axios from '../../config/axios';

const apiAMGetAdmin = async () => {
  try {
    const result = await axios.get('/admin/dropdown')
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiAMGetAdminList = async (get_all, cur_page, per_page, keyword) => {
  try {
    const result = await axios.get(`/admin/list?get_all=${get_all}&cur_page=${cur_page}&per_page=${per_page}&keyword=${keyword}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiAMAddAdminList = async (data) => {
  try {
    const result = await axios.post('/admin', data)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiAMDeleteAdminById = async (admin_id) => {
  try {
    const result = await axios.delete(`/admin?admin_id=${admin_id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiAMGetAdminById = async (admin_id) => {
  try {
    const result = await axios.get(`/admin?admin_id=${admin_id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiAMEditAdminById = async (data) => {
  try {
    const result = await axios.put('/admin', data)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiAMGetSupAdminList = async (get_all, cur_page, per_page, keyword) => {
  try {
    const result = await axios.get(`/sadmin/list?get_all=${get_all}&cur_page=${cur_page}&per_page=${per_page}&keyword=${keyword}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiAMAddSupAdminList = async (data) => {
  try {
    const result = await axios.post('/sadmin', data)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiAMDeleteSupAdminById = async (admin_id) => {
  try {
    const result = await axios.delete(`/sadmin?admin_id=${admin_id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiAMGetSupAdminById = async (admin_id) => {
  try {
    const result = await axios.get(`/sadmin?admin_id=${admin_id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiAMEditSupAdminById = async (data) => {
  try {
    const result = await axios.put('/sadmin', data)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

export {
  apiAMGetAdmin,

  apiAMGetAdminList,
  apiAMAddAdminList,
  apiAMDeleteAdminById,
  apiAMGetAdminById,
  apiAMEditAdminById,

  apiAMGetSupAdminList,
  apiAMAddSupAdminList,
  apiAMDeleteSupAdminById,
  apiAMGetSupAdminById,
  apiAMEditSupAdminById,
}