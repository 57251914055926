import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom';
import { Input, Avatar } from 'antd';
import './Machine.css'
import { LoadingOutlined } from '@ant-design/icons';

import { checkImageSize, openNotification, checkReqFieldWithId } from '../../utils/function'
import { apiMCHGetSubTypeById, apiMCHAddSubType, apiMCHEditSubTypeById } from '../../services/apis/machine'

const MachineSubTypeAddEditPage = () => {
  const history = useHistory();
  const { typeId, subTypeId } = useParams();
  const [isLoading, setIsLoading] = useState(false)
  const [machineSubType, setMachineSubType] = useState(undefined)

  const [isAlertName, setIsAlertName] = useState(false)
  const [isAlertImage, setIsAlertImage] = useState(false)

  useEffect(() => {
    if (subTypeId) getData()
  }, [subTypeId])

  const getData = async () => {
    let data = await apiMCHGetSubTypeById(subTypeId)
    if (data && data.status) setMachineSubType(data.result)
  }

  const addEditMachineSubType = async () => {
    let passIMF = checkReqFieldWithId(subTypeId, machineSubType && machineSubType.image_file)

    if (!(machineSubType && machineSubType.machine_sub_type_name) || !passIMF) {
      if (!(machineSubType && machineSubType.machine_sub_type_name)) setIsAlertName(true)
      if (!passIMF) setIsAlertImage(true)

    } else {
      setIsLoading(true)

      let data = new FormData()
      if (subTypeId) data.append('machine_sub_type_id', subTypeId)
      if (!subTypeId) data.append('machine_type_id', typeId)
      if (machineSubType.image_file) data.append('image', machineSubType.image_file)
      data.append('machine_sub_type_name', machineSubType.machine_sub_type_name || '')
      data.append('machine_sub_type_name_en', machineSubType.machine_sub_type_name_en || '')
      data.append('machine_sub_type_name_ch', machineSubType.machine_sub_type_name_ch || '')

      let newData = subTypeId ? await apiMCHEditSubTypeById(data) : await apiMCHAddSubType(data)
      if (newData && newData.status) {
        setIsLoading(false)
        openNotification('success', 'Success !', `${subTypeId ? 'Edit' : 'Add new'} machine sub type successful.`)
        setTimeout(() => history.push(`/machine/type/${typeId}/sub-type`), 1000);
      } else {
        setIsLoading(false)
        openNotification('error', 'Failed !', `Failed to ${subTypeId ? 'edit' : 'add new'} machine sub type.`)
      }
    }
  }

  return (
    <div>
      <div className="mg-bt-20 h-d-t-p">Machine Sub Type - {subTypeId ? 'Edit' : 'Add'}</div>

      <div className="t-m-c-i-p-g">
        <div className="f-s-c">ชื่อ</div>
        <div>
          <Input
            style={{ borderColor: isAlertName ? '#EC1C24' : null }}
            placeholder="ชื่อ"
            value={machineSubType && machineSubType.machine_sub_type_name || ''}
            onChange={e => {
              setIsAlertName(false)
              setMachineSubType({ ...machineSubType, machine_sub_type_name: e.target.value })
            }}
          />
        </div>

        <div className="f-s-c">ชื่อภาษาอังกฤษ</div>
        <div>
          <Input
            placeholder="ชื่อภาษาอังกฤษ"
            value={machineSubType && machineSubType.machine_sub_type_name_en || ''}
            onChange={e => setMachineSubType({ ...machineSubType, machine_sub_type_name_en: e.target.value })}
          />
        </div>

        <div className="f-s-c">ชื่อภาษาจีน</div>
        <div>
          <Input
            placeholder="ชื่อภาษาจีน"
            value={machineSubType && machineSubType.machine_sub_type_name_ch || ''}
            onChange={e => setMachineSubType({ ...machineSubType, machine_sub_type_name_ch: e.target.value })}
          />
        </div>

        <div className="f-s-c">Icon</div>
        <div>
          <input id="machine-type-image" accept="image/*" type="file" style={{ display: 'none' }}
            onChange={e => {
              setIsAlertImage(false)
              let passSize = checkImageSize(true, e.target.files[0])
              if (passSize) {
                const reader = new FileReader()
                reader.readAsDataURL(e.target.files[0])
                reader.onload = () => setMachineSubType({
                  ...machineSubType,
                  image_file: e.target.files[0],
                  machine_sub_type_icon: reader.result
                })
              }
            }} />
          <label htmlFor="machine-type-image">
            <div className={`antd-avt-al ${isAlertImage && 'antd-avt-al-bd' || ''}`}>
              <Avatar shape="square" size={150} className="cs-pt" src={machineSubType && machineSubType.machine_sub_type_icon || ''} icon={<i className="bi bi-image"></i>} />
            </div>
          </label>
        </div>
      </div>

      <div className="f-c-c s-e-b">
        <div className="f-c-c d-b-t-n-s d-bg-c-t"
          style={{ width: '230px', pointerEvents: isLoading ? 'none' : null }}
          onClick={() => addEditMachineSubType()}
        >{isLoading ? <LoadingOutlined /> : `${subTypeId ? 'Edit' : 'Add New'} Machine Sub Type`}</div>
      </div>

    </div>
  )
}

export default MachineSubTypeAddEditPage
