import axios from 'axios';
import { tw_Middleware } from './config';

const instance = axios.create({
  // baseURL: process.env.NODE_ENV === 'development' ? 'http://test.trendywash.net:9001/api/v1' : 'https://test.trendywash.net/api/v1'
  // baseURL: process.env.NODE_ENV === 'development' ? 'https://test.trendywash.net/api/v1' : 'https://backoffice.trendywash.net/api/v1'
  // baseURL: 'https://test.trendywash.net/api/v1'
  baseURL: tw_Middleware
});

export default instance;