import React, { useState, useEffect } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { Input, Select, Checkbox } from "antd";
import "./AdminManagement.css";
import { LoadingOutlined } from "@ant-design/icons";

import {
  openNotification,
  checkReqFieldWithId,
  getSearchParams,
} from "../../utils/function";
import { apiSGetSiteManagement } from "../../services/apis/site";
import {
  apiAMAddAdminList,
  apiAMGetAdminById,
  apiAMEditAdminById,
} from "../../services/apis/admin";

const { Option } = Select;

const AdminAddEdit = () => {
  const { adminId, siteId } = useParams();
  const location = useLocation();
  const history = useHistory();

  const params = new URLSearchParams(location.search);
  const p = params.get("p");
  const ttl = params.get("ttl");
  const sw = params.get("sw");

  const [isLoading, setIsLoading] = useState(false);
  const [admin, setAdmin] = useState(undefined);
  const [branchs, setBranchs] = useState([]);

  const menus = [{ key: "EXPORT", value: "Export" }];

  const [isAlertAdminName, setIsAlertAdminName] = useState(false);
  const [isAlertUsername, setIsAlertUsername] = useState(false);
  const [isAlertPassword, setIsAlertPassword] = useState(false);

  useEffect(() => {
    if (adminId) getData();
    else {
      setAdmin(undefined);
      setIsAlertAdminName(false);
      setIsAlertUsername(false);
      setIsAlertPassword(false);
    }
  }, [adminId]);

  const getData = async () => {
    let data = await apiAMGetAdminById(adminId);
    if (data && data.status) setAdmin(data.result);
  };

  useEffect(() => {
    const getStaticDropdown = async () => {
      let branch = await apiSGetSiteManagement(true);
      if (branch && branch.status) setBranchs(branch.result.data_list || []);
    };
    getStaticDropdown();
  }, []);

  useEffect(() => {
    if (siteId) {
      const dataNew = [];
      branchs?.filter((item) => {
        if (item?.site_id == siteId) {
          dataNew.push(item);
        }
      });
      if (dataNew?.length > 0)
        setAdmin({ ...admin, site_id_list: [dataNew[0]?.site_id] });
    }
  }, [siteId, branchs]);

  const addEditData = async () => {
    let passPW = checkReqFieldWithId(adminId, admin && admin.admin_pass);

    if (
      !(admin && admin.admin_name) ||
      !(admin && admin.admin_user) ||
      !passPW
    ) {
      if (!(admin && admin.admin_name)) setIsAlertAdminName(true);
      if (!(admin && admin.admin_user)) setIsAlertUsername(true);
      if (!passPW) setIsAlertPassword(true);
    } else {
      setIsLoading(true);

      let data = { ...admin };

      let newData = adminId
        ? await apiAMEditAdminById(data)
        : await apiAMAddAdminList(data);
      if (newData && newData.status) {
        setIsLoading(false);
        openNotification(
          "success",
          "Success !",
          `${adminId ? "Edit" : "Add new"} admin successful.`
        );
        setTimeout(
          () =>
            history.push(
              `/admin-management/list-admin${getSearchParams(
                ["p", "ttl", "sw"],
                [p, ttl, sw]
              )}`
            ),
          1000
        );
      } else {
        setIsLoading(false);
        openNotification(
          "error",
          "Failed !",
          `Failed to ${adminId ? "edit" : "add new"} admin.`
        );
      }
    }
  };

  return (
    <div>
      <div className="mg-bt-20 h-d-t-p">Admin - {adminId ? "Edit" : "Add"}</div>

      <div className="t-m-c-i-p-g">
        <div className="f-s-c">ชื่อเจ้าของสาขา</div>
        <div>
          <Input
            style={{ borderColor: isAlertAdminName ? "#EC1C24" : null }}
            placeholder="ชื่อเจ้าของสาขา"
            value={(admin && admin.admin_name) || ""}
            onChange={(e) => {
              setIsAlertAdminName(false);
              setAdmin({ ...admin, admin_name: e.target.value });
            }}
          />
        </div>

        <div className="f-s-c">อีเมลเจ้าของสาขา</div>
        <div>
          <Input
            placeholder="อีเมลเจ้าของสาขา"
            value={(admin && admin.admin_email) || ""}
            onChange={(e) =>
              setAdmin({ ...admin, admin_email: e.target.value })
            }
          />
        </div>

        <div className="f-s-c">เบอร์โทรเจ้าของสาขา</div>
        <div>
          <Input
            placeholder="เบอร์โทรเจ้าของสาขา"
            value={(admin && admin.admin_tel) || ""}
            onChange={(e) =>
              setAdmin({
                ...admin,
                admin_tel: e.target.value.replace(/[^0-9]+/g, ""),
              })
            }
          />
        </div>

        <div className="f-s-c">Username</div>
        <div>
          <Input
            style={{ borderColor: isAlertUsername ? "#EC1C24" : null }}
            placeholder="Username"
            value={(admin && admin.admin_user) || ""}
            onChange={(e) => {
              setIsAlertUsername(false);
              setAdmin({ ...admin, admin_user: e.target.value });
            }}
          />
        </div>

        <div className="f-s-c">Password</div>
        <div>
          <Input.Password
            style={{ borderColor: isAlertPassword ? "#EC1C24" : null }}
            placeholder="Password"
            value={(admin && admin.admin_pass) || ""}
            onChange={(e) => {
              setIsAlertPassword(false);
              setAdmin({ ...admin, admin_pass: e.target.value });
            }}
          />
        </div>

        <div className="f-s-c">Branch</div>
        <div>
          <Select
            style={{ width: "100%" }}
            placeholder="Branch"
            mode="multiple"
            showArrow
            value={admin && admin.site_id_list}
            onChange={(value) => setAdmin({ ...admin, site_id_list: value })}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {branchs.map((branch, idx) => (
              <Option key={idx} value={branch.site_id}>
                {branch.site_name}
              </Option>
            ))}
          </Select>
        </div>

        <div className="f-s-c">Menu</div>
        <div>
          {menus.map((menu, idx) => (
            <div key={idx} className="f-s-c antd-chb-fz">
              <Checkbox
                checked={((admin && admin.menu_list) || []).includes(menu.key)}
                onChange={(e) => {
                  let newAdmin = { ...admin };
                  if (!newAdmin.menu_list) newAdmin.menu_list = [];

                  if (e.target.checked) newAdmin.menu_list.push(menu.key);
                  else
                    newAdmin.menu_list = admin.menu_list.filter(
                      (list) => list !== menu.key
                    );

                  setAdmin(newAdmin);
                }}
              >
                {menu.value}
              </Checkbox>
            </div>
          ))}
        </div>
      </div>

      <div className="f-c-c s-e-b">
        <div
          className="f-c-c d-b-t-n-s d-bg-c-t"
          style={{ width: "160px", pointerEvents: isLoading ? "none" : null }}
          onClick={() => addEditData()}
        >
          {isLoading ? (
            <LoadingOutlined />
          ) : (
            `${adminId ? "Edit" : "Add New"} Admin`
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminAddEdit;
