import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom';
import { Input, Avatar } from 'antd';
import './Machine.css'
import { LoadingOutlined } from '@ant-design/icons';

import { checkImageSize, openNotification, checkReqFieldWithId } from '../../utils/function'
import { apiMCHGetTypeById, apiMCHAddType, apiMCHEditTypeById } from '../../services/apis/machine'

const MachineTypeAddEditPage = () => {
  const history = useHistory();
  const { typeId } = useParams();
  const [isLoading, setIsLoading] = useState(false)
  const [machineType, setMachineType] = useState(undefined)

  const [isAlertName, setIsAlertName] = useState(false)
  const [isAlertImage, setIsAlertImage] = useState(false)

  useEffect(() => {
    if (typeId) getData()
  }, [typeId])

  const getData = async () => {
    let data = await apiMCHGetTypeById(typeId)
    if (data && data.status) setMachineType(data.result)
  }

  const addEditMachineType = async () => {
    let passIMF = checkReqFieldWithId(typeId, machineType && machineType.image_file)

    if (!(machineType && machineType.machine_type_name) || !passIMF) {
      if (!(machineType && machineType.machine_type_name)) setIsAlertName(true)
      if (!passIMF) setIsAlertImage(true)

    } else {
      setIsLoading(true)

      let data = new FormData()
      if (typeId) data.append('machine_type_id', typeId)
      if (machineType.image_file) data.append('image', machineType.image_file)
      data.append('machine_type_name', machineType.machine_type_name || '')
      data.append('machine_type_name_en', machineType.machine_type_name_en || '')
      data.append('machine_type_name_ch', machineType.machine_type_name_ch || '')

      let newData = typeId ? await apiMCHEditTypeById(data) : await apiMCHAddType(data)
      if (newData && newData.status) {
        setIsLoading(false)
        openNotification('success', 'Success !', `${typeId ? 'Edit' : 'Add new'} machine type successful.`)
        setTimeout(() => history.push('/machine/type'), 1000);
      } else {
        setIsLoading(false)
        openNotification('error', 'Failed !', `Failed to ${typeId ? 'edit' : 'add new'} machine type.`)
      }
    }
  }

  return (
    <div>
      <div className="mg-bt-20 h-d-t-p">Machine Type - {typeId ? 'Edit' : 'Add'}</div>

      <div className="t-m-c-i-p-g">
        <div className="f-s-c">ชื่อ</div>
        <div>
          <Input
            style={{ borderColor: isAlertName ? '#EC1C24' : null }}
            placeholder="ชื่อ"
            value={machineType && machineType.machine_type_name || ''}
            onChange={e => {
              setIsAlertName(false)
              setMachineType({ ...machineType, machine_type_name: e.target.value })
            }}
          />
        </div>

        <div className="f-s-c">ชื่อภาษาอังกฤษ</div>
        <div>
          <Input
            placeholder="ชื่อภาษาอังกฤษ"
            value={machineType && machineType.machine_type_name_en || ''}
            onChange={e => setMachineType({ ...machineType, machine_type_name_en: e.target.value })}
          />
        </div>

        <div className="f-s-c">ชื่อภาษาจีน</div>
        <div>
          <Input
            placeholder="ชื่อภาษาจีน"
            value={machineType && machineType.machine_type_name_ch || ''}
            onChange={e => setMachineType({ ...machineType, machine_type_name_ch: e.target.value })}
          />
        </div>

        <div className="f-s-c">Icon</div>
        <div>
          <input id="machine-type-image" accept="image/*" type="file" style={{ display: 'none' }}
            onChange={e => {
              setIsAlertImage(false)
              let passSize = checkImageSize(true, e.target.files[0])
              if (passSize) {
                const reader = new FileReader()
                reader.readAsDataURL(e.target.files[0])
                reader.onload = () => setMachineType({
                  ...machineType,
                  image_file: e.target.files[0],
                  machine_type_icon: reader.result
                })
              }
            }} />
          <label htmlFor="machine-type-image">
            <div className={`antd-avt-al ${isAlertImage && 'antd-avt-al-bd' || ''}`}>
              <Avatar shape="square" size={150} className="cs-pt" src={machineType && machineType.machine_type_icon || ''} icon={<i className="bi bi-image"></i>} />
            </div>
          </label>
        </div>
      </div>

      <div className="f-c-c s-e-b">
        <div className="f-c-c d-b-t-n-s d-bg-c-t"
          style={{ width: '200px', pointerEvents: isLoading ? 'none' : null }}
          onClick={() => addEditMachineType()}
        >{isLoading ? <LoadingOutlined /> : `${typeId ? 'Edit' : 'Add New'} Machine Type`}</div>
      </div>

    </div>
  )
}

export default MachineTypeAddEditPage
