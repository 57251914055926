import React, { useState, useEffect } from 'react'
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { Input, Select, Checkbox } from 'antd';
import './CallSupport.css'
import { LoadingOutlined } from '@ant-design/icons';

import moment from 'moment';
import 'moment/locale/th'

import { openNotification, getSearchParams, checkIfIdEqualToZero } from '../../utils/function'
import { apiSGetSite } from '../../services/apis/site'
import { apiMCHGetTypeBySiteId, apiMCHGetNameBySiteId } from '../../services/apis/machine'
import { apiCSPGetStatus, apiCSPAddMaintenance, apiCSPGetMaintenanceById, apiCSPEditMaintenance } from '../../services/apis/call'
import { apiMTNGetType, apiMTNGetMaintainer } from '../../services/apis/maintenance'

const { Option } = Select;

const MaintenanceAddEdit = () => {
  const { callMaintenanceId } = useParams();
  const location = useLocation();
  const history = useHistory();

  const params = new URLSearchParams(location.search)
  const p = params.get('p')
  const ttl = params.get('ttl')
  const start = params.get('start')
  const end = params.get('end')
  const sw = params.get('sw')

  const [isLoading, setIsLoading] = useState(false)
  const [call, setCall] = useState(undefined)
  const [sites, setSites] = useState([])
  const [machineTypes, setMachineTypes] = useState([])
  const [statuses, setStatuses] = useState([])
  const [machineNames, setMachineNames] = useState([])
  const [maintenances, setMaintenances] = useState([])
  const [assignees, setAssignees] = useState([])
  const [time, setTime] = useState(moment())

  const [isAlertProblem, setIsAlertProblem] = useState(false)
  // const [isAlertDetail, setIsAlertDetail] = useState(false)

  useEffect(() => {
    if (callMaintenanceId) getData()
    else {
      setCall(undefined)
      setIsAlertProblem(false)
      // setIsAlertDetail(false)
    }
  }, [callMaintenanceId])

  useEffect(() => {
    if (!call) setCall({ ...call, call_channel: 'Line OA' })
  }, [call])

  const getData = async () => {
    let data = await apiCSPGetMaintenanceById(callMaintenanceId)
    if (data && data.status) setCall(data.result)
  }

  useEffect(() => {
    const getStaticDropdown = async () => {
      let site = await apiSGetSite()
      if (site && site.status) setSites(site.result || [])

      let status = await apiCSPGetStatus('')
      if (status && status.status) setStatuses(status.result || [])

      let type = await apiMTNGetType()
      if (type && type.status) setMaintenances(type.result || [])

      let assignee = await apiMTNGetMaintainer()
      if (assignee && assignee.status) setAssignees(assignee.result || [])
    }
    getStaticDropdown()
  }, [])


  useEffect(() => {
    if (callMaintenanceId) {
      setTime()
    } else {
      const timer = setInterval(() => setTime(moment()), 1000);
      return () => clearInterval(timer);
    }
  }, [callMaintenanceId]);

  useEffect(() => {
    if (checkIfIdEqualToZero(call && call.site_id)) {
      const getMachineData = async () => {
        let type = await apiMCHGetTypeBySiteId(call.site_id)
        if (type && type.status) setMachineTypes(type.result || [])
      }
      getMachineData()
    }
  }, [call && call.site_id])

  useEffect(() => {
    if (checkIfIdEqualToZero(call && call.site_id) && call.machine_type_id) {
      const getMachineData = async () => {
        let name = await apiMCHGetNameBySiteId(call.site_id, call.machine_type_id)
        if (name && name.status) setMachineNames(name.result || [])
      }
      getMachineData()
    }
  }, [call && call.site_id, call && call.machine_type_id])

  const addEditData = async () => {
    if (!(call && call.ticket_type_id)
      // !(call && call.detail)

    ) {
      if (!(call && call.ticket_type_id)) setIsAlertProblem(true)
      // if (!(call && call.detail)) setIsAlertDetail(true)

    } else {
      setIsLoading(true)

      let data = { ...call }
      if (!callMaintenanceId) {
        data.admin_id = Number(localStorage.getItem('user_id'))
        data.create_date = moment(time).format('YYYY-MM-DD HH:mm:ss')
      }
      // console.log(data)
      let newData = callMaintenanceId ? await apiCSPEditMaintenance(data) : await apiCSPAddMaintenance(data)
      if (newData && newData.status) {
        setIsLoading(false)
        openNotification('success', 'Success !', `${callMaintenanceId ? 'Edit' : 'Add new'} call support maintenance successful.`)
        setTimeout(() => history.push(`/call-support/list-maintenance${getSearchParams(['p', 'ttl', 'start', 'end', 'sw'], [p, ttl, start, end, sw])}`), 1000);
      } else {
        setIsLoading(false)
        openNotification('error', 'Failed !', `Failed to ${callMaintenanceId ? 'edit' : 'add new'} call support maintenance.`)
      }
    }
  }

  return (
    <div>
      <div className="mg-bt-20 h-d-t-p">Call Support Maintenance - {callMaintenanceId ? 'Edit' : 'Add'}</div>

      <div className="t-m-c-i-p-g">
        <div className="f-s-c">ผู้รับ</div>
        <div>{callMaintenanceId ? call && call.admin_name : localStorage.getItem('user_name') || '-'}</div>

        <div className="f-s-c">วันเดือนปี</div>
        <div>{callMaintenanceId
          ? call && call.create_date
            ? moment(call.create_date).add(543, 'years').format('D MMM YYYY')
            : '-'
          : moment(time).add(543, 'years').format('D MMM YYYY')}</div>

        <div className="f-s-c">เวลา</div>
        <div>{callMaintenanceId
          ? call && call.create_date
            ? moment(call.create_date).add(543, 'years').format('HH:mm:ss')
            : '-'
          : moment(time).add(543, 'years').format('HH:mm:ss')}</div>

        <div className="f-s-c">ช่องทางการแจ้ง</div>
        <div>
          <Select style={{ width: '100%' }}
            placeholder="ช่องทางการแจ้ง"
            value={call && call.call_channel || undefined}
            onChange={value => setCall({ ...call, call_channel: value })}
          >
            <Option value="Line OA">Line OA</Option>
            <Option value="Line Group">Line Group</Option>
            <Option value="Facebook">Facebook</Option>
            <Option value="Call">Call</Option>
            <Option value="Email">Email</Option>
          </Select>
        </div>

        <div className="f-s-c">สาขา</div>
        <div>
          <Select style={{ width: '100%' }}
            placeholder="สาขา"
            showSearch
            value={call && call.site_id}
            onChange={value => setCall({
              ...call,
              site_id: value,
              machine_type_id: undefined,
              machine_id: undefined,
            })}
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {sites.map((site, idx) => <Option key={idx} value={site.site_id}>{site.site_name}</Option>)}
          </Select>
        </div>

        <div className="f-s-c">ประเภทเครื่อง</div>
        <div>
          <Select style={{ width: '100%' }}
            placeholder="ประเภทเครื่อง"
            disabled={!(checkIfIdEqualToZero(call && call.site_id))}
            value={call && call.machine_type_id}
            onChange={value => setCall({
              ...call,
              machine_type_id: value,
              machine_id: undefined,
            })}
          >
            {machineTypes.map((type, idx) => <Option key={idx} value={type.machine_type_id}>{type.machine_type_name}</Option>)}
          </Select>
        </div>

        <div className="f-s-c">ชื่อเครื่อง</div>
        <div className="call-go-page-grid">
          <div>
            <Select style={{ width: '100%' }}
              placeholder="ชื่อเครื่อง"
              showSearch
              disabled={!(call && call.machine_type_id)}
              value={call && call.machine_id}
              onChange={value => setCall({ ...call, machine_id: value })}
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {machineNames.map((name, idx) => <Option key={idx} value={name.machine_id}>{name.machine_name}</Option>)}
            </Select>
          </div>
          <div className="f-c-c d-b-t-n-s d-bg-c-t"
            onClick={() => window.open('/machine/list', '_blank')}
          >ไปที่หน้า Machine</div>
        </div>

        <div className="f-s-c">ประเภทปัญหา</div>
        <div className={isAlertProblem && 'antd-sl-al-bd' || ''}>
          <Select style={{ width: '100%' }}
            placeholder="ประเภทปัญหา"
            value={call && call.ticket_type_id}
            onChange={value => {
              setIsAlertProblem(false)
              setCall({ ...call, ticket_type_id: value })
            }}
          >
            {maintenances.map((maintenance, idx) => <Option key={idx} value={maintenance.maintenance_type_id}>{maintenance.maintenance_type_name}</Option>)}
          </Select>
        </div>

        <div className="f-s-c">อาการ</div>
        <div>
          <Input.TextArea
            // style={{ borderColor: isAlertDetail ? '#EC1C24' : null }}
            autoSize={{ minRows: 3 }}
            placeholder="อาการ"
            value={call && call.detail || ''}
            onChange={e => {
              // setIsAlertDetail(false)
              setCall({ ...call, detail: e.target.value })
            }}
          />
        </div>

        <div className="f-s-c">หมายเหตุ</div>
        <div>
          <Input.TextArea
            autoSize={{ minRows: 3 }}
            placeholder="หมายเหตุ"
            value={call && call.remark || ''}
            onChange={e => setCall({ ...call, remark: e.target.value })}
          />
        </div>

        <div className="f-s-c">สถานะ</div>
        <div>
          <Select style={{ width: '100%' }}
            placeholder="สถานะ"
            value={call && call.status_id}
            onChange={value => setCall({ ...call, status_id: value })}
          >
            {statuses.map((status, idx) => <Option key={idx} value={status.id}>{status.name}</Option>)}
          </Select>
        </div>

        <div className="f-s-c">Assignee</div>
        <div>
          <Select style={{ width: '100%' }}
            placeholder="Assignee"
            mode="multiple"
            showArrow
            value={call && call.maintainer_list || []}
            onChange={value => {
              setCall({ ...call, maintainer_list: value })
            }}
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {assignees.map((assignee, idx) => <Option key={idx} value={assignee.maintainer_id}>{assignee.maintainer_name}</Option>)}
          </Select>
        </div>



        <div className="f-s-c">ส่งเรื่องให้ช่าง</div>
        <div className="f-s-c">
          <Checkbox
            checked={call && call.is_ticket}
            onChange={e => setCall({ ...call, is_ticket: e.target.checked })}
          />
        </div>

        <div className="f-s-c">ชื่อผู้ติดต่อ</div>
        <div className="call-go-page-grid">
          <div>
            <Input
              placeholder="ชื่อผู้ติดต่อ"
              value={call && call.name || ''}
              onChange={e => setCall({ ...call, name: e.target.value })}
            />
          </div>
          <div className="f-c-c d-b-t-n-s d-bg-c-t"
            onClick={() => window.open('/customer/list', '_blank')}
          >ไปที่หน้า Customer</div>
        </div>

        <div className="f-s-c">เบอร์โทร</div>
        <div>
          <Input
            placeholder="เบอร์โทร"
            value={call && call.phone || ''}
            onChange={e => setCall({ ...call, phone: e.target.value.replace(/[^0-9]+/g, '') })}
          />
        </div>
      </div>

      <div className="f-c-c s-e-b">
        <div className="f-c-c d-b-t-n-s d-bg-c-t"
          style={{ width: '100px', pointerEvents: isLoading ? 'none' : null }}
          onClick={() => addEditData()}
        >{isLoading ? <LoadingOutlined /> : 'Submit'}</div>
      </div>

    </div>
  )
}

export default MaintenanceAddEdit
