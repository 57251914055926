import React, { useState, useEffect } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Input, Table, Pagination, Tooltip, Select } from 'antd';
import './Maintenance.css'
import XLSX from "xlsx";
import { LoadingOutlined } from '@ant-design/icons';

import moment from 'moment';
import 'moment/locale/th'

import { colors } from '../../config/config'
import { numberWithCommas, getSearchParams, diffTime } from '../../utils/function'
import { apiSGetSite } from '../../services/apis/site'
import { apiMCHGetTypeBySiteId } from '../../services/apis/machine'
import { apiMTNGetSupportOffline, apiMTNGetType, apiMTNGetTicket } from '../../services/apis/maintenance'

const { Option } = Select;

const Support = () => {
  const history = useHistory();
  const location = useLocation();

  const params = new URLSearchParams(location.search)
  const olp = params.get('olp')
  const olttl = params.get('olttl')
  const olmch = params.get('olmch')
  const ols = params.get('ols')
  const olsw = params.get('olsw')
  const pdp = params.get('pdp')
  const pdttl = params.get('pdttl')
  const pdmtn = params.get('pdmtn')
  const pdmch = params.get('pdmch')
  const pds = params.get('pds')
  const pdsw = params.get('pdsw')

  const [isLoadingOL, setIsLoadingOL] = useState(false)
  const [currentPageOL, setCurrentPageOL] = useState(Number(olp) || 1)
  const [perPageOL, setPerPageOL] = useState(Number(olttl) || 10)
  const [tableDataOL, setTableDataOL] = useState(undefined)
  const [searchWordOL, setSearchWordOL] = useState(olsw || '')
  const [searchButtonOL, setSearchButtonOL] = useState(false)
  const [waitingForCSVOL, setWaitingForCSVOL] = useState(false)

  const [isLoadingPD, setIsLoadingPD] = useState(false)
  const [currentPagePD, setCurrentPagePD] = useState(Number(pdp) || 1)
  const [perPagePD, setPerPagePD] = useState(Number(pdttl) || 10)
  const [tableDataPD, setTableDataPD] = useState(undefined)
  const [searchWordPD, setSearchWordPD] = useState(pdsw || '')
  const [searchButtonPD, setSearchButtonPD] = useState(false)
  const [waitingForCSVPD, setWaitingForCSVPD] = useState(false)

  const [sumaries, setSumaries] = useState([])

  const [machines, setMachines] = useState([])
  const [machineOL, setMachineOL] = useState(Number(olmch) || '')
  const [sites, setSites] = useState([])
  const [siteOL, setSiteOL] = useState(Number(ols) || '')

  const [maintenances, setMaintenances] = useState([])
  const [maintenancePD, setMaintenancePD] = useState(Number(pdmtn) || '')
  const [machinePD, setMachinePD] = useState(Number(pdmch) || '')
  const [sitePD, setSitePD] = useState(Number(pds) || '')

  useEffect(() => {
    const getStaticDropdown = async () => {
      let type = await apiMTNGetType()
      if (type && type.status) setMaintenances(type.result || [])

      let machine = await apiMCHGetTypeBySiteId()
      if (machine && machine.status) setMachines(machine.result || [])

      let site = await apiSGetSite()
      if (site && site.status) setSites(site.result || [])
    }
    getStaticDropdown()
  }, [])

  useEffect(() => {
    getTableDataOL()
    history.push(`${location.pathname}${getSearchParams(['olp', 'olttl', 'olmch', 'ols', 'olsw', 'pdp', 'pdttl', 'pdmtn', 'pdmch', 'pds', 'pdsw'], [currentPageOL, perPageOL, machineOL, siteOL, searchWordOL, currentPagePD, perPagePD, maintenancePD, machinePD, sitePD, searchWordPD])}`)
  }, [currentPageOL, perPageOL, searchButtonOL])

  useEffect(() => {
    getTableDataPD()
    history.push(`${location.pathname}${getSearchParams(['olp', 'olttl', 'olmch', 'ols', 'olsw', 'pdp', 'pdttl', 'pdmtn', 'pdmch', 'pds', 'pdsw'], [currentPageOL, perPageOL, machineOL, siteOL, searchWordOL, currentPagePD, perPagePD, maintenancePD, machinePD, sitePD, searchWordPD])}`)
  }, [currentPagePD, perPagePD, searchButtonPD])

  const getTableDataOL = async () => {
    setIsLoadingOL(true)
    let table = await apiMTNGetSupportOffline(false, currentPageOL, perPageOL, searchWordOL, machineOL, siteOL)
    if (table && table.status) {
      setIsLoadingOL(false)
      setTableDataOL(table.result)
    }
  }

  const getTableDataPD = async () => {
    setIsLoadingPD(true)
    let table = await apiMTNGetTicket(false, currentPagePD, perPagePD, searchWordPD, maintenancePD, machinePD, sitePD)
    if (table && table.status) {
      setIsLoadingPD(false)
      setTableDataPD(table.result)
    }
  }

  useEffect(() => {
    if (tableDataOL && tableDataPD) setSumaries([...(tableDataOL.sumary || []), ...(tableDataPD.sumary || [])])
  }, [tableDataOL && tableDataPD])

  const columnsOL = [
    {
      title: '#',
      ellipsis: true,
      align: 'center',
      width: '5%',
      render: (data, record, index) => <div>{(index + 1) + ((currentPageOL - 1) * perPageOL)}</div>
    },
    {
      title: 'ประเภท',
      ellipsis: true,
      render: (data) => <div>{data.machine_type || '-'}</div>
    },
    {
      title: 'สถานที่',
      ellipsis: true,
      render: (data) => <div>{data.site_name || '-'}</div>
    },
    {
      title: 'ประเภทย่อย',
      ellipsis: true,
      render: (data) => <div>{data.machine_sub_type || '-'}</div>
    },
    {
      title: 'ชื่อ',
      ellipsis: true,
      render: (data) => <div>{data.machine_name || '-'}</div>
    },
    {
      title: 'รหัส',
      ellipsis: true,
      render: (data) => <div>{data.machine_mac_address || '-'}</div>
    },
    {
      title: 'ออนไลน์ล่าสุด',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{data.lastupdate ? moment(data.lastupdate).fromNow() : '-'}</div>
    },
    {
      title: 'สถานะการซ่อม',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{data.maintenance_status || '-'}</div>
    },
    {
      title: 'ตั้งค่า',
      ellipsis: true,
      align: 'center',
      fixed: 'right',
      render: (data) => <Tooltip placement="top" title="แจ้งซ่อม">
        <div className="f-c-c d-b-t-n-s b-c-o-i" style={{ backgroundColor: '#FFCB05' }}
          onClick={e => {
            e.stopPropagation()
            history.push(`/maintenance/create-ticket${getSearchParams(['olp', 'olttl', 'olmch', 'ols', 'olsw', 'olats', 'olatmch'], [currentPageOL, 100, machineOL, siteOL, searchWordOL, String(data.site_id), String(data.machine_id)])}`)
          }}
        ><i className="fas fa-comments"></i></div>
      </Tooltip>
    },
  ];

  const columnsPD = [
    {
      title: '#',
      ellipsis: true,
      align: 'center',
      width: '5%',
      render: (data) => <div>{data.maintenance_id || '-'}</div>
    },
    {
      title: 'Flag',
      ellipsis: true,
      align: 'center',
      width: '80px',
      render: (data) => <div className="f-c-c d-b-t-n-s b-c-o-i"
        style={{
          backgroundColor: data.maintenance_type_name === 'บำรุงรักษา'
            ? colors.general.green
            : getFlagColor(moment().diff(moment(data.start_date), 'hours'))
        }}
      />
    },
    {
      title: 'สถานะ',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{data.status || '-'}</div>
    },
    {
      title: 'วันที่แจ้ง',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{data.start_date ? moment(data.start_date).add(543, 'years').format('D MMM YYYY HH:mm:ss') : '-'}</div>
    },
    {
      title: 'ประเภท',
      ellipsis: true,
      render: (data) => <div>{data.maintenance_type_name || '-'}</div>
    },
    {
      title: 'สถานที่',
      ellipsis: true,
      render: (data) => <div>{data.site_name || '-'}</div>
    },
    {
      title: 'เครื่อง',
      ellipsis: true,
      render: (data) => <div>
        {data.machine_name
          ? data.machine_name.split(',').map((list, idx) => <div key={idx}>{list}</div>)
          : '-'}
      </div>
    },
    {
      title: 'จำนวนครั้ง',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{numberWithCommas(data.used_count || 0, 0)}</div>
    },
    {
      title: 'อาการ',
      // ellipsis: true,
      width: '500px',
      render: (data) => <div>{data.detail || '-'}</div>
    },
    {
      title: 'แก้ไขโดย',
      ellipsis: true,
      render: (data) => <div>
        {data.assignee
          ? data.assignee.split(',').map((list, idx) => <div key={idx}>{list}</div>)
          : '-'}
      </div>
    },
    {
      title: 'ระยะเวลา',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{data.start_date ? diffTime(data.start_date, moment()) : '-'}</div>
    },
    {
      title: 'ตั้งค่า',
      ellipsis: true,
      align: 'center',
      fixed: 'right',
      render: (data) => <Tooltip placement="top" title="Update">
        <div className="f-c-c d-b-t-n-s b-c-o-i" style={{ backgroundColor: '#00ADEF' }}
          onClick={e => {
            e.stopPropagation()
            history.push(`/maintenance/ticket/update/${data.maintenance_id}${getSearchParams(['pdp', 'pdttl', 'pdmtn', 'pdmch', 'pds', 'pdsw'], [currentPagePD, 100, maintenancePD, machinePD, sitePD, searchWordPD])}`)
          }}
        ><i className="fas fa-user-edit"></i></div>
      </Tooltip>,
    },
  ];

  const getFlagColor = value => {
    if (value > 48) return colors.general.red
    else if (value > 24) return colors.general.orange
    else return colors.general.green
  }

  const getFlagWord = value => {
    if (value > 48) return 'Red'
    else if (value > 24) return 'Orange'
    else return 'Green'
  }

  const toCSVFileOL = async () => {
    setWaitingForCSVOL(true)
    let data = await apiMTNGetSupportOffline(true, '', '', searchWordOL, '', '')
    let csvData = [];

    if (data && data.status) {
      (data.result.data_list || []).map((data, idx) => {
        let resultRow = {}
        resultRow['#'] = idx + 1
        resultRow['ประเภท'] = data.machine_type || '-'
        resultRow['สถานที่'] = data.site_name || '-'
        resultRow['ประเภทย่อย'] = data.machine_sub_type || '-'
        resultRow['ชื่อ'] = data.machine_name || '-'
        resultRow['รหัส'] = data.machine_mac_address || '-'
        resultRow['สถานะการซ่อม'] = data.maintenance_status || '-'
        csvData.push(resultRow);
      })

      const toCSV = XLSX.utils.json_to_sheet(csvData);
      const wb = XLSX.utils.book_new();

      const fileName = 'MAINTENANCE_SUPPORT'
      XLSX.utils.book_append_sheet(wb, toCSV, fileName);
      XLSX.writeFile(wb, fileName + ".csv");

      setWaitingForCSVOL(false)
    }
  }

  const toCSVFilePD = async () => {
    setWaitingForCSVPD(true)
    let data = await apiMTNGetTicket(true, '', '', searchWordPD, '', '', '')
    let csvData = [];

    if (data && data.status) {
      (data.result.data_list || []).map((data, idx) => {
        let resultRow = {}
        resultRow['#'] = idx + 1
        resultRow['Flag'] = data.maintenance_type_name === 'บำรุงรักษา'
          ? 'Green'
          : getFlagWord(moment().diff(moment(data.start_date), 'hours'))
        resultRow['วันที่แจ้ง'] = data.start_date || '-'
        resultRow['ประเภท'] = data.maintenance_type_name || '-'
        resultRow['สถานที่'] = data.site_name || '-'
        resultRow['เครื่อง'] = data.machine_name || '-'
        resultRow['จำนวนครั้ง'] = data.used_count || 0
        resultRow['อาการ'] = data.detail || '-'
        resultRow['สถานะ'] = data.status || '-'
        resultRow['แก้ไขโดย'] = data.assignee || '-'
        resultRow['ระยะเวลา'] = data.start_date ? diffTime(data.start_date, moment()) : '-'
        csvData.push(resultRow);
      })

      const toCSV = XLSX.utils.json_to_sheet(csvData);
      const wb = XLSX.utils.book_new();

      const fileName = 'MAINTENANCE_TICKET'
      XLSX.utils.book_append_sheet(wb, toCSV, fileName);
      XLSX.writeFile(wb, fileName + ".csv");

      setWaitingForCSVPD(false)
    }
  }

  return (
    <div>
      <div className="mg-bt-20 coin-collect-title">
        <div className="h-d-t-p">Maintenance</div>
        <div className="coin-collect-card-list">
          {sumaries.map((list, idx) => <div key={idx} className="c-c-t-s">
            <div className="coin-collect-card">
              <div className="coin-collect-card-grid">
                <div>{list.name}</div>
                <div>{numberWithCommas(list.value || 0, 0)}</div>
              </div>
            </div>
          </div>)}
        </div>
      </div>

      <div className="maintenance-header-grid maintenance-header-grid-support">
        <div className="ovf-hd maintenance-header-grid-support-machine">
          <Select style={{ width: '100%' }}
            value={machineOL}
            onChange={value => setMachineOL(value)}
          >
            <Option value="">All Machine Type</Option>
            {machines.map((machine, idx) => <Option key={idx} value={machine.machine_type_id}>{machine.machine_type_name}</Option>)}
          </Select>
        </div>
        <div className="ovf-hd maintenance-header-grid-support-site">
          <Select style={{ width: '100%' }}
            showSearch
            value={siteOL}
            onChange={value => setSiteOL(value)}
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            <Option value="">All Site</Option>
            {sites.map((site, idx) => <Option key={idx} value={site.site_id}>{site.site_name}</Option>)}
          </Select>
        </div>
        <div className="maintenance-header-grid-support-search">
          <Input
            placeholder="search .."
            value={searchWordOL}
            onChange={e => setSearchWordOL(e.target.value)}
            onPressEnter={() => {
              setCurrentPageOL(1)
              setSearchButtonOL(!searchButtonOL)
            }}
          />
        </div>
        <div className="f-c-c d-b-t-n-s d-bg-c-t"
          onClick={() => {
            setCurrentPageOL(1)
            setSearchButtonOL(!searchButtonOL)
          }}>
          <i className="bi bi-search" style={{ lineHeight: '0' }}></i>
        </div>
        <Link to="/maintenance/support/offline" className="h-32 maintenance-header-grid-support-all">
          <div className="f-c-c d-b-t-n-s d-bg-c-t" style={{ height: '100%' }}>ดูงานออฟไลน์ทั้งหมด</div>
        </Link>
        {/* <Link to={`/maintenance/support${getSearchParams(['olp', 'olttl', 'olmch', 'ols', 'olsw'], [currentPageOL, perPageOL, machineOL, siteOL, searchWordOL])}`}> */}
        <div className="f-c-c d-b-t-n-s d-bg-c-t h-32" style={{ height: '100%' }}>เปิดงานซ่อม</div>
        {/* </Link> */}
        <div className="f-c-c d-b-t-n-s d-bg-c-t h-32 maintenance-header-grid-support-export"
          style={{ pointerEvents: waitingForCSVOL ? 'none' : null }}
          onClick={() => toCSVFileOL()}
        >
          {waitingForCSVOL ? <LoadingOutlined /> : 'Export CSV'}
        </div>
      </div>

      <div>Maintenance - Offline</div>

      <Table bordered
        size="small"
        rowKey={record => record.machine_id}
        loading={isLoadingOL}
        dataSource={tableDataOL && tableDataOL.data_list || []}
        columns={columnsOL}
        pagination={false}
        scroll={{ x: true }}
      />

      <div className="pgnt-st">
        <Pagination
          size="small"
          current={currentPageOL}
          onChange={page => setCurrentPageOL(page)}
          total={tableDataOL && tableDataOL.max_total_rows}
          pageSize={perPageOL}
          showSizeChanger={false}
        />
      </div>

      <div className="mg-t-50 maintenance-header-grid maintenance-header-grid-ticket">
        <div className="ovf-hd maintenance-header-grid-ticket-select">
          <Select style={{ width: '100%' }}
            value={maintenancePD}
            onChange={value => setMaintenancePD(value)}
          >
            <Option value="">All Ticket Type</Option>
            {maintenances.map((maintenance, idx) => <Option key={idx} value={maintenance.maintenance_type_id}>{maintenance.maintenance_type_name}</Option>)}
          </Select>
        </div>
        <div className="ovf-hd maintenance-header-grid-ticket-machine">
          <Select style={{ width: '100%' }}
            value={machinePD}
            onChange={value => setMachinePD(value)}
          >
            <Option value="">All Machine Type</Option>
            {machines.map((machine, idx) => <Option key={idx} value={machine.machine_type_id}>{machine.machine_type_name}</Option>)}
          </Select>
        </div>
        <div className="ovf-hd maintenance-header-grid-ticket-select">
          <Select style={{ width: '100%' }}
            showSearch
            value={sitePD}
            onChange={value => setSitePD(value)}
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            <Option value="">All Site</Option>
            {sites.map((site, idx) => <Option key={idx} value={site.site_id}>{site.site_name}</Option>)}
          </Select>
        </div>
        <div className="maintenance-header-grid-ticket-search">
          <Input
            placeholder="search .."
            value={searchWordPD}
            onChange={e => setSearchWordPD(e.target.value)}
            onPressEnter={() => {
              setCurrentPagePD(1)
              setSearchButtonPD(!searchButtonPD)
            }}
          />
        </div>
        <div className="f-c-c d-b-t-n-s d-bg-c-t"
          onClick={() => {
            setCurrentPagePD(1)
            setSearchButtonPD(!searchButtonPD)
          }}>
          <i className="bi bi-search" style={{ lineHeight: '0' }}></i>
        </div>
        <Link to="/maintenance/ticket">
          <div className="f-c-c d-b-t-n-s d-bg-c-t h-32" style={{ height: '100%' }}>ดูงานซ่อมทั้งหมด</div>
        </Link>
        <div className="f-c-c d-b-t-n-s d-bg-c-t h-32 maintenance-header-grid-ticket-export"
          style={{ pointerEvents: waitingForCSVPD ? 'none' : null }}
          onClick={() => toCSVFilePD()}
        >
          {waitingForCSVPD ? <LoadingOutlined /> : 'Export CSV'}
        </div>
      </div>

      <div>Maintenance - Pending</div>

      <Table bordered
        size="small"
        rowKey={record => record.maintenance_id}
        loading={isLoadingPD}
        dataSource={tableDataPD && tableDataPD.data_list || []}
        columns={columnsPD}
        pagination={false}
        scroll={{ x: true }}
      />

      <div className="pgnt-st">
        <Pagination
          size="small"
          current={currentPagePD}
          onChange={page => setCurrentPagePD(page)}
          total={tableDataPD && tableDataPD.max_total_rows}
          pageSize={perPagePD}
          showSizeChanger={false}
        />
      </div>

    </div>
  )
}

export default Support
