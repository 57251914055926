import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { Table, DatePicker, Input, Pagination, Button, Card, Modal, List, Skeleton } from 'antd';

import { LoadingOutlined } from '@ant-design/icons';

import { apiGetSlip, apiUploadSlip } from '../../services/apis/slip';

import moment from 'moment';
import 'moment/locale/th'

import { openNotification } from '../../utils/function';
import { baseURL } from '../../config/config';


const UploadSlip = () => {
    const { siteId } = useParams();
    const history = useHistory();
    const location = useLocation();

    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState(undefined);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(20)
    const [fileName, setFileName] = useState('');
    const [amount, setAmount] = useState(0);
    const [file, setFile] = useState([]);
    const [searchButton, setSearchButton] = useState(false);
    const [date, setDate] = useState(moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'));

    const [visible, setVisible] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalId, setModalId] = useState(undefined);
    const [isLoadingConfirm, setIsLoadingConfirm] = useState(false);

    const [modalActionData, setModalActionData] = useState(undefined);

    useEffect(() => {
        getTableData()
    }, [currentPage, perPage, searchButton])

    const getTableData = async () => {
        setIsLoading(true)
        let slip = await apiGetSlip(currentPage, perPage, siteId);
        if (slip && slip.status) {
          setIsLoading(false)
          console.log('Slip Result: ', slip.result);
          setTableData(slip.result);
        }
    }

    const fileInput = useRef();
    const selectFile = () => {
        fileInput.current.click();
    }

    const onFileChange = e => {
        const fileUploaded = e.target.files[0];
        console.log('Select File: ', fileUploaded);
        setFile([fileUploaded]);
    };

    const clear = () => {
        setFileName('');
        setAmount(0);
        setFile([]);
        setDate(moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'));
    }

    const addEditData = async () => {
        // console.log(moment(date).unix());
        
        if (
            fileName != '' && amount != 0 && file.length > 0
        ) {
            setIsLoading(true)

            let data = new FormData();
            data.append('site_id', siteId);
            data.append('slip_img', file[0]);
            data.append('file_name', fileName);
            data.append('payment_date', moment(date).unix());
            data.append('price', amount);

            let newData = await apiUploadSlip(data);
            if (newData && newData.status) {
                clear();
                setIsLoading(false);
                setCurrentPage(1);
                getTableData();
                openNotification('success', 'Success !', `Upload slip ${fileName} successful.`);
            } else {
                setIsLoading(false);
                openNotification('error', 'Failed !', `Failed to upload ${fileName}`);
            }
        } else {
            openNotification('error', 'Failed !', `Please fill slip information.`)
        }
    }

    const columns = [
        {
            title: '#',
            ellipsis: true,
            width: '5%',
            render: (data) => <div>{data.id || '-'}</div>
        },
        {
            title: 'ชื่อไฟล์อัพโหลด',
            ellipsis: true,
            className: 'cs-pt',
            render: (data) => <div onClick={() => {
                if (data.path_file) {
                    window.open(baseURL + '/file/' + data.path_file);
                } else {
                    console.log('No Path file');
                }
            }}>{data.file_name || '-'}</div>
        },
        {
            title: 'ยอดโอน (บาท)',
            ellipsis: true,
            width: '15%',
            render: (data) => <div>{data.price || '-'}</div>
        },
        {
            title: 'วันที่ชำระเงิน',
            ellipsis: true,
            width: '15%',
            render: (data) => <div>{moment(data.payment_date).format('L') || '-'}</div>
        },
        {
            title: 'ตั้งค่า',
            ellipsis: true,
            align: 'center',
            width: '10%',
            render: (data) => data.is_delete ? <div className="f-c-c d-b-t-n-s b-c-o-i" style={{ backgroundColor: '#ED2228' }}
                onClick={(e) => {
                e.stopPropagation()
                setModalId(data.machine_id)
                setModalTitle(data.machine_name)
                setVisible(true)
                }}
            ><i className="fas fa-trash"></i></div> : null
        },
    ];

    return (
        <div>
            <div className="mg-bt-20 h-d-t-p">Upload Slip</div>

            <Card style={{marginBottom: 15}}>
                <div className="h-d-t-g-u-p">
                    <div className="f-c-c d-b-t-n-s d-bg-c-t h-d-t-g-w-d-d-s-order-2"
                        onClick={selectFile}>
                        <input type="file" style={{ "display": "none" }} ref={fileInput} onChange={onFileChange} />
                        + Add File
                    </div>
                    <div className="h-d-t-g-w-d-d-s-order-2">
                        <Input
                            placeholder="ชื่อไฟล์"
                            value={fileName}
                            onChange={e => setFileName(e.target.value)}
                        />
                    </div>
                   
                    <div className="h-d-t-g-w-d-d-s-order-1">
                        <DatePicker
                            style={{ width: '100%' }}
                            format={date => moment(date).add(543, 'years').format('D MMM YYYY')}
                            allowClear={false}
                            value={moment(date, 'YYYY-MM-DD')}
                            onChange={date => {
                                // setCurrentPage(1)
                                setDate(moment(date).startOf('day').format('YYYY-MM-DD HH:mm:ss'))
                            }}
                            disabledDate={current => current && current >= moment().endOf('day')}
                        />
                    </div>

                    <div className="h-d-t-g-w-d-d-s-order-3">
                        <Input
                            placeholder="ยอด"
                            value={amount}
                            onChange={e => setAmount(e.target.value)}
                            onPressEnter={() => {
                                // setCurrentPage(1)
                                setSearchButton(!searchButton)
                            }}
                            suffix="บาท"
                        />
                    </div>
                    
                    <div className="f-c-c d-b-t-n-s d-bg-c-t h-d-t-g-w-d-d-s-order-4"
                        onClick={() => addEditData()}>
                        Save
                    </div>
                </div>

                {
                    file.length > 0
                        ? <Card size="small">
                            <List.Item actions={[<Button type="link" onClick={() => setFile([])}><span class='bi bi-trash-fill' style={{color: 'red'}}></span></Button>]}>
                                <Skeleton avatar title={false} loading={false} active>
                                <span>{file[0].name}</span>
                                </Skeleton>
                            </List.Item>
                        </Card>
                        : null
                }
            </Card>
 

            <Table bordered
                size="small"
                loading={isLoading}
                rowKey={record => record.payment_id}
                dataSource={tableData && tableData.data_list || []}
                columns={columns}
                pagination={false}
                scroll={{ x: true }}
            />

            <div className="pgnt-st">
                <Pagination
                    size="small"
                    current={currentPage}
                    onChange={page => setCurrentPage(page)}
                    total={tableData && tableData.max_total_rows}
                    pageSize={perPage}
                    showSizeChanger={false}
                />
            </div>

            <Modal
                title=""
                visible={visible}
                closable={false}
                footer={false}
                centered
            >
                <div className="m-t-i-c" style={{ color: '#FFCB05' }}>
                <i className="bi bi-exclamation-triangle-fill"></i>
                </div>
                <div className="m-t-t-q">Do you want to delete '{modalTitle}' ?</div>
                <div className="m-b-y-n-g">
                <div className="f-c-c d-b-t-n-s b-c-o-i" style={{ backgroundColor: '#cccccc' }} onClick={() => setVisible(false)}>Cancel</div>
                <div className="f-c-c d-b-t-n-s b-c-o-i"
                    style={{ backgroundColor: '#3f4d67', pointerEvents: isLoadingConfirm ? 'none' : null }}
                    onClick={() => {
                        // Delete Data
                    }}
                >{isLoadingConfirm ? <LoadingOutlined /> : 'Confirm'}</div>
                </div>
            </Modal>            

        </div>
    )
};

export default UploadSlip