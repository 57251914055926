import { useQuery, gql } from "@apollo/client";

// # 1. หน้า Machine List (by site)
// ## API สำหรับ Query Site ทั้งหมด
export const GET_SITES = gql`
  query getSites {
    getSites {
      id
      img_site
      siteCode
      site_name
      site_status
      province_id
      side_type
      site_sub_type
      zone
      tier
      version
      admin_list
      site_name_en
      payment_method
      pay_amount
      group_site_id
      unit_size
      water_amount
      water_type
      elec_amount
      elec_type
      contact_end
      contact_start
      latitude
      longitude
      site_address
      comment
      qr_code
    }
  }
`;

// ## API สำหรับ Query Machine ทั้งหมด
export const GET_MACHINES = gql`
  query getMachines($limit: Int!) {
    getMachines(limit: $limit) {
      id
      siteId
      deviceName
      machine_group
      machine_sub_group
      machine_model
      machine_name
      site_name
      time_default
      price_default
      location_history
      machine_pic
      machine_no
      promotion_type
      brand
      coin_get
      start_period
      end_warranty
      circuit
      machine_run_mode
      device_status
      device_updateAt
      promotion_type
      # customer_name
      createdAt
    }
  }
`;

// ## API สำหรับ Query Machine ทั้งหมด
// export const GET_MACHINE = gql`
//   query getMachine($id: ID!) {
//     getMachine(machineId: $id) {
//       id
//       siteId
//       deviceName
//       machine_group
//       machine_sub_group
//       machine_model
//       machine_name
//       site_name
//       time_default
//       price_default
//       location_history
//       machine_pic
//       machine_no
//       promotion_type
//       brand
//       coin_get
//       start_period
//       end_warranty
//       circuit
//       machine_run_mode
//       device_status
//       device_updateAt
//       promotion_type
//       # customer_name
//       createdAt
//       lg_device {
//         deviceId
//         deviceType
//         model
//         nickName
//         newRegistry
//         isLGFlow
//       }
//     }
//   }
// `;
export const GET_MACHINE = gql`
  query getMachine($id: ID!) {
    getMachine(machineId: $id) {
      id
      siteId
      deviceName
      machine_group
      machine_sub_group
      machine_model
      machine_name
      site_name
      time_default
      price_default
      location_history
      machine_pic
      machine_no
      promotion_type
      brand
      coin_get
      start_period
      end_warranty
      circuit
      machine_run_mode
      device_status
      device_updateAt
      promotion_type
      # customer_name
      createdAt
    }
  }
`;
// ## API สำหรับ Query Machine ตาม siteId ทั้งหมด
// export const GET_MACHINES_BY_ID = gql`
//   query getMachineBySite($id: ID!) {
//     getMachineBySite(siteId: $id) {
//       id
//       siteId
//       deviceName
//       machine_group
//       machine_sub_group
//       machine_model
//       machine_name
//       site_name
//       time_default
//       price_default
//       location_history
//       machine_pic
//       machine_no
//       promotion_type
//       brand
//       coin_get
//       start_period
//       end_warranty
//       circuit
//       machine_run_mode
//       machine_mode_price
//       device_status
//       device_updateAt
//       promotion_type
//       machine_order
//       # customer_name
//       createdAt
//       machine_group_id
//       machine_sub_group_id
//       lg_device {
//         deviceId
//         deviceType
//         model
//         nickName
//         newRegistry
//       }
//     }
//   }
// `;
export const GET_MACHINES_BY_ID = gql`
  query getMachineBySite($id: ID!) {
    getMachineBySite(siteId: $id) {
      id
      siteId
      deviceName
      machine_group
      machine_sub_group
      machine_model
      machine_name
      site_name
      time_default
      price_default
      location_history
      machine_pic
      machine_no
      promotion_type
      brand
      coin_get
      start_period
      end_warranty
      circuit
      machine_run_mode
      machine_mode_price
      device_status
      device_updateAt
      promotion_type
      machine_order
      # customer_name
      createdAt
      machine_group_id
      machine_sub_group_id
    }
  }
`;
export const GET_MACHINES_BY_DeviceName = gql`
  query getMachineByDeviceName($deviceName: String!) {
    getMachineByDeviceName(deviceName: $deviceName) 
  }
`;
// export const GET_MACHINES_BY_ID_All = gql`
//   query getMachineBySites($id: ID!) {
//     getMachineBySites(siteId: $id) {
//       id
//       siteId
//       deviceName
//       machine_group
//       machine_sub_group
//       machine_model
//       machine_name
//       site_name
//       time_default
//       price_default
//       location_history
//       machine_pic
//       machine_no
//       promotion_type
//       brand
//       coin_get
//       start_period
//       end_warranty
//       circuit
//       machine_run_mode
//       machine_mode_price
//       device_status
//       device_updateAt
//       promotion_type
//       machine_order
//       # customer_name
//       createdAt
//       machine_group_id
//       machine_sub_group_id
//       lg_device {
//         deviceId
//         deviceType
//         model
//         nickName
//         newRegistry
//       }
//     }
//   }
// `;
export const GET_MACHINES_BY_ID_All = gql`
  query getMachineBySites($id: ID!) {
    getMachineBySites(siteId: $id) {
      id
      siteId
      deviceName
      machine_group
      machine_sub_group
      machine_model
      machine_name
      site_name
      time_default
      price_default
      location_history
      machine_pic
      machine_no
      promotion_type
      brand
      coin_get
      start_period
      end_warranty
      circuit
      machine_run_mode
      machine_mode_price
      device_status
      device_updateAt
      promotion_type
      machine_order
      # customer_name
      createdAt
      machine_group_id
      machine_sub_group_id
    }
  }
`;

// ## API สำหรับ Query Device ทั้งหมด
export const GET_DEVICES = gql`
  query getDevice {
    getDevices {
      id
      siteId
      deviceName
      machineId
      receive_date
      warranty_date
      producer
      version
      lot
      note
      createdAt
    }
  }
`;

// ## API สำหรับ Query Device ตาม siteId ทั้งหมด
export const GET_DEVICES_BY_SITE = gql`
  query getDeviceBySite($id: ID!) {
    getDeviceBySite(siteId: $id) {
      deviceName
      machineId
      status
      action_by
      run_time
      run_value
      run_mode
      discount
      mode
      createdAt
    }
  }
`;

// ## API สำหรับ Query Config Machine ตาม machineId ทั้งหมด
export const GET_CONFIG_MACHINE = gql`
query GetConfigMachine($deviceName: String!) {
  getConfigMachine(deviceName: $deviceName)
}
`;

// ## API สำหรับ ดู Realtime Status
export const GET_DEVICES_STATUS = gql`
  query getDevice($id: ID!) {
    getDevice(deviceId: $id) {
      id
      deviceName
      status
    }
  }
`;

// # 2. หน้า Machine History
// ## API สำหรับ Query History ทั้งหมด ตามช่วงเวลา
export const GET_HISTORY = gql`
  query getHistory($start: String!, $stop: String!) {
    getHistory(start: $start, stop: $stop) {
      id
      machineId
      siteId
      site_name
      deviceName
      machine_name
      machine_type
      machine_no
      machine_option
      device_status
      action_by
      run_time
      run_value
      run_mode
      customer_name
      device_startAt
      device_stopAt
      time
      siteCode
      province_id
      side_type
      site_sub_type
      zone
      createdAt
    }
  }
`;
export const GET_Transactions = gql`
  query getTransactions($start: String!, $stop: String!) {
    getTransactions(start: $start, stop: $stop) {
      id
      machineId
      siteId
      site_name
      deviceName
      machine_name
      machine_type
      machine_no
      machine_option
      device_status
      action_by
      run_time
      run_value
      run_mode
      customer_name
      device_startAt
      device_stopAt
      time
      siteCode
      province_id
      side_type
      site_sub_type
      zone
      createdAt
    }
  }
`;
export const GET_ReportTransactions = gql`
  query  getSiteReport($start: String!, $stop: String!) {
    getSiteReport(start: $start, stop: $stop) {
      siteCode
        site_name
        action_by{
          action
          values
        }
        total
      }
    }
`;
// # 3. หน้า Report by site
// ## API สำหรับ Query History ตาม siteId และ ช่วงเวลา
export const GET_HISTORY_BY_SITE = gql`
  query getHistoryBySite($id: ID!, $start: String!, $stop: String!) {
    getHistoryBySite(siteId: $id, start: $start, stop: $stop) {
      id
      machineId
      siteId
      site_name
      deviceName
      machine_name
      machine_type
      machine_no
      device_status
      action_by
      run_time
      run_value
      run_mode
      customer_name
      device_startAt
      device_stopAt
      time
      createdAt
    }
  }
`;

// # 4. หน้า Report by Machine
// ## API สำหรับ Query History ตาม machineId และ ช่วงเวลา
export const GET_HISTORY_BY_MACHINE = gql`
  query getHistoryByMachine($id: ID!, $start: String!, $stop: String!) {
    getHistoryByMachine(machineId: $id, start: $start, stop: $stop) {
      id
      machineId
      siteId
      site_name
      deviceName
      machine_name
      machine_type
      machine_no
      machine_option
      device_status
      action_by
      run_time
      run_value
      run_mode
      customer_name
      device_startAt
      device_stopAt
      time
      createdAt
    }
  }
`;

export const GET_LIVE_MACHINE_BY_SITE = gql`
  query getLiveMachineBySite($siteId: ID!) {
    getLiveMachineBySite(siteId: $siteId) {
      id
      deviceName
      machine_group
      machine_sub_group
      machine_name
      site_name
      customer_name
      price_default
      machine_pic
      machine_no
      device_startAt
      device_stopAt
      device_status
      device_updateAt
      coin_vault
      createdAt
    }
  }
`;

export const GET_HISTORY_BY_DAY = gql`
  query getHistoryByDay($siteId: ID!, $start: String!, $stop: String!) {
    getHistoryByDay(siteId: $siteId, start: $start, stop: $stop) {
      _id
      value
    }
  }
`;

export const GET_HISTORY_BY_MONTH = gql`
  query getHistoryByMonth($siteId: ID!, $start: String!, $stop: String!) {
    getHistoryByMonth(siteId: $siteId, start: $start, stop: $stop) {
      _id
      value
    }
  }
`;

export const GET_HISTORY_BY_HOUR = gql`
  query getHistoryByHour($siteId: ID!, $start: String!, $stop: String!) {
    getHistoryByHour(siteId: $siteId, start: $start, stop: $stop) {
      _id
      value
    }
  }
`;

export const GET_HISTORY_BY_PRICE = gql`
  query getHistoryByPrice($siteId: ID!, $start: String!, $stop: String!) {
    getHistoryByPrice(siteId: $siteId, start: $start, stop: $stop) {
      _id
      price_group {
        action
        machine
        values
        counts
      }
      total_value
    }
  }
`;

export const GET_HISTORY_REVENUE = gql`
  query getRevenue($siteId: ID!, $start: String!, $stop: String!) {
    getRevenue(siteId: $siteId, start: $start, stop: $stop) {
      siteId
      site_name
      site_type
      fee
      total_value
      total_app
      total_coin
      returnAmount
    }
  }
`;

export const GET_ADMIN = gql`
  query getAdmins {
    getAdmins {
      id
      site_id
      admin_branch
      admin_email
      admin_name
      admin_tel
      admin_user
      admin_password
      admin_role
      admin_token
      last_active
      createdAt
      menu_list
    }
  }
`;

export const GET_CUSTOMERS = gql`
  query getCustomers($page: Int!, $limit: Int!) {
    getCustomers(page: $page, limit: $limit) {
      customer_list {
        id
        credit
        customer_id
        customer_name
        customer_tel
        customer_email
        customer_bday
        customer_gender
        customer_fb_id
        customer_line_id
        customer_token_id
        customer_joindate
        customer_username
        customer_password
        customer_status
        customer_img
        customer_os
        customer_app_v
        customer_free
        last_active
        customer_token
        customer_firebase_token
        customer_role
        login_channel
        customer_first_name
        customer_last_name
        customer_address
      }
      pageLimit
      totalPage
      page
    }
  }
`;

export const GET_CUSTOMER_BY_ID = gql`
  query getCustomer($customerId: String) {
    getCustomer(customerId: $customerId) {
      id
      credit
      customer_name
      customer_tel
      customer_email
      customer_bday
      customer_gender
      customer_fb_id
      customer_line_id
      customer_token_id
      customer_joindate
      customer_username
      customer_password
      customer_status
      customer_img
      customer_os
      customer_app_v
      customer_free
      last_active
      customer_token
      customer_firebase_token
      customer_role
      login_channel
      customer_first_name
      customer_last_name
      customer_address
    }
  }
`;

export const GET_LG_MACHINE_SETTINGS = gql`
  query getLGMachineSettings($lgMachineId: ID!) {
    getLGMachineSettings(lgMachineId: $lgMachineId) {
      washingTime
      waterLevel
      rinsingTime
      rinsingCount
      spinSpeed
      dropCount
      twinSpray
      price
      coin
      addSuperWash
      nonStopRinsing
      dryCycleTime
      topOffTime
      topOff
      sensingDry
      regularPrice
      topOffPrice
      coin1
    }
  }
`;

export const QueryGraphQL = (query) => {
  const queryData = useQuery(query);
  return queryData;
};
export const QueryGraphQLById = (query, id, start, stop) => {
  const queryData = useQuery(query, {
    variables: {
      id,
      start,
      stop,
    },
  });
  return queryData;
};
export const QueryGraphQLBylimit = (query, limit) => {
  const queryData = useQuery(query, {
    variables: {
      limit
    },
  });
  return queryData;
};

export const QueryGraphQLByPeriod = (query, start, stop) => {
  const queryData = useQuery(query, {
    variables: {
      start,
      stop,
    },
  });
  return queryData;
};

export const QueryGraphQLBySiteId = (query, siteId) => {
  const queryData = useQuery(query, {
    variables: {
      siteId,
    },
  });
  return queryData;
};

export const QueryGraphQLBySiteIdPeriod = (query, siteId, start, stop) => {
  const queryData = useQuery(query, {
    variables: {
      siteId,
      start,
      stop,
    },
  });
  return queryData;
};

export const QueryGraphQLPageLimit = (query, page, limit) => {
  const queryData = useQuery(query, {
    variables: {
      page,
      limit,
    },
  });
  return queryData;
};
export const QueryGraphQL_ConfigMachineByDeviceName = (query, deviceName) => {
  const queryData = useQuery(query, {
    variables: {
      deviceName,
    },
  });
  return queryData;
  //
};

export const QueryGraphQL_MachineByDeviceName = (query, deviceName) => {
  const queryData = useQuery(query, {
    variables: {
      deviceName,
    },
  });
  return queryData;
  //
};