import React, { useState, useEffect } from 'react'
import { Table, Pagination } from 'antd';
import '../../Table.css'
import XLSX from "xlsx";
import { LoadingOutlined } from '@ant-design/icons';

import moment from 'moment';
import 'moment/locale/th'

import { apiDBGetNewUser } from '../../../../services/apis/dashboard'
import { numberWithCommas } from '../../../../utils/function'

const TableNewUser = props => {
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [perPage, setPerPage] = useState(20)
  const [tableData, setTableData] = useState(undefined)
  const [waitingForCSV, setWaitingForCSV] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    const getTableData = async () => {
      let data = await apiDBGetNewUser(false, props.startDate, props.endDate, currentPage, perPage)
      if (data && data.status) {
        setIsLoading(false)
        setTableData(data.result)
      }
    }
    getTableData()
  }, [props.searchButton, currentPage, perPage])

  const columns = [
    {
      title: '#',
      ellipsis: true,
      align: 'center',
      width: '5%',
      render: (data, record, index) => <div>{(index + 1) + ((currentPage - 1) * perPage)}</div>
    },
    {
      title: 'Name',
      ellipsis: true,
      width: '40%',
      className: 'cs-pt',
      render: (data) => <div onClick={() => window.open(`/customer/list/${data.key}`, '_blank')}>{data.customer_name || '-'}</div>
    },
    {
      title: 'Phone',
      ellipsis: true,
      className: 'table-column-width-phone',
      render: (data) => <div className="t-ovfl-el">{data.customer_tel || '-'}</div>
    },
    {
      title: 'Credit',
      ellipsis: true,
      align: 'center',
      width: '15%',
      render: (data) => <div>{numberWithCommas(data.credit || 0, 2)}</div>
    },
    {
      title: 'Join Date',
      ellipsis: true,
      align: 'center',
      width: '20%',
      render: (data) => <div>{data.last_active ? moment(data.last_active).add(543, 'years').format('D MMM YYYY HH:mm:ss') : '-'}</div>
    },
  ];

  const toCSVFile = async () => {
    setWaitingForCSV(true)
    let data = await apiDBGetNewUser(true, props.startDate, props.endDate, '', '')
    let csvData = [];

    if (data && data.status) {
      (data.result.data_list || []).map((data, idx) => {
        let resultRow = {}
        resultRow['ช่วงเวลาที่เลือก'] = `${moment(props.startDate).add(543, 'years').format('D MMM YYYY')} - ${moment(props.endDate).add(543, 'years').format('D MMM YYYY')}`
        resultRow['#'] = idx + 1
        resultRow['Name'] = data.customer_name || '-'
        resultRow['Phone'] = data.customer_tel || '-'
        resultRow['Credit'] = data.credit || 0
        resultRow['Join Date'] = data.last_active || '-'
        csvData.push(resultRow);
      })

      const toCSV = XLSX.utils.json_to_sheet(csvData);
      const wb = XLSX.utils.book_new();

      const fileName = 'NEW_USERS'
      XLSX.utils.book_append_sheet(wb, toCSV, fileName);
      XLSX.writeFile(wb, fileName + ".csv");

      setWaitingForCSV(false)
    }
  }

  return (
    <div className="dash-chart-box-container">
      <div className="dash-space-between">
        <div className="h-d-t-g">
          <div className="f-c-c"><i className="bi bi-person-plus-fill" style={{ lineHeight: '0' }}></i></div>
          <div className="f-s-c">รายงานผู้ใช้ใหม่ ( Top {tableData && tableData.per_page || 0} คนในช่วงวันที่เลือก )</div>
          <div className="f-c-c d-b-t-n-s d-bg-c-t table-export-btn-width table-export-btn-responsive" style={{ pointerEvents: waitingForCSV ? 'none' : null }} onClick={() => toCSVFile()}>
            {waitingForCSV ? <LoadingOutlined /> : 'Export CSV'}
          </div>
        </div>

        <div className="dash-table-container">
          <Table bordered
            size="small"
            rowKey={record => record.key}
            loading={isLoading}
            dataSource={tableData && tableData.data_list || []}
            columns={columns}
            pagination={false}
            scroll={{ x: true }}
          />
        </div>

        <div className="pgnt-st">
          <Pagination
            size="small"
            current={currentPage}
            onChange={page => setCurrentPage(page)}
            total={tableData && tableData.max_total_rows}
            pageSize={perPage}
            showSizeChanger={false}
          />
        </div>

      </div>
    </div>
  )
}

export default TableNewUser
