import axios from '../../config/axios';

const apiBWGetHomeBanner = async (get_all, cur_page, per_page, keyword) => {
  try {
    const result = await axios.get(`/washer/banner/home?get_all=${get_all}&cur_page=${cur_page}&per_page=${per_page}&keyword=${keyword}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiBWGetSiteBanner = async (get_all, cur_page, per_page, keyword, site_id) => {
  try {
    const result = await axios.get(`/washer/banner/site/list?get_all=${get_all}&cur_page=${cur_page}&per_page=${per_page}&keyword=${keyword}&site_id=${site_id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiBWAddBanner = async (data) => {
  try {
    const result = await axios.post('/washer/banner', data)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiBWGetBannerById = async (banner_id) => {
  try {
    const result = await axios.get(`/washer/banner?banner_id=${banner_id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiBWEditBannerById = async (data) => {
  try {
    const result = await axios.put('/washer/banner', data)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiBWDeleteBannerById = async (banner_id) => {
  try {
    const result = await axios.delete(`/washer/banner?banner_id=${banner_id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

export {
  apiBWGetHomeBanner,
  apiBWGetSiteBanner,
  apiBWAddBanner,
  apiBWGetBannerById,
  apiBWEditBannerById,
  apiBWDeleteBannerById,
}