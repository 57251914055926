import React, { useEffect } from "react";

const usePagination = (dataSites, currentPage, perPage) => {
  if (dataSites?.length) {
    let dataArr = [];
    dataSites?.map((item, index) => {
      if (index < currentPage * perPage) {
        if (perPage < currentPage * perPage) {
          if (index >= (currentPage - 1) * perPage && index >= perPage) {
            dataArr.push(item);
          }
        } else {
          dataArr.push(item);
        }
      }
    });
    return dataArr ?? true;
  }
};

export default usePagination;
