import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Table, Input, Pagination, Tooltip, Select, Modal } from "antd";
import "./Machine.css";
import XLSX from "xlsx";
import { LoadingOutlined } from "@ant-design/icons";

// import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
// import arrayMove from 'array-move';

import moment from "moment";

import {
  numberWithCommas,
  openNotification,
  getSearchParams,
} from "../../utils/function";
import { changeModalMachineAction } from "../../redux/machine";
// import { apiMCHDeleteMachine } from "../../services/apis/machine";
import ModalMachineAction from "../../components/Modal/ModalMachineAction-v2";
import {
  QueryGraphQL,
  QueryGraphQLById,QueryGraphQLBylimit,
  GET_MACHINES,
  GET_MACHINES_BY_ID_All,
} from "../../resolvers/Query";
import usePagination from "../../hooks/usePagination";
import { saveDataMachineItem } from "../../redux/data-v2";
import { useMutation } from "@apollo/client";
import { DELETE_MACHINE } from "../../resolvers/Mutation";

const { Option } = Select;

const MachineListPageV2 = () => {
  const dispatch = useDispatch();
  const machine = useSelector((state) => state.machine);
  const history = useHistory();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const p = params.get("p");
  const ttl = params.get("ttl");
  const mcht = params.get("mcht");
  const s = params.get("s");
  const sw = params.get("sw");

  const menuList = (localStorage.getItem("list") || "").split(",");
  const role = (localStorage.getItem("user_role") || "").toUpperCase();
  const [isLoading, setIsLoading] = useState(false);
  const [machineTypes, setMachineTypes] = useState([]);
  const [sites, setSites] = useState([]);
  const [currentPage, setCurrentPage] = useState(Number(p) || 1);
  const [perPage, setPerPage] = useState(Number(ttl) || 20);
  const [searchWord, setSearchWord] = useState(sw || "");
  const [waitingForCSV, setWaitingForCSV] = useState(false);

  const [machineType, setMachineType] = useState(Number(mcht) || "");
  const [site, setSite] = useState("");

  const [visible, setVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalId, setModalId] = useState(undefined);
  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false);

  const [modalActionData, setModalActionData] = useState(undefined);

  const dataMachine = QueryGraphQLBylimit(GET_MACHINES, 9999);
  const { data } = QueryGraphQLById(GET_MACHINES_BY_ID_All, params.get("s"));
  const [dataList, setDataList] = useState([]);
  const [dataMachineAll, setDataMachineAll] = useState(
    dataMachine?.data?.getMachines
  );
  const checkDataPage = usePagination(dataMachineAll, currentPage, perPage);
  const site_name = localStorage.getItem("site_name");
  const site_code = localStorage.getItem("site_code");

  const [searchTotalPage, setSearchTotalPage] = useState(20);
  const [dataSearchList, setDataSearchList] = useState([]);
  const dataSearch = usePagination(dataSearchList, currentPage, perPage);
  const [allMachine, setAllMachine] = useState([]);
  const [deleteMachine, dataDeleteMachine] = useMutation(DELETE_MACHINE);
   //console.log("data--MachineListPageV2-🐳🐳-", dataMachineAll, dataList);
  useEffect(() => {
    setDataList(checkDataPage ?? []);
  }, [dataMachineAll]);

  useEffect(() => {
    if (searchWord || machineType || site) {
      if (dataSearchList?.length > 0) {
        setDataList(dataSearch ?? []);
      } else if (dataSearchList == null) {
        setDataList([]);
      } else if (s) {
        setDataList(checkDataPage ?? []);
      }
    }
  }, [searchWord, machineType, site, dataSearchList]);

  useEffect(() => {
    const dataAll =
      dataSearchList?.length > 0 ? dataSearchList : dataMachineAll;
    if (dataAll?.length > 0) {
      let dataNew = [];
      dataAll.map((item, index) => {
        if (
          index + 1 >= currentPage * perPage - perPage + 1 &&
          index + 1 <= currentPage * perPage
        ) {
          dataNew.push(item);
        }
      });
      setDataList(dataNew);
    }
  }, [currentPage, perPage]);

  useEffect(() => {
    if (s) {
      const dataArr = data?.getMachineBySites ?? [];
      setSearchTotalPage(dataArr?.length ?? 20);
      setDataMachineAll(dataArr);
      if (dataArr?.length > 0) {
        dataArr.map((data) => {
          if (data?.siteId == s) {
            setSite(data?.site_name ?? "");
          }
        });
      }
    } else {
      setSearchTotalPage(dataMachine?.data?.getMachines?.length ?? 20);
      setDataMachineAll(dataMachine?.data?.getMachines);
    }
    setAllMachine(dataMachine?.data?.getMachines);
  }, [s, data, dataMachine]);

  useEffect(() => {
    let dataAllType =
      dataSearchList?.length > 0 && machineType !== ""
        ? [...dataSearchList]
        : allMachine?.length > 0
        ? [...allMachine]
        : [];
    if (dataAllType?.length > 0) {
      let machineTypesArr = [];
      dataAllType.map((item) => {
        machineTypesArr.push(item?.machine_group);
      });
      const _machineTypes = machineTypesArr.filter((val, id) => {
        return machineTypesArr.indexOf(val) === id;
      });
      if (dataSearchList?.length == 0) setMachineTypes(_machineTypes ?? []);
    }

    let dataAllSite =
      dataSearchList?.length > 0 && site !== ""
        ? [...dataSearchList]
        : allMachine?.length > 0
        ? [...allMachine]
        : [];
    if (dataAllSite?.length > 0) {
      let sitesArr = [];
      dataAllSite.map((item) => {
        sitesArr.push(item?.site_name);
      });
      const _siteName = sitesArr.filter((val, id) => {
        return sitesArr.indexOf(val) === id;
      });
      if (dataSearchList?.length == 0) setSites(_siteName ?? []);
    }
  }, [allMachine, dataSearchList]);

  useEffect(() => {
    if (dataDeleteMachine && isLoadingConfirm) {
      if (dataDeleteMachine?.error) {
        openNotification("error", "Failed !", "Failed to delete this machine.");
        setIsLoadingConfirm(false);
      }
      if (dataDeleteMachine?.data?.deleteMachine) {
        openNotification(
          "success",
          "Success !",
          "Delete this machine successful."
        );
        setVisible(false);
        setIsLoadingConfirm(false);
        window.location.reload(false);
      }
    }
  }, [dataDeleteMachine]);

  const columnsGraphQL = [
    {
      title: "#",
      ellipsis: true,
      align: "center",
      width: "5%",
      className: "machine-list-drag-visible",
      render: (data, record, index) => (
        <div>{index + 1 + (currentPage - 1) * perPage}</div>
      ),
    },
    {
      title: "ประเภท",
      ellipsis: true,
      className: "machine-list-drag-visible",
      render: (data) => <div>{data.machine_group || "-"}</div>,
    },
    {
      title: "สถานที่",
      ellipsis: true,
      render: (data) => <div>{data.site_name || "-"}</div>,
    },
    {
      title: "ประเภท - ย่อย",
      ellipsis: true,
      render: (data) => <div>{data.machine_sub_group || "-"}</div>,
    },
    {
      title: "ชื่อ",
      ellipsis: true,
      render: (data) => <div>{data.machine_name || "-"}</div>,
    },
    {
      title: "รหัส",
      ellipsis: true,
      align: "center",
      render: (data) => <div>{data.deviceName || "-"}</div>,
    },
    {
      title: "สถานะ",
      ellipsis: true,
      align: "center",
      render: (data) => (
        <div>
          {data?.device_status
            ? data.device_status == 0
              ? "Online"
              : data.device_status == 1
              ? "Working"
              : data.device_status == -1
              ? "Offline"
              : data.device_status
            : "-"}
        </div>
      ),
    },
    {
      title: "ออนไลน์ล่าสุด",
      ellipsis: true,
      align: "center",
      render: (data) => (
        <div>
          {data.device_updateAt ? moment(data.device_updateAt).fromNow() : "-"}
        </div>
      ),
    },
    {
      title: "ราคา",
      ellipsis: true,
      align: "center",
      render: (data) => (
        <div>{numberWithCommas(data.price_default || 0, 2)}</div>
      ),
    },
    {
      title: "ใส่กล่อง",
      ellipsis: true,
      align: "center",
      render: (data) => (
        <div>
          {data?.coin_vault
            ? data?.coin_vault == 0
              ? "ใส่"
              : "ไม่ใส่"
            : "ไม่ใส่"}
        </div>
      ),
    },
    {
      title: "ตั้งค่า",
      ellipsis: true,
      align: "center",
      fixed: "right",
      render: (data) =>
        role === "BRANCHADMIN" ? (
          <Tooltip placement="top" title="History">
            <div
              className="f-c-c d-b-t-n-s b-c-o-i"
              style={{ backgroundColor: "#45D88E" }}
              onClick={(e) => {
                e.stopPropagation();
                history.push(`/machine/list/history/v2/${data.id ?? 0}`);
                dispatch(saveDataMachineItem(data));
              }}
            >
              <i className="bi bi-clock-history"></i>
            </div>
          </Tooltip>
        ) : (
          <div className="f-c-c">
            <div
              className={
                menuList.includes("MACHINEMAN")
                  ? "tb-cl-a-g tb-cl-a-g-rp-2"
                  : "f-c-c tb-cl-a"
              }
            >
              <Tooltip placement="top" title="History">
                <div
                  className="f-c-c d-b-t-n-s b-c-o-i"
                  style={{ backgroundColor: "#45D88E" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    history.push(`/machine/list/history/v2/${data.id ?? 0}`);
                    dispatch(saveDataMachineItem(data));
                  }}
                >
                  <i className="bi bi-clock-history"></i>
                </div>
              </Tooltip>
              {menuList.includes("MACHINEMAN") && (
                <Tooltip placement="top" title="Edit">
                  <div
                    className="f-c-c d-b-t-n-s b-c-o-i"
                    style={{ backgroundColor: "#00ADEF" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      history.push(
                        `/machine/list/edit/v2/${data.id}${getSearchParams(
                          ["p", "ttl", "mcht", "s", "sw"],
                          [
                            currentPage,
                            perPage,
                            machineType,
                            s ? s : data?.siteId,
                            searchWord,
                          ]
                        )}`
                      );
                      dispatch(saveDataMachineItem(data));
                      localStorage.setItem("machine_sub_group", data.machine_sub_group);
                    }}
                  >
                    <i className="bi bi-pencil-fill"></i>
                  </div>
                </Tooltip>
              )}
              {menuList.includes("MACHINEMAN") && (
                <Tooltip placement="top" title="Delete">
                  <div
                    className="f-c-c d-b-t-n-s b-c-o-i"
                    style={{ backgroundColor: "#ED2228" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setModalId(data.id);
                      setModalTitle(data.machine_name);
                      setVisible(true);
                    }}
                  >
                    <i className="fas fa-trash"></i>
                  </div>
                </Tooltip>
              )}
              {menuList.includes("MACHINEMAN") && (
                <Tooltip placement="top" title="Action">
                  <div
                    className="f-c-c d-b-t-n-s b-c-o-i"
                    style={{ backgroundColor: "#FFCB05" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      dispatch(changeModalMachineAction(true));
                      setModalActionData(data);
                    }}
                  >
                    <i className="fas fa-cogs"></i>
                  </div>
                </Tooltip>
              )}
            </div>
          </div>
        ),
    },
  ];

  const deleteData = async () => {
    if (!modalId) {
      openNotification("error", "Failed !", "Failed to delete this machine.");
      return;
    }

    setIsLoadingConfirm(true);
    deleteMachine({
      variables: {
        machineId: modalId,
      },
    });
  };

  const toCSVFile = async () => {
    setWaitingForCSV(true);

    let csvData = [];

    if (dataMachineAll?.length > 0) {
      dataMachineAll.map((data, idx) => {
        let resultRow = {};
        resultRow["#"] = idx + 1;
        resultRow["ประเภท"] = data.machine_group || "-";
        resultRow["ประเภท - ย่อย"] = data.machine_sub_group || "-";
        resultRow["ชื่อ"] = data.machine_name || "-";
        resultRow["รหัส"] = data.deviceName || "-";
        resultRow["สถานะ"] = data.device_status || "-";
        resultRow["ราคา"] = data.price_default || 0;
        resultRow["สถานที่"] = data.site_name || "-";
        csvData.push(resultRow);
      });

      const toCSV = XLSX.utils.json_to_sheet(csvData);
      const wb = XLSX.utils.book_new();

      const fileName = "MACHINE_LIST";
      XLSX.utils.book_append_sheet(wb, toCSV, fileName);
      XLSX.writeFile(wb, fileName + ".csv");

      setWaitingForCSV(false);
    }
  };

  const getColorStatus = (status) => {
    switch (status) {
      case "1":
        return "#00ADEF";
      case "-1":
        return "#EC1C24";
      case "0":
        return "#45D88E";
      default:
        return "#CCCCCC";
    }
  };

  const searchData = () => {
    let dataTmp = [];
    if (machineType || site || searchWord) {
      dataTmp = allMachine?.filter((item) => {
        if (machineType && site && searchWord) {
          return (
            item?.machine_group
              ?.toLowerCase()
              .includes(machineType.toLowerCase()) &&
            item?.site_name?.toLowerCase().includes(site.toLowerCase()) &&
            (item?.machine_name
              ?.toLowerCase()
              .includes(searchWord.toLowerCase()) ||
              item?.deviceName
                ?.toLowerCase()
                .includes(searchWord.toLowerCase()))
          );
        } else if (machineType && site) {
          return (
            item?.machine_group
              ?.toLowerCase()
              .includes(machineType.toLowerCase()) &&
            item?.site_name?.toLowerCase().includes(site.toLowerCase())
          );
        } else if (machineType && searchWord) {
          return (
            item?.machine_group
              ?.toLowerCase()
              .includes(machineType.toLowerCase()) &&
            (item?.machine_name
              ?.toLowerCase()
              .includes(searchWord.toLowerCase()) ||
              item?.deviceName
                ?.toLowerCase()
                .includes(searchWord.toLowerCase()))
          );
        } else if (site && searchWord) {
          return (
            item?.site_name?.toLowerCase().includes(site.toLowerCase()) &&
            (item?.machine_name
              ?.toLowerCase()
              .includes(searchWord.toLowerCase()) ||
              item?.deviceName
                ?.toLowerCase()
                .includes(searchWord.toLowerCase()))
          );
        } else {
          if (machineType) {
            return item?.machine_group
              ?.toLowerCase()
              .includes(machineType.toLowerCase());
          }
          if (site) {
            return item?.site_name?.toLowerCase().includes(site.toLowerCase());
          }
          if (searchWord) {
            return (
              item?.machine_name
                ?.toLowerCase()
                .includes(searchWord.toLowerCase()) ||
              item?.deviceName?.toLowerCase().includes(searchWord.toLowerCase())
            );
          }
        }
      });
      setSearchTotalPage(dataTmp?.length ?? 20);
      setDataSearchList(dataTmp?.length == 0 ? null : dataTmp);
    } else {
      setDataSearchList(allMachine ?? []);
      setSearchTotalPage(dataTmp?.length ?? 20);
    }
  };

  return (
    <div>
      <div className="mg-bt-20 h-d-t-p">
        Machine List{" "}
        {`${
          params.get("s") ? `: ${site_name ?? ""} (${site_code ?? ""})` : ""
        }`}
      </div>

      <div
        className={`machine-list-container-grid machine-list-container-grid-${role.toLowerCase()}-${!menuList.includes(
          "MACHINEMAN"
        )}`}
      >
        <div
          className={`machine-list-container-grid-${role.toLowerCase()}-select-type`}
        >
          <Select
            className="machine-list-select-width"
            value={machineType}
            onChange={(value) => {
              setMachineType(value);
            }}
          >
            <Option value="">All Machine Type</Option>
            {machineTypes.map((type, idx) => (
              <Option key={idx} value={type}>
                {type}
              </Option>
            ))}
          </Select>
        </div>
        {role === "SUPERADMIN" && (
          <div
            className={`machine-list-container-grid-${role.toLowerCase()}-select-site`}
          >
            <Select
              className="machine-list-select-width"
              showSearch
              value={site}
              onChange={(value) => {
                setSite(value);
              }}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="">All Site</Option>
              {sites.map((site, idx) => (
                <Option key={idx} value={site}>
                  {site}
                </Option>
              ))}
            </Select>
          </div>
        )}
        <div
          className={`machine-list-container-grid-${role.toLowerCase()}-search`}
        >
          <Input
            placeholder="search .."
            value={searchWord}
            onChange={(e) => {
              setSearchWord(e.target.value);
            }}
            onPressEnter={() => {
              setCurrentPage(1);
            }}
          />
        </div>
        <div
          className="f-c-c d-b-t-n-s d-bg-c-t"
          onClick={() => {
            setCurrentPage(1);
            searchData();
          }}
        >
          <i className="bi bi-search" style={{ lineHeight: "0" }}></i>
        </div>
        {role === "SUPERADMIN" && menuList.includes("MACHINEMAN") && (
          <Link to="/machine/add/v2">
            <div
              className="f-c-c d-b-t-n-s d-bg-c-t h-32"
              style={{ height: "100%" }}
            >
              + Add
            </div>
          </Link>
        )}
        {role === "SUPERADMIN" && menuList.includes("MACHINEMAN") && (
          <Link to="/machine/type">
          <div
            className="f-c-c d-b-t-n-s d-bg-c-t h-32"
            style={{ height: "100%" }}
          >
            Machine Type
          </div>
        </Link>
        )}
        
        {role === "SUPERADMIN" && (
          <div
            className={`f-c-c d-b-t-n-s d-bg-c-t h-32 machine-list-container-grid-${role.toLowerCase()}-export-${
              !menuList.includes("MACHINEMAN") ? "1" : "2"
            }`}
            style={{ pointerEvents: waitingForCSV ? "none" : null }}
            onClick={() => toCSVFile()}
          >
            {waitingForCSV ? <LoadingOutlined /> : "Export CSV"}
          </div>
        )}
      </div>

      {role === "SUPERADMIN" && (
        <Table
          bordered
          size="small"
          loading={isLoading}
          rowKey={(record) => record.machineId}
          dataSource={dataList}
          columns={columnsGraphQL}
          pagination={false}
          scroll={{ x: true }}
        />
      )}

      {role === "SUPERADMIN" && (
        <div className="pgnt-st">
          <Pagination
            size="small"
            current={currentPage}
            onChange={(page) => setCurrentPage(page)}
            total={
              searchWord || machineType || site
                ? searchTotalPage
                : dataMachineAll?.length
            }
            pageSize={perPage}
            showSizeChanger={false}
          />
        </div>
      )}

      {role === "BRANCHADMIN" && (
        <div className="machine-list-container-machine-flex">
          {dataList.map((machine, idx) => (
            <div
              key={idx}
              className="c-c-t-s cs-pt machine-list-container-machine-card"
              onClick={() => {
                history.push(`/machine/list/history/v2/${machine.id ?? 0}`);
                dispatch(saveDataMachineItem(machine));
              }}
            >
              <div className="f-c-c">{machine.machine_name}</div>
              <div className="f-c-c machine-list-container-machine-image">
                <img
                  src={`/assets/image/machine/${
                    machine.machine_group === "เครื่องซักผ้า"
                      ? "blue"
                      : "orange"
                  }.png`}
                  alt=""
                />
              </div>
              <div
                className="f-c-c"
                style={{
                  color: getColorStatus(machine?.device_status),
                }}
              >
                {machine?.device_status
                  ? machine.device_status == 0
                    ? "Online"
                    : machine.device_status == 1
                    ? "Working"
                    : machine.device_status == -1
                    ? "Offline"
                    : machine.device_status
                  : "-"}
              </div>
            </div>
          ))}
        </div>
      )}

      <Modal
        title=""
        visible={visible}
        closable={false}
        footer={false}
        centered
      >
        <div className="m-t-i-c" style={{ color: "#FFCB05" }}>
          <i className="bi bi-exclamation-triangle-fill"></i>
        </div>
        <div className="m-t-t-q">Do you want to delete '{modalTitle}' ?</div>
        <div className="m-b-y-n-g">
          <div
            className="f-c-c d-b-t-n-s b-c-o-i"
            style={{ backgroundColor: "#cccccc" }}
            onClick={() => setVisible(false)}
          >
            Cancel
          </div>
          <div
            className="f-c-c d-b-t-n-s b-c-o-i"
            style={{
              backgroundColor: "#3f4d67",
              pointerEvents: isLoadingConfirm ? "none" : null,
            }}
            onClick={() => deleteData()}
          >
            {isLoadingConfirm ? <LoadingOutlined /> : "Confirm"}
          </div>
        </div>
      </Modal>

      <ModalMachineAction modalActionData={modalActionData} />
    </div>
  );
};

export default MachineListPageV2;
