import { notification } from "antd";
import moment from "moment";

export const numberWithCommas = (number, digit) => {
 // console.log(number, digit);
  var x = parseFloat(number).toFixed(digit);
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

export const openNotification = (type, message, description) => {
  notification[type]({ message, description });
};

export const checkImageSize = (isShowNoti, image) => {
  if (image) {
    // const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(image.size) / Math.log(1024)); // sizes[i]
    let imgSize = image.size / Math.pow(1024, i);
    if (i <= 2) {
      if (i === 2 && imgSize > 2) {
        isShowNoti &&
          openNotification(
            "warning",
            "ไม่สามารถอัพโหลดได้",
            "ขนาดรูปภาพต้องไม่เกิน 2MB"
          );
        return false;
      } else return true;
    } else {
      isShowNoti &&
        openNotification(
          "warning",
          "ไม่สามารถอัพโหลดได้",
          "ขนาดรูปภาพต้องไม่เกิน 2MB"
        );
      return false;
    }
  } else return false;
};

export const logOut = () => {
  localStorage.removeItem("user_id");
  localStorage.removeItem("user_name");
  localStorage.removeItem("user_role");
  localStorage.removeItem("site_id");
  localStorage.removeItem("list");
  localStorage.removeItem("site_name");
  localStorage.removeItem("site_code");
  localStorage.removeItem("machine_name");
  localStorage.removeItem("device_name");
  localStorage.removeItem("version_web");
};

export const checkReqFieldWithId = (id, value) => {
  if (id || value) return true;
  if (!id && !value) return false;
};

export const downloadQR = (name, elementId) => {
  const canvas = document.getElementById(elementId);
  const pngUrl = canvas
    .toDataURL("image/png")
    .replace("image/png", "image/octet-stream");
  let downloadLink = document.createElement("a");
  downloadLink.href = pngUrl;
  downloadLink.download = `QR_${name.toUpperCase().replace(/ /g, "_")}.png`;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export const getSearchParams = (names, values) => {
  let search = [];
  values.map((value, idx) =>
    value ? search.push(`${names[idx]}=${value}`) : null
  );
  return search.length > 0 ? `?${search.join("&")}` : "";
  // ttl = total list
};

export const checkIfIdEqualToZero = (id) => Number.isInteger(id);

export const diffTime = (start, end) => {
  let duration = moment.duration(moment(end).diff(moment(start)));
  let days = duration.get("days");
  let hours = duration.get("hours");
  let minutes = duration.get("minutes");

  return `${days} วัน ${hours} ชั่วโมง ${minutes} นาที`;
};
