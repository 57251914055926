import React, { useState, useEffect } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Input, Table, Pagination, Tooltip, Modal, Select, DatePicker } from 'antd';
import './WasherList.css'
import XLSX from "xlsx";
import { LoadingOutlined } from '@ant-design/icons';

import moment from 'moment';
import 'moment/locale/th'

import { numberWithCommas, openNotification, getSearchParams } from '../../utils/function'
import { apiWGetTaskWasher, apiWUpdateTask } from '../../services/apis/washer'
import { apiCTMDeleteCustomerById } from '../../services/apis/customer';


const { Option } = Select;
const { RangePicker } = DatePicker;

const TaskList = () => {
    const history = useHistory();
    const location = useLocation();

    const params = new URLSearchParams(location.search)
    const p = params.get('p')
    const ttl = params.get('ttl')
    const sw = params.get('sw')
    const start = params.get('start')
    const end = params.get('end')

    const role = (localStorage.getItem('user_role') || '').toUpperCase()

    const menuList = (localStorage.getItem('list') || '').split(',')
    const [isLoading, setIsLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(Number(p) || 1)
    const [perPage, setPerPage] = useState(Number(ttl) || 20)
    const [tableData, setTableData] = useState(undefined)
    const [searchWord, setSearchWord] = useState(sw || '')
    const [searchButton, setSearchButton] = useState(false)
    const [waitingForCSV, setWaitingForCSV] = useState(false)
    const [startDate, setStartDate] = useState(start ? moment(start).startOf('day').format('YYYY-MM-DD HH:mm:ss') : moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'))
    const [endDate, setEndDate] = useState(end ? moment(end).endOf('day').format('YYYY-MM-DD HH:mm:ss') : moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'))

    const [isModalSetting, setIsModalSetting] = useState(false)
    const [isLoadingSetting, setIsLoadingSetting] = useState(false)
    const [modalSettingId, setModalSettingId] = useState(undefined)

    const [statusId, setStatusId] = useState(undefined)

    const [visible, setVisible] = useState(false)
    const [modalTitle, setModalTitle] = useState('')
    const [modalId, setModalId] = useState(undefined)

    const [sumaries, setSumaries] = useState([])

    const [isAlertStatus, setIsAlertStatus] = useState(false)


    useEffect(() => {
        getTableData()
        history.push(`${location.pathname}${getSearchParams(['p', 'ttl', 'sw'], [currentPage, perPage, searchWord])}`)
    }, [currentPage, perPage, searchButton])

    const getTableData = async () => {
        setIsLoading(true)
        let customer = await apiWGetTaskWasher(false, currentPage, perPage, searchWord)
        if (customer && customer.status) {
            setIsLoading(false)
            setTableData(customer.result)
        }
    }

    useEffect(() => {
        if (tableData) setSumaries([...(tableData.sumary || [])])
      }, [tableData])



    const columns = [
        {
            title: '#',
            ellipsis: true,
            align: 'center',
            width: '5%',
            render: (data, record, index) => <div>{(index + 1) + ((currentPage - 1) * perPage)}</div>
        },
        {
            title: 'Bag Name',
            ellipsis: true,
            render: (data) => <div>{data.bag_name || '-'}</div>
        },
        {
            title: 'Name',
            ellipsis: true,
            render: (data) => <div>{data.customer_name || '-'}</div>
        },
        {
            title: 'Washer Name',
            ellipsis: true,
            align: 'center',
            render: (data) => <div>{data.washer_name || '-'}</div>
        },
        {
            title: 'Branch',
            ellipsis: true,
            align: 'center',
            render: (data) => <div>{data.site_name || '-'}</div>
        },
        {
            title: 'Date',
            ellipsis: true,
            align: 'center',
            render: (data) => <div>{data.create_date ? moment(data.create_date).add(543, 'years').format('D MMM YYYY HH:mm:ss') : '-'}</div>
        },
        {
            title: 'Status',
            ellipsis: true,
            render: (data) => <div>{data.status_name || '-'}</div>
        },
        {
            title: 'Manage',
            ellipsis: true,
            align: 'center',
            fixed: 'right',
            render: (data) => <div className="f-c-c tb-cl-a">
                {menuList.includes('WASHERMAN') && <Tooltip placement="top" title="Setting">
                    <div className="f-c-c d-b-t-n-s b-c-o-i" style={{ backgroundColor: '#FFCB05' }}
                        onClick={(e) => {
                            e.stopPropagation()
                            setIsModalSetting(true)
                            setModalSettingId(data.transaction_id)
                        }}
                    ><i className="fas fa-cogs"></i></div>
                </Tooltip>}
            </div>
        },
    ];


    const toCSVFile = async () => {
        setWaitingForCSV(true)
        let data = await apiWGetTaskWasher(true, currentPage, perPage, searchWord)
        let csvData = [];

        if (data && data.status) {
            (data.result.data_list || []).map((data, idx) => {
                let resultRow = {}
                resultRow['#'] = idx + 1
                resultRow['Bag Name'] = data.bag_name || '-'
                resultRow['Name'] = data.customer_name || '-'
                resultRow['Washer Name'] = data.washer_name || '-'
                resultRow['Branch'] = data.site_name || '-'
                resultRow['Date'] = data.create_date || '-'
                resultRow['Status'] = data.status_name || '-'
                csvData.push(resultRow);
            })

            const toCSV = XLSX.utils.json_to_sheet(csvData);
            const wb = XLSX.utils.book_new();

            const fileName = 'TASK_LIST'
            XLSX.utils.book_append_sheet(wb, toCSV, fileName);
            XLSX.writeFile(wb, fileName + ".csv");

            setWaitingForCSV(false)
        }
    }

    const changeStatus = async () => {
        if (!(statusId)
        ) {
            if (!(statusId)) setIsAlertStatus(true)
        } else {
            setIsLoadingSetting(true)

            let adminId = Number(localStorage.getItem('user_id'))

            let setValue = await apiWUpdateTask(modalSettingId, statusId, adminId)
            if (setValue && setValue.status) {
                setIsLoadingSetting(false)
                setIsModalSetting(false)
                getTableData()
                setStatusId(undefined)
                openNotification('success', 'Success !', `Setting status successful.`)
            } else {
                setIsLoadingSetting(false)
                openNotification('error', 'Failed !', `Failed to setting status.`)
            }
        }
    }



    return (
        <div>
            <div className="mg-bt-20 coin-collect-title">
                <div className="h-d-t-p">Pending Task</div>
                <div className="coin-collect-card-list">
                    {sumaries.map((list, idx) => <div key={idx} className="c-c-t-s">
                        <div className="coin-collect-card">
                            <div className="coin-collect-card-grid">
                                <div>{list.name}</div>
                                <div>{numberWithCommas(list.value || 0, 0)}</div>
                            </div>
                        </div>
                    </div>)}
                </div>
            </div>

            <div className={`pro-reward-header-grid pro-reward-header-grid-column-${!menuList.includes('WASHERMAN')}`}>
                <div className={`pro-reward-header-grid-search-${!menuList.includes('WASHERMAN')}`}>
                    <Input
                        placeholder="search .."
                        value={searchWord}
                        onChange={e => setSearchWord(e.target.value)}
                        onPressEnter={() => {
                            setCurrentPage(1)
                            setSearchButton(!searchButton)
                        }}
                    />
                </div>
                <div className="f-c-c d-b-t-n-s d-bg-c-t"
                    onClick={() => {
                        setCurrentPage(1)
                        setSearchButton(!searchButton)
                    }}>
                    <i className="bi bi-search" style={{ lineHeight: '0' }}></i>
                </div>
                <div className={`f-c-c d-b-t-n-s d-bg-c-t h-32 pro-reward-header-grid-export-${!menuList.includes('EXPORT')}`}
                    style={{ pointerEvents: waitingForCSV ? 'none' : null }}
                    onClick={() => toCSVFile()}
                >
                    {waitingForCSV ? <LoadingOutlined /> : 'Export CSV'}
                </div>
            </div>

            <Table bordered
                size="small"
                rowKey={record => record.customer_id}
                loading={isLoading}
                dataSource={tableData && tableData.data_list || []}
                columns={columns}
                pagination={false}
                scroll={{ x: true }}
            />

            <div className="pgnt-st">
                <Pagination
                    size="small"
                    current={currentPage}
                    onChange={page => setCurrentPage(page)}
                    total={tableData && tableData.max_total_rows}
                    pageSize={perPage}
                    showSizeChanger={false}
                />
            </div>


            <Modal
                title=""
                visible={isModalSetting}
                closable={false}
                footer={false}
                centered
            >

                <div style={{ marginBottom: "15px" }}>
                    <div className="f-s-c" style={{ marginBottom: "10px" }}>สถานะ</div>
                    <div className={isAlertStatus && 'antd-sl-al-bd' || ''}>
                        <div className="f-s-c">
                            <Select
                                value={statusId}
                                onChange={(e) => {
                                    setIsAlertStatus(false)
                                    setStatusId(e)
                                }}
                                placeholder="กรุณาเลือกสถานะ" style={{ width: '100%' }}>
                                <Option value={1}>ส่งผ้า</Option>
                                <Option value={2}>แม่บ้านรับผ้า</Option>
                                <Option value={3}>ซักผ้า</Option>
                                <Option value={4}>อบผ้า</Option>
                                <Option value={5}>พับผ้า</Option>
                                <Option value={6}>แม่บ้านส่งผ้า</Option>
                                <Option value={7}>ลูกค้ารับผ้า</Option>
                                <Option value={8}>ยกเลิก</Option>
                            </Select>
                        </div>
                    </div>
                </div>



                <div className="m-b-y-n-g">
                    <div className="f-c-c d-b-t-n-s b-c-o-i" style={{ backgroundColor: '#cccccc' }} onClick={() => setIsModalSetting(false)}>Cancel</div>
                    <div className="f-c-c d-b-t-n-s b-c-o-i"
                        style={{ backgroundColor: '#3f4d67', pointerEvents: isLoadingSetting ? 'none' : null }}
                        onClick={() => (changeStatus())}
                    >{isLoadingSetting ? <LoadingOutlined /> : 'Confirm'}</div>
                </div>



            </Modal>



        </div>
    )
}

export default TaskList
