import React, { useState, useEffect } from "react";
import { useParams, useLocation, useHistory, Link } from "react-router-dom";
import { Input, InputNumber, Select, DatePicker, Radio, Divider } from "antd";
import "./Machine.css";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import "moment/locale/th";

import { openNotification, getSearchParams } from "../../utils/function";
import {
  apiMCHGetTypeBySiteId,
  apiMCHGetSubTypeByTypeId,
  apiMCHGetBrand,
  apiMCHGetModel,
  apiMCHGetMachine,
  apiaddV1Machine,
  apiMCHAddMachine,
  apiMCHEditMachineById,
  apiMCHGetMachineById,
  apiMCHGetController
} from "../../services/apis/machine";
import { apiPRDGetProductByMachineSubTypeId, apiPRDGetProductByMachineSaveV2 } from "../../services/apis/product";
import { UPDATE_MACHINE, ADD_MACHINE } from "../../resolvers/Mutation";
import {
  QueryGraphQL,
  GET_SITES,
  QueryGraphQLById,
  GET_MACHINE,
  GET_MACHINES_BY_ID, QueryGraphQL_ConfigMachineByDeviceName, GET_CONFIG_MACHINE
} from "../../resolvers/Query";
import { useMutation } from "@apollo/client";
import MachineSetconfig from "./MachineSetconfig"
const { Option } = Select;

const MachineAddEditPageV2 = () => {
  const { machineId } = useParams();
  const location = useLocation();
  const history = useHistory();

  const params = new URLSearchParams(location.search);
  const p = params.get("p");
  const ttl = params.get("ttl");
  const mcht = params.get("mcht");
  const s = params.get("s");
  const sw = params.get("sw");
  const role = (localStorage.getItem("user_role") || "").toUpperCase();
  const [currentPage, setCurrentPage] = useState(Number(p) || 1);
  const [perPage, setPerPage] = useState(Number(ttl) || 20);
  const [machineType, setMachineType] = useState(Number(mcht) || "");
  const [site, setSite] = useState(
    Number(s) ||
    (role === "SUPERADMIN" ? "" : localStorage.getItem("site_id")) ||
    ""
  );
  const [isLoading, setIsLoading] = useState(false);
  const [machine, setMachine] = useState(undefined);
  const [machineTypes, setMachineTypes] = useState([]);
  const [machineSubTypes, setMachineSubTypes] = useState([]);
  const [sites, setSites] = useState([]);
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [controllers, setControllers] = useState([]);
  const [newSite, setNewSite] = useState("");
  const [productList, setProductList] = useState([]);
  const [machineProducts, setMachineProducts] = useState([]);
  const moneyAcceptable = [
    "1",
    "2",
    "5",
    "10",
    "20",
    "50",
    "100",
    "500",
    "1000",
  ];

  const [isAlertName, setIsAlertName] = useState(false);
  const [isAlertType, setIsAlertType] = useState(false);
  const [isAlertBrand, setIsAlertBrand] = useState(false);
  const [isAlertModel, setIsAlertModel] = useState(false);
  const [isAlertSubType, setIsAlertSubType] = useState(false);
  const [isAlertNo, setIsAlertNo] = useState(false);
  const [isAlertmachine_order, setIsAlertmachine_order] = useState(false);
  const [isAlertMacAddress, setIsAlertMacAddress] = useState(false);
  const [isAlertPrice, setIsAlertPrice] = useState(false);
  const [isAlertMoneyAccept, setIsAlertMoneyAccept] = useState(false);
  const [isAlertMachineTime, setIsAlertMachineTime] = useState(false);
  const [isAlertSetUpDate, setIsAlertSetUpDate] = useState(false);
  const [isAlertOpenDate, setIsAlertOpenDate] = useState(false);
  const [isAlertProduct, setIsAlertProduct] = useState(false);
  const [isAlertSiteName, setIsAlertSiteName] = useState(false);
  const [isAlertDeviceName, setIsAlertDeviceName] = useState(false);

  const dataMachineList = QueryGraphQLById(GET_MACHINES_BY_ID, params.get("s"));
  const dataMachineById = dataMachineList?.data?.getMachineBySite;
  const dataMachine = QueryGraphQLById(GET_MACHINE, machineId);
  const machineFound = dataMachine?.data?.getMachine;
  const [editMachine, { data, error }] = useMutation(UPDATE_MACHINE);
  const [addMachine, dataAddMachine] = useMutation(ADD_MACHINE);

  const dataSiteList = QueryGraphQL(GET_SITES);
  const dataSites = dataSiteList?.data?.getSites;

  const machine_name = localStorage.getItem("machine_name");
  const device_name = localStorage.getItem("device_name");
  const GetConfigMachine = QueryGraphQL_ConfigMachineByDeviceName(GET_CONFIG_MACHINE, device_name);

  console.log("-data->🙂🧟‍♂️", machine);
  //console.log("MachineAddEditPageV2-data->🙂",machine_name, device_name);

  useEffect(() => {
    getTableData();
  }, []);

  useEffect(() => {
    console.log("machineFound", machineId, dataMachineById, machineFound);
    if (machineId && dataMachineById?.length > 0) {
      let newData = "";
      dataMachineById.map((item) => {
        if (item?.id === machineId) {
          console.log(machineId)
          newData = {
            ...item,
            time_default: parseInt(item?.time_default) / 60,
          };
          if (newData.machine_run_mode != "") {
            newData.machine_run_mode = JSON.parse(newData.machine_run_mode)
          }
        } else {
          console.log("ไม่พบเครื่อง ID : " + machineId)
        }
      });

      setMachine(newData);
    }
    // if (machineId && machineFound) {
    //   let newData = {};
    //   setMachine((prevState) => {
    //     newData = {
    //       ...machineFound,
    //       time_default: parseInt(machineFound.time_default || 60) / 60,
    //     };
    //     if (newData.machine_run_mode != "") {
    //       newData.machine_run_mode = JSON.parse(machineFound.machine_run_mode)
    //     }
    //     return newData;
    //   });
    // }
  }, [machineId, dataMachineById, machineFound]);

  useEffect(() => {
    if (isLoading) {
      if (machineId) {
        if (error) {
          setIsLoading(false);
          openNotification("error", "Failed !", "Failed to edit machine.");
        }
        if (data?.updateMachine) {
          setIsLoading(false);
          openNotification("success", "Success !", "Edit machine successful.");
          setTimeout(
            () =>
              history.push(
                `/machine/list/v2${getSearchParams(
                  ["p", "ttl", "mcht", "s", "sw"],
                  [p, ttl, mcht, s, sw]
                )}`
              ),
            1000
          );
        }
      } else {
        if (dataAddMachine?.error) {
          setIsLoading(false);
          openNotification("error", "Failed !", "Failed to add new machine.");
        }
        if (dataAddMachine?.data?.addMachine) {
          setIsLoading(false);
          openNotification(
            "success",
            "Success !",
            "Add new machine successful."
          );
          setTimeout(
            () =>
              history.push(
                `/machine/list/v2${getSearchParams(
                  ["p", "ttl", "mcht", "s", "sw"],
                  [p, ttl, mcht, s, sw]
                )}`
              ),
            1000
          );
        }
      }
    }
  }, [error, data, dataAddMachine]);

  useEffect(() => {
    const getStaticDropdown = async () => {
      let type = await apiMCHGetTypeBySiteId("");
      if (type && type.status) setMachineTypes(type.result || []);

      let brand = await apiMCHGetBrand();
      if (brand && brand.status) setBrands(brand.result || []);
    };
    getStaticDropdown();
  }, []);

  useEffect(() => {
    if (dataSites?.length > 0) {
      const dataSiteNew = [];
      dataSites.map((item) => {
        dataSiteNew.push({ site_id: item.id, site_name: item.site_name, siteCode: item.siteCode });
      });
      setSites(dataSiteNew);
    }
  }, [dataSites]);

  useEffect(() => {
    if (machine && machine.machine_group_id) {
      const getMachineSubTypes = async () => {
        let subType = await apiMCHGetSubTypeByTypeId(machine.machine_group_id);
        if (subType && subType.status) setMachineSubTypes(subType.result || []);
      };
      getMachineSubTypes();
    }
  }, [machine && machine.machine_group_id]);

  useEffect(() => {
    if (machine && machine.machine_group_id && machine.machine_sub_group_id) {
      let machine_group = machine.machine_group_id
      let machine_sub_group_id = machine.machine_sub_group_id
      const getMachineProducts = async () => {
        let product = await apiPRDGetProductByMachineSaveV2(
          machine_group,
          machine_sub_group_id
        );
        //  console.log(product)
        if (product) {
          setMachineProducts(product || []);
          let newData = { ...machine };
          let newArrProList = [];
          let newArrProId = [];
          ((newData && newData.product_list) || []).map((pdl) =>
            (product || []).map((mp) => {

              if (pdl.product_id === mp.product_id) {
                newArrProList.push({
                  product_id: mp.product_id,
                  product_name: mp.product_name,
                  product_price: pdl.product_price,
                  machine_type_id: mp.machine_type_id,
                  machine_sub_type_id: mp.machine_sub_type_id,
                  mode: mp.mode,
                  multiple: mp.multiple,
                  time: mp.time
                });
                newArrProId.push(mp.product_id);
              }
            })
          );
          newData.product_list = newArrProList;
          console.log(newData);
          setMachine(newData);
          setProductList(newArrProId); // for display
        }
      };
      getMachineProducts();
    }


  }, [machine && machine.machine_group_id && machine.machine_sub_group_id]);

  useEffect(() => {
    if (machine && machine.brand_id) {
      const getModel = async () => {
        let model = await apiMCHGetModel(machine.brand_id);
        if (model && model.status) setModels(model.result || []);
      };
      getModel();
    }
  }, [machine && machine.brand_id]);

  const getTableData = async () => {
    let _machine = await apiMCHGetMachine(
      false,
      currentPage,
      100000,
      "Warehouse",
      machineType,
      site
    );
    // console.log(_machine)
    // if (_machine && _machine?.result) {
    //   let dataMachineAll = [..._machine.result?.data_list];
    //   if (dataMachineAll?.length > 0) {
    //     let controllerArr = [];
    //     dataMachineAll.map((item) => {
    //       controllerArr.push(item?.machine_mac_address);
    //     });
    //     const _controller = controllerArr.filter((val, id) => {
    //       return controllerArr.indexOf(val) === id;
    //     });
    //     setControllers(_controller ?? []);
    //   }
    // }
  };

  const addEditMachine = async () => {
    if (
      !(machine && machine.machine_name) ||
      !(machine && machine.machine_group) ||
      !(machine && machine.brand) ||
      !(machine && machine.machine_model) ||
      !(machine && machine.machine_sub_group) ||
      !(machine && machine.machine_no) ||
      !(machine && machine.machine_order) ||
      !(machine && machine.price_default) ||
      !(machine && machine.coin_get) ||
      !(machine && machine.time_default) ||
      !(machine && machine.start_period) ||
      !(machine && machine.end_warranty)
    ) {
      if (!(machine && machine.machine_name)) setIsAlertName(true);
      if (!(machine && machine.machine_group)) setIsAlertType(true);
      if (!(machine && machine.brand)) setIsAlertBrand(true);
      if (!(machine && machine.machine_model)) setIsAlertModel(true);
      if (!(machine && machine.machine_sub_group)) setIsAlertSubType(true);
      if (!(machine && machine.price_default)) setIsAlertPrice(true);
      if (!(machine && machine.machine_no)) setIsAlertNo(true);
      if (!(machine && machine.machine_order)) setIsAlertmachine_order(true);
      if (!(machine && machine.coin_get)) setIsAlertMoneyAccept(true);
      if (!(machine && machine.time_default)) setIsAlertMachineTime(true);
      if (!(machine && machine.start_period)) setIsAlertSetUpDate(true);
      if (!(machine && machine.end_warranty)) setIsAlertOpenDate(true);
      if (!(machine && machine.siteId)) setIsAlertSiteName(true);
      if (!(machine && machine.deviceName)) setIsAlertDeviceName(true);
    } else {
      setIsLoading(true);

      let data = { ...machine };
      data.machine_run_mode = JSON.stringify(machine.machine_run_mode)
      // data.coin_get = machine.coin_get.join();
      data.start_period = moment(machine.start_period).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      data.end_warranty = moment(machine.end_warranty).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      data.time_default = machine.time_default * 60 ?? machine.time_default;
      let _productList = [];
      if (machine?.product_list?.length > 0) {
        machine?.product_list?.filter((item) => {
          _productList.push(item.product_name);
        });
      }
      if (machineId) {
        let V1Machine = {
          machine_name: data.machine_name || "",
          machine_type_id: data.machine_group_id,
          machine_sub_type_id: data.machine_sub_group_id,
          machine_price: data.price_default,
          machine_group_id: data.siteCode,
          machine_no: data.deviceName,
          active: 1,
          brand_id: 1,
          model_id: 1,
        }
        //   console.log(V1Machine)
        let action = await apiaddV1Machine(V1Machine)
        editMachine({
          variables: {
            machineId: machineId,
            siteId: data.siteId ? data.siteId + "" : "",
            deviceName: data.deviceName ? data.deviceName + "" : "",
            machine_name: data.machine_name || "",
            brand: data.brand || "",
            machine_model: data.machine_model || "",
            machine_group: data.machine_group || "",
            machine_group_id: data.machine_group_id + "" || "",
            machine_sub_group: data.machine_sub_group || "",
            machine_sub_group_id: data.machine_sub_group_id + "" || "",
            machine_no: data.machine_no || "",
            price_default: data.price_default ? data.price_default + "" : "",
            time_default: data.time_default ? data.time_default + "" : "",
            coin_get: data.coin_get || [""],
            start_period: data.start_period || "",
            end_warranty: data.end_warranty || "",
            circuit: data?.circuit || "",
            machine_run_mode: data.machine_run_mode || [""],
            machine_pic: data.machine_pic || "",
            machine_order: data.machine_order ? data.machine_order + "" : "",
            isLGFlow: data?.lg_device?.deviceId ? data?.lg_device?.isLGFlow : false
          },
        });
      } else {
        addMachine({
          variables: {
            siteId: data.siteId ? data.siteId + "" : "",
            deviceName: data.deviceName ? data.deviceName + "" : "",
            machine_name: data.machine_name || "",
            brand: data.brand || "",
            machine_model: data.machine_model || "",
            machine_group: data.machine_group || "",
            machine_group_id: data.machine_group_id || "",
            machine_sub_group: data.machine_sub_group || "",
            machine_sub_group_id: data.machine_sub_group_id || "",
            machine_no: data.machine_no || "",
            price_default: data.price_default ? data.price_default + "" : "",
            time_default: data.time_default ? data.time_default + "" : "",
            coin_get: data.coin_get || [""],
            start_period: data.start_period || "",
            end_warranty: data.end_warranty || "",
            circuit: data?.circuit || "",
            machine_run_mode: data.machine_run_mode || [""],
            machine_pic: data.machine_pic || "",
            machine_order: data.machine_order ? data.machine_order + "" : "",
          },
        });
      }
    }
  };
  const CheckDataV2 = () => {
    // console.log("🚀 ~ file: MachineAddEditPage-v2.js:CheckDataV2 ~ data", GetConfigMachine.data)
    // if (GetConfigMachine.data.getConfigMachine == null) {
    //   alert("Not Data V.2")
    // }
    // else {
    //   history.push(
    //     `/machineConfig/edit/v2${machineId}`
    //   );
    // }
    // console.log(machine.deviceName)
    // console.log("-Config-data->🙂🧟‍♂️", GetConfigMachine.data.getConfigMachine);
  }
  let { isLoggedIn } = "a";

  const renderButtonSetconfig = () => {
    if (machineId) {
      return <div
        className="f-c-c d-b-t-n-s d-bg-c-t "
        style={{ width: "170px", pointerEvents: isLoading ? "none" : null }}
        onClick={() => CheckDataV2()}
      >
        Set Config Machine
      </div>;
    } else {
      return "";
    }
  }
  return (
    <div>

      <div className=" h-d-t-p">
        Machine List - {machineId ? "Edit" : "Add"}{" "}
        {machineId
          ? `: ${machine_name ?? ""} (
        ${device_name ?? ""})`
          : ""}

      </div>
      <div style={{ justifyContent: "right" }} className="mt-1 f-c-c s-e-b ">
        {renderButtonSetconfig()}

        {(machine?.lg_device?.deviceId) &&
          <div
            className="f-c-c d-b-t-n-s d-bg-c-t ms-2"
            style={{ width: "170px", pointerEvents: isLoading ? "none" : null }}
            onClick={() => {
              history.push(
                `/machine/list/edit/v2/${machineId}/lg-settings/${machine?.lg_device?.deviceId}`
              )
            }}
          >
            Set LG Settings
          </div>
        }
      </div>
      <div className="machine-add-container-grid-with-two pt-2">
        <div className="machine-add-container-grid-with-two-order-1">
          <div className="machine-add-container-grid">
            <div className="f-s-c">ชื่อเครื่อง</div>
            <div>
              <Input
                style={{ borderColor: isAlertName ? "#EC1C24" : null }}
                placeholder="ชื่อเครื่อง"
                value={(machine && machine.machine_name) || ""}
                onChange={(e) => {
                  setIsAlertName(false);
                  setMachine({ ...machine, machine_name: e.target.value });
                }}
              />
            </div>
          </div>
        </div>

        <div className="machine-add-container-grid-with-two-order-3">
          <div className="machine-add-brand-model-grid">
            <div className="f-s-c">Brand</div>
            {/* <Input
              style={{ borderColor: isAlertBrand ? "#EC1C24" : null }}
              placeholder="Brand"
              value={(machine && machine.brand) || ""}
              onChange={(e) => {
                setIsAlertBrand(false);
                setMachine({
                  ...machine,brand_id
                  brand: e.target.value,
                });
              }}
            /> */}
            <div className={(isAlertBrand && "antd-sl-al-bd") || ""}>
              <Select
                style={{ width: "100%" }}
                placeholder="Brand"
                value={machine && machine.brand}
                onChange={(value, data) => {
                  setIsAlertBrand(false);
                  setMachine({
                    ...machine,
                    brand_id: value,
                    brand: data.brandName,
                    machine_model: undefined,
                  });
                }}
              >
                {brands.map((brand, idx) => (
                  <Option key={idx} value={brand._id} brandName={brand.name}>
                    {brand.name}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>

        <div className="machine-add-container-grid-with-two-order-2">
          <div className="machine-add-container-grid">
            <div className="f-s-c">ประเภทเครื่อง</div>
            {/* <Input
              style={{ borderColor: isAlertType ? "#EC1C24" : null }}
              placeholder="ประเภทเครื่อง"
              value={(machine && machine.machine_group) || ""}
              onChange={(e) => {
                setIsAlertType(false);
                setMachine({
                  ...machine,
                  machine_group: e.target.value,
                });
              }}
            /> */}
            <div className={(isAlertType && "antd-sl-al-bd") || ""}>
              <Select
                style={{ width: "100%" }}
                placeholder="ประเภทเครื่อง"
                value={machine && machine.machine_group}
                onChange={(value, data) => {
                  setIsAlertType(false);
                  setMachine({
                    ...machine,
                    machine_group_id: value,
                    machine_group: data.machineTypeName,
                    machine_sub_group: undefined,
                    product_list: [],
                  });
                }}
              >
                {machineTypes.map((type, idx) => (
                  <Option
                    key={idx}
                    value={type.machine_type_id}
                    machineTypeName={type.machine_type_name}
                  >
                    {type.machine_type_name}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>

        <div className="machine-add-container-grid-with-two-order-4">
          <div className="machine-add-brand-model-grid">
            <div className="f-s-c">Model</div>
            {/* <Input
              style={{ borderColor: isAlertModel ? "#EC1C24" : null }}
              placeholder="Model"
              value={(machine && machine.machine_model) || ""}
              onChange={(e) => {
                setIsAlertModel(false);
                setMachine({
                  ...machine,
                  machine_model: e.target.value,
                });
              }}
            /> */}
            <div className={(isAlertModel && "antd-sl-al-bd") || ""}>
              <Select
                style={{ width: "100%" }}
                placeholder="Model"
                disabled={!(machine && machine.brand)}
                value={machine && machine.machine_model}
                onChange={(value, data) => {
                  setIsAlertModel(false);
                  setMachine({
                    ...machine,
                    machine_model_id: value,
                    machine_model: data.modelName,
                  });
                }}
              >
                {models.map((model, idx) => (
                  <Option key={idx} value={model._id} modelName={model.name}>
                    {model.name}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
      </div>

      <div className="machine-add-container-grid">
        <div className="f-s-c">ประเภทเครื่อง - ย่อย</div>
        {/* <Input
          style={{ borderColor: isAlertSubType ? "#EC1C24" : null }}
          placeholder="ประเภทเครื่อง - ย่อย"
          value={(machine && machine.machine_sub_group) || ""}
          onChange={(e) => {
            setIsAlertSubType(false);
            setMachine({
              ...machine,
              machine_sub_group: e.target.value,
            });
          }}
        /> */}
        <div className={(isAlertSubType && "antd-sl-al-bd") || ""}>
          <Select
            style={{ width: "100%" }}
            placeholder="ประเภทเครื่อง - ย่อย"
            disabled={!(machine && machine.machine_group)}
            value={(machine && machine.machine_sub_group) || undefined}
            onChange={(value, data) => {
              setIsAlertSubType(false);
              setMachine({
                ...machine,
                machine_sub_group_id: value,
                machine_sub_group: data.machineSubTypeName,
                product_list: [],
              });
            }}
          >
            {machineSubTypes.map((subType, idx) => (
              <Option
                key={idx}
                value={subType.machine_sub_type_id}
                machineSubTypeName={subType.machine_sub_type_name}
              >
                {subType.machine_sub_type_name}
              </Option>
            ))}
          </Select>
        </div>

        <div className="f-s-c">รหัสเครื่อง ( SN )</div>
        <div>
          <Input
            placeholder="รหัสเครื่อง ( SN )"
            value={(machine && machine.machine_no) || ""}
            onChange={(e) => {
              setMachine({ ...machine, machine_no: e.target.value });
              setIsAlertNo(false);
            }}
            style={{
              width: "100%",
              borderColor: isAlertNo ? "#EC1C24" : null,
            }}
          />
        </div>

        <div className="f-s-c">ราคาเครื่อง ( เริ่มต้น )</div>
        <div>
          <InputNumber
            min={0}
            step={0.01}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            style={{
              width: "100%",
              borderColor: isAlertPrice ? "#EC1C24" : null,
            }}
            placeholder="ราคาเครื่อง ( เริ่มต้น )"
            value={machine && machine.price_default}
            onChange={(value) => {
              setIsAlertPrice(false);
              setMachine({
                ...machine,
                price_default: Math.round(value * 100) / 100 || 0,
              });
            }}
          />
        </div>

        <div className="f-s-c">เงินที่รับ</div>
        <div
          className={`pst-rlt ${(isAlertMoneyAccept && "antd-sl-al-bd") || ""}`}
        >
          <Select
            style={{
              width: "100%",
              borderColor: isAlertMoneyAccept ? "#EC1C24" : null,
            }}

            placeholder="เงินที่รับ"
            mode="multiple"
            showArrow
            value={(machine && machine.coin_get) || []}
            onChange={(value) => {
              setIsAlertMoneyAccept(false);
              setMachine({
                ...machine,
                coin_get: value.sort((a, b) => a - b),
              });
            }}

          >
            {moneyAcceptable.map((money, idx) => (
              <Option key={idx} value={money}>
                {money}
              </Option>
            ))}
          </Select>
          <div className="s-f-o-i-p">บาท</div>
        </div>

        <div className="f-s-c">เวลาเครื่องทำงาน</div>
        <div className="pst-rlt">
          <InputNumber
            min={0}
            step={1}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            style={{
              width: "100%",
              borderColor: isAlertMachineTime ? "#EC1C24" : null,
            }}
            placeholder="เวลาเครื่องทำงาน"
            value={machine && machine.time_default}
            onChange={(value) => {
              setIsAlertMachineTime(false);
              setMachine({ ...machine, time_default: Math.round(value) || 0 });
            }}
          />
          <div className="s-f-o-i-p">นาที</div>
        </div>

        <div className="f-s-c">ลำดับการแสดง</div>
        <div>
          <InputNumber
            min={1}
            step={1}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            style={{
              width: "100%",
              borderColor: isAlertmachine_order ? "#EC1C24" : null,
            }}
            placeholder="ลำดับการแสดง"
            value={machine && machine.machine_order}
            onChange={(value) => {
              setIsAlertmachine_order(false);
              setMachine({
                ...machine,
                machine_order: value ? Math.round(value) || 1 : "",
              })
            }}
          />
        </div>

        <div className="f-s-c">วันที่รับเครื่อง</div>
        <div>
          <DatePicker
            style={{
              width: "100%",
              borderColor: isAlertSetUpDate ? "#EC1C24" : null,
            }}
            placeholder="วันที่รับเครื่อง"
            value={
              machine && machine.start_period && moment(machine.start_period)
            }
            onChange={(date) => {
              setIsAlertSetUpDate(false);
              setMachine({ ...machine, start_period: date });
            }}
            format={(date) =>
              moment(date).add(543, "years").format("D MMM YYYY")
            }
            allowClear={false}
          />
        </div>

        <div className="f-s-c">วันที่สิ้นสุดรับประกัน</div>
        <div>
          <DatePicker
            style={{
              width: "100%",
              borderColor: isAlertOpenDate ? "#EC1C24" : null,
            }}
            placeholder="วันที่สิ้นสุดรับประกัน"
            value={
              machine && machine.end_warranty && moment(machine.end_warranty)
            }
            onChange={(date) => {
              setIsAlertOpenDate(false);
              setMachine({ ...machine, end_warranty: date });
            }}
            format={(date) =>
              moment(date).add(543, "years").format("D MMM YYYY")
            }
            allowClear={false}
          />
        </div>

        <div className="f-s-c">แผงควบคุม</div>
        <div className={(isAlertDeviceName && "antd-sl-al-bd") || ""}>
          {/* <Select
            style={{ width: "100%" }}
            placeholder="แผงควบคุม"
            showSearch
            value={machine && machine.deviceName}
            onChange={(value) => {
              setIsAlertDeviceName(false);
              setMachine({
                ...machine,
                deviceName: value ?? "",
              });
            }}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {controllers.map((controller, idx) => (
              <Option key={idx} value={controller}>
                {controller}
              </Option>
            ))}
          </Select> */}
          <Input
            style={{ borderColor: isAlertMacAddress ? "#EC1C24" : null }}
            placeholder="รหัสเครื่อง ( SN )"
            value={machine && machine.deviceName}
            onChange={(e) => {
              setIsAlertDeviceName(false);
              setMachine({
                ...machine,
                deviceName: e.target.value ?? "",
              });
            }}
          />
        </div>

        <div className="f-s-c">สถานที่</div>
        <div className={(isAlertSiteName && "antd-sl-al-bd") || ""}>
          <Select
            style={{ width: "100%" }}
            showSearch
            // dropdownRender={(menu) => (
            //   <div>
            //     {menu}
            //     <Divider style={{ margin: "4px 0" }} />
            //     <div className="machine-add-dropdown-add-new-grid">
            //       <div>
            //         <Input
            //           placeholder="New Site"
            //           value={newSite}
            //           onChange={(e) => setNewSite(e.target.value)}
            //           onPressEnter={(e) => {
            //             e.stopPropagation();
            //             setSites([
            //               ...sites,
            //               { site_id: newSite, site_name: newSite },
            //             ]);
            //           }}
            //         />
            //       </div>
            //       <div
            //         className="f-c-c d-b-t-n-s d-bg-c-t"
            //         onClick={() =>
            //           setSites([
            //             ...sites,
            //             {
            //               site_id: newSite,
            //               site_name: newSite,
            //               site_new: true,
            //             },
            //           ])
            //         }
            //       >
            //         + Add
            //       </div>
            //     </div>
            //   </div>
            // )}
            placeholder="สถานที่"
            value={machine && machine.site_name}
            onChange={(value, data) => {
              setIsAlertSiteName(false);
              setMachine({
                ...machine,
                site_name: data?.siteName,
                siteId: value,
                siteCode: data?.siteCode,
                // zone_id: undefined,
              });
            }}
            optionFilterProp="children"
          // filterOption={(input, option) =>
          //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          // }
          >
            {sites.map((site, idx) => (
              <Option key={idx} value={site.site_id} siteName={site.site_name} siteCode={site.siteCode}>
                {site.site_name}
              </Option>
            ))}
          </Select>
        </div>

        {/* <div className="f-s-c">โซน</div>
        <div>
          <Select
            style={{ width: "100%" }}
            placeholder="โซน"
            disabled={!checkIfIdEqualToZero(machine && machine.site_id)}
            value={machine && machine.zone_id}
            onChange={(value) => setMachine({ ...machine, zone_id: value })}
          >
            {zones.map((zone, idx) => (
              <Option key={idx} value={zone.zone_id}>
                {zone.zone_name}
              </Option>
            ))}
          </Select>
        </div> */}

        {/* <div className="f-s-c">สินค้า</div> */}
        {/* <div className={`pst-rlt ${(isAlertProduct && "antd-sl-al-bd") || ""}`}>
          <Select
            style={{ width: "100%" }}
            placeholder="สินค้า"
            mode="tags"
            // showArrow
            value={(machine && machine.machine_run_mode) || []}
            onChange={(value) => {
              setIsAlertProduct(false);
              setMachine({
                ...machine,
                machine_run_mode: value.sort((a, b) => a - b),
                // machine_run_mode: value,
              });
            }}
          >
          </Select>
        </div> */}
        {/* <div>
          <Select
            style={{ width: "100%" }}
            placeholder="สินค้า"
            disabled={
              !(machine && machine.machine_group && machine.machine_sub_group)
            }
            mode="multiple"
            showArrow
            value={productList || undefined}
            onChange={(value) => {
              setProductList(value); // for display

              let productListCopy = [...machine.product_list];
              let productListChange = [];
              let productList = [];

              value.map((pid) =>
                machineProducts.map((mp) =>
                  pid === mp.product_id ? productListChange.push(mp) : ""
                )
              );

              // productListChange.map((plch) => {
              //   let found = productListCopy.find(
              //     (plcp) => plcp.product_id === plch.product_id
              //   );
              //   if (!found) {
              //     plch.product_price = 0;
              //     productList.push(plch);
              //   } else productList.push(found);
              // });

              setMachine({ ...machine, product_list: productList });
            }}
          >
            {machineProducts.map((product, idx) => (
              <Option key={idx} value={product.product_id}>
                {product.product_name}
              </Option>
            ))}
          </Select>
        </div> */}
        {
          machine?.lg_device?.deviceId &&
          <>
            <div className="f-s-c">ใช้กับ LG API</div>
            <div>
              <Radio.Group value={machine?.lg_device?.isLGFlow} disabled={!machine?.lg_device?.deviceId}
                onChange={(e) => {
                  setMachine((prevState) => {
                    return {
                      ...prevState,
                      lg_device: {
                        ...prevState["lg_device"],
                        isLGFlow: e.target.value
                      }
                    }
                  })
                }}>
                <Radio value={false}>ไม่ใช้</Radio>
                <Radio value={true}>ใช้</Radio>
              </Radio.Group>
            </div>
          </>
        }

        <div className="f-s-c">โหมดเครื่อง</div>
        <div>
          <Select
            style={{ width: "100%" }}
            placeholder="สินค้า"
            disabled={
              !(machine && machine.machine_group_id && machine.machine_sub_group_id)
            }
            mode="multiple"
            showArrow
            value={productList || undefined}

            onChange={(value) => {
              setProductList(value); // for display

              //let productListCopy = [...machine.machine_run_mode];
              let productListChange = [];
              let productList = [];

              value.map((pid) =>
                machineProducts.map((mp) =>
                  pid === mp.product_id ? productListChange.push(mp) : ""
                )
              );

              // productListChange.map((plch) => {
              //   let found = productListCopy.find(
              //     (plcp) => plcp.product_id === plch.product_id
              //   );
              //   if (!found) {
              //     plch.product_price = 0;
              //     productList.push(plch);
              //   } else productList.push(found);
              // });
              //   console.log(productListChange)
              //  console.log(productList)
              setMachine({ ...machine, machine_run_mode: productListChange });
            }}
          >
            {machineProducts.map((product, idx) => (
              <Option key={idx} value={product.product_id}>
                {product.product_name}
              </Option>
            ))}
          </Select>
        </div>
        <div></div>

        {(() => {
          if (machine && machine.machine_run_mode != "") {
            return (
              <div>
                {((machine && machine.machine_run_mode) || []).map((product, idx) => (
                  <div key={idx} className="machine-add-product-grid">
                    <div className="f-s-c">{product.product_name}</div>
                    <div>
                      <InputNumber
                        min={0}
                        step={1}
                        formatter={(value) =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        style={{ width: "100%" }}
                        placeholder="ราคา"
                        value={product.product_price || 0}
                        onChange={(value) => {
                          let newMachine = { ...machine };
                          newMachine.machine_run_mode[idx].product_price =
                            Math.round(value);
                          setMachine(newMachine);
                        }}
                      />
                    </div>
                    <div className="f-s-c">บาท</div>
                    <div
                      className="f-c-c machine-add-product-icon"
                      onClick={() => {
                        let filterProductList = productList.filter(
                          (pl) => pl !== product.product_id
                        );
                        setProductList(filterProductList);
                        let filterProductListMachine = machine.machine_run_mode.filter(
                          (mpl) => mpl.product_id !== product.product_id
                        );
                        let machineCopy = { ...machine };
                        machineCopy.machine_run_mode = filterProductListMachine;

                        setMachine(machineCopy);
                      }}
                    >
                      <i className="bi bi-x-circle-fill"></i>
                    </div>
                  </div>
                ))}
              </div>
            )
          }
        })()}
      </div>

      <div className="f-c-c s-e-b">
        <div>
          <div
            className="f-c-c d-b-t-n-s d-bg-c-t "
            style={{ width: "170px", pointerEvents: isLoading ? "none" : null }}
            onClick={() => addEditMachine()}
          >
            {isLoading ? (
              <LoadingOutlined />
            ) : (
              `${machineId ? "Edit" : "Add New"} Machine`
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MachineAddEditPageV2;
