import React, { useState, useEffect } from 'react'
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { Input, InputNumber, Select, DatePicker, Avatar } from 'antd';
import './Promotion.css'
import { LoadingOutlined } from '@ant-design/icons';

import { checkImageSize } from '../../utils/function'

import moment from 'moment';
import 'moment/locale/th'

import { openNotification, getSearchParams } from '../../utils/function'
import { apiPMTAddReward, apiPMTGetRewardById, apiPMTEditRewardById } from '../../services/apis/promotion'

const { Option } = Select;
const { RangePicker } = DatePicker;

const PromotionRewardAddEditPage = () => {
  const { promotionId } = useParams();
  const location = useLocation();
  const history = useHistory();

  const params = new URLSearchParams(location.search)
  const p = params.get('p')
  const ttl = params.get('ttl')
  const sw = params.get('sw')

  const [isLoading, setIsLoading] = useState(false)
  const [promotion, setPromotion] = useState(undefined)

  const [isAlertPeriodPromotion, setIsAlertPeriodPromotion] = useState(false)
  const [isAlertName, setIsAlertName] = useState(false)
  const [isAlertPoint, setIsAlertPoint] = useState(false)
  const [isAlertImage, setIsAlertImage] = useState(false)
  const [isAlertCredit, setIsAlertCredit] = useState(false)

  useEffect(() => {
    setPromotion({ ...promotion, return_type: 'CREDIT' })
  }, [])

  useEffect(() => {
    if (promotionId) getData()
  }, [promotionId])

  const getData = async () => {
    let data = await apiPMTGetRewardById(promotionId)
    if (data && data.status) setPromotion(data.result)
  }

  const addEditPromotion = async () => {
    if (
      !(promotion && promotion.start_date) ||
      !(promotion && promotion.name) ||
      isNaN(promotion && promotion.value) ||
      !(promotionId || promotion && promotion.image_file) ||
      (promotion && promotion.return_type && promotion.return_type === 'CREDIT' && isNaN(promotion && promotion.return_value))
    ) {
      if (!(promotion && promotion.start_date)) setIsAlertPeriodPromotion(true)
      if (!(promotion && promotion.name)) setIsAlertName(true)
      if (isNaN(promotion && promotion.value)) setIsAlertPoint(true)
      if (!(promotionId || promotion && promotion.image_file)) setIsAlertImage(true)
      if (promotion && promotion.return_type && promotion.return_type === 'CREDIT' && isNaN(promotion && promotion.return_value)) setIsAlertCredit(true)

    } else {
      setIsLoading(true)

      let data = new FormData()
      if (promotionId) data.append('reward_id', promotion._id)
      data.append('return_type', promotion.return_type)
      data.append('start_date', promotion.start_date)
      data.append('end_date', promotion.end_date)
      data.append('name', promotion.name)
      data.append('value', promotion.value)
      if (!promotionId || promotion.image_file) data.append('image', promotion.image_file)
      data.append('return_value', promotion && promotion.return_type && promotion.return_type === 'CREDIT' ? promotion.return_value : 0)

      let newData = promotionId ? await apiPMTEditRewardById(data) : await apiPMTAddReward(data)
      if (newData && newData.status) {
        setIsLoading(false)
        openNotification('success', 'Success !', `${promotionId ? 'Edit' : 'Add new'} reward successful.`)
        setTimeout(() => history.push(`/promotion/reward${getSearchParams(['p', 'ttl', 'sw'], [p, ttl, sw])}`), 1000);
      } else {
        setIsLoading(false)
        openNotification('error', 'Failed !', `Failed to ${promotionId ? 'edit' : 'add new'} reward.`)
      }
    }
  }

  return (
    <div>
      <div className="mg-bt-20 h-d-t-p">Promotion - {promotionId ? 'Edit' : 'Add'} Reward</div>

      <div className="t-m-c-i-p-g">
        <div className="f-s-c">Type</div>
        <div>
          <Select style={{ width: '100%' }}
            value={promotion && promotion.return_type || undefined}
            onChange={value => setPromotion({ ...promotion, return_type: value })}
          >
            <Option value="CREDIT">Credit</Option>
            <Option value="GIFT">Gift</Option>
          </Select>
        </div>

        <div className="f-s-c">Period Promotion</div>
        <div>
          <RangePicker
            style={{ width: '100%', borderColor: isAlertPeriodPromotion ? '#EC1C24' : null }}
            placeholder={["Period Start", "Period End"]}
            format={date => moment(date).add(543, 'years').format('D MMM YYYY')}
            allowClear={false}
            value={[
              promotion && promotion.start_date && moment(promotion.start_date, 'YYYY-MM-DD HH:mm:ss'),
              promotion && promotion.end_date && moment(promotion.end_date, 'YYYY-MM-DD HH:mm:ss')
            ]}
            onChange={date => {
              setIsAlertPeriodPromotion(false)
              setPromotion({
                ...promotion,
                start_date: moment(date[0]).format('YYYY-MM-DD HH:mm:ss'),
                end_date: moment(date[1]).format('YYYY-MM-DD HH:mm:ss')
              })
            }}
            disabledDate={current => current && current < moment().startOf('day')}
          />
        </div>

        <div className="f-s-c">Name</div>
        <div>
          <Input
            style={{ borderColor: isAlertName ? '#EC1C24' : null }}
            placeholder="Name"
            value={promotion && promotion.name || ''}
            onChange={e => {
              setIsAlertName(false)
              setPromotion({ ...promotion, name: e.target.value })
            }}
          />
        </div>

        <div className="f-s-c">Point</div>
        <div>
          <InputNumber min={0}
            step={1}
            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
            style={{ width: '100%', borderColor: isAlertPoint ? '#EC1C24' : null }}
            placeholder="Point"
            value={promotion && promotion.value}
            onChange={value => {
              setIsAlertPoint(false)
              setPromotion({ ...promotion, value: Math.round(value) || 0 })
            }}
          />
        </div>

        <div className="f-s-c">Image (ขนาด 155*120)</div>
        <div>
          <input id="promotion-reward-image" accept="image/*" type="file" style={{ display: 'none' }}
            onChange={e => {
              setIsAlertImage(false)
              let passSize = checkImageSize(true, e.target.files[0])
              if (passSize) {
                const reader = new FileReader()
                reader.readAsDataURL(e.target.files[0])
                reader.onload = () => setPromotion({
                  ...promotion,
                  image_file: e.target.files[0],
                  image: reader.result
                })
              }
            }} />
          <label htmlFor="promotion-reward-image">
            <div className={`antd-avt-al ${isAlertImage && 'antd-avt-al-bd' || ''}`}>
              <Avatar shape="square" size={150} className="cs-pt" src={promotion && promotion.image || ''} icon={<i className="bi bi-image"></i>} />
            </div>
          </label>
        </div>

        {promotion && promotion.return_type && promotion.return_type === 'CREDIT' && <div className="f-s-c">Credit</div>}
        {promotion && promotion.return_type && promotion.return_type === 'CREDIT' && <div>
          <InputNumber min={0}
            step={1}
            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
            style={{ width: '100%', borderColor: isAlertCredit ? '#EC1C24' : null }}
            placeholder="Credit"
            value={promotion && promotion.return_value}
            onChange={value => {
              setIsAlertCredit(false)
              setPromotion({ ...promotion, return_value: Math.round(value) || 0 })
            }}
          />
        </div>}
      </div>

      <div className="f-c-c s-e-b">
        <div className="f-c-c d-b-t-n-s d-bg-c-t"
          style={{ width: '170px', pointerEvents: isLoading ? 'none' : null }}
          onClick={() => addEditPromotion()}
        >{isLoading ? <LoadingOutlined /> : `${promotionId ? 'Edit' : 'Add New'} Reward`}</div>
      </div>

    </div>
  )
}

export default PromotionRewardAddEditPage
