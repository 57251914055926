import { createAction, createReducer } from '@reduxjs/toolkit';

const initialState = {
  lat: 0,
  lng: 0
}

export const locationLatLng = createAction('LOCATION_LAT_LNG');

export default createReducer(initialState, {
  [locationLatLng]: (state, action) => {
    state = action.payload
    return state;
  },
});
