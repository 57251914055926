import React, { useState, useEffect } from 'react'
import { Link, useParams, useHistory } from 'react-router-dom';
import { Table, Pagination, Tooltip, Avatar, Modal } from 'antd';
import './Machine.css'
import XLSX from "xlsx";
import { LoadingOutlined } from '@ant-design/icons';

import { numberWithCommas, openNotification } from '../../utils/function'
import { apiMCHGetSubTypeListByTypeId, apiMCHDeleteSubTypeById } from '../../services/apis/machine'

const MachineSubTypePage = () => {
  const history = useHistory();
  const { typeId } = useParams();
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [perPage, setPerPage] = useState(20)
  const [tableData, setTableData] = useState(undefined)

  const [visible, setVisible] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalId, setModalId] = useState(undefined)
  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false)

  useEffect(() => {
    getTableData()
  }, [currentPage, perPage])

  const getTableData = async () => {
    setIsLoading(true)
    let machine = await apiMCHGetSubTypeListByTypeId(typeId)
    if (machine && machine.status) {
      setIsLoading(false)
      setTableData(machine.result)
    }
  }

  const columns = [
    {
      title: '#',
      ellipsis: true,
      align: 'center',
      width: '5%',
      render: (data, record, index) => <div>{(index + 1) + ((currentPage - 1) * perPage)}</div>
    },
    {
      title: 'Icon',
      ellipsis: true,
      align: 'center',
      render: (data) => <Avatar shape="square" size={80} src={data.machine_sub_type_icon || ''} icon={<i className="bi bi-image"></i>} />
    },
    {
      title: 'ประเภท',
      ellipsis: true,
      render: (data) => <div>{data.machine_sub_type_name || '-'}</div>
    },
    {
      title: 'จำนวนเครื่อง',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{numberWithCommas(data.machine_sub_type_count || 0, 0)}</div>
    },
    {
      title: 'Option',
      ellipsis: true,
      align: 'center',
      fixed: 'right',
      render: (data) => <div className="f-c-c tb-cl-a">
        <Tooltip placement="top" title="Edit">
          <div className="f-c-c d-b-t-n-s b-c-o-i" style={{ backgroundColor: '#00ADEF' }}
            onClick={e => {
              e.stopPropagation()
              history.push(`/machine/type/${typeId}/sub-type/edit/${data.machine_sub_type_id}`)
            }}
          ><i className="bi bi-pencil-fill"></i></div>
        </Tooltip>
        <Tooltip placement="top" title="Delete">
          <div className="f-c-c d-b-t-n-s b-c-o-i" style={{ backgroundColor: '#ED2228' }}
            onClick={(e) => {
              e.stopPropagation()
              setModalId(data.machine_sub_type_id)
              setModalTitle(data.machine_sub_type_name)
              setVisible(true)
            }}
          ><i className="fas fa-trash"></i></div>
        </Tooltip>
      </div>
    },
  ];

  const deleteData = async () => {
    if (!modalId) {
      openNotification('error', 'Failed !', 'Failed to delete this machine sub type.')
      return
    }

    setIsLoadingConfirm(true)
    let action = await apiMCHDeleteSubTypeById(modalId)
    if (action && action.status) {
      openNotification('success', 'Success !', 'Delete this machine sub type successful.')
      setVisible(false)
      setIsLoadingConfirm(false)
      getTableData()
    } else {
      openNotification('error', 'Failed !', 'Failed to delete this machine sub type.')
      setIsLoadingConfirm(false)
    }
  }

  const toCSVFile = async () => {
    let csvData = [];

    (tableData && tableData.data_list || []).map((data, idx) => {
      let resultRow = {}
      resultRow['#'] = idx + 1
      // resultRow['Icon'] = ''
      resultRow['ชื่อ'] = data.machine_sub_type_name || '-'
      resultRow['จำนวนเครื่อง'] = data.machine_sub_type_count || 0
      csvData.push(resultRow);
    })

    const toCSV = XLSX.utils.json_to_sheet(csvData);
    const wb = XLSX.utils.book_new();

    const fileName = 'MACHINE_SUB_TYPE_LIST'
    XLSX.utils.book_append_sheet(wb, toCSV, fileName);
    XLSX.writeFile(wb, fileName + ".csv");
  }

  return (
    <div>
      <div className="mg-bt-20 h-d-t-p">Machine Sub Type</div>

      <div className="machine-type-header-grid">
        <Link to={`/machine/type/${typeId}/sub-type/add`}>
          <div className="f-c-c d-b-t-n-s d-bg-c-t" style={{ height: '100%' }}>+ Add</div>
        </Link>
        <div className="f-c-c d-b-t-n-s d-bg-c-t" onClick={() => toCSVFile()}>Export CSV</div>
      </div>

      <Table bordered
        size="small"
        rowKey={record => record.machine_sub_type_id}
        loading={isLoading}
        dataSource={tableData && tableData.data_list || []}
        columns={columns}
        pagination={false}
        scroll={{ x: true }}
      />

      <div className="pgnt-st">
        <Pagination
          size="small"
          current={currentPage}
          onChange={page => setCurrentPage(page)}
          total={tableData && tableData.max_total_rows}
          pageSize={perPage}
          showSizeChanger={false}
        />
      </div>

      <Modal
        title=""
        visible={visible}
        closable={false}
        footer={false}
        centered
      >
        <div className="m-t-i-c" style={{ color: '#FFCB05' }}>
          <i className="bi bi-exclamation-triangle-fill"></i>
        </div>
        <div className="m-t-t-q">Do you want to delete '{modalTitle}' ?</div>
        <div className="m-b-y-n-g">
          <div className="f-c-c d-b-t-n-s b-c-o-i" style={{ backgroundColor: '#cccccc' }} onClick={() => setVisible(false)}>Cancel</div>
          <div className="f-c-c d-b-t-n-s b-c-o-i"
            style={{ backgroundColor: '#3f4d67', pointerEvents: isLoadingConfirm ? 'none' : null }}
            onClick={() => deleteData()}
          >{isLoadingConfirm ? <LoadingOutlined /> : 'Confirm'}</div>
        </div>
      </Modal>

    </div>
  )
}

export default MachineSubTypePage
