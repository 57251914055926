import React, { useState, useEffect } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Input, Table, Pagination, Tooltip, Modal } from 'antd';
import './CoinCollection.css'
import XLSX from "xlsx";
import { LoadingOutlined } from '@ant-design/icons';

import moment from 'moment';
import 'moment/locale/th'

import { numberWithCommas, openNotification, getSearchParams } from '../../utils/function'
import { apiCGetCoinBox, apiCDeleteCoinBoxById } from '../../services/apis/coin'

const CoinBox = () => {
  const history = useHistory();
  const location = useLocation();

  const params = new URLSearchParams(location.search)
  const p = params.get('p')
  const ttl = params.get('ttl')
  const sw = params.get('sw')

  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(Number(p) || 1)
  const [perPage, setPerPage] = useState(Number(ttl) || 20)
  const [tableData, setTableData] = useState(undefined)
  const [searchWord, setSearchWord] = useState(sw || '')
  const [searchButton, setSearchButton] = useState(false)
  const [waitingForCSV, setWaitingForCSV] = useState(false)

  const [visible, setVisible] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalId, setModalId] = useState(undefined)
  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false)

  useEffect(() => {
    getTableData()
    history.push(`${location.pathname}${getSearchParams(['p', 'ttl', 'sw'], [currentPage, perPage, searchWord])}`)
  }, [currentPage, perPage, searchButton])

  const getTableData = async () => {
    setIsLoading(true)
    let table = await apiCGetCoinBox(false, currentPage, perPage, searchWord)
    if (table && table.status) {
      setIsLoading(false)
      setTableData(table.result)
    }
  }

  const columns = [
    {
      title: '#',
      ellipsis: true,
      align: 'center',
      width: '5%',
      render: (data, record, index) => <div>{(index + 1) + ((currentPage - 1) * perPage)}</div>
    },
    {
      title: 'วันที่เริ่มต้น',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{data.create_date ? moment(data.create_date).add(543, 'years').format('D MMM YYYY HH:mm:ss') : '-'}</div>
    },
    {
      title: 'สถานที่',
      ellipsis: true,
      render: (data) => <div>{data.site_name || '-'}</div>
    },
    {
      title: 'เครื่อง',
      ellipsis: true,
      render: (data) => <div>{data.machine_name || '-'}</div>
    },
    {
      title: 'รหัสกล่อง',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{data.coin_box_code || '-'}</div>
    },
    {
      title: 'สถานะ',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{data.status || '-'}</div>
    },
    {
      title: 'ตั้งค่า',
      ellipsis: true,
      align: 'center',
      fixed: 'right',
      render: (data) => <div className="f-c-c tb-cl-a">
        <Tooltip placement="top" title="Edit">
          <div className="f-c-c d-b-t-n-s b-c-o-i" style={{ backgroundColor: '#00ADEF' }}
            onClick={e => {
              e.stopPropagation()
              history.push(`/coin/box/edit/${data.coin_box_id}${getSearchParams(['p', 'ttl', 'sw'], [currentPage, perPage, searchWord])}`)
            }}
          ><i className="bi bi-pencil-fill"></i></div>
        </Tooltip>
        <Tooltip placement="top" title="Delete">
          <div className="f-c-c d-b-t-n-s b-c-o-i" style={{ backgroundColor: '#ED2228' }}
            onClick={(e) => {
              e.stopPropagation()
              setModalId(data.coin_box_id)
              setModalTitle(data.coin_box_code)
              setVisible(true)
            }}
          ><i className="fas fa-trash"></i></div>
        </Tooltip>
      </div>
    },
  ];

  const deleteData = async () => {
    if (!modalId) {
      openNotification('error', 'Failed !', 'Failed to delete this coin.')
      return
    }

    setIsLoadingConfirm(true)
    let action = await apiCDeleteCoinBoxById(modalId)
    if (action && action.status) {
      openNotification('success', 'Success !', 'Delete this coin successful.')
      setVisible(false)
      setIsLoadingConfirm(false)
      getTableData()
    } else {
      openNotification('error', 'Failed !', 'Failed to delete this coin.')
      setIsLoadingConfirm(false)
    }
  }

  const toCSVFile = async () => {
    setWaitingForCSV(true)
    let data = await apiCGetCoinBox(true, '', '', searchWord)
    let csvData = [];

    if (data && data.status) {
      (data.result.data_list || []).map((data, idx) => {
        let resultRow = {}
        resultRow['วันที่เริ่มต้น'] = data.create_date || '-'
        resultRow['สถานที่'] = data.site_name || '-'
        resultRow['เครื่อง'] = data.machine_name || '-'
        resultRow['รหัสกล่อง'] = data.coin_box_code || '-'
        resultRow['สถานะ'] = data.status || '-'
        csvData.push(resultRow);
      })

      const toCSV = XLSX.utils.json_to_sheet(csvData);
      const wb = XLSX.utils.book_new();

      const fileName = 'COIN_COLLECT_BOX'
      XLSX.utils.book_append_sheet(wb, toCSV, fileName);
      XLSX.writeFile(wb, fileName + ".csv");

      setWaitingForCSV(false)
    }
  }

  return (
    <div>
      <div className="mg-bt-20 coin-collect-title">
        <div className="h-d-t-p">Coin Collection - Box</div>
        <div className="coin-collect-card-list">
          {(tableData && tableData.sumary || []).map((list, idx) => <div key={idx} className="c-c-t-s">
            <div className="coin-collect-card">
              <div className="coin-collect-card-grid">
                <div>{list.name}</div>
                <div>{numberWithCommas(list.value || 0, 0)}</div>
              </div>
            </div>
          </div>)}
        </div>
      </div>

      <div className="pro-reward-header-grid pro-reward-header-grid-column-false">
        <div className="pro-reward-header-grid-search-false">
          <Input
            placeholder="search .."
            value={searchWord}
            onChange={e => setSearchWord(e.target.value)}
            onPressEnter={() => {
              setCurrentPage(1)
              setSearchButton(!searchButton)
            }}
          />
        </div>
        <div className="f-c-c d-b-t-n-s d-bg-c-t"
          onClick={() => {
            setCurrentPage(1)
            setSearchButton(!searchButton)
          }}>
          <i className="bi bi-search" style={{ lineHeight: '0' }}></i>
        </div>
        <Link to="/coin/box/add">
          <div className="f-c-c d-b-t-n-s d-bg-c-t h-32" style={{ height: '100%' }}>+ Add</div>
        </Link>
        <div className="f-c-c d-b-t-n-s d-bg-c-t h-32 pro-reward-header-grid-export-false"
          style={{ pointerEvents: waitingForCSV ? 'none' : null }}
          onClick={() => toCSVFile()}
        >
          {waitingForCSV ? <LoadingOutlined /> : 'Export CSV'}
        </div>
      </div>

      <Table bordered
        size="small"
        rowKey={record => record.coin_box_id}
        loading={isLoading}
        dataSource={tableData && tableData.data_list || []}
        columns={columns}
        pagination={false}
        scroll={{ x: true }}
        rowClassName="cs-pt"
        onRow={data => ({
          onClick: () => history.push(`/coin/history?sw=${data.coin_box_code}`)
        })}
      />

      <div className="pgnt-st">
        <Pagination
          size="small"
          current={currentPage}
          onChange={page => setCurrentPage(page)}
          total={tableData && tableData.max_total_rows}
          pageSize={perPage}
          showSizeChanger={false}
        />
      </div>

      <Modal
        title=""
        visible={visible}
        closable={false}
        footer={false}
        centered
      >
        <div className="m-t-i-c" style={{ color: '#FFCB05' }}>
          <i className="bi bi-exclamation-triangle-fill"></i>
        </div>
        <div className="m-t-t-q">Do you want to delete '{modalTitle}' ?</div>
        <div className="m-b-y-n-g">
          <div className="f-c-c d-b-t-n-s b-c-o-i" style={{ backgroundColor: '#cccccc' }} onClick={() => setVisible(false)}>Cancel</div>
          <div className="f-c-c d-b-t-n-s b-c-o-i"
            style={{ backgroundColor: '#3f4d67', pointerEvents: isLoadingConfirm ? 'none' : null }}
            onClick={() => deleteData()}
          >{isLoadingConfirm ? <LoadingOutlined /> : 'Confirm'}</div>
        </div>
      </Modal>

    </div>
  )
}

export default CoinBox
