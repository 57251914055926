import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import GoogleMapReact from 'google-map-react';
import { Input } from 'antd';
import './SiteManagement.css'

import { locationLatLng } from '../../redux/place'

const MyMaker = props => {
  // prevent react unknown props
  const { $dimensionKey, $geoService, $getDimensions, $hover, $onMouseAllow, $prerender, ...rest } = props
  return <div className="pst-rlt site-manage-maps-pin" {...rest}><i className="bi bi-geo-alt-fill"></i></div>
}

const SiteManagementMaps = () => {
  const dispatch = useDispatch();
  const place = useSelector(state => state.place);
  const [center, setCenter] = useState({ lat: 0, lng: 0 })
  const [position, setPosition] = useState({ lat: 0, lng: 0 })
  const [selectedLocation, setSelectedLocation] = useState(undefined)
  const [draggable, setDraggable] = useState(true)

  useEffect(() => {
    // prevent google maps reload
    if (!place.lat) {
      let mounted = true
      const onEvent = event => {
        if (mounted) {
          let newPosition = { lat: event.coords.latitude, lng: event.coords.longitude }
          setCenter(newPosition)
          setPosition(newPosition)
          findLocationName(newPosition)
          dispatch(locationLatLng(newPosition))
        }
      }

      navigator.geolocation.getCurrentPosition(onEvent)
      let watchId = navigator.geolocation.watchPosition(onEvent)

      return () => {
        mounted = false;
        navigator.geolocation.clearWatch(watchId);
      }
    } else {
      setCenter(place)
      setPosition(place)
      findLocationName(place)
    }
  }, [place])

  useEffect(() => {
    const input = document.getElementById("search-location");
    const searchBox = new window.google.maps.places.SearchBox(input);
    searchBox.addListener('places_changed', () => {
      const places = searchBox.getPlaces()
      getLocationName(places[0])

      let place = places[0]
      let lat = place.geometry.location.lat()
      let lng = place.geometry.location.lng()
      setCenter({ lat, lng })
      setPosition({ lat, lng })
      dispatch(locationLatLng({ lat, lng }))
    });

    return () => window.google.maps.event.clearInstanceListeners(searchBox)
  }, [])

  const findLocationName = location => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location }, (places, status) => {
      if (status === 'OK') getLocationName(places[0])
    });
  }

  const getLocationName = place => {
    place.display_name = `${place.name ? `${place.name} ·` : ''} ${place.formatted_address || ''}`
    setSelectedLocation(place)
  }

  return (
    <div>
      <div className="site-manage-maps-container">
        <GoogleMapReact
          center={center}
          zoom={18}
          options={{
            mapTypeId: 'roadmap',
            mapTypeControl: false,
            fullscreenControl: false,
            mapId: '9e116762b80775b',
          }}
          draggable={draggable}
          onChildMouseDown={() => setDraggable(false)}
          onChildMouseUp={(childKey, childProps, mouse) => {
            setDraggable(true)
            let newPosition = { lat: mouse.lat || position.lat, lng: mouse.lng || position.lng }
            setCenter(newPosition)
            findLocationName(newPosition)
            dispatch(locationLatLng(newPosition))
          }}
          onChildMouseMove={(childKey, childProps, mouse) => {
            setDraggable(false)
            let newPosition = { lat: mouse.lat, lng: mouse.lng }
            setPosition(newPosition)
          }}
        >
          <MyMaker
            lat={position.lat}
            lng={position.lng}
          />
        </GoogleMapReact>
      </div>

      <div className="site-manage-maps-lat-lng-grid">
        <div>Latitude</div>
        <div>:</div>
        <div>{position.lat}</div>
        <div>Longitude</div>
        <div>:</div>
        <div>{position.lng}</div>
      </div>

      <div className="mg-t-10">
        <Input
          id="search-location"
          placeholder="Location"
          prefix={<div className="f-c-c site-manage-maps-pin-input"><i className="bi bi-geo-alt-fill"></i></div>}
          value={selectedLocation && selectedLocation.display_name || ''}
          onChange={e => setSelectedLocation({ ...selectedLocation, display_name: e.target.value })}
        />
      </div>
    </div >
  )
}

export default SiteManagementMaps
