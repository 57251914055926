import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Table,
  Pagination,
  Avatar,
  Modal,
  Tooltip,
  Input,
  InputNumber,
  Checkbox,
  Select,
} from "antd";
import "./Customer.css";
import XLSX from "xlsx";
import { LoadingOutlined } from "@ant-design/icons";

import moment from "moment";
import "moment/locale/th";

import { numberWithCommas, openNotification } from "../../utils/function";
import {
  apiCTMGetCustomerHistoryById,
  apiCTMUpdateCreditByCustomerId,
  apiCTMEditCustomerPasswordById,
  apiCTMRefundHistoryByCustomerId,
  apiCTMEditCustomerById,
} from "../../services/apis/customer";
import { QueryGraphQLPageLimit, GET_CUSTOMERS } from "../../resolvers/Query";
import { apiCreditCustomer } from "../../services/apis/customer";

const { Option } = Select;

const CustomerViewV2 = () => {
  const { customerId } = useParams();
  const menuList = (localStorage.getItem("list") || "").split(",");
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [tableData, setTableData] = useState(undefined);
  const [customer, setCustomer] = useState(undefined);
  const [waitingForCSV, setWaitingForCSV] = useState(false);

  const [credit, setCredit] = useState({
    credit_type: "Deposit",
    credit_value: undefined,
  });
  const [visibleCredit, setVisibleCredit] = useState(false);
  const [isLoadingConfirmCredit, setIsLoadingConfirmCredit] = useState(false);

  const [newPassword, setNewPassword] = useState("");
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [isLoadingConfirmPassword, setIsLoadingConfirmPassword] =
    useState(false);

  const [visibleRefund, setVisibleRefund] = useState(false);
  const [modalIdRefund, setModalIdRefund] = useState(undefined);
  const [isLoadingConfirmRefund, setIsLoadingConfirmRefund] = useState(false);

  const [isAlertCredit, setIsAlertCredit] = useState(false);
  // const { data } = QueryGraphQL(GET_CUSTOMERS);
  const { data } = QueryGraphQLPageLimit(GET_CUSTOMERS, currentPage, 1000);
  const dataCustomers = data?.getCustomers;
  const [creditData, setCreditData] = useState("");

  // console.log("customer--🐁🐁", customerId, dataCustomers);

  const creditChoices = [
    { key: "Deposit", name: "เติมเครดิต" },
    { key: "Withdraw", name: "ถอนเครดิต" },
  ];

  useEffect(() => {
    if (customerId) getData();
  }, [customerId, dataCustomers]);

  useEffect(() => {
    getDataTable();
  }, [currentPage, perPage]);

  const getData = () => {
    if (dataCustomers?.customer_list?.length > 0) {
      dataCustomers.customer_list.map(async (item) => {
        let dataItem = { ...item };

        if (item?.id == customerId) {
          const dateNew = moment(dataItem.customer_bday).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          dataItem.customer_bday = dateNew;
          setCustomer(dataItem);

          let res = await apiCreditCustomer(item?.customer_id);
          if (res?.data?.balance) {
            setCreditData(res.data.balance);
          }
        }
      });
    }
  };

  const getDataTable = async () => {
    setIsLoadingTable(true);
    let data = await apiCTMGetCustomerHistoryById(
      false,
      currentPage,
      perPage,
      customerId
    );
    if (data && data.status) {
      setTableData(data.result);
      setIsLoadingTable(false);
    }
  };

  const updateCustomerRole = async (role) => {
    let data = { ...customer };
    data.customer_role = role;

    let newData = await apiCTMEditCustomerById(data);
    if (newData && newData.status) {
      getData();
      openNotification(
        "success",
        "Success !",
        "Update customer role successful."
      );
    } else {
      getData();
      openNotification("error", "Failed !", "Failed to update customer role.");
    }
  };

  const updateCredit = async () => {
    if (!customerId) {
      openNotification(
        "error",
        "Failed !",
        `Failed to ${credit.credit_type.toLowerCase()} credit.`
      );
      return;
    }

    if (isNaN(credit.credit_value)) setIsAlertCredit(true);
    else {
      setIsLoadingConfirmCredit(true);

      let data = {
        customer_id: Number(customerId),
        amount: credit.credit_value,
      };

      let action = await apiCTMUpdateCreditByCustomerId(
        credit.credit_type.toLowerCase(),
        data
      );
      if (action && action.status) {
        getData();
        getDataTable();
        openNotification(
          "success",
          "Success !",
          `${credit.credit_type} credit successful.`
        );
        setVisibleCredit(false);
        setIsLoadingConfirmCredit(false);
      } else {
        openNotification(
          "error",
          "Failed !",
          `Failed to ${credit.credit_type.toLowerCase()} credit.`
        );
        setIsLoadingConfirmCredit(false);
      }
    }
  };

  const resetPassword = async () => {
    if (!newPassword) return;

    setIsLoadingConfirmPassword(true);

    let data = {
      customer_id: Number(customerId),
      password: newPassword,
    };

    let action = await apiCTMEditCustomerPasswordById(data);
    if (action && action.status) {
      getData();
      openNotification("success", "Success !", "Reset password successful.");
      setVisiblePassword(false);
      setIsLoadingConfirmPassword(false);
    } else {
      openNotification("error", "Failed !", "Failed to reset password.");
      setIsLoadingConfirmPassword(false);
    }
  };

  const refundData = async () => {
    if (!modalIdRefund) {
      openNotification("error", "Failed !", "Failed to refund.");
      return;
    }

    setIsLoadingConfirmRefund(true);

    let data = {
      customer_id: Number(customerId),
      history_id: modalIdRefund,
    };

    let action = await apiCTMRefundHistoryByCustomerId(data);
    if (action && action.status) {
      getData();
      getDataTable();
      openNotification("success", "Success !", "Refund successful.");
      setVisibleRefund(false);
      setIsLoadingConfirmRefund(false);
    } else {
      openNotification("error", "Failed !", "Failed to refund.");
      setIsLoadingConfirmRefund(false);
    }
  };

  const columns = [
    {
      title: "#",
      ellipsis: true,
      align: "center",
      width: "5%",
      render: (data, record, index) => (
        <div>{index + 1 + (currentPage - 1) * perPage}</div>
      ),
    },
    {
      title: "Date",
      ellipsis: true,
      align: "center",
      render: (data) => (
        <div>
          {data.date
            ? moment(data.date).add(543, "years").format("D MMM YYYY HH:mm:ss")
            : "-"}
        </div>
      ),
    },
    {
      title: "Site",
      ellipsis: true,
      render: (data) => <div>{data.site_name || "-"}</div>,
    },
    {
      title: "Machine",
      ellipsis: true,
      render: (data) => <div>{data.machine_name || "-"}</div>,
    },
    {
      title: "Amount",
      ellipsis: true,
      align: "center",
      render: (data) => <div>{numberWithCommas(data.amount || 0, 2)}</div>,
    },
    {
      title: "Action",
      ellipsis: true,
      align: "center",
      fixed: "right",
      render: (data) => (
        <Tooltip placement="top" title="Refund">
          <div
            className="f-c-c d-b-t-n-s b-c-o-i"
            style={{ backgroundColor: "#ED2228" }}
            onClick={(e) => {
              e.stopPropagation();
              setModalIdRefund(data.history_id);
              setVisibleRefund(true);
            }}
          >
            <i className="fas fa-coins"></i>
          </div>
        </Tooltip>
      ),
    },
  ];

  const toCSVFile = async () => {
    setWaitingForCSV(true);
    let data = await apiCTMGetCustomerHistoryById(true, "", "", customerId);
    let csvData = [];

    if (data && data.status) {
      (data.result.data_list || []).map((data, idx) => {
        let resultRow = {};
        resultRow["#"] = idx + 1;
        resultRow["Date"] = data.date || "-";
        resultRow["Site"] = data.site_name || "-";
        resultRow["Machine"] = data.machine_name || "-";
        resultRow["Amount"] = data.amount || 0;
        csvData.push(resultRow);
      });

      const toCSV = XLSX.utils.json_to_sheet(csvData);
      const wb = XLSX.utils.book_new();

      const fileName = `CUSTOMER_${customerId}_HISTORY`;
      XLSX.utils.book_append_sheet(wb, toCSV, fileName);
      XLSX.writeFile(wb, fileName + ".csv");

      setWaitingForCSV(false);
    }
  };

  return (
    <div>
      <div className="mg-bt-20 h-d-t-p">Customer - View</div>

      {(menuList.includes("CUSTOMERMAN") ||
        menuList.includes("CUSTOMERCREDIT")) && (
        <div
          className={`customer-view-header-grid customer-view-header-grid-${
            menuList.includes("CUSTOMERMAN") &&
            menuList.includes("CUSTOMERCREDIT")
          }`}
        >
          {menuList.includes("CUSTOMERMAN") && (
            <div>
              <Select
                style={{ width: "100%" }}
                placeholder="Role"
                value={(customer && customer.customer_role) || undefined}
                onChange={(value) => updateCustomerRole(value)}
              >
                <Option value="NORMAL">Normal</Option>
                <Option value="ADMIN">Admin</Option>
                <Option value="SALE">Sale</Option>
                <Option value="DEVELOP">Develop</Option>
                <Option value="OPERATION">Operation</Option>
              </Select>
            </div>
          )}
          {menuList.includes("CUSTOMERCREDIT") && (
            <div
              className="f-c-c d-b-t-n-s d-bg-c-t"
              onClick={() => {
                setCredit({ credit_type: "Deposit", credit_value: undefined });
                setIsAlertCredit(false);
                setVisibleCredit(true);
              }}
            >
              Credit
            </div>
          )}
        </div>
      )}

      <div className="ovf-sc">
        <div className="customer-view-container-grid">
          <div className="f-c-c customer-view-profile-image">
            <Avatar
              shape="square"
              src={
                (customer && customer.customer_img) ||
                "/assets/image/default/user_image.png"
              }
            />
          </div>
          <div className="customer-view-info-container">
            <div>
              <div className="customer-view-info">
                <div>Name</div>
                <div>{(customer && customer.customer_name) || "-"}</div>
                <div>Date of Birth</div>
                <div>
                  {customer && customer.customer_bday
                    ? moment(customer.customer_bday)
                        .add(543, "years")
                        .format("D MMM YYYY")
                    : "-"}
                </div>
                <div>Tel.</div>
                <div>{(customer && customer.customer_tel) || "-"}</div>
                <div>E-mail</div>
                <div>{(customer && customer.customer_email) || "-"}</div>
                <div>Gender</div>
                <div>{(customer && customer.customer_gender) || "-"}</div>
                <div>Credit</div>
                <div>{numberWithCommas(creditData || 0, 2)}</div>
                <div>Last Active</div>
                <div>
                  {customer && customer.last_active
                    ? moment(customer.last_active)
                        .add(543, "years")
                        .format("D MMM YYYY HH:mm:ss")
                    : "-"}
                </div>
              </div>

              <div className="customer-view-reset-pw">
                <div
                  className="f-c-c d-b-t-n-s d-bg-c-t"
                  onClick={() => {
                    setNewPassword("");
                    setVisiblePassword(true);
                  }}
                >
                  Reset Password
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="dash-chart-box-container">
        <div className="dash-space-between">
          <div className="h-d-t-g">
            <div className="f-c-c">
              <i
                className="bi bi-clock-history"
                style={{ lineHeight: "0" }}
              ></i>
            </div>
            <div className="f-s-c">History</div>
            <div
              className="f-c-c d-b-t-n-s d-bg-c-t h-32 table-export-btn-width table-export-btn-responsive"
              style={{ pointerEvents: waitingForCSV ? "none" : null }}
              onClick={() => toCSVFile()}
            >
              {waitingForCSV ? <LoadingOutlined /> : "Export CSV"}
            </div>
          </div>

          <div className="dash-table-container">
            <Table
              bordered
              size="small"
              loading={isLoadingTable}
              rowKey={(record) => record.history_id}
              dataSource={(tableData && tableData.data_list) || []}
              columns={columns}
              pagination={false}
              scroll={{ x: true }}
            />
          </div>

          <div className="pgnt-st">
            <Pagination
              size="small"
              current={currentPage}
              onChange={(page) => setCurrentPage(page)}
              total={tableData && tableData.max_total_rows}
              pageSize={perPage}
              showSizeChanger={false}
            />
          </div>
        </div>
      </div>

      <Modal
        title=""
        visible={visibleCredit}
        closable={false}
        footer={false}
        centered
      >
        <div className="customer-view-credit-grid">
          {creditChoices.map((choice, idx) => (
            <div key={idx} className="f-s-c antd-chb-fz">
              <Checkbox
                checked={credit.credit_type === choice.key}
                onChange={() =>
                  setCredit({ ...credit, credit_type: choice.key })
                }
              >
                {choice.name}
              </Checkbox>
            </div>
          ))}
        </div>

        <div className="mg-t-10">
          <InputNumber
            min={0}
            step={0.01}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            style={{
              width: "100%",
              borderColor: isAlertCredit ? "#EC1C24" : null,
            }}
            placeholder="จำนวนเงิน"
            value={credit.credit_value}
            onChange={(value) => {
              setIsAlertCredit(false);
              setCredit({
                ...credit,
                credit_value: Math.round(value * 100) / 100 || 0,
              });
            }}
          />
        </div>

        <div className="m-b-y-n-g">
          <div
            className="f-c-c d-b-t-n-s b-c-o-i"
            style={{ backgroundColor: "#cccccc" }}
            onClick={() => setVisibleCredit(false)}
          >
            Cancel
          </div>
          <div
            className="f-c-c d-b-t-n-s b-c-o-i"
            style={{
              backgroundColor: "#3f4d67",
              pointerEvents: isLoadingConfirmCredit ? "none" : null,
            }}
            onClick={() => updateCredit()}
          >
            {isLoadingConfirmCredit ? <LoadingOutlined /> : "Confirm"}
          </div>
        </div>
      </Modal>

      <Modal
        title=""
        visible={visiblePassword}
        closable={false}
        footer={false}
        centered
      >
        <div className="m-t-t-q">Set new password</div>
        <div className="mg-t-10">
          <Input.Password
            placeholder="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </div>
        <div className="m-b-y-n-g">
          <div
            className="f-c-c d-b-t-n-s b-c-o-i"
            style={{ backgroundColor: "#cccccc" }}
            onClick={() => setVisiblePassword(false)}
          >
            Cancel
          </div>
          <div
            className="f-c-c d-b-t-n-s b-c-o-i"
            style={{
              backgroundColor: "#3f4d67",
              pointerEvents: isLoadingConfirmPassword ? "none" : null,
            }}
            onClick={() => resetPassword()}
          >
            {isLoadingConfirmPassword ? <LoadingOutlined /> : "Confirm"}
          </div>
        </div>
      </Modal>

      <Modal
        title=""
        visible={visibleRefund}
        closable={false}
        footer={false}
        centered
      >
        <div className="m-t-i-c" style={{ color: "#FFCB05" }}>
          <i className="bi bi-exclamation-triangle-fill"></i>
        </div>
        <div className="m-t-t-q">Do you want to refund ?</div>
        <div className="m-b-y-n-g">
          <div
            className="f-c-c d-b-t-n-s b-c-o-i"
            style={{ backgroundColor: "#cccccc" }}
            onClick={() => setVisibleRefund(false)}
          >
            Cancel
          </div>
          <div
            className="f-c-c d-b-t-n-s b-c-o-i"
            style={{
              backgroundColor: "#3f4d67",
              pointerEvents: isLoadingConfirmRefund ? "none" : null,
            }}
            onClick={() => refundData()}
          >
            {isLoadingConfirmRefund ? <LoadingOutlined /> : "Confirm"}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CustomerViewV2;
