import React, { useState, useEffect } from 'react'
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { Input, InputNumber, Select, DatePicker, Checkbox, Modal } from 'antd';
import './Broadcast.css'
import { LoadingOutlined } from '@ant-design/icons';

import moment from 'moment';
import 'moment/locale/th'

import { numberWithCommas, openNotification, getSearchParams } from '../../utils/function'
import { apiBRCGetBroadcastPotential, apiBRCAddBroadcast } from '../../services/apis/broadcast'
import { apiSGetSite } from '../../services/apis/site'

const { Option } = Select;

const BroadcastAddEdit = () => {
  const { broadcastId } = useParams();
  const location = useLocation();
  const history = useHistory();

  const params = new URLSearchParams(location.search)
  const p = params.get('p')
  const ttl = params.get('ttl')
  const start = params.get('start')
  const end = params.get('end')
  const sw = params.get('sw')

  const [isLoading, setIsLoading] = useState(false)
  const [isModalPreview, setIsModalPreview] = useState(false)

  const [broadcast, setBroadcast] = useState(undefined)
  const [sites, setSites] = useState([])
  const [potential, setPotential] = useState(0)

  const [isAlertSite, setIsAlertSite] = useState(false)
  const [isAlertTitle, setIsAlertTitle] = useState(false)
  const [isAlertBody, setIsAlertBody] = useState(false)

  useEffect(() => {
    if (broadcastId) getData()
    else {
      setBroadcast(undefined)
      setIsAlertSite(false)
      setIsAlertTitle(false)
      setIsAlertBody(false)
    }
  }, [broadcastId])

  useEffect(() => {
    if (!broadcast) setBroadcast({
      ...broadcast,
      boardcast_date: moment(),
      age_undefined: false
    })
  }, [broadcast])

  const getData = async () => {
    // let data = await apiCSPGetInquiryById(broadcastId)
    // if (data && data.status) setBroadcast(data.result)
  }

  useEffect(() => {
    const getStaticDropdown = async () => {
      let site = await apiSGetSite()
      if (site && site.status) setSites(site.result || [])
    }
    getStaticDropdown()
  }, [])

  useEffect(() => {
    if (!broadcast) return
    console.log('change');
    const getStaticPotential = async () => {
      let data = { ...broadcast }
      data.boardcast_date = moment(broadcast.boardcast_date).format('YYYY-MM-DD HH:mm:ss')

      let potential = await apiBRCGetBroadcastPotential(data)
      if (potential && potential.status) setPotential(potential.result)
    }
    getStaticPotential()
  }, [broadcast])

  const addEditData = async () => {
    if (
      !(broadcast && broadcast.site_list && broadcast.site_list.length) ||
      !(broadcast && broadcast.title) ||
      !(broadcast && broadcast.body)

    ) {
      if (!(broadcast && broadcast.site_list && broadcast.site_list.length)) setIsAlertSite(true)
      if (!(broadcast && broadcast.title)) setIsAlertTitle(true)
      if (!(broadcast && broadcast.body)) setIsAlertBody(true)

    } else {
      setIsLoading(true)

      let data = { ...broadcast }
      data.boardcast_date = moment(broadcast.boardcast_date).format('YYYY-MM-DD HH:mm:ss')
      data.potential = potential

      let newData = broadcastId ? null : await apiBRCAddBroadcast(data)
      if (newData && newData.status) {
        setIsLoading(false)
        openNotification('success', 'Success !', `${broadcastId ? 'Edit' : 'Add new'} broadcast successful.`)
        setTimeout(() => history.push(`/broadcast/list${getSearchParams(['p', 'ttl', 'start', 'end', 'sw'], [p, ttl, start, end, sw])}`), 1000);
      } else {
        setIsLoading(false)
        openNotification('error', 'Failed !', `Failed to ${broadcastId ? 'edit' : 'add new'} broadcast.`)
      }
    }
  }

  const handleCancelPreview = () => {
    setIsModalPreview(false)
}

  const showModalPreview = () => {
      setIsModalPreview(true)
  }

  return (
    <div>
      <div className="mg-bt-20 h-d-t-p">Broadcast - {broadcastId ? 'Edit' : 'Add'}</div>

      <div className="t-m-c-i-p-g">
        <div className="f-s-c">เวลาประกาศ</div>
        <div>
          <DatePicker style={{ width: '100%' }}
            placeholder="เวลาประกาศ"
            value={broadcast && broadcast.boardcast_date && moment(broadcast.boardcast_date)}
            onChange={date => {
              console.log(date)
              setBroadcast({ ...broadcast, boardcast_date: date })
            }}
            showTime
            format={date => moment(date).add(543, 'years').format('D MMM YYYY HH:mm:ss')}
            allowClear={false}
          />
        </div>

        <div className="f-s-c">เพศ</div>
        <div>
          <Select style={{ width: '100%' }}
            placeholder="เพศ"
            mode="multiple"
            showArrow
            value={broadcast && broadcast.gender_list || []}
            onChange={value => setBroadcast({ ...broadcast, gender_list: value })}
          >
            <Option value="male">ชาย</Option>
            <Option value="female">หญิง</Option>
            <Option value="">ไม่ระบุ</Option>
          </Select>
        </div>

        <div className="f-s-c">อายุ</div>
        <div className="broadcast-age-grid">
          <div className="f-s-c">
            <Checkbox
              checked={broadcast && broadcast.age_undefined}
              onChange={e => setBroadcast({
                ...broadcast,
                age_undefined: e.target.checked,
                age_start: undefined,
                age_end: undefined,
              })}
            >ไม่ระบุ</Checkbox>
          </div>
          <div>
            <InputNumber min={0}
              step={1}
              formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
              style={{ width: '100%' }}
              placeholder="อายุ"
              value={broadcast && broadcast.age_start}
              onChange={value => setBroadcast({ ...broadcast, age_start: Math.round(value) || 0 })}
              // disabled={broadcast && broadcast.age_undefined}
            />
          </div>
          <div className="f-c-c">ถึง</div>
          <div>
            <InputNumber min={0}
              step={1}
              formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
              style={{ width: '100%' }}
              placeholder="อายุ"
              value={broadcast && broadcast.age_end}
              onChange={value => setBroadcast({ ...broadcast, age_end: Math.round(value) || 0 })}
              // disabled={broadcast && broadcast.age_undefined}
            />
          </div>
        </div>

        <div className="f-s-c">อาชีพ</div>
        <div>
          <Select style={{ width: '100%' }}
            placeholder="อาชีพ"
            mode="multiple"
            showArrow
            value={broadcast && broadcast.career_list || undefined}
            onChange={value => setBroadcast({ ...broadcast, career_list: value })}
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            <Option value="รับราชการ/รัฐวิสาหกิจ">รับราชการ / รัฐวิสาหกิจ</Option>
            <Option value="รับจ้างทั่วไป">รับจ้างทั่วไป</Option>
            <Option value="นักเรียน นิสิต นักศึกษา">นักเรียน / นิสิต / นักศึกษา</Option>
            <Option value="พนักงานเอกชน">พนักงานเอกชน</Option>
            <Option value="อาชีพอิสระ / ค้าขาย">อาชีพอิสระ / ค้าขาย</Option>
            <Option value="อื่นๆ">อื่นๆ</Option>
            <Option value="">ไม่ระบุ</Option>
          </Select>
        </div>

        <div className="f-s-c">สถานที่เคยใช้</div>
        <div className={isAlertSite && 'antd-sl-al-bd' || ''}>
          <Select style={{ width: '100%' }}
            placeholder="สถานที่เคยใช้"
            mode="multiple"
            showArrow
            value={broadcast && broadcast.site_list}
            onChange={value => {
              setIsAlertSite(false)
              setBroadcast({ ...broadcast, site_list: value })
            }}
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {sites.map((site, idx) => <Option key={idx} value={site.site_id}>{site.site_name}</Option>)}
          </Select>
        </div>

        <div className="f-s-c">แอพ</div>
        <div>
          <Select style={{ width: '100%' }}
            placeholder="แอพ"
            mode="multiple"
            showArrow
            value={broadcast && broadcast.os_list || undefined}
            onChange={value => setBroadcast({ ...broadcast, os_list: value })}
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            <Option value="ios">iOS</Option>
            <Option value="android">Android</Option>
            <Option value="sansiri">Sansiri</Option>
            <Option value="linux">Linux</Option>
            <Option value="">ไม่ระบุ</Option>
          </Select>
        </div>

        <div className="f-s-c">Title</div>
        <div>
          <Input
            style={{ borderColor: isAlertTitle ? '#EC1C24' : null }}
            placeholder="Title"
            value={broadcast && broadcast.title || ''}
            onChange={e => {
              setIsAlertTitle(false)
              setBroadcast({ ...broadcast, title: e.target.value })
            }}
          />
        </div>

        <div className="f-s-c">Body</div>
        <div>
          <Input
            style={{ borderColor: isAlertBody ? '#EC1C24' : null }}
            placeholder="Body"
            value={broadcast && broadcast.body || ''}
            onChange={e => {
              setIsAlertBody(false)
              setBroadcast({ ...broadcast, body: e.target.value })
            }}
          />
        </div>

        <div className="f-s-c">Link</div>
        <div>
          <Input
            placeholder="https://www.google.com"
            value={broadcast && broadcast.link || ''}
            onChange={e => setBroadcast({ ...broadcast, link: e.target.value })}
          />
        </div>

        <div className="f-s-c">Potential Count</div>
        <div>{numberWithCommas(potential || 0, 0)}</div>
      </div>

      <div className="f-c-c s-e-b">
      <div className="f-c-c d-b-t-n-s d-bg-c-t"
          style={{ width: '100px', marginRight: "15px" }}
          onClick={() => showModalPreview()}
        >Preview</div>

        <div className="f-c-c d-b-t-n-s d-bg-c-t"
          style={{ width: '100px', pointerEvents: isLoading ? 'none' : null }}
          onClick={() => addEditData()}
        >{isLoading ? <LoadingOutlined /> : 'Submit'}</div>
      </div>


      <Modal title="Preview Boardcast" visible={isModalPreview} footer={false} onCancel={handleCancelPreview}>
        <div className="bx-show">
          <div className="logo-board">
            <img alt="logo" className="logo-icon" src="/assets/image/logo/logo_color_square.png" />
          </div>
          <div className="detail-board">
          <p className="title-board">{broadcast && broadcast.title}</p>
          <p className="body-board">{broadcast && broadcast.body}</p>
          </div>
          
        </div>
      </Modal>

    </div>
  )
}

export default BroadcastAddEdit
