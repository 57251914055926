import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

import PrivateRoute from "./PrivateRoute";

import DashboardSuperAdmin from "../pages/Dashboard/SuperAdmin/Dashboard";
import DashboardCustomer from "../pages/Dashboard/SuperAdmin/Dashboard-customer";

import DashboardBranchAdmin from "../pages/Dashboard/BranchAdmin/Dashboard";
import DashboardNewBranchAdmin from "../pages/Dashboard/BranchAdmin/DashboardNew";
import DashboardSuperAdminV2 from "../pages/Dashboard/SuperAdmin/Dashboard-v2";
import DashboardBranchAdminV2 from "../pages/Dashboard/BranchAdmin/Dashboard-v2";

import UploadSlip from "../pages/UploadSlip/UploadSlip";

import TransactionList from "../pages/Transaction/TransactionList";
import TransactionListV2 from "../pages/Transaction/TransactionList-v2";
import TransactionCenterList from "../pages/Transaction/TransactionCenterList";
import TransactionListPG from "../pages/Transaction/TransactionCenter-pg";
import TransactionReconcile from "../pages/Transaction/TransactionReconcile";
import Transactiontable from "../pages/Transaction/tabletransatoncenter";

import Payment from "../pages/Payment/Payment";

import MachineListPage from "../pages/Machine/MachineListPage";
import MachineListPageV2 from "../pages/Machine/MachineListPage-v2";
import MachineListWarehousev2 from "../pages/Machine/MachineListWarehouse-v2";
import MachineAllSetSite from "../pages/Machine/MachineAll-SetSite";
import MachineAddEditPage from "../pages/Machine/MachineAddEditPage";
import MachineAddEditPageV2 from "../pages/Machine/MachineAddEditPage-v2";
import MachineSetconfig from "../pages/Machine/MachineSetconfig";
import MachineHistoryPage from "../pages/Machine/MachineHistoryPage";
import MachineHistoryPageV2 from "../pages/Machine/MachineHistoryPage-v2";
import MachineTypePage from "../pages/Machine/MachineTypePage";
import MachineTypeAddEditPage from "../pages/Machine/MachineTypeAddEditPage";
import MachineSubTypePage from "../pages/Machine/MachineSubTypePage";
import MachineSubTypeAddEditPage from "../pages/Machine/MachineSubTypeAddEditPage";
import MachineControllerPage from "../pages/Machine/MachineControllerPage";
import MachineControllerPageV2 from "../pages/Machine/MachineControllerPage-v2";
import MachineControllerAddEditPage from "../pages/Machine/MachineControllerAddEditPage";
import MachineLgSettings from "../pages/Machine/MachineLgSettings";

import CustomerList from "../pages/Customer/CustomerList";
import CustomerListV2 from "../pages/Customer/CustomerList-v2";
import CustomerAddEdit from "../pages/Customer/CustomerAddEdit";
import CustomerAddEditV2 from "../pages/Customer/CustomerAddEdit-v2";
import CustomerView from "../pages/Customer/CustomerView";
import CustomerViewV2 from "../pages/Customer/CustomerView-v2";

import SiteManagementList from "../pages/SiteManagement/SiteManagementList";
import SiteManagementListV2 from "../pages/SiteManagement/SiteManagementList-v2";
import SiteManagementAddEdit from "../pages/SiteManagement/SiteManagementAddEdit";
import SiteManagementAddEditV2 from "../pages/SiteManagement/SiteManagementAddEdit-v2";

import SiteManagementQR from "../pages/SiteManagement/SiteManagementQR";

import AdminList from "../pages/AdminManagement/AdminList";
import AdminListV2 from "../pages/AdminManagement/AdminList-v2";
import SupAdminList from "../pages/AdminManagement/SupAdminList";
import AdminAddEdit from "../pages/AdminManagement/AdminAddEdit";
import AdminAddEditV2 from "../pages/AdminManagement/AdminAddEdit-v2";
import SupAdminAddEdit from "../pages/AdminManagement/SupAdminAddEdit";

import HomeBanner from "../pages/Banner/HomeBanner";
import SiteBanner from "../pages/Banner/SiteBanner";
import HomeBannerAddEdit from "../pages/Banner/BannerAddEdit";

import News from "../pages/News/News";
import NewsAddEdit from "../pages/News/NewsAddEdit";

import CoinHistory from "../pages/CoinCollection/CoinHistory";
import CoinAddSiteCheckOut from "../pages/CoinCollection/CoinAddSiteCheckOut";
import CoinCountAddEdit from "../pages/CoinCollection/CoinCountAddEdit";
import CoinBox from "../pages/CoinCollection/CoinBox";
import CoinBoxAddEdit from "../pages/CoinCollection/CoinBoxAddEdit";
import CoinPush from "../pages/CoinCollection/CoinPush";
import CoinReconcile from "../pages/CoinCollection/CoinReconcile";
import CoinCountReconcile from "../pages/CoinCollection/CoinCountReconcile";

import Support from "../pages/Maintenance/Support";
import SupportOffline from "../pages/Maintenance/SupportOffline";
import Ticket from "../pages/Maintenance/Ticket";
import TicketMaintenanceAddEdit from "../pages/Maintenance/TicketMaintenanceAddEdit";
import History from "../pages/Maintenance/History";
import HistoryView from "../pages/Maintenance/HistoryView";

import MaintenanceList from "../pages/CallSupport/MaintenanceList";
import MaintenanceAddEdit from "../pages/CallSupport/MaintenanceAddEdit";
import InquiryList from "../pages/CallSupport/InquiryList";
import InquiryAddEdit from "../pages/CallSupport/InquiryAddEdit";

import BroadcastList from "../pages/Broadcast/BroadcastList";
import BroadcastAddEdit from "../pages/Broadcast/BroadcastAddEdit";
import BroadcastView from "../pages/Broadcast/BroadcastView";

import SitePromotionListPage from "../pages/SitePromotion/SitePromotionListPage";
import SitePromoDiscountPage from "../pages/SitePromotion/SitePromotionDiscountPage";
import SitePromotionFreePage from "../pages/SitePromotion/SitePromotionFreePage";
import SitePromotionTimeCountPage from "../pages/SitePromotion/SitePromotionTimeCountPage";
import SitePromotionPointPage from "../pages/SitePromotion/SitePromotionPointPage";

// Site Promotion V2
import SitePromotionListPageV2 from "../pages/SitePromotion/SitePromotionListPage-v2";
import SitePromotionDiscountPageV2 from "../pages/SitePromotion/SitePromotionDiscountPage-v2";
import SitePromotionFreePageV2 from "../pages/SitePromotion/SitePromotionFreePage-v2";
import SitePromotionTimeCountPageV2 from "../pages/SitePromotion/SitePromotionTimeCountPage-v2";
import SitePromotionPointPageV2 from "../pages/SitePromotion/SitePromotionPointPage-v2";

import PromotionTopupPage from "../pages/Promotion/PromotionTopupPage";
import PromotionTimeCountPage from "../pages/Promotion/PromotionTimeCountPage";
import PromotionReward from "../pages/Promotion/PromotionRewardPage";
import PromotionRewardAddEditPage from "../pages/Promotion/PromotionRewardAddEditPage";
import PromotionPointPage from "../pages/Promotion/PromotionPointPage";
import PromotionFriendPage from "../pages/Promotion/PromotionFriendPage";
import PromotionPromoPage from "../pages/Promotion/PromotionPromoPage";
import PromotionPromoAddEditPage from "../pages/Promotion/PromotionPromoAddEditPage";
import PromotionRedeemPage from "../pages/Promotion/PromotionRedeemPage";
import SitePromotionCollect from "../pages/SitePromotion/SitePromotionCollect";
import SitePromotionCollectListPage from "../pages/SitePromotion/SitePromotionCollectListPage";
import PromotionCollectmanament from "../pages/SitePromotion/PromotionCollectmanament";

import CreatePromocode from "../pages/SitePromotion/CreatePromocode";
import ListPromocode from "../pages/SitePromotion/listPromocode";

import WasherList from "../pages/Washer/WasherList";
import CouponList from "../pages/CouponWasher/CouponList";
import CouponAddEdit from "../pages/CouponWasher/CouponAddEdit";

import TaskList from "../pages/Washer/Task";
import BagList from "../pages/Washer/Bag";
import NewsWasher from "../pages/Washer/News";
import HomeBannerWasher from "../pages/Washer/Banner";
import NewsAddEditWasher from "../pages/Washer/NewsAddEdit";
import BannerAddEditWasher from "../pages/Washer/BannerAddEdit";
import Transaction from "../pages/Washer/TransactionList";


import Report from "../pages/Report/Report";
import ReportNew from "../pages/Report/ReportNew";
import ReportV2 from "../pages/Report/Report-v2";

import ReportSCB from "../pages/Payment-scb/Payment";

import ReportPromotion from "../pages/Report/Report-Promotion";
import ReportCollect from "../pages/Report/Report-Collect";
const Router = () => {
  const data = useSelector((state) => state.data);

  const menuList = (localStorage.getItem("list") || "").split(",") || "";
  const role = (localStorage.getItem("user_role") || "").toUpperCase();
  const site = localStorage.getItem("site_id") || "";

  const getRedirect = () => {
    if (menuList.includes("DASHBOARD") || role === "BRANCHADMIN")
      switch (role) {
        case "BRANCHADMIN":
          return `/dashboard/${site}`;
        case "SUPERADMIN":
          return "/dashboard";
        default:
          return `/dashboard/${site}`; // return lowest
      }
    else if (menuList.includes("TRANSACTION")) return "/transaction/list";
    else if (menuList.includes("PAYMENT")) return "/payment";
    else if (
      menuList.includes("MACHINEVIEW") ||
      menuList.includes("MACHINEMAN")
    )
      return "/machine/list";
    else if (
      menuList.includes("CUSTOMERVIEW") ||
      menuList.includes("CUSTOMERMAN")
    )
      return "/customer/list";
    else if (menuList.includes("SITEVIEW") || menuList.includes("SITEMAN"))
      return "/site-management/list";
    else if (menuList.includes("ADMINVIEW") || menuList.includes("ADMINMAN"))
      return "/admin-management/list-admin";
    else if (menuList.includes("BANNER")) return "/banner/home";
    else if (menuList.includes("ANNOUNCE")) return "/news/list";
    else if (menuList.includes("COINCOLLECT")) return "/coin/history";
    else if (menuList.includes("MANTENANCE")) return "/maintenance/support";
    else if (
      menuList.includes("SUPERADMINVIEW") ||
      menuList.includes("SUPERADMINMAN")
    )
      return "/admin-management/list-superadmin";
    else if (menuList.includes("SITEPROMO")) return "/site-promotion";
    else if (menuList.includes("ALLPROMO")) return "/promotion/topup";
    else if (menuList.includes("WASHER")) return "/washer/user";
    else if (menuList.includes("EXPORT"))
      switch (role) {
        case "BRANCHADMIN":
          return "/report";
        case "SUPERADMIN":
          return "/site-management/list/report/:siteId";
        default:
          return "/report"; // return lowest
      }
    else return `/dashboard/${site}`; // return lowest
  };

  return (
    <div
      className="ctn-wo-nvgt"
      style={{ marginLeft: data.hideNav ? "60px" : null }}
    >
      <Switch>
        <Route exact path="/">
          <Redirect to={getRedirect()} />
        </Route>

        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/dashboard"
          component={DashboardSuperAdmin}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/dashboardCustomer"
          component={DashboardCustomer}
        />
        
        <PrivateRoute
          exact
          role={["BRANCHADMIN", "SUPERADMIN"]}
          path="/dashboard/:siteId"
          component={DashboardBranchAdmin}
        />
        <PrivateRoute
          exact
          role={["BRANCHADMIN", "SUPERADMIN"]}
          path="/dashboard-new/:siteId"
          component={DashboardNewBranchAdmin}
        />

        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/dashboards/v2"
          component={DashboardSuperAdminV2}
        />
        <PrivateRoute
          exact
          role={["BRANCHADMIN", "SUPERADMIN"]}
          path="/dashboard/v2/:siteId"
          component={DashboardBranchAdminV2}
        />

        {/* Deverlopment Screen */}
        <PrivateRoute
          exact
          role={["BRANCHADMIN", "SUPERADMIN"]}
          path="/slip/:siteId"
          component={UploadSlip}
        />

        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/transaction/list"
          component={TransactionList}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/transaction/list/v2"
          component={TransactionListV2}
        />
        <PrivateRoute
        exact
        role={["SUPERADMIN"]}
        path="/transaction/TransactionCenter/list"
        component={TransactionCenterList}
      />
       <PrivateRoute
        exact
        role={["SUPERADMIN"]}
        path="/transaction/TransactionCenter-pg/list"
        component={TransactionListPG}
      />
      
      <PrivateRoute
        exact
        role={["SUPERADMIN"]}
        path="/transaction/checktransaton/list"
        component={Transactiontable}
      />

        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/transaction/reconcile"
          component={TransactionReconcile}
        />

        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/payment"
          component={Payment}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/reportSCB"
          component={ReportSCB}
        />

        <PrivateRoute
          exact
          role={["BRANCHADMIN"]}
          path="/report"
          component={Report}
        />
        <PrivateRoute
          exact
          role={["BRANCHADMIN"]}
          path="/ReportPromotion"
          component={ReportPromotion}
        />
         <PrivateRoute
          exact
          role={["BRANCHADMIN"]}
          path="/ReportCollect"
          component={ReportCollect}
        />
        
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/site-management/list/report/:siteId"
          component={Report}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/site-management/list/report-new/:siteId"
          component={ReportNew}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/site-management/list/report/v2/:siteId"
          component={ReportV2}
        />
        <PrivateRoute
          exact
          role={["BRANCHADMIN", "SUPERADMIN"]}
          path="/machine/list"
          component={MachineListPage}
        />
        <PrivateRoute
          exact
          role={["BRANCHADMIN", "SUPERADMIN"]}
          path="/machine/list/v2"
          component={MachineListPageV2}
        />
        <PrivateRoute
          exact
          role={["BRANCHADMIN", "SUPERADMIN"]}
          path="/machine/list/MachineListWarehousev2"
          component={MachineListWarehousev2}
        />
        
        <PrivateRoute
          exact
          role={["BRANCHADMIN", "SUPERADMIN"]}
          path="/machine/All"
          component={MachineAllSetSite}
        />
        
        <PrivateRoute
          exact
          role={["BRANCHADMIN", "SUPERADMIN"]}
          path="/machine/list/history/:machineId"
          component={MachineHistoryPage}
        />
        <PrivateRoute
          exact
          role={["BRANCHADMIN", "SUPERADMIN"]}
          path="/machine/list/history/v2/:machineId"
          component={MachineHistoryPageV2}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/machine/add"
          component={MachineAddEditPage}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/machine/list/edit/:machineId"
          component={MachineAddEditPage}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/machine/add/v2"
          component={MachineAddEditPageV2}
        />
         <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/machineConfig/edit/v2:machineId"
          component={MachineSetconfig}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/machine/list/edit/v2/:machineId"
          component={MachineAddEditPageV2}
        />

        <PrivateRoute
          exact
          role={["BRANCHADMIN", "SUPERADMIN"]}
          path="/machine/list/edit/v2/:machineId/lg-settings/:lgDeviceId"
          component={MachineLgSettings} //
        />

        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/machine/type"
          component={MachineTypePage}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/machine/type/add"
          component={MachineTypeAddEditPage}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/machine/type/edit/:typeId"
          component={MachineTypeAddEditPage}
        />

        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/machine/type/:typeId/sub-type"
          component={MachineSubTypePage}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/machine/type/:typeId/sub-type/add"
          component={MachineSubTypeAddEditPage}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/machine/type/:typeId/sub-type/edit/:subTypeId"
          component={MachineSubTypeAddEditPage}
        />

        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/machine/controller"
          component={MachineControllerPage}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/machine/controller/v2"
          component={MachineControllerPageV2}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/machine/controller/add"
          component={MachineControllerAddEditPage}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/machine/controller/edit/:controllerId"
          component={MachineControllerAddEditPage}
        />

        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/customer/list"
          component={CustomerList}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/customer/list/v2"
          component={CustomerListV2}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/customer/list/:customerId"
          component={CustomerView}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/customer/list/v2/:customerId"
          component={CustomerViewV2}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/customer/add"
          component={CustomerAddEdit}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/customer/add/v2"
          component={CustomerAddEditV2}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/customer/list/edit/:customerId"
          component={CustomerAddEdit}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/customer/list/edit/v2/:customerId"
          component={CustomerAddEditV2}
        />

        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/site-management/list"
          component={SiteManagementList}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/site-management/list/v2"
          component={SiteManagementListV2}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/site-management/list/gen-qr/:siteId"
          component={SiteManagementQR}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/site-management/add"
          component={SiteManagementAddEdit}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/site-management/add/v2"
          component={SiteManagementAddEditV2}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/site-management/list/edit/:siteId"
          component={SiteManagementAddEdit}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/site-management/v2/list/edit/:siteId"
          component={SiteManagementAddEditV2}
        />

        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/admin-management/list-admin"
          component={AdminList}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/admin-management/list-admin/:siteId"
          component={AdminList}
        />
         <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/admin-management/list/v2"
          component={AdminListV2}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/admin-management/list/v2/:siteId"
          component={AdminListV2}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/admin-management/list-superadmin"
          component={SupAdminList}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/admin-management/add-admin"
          component={AdminAddEdit}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/admin-management/add-admin/:siteId"
          component={AdminAddEdit}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/admin-management/add/v2"
          component={AdminAddEditV2}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/admin-management/add/v2/:siteId"
          component={AdminAddEditV2}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/admin-management/add-superadmin"
          component={SupAdminAddEdit}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/admin-management/list-admin/edit/:adminId"
          component={AdminAddEdit}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/admin-management/list-admin/edit-v2/:adminId"
          component={AdminAddEditV2}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/admin-management/list-superadmin/edit/:adminId"
          component={SupAdminAddEdit}
        />

        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/banner/home"
          component={HomeBanner}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/banner/site"
          component={SiteBanner}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/banner/:bannerType/add"
          component={HomeBannerAddEdit}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/banner/:bannerType/edit/:bannerId"
          component={HomeBannerAddEdit}
        />

        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/news/list"
          component={News}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/news/add"
          component={NewsAddEdit}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/news/list/edit/:newsId"
          component={NewsAddEdit}
        />

        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/coin/history"
          component={CoinHistory}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/coin/reconcile"
          component={CoinReconcile}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/coin/history/count"
          component={CoinCountAddEdit}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/coin/history/:coinType"
          component={CoinAddSiteCheckOut}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/coin/history/edit/:coinId"
          component={CoinCountAddEdit}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/coin/reconcile/count"
          component={CoinCountReconcile}
        />

        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/coin/box"
          component={CoinBox}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/coin/box/edit/:coinId"
          component={CoinBoxAddEdit}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/coin/box/add"
          component={CoinBoxAddEdit}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/coin/add"
          component={CoinPush}
        />

        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/maintenance/support"
          component={Support}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/maintenance/support/offline"
          component={SupportOffline}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/maintenance/ticket"
          component={Ticket}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/maintenance/create-ticket"
          component={TicketMaintenanceAddEdit}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/maintenance/ticket/update/:maintenanceId"
          component={TicketMaintenanceAddEdit}
        />
        {/* <PrivateRoute exact role={['SUPERADMIN']} path="/maintenance/ticket/maintenance/:maintenanceId" component={TicketMaintenanceAddEdit} /> */}
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/maintenance/history"
          component={History}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/maintenance/history/view/:maintenanceId"
          component={HistoryView}
        />

        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/call-support/list-maintenance"
          component={MaintenanceList}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/call-support/add-maintenance"
          component={MaintenanceAddEdit}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/call-support/list-maintenance/edit-maintenance/:callMaintenanceId"
          component={MaintenanceAddEdit}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/call-support/list-inquiry"
          component={InquiryList}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/call-support/add-inquiry"
          component={InquiryAddEdit}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/call-support/list-inquiry/edit-inquiry/:callMaintenanceId"
          component={InquiryAddEdit}
        />

        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/broadcast/list"
          component={BroadcastList}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/broadcast/add"
          component={BroadcastAddEdit}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/broadcast/list/:broadcastId"
          component={BroadcastView}
        />

        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/site-promotion/list"
          component={SitePromotionListPage}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/site-promotion/discount"
          component={SitePromoDiscountPage}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/site-promotion/free"
          component={SitePromotionFreePage}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/site-promotion/time-count"
          component={SitePromotionTimeCountPage}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/site-promotion/point"
          component={SitePromotionPointPage}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/site-promotion/Collect"
          component={SitePromotionCollect}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/site-promotion/ListCollect"
          component={SitePromotionCollectListPage}
        />
         <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/site-promotion/CreatePromocode"
          component={CreatePromocode}
        />
       <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/site-promotion/ListPromocode"
          component={ListPromocode}
        />
       
         <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/site-promotion/manamentCollect"
          component={PromotionCollectmanament}
        />

        {/* Site Promotion V2 */}

        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/site-promotion/list/v2"
          component={SitePromotionListPageV2}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/site-promotion/discount/v2"
          component={SitePromotionDiscountPageV2}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/site-promotion/free/v2"
          component={SitePromotionFreePageV2}
        />
        {/* <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/site-promotion/time-count/v2"
          component={SitePromotionTimeCountPageV2}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/site-promotion/point/v2"
          component={SitePromotionPointPageV2}
        /> */}

        {/*  */}

        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/promotion/topup"
          component={PromotionTopupPage}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/promotion/time-count"
          component={PromotionTimeCountPage}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/promotion/reward"
          component={PromotionReward}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/promotion/reward/add"
          component={PromotionRewardAddEditPage}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/promotion/reward/edit/:promotionId"
          component={PromotionRewardAddEditPage}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/promotion/point"
          component={PromotionPointPage}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/promotion/friend"
          component={PromotionFriendPage}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/promotion/promotion-code"
          component={PromotionPromoPage}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/promotion/promotion-code/add"
          component={PromotionPromoAddEditPage}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/promotion/promotion-code/edit/:promotionId"
          component={PromotionPromoAddEditPage}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/promotion/redeem"
          component={PromotionRedeemPage}
        />
         



        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/washer/user"
          component={WasherList}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/washer/coupon"
          component={CouponList}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/washer/coupon/add"
          component={CouponAddEdit}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/washer/bag"
          component={BagList}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/washer/task"
          component={TaskList}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/washer/banner"
          component={HomeBannerWasher}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/washer/transaction"
          component={Transaction}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/washer/news"
          component={NewsWasher}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/washer/banner/:bannerType/add"
          component={BannerAddEditWasher}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/washer/banner/:bannerType/edit/:bannerId"
          component={BannerAddEditWasher}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/washer/news/add"
          component={NewsAddEditWasher}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/washer/news/list/edit/:newsId"
          component={NewsAddEditWasher}
        />

        {/* <Redirect to="/" /> */}
      </Switch>
    </div>
  );
};

export default Router;
