import axios from '../../config/axios';

const apiCGetCoinBoxCode = async () => {
  try {
    const result = await axios.get('/coin/box/dropdown')
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiCGetCoinHistory = async (get_all, start_date, end_date, cur_page, per_page, keyword) => {
  try {
    const result = await axios.get(`/coin/collect/list?get_all=${get_all}&start_date=${start_date}&end_date=${end_date}&cur_page=${cur_page}&per_page=${per_page}&keyword=${keyword}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiCGetCoinHistoryNS = async (get_all, start_date, end_date, cur_page, per_page, keyword) => {
  try {
    const result = await axios.get(`/coin/collect/list_ns?get_all=${get_all}&start_date=${start_date}&end_date=${end_date}&cur_page=${cur_page}&per_page=${per_page}&keyword=${keyword}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiCGetCoinBox = async (get_all, cur_page, per_page, keyword) => {
  try {
    const result = await axios.get(`/coin/box/list?get_all=${get_all}&cur_page=${cur_page}&per_page=${per_page}&keyword=${keyword}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiCGetMachineBySiteId = async (site_id) => {
  try {
    const result = await axios.get(`/coin/machine/dropdown?site_id=${site_id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiCAddSite = async (data) => {
  try {
    const result = await axios.post('/coin/collect/add_site', data)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiCCheckOut = async (data) => {
  try {
    const result = await axios.put('/coin/collect/checkout', data)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiCNGetCoinById = async (coin_collect_id) => {
  try {
    const result = await axios.get(`/coin/collect?coin_collect_id=${coin_collect_id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiCNGetCoinByBoxCode = async (coin_box_code) => {
  try {
    const result = await axios.get(`/coin/collect/box_code?coin_box_code=${coin_box_code}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiCNEditCoinById = async (data) => {
  try {
    const result = await axios.put('/coin/collect/count', data)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiCDeleteById = async (coin_collect_id) => {
  try {
    const result = await axios.delete(`/coin/collect?coin_collect_id=${coin_collect_id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiCGetCoinBoxById = async (coin_box_id) => {
  try {
    const result = await axios.get(`/coin/box?coin_box_id=${coin_box_id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiCGetCoinBoxStatus = async () => {
  try {
    const result = await axios.get('/coin/box/status')
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiCAddCoinBox = async (data) => {
  try {
    const result = await axios.post('/coin/box', data)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiCEditCoinBoxById = async (data) => {
  try {
    const result = await axios.put('/coin/box', data)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiCDeleteCoinBoxById = async (coin_box_id) => {
  try {
    const result = await axios.delete(`/coin/box?coin_box_id=${coin_box_id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiCCheckCoinById = async (coin_collect_id) => {
  try {
    const result = await axios.put(`/coin/transaction_check?coin_collect_id=${coin_collect_id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiCPushCoin = async (data) => {
  try {
    const result = await axios.post(`/super/push/coin`,data)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

export {
  apiCGetCoinBoxCode,
  apiCGetCoinHistory,
  apiCGetCoinHistoryNS,
  apiCGetCoinBox,
  apiCGetMachineBySiteId,
  apiCAddSite,
  apiCCheckOut,
  apiCNGetCoinById,
  apiCNGetCoinByBoxCode,
  apiCNEditCoinById,
  apiCDeleteById,

  apiCGetCoinBoxById,
  apiCGetCoinBoxStatus,
  apiCAddCoinBox,
  apiCEditCoinBoxById,
  apiCDeleteCoinBoxById,
  apiCCheckCoinById,
  apiCPushCoin
}