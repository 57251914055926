import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Input, Table, Pagination, Tooltip, Modal } from "antd";
import "./Machine.css";
import XLSX from "xlsx";
import { LoadingOutlined } from "@ant-design/icons";

import moment from "moment";
import "moment/locale/th";

import { openNotification, getSearchParams } from "../../utils/function";
import {
  apiMCHGetControllerList,
  apiMCHDeleteControllerById,
} from "../../services/apis/machine";
import { QueryGraphQL, GET_DEVICES } from "../../resolvers/Query";
import usePagination from "../../hooks/usePagination";

const MachineControllerPageV2 = () => {
  const history = useHistory();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const p = params.get("p");
  const ttl = params.get("ttl");
  const sw = params.get("sw");

  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(Number(p) || 1);
  // const [perPage, setPerPage] = useState(Number(ttl) || 20);
  const perPage = 20;
  const [searchWord, setSearchWord] = useState(sw || "");
  const [searchButton, setSearchButton] = useState(false);
  const [waitingForCSV, setWaitingForCSV] = useState(false);

  const [visible, setVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalId, setModalId] = useState(undefined);
  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false);
  const { data } = QueryGraphQL(GET_DEVICES);
  const dataDevices = data?.getDevices;
  const [dataList, setDataList] = useState([]);
  const checkDataPage = usePagination(dataDevices, currentPage, perPage);

  console.log("data--MachineControllerPageV2-🐳🐳-", dataList, dataDevices);
  useEffect(() => {
    setDataList(checkDataPage ?? []);
  }, [currentPage, perPage, dataDevices]);

  const columnsGraphQL = [
    {
      title: "#",
      ellipsis: true,
      align: "center",
      width: "5%",
      render: (data, record, index) => (
        <div>{index + 1 + (currentPage - 1) * perPage}</div>
      ),
    },
    {
      title: "Mac Addr.",
      ellipsis: true,
      align: "center",
      render: (data) => <div>{data.deviceName || "-"}</div>,
    },
    {
      title: "Ver.",
      ellipsis: true,
      align: "center",
      render: (data) => <div>{data.version || "-"}</div>,
    },
    {
      title: "ผู้ผลิต",
      ellipsis: true,
      render: (data) => <div>{data.producer || "-"}</div>,
    },
    {
      title: "สถานที่",
      ellipsis: true,
      render: (data) => <div>{data.site_name || "-"}</div>,
    },
    {
      title: "ชื่อเครื่อง",
      ellipsis: true,
      render: (data) => <div>{data.machine_name || "-"}</div>,
    },
    {
      title: "วันที่รับ",
      ellipsis: true,
      align: "center",
      render: (data) => (
        <div>
          {data.receive_date
            ? moment(data.receive_date)
                .add(543, "years")
                .format("D MMM YYYY HH:mm:ss")
            : "-"}
        </div>
      ),
    },
    {
      title: "วันที่สิ้นสุดรับประกัน",
      ellipsis: true,
      align: "center",
      render: (data) => (
        <div>
          {data.warranty_date
            ? moment(data.warranty_date)
                .add(543, "years")
                .format("D MMM YYYY HH:mm:ss")
            : "-"}
        </div>
      ),
    },
    {
      title: "Manage",
      ellipsis: true,
      align: "center",
      fixed: "right",
      render: (data) => (
        <div className="f-c-c tb-cl-a">
          <Tooltip placement="top" title="Edit">
            <div
              className="f-c-c d-b-t-n-s b-c-o-i"
              style={{ backgroundColor: "#00ADEF" }}
              onClick={(e) => {
                e.stopPropagation();
                history.push(
                  `/machine/controller/edit/${data._id}${getSearchParams(
                    ["p", "ttl", "sw"],
                    [currentPage, perPage, searchWord]
                  )}`
                );
              }}
            >
              <i className="bi bi-pencil-fill"></i>
            </div>
          </Tooltip>
          <Tooltip placement="top" title="Delete">
            <div
              className="f-c-c d-b-t-n-s b-c-o-i"
              style={{ backgroundColor: "#ED2228" }}
              onClick={(e) => {
                e.stopPropagation();
                setModalId(data._id);
                setModalTitle(data.machine_no);
                setVisible(true);
              }}
            >
              <i className="fas fa-trash"></i>
            </div>
          </Tooltip>
        </div>
      ),
    },
  ];

  const deleteData = async () => {
    if (!modalId) {
      openNotification(
        "error",
        "Failed !",
        "Failed to delete this controller."
      );
      return;
    }

    setIsLoadingConfirm(true);
    let action = await apiMCHDeleteControllerById(modalId);
    if (action && action.status) {
      openNotification(
        "success",
        "Success !",
        "Delete this controller successful."
      );
      setVisible(false);
      setIsLoadingConfirm(false);
    } else {
      openNotification(
        "error",
        "Failed !",
        "Failed to delete this controller."
      );
      setIsLoadingConfirm(false);
    }
  };

  const toCSVFile = async () => {
    setWaitingForCSV(true);
    let csvData = [];

    if (dataDevices?.length > 0) {
      dataDevices.map((data, idx) => {
        let resultRow = {};
        resultRow["#"] = idx + 1;
        resultRow["Mac Addr."] = data.deviceName || "-";
        resultRow["Ver."] = data.version || "-";
        resultRow["ผู้ผลิต"] = data.producer || "-";
        resultRow["สถานที่"] = data.site_name || "-";
        resultRow["ชื่อเครื่อง"] = data.machine_name || "-";
        resultRow["วันที่รับ"] = data.receive_date || "-";
        resultRow["วันที่สิ้นสุดรับประกัน"] = data.warranty_date || "-";
        csvData.push(resultRow);
      });

      const toCSV = XLSX.utils.json_to_sheet(csvData);
      const wb = XLSX.utils.book_new();

      const fileName = "CONTROLLER";
      XLSX.utils.book_append_sheet(wb, toCSV, fileName);
      XLSX.writeFile(wb, fileName + ".csv");

      setWaitingForCSV(false);
    }
  };

  return (
    <div>
      <div className="mg-bt-20 h-d-t-p">Machine - Controller</div>

      <div className="pro-reward-header-grid pro-reward-header-grid-column-false">
        <div className="pro-reward-header-grid-search-false">
          <Input
            placeholder="search .."
            value={searchWord}
            onChange={(e) => setSearchWord(e.target.value)}
            onPressEnter={() => {
              setCurrentPage(1);
              setSearchButton(!searchButton);
            }}
          />
        </div>
        <div
          className="f-c-c d-b-t-n-s d-bg-c-t"
          onClick={() => {
            setCurrentPage(1);
            setSearchButton(!searchButton);
          }}
        >
          <i className="bi bi-search" style={{ lineHeight: "0" }}></i>
        </div>
        <Link to="/machine/controller/add">
          <div
            className="f-c-c d-b-t-n-s d-bg-c-t h-32"
            style={{ height: "100%" }}
          >
            + Add
          </div>
        </Link>
        <div
          className="f-c-c d-b-t-n-s d-bg-c-t h-32 pro-reward-header-grid-export-false"
          style={{ pointerEvents: waitingForCSV ? "none" : null }}
          onClick={() => toCSVFile()}
        >
          {waitingForCSV ? <LoadingOutlined /> : "Export CSV"}
        </div>
      </div>

      <Table
        bordered
        size="small"
        rowKey={(record) => record._id}
        loading={isLoading}
        dataSource={dataList}
        columns={columnsGraphQL}
        pagination={false}
        scroll={{ x: true }}
      />

      <div className="pgnt-st">
        <Pagination
          size="small"
          current={currentPage}
          onChange={(page) => setCurrentPage(page)}
          total={dataDevices?.length}
          pageSize={perPage}
          showSizeChanger={false}
        />
      </div>

      <Modal
        title=""
        visible={visible}
        closable={false}
        footer={false}
        centered
      >
        <div className="m-t-i-c" style={{ color: "#FFCB05" }}>
          <i className="bi bi-exclamation-triangle-fill"></i>
        </div>
        <div className="m-t-t-q">Do you want to delete '{modalTitle}' ?</div>
        <div className="m-b-y-n-g">
          <div
            className="f-c-c d-b-t-n-s b-c-o-i"
            style={{ backgroundColor: "#cccccc" }}
            onClick={() => setVisible(false)}
          >
            Cancel
          </div>
          <div
            className="f-c-c d-b-t-n-s b-c-o-i"
            style={{
              backgroundColor: "#3f4d67",
              pointerEvents: isLoadingConfirm ? "none" : null,
            }}
            onClick={() => deleteData()}
          >
            {isLoadingConfirm ? <LoadingOutlined /> : "Confirm"}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default MachineControllerPageV2;
