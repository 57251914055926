import React, { useState, useEffect } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { Table, Pagination, DatePicker, Input } from "antd";
import "./Machine.css";
import XLSX from "xlsx";
// import { LoadingOutlined } from '@ant-design/icons';

import Loading from "../../components/Loading/Loading";
import ChartColumnStack from "../../components/Tools/Chart/ChartColumnStack";
// import ProgressPercent from '../../components/Tools/ProgressPercent'

import moment from "moment";
import "moment/locale/th";

import { numberWithCommas, getSearchParams } from "../../utils/function";
import {
  apiMCHGetMachineHistoryById,
  apiMCHGetMachineHistoryGraphById,
} from "../../services/apis/machine";

const { RangePicker } = DatePicker;

const MachineHistoryPage = () => {
  const { machineId } = useParams();
  const history = useHistory();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const start = params.get("start");
  const end = params.get("end");

  const role = (localStorage.getItem("user_role") || "").toUpperCase();
  const [isLoadingGraph, setIsLoadingGraph] = useState(false);
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [startDate, setStartDate] = useState(
    start
      ? moment(start).startOf("day").format("YYYY-MM-DD HH:mm:ss")
      : moment().startOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [endDate, setEndDate] = useState(
    end
      ? moment(end).endOf("day").format("YYYY-MM-DD HH:mm:ss")
      : moment().endOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [paymentData, setPaymentData] = useState(undefined);
  const [tableData, setTableData] = useState(undefined);
  const [searchWord, setSearchWord] = useState("");
  const [searchButtonDate, setSearchButtonDate] = useState(false);
  const [searchButtonTable, setSearchButtonTable] = useState(false);
  // const [waitingForCSV, setWaitingForCSV] = useState(false)

  useEffect(() => {
    getDataPayment();
    getDataTable();
  }, [searchButtonDate]);

  useEffect(() => {
    getDataTable();
    history.push(
      `${location.pathname}${getSearchParams(
        ["start", "end"],
        [startDate, endDate]
      )}`
    );
  }, [currentPage, perPage, searchButtonDate, searchButtonTable]);

  const getDataPayment = async () => {
    setIsLoadingGraph(true);
    let payment = await apiMCHGetMachineHistoryGraphById(
      startDate,
      endDate,
      machineId
    );
    if (payment && payment.status) {
      setIsLoadingGraph(false);
      setPaymentData(payment);
    }
  };

  const getDataTable = async () => {
    setIsLoadingTable(true);
    let data = await apiMCHGetMachineHistoryById(
      false,
      startDate,
      endDate,
      currentPage,
      perPage,
      searchWord,
      machineId
    );
    if (data && data.status) {
      setIsLoadingTable(false);
      setTableData(data.result);
    }
  };

  const columns = [
    {
      title: "#",
      ellipsis: true,
      align: "center",
      width: "5%",
      render: (data, record, index) => (
        <div>{index + 1 + (currentPage - 1) * perPage}</div>
      ),
    },
    {
      title: "Customer Name",
      ellipsis: true,
      render: (data) => <div>{data.customer_name || "-"}</div>,
    },
    {
      title: "Action By",
      ellipsis: true,
      align: "center",
      render: (data) => <div>{data.action_by || "-"}</div>,
    },
    {
      title: "Mode",
      ellipsis: true,
      align: "center",
      render: (data) => <div>{data.action_mode || "-"}</div>,
    },
    {
      title: "Option",
      ellipsis: true,
      align: "center",
      // render: (data) => <div>{data.action_mode || '-'}</div>
    },
    {
      title: "Price",
      ellipsis: true,
      align: "center",
      render: (data) => <div>{numberWithCommas(data.price || 0, 2)}</div>,
    },
    {
      title: "Date",
      ellipsis: true,
      align: "center",
      render: (data) => (
        <div>
          {data.date
            ? moment(data.date).add(543, "years").format("D MMM YYYY HH:mm:ss")
            : "-"}
        </div>
      ),
    },
  ];

  const toCSVFile = async () => {
    // setWaitingForCSV(true)
    // let data = await apiMCHGetMachineHistoryById(true, startDate, endDate, '', '', searchWord, machineId)
    // let csvData = [];

    // if (data && data.status) {
    //   (data.result.data_list || []).map((data, idx) => {
    //     let resultRow = {}
    //     resultRow['#'] = idx + 1
    //     resultRow['Customer Name'] = data.customer_name || '-'
    //     resultRow['Action By'] = data.action_by || '-'
    //     resultRow['Price'] = data.price || '-'
    //     resultRow['Date'] = data.date || '-'
    //     csvData.push(resultRow);
    //   })

    //   const toCSV = XLSX.utils.json_to_sheet(csvData);
    //   const wb = XLSX.utils.book_new();

    //   const fileName = `MACHINE_${machineId}_HISTORY`
    //   XLSX.utils.book_append_sheet(wb, toCSV, fileName);
    //   XLSX.writeFile(wb, fileName + ".csv");

    //   setWaitingForCSV(false)
    // }

    let csvData = [];

    ((tableData && tableData.data_list) || []).map((data, idx) => {
      let resultRow = {};
      resultRow["ช่วงเวลาที่เลือก"] = `${moment(startDate)
        .add(543, "years")
        .format("D MMM YYYY")} - ${moment(endDate)
        .add(543, "years")
        .format("D MMM YYYY")}`;
      resultRow["#"] = idx + 1;
      resultRow["Customer Name"] = data.customer_name || "-";
      resultRow["Action By"] = data.action_by || "-";
      resultRow["Mode"] = data.action_mode || "-";
      // resultRow['Option'] = data.action_mode || '-'
      resultRow["Price"] = data.price || 0;
      resultRow["Date"] = data.date || "-";
      csvData.push(resultRow);
    });

    const toCSV = XLSX.utils.json_to_sheet(csvData);
    const wb = XLSX.utils.book_new();

    const fileName = `MACHINE_${machineId}_HISTORY`;
    XLSX.utils.book_append_sheet(wb, toCSV, fileName);
    XLSX.writeFile(wb, fileName + ".csv");
  };

  return (
    <div>
      <div className="mg-bt-20 h-d-t-p">Machine History</div>

      <div className="h-d-i-b">
        <div>
          <RangePicker
            style={{ width: "100%" }}
            format={(date) =>
              moment(date).add(543, "years").format("D MMM YYYY")
            }
            allowClear={false}
            value={[
              moment(startDate, "YYYY-MM-DD HH:mm:ss"),
              moment(endDate, "YYYY-MM-DD HH:mm:ss"),
            ]}
            onChange={(date) => {
              setStartDate(
                moment(date[0]).startOf("day").format("YYYY-MM-DD HH:mm:ss")
              );
              setEndDate(
                moment(date[1]).endOf("day").format("YYYY-MM-DD HH:mm:ss")
              );
            }}
            disabledDate={(current) =>
              role === "BRANCHADMIN"
                ? current && current < moment("2021-06-25").startOf("day")
                : null
            }
          />
        </div>
        <div
          className="f-c-c d-b-t-n-s d-bg-c-t"
          onClick={() => setSearchButtonDate(!searchButtonDate)}
        >
          <i className="bi bi-search" style={{ lineHeight: "0" }}></i>
        </div>
      </div>

      <div className="c-c-t-s mg-t-20" style={{ maxWidth: "500px" }}>
        <div className="pst-rlt">
          {isLoadingGraph && <Loading />}
          <div className="dash-report-card-padding">
            <div className="dash-report-title">
              จำนวนใช้งานทั้งหมด ( ครั้ง )
            </div>
            <div className="dash-report-total-credit">
              {numberWithCommas(
                (paymentData &&
                  paymentData.payment_all &&
                  paymentData.payment_all.total_count) ||
                  0,
                0
              )}
            </div>
            {/* <div>
              <ProgressPercent value={paymentData && paymentData.payment_all && paymentData.payment_all.percentUse || 0} />
            </div> */}
          </div>
        </div>
      </div>

      <div className="dash-big-title">ช่องทางการชำระเงิน</div>
      <div className="dash-report-card-payment-grid">
        <div className="c-c-t-s">
          <div className="pst-rlt-h-100pc">
            {isLoadingGraph && <Loading />}
            <div className="p-d-p-ch-10-20">
              <div className="dash-report-card-payment-detail-grid">
                <div className="f-c-c">
                  <i className="bi bi-layers"></i>
                </div>
                <div>เหรียญ</div>
                <div>
                  {numberWithCommas(
                    (paymentData &&
                      paymentData.payment_all &&
                      paymentData.payment_all.coin) ||
                      0,
                    2
                  )}
                </div>
              </div>
              {/* <div>
                <ProgressPercent value={paymentData && paymentData.payment_all && paymentData.payment_all.coin_percent || 0} />
              </div> */}
            </div>
          </div>
        </div>

        <div className="c-c-t-s">
          <div className="pst-rlt-h-100pc">
            {isLoadingGraph && <Loading />}
            <div className="p-d-p-ch-10-20">
              <div className="dash-report-card-payment-detail-grid">
                <div className="f-c-c">
                  <i className="bi bi-phone"></i>
                </div>
                <div>App</div>
                <div>
                  {numberWithCommas(
                    (paymentData &&
                      paymentData.payment_all &&
                      paymentData.payment_all.mobile) ||
                      0,
                    2
                  )}
                </div>
              </div>
              {/* <div>
                <ProgressPercent value={paymentData && paymentData.payment_all && paymentData.payment_all.app_percent || 0} />
              </div> */}
            </div>
          </div>
        </div>

        <div className="c-c-t-s">
          <div className="pst-rlt-h-100pc">
            {isLoadingGraph && <Loading />}
            <div className="p-d-p-ch-10-20">
              <div className="dash-report-card-payment-detail-grid">
                <div className="f-c-c">
                  <i className="bi bi-upc-scan"></i>
                </div>
                <div>QR-Code</div>
                <div>
                  {numberWithCommas(
                    (paymentData &&
                      paymentData.payment_all &&
                      paymentData.payment_all.qrcode) ||
                      0,
                    2
                  )}
                </div>
              </div>
              {/* <div>
                <ProgressPercent value={paymentData && paymentData.payment_all && paymentData.payment_all.qr_code_percent || 0} />
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <ChartColumnStack
        isLoading={isLoadingGraph}
        chartReport={(paymentData && paymentData.result) || []}
        chartTitle="กราฟแสดงช่วงเวลาที่มีการใช้งาน"
        dataLabels={false}
        stackLabels={false}
        tooltipEnabled={true}
        digits={0}
      />

      <div className="dash-chart-box-container">
        <div className="dash-space-between">
          <div className="h-d-t-g">
            <div className="f-c-c">
              <i className="bi bi-basket" style={{ lineHeight: "0" }}></i>
            </div>
            <div className="f-s-c">รายละเอียดการใช้งาน</div>
          </div>

          <div className="pro-reward-header-grid pro-reward-header-grid-column-true">
            <div className="pro-reward-header-grid-search-true">
              <Input
                placeholder="search .."
                value={searchWord}
                onChange={(e) => setSearchWord(e.target.value)}
                onPressEnter={() => {
                  setCurrentPage(1);
                  setSearchButtonTable(!searchButtonTable);
                }}
              />
            </div>
            <div
              className="f-c-c d-b-t-n-s d-bg-c-t"
              onClick={() => setSearchButtonTable(!searchButtonTable)}
            >
              <i className="bi bi-search" style={{ lineHeight: "0" }}></i>
            </div>
            {/* <div className="f-c-c d-b-t-n-s d-bg-c-t table-export-btn-width" style={{ pointerEvents: waitingForCSV ? 'none' : null }} onClick={() => toCSVFile()}>
              {waitingForCSV ? <LoadingOutlined /> : 'Export CSV'}
            </div> */}
            {/* {role === 'SUPERADMIN' && <div className="f-c-c d-b-t-n-s d-bg-c-t table-export-btn-width" onClick={() => toCSVFile()}>Export CSV</div>} */}
            {role === "SUPERADMIN" && (
              <div
                className="f-c-c d-b-t-n-s d-bg-c-t h-32 pro-reward-header-grid-export-true"
                onClick={() => toCSVFile()}
              >
                Export CSV
              </div>
            )}
          </div>

          <div className="dash-table-container">
            <Table
              bordered
              size="small"
              loading={isLoadingTable}
              rowKey={(record) => record._id}
              dataSource={(tableData && tableData.data_list) || []}
              columns={columns}
              pagination={false}
              scroll={{ x: true }}
            />
          </div>

          <div className="pgnt-st">
            <Pagination
              size="small"
              current={currentPage}
              onChange={(page) => setCurrentPage(page)}
              total={tableData && tableData.max_total_rows}
              pageSize={perPage}
              showSizeChanger={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MachineHistoryPage;
