import React, { useState, useEffect } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { Empty } from 'antd';

import Loading from '../../../components/Loading/Loading'
import { colors } from '../../../config/config'

Highcharts.setOptions({ lang: { thousandsSep: ',' } })

const ChartPie = props => {
  const [chartTitle, setChartTitle] = useState('')
  const [dataSeries, setDataSeries] = useState([])

  useEffect(() => {
    let data = [];
    props.chartReport.map(key =>
      data.push({
        name: key.name,
        y: key.value
      })
    )
    setDataSeries(data)
    setChartTitle(props.chartTitle)
  }, [props.chartReport])

  const options = {
    chart: {
      type: 'pie',
      backgroundColor: 'transparent',
    },
    colors: colors.pastel,
    title: { text: '' },
    credits: {
      enabled: false
    },
    tooltip: {
      enabled: false
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: true,
          format: '<br>{point.percentage:.2f}%',
          distance: -50,
          color: 'black',
          style: {
            fontSize: '12px',
            textOutline: 'transparent'
          },
        },
        showInLegend: true,
        states: {
          hover: {
            halo: {
              opacity: 0
            },
          }
        }
      }
    },
    series: [{
      colorByPoint: true,
      data: dataSeries,
    }]
  }

  return (
    <div className="dash-chart-box-container">
      <div className="pst-rlt-h-100pc">
        {props.isLoading && <Loading />}
        <div className="dash-space-between">
          <div className="dash-chart-title">{chartTitle}</div>
          {dataSeries.length > 0 ?
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
            />
            : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ marginTop: '100px' }} />
          }
        </div>
      </div>
    </div>
  )
}

export default ChartPie
