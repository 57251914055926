import React, { useState, useEffect } from 'react'
import '../Report.css'

import Loading from '../../../components/Loading/Loading'
import ProgressPercent from '../../../components/Tools/Progress/ProgressPercent'

import { numberWithCommas } from '../../../utils/function'

const Report = props => {
  const [report, setReport] = useState(undefined)

  useEffect(() => {
    setReport(props.report)
  }, [props])

  return (
    <div>
      {/* <div className="dash-big-title">รายได้ / การใช้งาน</div> */}
      {/* <div className="dash-card-grid"> */}
      <div className="dash-report-card-payment-grid">
        <div className="c-c-t-s">
          <div className="pst-rlt-h-100pc">
            {props.isLoading && <Loading />}
            <div className="dash-report-card-padding">
              <div className="dash-report-title">รายรับรวมทั้งหมด ( บาท )</div>
              <div className="dash-report-card-detail-grid">
                <div className="f-c-c"><i className="bi bi-layers"></i></div>
                <div>เหรียญ</div>
                <div>{numberWithCommas(report && report.payment_all && report.payment_all.coin || 0, 2)}</div>
              </div>
              <div className="dash-report-card-detail-grid">
                <div className="f-c-c"><i className="bi bi-phone"></i></div>
                <div>App</div>
                <div>{numberWithCommas(report && report.payment_all && report.payment_all.app || 0, 2)}</div>
              </div>
              <div className="dash-report-card-detail-grid">
                <div className="f-c-c"><i className="bi bi-upc-scan"></i></div>
                <div>QR-Code</div>
                <div>{numberWithCommas(report && report.payment_all && report.payment_all.qr_code || 0, 2)}</div>
              </div>
              <div className="dash-report-total-credit" style={{ margin: '10px 0 0' }}>
                {numberWithCommas(report && report.payment_all && (report.payment_all.coin || 0) + (report.payment_all.app || 0) + (report.payment_all.qr_code || 0) || 0, 2)}
              </div>
              <div>
                <ProgressPercent value={report && report.payment_all && report.payment_all.total_percent || 0} />
              </div>
            </div>
          </div>
        </div>

        <div className="c-c-t-s">
          <div className="pst-rlt-h-100pc">
            {props.isLoading && <Loading />}
            <div className="dash-report-card-padding">
              <div className="dash-report-title">* ผู้ใช้งานทั้งหมด ( คน )</div>
              <div className="dash-report-card-detail-grid">
                <div className="f-c-c"><i className="bi bi-phone"></i></div>
                <div>Android</div>
                <div>{numberWithCommas(report && report.customer_all && report.customer_all.android || 0, 0)}</div>
              </div>
              <div className="dash-report-card-detail-grid">
                <div className="f-c-c"><i className="bi bi-phone"></i></div>
                <div>iOS</div>
                <div>{numberWithCommas(report && report.customer_all && report.customer_all.ios || 0, 0)}</div>
              </div>
              <div className="dash-report-card-detail-grid">
                <div className="f-c-c"><i className="bi bi-phone"></i></div>
                <div>Sansiri</div>
                <div>{numberWithCommas(report && report.customer_all && report.customer_all.sansiri || 0, 0)}</div>
              </div>
              <div className="dash-report-card-detail-grid">
                <div className="f-c-c"><i className="bi bi-phone-fill"></i></div>
                <div>Undefined</div>
                <div>{numberWithCommas(report && report.customer_all && report.customer_all.undefined || 0, 0)}</div>
              </div>
              <div className="dash-report-total-credit" style={{ margin: '10px 0 0' }}>
                {numberWithCommas(report && report.customer_all && report.customer_all.total || 0)}
              </div>
            </div>
          </div>
        </div>

        <div className="c-c-t-s">
          <div className="pst-rlt-h-100pc">
            {props.isLoading && <Loading />}
            <div className="dash-report-card-padding">
              <div className="dash-report-title">จำนวนใช้งานทั้งหมด ( ครั้ง )</div>
              <div className="dash-report-total-credit">{numberWithCommas(report && report.machine_use_count && report.machine_use_count.total_use || 0, 0)}</div>
              <div>
                <ProgressPercent value={report && report.machine_use_count && report.machine_use_count.percentUse || 0} />
              </div>
            </div>
          </div>
        </div>

        {/* <div className="c-c-t-s">
          <div className="pst-rlt-h-100pc">
            {props.isLoading && <Loading />}
            <div className="dash-report-card-padding">
              <div className="dash-report-title">** ยอดเครดิตคงเหลือ ( บาท )</div>
              <div className="dash-report-total-credit">{numberWithCommas(report && report.credits_total || 0, 2)}</div>
            </div>
          </div>
        </div> */}
      </div>

      <div className="dash-big-title">ช่องทางการชำระเงินจากการใช้งาน</div>
      <div className="dash-report-card-payment-grid">
        <div className="c-c-t-s">
          <div className="pst-rlt-h-100pc">
            {props.isLoading && <Loading />}
            <div className="p-d-p-ch-10-20">
              <div className="dash-report-card-payment-detail-grid">
                <div className="f-c-c"><i className="bi bi-layers"></i></div>
                <div>เหรียญ</div>
                <div>{numberWithCommas(report && report.payment_all && report.payment_all.coin || 0, 2)}</div>
              </div>
              <div>
                <ProgressPercent value={report && report.payment_all && report.payment_all.coin_percent || 0} />
              </div>
            </div>
          </div>
        </div>

        <div className="c-c-t-s">
          <div className="pst-rlt-h-100pc">
            {props.isLoading && <Loading />}
            <div className="p-d-p-ch-10-20">
              <div className="dash-report-card-payment-detail-grid">
                <div className="f-c-c"><i className="bi bi-phone"></i></div>
                <div>App</div>
                <div>{numberWithCommas(report && report.payment_all && report.payment_all.app || 0, 2)}</div>
              </div>
              <div>
                <ProgressPercent value={report && report.payment_all && report.payment_all.app_percent || 0} />
              </div>
            </div>
          </div>
        </div>

        <div className="c-c-t-s">
          <div className="pst-rlt-h-100pc">
            {props.isLoading && <Loading />}
            <div className="p-d-p-ch-10-20">
              <div className="dash-report-card-payment-detail-grid">
                <div className="f-c-c"><i className="bi bi-upc-scan"></i></div>
                <div>QR-Code</div>
                <div>{numberWithCommas(report && report.payment_all && report.payment_all.qr_code || 0, 2)}</div>
              </div>
              <div>
                <ProgressPercent value={report && report.payment_all && report.payment_all.qr_code_percent || 0} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="dash-big-title">ช่องทางการเติมเงิน</div>
      <div className="dash-report-card-payment-grid">
        {(report && report.payment_type || []).map((type, idx) =>
          <div key={idx} className="c-c-t-s">
            <div className="pst-rlt-h-100pc">
              {props.isLoading && <Loading />}
              <div className="p-d-p-ch-10-20">
                <div className="dash-report-card-payment-detail-grid">
                  <div className="f-c-c">{type.payty_image ? <img src={type.payty_image} alt="" /> : <i className="bi bi-wallet2"></i>}</div>
                  <div>{type.payty_name || '-'}</div>
                  <div>{numberWithCommas(type.value || 0, 2)}</div>
                </div>
                <div>
                  <ProgressPercent value={type.percent || 0} />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Report
