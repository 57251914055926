import React, { useState, useEffect } from 'react'
import { InputNumber, DatePicker, Checkbox, Switch } from 'antd';
import './Promotion.css'
import { LoadingOutlined } from '@ant-design/icons';

import moment from 'moment';
import 'moment/locale/th'

import { openNotification } from '../../utils/function'
import { apiPMTGetPoint, apiPMTEditPoint } from '../../services/apis/promotion'

const { RangePicker } = DatePicker;

const PromotionPointPage = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [promotion, setPromotion] = useState(undefined)

  const [isAlertPeriodPromotion, setIsAlertPeriodPromotion] = useState(false)
  const [isAlertPrice, setIsAlertPrice] = useState(false)
  const [isAlertPoint, setIsAlertPoint] = useState(false)
  const [isAlertLimit, setIsAlertLimit] = useState(false)

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    let data = await apiPMTGetPoint()
    if (data && data.status) setPromotion(data.result)
  }

  const addEditPromotion = async () => {
    if (
      !(promotion && promotion.start_date) ||
      isNaN(promotion && promotion.return_value) ||
      isNaN(promotion && promotion.point)
    ) {
      if (!(promotion && promotion.start_date)) setIsAlertPeriodPromotion(true)
      if (isNaN(promotion && promotion.return_value)) setIsAlertPrice(true)
      if (isNaN(promotion && promotion.point)) setIsAlertPoint(true)

    } else {
      setIsLoading(true)

      let data = { ...promotion }
      let newData = await apiPMTEditPoint(data)
      if (newData && newData.status) {
        setIsLoading(false)
        openNotification('success', 'Success !', 'Edit point successful.')
        getData()
      } else {
        setIsLoading(false)
        openNotification('error', 'Failed !', 'Failed to edit point.')
      }
    }
  }

  return (
    <div>
      <div className="mg-bt-20 h-d-t-p">Promotion - Point</div>

      <div className="t-m-c-i-p-g">
        <div className="f-s-c">Period Promotion</div>
        <div>
          <RangePicker
            style={{ width: '100%', borderColor: isAlertPeriodPromotion ? '#EC1C24' : null }}
            placeholder={["Period Start", "Period End"]}
            format={date => moment(date).add(543, 'years').format('D MMM YYYY')}
            allowClear={false}
            value={[
              promotion && promotion.start_date && moment(promotion.start_date, 'YYYY-MM-DD HH:mm:ss'),
              promotion && promotion.end_date && moment(promotion.end_date, 'YYYY-MM-DD HH:mm:ss')
            ]}
            onChange={date => {
              setIsAlertPeriodPromotion(false)
              setPromotion({
                ...promotion,
                start_date: moment(date[0]).format('YYYY-MM-DD HH:mm:ss'),
                end_date: moment(date[1]).format('YYYY-MM-DD HH:mm:ss')
              })
            }}
            disabledDate={current => current && current < moment().startOf('day')}
          />
        </div>

        <div className="f-s-c">Price</div>
        <div className="pst-rlt">
          <InputNumber min={0}
            step={1}
            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
            style={{ width: '100%', borderColor: isAlertPrice ? '#EC1C24' : null }}
            placeholder="Price"
            value={promotion && promotion.return_value}
            onChange={value => {
              setIsAlertPrice(false)
              setPromotion({ ...promotion, return_value: Math.round(value) || 0 })
            }}
          />
          <div className="s-f-o-i-p">Credits</div>
        </div>

        <div className="f-s-c">Point</div>
        <div className="pst-rlt">
          <InputNumber min={0}
            step={1}
            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
            style={{ width: '100%', borderColor: isAlertPoint ? '#EC1C24' : null }}
            placeholder="Point"
            value={promotion && promotion.point}
            onChange={value => {
              setIsAlertPoint(false)
              setPromotion({ ...promotion, point: Math.round(value) || 0 })
            }}
          />
          <div className="s-f-o-i-p">Points</div>
        </div>

        <div className="f-s-c">Limit</div>
        <div>
          <InputNumber min={0} max={1000000}
            step={1}
            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
            style={{ width: '100%', borderColor: isAlertLimit ? '#EC1C24' : null }}
            placeholder="Limit"
            value={promotion && promotion.total_limit}
            onChange={value => {
              setIsAlertLimit(false)
              setPromotion({ ...promotion, total_limit: Math.round(value) || 0 })
            }}
          />
        </div>

        <div className="f-s-c">Multiple</div>
        <div className="f-s-c">
          <Checkbox
            checked={promotion && promotion.multiple}
            onChange={e => setPromotion({ ...promotion, multiple: e.target.checked })}
          />
        </div>

        <div className="f-s-c">Active</div>
        <div className="f-s-c">
          <Switch
            checked={promotion && promotion.active}
            onChange={checked => setPromotion({ ...promotion, active: checked })}
          />
        </div>
      </div>

      <div className="f-c-c s-e-b">
        <div className="f-c-c d-b-t-n-s d-bg-c-t"
          style={{ width: '130px', pointerEvents: isLoading ? 'none' : null }}
          onClick={() => addEditPromotion()}
        >{isLoading ? <LoadingOutlined /> : `Save Point`}</div>
      </div>

    </div>
  )
}

export default PromotionPointPage
