import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import { Input, Table, Pagination, DatePicker, Select } from 'antd';
import './Maintenance.css'
import XLSX from "xlsx";
import { LoadingOutlined } from '@ant-design/icons';

import moment from 'moment';
import 'moment/locale/th'

import { colors } from '../../config/config'
import { numberWithCommas, getSearchParams, diffTime } from '../../utils/function'
import { apiSGetSite } from '../../services/apis/site'
import { apiMCHGetTypeBySiteId } from '../../services/apis/machine'
import { apiMTNGetType, apiMTNGetHistory } from '../../services/apis/maintenance'

const { Option } = Select;
const { RangePicker } = DatePicker;

const History = () => {
  const history = useHistory();
  const location = useLocation();

  const params = new URLSearchParams(location.search)
  const p = params.get('p')
  const ttl = params.get('ttl')
  const mtn = params.get('mtn')
  const mch = params.get('mch')
  const s = params.get('s')
  const start = params.get('start')
  const end = params.get('end')
  const sw = params.get('sw')

  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(Number(p) || 1)
  const [perPage, setPerPage] = useState(Number(ttl) || 20)
  const [startDate, setStartDate] = useState(start ? moment(start).startOf('day').format('YYYY-MM-DD HH:mm:ss') : moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'))
  const [endDate, setEndDate] = useState(end ? moment(end).endOf('day').format('YYYY-MM-DD HH:mm:ss') : moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'))
  const [tableData, setTableData] = useState(undefined)
  const [searchWord, setSearchWord] = useState(sw || '')
  const [searchButton, setSearchButton] = useState(false)
  const [waitingForCSV, setWaitingForCSV] = useState(false)

  const [maintenances, setMaintenances] = useState([])
  const [maintenance, setMaintenance] = useState(Number(mtn) || '')
  const [machines, setMachines] = useState([])
  const [machine, setMachine] = useState(Number(mch) || '')
  const [sites, setSites] = useState([])
  const [site, setSite] = useState(Number(s) || '')

  useEffect(() => {
    const getStaticDropdown = async () => {
      let type = await apiMTNGetType()
      if (type && type.status) setMaintenances(type.result || [])

      let machine = await apiMCHGetTypeBySiteId()
      if (machine && machine.status) setMachines(machine.result || [])

      let site = await apiSGetSite()
      if (site && site.status) setSites(site.result || [])
    }
    getStaticDropdown()
  }, [])

  useEffect(() => {
    getTableData()
    history.push(`${location.pathname}${getSearchParams(['p', 'ttl', 'mtn', 'mch', 's', 'start', 'end', 'sw'], [currentPage, perPage, maintenance, machine, site, startDate, endDate, searchWord])}`)
  }, [currentPage, perPage, searchButton])

  const getTableData = async () => {
    setIsLoading(true)
    let table = await apiMTNGetHistory(false, startDate, endDate, currentPage, perPage, searchWord, maintenance, machine, site)
    if (table && table.status) {
      setIsLoading(false)
      setTableData(table.result)
    }
  }

  const columns = [
    {
      title: '#',
      ellipsis: true,
      align: 'center',
      width: '5%',
      render: (data) => <div>{data.maintenance_id || '-'}</div>
    },
    {
      title: 'Flag',
      ellipsis: true,
      align: 'center',
      width: '80px',
      render: (data) => <div className="f-c-c d-b-t-n-s b-c-o-i"
      style={{
        backgroundColor: data.maintenance_type_id === 1
          ? colors.general.green
          : getFlagColor(moment().diff(moment(data.start_date), 'hours'))
      }}
        // style={{ backgroundColor: getFlagColor(moment(data.end_date).diff(moment(data.start_date), 'hours')) }}
      />
    },
    {
      title: 'วันที่แจ้ง',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{data.start_date ? moment(data.start_date).add(543, 'years').format('D MMM YYYY HH:mm:ss') : '-'}</div>
    },
    {
      title: 'วันที่เสร็จ',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{data.end_date ? moment(data.end_date).add(543, 'years').format('D MMM YYYY HH:mm:ss') : '-'}</div>
    },
    {
      title: 'สถานที่',
      ellipsis: true,
      render: (data) => <div>{data.site_name || '-'}</div>
    },
    {
      title: 'เครื่อง',
      ellipsis: true,
      render: (data) => <div>
        {data.machine_name
          ? data.machine_name.split(',').map((list, idx) => <div key={idx}>{list}</div>)
          : '-'}
      </div>
    },
    {
      title: 'อาการ',
      // ellipsis: true,
      width: '500px',
      render: (data) => <div>{data.detail || '-'}</div>
    },
    {
      title: 'แก้ไขโดย',
      ellipsis: true,
      render: (data) => <div>
        {data.maintainer_list
          ? data.maintainer_list.split(',').map((list, idx) => <div key={idx}>{list}</div>)
          : '-'}
      </div>
    },
    {
      title: 'ระยะเวลา',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{(data.start_date && data.end_date) ? diffTime(data.start_date, data.end_date) : '-'}</div>
    },
  ];

  const getFlagColor = value => {
    if (value > 48) return colors.general.red
    else if (value > 24) return colors.general.orange
    else return colors.general.green
  }

  const getFlagWord = value => {
    if (value > 48) return 'Red'
    else if (value > 24) return 'Orange'
    else return 'Green'
  }

  const toCSVFile = async () => {
    setWaitingForCSV(true)
    let data = await apiMTNGetHistory(true, startDate, endDate, currentPage, perPage, searchWord, maintenance, machine, site)
    // let data = await apiMTNGetHistory(true, startDate, endDate, '', '', searchWord, '', '', '')
    let csvData = [];

    if (data && data.status) {
      (data.result.data_list || []).map((data, idx) => {
        let resultRow = {}
        resultRow['#'] = idx + 1
        resultRow['Flag'] = data.maintenance_type_id === 1 ? "Green" : getFlagWord(moment(data.end_date).diff(moment(data.start_date), 'hours'))
        resultRow['วันที่แจ้ง'] = data.start_date || '-'
        resultRow['วันที่เสร็จ'] = data.end_date || '-'
        resultRow['สถานที่'] = data.site_name || '-'
        resultRow['เครื่อง'] = data.machine_name || '-'
        resultRow['อาการ'] = data.detail || '-'
        resultRow['แก้ไขโดย'] = data.maintainer_list || '-'
        resultRow['ระยะเวลา'] = (data.start_date && data.end_date) ? diffTime(data.start_date, data.end_date) : '-'
        csvData.push(resultRow);
      })

      const toCSV = XLSX.utils.json_to_sheet(csvData);
      const wb = XLSX.utils.book_new();

      const fileName = 'MAINTENANCE_HISTORY'
      XLSX.utils.book_append_sheet(wb, toCSV, fileName);
      XLSX.writeFile(wb, fileName + ".csv");

      setWaitingForCSV(false)
    }
  }

  return (
    <div>
      <div className="mg-bt-20 coin-collect-title">
        <div className="h-d-t-p">Maintenance - History</div>
        <div className="coin-collect-card-list">
          {(tableData && tableData.sumary || []).map((list, idx) => <div key={idx} className="c-c-t-s">
            <div className="coin-collect-card">
              <div className="coin-collect-card-grid">
                <div>{list.name}</div>
                <div>{numberWithCommas(list.value || 0, 0)}</div>
              </div>
            </div>
          </div>)}
        </div>
      </div>

      <div className="maintenance-header-grid maintenance-header-grid-history">
        <div className="ovf-hd maintenance-header-grid-history-maintenance">
          <Select style={{ width: '100%' }}
            value={maintenance}
            onChange={value => setMaintenance(value)}
          >
            <Option value="">All Ticket Type</Option>
            {maintenances.map((maintenance, idx) => <Option key={idx} value={maintenance.maintenance_type_id}>{maintenance.maintenance_type_name}</Option>)}
          </Select>
        </div>
        <div className="ovf-hd maintenance-header-grid-history-machine">
          <Select style={{ width: '100%' }}
            value={machine}
            onChange={value => setMachine(value)}
          >
            <Option value="">All Machine Type</Option>
            {machines.map((machine, idx) => <Option key={idx} value={machine.machine_type_id}>{machine.machine_type_name}</Option>)}
          </Select>
        </div>
        <div className="ovf-hd maintenance-header-grid-history-site">
          <Select style={{ width: '100%' }}
            showSearch
            value={site}
            onChange={value => setSite(value)}
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            <Option value="">All Site</Option>
            {sites.map((site, idx) => <Option key={idx} value={site.site_id}>{site.site_name}</Option>)}
          </Select>
        </div>
        <div className="maintenance-header-grid-history-date">
          <RangePicker
            style={{ width: '100%' }}
            format={date => moment(date).add(543, 'years').format('D MMM YYYY')}
            allowClear={false}
            value={[moment(startDate, 'YYYY-MM-DD'), moment(endDate, 'YYYY-MM-DD')]}
            onChange={date => {
              setCurrentPage(1)
              setStartDate(moment(date[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss'))
              setEndDate(moment(date[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss'))
            }}
          />
        </div>
        <div className="maintenance-header-grid-history-search">
          <Input
            placeholder="search .."
            value={searchWord}
            onChange={e => setSearchWord(e.target.value)}
            onPressEnter={() => {
              setCurrentPage(1)
              setSearchButton(!searchButton)
            }}
          />
        </div>
        <div className="f-c-c d-b-t-n-s d-bg-c-t"
          onClick={() => {
            setCurrentPage(1)
            setSearchButton(!searchButton)
          }}>
          <i className="bi bi-search" style={{ lineHeight: '0' }}></i>
        </div>
        <div className="f-c-c d-b-t-n-s d-bg-c-t h-32 maintenance-header-grid-history-export"
          style={{ pointerEvents: waitingForCSV ? 'none' : null }}
          onClick={() => toCSVFile()}
        >
          {waitingForCSV ? <LoadingOutlined /> : 'Export CSV'}
        </div>
      </div>

      <Table bordered
        size="small"
        rowKey={record => record.maintenance_id}
        loading={isLoading}
        dataSource={tableData && tableData.data_list || []}
        columns={columns}
        pagination={false}
        scroll={{ x: true }}
        rowClassName="cs-pt"
        onRow={data => ({
          onClick: () => history.push(`/maintenance/history/view/${data.maintenance_id}`)
        })}
      />

      <div className="pgnt-st">
        <Pagination
          size="small"
          current={currentPage}
          onChange={page => setCurrentPage(page)}
          total={tableData && tableData.max_total_rows}
          pageSize={perPage}
          showSizeChanger={false}
        />
      </div>

    </div>
  )
}

export default History
