import React, { useState, useEffect } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { Empty } from 'antd';

import Loading from '../../Loading/Loading'
import { colors } from '../../../config/config'

Highcharts.setOptions({ lang: { thousandsSep: ',' } })

const ChartColumn = props => {
  const [chartTitle, setChartTitle] = useState('')
  const [categories, setCategories] = useState([])
  const [dataSeries, setDataSeries] = useState([])
  const [nameSeries, setNameSeries] = useState('')
  const [dataLabels, setDataLabels] = useState(true)
  const [tooltipEnabled, setTooltipEnabled] = useState(false)
console.log("props",props)  
  useEffect(() => {
    let categories = [];
    let data = [];
    props.chartReport.map(key => {
      categories.push(key.name)
      data.push(key[props.valueData])
    })
    setCategories(categories)
    setDataSeries(data)
    setChartTitle(props.chartTitle)
    setNameSeries(props.nameSeries)
    setDataLabels(props.dataLabels)
    setTooltipEnabled(props.tooltipEnabled)
  }, [props.chartReport])

  const options = {
    chart: {
      type: 'column',
      backgroundColor: 'transparent',
      zoomType: 'x'
    },
    colors: colors.pastel,
    title: { text: '' },
    xAxis: {
      allowDecimals: true,
      categories: categories,
    },
    yAxis: {
      min: 0,
      title: {
        text: ''
      }
    },
    credits: {
      enabled: false
    },
    tooltip: {
      enabled: tooltipEnabled,
      formatter: function () {
        return `<b>${this.x}</b><br /><span style="color:${this.color}">●</span> ${this.y}%`
      },
    },
    plotOptions: {
      series: {
        colorByPoint: true
      }
    },
    series: [{
      name: nameSeries,
      data: dataSeries,
      pointPadding: 0,
      groupPadding: 0,
      dataLabels: {
        enabled: dataLabels
      }
    }]
  }

  return (
    <div className="dash-chart-box-container">
      <div className="pst-rlt-h-100pc">
        {props.isLoading && <Loading />}
        <div className="dash-space-between">
          <div className="dash-chart-title">{chartTitle}</div>
          {dataSeries.length > 0 ?
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
            />
            : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ margin: '100px 0' }} />
          }
        </div>
      </div>
    </div>
  )
}

export default ChartColumn
