import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom';
import { InputNumber, Select, DatePicker, TimePicker, Checkbox } from 'antd';
import './SitePromotion.css'
import { LoadingOutlined } from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';

import moment from 'moment';
import 'moment/locale/th'

import { openNotification, checkIfIdEqualToZero } from '../../utils/function'
import { apiSGetSite, apiSGetMachineBySiteId, apiSGetProgramByMachineId, apiSAddDiscount } from '../../services/apis/site'

import { QueryGraphQL, GET_SITES, GET_MACHINES_BY_ID } from "../../resolvers/Query";
import useFilterMachine from '../../hooks/useFilterData';

const { Option } = Select;
const { RangePicker } = DatePicker;

export default function SitePromotionDiscountPageV2() {
  const history = useHistory();
  const { siteId } = useParams();
  const [isLoading, setIsLoading] = useState(false)
  const [sites, setSites] = useState([])
  const [machineTypes, setMachineTypes] = useState([])
  const [programs, setPrograms] = useState([])
  const [sitePromotion, setSitePromotion] = useState(undefined)
  const [selectAllMachine, setSelectAllMachine] = useState(false)

  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  const [isAlertSiteName, setIsAlertSiteName] = useState(false)
  const [isAlertProduct, setIsAlertProduct] = useState(false)
  const [isAlertMachineName, setIsAlertMachineName] = useState(false)
  const [isAlertDiscount, setIsAlertDiscount] = useState(false)
  const [isAlertPeriodPromotion, setIsAlertPeriodPromotion] = useState(false)
  const [isAlertLimit, setIsAlertLimit] = useState(false)

  const client = useApolloClient();
  const { getFilterMachine, getMapProgram} = useFilterMachine();

  const getSitesQuery = QueryGraphQL(GET_SITES);
  const sitesData = getSitesQuery?.data?.getSites;
  // console.log("data--GET_SITES-🐳🐳-", getSitesQuery);

  useEffect(() => {
    if (sitesData && sitesData.length > 0) {
      // console.log("get sites", sitesData);
      setSites((prevState) => {
        return sitesData.map(site => {
          return {site_id: Number(site.siteCode), site_name: site.site_name, uuid: site.id}
        });
      });
    }
  }, [sitesData]);

  useEffect(() => {
    const getStaticDropdown = async () => {
      let site = await apiSGetSite()
      // console.log("api get site", site)
      if (site && site.status) setSites(site.result || [])
    }
    // getStaticDropdown()

    setSitePromotion({
      ...sitePromotion, period_time: [
        {
          start_time: '00:00',
          end_time: '00:00'
        },
        {
          start_time: '00:00',
          end_time: '00:00'
        },
        {
          start_time: '00:00',
          end_time: '00:00'
        },
        {
          start_time: '00:00',
          end_time: '00:00'
        },
        {
          start_time: '00:00',
          end_time: '00:00'
        },
        {
          start_time: '00:00',
          end_time: '00:00'
        },
        {
          start_time: '00:00',
          end_time: '00:00'
        },
      ]
    })
  }, [])

  useEffect(() => {
    if (checkIfIdEqualToZero(sitePromotion && sitePromotion.site_id)) {
      const getMachineSubTypes = async () => {
        // let machineType = await apiSGetMachineBySiteId(sitePromotion.site_id)
        // console.log("get machine api", machineType)
        // if (machineType && machineType.status) setMachineTypes(machineType.result || [])
       // Use the client to send the query
        client.query({ query: GET_MACHINES_BY_ID,
          variables: {
            id: sitePromotion.site_uuid,
          }
        })  
        .then(response => {
          // console.log("get machine query", response.data)
          setMachineTypes((prevState) => {
            return response?.data?.getMachineBySite.map(item => {
              return {machine_id: item.deviceName, machine_name: item.machine_name, uuid: item.id, deviceName: item.deviceName, machine_run_mode: item.machine_run_mode}
            });
          });
        })
        .catch(error => console.error(error));
      }
      getMachineSubTypes()
    }
  }, [sitePromotion && sitePromotion.site_id])

  useEffect(() => {
    if (sitePromotion && sitePromotion.machine_type && sitePromotion.machine_type.length > 0) {
      const getMachineSubTypes = async () => {
        // let machineProgram = await apiSGetProgramByMachineId(sitePromotion.machine_type)
        // console.log("get program api", machineProgram)
        // if (machineProgram && machineProgram.status) setPrograms(machineProgram.result || [])
        // { program_id: 8, program_name:  "เพิ่มรอบล้าง" }

        // console.log("get filter selected machine", getFilterMachine(machineTypes, sitePromotion.machine_type));
        // console.log("map program", getMapProgram(getFilterMachine(machineTypes, sitePromotion.machine_type)));
        setPrograms((prevState) => {
          return getMapProgram(getFilterMachine(machineTypes, sitePromotion.machine_type)).map((m, i) => {
            return {...m, program_id: Number(m.mode), program_name: m.product_name}
          });
        });
      }
      getMachineSubTypes()
      // console.log("on machine type", sitePromotion.machine_type);
    }
  }, [sitePromotion && sitePromotion.machine_type])

  const addEditPromotion = async () => {
    if (
      !(checkIfIdEqualToZero(sitePromotion && sitePromotion.site_id)) ||
      !(sitePromotion && sitePromotion.machine_type && sitePromotion.machine_type.length > 0) ||
      !(sitePromotion && sitePromotion.program && sitePromotion.program.length > 0) ||
      isNaN(sitePromotion && sitePromotion.discount) ||
      !(sitePromotion && sitePromotion.period_promotion) ||
      isNaN(sitePromotion && sitePromotion.limit)
    ) {
      if (!(checkIfIdEqualToZero(sitePromotion && sitePromotion.site_id))) setIsAlertSiteName(true)
      if (!(sitePromotion && sitePromotion.machine_type && sitePromotion.machine_type.length > 0)) setIsAlertMachineName(true)
      if (!(sitePromotion && sitePromotion.program && sitePromotion.program.length > 0)) setIsAlertProduct(true)
      if (isNaN(sitePromotion && sitePromotion.discount)) setIsAlertDiscount(true)
      if (!(sitePromotion && sitePromotion.period_promotion)) setIsAlertPeriodPromotion(true)
      if (isNaN(sitePromotion && sitePromotion.limit)) setIsAlertLimit(true)

    } else {
      setIsLoading(true)

      let data = {
        site_id: sitePromotion.site_id,
        machine_id_list: sitePromotion.machine_type,
        program_id_list: sitePromotion.program,
        value: sitePromotion.discount,
        start_date: sitePromotion.period_promotion.start_date,
        end_date: sitePromotion.period_promotion.end_date,
        sunday_start: sitePromotion.period_time[0].all_day ? '00:00' : sitePromotion.period_time[0].start_time,
        sunday_end: sitePromotion.period_time[0].all_day ? '23:59' : sitePromotion.period_time[0].end_time,
        monday_start: sitePromotion.period_time[1].all_day ? '00:00' : sitePromotion.period_time[1].start_time,
        monday_end: sitePromotion.period_time[1].all_day ? '23:59' : sitePromotion.period_time[1].end_time,
        tuesday_start: sitePromotion.period_time[2].all_day ? '00:00' : sitePromotion.period_time[2].start_time,
        tuesday_end: sitePromotion.period_time[2].all_day ? '23:59' : sitePromotion.period_time[2].end_time,
        wednesday_start: sitePromotion.period_time[3].all_day ? '00:00' : sitePromotion.period_time[3].start_time,
        wednesday_end: sitePromotion.period_time[3].all_day ? '23:59' : sitePromotion.period_time[3].end_time,
        thursday_start: sitePromotion.period_time[4].all_day ? '00:00' : sitePromotion.period_time[4].start_time,
        thursday_end: sitePromotion.period_time[4].all_day ? '23:59' : sitePromotion.period_time[4].end_time,
        friday_start: sitePromotion.period_time[5].all_day ? '00:00' : sitePromotion.period_time[5].start_time,
        friday_end: sitePromotion.period_time[5].all_day ? '23:59' : sitePromotion.period_time[5].end_time,
        saturday_start: sitePromotion.period_time[6].all_day ? '00:00' : sitePromotion.period_time[6].start_time,
        saturday_end: sitePromotion.period_time[6].all_day ? '23:59' : sitePromotion.period_time[6].end_time,
        limit: sitePromotion.limit,
        multiple: sitePromotion.multiple && true || false
      }

      let newData = await apiSAddDiscount(data)
      if (newData && newData.status) {
        setIsLoading(false)
        openNotification('success', 'Success !', 'Add new discount successful.')
        setTimeout(() => history.push('/site-promotion/list'), 1000);
      } else {
        setIsLoading(false)
        openNotification('error', 'Failed !', 'Failed to add new discount.')
      }
    }
  }

  return (
    <div>
      <div className="mg-bt-20 h-d-t-p">Site Promotion - Discount</div>

      <div className="t-m-c-i-p-g">
        <div className="f-s-c">Site Name</div>
        <div className={isAlertSiteName && 'antd-sl-al-bd' || ''}>
          <Select style={{ width: '100%' }}
            placeholder="Site Name"
            showSearch
            value={sitePromotion && sitePromotion.site_id}
            onChange={(value, option) => {
              setIsAlertSiteName(false)
              setSitePromotion({
                ...sitePromotion,
                site_id: value,
                site_uuid: option.uuid,
                machine_type: [],
                program: []
              })
            }}
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {sites.map((site, idx) => <Option key={idx} value={site.site_id} uuid={site.uuid}>{site.site_name}</Option>)}
          </Select>
        </div>

        <div className="f-s-c">Machine Name</div>
        <div className={isAlertMachineName && 'antd-sl-al-bd' || ''}>
          <Select style={{ width: '100%' }}
            dropdownRender={menu => <div>
              <div className="f-s-c antd-chb-fz" style={{ padding: '5px 10px' }}>
                <Checkbox
                  checked={selectAllMachine}
                  onChange={e => {
                    setSelectAllMachine(e.target.checked)
                    setIsAlertMachineName(false)

                    if (e.target.checked) {
                      let machine = machineTypes.map(mid => mid.machine_id)
                      setSitePromotion({ ...sitePromotion, machine_type: machine })
                    } else setSitePromotion({ ...sitePromotion, machine_type: [] })
                  }}
                >Select All</Checkbox>
              </div>
              {menu}
            </div>}
            placeholder="Machine Name"
            disabled={!(checkIfIdEqualToZero(sitePromotion && sitePromotion.site_id))}
            mode="multiple"
            showArrow
            value={sitePromotion && sitePromotion.machine_type || []}
            onChange={value => {
              console.log("select machine ", value)
              setIsAlertMachineName(false)
              setSitePromotion({
                ...sitePromotion,
                machine_type: value,
                program: []
              })
              setSelectAllMachine(machineTypes.length === value.length)
            }}
          >
            {machineTypes.map((machine, idx) => <Option key={idx} value={machine.machine_id}>{machine.machine_name}</Option>)}
          </Select>
        </div>

        <div className="f-s-c">Product / Program</div>
        <div className={isAlertProduct && 'antd-sl-al-bd' || ''}>
          <Select style={{ width: '100%' }}
            placeholder="Product / Program"
            disabled={!(sitePromotion && sitePromotion.machine_type && sitePromotion.machine_type.length > 0)}
            mode="multiple"
            showArrow
            value={sitePromotion && sitePromotion.program || []}
            onChange={value => {
              setIsAlertProduct(false)
              setSitePromotion({ ...sitePromotion, program: value })
            }}
          >
            {programs.map((program, idx) => <Option key={idx} value={program.program_id}>{program.program_name}</Option>)}
          </Select>
        </div>

        <div className="f-s-c">Discount ( THB )</div>
        <div>
          <InputNumber min={0}
            step={0.01}
            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
            style={{ width: '100%', borderColor: isAlertDiscount ? '#EC1C24' : null }}
            placeholder="Discount"
            value={sitePromotion && sitePromotion.discount}
            onChange={value => {
              setIsAlertDiscount(false)
              setSitePromotion({ ...sitePromotion, discount: Math.round(value * 100) / 100 || 0 })
            }}
          />
        </div>

        <div className="f-s-c">Period Promotion</div>
        <div>
          <RangePicker
            style={{ width: '100%', borderColor: isAlertPeriodPromotion ? '#EC1C24' : null }}
            placeholder={["Period Start", "Period End"]}
            format={date => moment(date).add(543, 'years').format('D MMM YYYY')}
            allowClear={false}
            value={[
              sitePromotion && sitePromotion.period_promotion && sitePromotion.period_promotion.start_date && moment(sitePromotion.period_promotion.start_date, 'YYYY-MM-DD'),
              sitePromotion && sitePromotion.period_promotion && sitePromotion.period_promotion.end_date && moment(sitePromotion.period_promotion.end_date, 'YYYY-MM-DD')
            ]}
            onChange={date => {
              setIsAlertPeriodPromotion(false)

              let newSitePromotion = { ...sitePromotion }
              if (!newSitePromotion.period_promotion) newSitePromotion.period_promotion = {}

              newSitePromotion.period_promotion.start_date = moment(date[0]).format('YYYY-MM-DD')
              newSitePromotion.period_promotion.end_date = moment(date[1]).format('YYYY-MM-DD')
              setSitePromotion(newSitePromotion)
            }}
            disabledDate={current => current && current < moment().startOf('day')}
          />
        </div>

        <div className="f-s-c">Period Time</div>
        <div>
          {days.map((day, idx) =>
            <div key={idx} className="site-pro-discount-period-time-grid">
              <div className="site-pro-discount-period-time-day-grid site-pro-discount-period-time-grid-day site-pro-discount-period-time-order-1">
                <div className="f-s-c">
                  <i className="bi bi-calendar-range-fill"></i>
                </div>
                <div className="f-s-c">{day}</div>
              </div>
              <div className="site-pro-discount-period-time-grid-time site-pro-discount-period-time-order-3">
                <TimePicker.RangePicker
                  style={{ width: '100%' }}
                  placeholder={["Period Time Start", "Period Time End"]}
                  format={'HH:mm'}
                  allowClear={false}
                  value={sitePromotion && sitePromotion.period_time && sitePromotion.period_time[idx] && sitePromotion.period_time[idx].all_day
                    ? [moment('00:00', 'HH:mm'), moment('23:59', 'HH:mm')]
                    : [
                      sitePromotion && sitePromotion.period_time && sitePromotion.period_time[idx] && sitePromotion.period_time[idx].start_time && moment(sitePromotion.period_time[idx].start_time, 'HH:mm'),
                      sitePromotion && sitePromotion.period_time && sitePromotion.period_time[idx] && sitePromotion.period_time[idx].end_time && moment(sitePromotion.period_time[idx].end_time, 'HH:mm')
                    ]}
                  onChange={date => {
                    let newSitePromotion = { ...sitePromotion }
                    if (!newSitePromotion.period_time) newSitePromotion.period_time = []
                    if (!newSitePromotion.period_time[idx]) newSitePromotion.period_time[idx] = {}

                    let st = moment(date[0]).format('HH:mm')
                    let ed = moment(date[1]).format('HH:mm')

                    newSitePromotion.period_time[idx].start_time = st
                    newSitePromotion.period_time[idx].end_time = ed
                    newSitePromotion.period_time[idx].all_day = st === '00:00' && ed === '23:59'
                    setSitePromotion(newSitePromotion)
                  }}
                />
              </div>
              <div className="f-s-c antd-chb-fz site-pro-discount-all-day site-pro-discount-period-time-order-2">
                <Checkbox
                  checked={sitePromotion && sitePromotion.period_time && sitePromotion.period_time[idx] && sitePromotion.period_time[idx].all_day}
                  onChange={e => {
                    let newSitePromotion = { ...sitePromotion }
                    if (!newSitePromotion.period_time) newSitePromotion.period_time = []
                    if (!newSitePromotion.period_time[idx]) newSitePromotion.period_time[idx] = {}

                    newSitePromotion.period_time[idx].all_day = e.target.checked
                    setSitePromotion(newSitePromotion)
                  }}
                >24 Hours</Checkbox>
              </div>
            </div>
          )}
        </div>

        <div className="f-s-c">จำนวนครั้ง</div>
        <div>
          <InputNumber min={0} max={1000}
            step={1}
            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
            style={{ width: '100%', borderColor: isAlertLimit ? '#EC1C24' : null }}
            placeholder="จำนวนครั้ง"
            value={sitePromotion && sitePromotion.limit}
            onChange={value => {
              setIsAlertLimit(false)
              setSitePromotion({ ...sitePromotion, limit: Math.round(value) || 0 })
            }}
          />
        </div>

        <div className="f-s-c">Multiple</div>
        <div className="f-s-c">
          <Checkbox
            checked={sitePromotion && sitePromotion.multiple}
            onChange={e => setSitePromotion({ ...sitePromotion, multiple: e.target.checked })}
          />
        </div>
      </div>

      <div className="f-c-c s-e-b">
        <div className="f-c-c d-b-t-n-s d-bg-c-t"
          style={{ width: '190px', pointerEvents: isLoading ? 'none' : null }}
          // onClick={() => addEditPromotion()}
        >{isLoading ? <LoadingOutlined /> : `${siteId ? 'Edit' : 'Add New'} Discount`}</div>
      </div>

    </div>
  )
}
