import React, { useState, useEffect } from 'react'
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { Input, Select, Avatar, Modal } from 'antd';
import './Maintenance.css'
import { LoadingOutlined } from '@ant-design/icons';

import { checkImageSize, openNotification, getSearchParams, checkIfIdEqualToZero } from '../../utils/function'
import { apiSGetSite } from '../../services/apis/site'
import {
  apiMTNGetType,
  apiMTNGetMaintainer,
  apiMTNGetStatus,
  apiMTNGetTicketByMaintenanceId,
  apiMTNAddTicket,
  apiMTNEditTicketById,
  apiMTNGetMachineBySiteId
} from '../../services/apis/maintenance'

const { Option } = Select;

const TicketMaintenanceAddEdit = () => {
  const { maintenanceId } = useParams();
  const location = useLocation();
  const history = useHistory();

  const params = new URLSearchParams(location.search)
  const p = params.get(maintenanceId ? 'pdp' : 'olp')
  const ttl = params.get(maintenanceId ? 'pdttl' : 'olttl')
  const mtn = params.get(maintenanceId ? 'pdmtn' : 'olmtn')
  const mch = params.get(maintenanceId ? 'pdmch' : 'olmch')
  const s = params.get(maintenanceId ? 'pds' : 'ols')
  const sw = params.get(maintenanceId ? 'pdsw' : 'olsw')
  const ats = params.get(maintenanceId ? 'pdats' : 'olats')
  const atmch = params.get(maintenanceId ? 'pdatmch' : 'olatmch')

  const [isLoading, setIsLoading] = useState(false)
  const [maintenance, setMaintenance] = useState(undefined)

  const [sites, setSites] = useState([])
  const [machines, setMachines] = useState([])
  const [maintenances, setMaintenances] = useState([])
  const [assignees, setAssignees] = useState([])
  const [statuses, setStatuses] = useState([])

  const [modalImageIdx, setModalImageIdx] = useState(0)
  const [visible, setVisible] = useState(false)

  const [isAlertSite, setIsAlertSite] = useState(false)
  const [isAlertMachine, setIsAlertMachine] = useState(false)
  const [isAlertType, setIsAlertType] = useState(false)
  const [isAlertAssignee, setIsAlertAssignee] = useState(false)
  const [isAlertDetail, setIsAlertDetail] = useState(false)

  useEffect(() => {
    if (maintenanceId) getData()
    else {
      setMaintenance(undefined)
      setIsAlertSite(false)
      setIsAlertMachine(false)
      setIsAlertType(false)
      setIsAlertAssignee(false)
      setIsAlertDetail(false)
    }
  }, [maintenanceId])

  useEffect(() => {
    if (!maintenance) setMaintenance({ ...maintenance, images: [] })
  }, [maintenance])

  const getData = async () => {
    let data = await apiMTNGetTicketByMaintenanceId(maintenanceId)
    if (data && data.status) {
      let newData = { ...data.result }
      newData.maintainer_list = data.result.maintainer_list.split(',').map(list => Number(list));
      newData.machine_list = data.result.machine_list.split(',').map(list => Number(list));
      newData.images = (data.result.image_list || []).map(image => {
        return {
          ticket_image: image,
          ticket_image_file: undefined
        }
      })

      setMaintenance(newData)
    }
  }

  useEffect(() => {
    if (ats && atmch) setMaintenance({
      ...maintenance,
      site_id: Number(ats),
      machine_list: [Number(atmch)]
    })

    const getStaticDropdown = async () => {
      let site = await apiSGetSite()
      if (site && site.status) setSites(site.result || [])

      let type = await apiMTNGetType()
      if (type && type.status) setMaintenances(type.result || [])

      let assignee = await apiMTNGetMaintainer()
      if (assignee && assignee.status) setAssignees(assignee.result || [])

      let status = await apiMTNGetStatus()
      if (status && status.status) setStatuses(status.result || [])
    }
    getStaticDropdown()
  }, [])

  useEffect(() => {
    if (checkIfIdEqualToZero(maintenance && maintenance.site_id)) {
      const getMachine = async () => {
        let status = await apiMTNGetMachineBySiteId(maintenance && maintenance.site_id)
        if (status && status.status) setMachines(status.result || [])
      }
      getMachine()
    }
  }, [maintenance && maintenance.site_id])

  const addEditData = async () => {
    if (
      !(checkIfIdEqualToZero(maintenance && maintenance.site_id)) ||
      !(maintenance && maintenance.machine_list && maintenance.machine_list.length) ||
      !(maintenance && maintenance.maintenance_type_id) ||
      !(maintenance && maintenance.maintainer_list && maintenance.maintainer_list.length) ||
      !(maintenance && maintenance.detail)
    ) {
      if (!(checkIfIdEqualToZero(maintenance && maintenance.site_id))) setIsAlertSite(true)
      if (!(maintenance && maintenance.machine_list && maintenance.machine_list.length)) setIsAlertMachine(true)
      if (!(maintenance && maintenance.maintenance_type_id)) setIsAlertType(true)
      if (!(maintenance && maintenance.maintainer_list && maintenance.maintainer_list.length)) setIsAlertAssignee(true)
      if (!(maintenance && maintenance.detail)) setIsAlertDetail(true)

    } else {
      setIsLoading(true)

      let data = new FormData()
      if (maintenanceId) data.append('maintenance_id', maintenanceId)
      if (maintenanceId) data.append('status_id', maintenance.status_id || 0)
      data.append('admin_id', localStorage.getItem('user_id'))
      data.append('site_id', maintenance.site_id)
      data.append('machine_list', maintenance.machine_list.join(','))
      data.append('maintenance_type_id', maintenance.maintenance_type_id)
      data.append('maintainer_list', maintenance.maintainer_list.join(','))
      data.append('detail', maintenance.detail);
      data.append('remark', maintenance.remark);

      (maintenance.images || []).map(image => {
        if (image.ticket_image_file) data.append('image_list', image.ticket_image_file)
      })

      let newData = maintenanceId ? await apiMTNEditTicketById(data) : await apiMTNAddTicket(data)
      if (newData && newData.status) {
        setIsLoading(false)
        openNotification('success', 'Success !', `${maintenanceId ? 'Edit' : 'Add new'} maintenance ticket successful.`)
        setTimeout(() => history.push(`/maintenance/ticket${getSearchParams(['p', 'ttl', 'mtn', 'mch', 's', 'sw'], [p, ttl, mtn, mch, s, sw])}`), 1000);
      } else {
        setIsLoading(false)
        openNotification('error', 'Failed !', `Failed to ${maintenanceId ? 'edit' : 'add new'} maintenance ticket.`)
      }
    }
  }

  return (
    <div>
      <div className="mg-bt-20 h-d-t-p">Maintenance - แจ้งซ่อม</div>

      <div className="t-m-c-i-p-g">
        <div className="f-s-c">Site</div>
        <div className={isAlertSite && 'antd-sl-al-bd' || ''}>
          <Select style={{ width: '100%' }}
            placeholder="Site"
            showSearch
            value={maintenance && maintenance.site_id}
            onChange={value => {
              setIsAlertSite(false)
              setMaintenance({
                ...maintenance,
                site_id: value,
                machine_list: []
              })
            }}
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            disabled={maintenanceId}
          >
            {sites.map((site, idx) => <Option key={idx} value={site.site_id}>{site.site_name}</Option>)}
          </Select>
        </div>

        <div className="f-s-c">Machine</div>
        <div className={isAlertMachine && 'antd-sl-al-bd' || ''}>
          <Select style={{ width: '100%' }}
            placeholder="Machine"
            mode="multiple"
            showArrow
            value={maintenance && maintenance.machine_list || []}
            onChange={value => {
              setIsAlertMachine(false)
              setMaintenance({ ...maintenance, machine_list: value })
            }}
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            disabled={maintenanceId || (!(checkIfIdEqualToZero(maintenance && maintenance.site_id)))}
          >
            {machines.map((machine, idx) => <Option key={idx} value={machine.machine_id}>{machine.machine_name}</Option>)}
          </Select>
        </div>

        <div className="f-s-c">Ticket Type</div>
        <div className={isAlertType && 'antd-sl-al-bd' || ''}>
          <Select style={{ width: '100%' }}
            placeholder="Ticket Type"
            value={maintenance && maintenance.maintenance_type_id}
            onChange={value => {
              setIsAlertType(false)
              setMaintenance({ ...maintenance, maintenance_type_id: value })
            }}
            disabled={maintenanceId}
          >
            {maintenances.map((maintenance, idx) => <Option key={idx} value={maintenance.maintenance_type_id}>{maintenance.maintenance_type_name}</Option>)}
          </Select>
        </div>

        <div className="f-s-c">Assignee</div>
        <div className={isAlertAssignee && 'antd-sl-al-bd' || ''}>
          <Select style={{ width: '100%' }}
            placeholder="Assignee"
            mode="multiple"
            showArrow
            value={maintenance && maintenance.maintainer_list || []}
            onChange={value => {
              setIsAlertAssignee(false)
              setMaintenance({ ...maintenance, maintainer_list: value })
            }}
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {assignees.map((assignee, idx) => <Option key={idx} value={assignee.maintainer_id}>{assignee.maintainer_name}</Option>)}
          </Select>
        </div>

        <div className="f-s-c">อาการ</div>
        <div>
          <Input.TextArea
            autoSize={{ minRows: 3 }}
            style={{ borderColor: isAlertDetail ? '#EC1C24' : null }}
            placeholder="อาการ"
            value={maintenance && maintenance.detail || ''}
            onChange={e => {
              setIsAlertDetail(false)
              setMaintenance({ ...maintenance, detail: e.target.value })
            }}
          />
        </div>

        <div className="f-s-c">หมายเหตุ</div>
        <div>
          <Input.TextArea
            autoSize={{ minRows: 3 }}
            placeholder="หมายเหตุ"
            value={maintenance && maintenance.remark || ''}
            onChange={e => setMaintenance({ ...maintenance, remark: e.target.value })}
          />
        </div>

        {maintenanceId && <div className="f-s-c">Status</div>}
        {maintenanceId && <div>
          <Select style={{ width: '100%' }}
            placeholder="Status"
            value={maintenance && maintenance.status_id}
            onChange={value => setMaintenance({ ...maintenance, status_id: value })}
          >
            {statuses.map((status, idx) => <Option key={idx} value={status.maintenance_status_id}>{status.maintenance_status_name}</Option>)}
          </Select>
        </div>}

        <div className="f-s-c">Image</div>
        <div className="dpl-im-l-a-ip-f">
          {(maintenance && maintenance.images || []).map((image, idx) => <div key={idx}
            className="pst-rlt"
            onClick={() => {
              setVisible(true)
              setModalImageIdx(idx)
            }}
          >
            {image.ticket_image_file && <div className="f-c-c dpl-im-l-dl-ic"
              onClick={e => {
                e.stopPropagation()
                let newSite = { ...maintenance }
                newSite.images = maintenance.images.filter(img => img.ticket_image !== image.ticket_image)
                setMaintenance(newSite)
              }}
            ><i className="bi bi-x"></i></div>}
            <Avatar shape="square" size={110} className="cs-pt" src={image.ticket_image || ''} />
          </div>
          )}
          <div>
            <input id="maintenance-ticket-image" accept="image/*" type="file" multiple style={{ display: 'none' }}
              onChange={e => {
                let files = [...e.target.files]
                let passSize = files.map(file => { return { file, canUse: checkImageSize(false, file) } })
                let found = passSize.find(element => !element.canUse);
                if (found) openNotification('warning', 'ไม่สามารถอัพโหลดบางภาพได้', 'รูปภาพที่มีขนาดเกิน 2MB จะไม่ถูกอัพโหลด')

                passSize.map(image => {
                  if (image.canUse) {
                    const reader = new FileReader()
                    reader.readAsDataURL(image.file)
                    reader.onload = () => {
                      let newSite = { ...maintenance }
                      newSite.images.push({
                        ticket_image: reader.result,
                        ticket_image_file: image.file,
                      })
                      setMaintenance(newSite)
                    }
                  }
                })
              }} />
            <label htmlFor="maintenance-ticket-image">
              <div>
                <Avatar shape="square" size={110} className="cs-pt" icon={<i className="bi bi-image"></i>} />
              </div>
            </label>
          </div>
        </div>
      </div>

      <div className="f-c-c s-e-b">
        <div className="f-c-c d-b-t-n-s d-bg-c-t"
          style={{ width: '100px', pointerEvents: isLoading ? 'none' : null }}
          onClick={() => addEditData()}
        >{isLoading ? <LoadingOutlined /> : 'Submit'}</div>
      </div>

      <Modal
        title=""
        visible={visible}
        closable={false}
        footer={false}
        centered
      >
        <div className="md-ctn-crs-im-l">
          <div className="f-c-c md-ctn-crs-im-ar" onClick={() => modalImageIdx > 0 ? setModalImageIdx(modalImageIdx - 1) : null}>
            <i className="fas fa-chevron-left"
              style={modalImageIdx > 0 ? null : { opacity: '0.7', cursor: 'not-allowed' }}
            ></i>
          </div>
          <div className="f-c-c md-ctn-crs-im">
            <img src={maintenance && maintenance.images && maintenance.images.length > 0 && maintenance.images[modalImageIdx] && maintenance.images[modalImageIdx].ticket_image} />
          </div>
          <div className="f-c-c md-ctn-crs-im-ar"
            onClick={() => modalImageIdx < (maintenance && maintenance.images && maintenance.images.length) - 1 ? setModalImageIdx(modalImageIdx + 1) : null}
          >
            <i className="fas fa-chevron-right"
              style={modalImageIdx < (maintenance && maintenance.images && maintenance.images.length) - 1 ? null : { opacity: '0.7', cursor: 'not-allowed' }}
            ></i>
          </div>
        </div>

        <div className="f-c-c d-b-t-n-s d-bg-c-t md-btn-ol-cc"
          onClick={() => setVisible(false)}
        >Cancel</div>
      </Modal>

    </div>
  )
}

export default TicketMaintenanceAddEdit
