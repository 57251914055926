import React, { useState, useEffect } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { Col, Row, Input, InputNumber, Radio, Select, Space, Button, Typography } from "antd";
import { useApolloClient } from "@apollo/client";
import "./Machine.css";
import { LoadingOutlined } from "@ant-design/icons";
import { GET_LG_MACHINE_SETTINGS } from "../../resolvers/Query";
import { UPDATE_LG_WASHER_SETTINGS } from "../../resolvers/Mutation";
import moment from "moment";
import "moment/locale/th";

const washerDefaultSetting = {
    washingTime: [8, 8, 8, 8],                // 8, 5 - 15
    waterLevel: [1, 1, 1, 1],                 // 1, 1 - 3
    rinsingTime: [8, 8, 8, 8],                // 8, 8 - 16
    rinsingCount: [2, 2, 2, 2],               // 2, 1 - 5
    spinSpeed: ["Max", "Max", "Max", "Max"],  // Max, "800" - "1000", Max
    dropCount: 50,                            // 50 25 - 50
    twinSpray: "0",                           // '0', '0' - '1'
    price: [8, 8, 10, 12, 14],                // 0 - 199
    coin: 2,                                  // 5, 1, 2, 5, 20
    addSuperWash: 4,                          // 5, 0 - 199
    nonStopRinsing: false,                    //
    
}

const { Title,  Text} = Typography;

const LgWasherSettings = ({deviceId}) => {

    const { machineId } = useParams();
    const location = useLocation();
    const history = useHistory();
    const apolloClient = useApolloClient();

    const [lgDevice, setLgDevice] = useState();
    const [selectValue, setSelectValue] = React.useState("");
    const [isLoading, setIsLoading] = useState(false);

    let machine_sub_group = "LG commercial";
    
    useEffect(async() => {
        getSettings();
        return () => {
            setLgDevice()
        }
    }, []);

    const getSettings = async (event) => {
        const response = await apolloClient.query({ 
            query: GET_LG_MACHINE_SETTINGS,
            variables: { lgMachineId: deviceId },
            fetchPolicy: "no-cache"
        });
        // console.log("getLGMachineSettings", response)
        if (response?.data?.getLGMachineSettings) {
            const { getLGMachineSettings } = response.data;
            setLgDevice((prevState) => {
                return {
                    washingTime: getLGMachineSettings.washingTime,
                    waterLevel: getLGMachineSettings.waterLevel,
                    rinsingTime: getLGMachineSettings.rinsingTime,
                    rinsingCount: getLGMachineSettings.rinsingCount,
                    spinSpeed: getLGMachineSettings.spinSpeed,
                    dropCount: getLGMachineSettings.dropCount,
                    twinSpray: getLGMachineSettings.twinSpray,
                    price: getLGMachineSettings.price,
                    coin: getLGMachineSettings.coin,
                    addSuperWash: getLGMachineSettings.addSuperWash,
                    nonStopRinsing: getLGMachineSettings.nonStopRinsing,  
                };
            })
        }
    };

    const saveSettings = async (event) => {
        const formData = {
            ...lgDevice,
            lgMachineId: deviceId
        }
        try {
            const response = await apolloClient.mutate({ 
                mutation: UPDATE_LG_WASHER_SETTINGS,
                variables: formData
            });
            if (response.data.updateLGWasherSettings) {
                setLgDevice();
                history.goBack();
            }
        } catch (error) {
            console.log("Error : ", error)
        }
    };

    return (
        <div>

            <div className="machine-add-container-grid">
                <div className="f-s-c"></div>
                <Row justify="end">
                    <Button type="primary" onClick={() => {
                        setLgDevice(washerDefaultSetting)
                    }}>Set Default</Button>
                </Row>

                <div className="f-s-c">หมายเลขเครื่องซัก</div>
                <div>
                    <Input
                        value={deviceId || ""}
                        disabled
                    />
                </div>
                <Title level={5} >เวลาการซัก</Title>
                <Row gutter={[16, 8]}>
                    <Col className="gutter-row" span={12}>
                        <div className="f-s-c" style={{marginLeft: ".25em"}}>น้ำร้อน</div>
                        <InputNumber min={5} max={15}  style={{ width: '100%' }} 
                        value={lgDevice?.washingTime.at(0)} 
                        onChange={(v) =>{
                            setLgDevice((prevState) => {
                                const updateArray = [...lgDevice.washingTime];
                                updateArray[0] = v;
                                return {
                                    ...prevState,
                                    washingTime: updateArray
                                }
                            })
                        }} />
                    </Col>
                    <Col span={12}>
                        <div className="f-s-c" style={{marginLeft: ".25em"}}>น้ำอุ่น</div>
                        <InputNumber min={5} max={15} style={{ width: '100%' }} 
                        value={lgDevice?.washingTime.at(1)}
                        onChange={(v) =>{
                            setLgDevice((prevState) => {
                                const updateArray = [...lgDevice.washingTime];
                                updateArray[1] = v;
                                return {
                                    ...prevState,
                                    washingTime: updateArray
                                }
                            })
                        }} />
                    </Col>
                    <Col span={12}>
                        <div className="f-s-c" style={{marginLeft: ".25em"}}>น้ำเย็น</div>
                        <InputNumber min={5} max={15}  style={{ width: '100%' }} 
                        value={lgDevice?.washingTime.at(2)}
                        onChange={(v) =>{
                            setLgDevice((prevState) => {
                                const updateArray = [...lgDevice.washingTime];
                                updateArray[2] = v;
                                return {
                                    ...prevState,
                                    washingTime: updateArray
                                }
                            })
                        }} />
                    </Col>
                    <Col span={12}>
                        <div className="f-s-c" style={{marginLeft: ".25em"}}>ถนอมผ้า</div>
                        <InputNumber min={5} max={15} style={{ width: '100%' }} 
                        value={lgDevice?.washingTime.at(3)}
                        onChange={(v) =>{
                            setLgDevice((prevState) => {
                                const updateArray = [...lgDevice.washingTime];
                                updateArray[3] = v;
                                return {
                                    ...prevState,
                                    washingTime: updateArray
                                }
                            })
                        }} />
                    </Col>
                </Row>

                <Title level={5}>ระดับน้ำ</Title>
                <Row gutter={[16, 8]}>
                    <Col className="gutter-row" span={12}>
                        <div className="f-s-c" style={{marginLeft: ".25em"}}>น้ำร้อน</div>
                        <InputNumber min={1} max={3}  style={{ width: '100%' }}
                        value={lgDevice?.waterLevel.at(0)} 
                        onChange={(v) =>{
                            setLgDevice((prevState) => {
                                const updateArray = [...lgDevice.waterLevel];
                                updateArray[0] = v;
                                return {
                                    ...prevState,
                                    waterLevel: updateArray
                                }
                            })
                        }} />
                    </Col>
                    <Col span={12}>
                        <div className="f-s-c" style={{marginLeft: ".25em"}}>น้ำอุ่น</div>
                        <InputNumber min={1} max={3}  style={{ width: '100%' }} 
                        value={lgDevice?.waterLevel.at(1)} 
                        onChange={(v) =>{
                            setLgDevice((prevState) => {
                                const updateArray = [...lgDevice.waterLevel];
                                updateArray[1] = v;
                                return {
                                    ...prevState,
                                    waterLevel: updateArray
                                }
                            })
                        }} />
                    </Col>
                    <Col span={12}>
                        <div className="f-s-c" style={{marginLeft: ".25em"}}>น้ำเย็น</div>
                        <InputNumber min={1} max={3}  style={{ width: '100%' }} 
                        value={lgDevice?.waterLevel.at(2)} 
                        onChange={(v) =>{
                            setLgDevice((prevState) => {
                                const updateArray = [...lgDevice.waterLevel];
                                updateArray[2] = v;
                                return {
                                    ...prevState,
                                    waterLevel: updateArray
                                }
                            })
                        }} />
                    </Col>
                    <Col span={12}>
                        <div className="f-s-c" style={{marginLeft: ".25em"}}>ถนอมผ้า</div>
                        <InputNumber min={1} max={3}  style={{ width: '100%' }}
                        value={lgDevice?.waterLevel.at(3)} 
                        onChange={(v) =>{
                            setLgDevice((prevState) => {
                                const updateArray = [...lgDevice.waterLevel];
                                updateArray[3] = v;
                                return {
                                    ...prevState,
                                    waterLevel: updateArray
                                }
                            })
                        }} />
                    </Col>
                </Row>

                <Title level={5}>เวลาการล้างน้ำ</Title>
                <Row gutter={[16, 8]}>
                    <Col className="gutter-row" span={12}>
                        <div className="f-s-c"  style={{marginLeft: ".25em"}}>น้ำร้อน</div>
                        <InputNumber min={8} max={16}  style={{ width: '100%' }} 
                        value={lgDevice?.rinsingTime.at(0)} 
                        onChange={(v) =>{
                            setLgDevice((prevState) => {
                                const updateArray = [...lgDevice.rinsingTime];
                                updateArray[0] = v;
                                return {
                                    ...prevState,
                                    rinsingTime: updateArray
                                }
                            })
                        }} />
                    </Col>
                    <Col span={12}>
                        <div className="f-s-c"style={{marginLeft: ".25em"}}>น้ำอุ่น</div>
                        <InputNumber min={8} max={16}  style={{ width: '100%' }} 
                        value={lgDevice?.rinsingTime.at(1)} 
                        onChange={(v) =>{
                            setLgDevice((prevState) => {
                                const updateArray = [...lgDevice.rinsingTime];
                                updateArray[1] = v;
                                return {
                                    ...prevState,
                                    rinsingTime: updateArray
                                }
                            })
                        }} />
                    </Col>
                    <Col span={12}>
                        <div className="f-s-c" style={{marginLeft: ".25em"}}>น้ำเย็น </div>
                        <InputNumber min={8} max={16}  style={{ width: '100%' }} 
                        value={lgDevice?.rinsingTime.at(2)} 
                        onChange={(v) =>{
                            setLgDevice((prevState) => {
                                const updateArray = [...lgDevice.rinsingTime];
                                updateArray[2] = v;
                                return {
                                    ...prevState,
                                    rinsingTime: updateArray
                                }
                            })
                        }} />
                    </Col>
                    <Col span={12}>
                        <div className="f-s-c" style={{marginLeft: ".25em"}}>ถนอมผ้า</div>
                        <InputNumber min={8} max={16} style={{ width: '100%' }} 
                        value={lgDevice?.rinsingTime.at(3)} 
                        onChange={(v) =>{
                            setLgDevice((prevState) => {
                                const updateArray = [...lgDevice.rinsingTime];
                                updateArray[3] = v;
                                return {
                                    ...prevState,
                                    rinsingTime: updateArray
                                }
                            })
                        }} />
                    </Col>
                </Row>

                <Title level={5}>จำนวนครั้งการล้างน้ำ</Title>
                <Row gutter={[16, 8]}>
                    <Col className="gutter-row" span={12}>
                        <div className="f-s-c" style={{marginLeft: ".25em"}}>น้ำร้อน</div>
                        <InputNumber min={1} max={5}  style={{ width: '100%' }} 
                         value={lgDevice?.rinsingCount.at(0)} 
                         onChange={(v) =>{
                             setLgDevice((prevState) => {
                                 const updateArray = [...lgDevice.rinsingCount];
                                 updateArray[0] = v;
                                 return {
                                     ...prevState,
                                     rinsingCount: updateArray
                                 }
                             })
                         }} />
                    </Col>
                    <Col span={12}>
                        <div className="f-s-c" style={{marginLeft: ".25em"}}>น้ำอุ่น</div>
                        <InputNumber min={1} max={5}  style={{ width: '100%' }}
                        value={lgDevice?.rinsingCount.at(1)} 
                        onChange={(v) =>{
                            setLgDevice((prevState) => {
                                const updateArray = [...lgDevice.rinsingCount];
                                updateArray[1] = v;
                                return {
                                    ...prevState,
                                    rinsingCount: updateArray
                                }
                            })
                        }} />
                    </Col>
                    <Col span={12}>
                        <div className="f-s-c" style={{marginLeft: ".25em"}}>น้ำเย็น</div>
                        <InputNumber min={1} max={5}  style={{ width: '100%' }} 
                        value={lgDevice?.rinsingCount.at(2)} 
                        onChange={(v) =>{
                            setLgDevice((prevState) => {
                                const updateArray = [...lgDevice.rinsingCount];
                                updateArray[2] = v;
                                return {
                                    ...prevState,
                                    rinsingCount: updateArray
                                }
                            })
                        }} />
                    </Col>
                    <Col span={12}>
                        <div className="f-s-c" style={{marginLeft: ".25em"}}>ถนอมผ้า</div>
                        <InputNumber min={1} max={5} style={{ width: '100%' }} 
                        value={lgDevice?.rinsingCount.at(3)} 
                        onChange={(v) =>{
                            setLgDevice((prevState) => {
                                const updateArray = [...lgDevice.rinsingCount];
                                updateArray[3] = v;
                                return {
                                    ...prevState,
                                    rinsingCount: updateArray
                                }
                            })
                        }} />
                    </Col>
                </Row>

                <Title level={5}>จำนวนรอบการหมุนสูงสุดต่อนาที</Title>
                <Row gutter={[16, 8]}>
                    <Col className="gutter-row" span={12}>
                        <div className="f-s-c" style={{marginLeft: ".25em"}}>น้ำร้อน</div>
                        <Input  style={{ width: '100%' }} 
                        value={lgDevice?.spinSpeed.at(0)} 
                        onChange={(e) =>{
                            setLgDevice((prevState) => {
                                const updateArray = [...lgDevice.spinSpeed];
                                updateArray[0] = e.target.value;
                                return {
                                    ...prevState,
                                    spinSpeed: updateArray
                                }
                            })
                        }} />
                    </Col>
                    <Col span={12}>
                        <div className="f-s-c" style={{marginLeft: ".25em"}}>น้ำอุ่น</div>
                        <Input  style={{ width: '100%' }} 
                        value={lgDevice?.spinSpeed.at(1)} 
                        onChange={(e) =>{
                            setLgDevice((prevState) => {
                                const updateArray = [...lgDevice.spinSpeed];
                                updateArray[1] = e.target.value;
                                return {
                                    ...prevState,
                                    spinSpeed: updateArray
                                }
                            })
                        }} />
                    </Col>
                    <Col span={12}>
                        <div className="f-s-c" style={{marginLeft: ".25em"}}>น้ำเย็น</div>
                        <Input style={{ width: '100%' }}
                        value={lgDevice?.spinSpeed.at(2)} 
                        onChange={(e) =>{
                            setLgDevice((prevState) => {
                                const updateArray = [...lgDevice.spinSpeed];
                                updateArray[2] = e.target.value;
                                return {
                                    ...prevState,
                                    spinSpeed: updateArray
                                }
                            })
                        }} />
                    </Col>
                    <Col span={12}>
                        <div className="f-s-c" style={{marginLeft: ".25em"}}>ถนอมผ้า</div>
                        <Input style={{ width: '100%' }} 
                        value={lgDevice?.spinSpeed.at(3)} 
                        onChange={(e) =>{
                            setLgDevice((prevState) => {
                                const updateArray = [...lgDevice.spinSpeed];
                                updateArray[3] = e.target.value;
                                return {
                                    ...prevState,
                                    spinSpeed: updateArray
                                }
                            })
                        }} />
                    </Col>
                </Row>

                <Title level={5}>จำนวน Drop Count</Title>
                <div>
                    <InputNumber min={25} max={50} style={{ width: '100%' }}
                    value={lgDevice?.dropCount} 
                    onChange={(v) => {
                        setLgDevice((prevState) => {
                            return {
                                ...prevState,
                                dropCount: v
                            }
                        })
                }}/>
                    <Text type="secondary" ><small>จะทำให้เกิด UE เมื่อจำนวนครั้งมากที่สุดของจำนวนที่ถูกหยุดจาก UB เกินค่านี้ ในขณะปั่นหมาด</small></Text>
                </div>

                <Title level={5}>Twin Spray</Title>
                <Radio.Group  value={lgDevice?.twinSpray} onChange={(e) => {
                    setLgDevice((prevState) => {
                        return {
                            ...prevState,
                            twinSpray: e.target.value
                        }
                    })
                }}>
                    <Radio value={'0'}>ปิด</Radio>
                    <Radio value={'1'}>เปิด</Radio>
                </Radio.Group>

                <Title level={5}>ราคา **</Title>
                <Row gutter={[16, 8]}>
                    <Col className="gutter-row" span={24}>
                        <div className="f-s-c" style={{marginLeft: ".25em"}}>Default 
                            <Text type="secondary" style={{marginLeft: "1em"}}>  (หากเซ็ตเป็น 0 ทั้งหมด จะใช้งานโดยไม่เสียค่าใช้จ่าย)</Text>
                        </div>
                        <InputNumber min={0} max={199}  style={{ width: '100%' }} 
                        value={lgDevice?.price.at(0)} 
                        onChange={(v) =>{
                            setLgDevice((prevState) => {
                                const updateArray = [...lgDevice.price];
                                updateArray[0] = v;
                                return {
                                    ...prevState,
                                    price: updateArray
                                }
                            })
                        }} />
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <div className="f-s-c" style={{marginLeft: ".25em"}}>น้ำร้อน 
                            <Text type="secondary" style={{marginLeft: "1em"}}>  (หากเซ็ตเป็น 0 จะดึงจากค่า default มาใช้)</Text>
                        </div>
                        <InputNumber min={0} max={199}  style={{ width: '100%' }}
                        value={lgDevice?.price.at(1)} 
                        onChange={(v) =>{
                            setLgDevice((prevState) => {
                                const updateArray = [...lgDevice.price];
                                updateArray[1] = v;
                                return {
                                    ...prevState,
                                    price: updateArray
                                }
                            })
                        }} />
                    </Col>
                    <Col span={12}>
                        <div className="f-s-c" style={{marginLeft: ".25em"}}>น้ำอุ่น  
                            <Text type="secondary" style={{marginLeft: "1em"}}>  (หากเซ็ตเป็น 0 จะดึงจากค่า default มาใช้)</Text>
                        </div>
                        <InputNumber min={0} max={199}  style={{ width: '100%' }} 
                        value={lgDevice?.price.at(2)} 
                        onChange={(v) =>{
                            setLgDevice((prevState) => {
                                const updateArray = [...lgDevice.price];
                                updateArray[2] = v;
                                return {
                                    ...prevState,
                                    price: updateArray
                                }
                            })
                        }} />
                    </Col>
                    <Col span={12}>
                        <div className="f-s-c" style={{marginLeft: ".25em"}}>น้ำเย็น  
                            <Text type="secondary" style={{marginLeft: "1em"}}>  (หากเซ็ตเป็น 0 จะดึงจากค่า default มาใช้)</Text>
                        </div>
                        <InputNumber min={0} max={199}  style={{ width: '100%' }} 
                        value={lgDevice?.price.at(3)} 
                        onChange={(v) =>{
                            setLgDevice((prevState) => {
                                const updateArray = [...lgDevice.price];
                                updateArray[3] = v;
                                return {
                                    ...prevState,
                                    price: updateArray
                                }
                            })
                        }} />
                    </Col>
                    <Col span={12}>
                        <div className="f-s-c" style={{marginLeft: ".25em"}}>ถนอมผ้า  
                            <Text type="secondary" style={{marginLeft: "1em"}}>  (หากเซ็ตเป็น 0 จะดึงจากค่า default มาใช้)</Text>
                        </div>
                        <InputNumber min={0} max={199} style={{ width: '100%' }} 
                        value={lgDevice?.price.at(4)} 
                        onChange={(v) =>{
                            setLgDevice((prevState) => {
                                const updateArray = [...lgDevice.price];
                                updateArray[4] = v;
                                return {
                                    ...prevState,
                                    price: updateArray
                                }
                            })
                        }} />
                    </Col>
                </Row>

                <Title level={5}>ราคาการซักพิเศษ **</Title>
                <div>
                    <InputNumber min={0} max={199} style={{ width: '100%' }} 
                    value={lgDevice?.addSuperWash} 
                    onChange={(v) =>{
                        setLgDevice((prevState) => {
                            return {
                                ...prevState,
                                addSuperWash: v
                            }
                        })
                    }} />
                </div>
                
                <Title level={5}>หน่วยเงินเหรียญ **</Title>
                <div>
                    <InputNumber min={1} max={20} style={{ width: '100%' }} 
                    value={lgDevice?.coin} 
                    onChange={(v) =>{
                        setLgDevice((prevState) => {
                            return {
                                ...prevState,
                                coin: v
                            }
                        })
                    }} />
                    <Text type="secondary"><small>ค่าของเหรียญต่ำสุดที่ใช้ เช่น ใช้เหรียญขนาด 10 บาท กรอกค่าเป็น 2 เพื่อให้คูณกับ 5 เป็น 10</small></Text>
                </div>
                
                <div className="f-s-c"></div>
                <div>
                    
                </div>

                <div className="f-s-c"></div>
                <div>
                    <small>
                        <div>** ราคาจะคิดจาก ค่าที่กรอกนี้ x Rating Money ของเครื่อง โดยประเทศไทย กำหนดค่า Rating Money = 5</div>
                        <div>หากจะคิดราคา 40 จะต้องกรอกเลข 8 โดยไปคูณกับ 5 ให้เท่ากับ 40 </div>
                    </small>
                </div>
                
        
            </div>
            <div className="f-c-c s-e-b">
                <div
                    className="f-c-c d-b-t-n-s d-bg-c-t"
                    style={{ width: "170px", pointerEvents: isLoading ? "none" : null }}
                    onClick={saveSettings}
                >
                    {isLoading ? (
                        <LoadingOutlined />
                    ) : (
                        `Save Config`
                    )}
                </div>
            </div>
            <div>

            </div>


        </div>
    )
    
    

};

export default LgWasherSettings;