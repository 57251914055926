import React, { useState, useEffect } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { Col, Row, Input, InputNumber, Radio, Select, Space, Button, Typography } from "antd";
import { useQuery, useApolloClient } from "@apollo/client";
import "./Machine.css";
import { LoadingOutlined } from "@ant-design/icons";
import { GET_LG_MACHINE_SETTINGS } from "../../resolvers/Query";
import { UPDATE_LG_DRYER_SETTINGS } from "../../resolvers/Mutation";

import moment from "moment";
import "moment/locale/th";

const dryerDefaultSetting = {
    dryCycleTime: [45, 45, 45, 45],         // 45, 5 - 99
    topOffTime: [10, 10, 10, 10],           // 5, 1 - 99
    topOff: "1",                            // '0', '0' - '1'
    sensingDry: "1",                        // '0', '0' - '1'
    regularPrice: [0, 10, 10, 10, 10],      // 0 - 199
    topOffPrice: [2, 2, 2, 2],              // 0 - 199
    coin1: "2"                                // 5, 1, 2, 5, 20
    
}

const { Title,  Text} = Typography;

const LgDryerSettings = ({deviceId}) => {

    const { machineId } = useParams();
    const location = useLocation();
    const history = useHistory();
    const apolloClient = useApolloClient();

    const queryData = useQuery(GET_LG_MACHINE_SETTINGS, {
        variables: {
            lgMachineId: deviceId
        },
      });

    const [lgDevice, setLgDevice] = useState();
    const [selectValue, setSelectValue] = React.useState("");
    const [isLoading, setIsLoading] = useState(false);

    let machine_sub_group = "LG commercial";
    
    useEffect(async() => {
        getSettings();
        return () => {
            setLgDevice()
        }
    }, []);
    

    const getSettings = async (event) => {
        const response = await apolloClient.query({ 
            query: GET_LG_MACHINE_SETTINGS,
            variables: { lgMachineId: deviceId },
            fetchPolicy: "no-cache"
        });
        if (response?.data?.getLGMachineSettings) {
            const { getLGMachineSettings } = response.data;
            // console.log("getLGMachineSettings", getLGMachineSettings);
            setLgDevice((prevState) => {
                return {
                    dryCycleTime: getLGMachineSettings.dryCycleTime,
                    topOffTime: getLGMachineSettings.topOffTime,
                    topOff: getLGMachineSettings.topOff,
                    sensingDry: getLGMachineSettings.sensingDry,
                    regularPrice: getLGMachineSettings.regularPrice,
                    topOffPrice: getLGMachineSettings.topOffPrice,
                    coin1: getLGMachineSettings.coin1,
                };
            })
        }
    }

    const saveSettings = async (event) => {
        const formData = {
            ...lgDevice,
            lgMachineId: deviceId
        }
        try {
            const response = await apolloClient.mutate({ 
                mutation: UPDATE_LG_DRYER_SETTINGS,
                variables: formData
            });
            if (response.data.updateLGDryerSettings) {
                setLgDevice()
                history.goBack();
            }
        } catch (error) {
            console.log("Error : ", error)
        }
    };

    return (
        <div>

            <div className="machine-add-container-grid">
                <div className="f-s-c"></div>
                <Row justify="end">
                    <Button type="primary" onClick={() => {
                        setLgDevice(dryerDefaultSetting)
                    }}>Set Default</Button>
                </Row>

                <div className="f-s-c">หมายเลขเครื่องอบ</div>
                <div>
                    <Input
                        value={deviceId || ""}
                        disabled
                    />
                </div>
                <Title level={5} >เวลาการอบ</Title>
                <Row gutter={[16, 8]}>
                    <Col className="gutter-row" span={12}>
                        <div className="f-s-c" style={{marginLeft: ".25em"}}>อุณหภูมิสูง</div>
                        <InputNumber min={5} max={99}  style={{ width: '100%' }} 
                        value={lgDevice?.dryCycleTime.at(0)}
                        onChange={(v) =>{
                            setLgDevice((prevState) => {
                                const updateArray = [...lgDevice.dryCycleTime];
                                updateArray[0] = v;
                                return {
                                    ...prevState,
                                    dryCycleTime: updateArray
                                }
                            })
                        }} />
                    </Col>
                    <Col span={12}>
                        <div className="f-s-c" style={{marginLeft: ".25em"}}>อุณหภูมิปานกลาง</div>
                        <InputNumber min={5} max={99} style={{ width: '100%' }} 
                        value={lgDevice?.dryCycleTime.at(1)}
                        onChange={(v) =>{
                            setLgDevice((prevState) => {
                                const updateArray = [...lgDevice.dryCycleTime];
                                updateArray[1] = v;
                                return {
                                    ...prevState,
                                    dryCycleTime: updateArray
                                }
                            })
                        }} />
                    </Col>
                    <Col span={12}>
                        <div className="f-s-c" style={{marginLeft: ".25em"}}>อุณหภูมิต่ำ</div>
                        <InputNumber min={5} max={99}  style={{ width: '100%' }}
                        value={lgDevice?.dryCycleTime.at(2)}
                        onChange={(v) =>{
                            setLgDevice((prevState) => {
                                const updateArray = [...lgDevice.dryCycleTime];
                                updateArray[2] = v;
                                return {
                                    ...prevState,
                                    dryCycleTime: updateArray
                                }
                            })
                        }} />
                    </Col>
                    <Col span={12}>
                        <div className="f-s-c" style={{marginLeft: ".25em"}}>ไม่ใช้ความร้อน</div>
                        <InputNumber min={5} max={99} style={{ width: '100%' }} 
                        value={lgDevice?.dryCycleTime.at(3)}
                        onChange={(v) =>{
                            setLgDevice((prevState) => {
                                const updateArray = [...lgDevice.dryCycleTime];
                                updateArray[3] = v;
                                return {
                                    ...prevState,
                                    dryCycleTime: updateArray
                                }
                            })
                        }} />
                    </Col>
                </Row>

                <Title level={5}>เวลาการอบเพิ่ม</Title>
                <Row gutter={[16, 8]}>
                    <Col className="gutter-row" span={12}>
                        <div className="f-s-c" style={{marginLeft: ".25em"}}>อุณหภูมิสูง</div>
                        <InputNumber min={1} max={99}  style={{ width: '100%' }} 
                        value={lgDevice?.topOffTime.at(0)}
                        onChange={(v) =>{
                            setLgDevice((prevState) => {
                                const updateArray = [...lgDevice.topOffTime];
                                updateArray[0] = v;
                                return {
                                    ...prevState,
                                    topOffTime: updateArray
                                }
                            })
                        }} />
                    </Col>
                    <Col span={12}>
                        <div className="f-s-c" style={{marginLeft: ".25em"}}>อุณหภูมิปานกลาง</div>
                        <InputNumber min={1} max={99}  style={{ width: '100%' }} 
                        value={lgDevice?.topOffTime.at(1)}
                        onChange={(v) =>{
                            setLgDevice((prevState) => {
                                const updateArray = [...lgDevice.topOffTime];
                                updateArray[1] = v;
                                return {
                                    ...prevState,
                                    topOffTime: updateArray
                                }
                            })
                        }} />
                    </Col>
                    <Col span={12}>
                        <div className="f-s-c" style={{marginLeft: ".25em"}}>อุณหภูมิต่ำ</div>
                        <InputNumber min={1} max={99}  style={{ width: '100%' }} 
                        value={lgDevice?.topOffTime.at(2)}
                        onChange={(v) =>{
                            setLgDevice((prevState) => {
                                const updateArray = [...lgDevice.topOffTime];
                                updateArray[2] = v;
                                return {
                                    ...prevState,
                                    topOffTime: updateArray
                                }
                            })
                        }} />
                    </Col>
                    <Col span={12}>
                        <div className="f-s-c" style={{marginLeft: ".25em"}}>ไม่ใช้ความร้อน</div>
                        <InputNumber min={1} max={99}  style={{ width: '100%' }} 
                        value={lgDevice?.topOffTime.at(3)}
                        onChange={(v) =>{
                            setLgDevice((prevState) => {
                                const updateArray = [...lgDevice.topOffTime];
                                updateArray[3] = v;
                                return {
                                    ...prevState,
                                    topOffTime: updateArray
                                }
                            })
                        }} />
                    </Col>
                </Row>

                <Title level={5}>เปิดเพิ่มเวลาการอบระหว่างเครื่องทำงาน</Title>
                <Radio.Group value={lgDevice?.topOff} onChange={(e) => {
                    setLgDevice((prevState) => {
                        return {
                            ...prevState,
                            topOff: e.target.value
                        }
                    })
                }}>
                    <Radio value={'0'}>ปิด</Radio>
                    <Radio value={'1'}>เปิด</Radio>
                </Radio.Group>

                <Title level={5}>เปิดปรับเวลาการอบอัตโมมัติตามค่าความชื้น</Title>
                <Radio.Group value={lgDevice?.sensingDry} onChange={(e) => {
                    setLgDevice((prevState) => {
                        return {
                            ...prevState,
                            sensingDry: e.target.value
                        }
                    })
                }}>
                    <Radio value={'0'}>ปิด</Radio>
                    <Radio value={'1'}>เปิด</Radio>
                </Radio.Group>

                <Title level={5}>ราคา **</Title>
                <Row gutter={[16, 8]}>
                    <Col className="gutter-row" span={24}>
                        <div className="f-s-c" style={{marginLeft: ".25em"}}>Default 
                            <Text type="secondary" style={{marginLeft: "1em"}}>  (หากเซ็ตเป็น 0 ทั้งหมด จะใช้งานโดยไม่เสียค่าใช้จ่าย)</Text>
                        </div>
                        <InputNumber min={0} max={199}  style={{ width: '100%' }} 
                        value={lgDevice?.regularPrice.at(0)} 
                        onChange={(v) =>{
                            setLgDevice((prevState) => {
                                const updateArray = [...lgDevice.regularPrice];
                                updateArray[0] = v;
                                return {
                                    ...prevState,
                                    regularPrice: updateArray
                                }
                            })
                        }} />
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <div className="f-s-c" style={{marginLeft: ".25em"}}>อุณหภูมิสูง 
                            <Text type="secondary" style={{marginLeft: "1em"}}>  (หากเซ็ตเป็น 0 จะดึงจากค่า default มาใช้)</Text>
                        </div>
                        <InputNumber min={0} max={199}  style={{ width: '100%' }} 
                         value={lgDevice?.regularPrice.at(1)} 
                         onChange={(v) =>{
                             setLgDevice((prevState) => {
                                 const updateArray = [...lgDevice.regularPrice];
                                 updateArray[1] = v;
                                 return {
                                     ...prevState,
                                     regularPrice: updateArray
                                 }
                             })
                         }} />
                    </Col>
                    <Col span={12}>
                        <div className="f-s-c" style={{marginLeft: ".25em"}}>อุณหภูมิปานกลาง  
                            <Text type="secondary" style={{marginLeft: "1em"}}>  (หากเซ็ตเป็น 0 จะดึงจากค่า default มาใช้)</Text>
                        </div>
                        <InputNumber min={0} max={199}  style={{ width: '100%' }} 
                         value={lgDevice?.regularPrice.at(2)} 
                         onChange={(v) =>{
                             setLgDevice((prevState) => {
                                 const updateArray = [...lgDevice.regularPrice];
                                 updateArray[2] = v;
                                 return {
                                     ...prevState,
                                     regularPrice: updateArray
                                 }
                             })
                         }} />
                    </Col>
                    <Col span={12}>
                        <div className="f-s-c" style={{marginLeft: ".25em"}}>อุณหภูมิต่ำ  
                            <Text type="secondary" style={{marginLeft: "1em"}}>  (หากเซ็ตเป็น 0 จะดึงจากค่า default มาใช้)</Text>
                        </div>
                        <InputNumber min={0} max={199}  style={{ width: '100%' }} 
                         value={lgDevice?.regularPrice.at(3)} 
                         onChange={(v) =>{
                             setLgDevice((prevState) => {
                                 const updateArray = [...lgDevice.regularPrice];
                                 updateArray[3] = v;
                                 return {
                                     ...prevState,
                                     regularPrice: updateArray
                                 }
                             })
                         }} />
                    </Col>
                    <Col span={12}>
                        <div className="f-s-c" style={{marginLeft: ".25em"}}>ไม่ใช้ความร้อน  
                            <Text type="secondary" style={{marginLeft: "1em"}}>  (หากเซ็ตเป็น 0 จะดึงจากค่า default มาใช้)</Text>
                        </div>
                        <InputNumber min={0} max={199} style={{ width: '100%' }} 
                         value={lgDevice?.regularPrice.at(4)} 
                         onChange={(v) =>{
                             setLgDevice((prevState) => {
                                 const updateArray = [...lgDevice.regularPrice];
                                 updateArray[4] = v;
                                 return {
                                     ...prevState,
                                     regularPrice: updateArray
                                 }
                             })
                         }} />
                    </Col>
                </Row>

                <Title level={5}>ราคาการเพิ่มเวลาอบ **</Title>
         
                <Row gutter={[16, 8]}>
                    <Col className="gutter-row" span={12}>
                        <div className="f-s-c" style={{marginLeft: ".25em"}}>อุณหภูมิสูง</div>
                        <InputNumber  min={0} max={199} style={{ width: '100%' }} 
                        value={lgDevice?.topOffPrice.at(0)}
                        onChange={(v) =>{
                            setLgDevice((prevState) => {
                                const updateArray = [...lgDevice.topOffPrice];
                                updateArray[0] = v;
                                return {
                                    ...prevState,
                                    topOffPrice: updateArray
                                }
                            })
                        }} />
                    </Col>
                    <Col span={12}>
                        <div className="f-s-c" style={{marginLeft: ".25em"}}>อุณหภูมิปานกลาง</div>
                        <InputNumber  min={0} max={199}  style={{ width: '100%' }} 
                        value={lgDevice?.topOffPrice.at(1)}
                        onChange={(v) =>{
                            setLgDevice((prevState) => {
                                const updateArray = [...lgDevice.topOffPrice];
                                updateArray[1] = v;
                                return {
                                    ...prevState,
                                    topOffPrice: updateArray
                                }
                            })
                        }} />
                    </Col>
                    <Col span={12}>
                        <div className="f-s-c" style={{marginLeft: ".25em"}}>อุณหภูมิต่ำ</div>
                        <InputNumber  min={0} max={199}  style={{ width: '100%' }} 
                        value={lgDevice?.topOffPrice.at(2)}
                        onChange={(v) =>{
                            setLgDevice((prevState) => {
                                const updateArray = [...lgDevice.topOffPrice];
                                updateArray[2] = v;
                                return {
                                    ...prevState,
                                    topOffPrice: updateArray
                                }
                            })
                        }} />
                    </Col>
                    <Col span={12}>
                        <div className="f-s-c" style={{marginLeft: ".25em"}}>ไม่ใช้ความร้อน</div>
                        <InputNumber  min={0} max={199}  style={{ width: '100%' }} 
                        value={lgDevice?.topOffPrice.at(3)}
                        onChange={(v) =>{
                            setLgDevice((prevState) => {
                                const updateArray = [...lgDevice.topOffPrice];
                                updateArray[3] = v;
                                return {
                                    ...prevState,
                                    topOffPrice: updateArray
                                }
                            })
                        }} />
                    </Col>
                </Row>
                
                <Title level={5}>หน่วยเงินเหรียญ **</Title>
                <div>
                    <InputNumber min={1} max={20} style={{ width: '100%' }} 
                    value={lgDevice?.coin1} 
                    onChange={(v) =>{
                        setLgDevice((prevState) => {
                            return {
                                ...prevState,
                                coin1: `${v}`
                            }
                        })
                    }} />
                    <Text type="secondary"><small>ค่าของเหรียญต่ำสุดที่ใช้ เช่น ใช้เหรียญขนาด 10 บาท กรอกค่าเป็น 2 เพื่อให้คูณกับ 5 เป็น 10</small></Text>
                </div>
                
                <div className="f-s-c"></div>
                <div>
                    
                </div>

                <div className="f-s-c"></div>
                <div>
                    <small>
                        <div>** ราคาจะคิดจาก ค่าที่กรอกนี้ x Rating Money ของเครื่อง โดยประเทศไทย กำหนดค่า Rating Money = 5</div>
                        <div>หากจะคิดราคา 40 จะต้องกรอกเลข 8 โดยไปคูณกับ 5 ให้เท่ากับ 40 </div>
                    </small>
                </div>
                
        
            </div>
            <div className="f-c-c s-e-b">
                <div
                    className="f-c-c d-b-t-n-s d-bg-c-t"
                    style={{ width: "170px", pointerEvents: isLoading ? "none" : null }}
                    onClick={saveSettings}
                >
                    {isLoading ? (
                        <LoadingOutlined />
                    ) : (
                        `Save Config`
                    )}
                </div>
            </div>
            <div>

            </div>


        </div>
    )
    
    

};

export default LgDryerSettings;