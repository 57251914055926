import React, { useState, useEffect } from 'react'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { Input, Table, Pagination, Tooltip, Modal, Avatar, Select, InputNumber } from 'antd';
import './CouponList.css'
import XLSX from "xlsx";
import { LoadingOutlined } from '@ant-design/icons';

import { openNotification, getSearchParams } from '../../utils/function'

import moment from 'moment';
import 'moment/locale/th'

import { apiCPCouponAddEdit } from '../../services/apis/coupon'

const { Option } = Select;

const CouponAddEdit = () => {
  const { couponId } = useParams();
  const location = useLocation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false)
  const [coupon, setCoupon] = useState(undefined)


  const [isAlertName, setIsAlertName] = useState(false)
  const [isAlertSizeBag, setIsAlertSizeBag] = useState(false)
  const [isAlertTotal, setIsAlertTotal] = useState(false)
  const [isAlertDuration, setIsAlertDuration] = useState(false)
  const [isAlertPrice, setIsAlertPrice] = useState(false)


  const addEditCoupon = async () => {
    if (!(coupon && coupon.coupon_name) ||
      !(coupon && coupon.bag_size) ||
      !(coupon && coupon.time) ||
      !(coupon && coupon.duration) ||
      !(coupon && coupon.price)
    ) {
      if (!(coupon && coupon.coupon_name)) setIsAlertName(true)
      if (!(coupon && coupon.bag_size)) setIsAlertSizeBag(true)
      if (isNaN(coupon && coupon.time)) setIsAlertTotal(true)
      if (isNaN(coupon && coupon.price)) setIsAlertPrice(true)
      if (isNaN(coupon && coupon.duration)) setIsAlertDuration(true)

    } else {
      setIsLoading(true)

      let data = { ...coupon }
    
      let newCoupon = await apiCPCouponAddEdit(data)
      if (newCoupon && newCoupon.status) {
        setIsLoading(false)
        openNotification('success', 'Success !', `${couponId ? 'Edit' : 'Add new'} coupon successful.`)
        setTimeout(() => history.push(`/washer/coupon`), 1000);
      } else {
        setIsLoading(false)
        openNotification('error', 'Failed !', `Failed to ${couponId ? 'edit' : 'add new'} coupon.`)
      }
    }
  }

  return (
    <div>
      <div className="mg-bt-20 h-d-t-p">Coupon - {couponId ? 'Edit' : 'Add'}</div>

      <div className="t-m-c-i-p-g">

        <div className="f-s-c">ชื่อแพ็คเกจ</div>
        <div>
          <Input
            style={{ width: '100%', borderColor: isAlertName ? '#EC1C24' : null }}
            placeholder="ชื่อแพ็คเกจ"
            value={coupon && coupon.coupon_name || ''}
            onChange={e => {
              setIsAlertName(false)
              setCoupon({ ...coupon, coupon_name: e.target.value })
            }}
          />
        </div>

        <div className="f-s-c">ไซส์ถุง</div>
        <div className={`pst-rlt ${isAlertSizeBag && 'antd-sl-al-bd' || ''}`}>
          <Select
            style={{ width: '100%' }}
            placeholder="Please select"
            value={coupon && coupon.bag_size}
                onChange={value => {
                  setIsAlertSizeBag(false)
                  setCoupon({
                    ...coupon,
                    bag_size: value
                  })
                }}
          >
            <Option key="S">S</Option>
            <Option key="M">M</Option>
            <Option key="L">L</Option>
          </Select>
        </div>

        <div className="f-s-c">จำนวนครั้ง</div>
        <div>
          <InputNumber min={1}
            step={1}
            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
            style={{ width: '100%', borderColor: isAlertTotal ? '#EC1C24' : null }}
            placeholder="จำนวนครั้ง"
            value={coupon && coupon.time || ''}
            onChange={value => {
              setIsAlertTotal(false)
              setCoupon({ ...coupon, time: Math.round(value * 100) / 100 || 0 })
            }}
          />
        </div>




        <div className="f-s-c">อายุการใช้งานคูปอง</div>
        <div className="pst-rlt">
          <InputNumber
            style={{ width: '100%', borderColor: isAlertDuration ? '#EC1C24' : null }}
            step={1}
            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
            placeholder="อายุการใช้งาน"
            min={10}
            value={coupon && coupon.duration || ''}
            onChange={value => {
              setIsAlertDuration(false)
              setCoupon({ ...coupon, duration: Math.round(value * 100) / 100 || 0 })
            }}
          />
          <div className="s-f-o-i-p">วัน</div>
        </div>



        <div className="f-s-c">ราคา</div>
        <div>
          <InputNumber min={0}
            step={0.01}
            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
            style={{ width: '100%', borderColor: isAlertPrice ? '#EC1C24' : null }}
            placeholder="ราคา"
            value={coupon && coupon.price || ''}
            onChange={value => {
              setIsAlertPrice(false)
              setCoupon({ ...coupon, price: Math.round(value * 100) / 100 || 0 })
            }}
          />
        </div>


      </div>

      <div className="f-c-c s-e-b">
        <div className="f-c-c d-b-t-n-s d-bg-c-t"
          style={{ width: '170px', pointerEvents: isLoading ? 'none' : null }}
          onClick={() => addEditCoupon()}
        >{isLoading ? <LoadingOutlined /> : `${couponId ? 'Edit' : 'Add New'} Package`}</div>
      </div>

    </div>
  )
}

export default CouponAddEdit
