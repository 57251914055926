import axios from '../../config/axios';

const apiGetSlip = async (cur_page, per_page, siteId) => {
    try {
      const result = await axios.get(`/slip?cur_page=${cur_page}&per_page=${per_page}&site_id=${siteId}`)
      return result && result.data
    } catch (error) {
      return error && error.response && error.response.data
    }
  }
  
  const apiUploadSlip = async (data) => {
    try {
      const result = await axios.post('/slip', data)
      return result && result.data
    } catch (error) {
      return error && error.response && error.response.data
    }
  }
  
  export {
    apiGetSlip,
    apiUploadSlip
  }