import React, { useState, useEffect } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Input, Table, Pagination, Tooltip, DatePicker } from 'antd';
import './CallSupport.css'
import XLSX from "xlsx";
import { LoadingOutlined } from '@ant-design/icons';

import moment from 'moment';
import 'moment/locale/th'

import { colors } from '../../config/config'
import { numberWithCommas, getSearchParams } from '../../utils/function'
import { apiCSPGetMaintenance } from '../../services/apis/call'

const { RangePicker } = DatePicker;

const MaintenanceList = () => {
  const history = useHistory();
  const location = useLocation();

  const params = new URLSearchParams(location.search)
  const p = params.get('p')
  const ttl = params.get('ttl')
  const start = params.get('start')
  const end = params.get('end')
  const sw = params.get('sw')

  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(Number(p) || 1)
  const [perPage, setPerPage] = useState(Number(ttl) || 20)
  const [startDate, setStartDate] = useState(start ? moment(start).startOf('day').format('YYYY-MM-DD HH:mm:ss') : moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'))
  const [endDate, setEndDate] = useState(end ? moment(end).endOf('day').format('YYYY-MM-DD HH:mm:ss') : moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'))
  const [tableData, setTableData] = useState(undefined)
  const [searchWord, setSearchWord] = useState(sw || '')
  const [searchButton, setSearchButton] = useState(false)
  const [waitingForCSV, setWaitingForCSV] = useState(false)

  useEffect(() => {
    getTableData()
    history.push(`${location.pathname}${getSearchParams(['p', 'ttl', 'start', 'end', 'sw'], [currentPage, perPage, startDate, endDate, searchWord])}`)
  }, [currentPage, perPage, searchButton])

  const getTableData = async () => {
    setIsLoading(true)
    let table = await apiCSPGetMaintenance(false, startDate, endDate, currentPage, perPage, searchWord)
    if (table && table.status) {
      setIsLoading(false)
      setTableData(table.result)
      console.log('Result: ', table.result);
    }
  }

  const columns = [
    {
      title: '#',
      ellipsis: true,
      align: 'center',
      width: '5%',
      render: (data, record, index) => <div>{(index + 1) + ((currentPage - 1) * perPage)}</div>
    },
    {
      title: 'Flag',
      ellipsis: true,
      align: 'center',
      width: '80px',
      render: (data) => <div className="f-c-c d-b-t-n-s b-c-o-i"
        style={{ backgroundColor: getFlagColor(data.status) }}
      />
    },
    {
      title: 'วันที่แจ้ง',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{data.create_date ? moment(data.create_date).add(543, 'years').format('D MMM YYYY HH:mm:ss') : '-'}</div>
    },
    {
      title: 'ชื่อผู้ติดต่อ',
      ellipsis: true,
      render: (data) => <div>{data.name || '-'}</div>
    },
    {
      title: 'เบอร์โทร',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{data.phone || '-'}</div>
    },
    {
      title: 'ประเภท',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{data.machine_type || '-'}</div>
    },
    {
      title: 'สาขา',
      ellipsis: true,
      render: (data) => <div>{data.site_name || '-'}</div>
    },
    {
      title: 'อาการ',
      ellipsis: true,
      render: (data) => <div>{data.detail || '-'}</div>
    },
    {
      title: 'สถานะ',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{data.status || '-'}</div>
    },
    {
      title: 'ตั้งค่า',
      ellipsis: true,
      align: 'center',
      fixed: 'right',
      render: (data) => <Tooltip placement="top" title="Update">
        <div className="f-c-c d-b-t-n-s b-c-o-i" style={{ backgroundColor: '#00ADEF' }}
          onClick={e => {
            e.stopPropagation()
            history.push(`/call-support/list-maintenance/edit-maintenance/${data.id}${getSearchParams(['p', 'ttl', 'start', 'end', 'sw'], [currentPage, perPage, startDate, endDate, searchWord])}`)
          }}
        ><i className="fas fa-user-edit"></i></div>
      </Tooltip>
    },
  ];

  const getFlagColor = value => {
    switch ((value || '').toLowerCase()) {
      case 'open': return colors.general.red
      case 'pending': return colors.general.yellow
      case 'close': return colors.general.green
      default: return
    }
  }

  const getFlagWord = value => {
    switch ((value || '').toLowerCase()) {
      case 'open': return 'Red'
      case 'pending': return 'Yellow'
      case 'close': return 'Green'
      default: return '-'
    }
  }

  const toCSVFile = async () => {
    setWaitingForCSV(true)
    let data = await apiCSPGetMaintenance(true, startDate, endDate, '', '', searchWord)
    let csvData = [];

    if (data && data.status) {
      (data.result.data_list || []).map((data, idx) => {
        let resultRow = {}
        resultRow['#'] = idx + 1
        resultRow['Flag'] = getFlagWord(data.status)
        resultRow['วันที่แจ้ง'] = data.create_date || '-'
        resultRow['ชื่อผู้ติดต่อ'] = data.name || '-'
        resultRow['เบอร์โทร'] = data.phone || '-'
        resultRow['ประเภท'] = data.machine_type || '-'
        resultRow['สาขา'] = data.site_name || '-'
        resultRow['อาการ'] = data.detail || '-'
        resultRow['สถานะ'] = data.status || '-'
        csvData.push(resultRow);
      })

      const toCSV = XLSX.utils.json_to_sheet(csvData);
      const wb = XLSX.utils.book_new();

      const fileName = 'CALL_MAINTENANCE_LIST'
      XLSX.utils.book_append_sheet(wb, toCSV, fileName);
      XLSX.writeFile(wb, fileName + ".csv");

      setWaitingForCSV(false)
    }
  }

  return (
    <div>
      <div className="mg-bt-20 coin-collect-title">
        <div className="mg-bt-20 h-d-t-p">Call Support - Maintenance List</div>
        <div className="coin-collect-card-list">
          {(tableData && tableData.sumary || []).map((list, idx) => <div key={idx} className="c-c-t-s">
            <div className="coin-collect-card">
              <div className="coin-collect-card-grid">
                <div>{list.name}</div>
                <div>{numberWithCommas(list.value || 0, 0)}</div>
              </div>
            </div>
          </div>)}
        </div>
      </div>

      <div className="call-header-grid">
        <div>
          <RangePicker
            style={{ width: '100%' }}
            format={date => moment(date).add(543, 'years').format('D MMM YYYY')}
            allowClear={false}
            value={[moment(startDate, 'YYYY-MM-DD'), moment(endDate, 'YYYY-MM-DD')]}
            onChange={date => {
              setCurrentPage(1)
              setStartDate(moment(date[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss'))
              setEndDate(moment(date[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss'))
            }}
            disabledDate={current => current && current >= moment().endOf('day')}
          />
        </div>
        <div>
          <Input
            placeholder="search .."
            value={searchWord}
            onChange={e => setSearchWord(e.target.value)}
            onPressEnter={() => {
              setCurrentPage(1)
              setSearchButton(!searchButton)
            }}
          />
        </div>
        <div className="f-c-c d-b-t-n-s d-bg-c-t"
          onClick={() => {
            setCurrentPage(1)
            setSearchButton(!searchButton)
          }}>
          <i className="bi bi-search" style={{ lineHeight: '0' }}></i>
        </div>
        <Link to="/call-support/add-maintenance">
          <div className="f-c-c d-b-t-n-s d-bg-c-t h-32" style={{ height: '100%' }}>+ Add</div>
        </Link>
        <div className="f-c-c d-b-t-n-s d-bg-c-t h-32"
          style={{ pointerEvents: waitingForCSV ? 'none' : null }}
          onClick={() => toCSVFile()}
        >
          {waitingForCSV ? <LoadingOutlined /> : 'Export CSV'}
        </div>
      </div>

      <Table bordered
        size="small"
        rowKey={record => record.id}
        loading={isLoading}
        dataSource={tableData && tableData.data_list || []}
        columns={columns}
        pagination={false}
        scroll={{ x: true }}
      />

      <div className="pgnt-st">
        <Pagination
          size="small"
          current={currentPage}
          onChange={page => setCurrentPage(page)}
          total={tableData && tableData.max_total_rows}
          pageSize={perPage}
          showSizeChanger={false}
        />
      </div>

    </div>
  )
}

export default MaintenanceList
