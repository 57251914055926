import React, { useState, useEffect } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { Input, InputNumber, Select, DatePicker, Divider } from "antd";
import "./Machine.css";
import { LoadingOutlined } from "@ant-design/icons";

import moment from "moment";
import "moment/locale/th";

import { openNotification, getSearchParams } from "../../utils/function";

import { UPDATE_MACHINE, ADD_MACHINE, SET_CONFIG_MACHINE } from "../../resolvers/Mutation";
import {
    QueryGraphQL_ConfigMachineByDeviceName, GET_CONFIG_MACHINE,
} from "../../resolvers/Query";
import { useMutation } from "@apollo/client";
import { render } from "react-dom";

const { Option } = Select;

const MachineSetconfig = () => {

    const { machineId } = useParams();
    const location = useLocation();
    const history = useHistory();

    const params = new URLSearchParams(location.search);
    const p = params.get("p");
    const ttl = params.get("ttl");
    const mcht = params.get("mcht");
    const s = params.get("s");
    const sw = params.get("sw");
    const [isLoading, setIsLoading] = useState(false);
    const [machine, setMachine] = useState(undefined);
    const [isAlertmachine_price, setIsAlertmachine_price] = useState(false);
    const [isAlertDeviceName, setIsAlertDeviceName] = useState(false);
    const [editMachine, { data, error }] = useMutation(UPDATE_MACHINE);

    const [addMachine, dataAddMachine] = useMutation(ADD_MACHINE);

    const [SetConfigMachine, setConfigMachine] = useMutation(SET_CONFIG_MACHINE);
    const machine_name = localStorage.getItem("machine_name");
    const machine_sub_group = localStorage.getItem("machine_sub_group");
    const device_name = localStorage.getItem("device_name");
    // console.log("MachineAddEditPageV2-data->🙂", machine_name, device_name);
    const GetConfigMachine = QueryGraphQL_ConfigMachineByDeviceName(GET_CONFIG_MACHINE, device_name);
    // console.log("-Config-data->🙂🧟‍♂️", GetConfigMachine);
    const dataConfig = GetConfigMachine.data?.getConfigMachine;

    const [selectValue, setSelectValue] = React.useState("");
    const onChange = (event) => {
        const value = event.target.value;
        setSelectValue(value);
    };
    useEffect(() => {
        if (dataConfig) {
            setMachine(dataConfig);
        }
    }, [dataConfig]);
    useEffect(() => {
        if (isLoading) {
            if (setConfigMachine.data != null) {
                setIsLoading(false);
                openNotification("success", "Success !", "Edit & Config machine successful.");
                // localStorage.removeItem("machine_sub_group");
                setTimeout(
                    () =>
                        history.push(
                            `/machine/list/v2${getSearchParams(
                                ["p", "ttl", "mcht", "s", "sw"],
                                [p, ttl, mcht, s, sw]
                            )}`
                        ),
                    1000
                );
            }
        }
    }, [error, data, dataAddMachine]);
    const Save_EditConfigMachine = async () => {
        if (!(machine && machine.machine_price)) {
            if (!(machine && machine.machine_price)) setIsAlertDeviceName(true);
        } else {
            let data = { ...machine };
            setIsLoading(true);
            if (machineId) {
                SetConfigMachine({
                    variables: {
                        deviceName: device_name ? device_name + "" : "",
                        value: machine.machine_price ? machine.machine_price + "" : "",
                        option: selectValue
                    }
                })

            }

        }

    };

    if (machine_sub_group === "เครื่องมาตรฐาน") {
        return (
            <div>
                <div className="mg-bt-20 h-d-t-p pt-3">
                    Set Config Machine {" "}
                    {machineId
                        ? ` (
        ${device_name ?? ""})`
                        : ""}
                </div>
                <div className="machine-add-container-grid-with-two">
                    <div className="machine-add-container-grid-with-two-order-1">
                        <div className="machine-add-container-grid">
                            <div className="f-s-c">MAC Address</div>
                            <div>
                                <Input
                                    value={(machine && machine.mac) || ""}
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                    <div className="machine-add-container-grid-with-two-order-2">
                        <div className="machine-add-container-grid">
                            <div className="f-s-c">ประเภทเครื่อง</div>
                            <div>
                                <Input
                                    value={machine_sub_group}
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="machine-add-container-grid">
                    <div className="f-s-c">หมายเลขเครื่อง</div>
                    <div>
                        <Input
                            value={(machine && machine.machine_no) || ""}
                            // onChange={(e) => {
                            //     setIsAlertMacAddress(false);
                            //     setMachine({ ...machine, machine_no: e.target.value });
                            // }}
                            disabled
                        />
                    </div>
                    <div className="f-s-c">ราคา</div>
                    <div>
                        <Input
                            style={{ borderColor: isAlertmachine_price ? "#EC1C24" : null }}
                            placeholder="machine_price"
                            value={(machine && machine.machine_price) || ""}
                            onChange={(e) => {
                                setIsAlertmachine_price(false);
                                setMachine({ ...machine, machine_price: e.target.value });
                            }}
                        />
                    </div>
                    <div className="f-s-c">เวลา หน่วยนาที</div>
                    <div>
                        <Input
                            value={(machine && machine.machine_time) || ""}
                            disabled
                        />
                    </div>
                    <div className="f-s-c">โหมดการทำงาน</div>
                    <div>
                        <Input
                            value={(machine && machine.machine_mode) || ""}
                            disabled
                        />
                    </div>
                    <div className="f-s-c">เฟิร์มแวร์</div>
                    <div>
                        <Input
                            value={(machine && machine.fw) || ""}
                            disabled
                        />
                    </div>
                    <div className="f-s-c">วันที่อัพเดท</div>
                    <div>
                        <Input
                            value={(machine && machine.fw_date) || ""}
                            disabled
                        />
                    </div>
                    <div className="f-s-c">อัพเดทอะไรเพิ่ม</div>
                    <div>
                        <Input
                            value={(machine && machine.fw_note) || ""}
                            disabled
                        />
                    </div>
                    <div className="f-s-c">เวอร์ชั่นฮาร์ดแวร์</div>
                    <div>
                        <Input
                            value={(machine && machine.hw) || ""}
                            disabled
                        />
                    </div>
                </div>
                <div className="f-c-c s-e-b">
                    <div
                        className="f-c-c d-b-t-n-s d-bg-c-t"
                        style={{ width: "170px", pointerEvents: isLoading ? "none" : null }}
                        onClick={() => Save_EditConfigMachine()}
                    >
                        {isLoading ? (
                            <LoadingOutlined />
                        ) : (
                            `${machineId ? "Edit" : "Add New"} Config Machine`
                        )}
                    </div>
                </div>
            </div>
        )
    } else if (machine_sub_group === "เครื่องพานิช" || machine_sub_group === "LG commercial") {
        return (
            <div>
                <div className="mg-bt-20 h-d-t-p pt-3">
                    Set Config Machine {" "}
                    {machineId
                        ? ` (
        ${device_name ?? ""})`
                        : ""}
                </div>

                <div className="machine-add-container-grid-with-two">
                    <div className="machine-add-container-grid-with-two-order-1">
                        <div className="machine-add-container-grid">
                            <div className="f-s-c">เฟิร์มแวร์ / SW</div>
                            <div>
                                <Input
                                    value={(machine && machine.sw) || ""}
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                    <div className="machine-add-container-grid-with-two-order-2">
                        <div className="machine-add-container-grid">
                            <div className="f-s-c">ประเภทเครื่อง</div>
                            <div>
                                <Input
                                    value={machine_sub_group}
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="machine-add-container-grid">
                    <div className="f-s-c">หมายเลขเครื่องซัก</div>
                    <div>
                        <Input
                            value={(machine && machine.wash_no) || ""}
                            disabled
                        />
                    </div>
                    <div className="f-s-c">ราคาซักน้ำเย็น</div>
                    <div>
                        <Input
                            value={(machine && machine.wp1) || ""}
                            disabled
                        />
                    </div>
                    <div className="f-s-c">ราคาซักน้ำอุ่น</div>
                    <div>
                        <Input
                            value={(machine && machine.wp2) || ""}
                            disabled
                        />
                    </div>
                    <div className="f-s-c">ราคาซักน้ำร้อน</div>
                    <div>
                        <Input
                            value={(machine && machine.wp3) || ""}
                            disabled
                        />
                    </div>

                    <div className="f-s-c">หมายเลขเครื่องอบ</div>
                    <div>
                        <Input
                            value={(machine && machine.dry_no) || ""}
                            disabled
                        />
                    </div>
                    <div className="f-s-c">ราคาอบความร้อนต่ำ</div>
                    <div>
                        <Input
                            value={(machine && machine.dp1) || ""}
                            disabled
                        />
                    </div>
                    <div className="f-s-c">ราคาอบความร้อนกลาง</div>
                    <div>
                        <Input
                            value={(machine && machine.dp2) || ""}
                            disabled
                        />
                    </div>
                    <div className="f-s-c">ราคาอบความร้อนสูง</div>
                    <div>
                        <Input
                            value={(machine && machine.dp3) || ""}
                            disabled
                        />
                    </div>
                    <div className="f-s-c">เพิ่มเวลาอบ</div>
                    <div>
                        <Input
                            value={(machine && machine.dry_add_timer) || ""}
                            disabled />
                    </div>
                    <div className="f-s-c">เฟิร์มแวร์</div>
                    <div>
                        <Input
                            value={(machine && machine.sw) || ""}
                            disabled
                        />
                    </div>
                    <div className="f-s-c">วันที่อัพเดท</div>
                    <div>
                        <Input
                            value={(machine && machine.fw) || ""}
                            disabled
                        />
                    </div>
                    <div className="f-s-c">อัพเดทอะไรเพิ่ม</div>
                    <div>
                        <Input
                            value={(machine && machine.fwn) || ""}
                            disabled
                        />
                    </div>
                    <div className="f-s-c">แก้ไขราคา</div>
                    <div className="f-s-c"></div>
                    <div className="f-s-c">โหมดการทำงาน</div>
                    <div>
                        <select onChange={onChange} className="form-select">
                            <option defaultValue>
                                กรุณาเลือกโหมด
                            </option>
                            <option value="wp1">ราคาซักน้ำเย็น</option>
                            <option value="wp2">ราคาซักน้ำอุ่น</option>
                            <option value="wp3">ราคาซักน้ำร้อน</option>
                            <option value="dp1">ราคาอบความร้อนต่ำ</option>
                            <option value="dp2">ราคาอบความร้อนกลาง</option>
                            <option value="dp3">ราคาอบความร้อนสูง</option>
                        </select>
                    </div>
                    <div className="f-s-c">ราคา</div>
                    <div>
                        <Input
                            style={{ borderColor: isAlertmachine_price ? "#EC1C24" : null }}
                            placeholder="machine_price"
                            value={(machine && machine.machine_price) || ""}
                            onChange={(e) => {
                                setIsAlertmachine_price(false);
                                setMachine({ ...machine, machine_price: e.target.value });
                            }}
                        />
                    </div>
                    {/* <div className="f-s-c">ราคา</div>
                    <div>
                        <Input
                            style={{ borderColor: isAlertmachine_price ? "#EC1C24" : null }}
                            placeholder="machine_price"
                            id="message"
                            name="message"
                            onChange={handleChange}
                        />
                    </div> */}
                </div>
                <div className="f-c-c s-e-b">
                    <div
                        className="f-c-c d-b-t-n-s d-bg-c-t"
                        style={{ width: "170px", pointerEvents: isLoading ? "none" : null }}
                        onClick={() => Save_EditConfigMachine()}
                    >
                        {isLoading ? (
                            <LoadingOutlined />
                        ) : (
                            `${machineId ? "Edit" : "Add New"} Config Machine`
                        )}
                    </div>
                </div>
                <div>

                </div>


            </div>
        )
    }
    else if (machine_sub_group === "เครื่องอุตสาหกรรม") {
    } else {
        history.push(
            `/machine/list/v2${getSearchParams(
                ["p", "ttl", "mcht", "s", "sw"],
                [p, ttl, mcht, s, sw]
            )}`
        )
        return (

            <div>
                <div className="mg-bt-20 h-d-t-p pt-3">
                    Not Data V.2 Config Machine {" "}
                    {machineId
                        ? ` (
${device_name ?? ""})`
                        : ""}
                </div>
            </div>
        );
    }

};

export default MachineSetconfig;