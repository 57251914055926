import React, { useState, useEffect } from 'react'
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { Input, InputNumber, Avatar } from 'antd';
// import './News.css'
import { LoadingOutlined } from '@ant-design/icons';

import { checkImageSize, openNotification, checkReqFieldWithId, getSearchParams } from '../../utils/function'
import { apiWAddNews, apiWGetNewsById, apiWEditNewsById  } from '../../services/apis/washerNews';

const NewsAddEditWasher = () => {
  const { newsId } = useParams();
  const location = useLocation();
  const history = useHistory();

  const params = new URLSearchParams(location.search)
  const p = params.get('p')
  const ttl = params.get('ttl')
  const sw = params.get('sw')

  const [isLoading, setIsLoading] = useState(false)
  const [news, setNews] = useState(undefined)

  const [isAlertTitle, setIsAlertTitle] = useState(false)
  const [isAlertImage, setIsAlertImage] = useState(false)
  const [isAlertLink, setIsAlertLink] = useState(false)
  const [isAlertOrder, setIsAlertOrder] = useState(false)

  useEffect(() => {
    if (newsId) getData()
    else {
      setNews(undefined)
      setIsAlertTitle(false)
      setIsAlertImage(false)
      setIsAlertLink(false)
      setIsAlertOrder(false)
    }
  }, [newsId])

  const getData = async () => {
    let data = await apiWGetNewsById(newsId)
    if (data && data.status) {
      let news = { ...data.result }
      let link = data.result.news_link || ''

      if (link.substring(0, 7) === 'http://') news.news_link = link.substring(7)
      else if (link.substring(0, 8) === 'https://') news.news_link = link.substring(8)

      setNews(news)
    }
  }

  const addEditPromotion = async () => {
    let passIMF = checkReqFieldWithId(newsId, news && news.image_file)

    if (
      !(news && news.news_title) ||
      !passIMF ||
      !(news && news.news_link) ||
      (newsId && isNaN(news && news.news_order))
    ) {
      if (!(news && news.news_title)) setIsAlertTitle(true)
      if (!passIMF) setIsAlertImage(true)
      if (!(news && news.news_link)) setIsAlertLink(true)
      if (newsId && isNaN(news && news.news_order)) setIsAlertOrder(true)

    } else {
      setIsLoading(true)

      let data = new FormData()
      if (newsId) data.append('news_id', newsId)
      data.append('news_title', news.news_title)
      data.append('news_desc', news.news_desciption || '')
      if (news.image_file) data.append('news_img', news.image_file)
      data.append('news_link', `https://${news.news_link}`)
      if (newsId) data.append('news_order', news.news_order)

      let newData = newsId ? await apiWEditNewsById(data) : await apiWAddNews(data)
      if (newData && newData.status) {
        setIsLoading(false)
        openNotification('success', 'Success !', `${newsId ? 'Edit' : 'Add new'} news successful.`)
        setTimeout(() => history.push(`/washer/news${getSearchParams(['p', 'ttl', 'sw'], [p, ttl, sw])}`), 1000);
      } else {
        setIsLoading(false)
        openNotification('error', 'Failed !', `Failed to ${newsId ? 'edit' : 'add new'} news.`)
      }
    }
  }

  return (
    <div>
      <div className="mg-bt-20 h-d-t-p">News - {newsId ? 'Edit' : 'Add'}</div>

      <div className="t-m-c-i-p-g">
        <div className="f-s-c">Title</div>
        <div>
          <Input
            style={{ borderColor: isAlertTitle ? '#EC1C24' : null }}
            placeholder="Title"
            value={news && news.news_title || ''}
            onChange={e => {
              setIsAlertTitle(false)
              setNews({ ...news, news_title: e.target.value })
            }}
          />
        </div>

        <div className="f-s-c">Description</div>
        <div>
          <Input.TextArea
            autoSize={{ minRows: 3 }}
            placeholder="Description"
            value={news && news.news_desciption || ''}
            onChange={e => setNews({ ...news, news_desciption: e.target.value })}
          />
        </div>

        <div className="f-s-c">Image (ขนาด 40*40)</div>
        <div>
          <input id="news-image" accept="image/*" type="file" style={{ display: 'none' }}
            onChange={e => {
              setIsAlertImage(false)
              let passSize = checkImageSize(true, e.target.files[0])
              if (passSize) {
                const reader = new FileReader()
                reader.readAsDataURL(e.target.files[0])
                reader.onload = () => setNews({
                  ...news,
                  image_file: e.target.files[0],
                  news_img: reader.result
                })
              }
            }} />
          <label htmlFor="news-image">
            <div className={`antd-avt-al ${isAlertImage && 'antd-avt-al-bd' || ''}`}>
              <Avatar shape="square" size={150} className="cs-pt" src={news && news.news_img || ''} icon={<i className="bi bi-image"></i>} />
            </div>
          </label>
        </div>

        <div className="f-s-c">Link</div>
        <div className={isAlertLink && 'antd-ip-g-al-bd' || ''}>
          <Input
            placeholder="Link ex. www.google.com or google.com"
            addonBefore="https://"
            value={news && news.news_link || ''}
            onChange={e => {
              setIsAlertLink(false)
              setNews({ ...news, news_link: e.target.value })
            }}
          />
        </div>

        <div className="f-s-c">Order</div>
        <div>
          <InputNumber min={0}
            step={1}
            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
            style={{ width: '100%', borderColor: isAlertOrder ? '#EC1C24' : null }}
            placeholder="Order"
            value={news && news.news_order}
            onChange={value => {
              setIsAlertOrder(false)
              setNews({ ...news, news_order: Math.round(value) || 0 })
            }}
          />
        </div>
      </div>

      <div className="f-c-c s-e-b">
        <div className="f-c-c d-b-t-n-s d-bg-c-t"
          style={{ width: '155px', pointerEvents: isLoading ? 'none' : null }}
          onClick={() => addEditPromotion()}
        >{isLoading ? <LoadingOutlined /> : `${newsId ? 'Edit' : 'Add New'} News`}</div>
      </div>

    </div>
  )
}

export default NewsAddEditWasher
