import axios from '../../config/axios';
import axios_mid from '../../config/axios-tw';
const apiSGetSite = async () => {
  try {
    const result = await axios.get('/site/dropdown')
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiSGetZoneBySiteId = async (site_id) => {
  try {
    const result = await axios.get(`/site/zone/dropdown?site_id=${site_id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiSGetSitePromotion = async (get_all, cur_page, per_page, keyword) => {
  try {
    const result = await axios.get(`/site/promotion/list?get_all=${get_all}&cur_page=${cur_page}&per_page=${per_page}&keyword=${keyword}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiSGetMachineBySiteId = async (site_id) => {
  try {
    const result = await axios.get(`/site/promotion/machine?site_id=${site_id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiSGetProgramByMachineId = async (machine_id) => {
  try {
    const result = await axios.get(`/site/promotion/machine/program?machine_id=${machine_id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiSAddDiscount = async (data) => {
  try {
    const result = await axios.post('/site/promotion/discount', data)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiSAddFree = async (data) => {
  try {
    const result = await axios.post('/site/promotion/free', data)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiSAddTimeCount = async (data) => {
  try {
    const result = await axios.post('/site/promotion/timeCount', data)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiSAddPoint = async (data) => {
  try {
    const result = await axios.post('/site/promotion/point', data)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiSDeleteSitePromotion = async (site_promotion_id) => {
  try {
    const result = await axios.delete(`/site/promotion?site_promotion_id=${site_promotion_id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiSGetSiteManagement = async (get_all, cur_page, per_page, keyword) => {
  try {
    const result = await axios.get(`/site/list?get_all=${get_all}&cur_page=${cur_page}&per_page=${per_page}&keyword=${keyword}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiSGetSiteManagementGroups = async () => {
  try {
    const result = await axios.get('/site/group/dropdown')
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiSDeleteSiteManagement = async (site_id) => {
  try {
    const result = await axios.delete(`/site?site_id=${site_id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiSAddSiteManagement = async (data) => {
  try {
    const result = await axios.post('/site', data)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiSGetSiteManagementById = async (site_id) => {
  try {
    const result = await axios.get(`/site?site_id=${site_id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiSEditSiteManagement = async (data) => {
  try {
    const result = await axios.put('/site', data)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiSGetSiteManagementQR = async (machine_id, product_id) => {
  try {
    const result = await axios.get(`/site/qrcode?machine_id=${machine_id}&product_id=${product_id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}


const apiSetWasher = async (data) => {
  try {
    const result = await axios.put(`/washer/site`,data)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}
const apiSite_middleware = async (data) => {
  try {
    const result = await axios_mid.get(`/site/site-all`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiSGetMachineBySiteId_middleware = async (site_code) => {
  try {
    const result = await axios_mid.post(`/machine/machine-BYsiteCode`,site_code)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}
export {
  apiSGetSite,
  apiSGetZoneBySiteId,
  apiSGetSitePromotion,
  apiSGetMachineBySiteId,
  apiSGetProgramByMachineId,
  apiSAddDiscount,
  apiSAddFree,
  apiSAddTimeCount,
  apiSAddPoint,
  apiSDeleteSitePromotion,

  apiSGetSiteManagement,
  apiSGetSiteManagementGroups,
  apiSDeleteSiteManagement,
  apiSAddSiteManagement,
  apiSGetSiteManagementById,
  apiSEditSiteManagement,
  apiSGetSiteManagementQR,

  apiSetWasher,

  apiSite_middleware,
  apiSGetMachineBySiteId_middleware
}