import { configureStore } from "@reduxjs/toolkit";
import dataReducers from "./data";
import dataReducersV2 from "./data-v2";
import placeReducers from "./place";
import machineReducers from "./machine";

export default configureStore({
  reducer: {
    data: dataReducers,
    place: placeReducers,
    machine: machineReducers,
    dataV2: dataReducersV2,
  },
});
