import axios from "../../config/axios";
import _axios from "axios";
import { baseURL2 } from "../../config/config";
import axiostw from '../../config/axios-tw';
const axios2 = _axios.create({ baseURL: baseURL2 });

const apiCTMGetCustomer = async (get_all, cur_page, per_page, keyword) => {
  try {
    const result = await axios.get(
      `/customer/list?get_all=${get_all}&cur_page=${cur_page}&per_page=${per_page}&keyword=${keyword}`
    );
    return result && result.data;
  } catch (error) {
    return error && error.response && error.response.data;
  }
};

const apiCTMGetCustomerHistoryById = async (
  get_all,
  cur_page,
  per_page,
  customer_id
) => {
  try {
    const result = await axios.get(
      `/customer/history?get_all=${get_all}&cur_page=${cur_page}&per_page=${per_page}&customer_id=${customer_id}`
    );
    return result && result.data;
  } catch (error) {
    return error && error.response && error.response.data;
  }
};

const apiCTMGetCustomerById = async (customer_id) => {
  try {
    const result = await axios.get(`/customer?customer_id=${customer_id}`);
    return result && result.data;
  } catch (error) {
    return error && error.response && error.response.data;
  }
};

const apiCTMAddCustomer = async (data) => {
  try {
    const result = await axios.post("/customer", data);
    return result && result.data;
  } catch (error) {
    return error && error.response && error.response.data;
  }
};

const apiCTMEditCustomerById = async (data) => {
  try {
    const result = await axios.put("/customer", data);
    return result && result.data;
  } catch (error) {
    return error && error.response && error.response.data;
  }
};

const apiCTMEditCustomerPasswordById = async (data) => {
  try {
    const result = await axios.put("/customer/password", data);
    console.log(result)
    return result && result.data;
  } catch (error) {
    return error && error.response && error.response.data;
  }
};
const apiAxiostw_CTMEditCustomerPasswordById = async (data) => {
  try {
    const result = await axiostw.post("/customer/Addcredit", data);
    return result && result.data;
  } catch (error) {
    return error && error.response && error.response.data;
  }
};
const apiAxiostw_DeleteCTMCustomer = async (data) => {
  try {
    const result = await axiostw.post("/customer/Deletecredit", data);
    return result && result.data;
  } catch (error) {
    return error && error.response && error.response.data;
  }
};
const apiCTMDeleteCustomerById = async (customer_id) => {
  try {
    const result = await axios.delete(`/customer?customer_id=${customer_id}`);
    return result && result.data;
  } catch (error) {
    return error && error.response && error.response.data;
  }
};

const apiCTMUpdateCreditByCustomerId = async (path, data) => {
  try {
    const result = await axios.post(`/customer/${path}`, data);
    return result && result.data;
  } catch (error) {
    return error && error.response && error.response.data;
  }
};

const apiCTMRefundHistoryByCustomerId = async (data) => {
  try {
    const result = await axios.put("/customer/history/refund", data);
    return result && result.data;
  } catch (error) {
    return error && error.response && error.response.data;
  }
};

const apiCTMGetAllCustomer = async (keyword) => {
  try {
    const result = await axios.get(
      `/customer/list/dropdown?keyword=${keyword}`
    );
    return result && result.data;
  } catch (error) {
    return error && error.response && error.response.data;
  }
};

const apiCreditCustomer = async (customer_id) => {
  try {
    const result = await axios2.get(
      `/customer_credits.php?checkcredit=1&customer_id=${customer_id}`
    );
    return result;
  } catch (error) {
    return error && error.response && error.response.data;
  }
};

export {
  apiCTMGetCustomer,
  apiCTMGetCustomerHistoryById,
  apiCTMGetCustomerById,
  apiCTMAddCustomer,
  apiCTMEditCustomerById,
  apiCTMEditCustomerPasswordById,
  apiCTMDeleteCustomerById,
  apiCTMUpdateCreditByCustomerId,
  apiCTMRefundHistoryByCustomerId,
  apiCTMGetAllCustomer,
  apiCreditCustomer,
  apiAxiostw_CTMEditCustomerPasswordById,
  apiAxiostw_DeleteCTMCustomer,
};
