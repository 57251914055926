import axios from '../../config/axios';

const apiBRCGetBroadcast = async (get_all, start_date, end_date, cur_page, per_page, keyword) => {
  try {
    const result = await axios.get(`/boardcast/list?get_all=${get_all}&start_date=${start_date}&end_date=${end_date}&cur_page=${cur_page}&per_page=${per_page}&keyword=${keyword}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiBRCGetBroadcastPotential = async (data) => {
  try {
    const result = await axios.post('/boardcast/potential', data)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiBRCAddBroadcast = async (data) => {
  try {
    const result = await axios.post('/boardcast', data)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiBRCGetBroadcastById = async (id) => {
  try {
    const result = await axios.get(`/boardcast?id=${id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiBRCDeleteBroadcastById = async (id) => {
  try {
    const result = await axios.delete(`/boardcast?id=${id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

export {
  apiBRCGetBroadcast,
  apiBRCGetBroadcastPotential,
  apiBRCDeleteBroadcastById,
  apiBRCAddBroadcast,
  apiBRCGetBroadcastById,
}