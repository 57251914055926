import React, { useState, useEffect } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Input, Table, Pagination, Tooltip, Modal, Select, InputNumber } from 'antd';
import './WasherList.css'
import XLSX from "xlsx";
import { LoadingOutlined } from '@ant-design/icons';

import moment from 'moment';
import 'moment/locale/th'

import { downloadQR, openNotification, getSearchParams, numberWithCommas } from '../../utils/function'
import { apiWGetBag, apiSetCusBag } from '../../services/apis/bag';
import { apiCTMGetAllCustomer } from '../../services/apis/customer';

const { Option } = Select;

var QRCode = require('qrcode.react');


const BagList = () => {
  const history = useHistory();
  const location = useLocation();

  const params = new URLSearchParams(location.search)
  const p = params.get('p')
  const ttl = params.get('ttl')
  const sw = params.get('sw')

  const menuList = (localStorage.getItem('list') || '').split(',')
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(Number(p) || 1)
  const [perPage, setPerPage] = useState(Number(ttl) || 20)
  const [tableData, setTableData] = useState(undefined)
  const [searchWord, setSearchWord] = useState(sw || '')
  const [searchButton, setSearchButton] = useState(false)
  const [waitingForCSV, setWaitingForCSV] = useState(false)
  const [customerList, setCustomerList] = useState([])

  const [isModalSetting, setIsModalSetting] = useState(false)
  const [isLoadingSetting, setIsLoadingSetting] = useState(false)

  const [customer, setCustomer] = useState(undefined)
  const [prefix, setPrefix] = useState("")
  const [startCode, setStartCode] = useState(undefined)
  const [endCode, setEndCode] = useState(undefined)


  const [visible, setVisible] = useState(false)
  const [visibleGen, setVisibleGen] = useState(false)

  const [modalTitle, setModalTitle] = useState('')
  const [modalId, setModalId] = useState(undefined)
  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false)

  const [isAlertCustomer, setIsAlertCustomer] = useState(false)
  const [isAlertPrefix, setIsAlertPrefix] = useState(false)
  const [isAlertStart, setIsAlertStart] = useState(false)
  const [isAlertEnd, setIsAlertEnd] = useState(false)


  const [sumaries, setSumaries] = useState([])

  useEffect(() => {
    getTableData()
    history.push(`${location.pathname}${getSearchParams(['p', 'ttl', 'sw'], [currentPage, perPage, searchWord])}`)
  }, [currentPage, perPage, searchButton])

  const getTableData = async () => {
    setIsLoading(true)
    let bag = await apiWGetBag(false, currentPage, perPage, searchWord)
    if (bag && bag.status) {
      setIsLoading(false)
      setTableData(bag.result)
    }
  }


  useEffect(() => {
    if (tableData) setSumaries([...(tableData.sumary || [])])
  }, [tableData])


  useEffect(() => {
    const getStaticDropdown = async () => {
      let customer = await apiCTMGetAllCustomer('')
      if (customer && customer.status) setCustomerList(customer.result || [])
    }
    getStaticDropdown()
  }, [])



  const columns = [
    {
      title: '#',
      ellipsis: true,
      align: 'center',
      width: '5%',
      render: (data, record, index) => <div>{(index + 1) + ((currentPage - 1) * perPage)}</div>
    },
    {
      title: 'Bag Size',
      ellipsis: true,
      render: (data) => <div>{data.bag_size || '-'}</div>
    },
    {
      title: 'Bag Name',
      ellipsis: true,
      render: (data) => <div>{data.bag_name || '-'}</div>
    },
    {
      title: 'Customer',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{data.customer_name || '-'}</div>
    },
    {
      title: 'Register Date',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{data.register_date ? moment(data.register_date).add(543, 'years').format('D MMM YYYY HH:mm:ss') : '-'}</div>
    },
    {
      title: 'Qr Code',
      ellipsis: true,
      align: 'center',
      render: (data) => <div className="cs-pt" onClick={() => downloadQR(data.bag_name || '', 'site-list-qr')}>
        <QRCode id="site-list-qr" size={80} value={data.bag_name} includeMargin={true} />
      </div>
    },
    {
      title: 'Manage',
      ellipsis: true,
      align: 'center',
      fixed: 'right',
      render: (data) => <div className="f-c-c tb-cl-a">
        {menuList.includes('WASHERMAN') && <Tooltip placement="top" title="Setting">
          <div className="f-c-c d-b-t-n-s b-c-o-i" style={{ backgroundColor: '#FFCB05' }}
            onClick={(e) => {
              e.stopPropagation()
              setIsModalSetting(true)
              setModalId(data.bag_id)
            }}
          ><i className="fas fa-cogs"></i></div>
        </Tooltip>}
        {menuList.includes('WASHERMAN') && <Tooltip placement="top" title="Cancel registration">
          <div className="f-c-c d-b-t-n-s b-c-o-i" style={{ backgroundColor: '#ED2228' }}
            onClick={(e) => {
              e.stopPropagation()
              setModalId(data.bag_id)
              setModalTitle(data.bag_name)
              setVisible(true)
            }}
          ><i class="far fa-registered"></i></div>
        </Tooltip>}
      </div>
    },
  ];

  const cancelRegisterBag = async () => {
    if (!modalId) {
      openNotification('error', 'Failed !', 'Failed to cancel register this user.')
      return
    }

    setIsLoadingConfirm(true)
    let setValue = await apiSetCusBag(modalId, 0)
    if (setValue && setValue.status) {

      setVisible(false)
      setIsLoadingConfirm(false)
      getTableData()
      openNotification('success', 'Success !', `Cancel register successful.`)
    } else {
      setIsLoadingConfirm(false)
      openNotification('error', 'Failed !', `Failed to cancel register.`)
    }
  }

  const toCSVFile = async () => {
    setWaitingForCSV(true)
    let data = await apiWGetBag(true, '', '', searchWord)
    let csvData = [];

    if (data && data.status) {
      (data.result.data_list || []).map((data, idx) => {
        let resultRow = {}
        resultRow['#'] = idx + 1
        resultRow['Bag size'] = data.bag_size || '-'
        resultRow['Bag Name'] = data.bag_name || '-'
        resultRow['Customer'] = data.customer_name || '-'
        resultRow['Register Date'] = data.register_date || '-'
        csvData.push(resultRow);
      })

      const toCSV = XLSX.utils.json_to_sheet(csvData);
      const wb = XLSX.utils.book_new();

      const fileName = 'BAG_LIST'
      XLSX.utils.book_append_sheet(wb, toCSV, fileName);
      XLSX.writeFile(wb, fileName + ".csv");

      setWaitingForCSV(false)
    }
  }

  const setCusBag = async () => {
    if (!(customer)
    ) {
      if (!(customer)) setIsAlertCustomer(true)

    } else {
      setIsLoading(true)
      let setValue = await apiSetCusBag(modalId, customer._customer_id)
      if (setValue && setValue.status) {
        setIsLoading(false)
        setIsModalSetting(false)
        getTableData()
        setCustomer(undefined)
        openNotification('success', 'Success !', `Cancel register successful.`)
      } else {
        setIsLoading(false)
        openNotification('error', 'Failed !', `Failed to cancel register.`)
      }
    }
  }


  const generateQrBag = async () => {
    if (!(prefix) ||
      !(startCode) ||
      !(endCode)
    ) {
      if (!(prefix)) setIsAlertPrefix(true)
      if (!(startCode)) setIsAlertStart(true)
      if (!(endCode)) setIsAlertEnd(true)


    } else {
      setIsLoadingSetting(true)
      let new_prefix = prefix.toUpperCase()

      // window.open("https://backoffice.trendywash.net/api/v1/washer/bag/qrcode_multi?start_code=" + startCode + "&end_code=" + endCode + "&prefix=" + new_prefix, "_blank")
      window.open(`${process.env.REACT_APP_GO_BASE_URL}/washer/bag/qrcode_multi?start_code=${startCode}&end_code=${endCode}&prefix=${new_prefix}`, "_blank")

      setPrefix("")
      setStartCode(undefined)
      setEndCode(undefined)
      setVisibleGen(false)

      setTimeout(() => {
        getTableData()
        setIsLoadingSetting(false)
      }, 200)
    }
  }

  return (
    <div>
      <div className="mg-bt-20 coin-collect-title">
        <div className="h-d-t-p">Bag List</div>
        <div className="coin-collect-card-list">
          {sumaries.map((list, idx) => <div key={idx} className="c-c-t-s">
            <div className="coin-collect-card">
              <div className="coin-collect-card-grid">
                <div>{list.name}</div>
                <div>{numberWithCommas(list.value || 0, 0)}</div>
              </div>
            </div>
          </div>)}
        </div>

      </div>

      <div className={`pro-reward-header-grid pro-reward-header-grid-column-${!menuList.includes('WASHERMAN')}`}>
        <div className={`pro-reward-header-grid-search-${!menuList.includes('WASHERMAN')}`}>
          <Input
            placeholder="search .."
            value={searchWord}
            onChange={e => setSearchWord(e.target.value)}
            onPressEnter={() => {
              setCurrentPage(1)
              setSearchButton(!searchButton)
            }}
          />
        </div>
        <div className="f-c-c d-b-t-n-s d-bg-c-t"
          onClick={() => {
            setCurrentPage(1)
            setSearchButton(!searchButton)
          }}>
          <i className="bi bi-search" style={{ lineHeight: '0' }}></i>
        </div>
        {menuList.includes('WASHERMAN') && <div className={`f-c-c d-b-t-n-s d-bg-c-t h-32 pro-reward-header-grid-export-${!menuList.includes('EXPORT')}`}
          onClick={() =>
            setVisibleGen(true)
          }
        >
          Create QR CODE Bag
        </div>}
        <div className={`f-c-c d-b-t-n-s d-bg-c-t h-32 pro-reward-header-grid-export-${!menuList.includes('EXPORT')}`}
          style={{ pointerEvents: waitingForCSV ? 'none' : null }}
          onClick={() => toCSVFile()}
        >
          {waitingForCSV ? <LoadingOutlined /> : 'Export CSV'}
        </div>
      </div>

      <Table bordered
        size="small"
        rowKey={record => record.customer_id}
        loading={isLoading}
        dataSource={tableData && tableData.data_list || []}
        columns={columns}
        pagination={false}
        scroll={{ x: true }}
      />

      <div className="pgnt-st">
        <Pagination
          size="small"
          current={currentPage}
          onChange={page => setCurrentPage(page)}
          total={tableData && tableData.max_total_rows}
          pageSize={perPage}
          showSizeChanger={false}
        />
      </div>

      <Modal
        title=""
        visible={visible}
        closable={false}
        footer={false}
        centered
      >
        <div className="m-t-i-c" style={{ color: '#FFCB05' }}>
          <i className="bi bi-exclamation-triangle-fill"></i>
        </div>
        <div className="m-t-t-q">Do you want to cancel register '{modalTitle}' ?</div>
        <div className="m-b-y-n-g">
          <div className="f-c-c d-b-t-n-s b-c-o-i" style={{ backgroundColor: '#cccccc' }} onClick={() => setVisible(false)}>Cancel</div>
          <div className="f-c-c d-b-t-n-s b-c-o-i"
            style={{ backgroundColor: '#3f4d67', pointerEvents: isLoadingConfirm ? 'none' : null }}
            onClick={() => cancelRegisterBag()}
          >{isLoadingConfirm ? <LoadingOutlined /> : 'Confirm'}</div>
        </div>
      </Modal>


      <Modal
        title=""
        visible={isModalSetting}
        closable={false}
        footer={false}
        centered
      >

        <div style={{ marginBottom: "15px" }}>
          <div style={{ marginBottom: "15px" }} className="f-s-c">Customer</div>
          <div>
            <div className={isAlertCustomer && 'antd-sl-al-bd' || ''}>
              <Select style={{ width: '100%' }}
                placeholder="Customer"
                showSearch
                value={customer && customer._customer_id}
                onChange={value => {
                  setIsAlertCustomer(false)
                  setCustomer({ _customer_id: value })
                }}
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {customerList.map((customer, idx) => <Option key={idx} value={customer.key}>{customer.customer_name}</Option>)}
              </Select>
            </div>
          </div>

        </div>




        <div className="m-b-y-n-g">
          <div className="f-c-c d-b-t-n-s b-c-o-i" style={{ backgroundColor: '#cccccc' }} onClick={() => setIsModalSetting(false)}>Cancel</div>
          <div className="f-c-c d-b-t-n-s b-c-o-i"
            style={{ backgroundColor: '#3f4d67', pointerEvents: isLoadingSetting ? 'none' : null }}
            onClick={() => setCusBag()}
          >{isLoadingSetting ? <LoadingOutlined /> : 'Confirm'}</div>
        </div>



      </Modal>


      <Modal
        title=""
        visible={visibleGen}
        closable={false}
        footer={false}
        centered
      >

        <div>
          <div style={{ marginBottom: "10px" }} className="f-s-c">Prefix</div>
          <div style={{ marginBottom: "10px" }}>
            <div className={isAlertPrefix && 'antd-sl-al-bd' || ''}>
              <Input
                onChange={(e) => {
                  setIsAlertPrefix(false)
                  setPrefix(e.target.value)
                }}
                value={prefix}
                placeholder="S,M,L" />
            </div>
          </div>

          <div style={{ marginBottom: "10px" }} className="f-s-c">Start Code</div>
          <div style={{ marginBottom: "10px" }}>
            <div>
              <InputNumber
                onChange={(e) => {
                  setIsAlertStart(false)
                  setStartCode(e)
                }}
                style={{ width: '100%', borderColor: isAlertStart ? '#EC1C24' : null }}
                value={startCode}
                min={1}
                placeholder="1" />
            </div>
          </div>


          <div style={{ marginBottom: "10px" }} className="f-s-c">End Code</div>
          <div style={{ marginBottom: "10px" }}>
            <div>
              <InputNumber
                onChange={(e) => {
                  setIsAlertEnd(false)
                  setEndCode(e)
                }}
                style={{ width: '100%', borderColor: isAlertEnd ? '#EC1C24' : null }}
                value={endCode}
                min={1}
                placeholder="10" />
            </div>
          </div>
        </div>




        <div className="m-b-y-n-g">
          <div className="f-c-c d-b-t-n-s b-c-o-i" style={{ backgroundColor: '#cccccc' }} onClick={() => setVisibleGen(false)}>Cancel</div>
          <div className="f-c-c d-b-t-n-s b-c-o-i"
            style={{ backgroundColor: '#3f4d67', pointerEvents: isLoadingSetting ? 'none' : null }}
            onClick={() => generateQrBag()}
          >{isLoadingSetting ? <LoadingOutlined /> : 'Confirm'}</div>
        </div>



      </Modal>



    </div>
  )
}

export default BagList
