import React, { useState, useEffect } from "react";
import "../Report.css";

import Loading from "../../../components/Loading/Loading";
import ProgressPercent from "../../../components/Tools/Progress/ProgressPercent";

import { numberWithCommas } from "../../../utils/function";

const ReportV2 = (props) => {
  const [report, setReport] = useState(undefined);
  const [coin, setCoin] = useState(0);
  const [mobile, setMobile] = useState(0);
  const [qrCode, setQrCode] = useState(0);
  const [runTime, setRunTime] = useState(0);
  const [incomeTotal, setIncomeTotal] = useState(0);

  useEffect(() => {
    setReport(props.report);
  }, [props]);

  useEffect(() => {
    if (report?.length > 0) {
      let coinValue = 0;
      let mobileValue = 0;
      let qrCodeValue = 0;
      let incomeTotalValue = 0;

      report.map((item) => {
        if (item?.action_by == "NOP") {
          if (item?.run_value) {
            coinValue += parseInt(item?.run_value);
          }
          setCoin(coinValue);
        }
        if (item?.action_by == "Mobile") {
          if (item?.run_value) {
            mobileValue += parseInt(item?.run_value);
          }
          setMobile(mobileValue);
        }
        if (item?.action_by == "QR") {
          if (item?.run_value) {
            qrCodeValue += parseInt(item?.run_value);
          }
          setQrCode(qrCodeValue);
        }
        incomeTotalValue += parseInt(item?.run_value);
        setRunTime(report?.length ?? 0);
        setIncomeTotal(incomeTotalValue ?? 0);
      });
    }
  }, [report]);

  return (
    <div>
      <div className="dash-report-card-payment-grid">
        <div className="c-c-t-s">
          <div className="pst-rlt-h-100pc">
            {props.isLoading && <Loading />}
            <div className="dash-report-card-padding">
              <div className="dash-report-title">รายรับรวมทั้งหมด ( บาท )</div>
              <div className="dash-report-card-detail-grid">
                <div className="f-c-c">
                  <i className="bi bi-layers"></i>
                </div>
                <div>เหรียญ</div>
                <div>{numberWithCommas(coin, 2)}</div>
              </div>
              <div className="dash-report-card-detail-grid">
                <div className="f-c-c">
                  <i className="bi bi-phone"></i>
                </div>
                <div>App</div>
                <div>{numberWithCommas(mobile, 2)}</div>
              </div>
              <div className="dash-report-card-detail-grid">
                <div className="f-c-c">
                  <i className="bi bi-upc-scan"></i>
                </div>
                <div>QR-Code</div>
                <div>{numberWithCommas(qrCode, 2)}</div>
              </div>
              <div
                className="dash-report-total-credit"
                style={{ margin: "10px 0 0" }}
              >
                {numberWithCommas(incomeTotal, 2)}
              </div>
              <div>
                <ProgressPercent value={incomeTotal} />
              </div>
            </div>
          </div>
        </div>

        <div className="c-c-t-s">
          <div className="pst-rlt-h-100pc">
            {props.isLoading && <Loading />}
            <div className="dash-report-card-padding">
              <div className="dash-report-title">* ผู้ใช้งานทั้งหมด ( คน )</div>
              <div className="dash-report-card-detail-grid">
                <div className="f-c-c">
                  <i className="bi bi-phone"></i>
                </div>
                <div>Android</div>
                <div>
                  {numberWithCommas(
                    (report &&
                      report.customer_all &&
                      report.customer_all.android) ||
                      0,
                    0
                  )}
                </div>
              </div>
              <div className="dash-report-card-detail-grid">
                <div className="f-c-c">
                  <i className="bi bi-phone"></i>
                </div>
                <div>iOS</div>
                <div>
                  {numberWithCommas(
                    (report &&
                      report.customer_all &&
                      report.customer_all.ios) ||
                      0,
                    0
                  )}
                </div>
              </div>
              <div className="dash-report-card-detail-grid">
                <div className="f-c-c">
                  <i className="bi bi-phone"></i>
                </div>
                <div>Sansiri</div>
                <div>
                  {numberWithCommas(
                    (report &&
                      report.customer_all &&
                      report.customer_all.sansiri) ||
                      0,
                    0
                  )}
                </div>
              </div>
              <div className="dash-report-card-detail-grid">
                <div className="f-c-c">
                  <i className="bi bi-phone-fill"></i>
                </div>
                <div>Undefined</div>
                <div>
                  {numberWithCommas(
                    (report &&
                      report.customer_all &&
                      report.customer_all.undefined) ||
                      0,
                    0
                  )}
                </div>
              </div>
              <div
                className="dash-report-total-credit"
                style={{ margin: "10px 0 0" }}
              >
                {numberWithCommas(
                  (report &&
                    report.customer_all &&
                    report.customer_all.total) ||
                    0
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="c-c-t-s">
          <div className="pst-rlt-h-100pc">
            {props.isLoading && <Loading />}
            <div className="dash-report-card-padding">
              <div className="dash-report-title">
                จำนวนใช้งานทั้งหมด ( ครั้ง )
              </div>
              <div className="dash-report-total-credit">{runTime}</div>
              <div>
                <ProgressPercent value={runTime} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="dash-big-title">ช่องทางการชำระเงินจากการใช้งาน</div>
      <div className="dash-report-card-payment-grid">
        <div className="c-c-t-s">
          <div className="pst-rlt-h-100pc">
            {props.isLoading && <Loading />}
            <div className="p-d-p-ch-10-20">
              <div className="dash-report-card-payment-detail-grid">
                <div className="f-c-c">
                  <i className="bi bi-layers"></i>
                </div>
                <div>เหรียญ</div>
                <div>{numberWithCommas(coin, 2)}</div>
              </div>
              <div>
                <ProgressPercent value={coin} />
              </div>
            </div>
          </div>
        </div>

        <div className="c-c-t-s">
          <div className="pst-rlt-h-100pc">
            {props.isLoading && <Loading />}
            <div className="p-d-p-ch-10-20">
              <div className="dash-report-card-payment-detail-grid">
                <div className="f-c-c">
                  <i className="bi bi-phone"></i>
                </div>
                <div>App</div>
                <div>{numberWithCommas(mobile, 2)}</div>
              </div>
              <div>
                <ProgressPercent value={mobile} />
              </div>
            </div>
          </div>
        </div>

        <div className="c-c-t-s">
          <div className="pst-rlt-h-100pc">
            {props.isLoading && <Loading />}
            <div className="p-d-p-ch-10-20">
              <div className="dash-report-card-payment-detail-grid">
                <div className="f-c-c">
                  <i className="bi bi-upc-scan"></i>
                </div>
                <div>QR-Code</div>
                <div>{numberWithCommas(qrCode, 2)}</div>
              </div>
              <div>
                <ProgressPercent value={qrCode} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="dash-big-title">ช่องทางการเติมเงิน</div>
      <div className="dash-report-card-payment-grid">
        {((report && report.payment_type) || []).map((type, idx) => (
          <div key={idx} className="c-c-t-s">
            <div className="pst-rlt-h-100pc">
              {props.isLoading && <Loading />}
              <div className="p-d-p-ch-10-20">
                <div className="dash-report-card-payment-detail-grid">
                  <div className="f-c-c">
                    {type.payty_image ? (
                      <img src={type.payty_image} alt="" />
                    ) : (
                      <i className="bi bi-wallet2"></i>
                    )}
                  </div>
                  <div>{type.payty_name || "-"}</div>
                  <div>{numberWithCommas(type.value || 0, 2)}</div>
                </div>
                <div>
                  <ProgressPercent value={type.percent || 0} />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReportV2;
