import React, { useState, useEffect } from "react";
import "../Report.css";

import Loading from "../../../components/Loading/Loading";
import ProgressPercent from "../../../components/Tools/Progress/ProgressPercent-v2";

import { numberWithCommas } from "../../../utils/function";

const ReportV2 = (props) => {
  const [report, setReport] = useState(undefined);
  const [coin, setCoin] = useState(0);
  const [mobile, setMobile] = useState(0);
  const [qrCode, setQrCode] = useState(0);
  const [runTime, setRunTime] = useState(0);
  const [incomeTotal, setIncomeTotal] = useState(0);

  useEffect(() => {
    setReport(props.report);
  }, [props]);
console.log(report)
  useEffect(() => {
    if (report?.length > 0) {
      let coinValue = 0;
      let mobileValue = 0;
      let qrCodeValue = 0;
      let incomeTotalValue = 0;

      report.map((item) => {
        if (item?.action_by == "NOP" || item?.action_by == "coin") {
          if (item?.run_value) {
            coinValue += parseInt(item?.run_value);
          }
          setCoin(coinValue);
        }
        if (item?.action_by == "Mobile" || item?.action_by == "mobile") {
          if (item?.run_value) {
            mobileValue += parseInt(item?.run_value);
          }
          setMobile(mobileValue);
        }
        if (
          item?.action_by == "tw_pay" ||
          item?.action_by == "QR-Code" ||
          item?.action_by == "QR" ||
          item?.action_by == "qrcode"||
          item?.action_by == "qr"||
          item?.action_by == "qr_code"
        ) {
          if (item?.run_value) {
            qrCodeValue += parseInt(item?.run_value);
          }
          setQrCode(qrCodeValue);
        }else{
         //console.log(item?.action_by)
        }
        incomeTotalValue += parseInt(item?.run_value);
        setRunTime(report?.length ?? 0);
        setIncomeTotal(incomeTotalValue ?? 0);
      });
    }
  }, [report]);

   //console.log("report----🐳🐳", incomeTotal);

  return (
    <div>
      <div className="dash-card-grid">
        <div className="c-c-t-s">
          <div className="pst-rlt-h-100pc">
            {props.isLoading && <Loading />}
            <div className="dash-report-card-padding">
              <div className="dash-report-title">รายรับรวม ( บาท )</div>
              <div className="dash-report-total-credit">
                {numberWithCommas(incomeTotal, 2)}
              </div>
              <div>
                <ProgressPercent value={incomeTotal} />
              </div>
            </div>
          </div>
        </div>

        <div className="c-c-t-s">
          <div className="pst-rlt-h-100pc">
            {props.isLoading && <Loading />}
            <div className="dash-report-card-padding">
              <div className="dash-report-title">
                จำนวนที่ลูกค้าใช้งานทั้งหมด ( ครั้ง )
              </div>
              <div className="dash-report-total-credit">{runTime}</div>
              <div>
                <ProgressPercent value={runTime} type="time" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="dash-big-title">ช่องทางการชำระเงิน</div>
      <div className="dash-report-card-payment-grid">
        <div className="c-c-t-s">
          <div className="pst-rlt-h-100pc">
            {props.isLoading && <Loading />}
            <div className="p-d-p-ch-10-20">
              <div className="dash-report-card-payment-detail-grid">
                <div className="f-c-c">
                  <i className="bi bi-layers"></i>
                </div>
                <div>เหรียญ</div>
                <div>{numberWithCommas(coin, 2)}</div>
              </div>
              <div>
                <ProgressPercent value={coin} />
              </div>
            </div>
          </div>
        </div>

        <div className="c-c-t-s">
          <div className="pst-rlt-h-100pc">
            {props.isLoading && <Loading />}
            <div className="p-d-p-ch-10-20">
              <div className="dash-report-card-payment-detail-grid">
                <div className="f-c-c">
                  <i className="bi bi-phone"></i>
                </div>
                <div>App</div>
                <div>{numberWithCommas(mobile, 2)}</div>
              </div>
              <div>
                <ProgressPercent value={mobile} />
              </div>
            </div>
          </div>
        </div>

        <div className="c-c-t-s">
          <div className="pst-rlt-h-100pc">
            {props.isLoading && <Loading />}
            <div className="p-d-p-ch-10-20">
              <div className="dash-report-card-payment-detail-grid">
                <div className="f-c-c">
                  <i className="bi bi-upc-scan"></i>
                </div>
                <div>QR-Code</div>
                <div>{numberWithCommas(qrCode, 2)}</div>
              </div>
              <div>
                <ProgressPercent value={qrCode} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportV2;
