import axios from '../../config/axios';

const apiCPCouponList = async () => {
  try {
    const result = await axios.get(`/washer/coupon/list`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}


const apiCPCouponAddEdit = async (data) => {
  try {
    const result = await axios.post(`/washer/coupon`,data)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

export {
    apiCPCouponList,
    apiCPCouponAddEdit
}