import axios from '../../config/axios';

const apiNGetNews = async (get_all, cur_page, per_page, keyword) => {
  try {
    const result = await axios.get(`/news/list?get_all=${get_all}&cur_page=${cur_page}&per_page=${per_page}&keyword=${keyword}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiNAddNews = async (data) => {
  try {
    const result = await axios.post('/news', data)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiNGetNewsById = async (news_id) => {
  try {
    const result = await axios.get(`/news?news_id=${news_id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiNEditNewsById = async (data) => {
  try {
    const result = await axios.put('/news', data)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiNDeleteNewsById = async (news_id) => {
  try {
    const result = await axios.delete(`/news?news_id=${news_id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

export {
  apiNGetNews,
  apiNAddNews,
  apiNGetNewsById,
  apiNEditNewsById,
  apiNDeleteNewsById,
}