import { createAction, createReducer } from '@reduxjs/toolkit';

const initialState = {
  isModalMachineAction: false,
  reloadMachineList: false
}

export const changeModalMachineAction = createAction('CHANGE_MODAL_MACHINE_ACTION');
export const changeReloadStatus = createAction('CHANGE_RELOAD_STATUS');

export default createReducer(initialState, {
  [changeModalMachineAction]: (state, action) => {
    state.isModalMachineAction = action.payload
    return state;
  },
  [changeReloadStatus]: (state, action) => {
    state.reloadMachineList = !state.reloadMachineList
    return state;
  },
});
