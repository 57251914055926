import React, { useState, useEffect } from 'react'
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { Input, Select, DatePicker } from 'antd';
import './Machine.css'
import { LoadingOutlined } from '@ant-design/icons';

import moment from 'moment';
import 'moment/locale/th'

import { openNotification, getSearchParams } from '../../utils/function'
import { apiMCHGetControllerMachine, apiMCHAddController, apiMCHGetControllerById, apiMCHEditControllerById } from '../../services/apis/machine'

const { Option } = Select;

const MachineControllerAddEditPage = () => {
  const { controllerId } = useParams();
  const location = useLocation();
  const history = useHistory();

  const params = new URLSearchParams(location.search)
  const p = params.get('p')
  const ttl = params.get('ttl')
  const sw = params.get('sw')

  const [isLoading, setIsLoading] = useState(false)
  const [machine, setMachine] = useState(undefined)
  const [machines, setMachines] = useState([])

  const [isAlertMacAddress, setIsAlertMacAddress] = useState(false)
  const [isAlertVersion, setIsAlertVersion] = useState(false)
  const [isAlertLot, setIsAlertLot] = useState(false)
  const [isAlertProducer, setIsAlertProducer] = useState(false)
  const [isAlertSetUpDate, setIsAlertSetUpDate] = useState(false)
  const [isAlertExpire, setIsAlertExpire] = useState(false)

  useEffect(() => {
    if (controllerId) getData()
  }, [controllerId])

  const getData = async () => {
    let data = await apiMCHGetControllerById(controllerId)
    if (data && data.status) setMachine(data.result)
  }

  useEffect(() => {
    const getControllerMachines = async () => {
      let type = await apiMCHGetControllerMachine(machine && machine.machine_id || '')
      if (type && type.status) setMachines(type.result || [])
    }
    getControllerMachines()
  }, [machine && machine.machine_id])

  const addEditMachine = async () => {
    if (!(machine && machine.machine_no) ||
      !(machine && machine.controller_version) ||
      !(machine && machine.controller_lot) ||
      !(machine && machine.controller_producer) ||
      !(machine && machine.controller_setup_date) ||
      !(machine && machine.controller_warranty_expire)
    ) {
      if (!(machine && machine.machine_no)) setIsAlertMacAddress(true)
      if (!(machine && machine.controller_version)) setIsAlertVersion(true)
      if (!(machine && machine.controller_lot)) setIsAlertLot(true)
      if (!(machine && machine.controller_producer)) setIsAlertProducer(true)
      if (!(machine && machine.controller_setup_date)) setIsAlertSetUpDate(true)
      if (!(machine && machine.controller_warranty_expire)) setIsAlertExpire(true)

    } else {
      setIsLoading(true)

      let data = { ...machine }
      data.controller_setup_date = moment(machine.controller_setup_date).format('YYYY-MM-DD HH:mm:ss')
      data.controller_warranty_expire = moment(machine.controller_warranty_expire).format('YYYY-MM-DD HH:mm:ss')

      let newData = controllerId ? await apiMCHEditControllerById(data) : await apiMCHAddController(data)
      if (newData && newData.status) {
        setIsLoading(false)
        openNotification('success', 'Success !', `${controllerId ? 'Edit' : 'Add new'} controller successful.`)
        setTimeout(() => history.push(`/machine/controller${getSearchParams(['p', 'ttl', 'sw'], [p, ttl, sw])}`), 1000);
      } else {
        setIsLoading(false)
        openNotification('error', 'Failed !', `Failed to ${controllerId ? 'edit' : 'add new'} controller.`)
      }
    }
  }

  return (
    <div>
      <div className="mg-bt-20 h-d-t-p">Machine - {controllerId ? 'Edit' : 'Add'} Controller</div>

      <div className="t-m-c-i-p-g">
        <div className="f-s-c">รหัส ( MC )</div>
        <div>
          <Input
            style={{ borderColor: isAlertMacAddress ? '#EC1C24' : null }}
            placeholder="รหัส ( MC )"
            value={machine && machine.machine_no || ''}
            onChange={e => {
              setIsAlertMacAddress(false)
              setMachine({ ...machine, machine_no: e.target.value })
            }}
          />
        </div>

        <div className="f-s-c">Version</div>
        <div>
          <Input
            style={{ borderColor: isAlertVersion ? '#EC1C24' : null }}
            placeholder="Version"
            value={machine && machine.controller_version || ''}
            onChange={e => {
              setIsAlertVersion(false)
              setMachine({ ...machine, controller_version: e.target.value })
            }}
          />
        </div>

        <div className="f-s-c">Lot.</div>
        <div>
          <Input
            style={{ borderColor: isAlertLot ? '#EC1C24' : null }}
            placeholder="Lot."
            value={machine && machine.controller_lot || ''}
            onChange={e => {
              setIsAlertLot(false)
              setMachine({ ...machine, controller_lot: e.target.value })
            }}
          />
        </div>

        <div className="f-s-c">ผู้ผลิต</div>
        <div>
          <Input
            style={{ borderColor: isAlertProducer ? '#EC1C24' : null }}
            placeholder="ผู้ผลิต"
            value={machine && machine.controller_producer || ''}
            onChange={e => {
              setIsAlertProducer(false)
              setMachine({ ...machine, controller_producer: e.target.value })
            }}
          />
        </div>

        <div className="f-s-c">Note</div>
        <div>
          <Input.TextArea
            autoSize={{ minRows: 3 }}
            placeholder="Note"
            value={machine && machine.controller_comment || ''}
            onChange={e => setMachine({ ...machine, controller_comment: e.target.value })}
          />
        </div>

        <div className="f-s-c">วันที่รับ Hardware</div>
        <div>
          <DatePicker style={{ width: '100%', borderColor: isAlertSetUpDate ? '#EC1C24' : null }}
            placeholder="วันที่รับ Hardware"
            value={machine && machine.controller_setup_date && moment(machine.controller_setup_date)}
            onChange={date => {
              setIsAlertSetUpDate(false)
              setMachine({ ...machine, controller_setup_date: date })
            }}
            format={date => moment(date).add(543, 'years').format('D MMM YYYY')}
            allowClear={false}
          />
        </div>

        <div className="f-s-c">วันที่สิ้นสุดรับประกัน</div>
        <div>
          <DatePicker style={{ width: '100%', borderColor: isAlertExpire ? '#EC1C24' : null }}
            placeholder="วันที่สิ้นสุดรับประกัน"
            value={machine && machine.controller_warranty_expire && moment(machine.controller_warranty_expire)}
            onChange={date => {
              setIsAlertExpire(false)
              setMachine({ ...machine, controller_warranty_expire: date })
            }}
            format={date => moment(date).add(543, 'years').format('D MMM YYYY')}
            allowClear={false}
          />
        </div>

        <div className="f-s-c">เครื่อง</div>
        <div>
          <Select style={{ width: '100%' }}
            placeholder="เครื่อง"
            showSearch
            value={machine && machine.machine_id}
            onChange={value => setMachine({ ...machine, machine_id: value })}
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {machines.map((machine, idx) => <Option key={idx} value={machine.machine_id}>{machine.machine_name}</Option>)}
          </Select>
        </div>
      </div>

      <div className="f-c-c s-e-b">
        <div className="f-c-c d-b-t-n-s d-bg-c-t"
          style={{ width: '190px', pointerEvents: isLoading ? 'none' : null }}
          onClick={() => addEditMachine()}
        >{isLoading ? <LoadingOutlined /> : `${controllerId ? 'Edit' : 'Add New'} Controller`}</div>
      </div>

    </div>
  )
}

export default MachineControllerAddEditPage
