import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom';
import { InputNumber, Select, DatePicker, TimePicker, Checkbox } from 'antd';
import './SitePromotion.css'
import { LoadingOutlined } from '@ant-design/icons';

import moment from 'moment';
import 'moment/locale/th'

import { checkIfIdEqualToZero, openNotification } from '../../utils/function'
import { siteall, machinebysite, CreateCollectSite } from '../../services/apis/tw-promotion'
const role = (localStorage.getItem("user_role") || "").toUpperCase();
console.log(role)
const { Option } = Select;
const { RangePicker } = DatePicker;

const SitePromotionCollect = () => {
  const history = useHistory();
  const { siteId } = useParams();
  const [isLoading, setIsLoading] = useState(false)
  const [sites, setSites] = useState([])
  const [machineTypes, setMachineTypes] = useState([])
  const [sitePromotion, setSitePromotion] = useState(undefined)
  const [selectAllMachine, setSelectAllMachine] = useState(false)
  const [Alertsite_id, setIsAlertsite_id] = useState(false)
  const [AlertSiteName, setIsAlertSiteName] = useState(false)
  const [machine_type, setIsAlertmachine_type] = useState(false)
  const [Alertmachine_use_promotion, setIsAlertmachine_use_promotion] = useState(false)
  const [AlertCollect_count, setIsAlertCollect_count] = useState(false)
  const [AlertCollect_money, setIsAlertCollect_money] = useState(false)
  const [isAlertLimit, setIsAlertLimit] = useState(false)

  useEffect(() => {
  }, [])
  useEffect(() => {
  }, [sitePromotion && sitePromotion.machine_type])

  const addEditPromotion = async () => {
    console.log(sitePromotion)
    if (
      !(sitePromotion && sitePromotion.machine_use_promotion) ||
      !(sitePromotion && sitePromotion.machine_type) ||
      isNaN(sitePromotion && sitePromotion.Collect_count) ||
      !(sitePromotion && sitePromotion.Collect_money)
    ) {
      if (!(sitePromotion && sitePromotion.machine_use_promotion)) setIsAlertmachine_use_promotion(true)
      if (!(sitePromotion && sitePromotion.machine_type)) setIsAlertmachine_type(true)
      if (isNaN(sitePromotion && sitePromotion.Collect_count)) setIsAlertCollect_count(true)
      if (!(sitePromotion && sitePromotion.Collect_money)) setIsAlertCollect_money(true)

    } else {

      let data = {
        site_code: sitePromotion.site_id,
        site_name: sitePromotion.site_name,
        machine_type: sitePromotion.machine_type,
        machine_use_promotion: sitePromotion.machine_use_promotion,
        Collect_start_date: sitePromotion.period_promotion.start_date,
        Collect_end_date: sitePromotion.period_promotion.end_date,
        Collect_count: sitePromotion.Collect_count,
        Collect_money: sitePromotion.Collect_money,
        Collect_count_machine: sitePromotion.Collect_count_machine,
        Active: true,
      }
      let newData = await CreateCollectSite(data)
      if (newData.message) {
        openNotification('success', newData.message)
        setIsLoading(true)
        setTimeout(() => history.push('/site-promotion/ListCollect'), 1000);
      } else {
        setIsLoading(false)
        openNotification('error', 'Failed !', 'Failed to add new discount.')
      }
    }
  }
  const addPromotionallsite = async () => {
    if (role === 'SUPERADMIN') {
      for (let i = 0; i < sites.length; i++) {
        let postdata =
        {
          "site_code": sites[i].siteCode + ""
        }
        let machineType = await machinebysite(postdata)
        let datamachine = []
        for (let j = 0; j < machineType.data.length; j++) {
          if (machineType.data[j].machine_group_id == sitePromotion.machine_type || machineType.data[j].machine_group == sitePromotion.machine_type) {
            datamachine.push(machineType.data[j].deviceName)
          }
          else if ("3" == sitePromotion.machine_type) {
            datamachine.push(machineType.data[j].deviceName)
          }
        }

        let data = {
          site_code: sites[i].siteCode,
          site_name: sites[i].site_name,
          machine_type: sitePromotion.machine_type,
          machine_use_promotion: datamachine,
          Collect_start_date: sitePromotion.period_promotion.start_date,
          Collect_end_date: sitePromotion.period_promotion.end_date,
          Collect_count: sitePromotion.Collect_count,
          Collect_money: sitePromotion.Collect_money,
          Collect_count_machine: sitePromotion.Collect_count_machine,
          Active: true,
        }
        console.log(data)
        let newData = await CreateCollectSite(data)
        if (newData.message) {
          console.log('success', newData.message)
        } else {
          console.log('error', 'Failed !', 'Failed to add new discount.')
        }
      }
    } else {
      alert('ไม่มีสิทธิใช้งาน')
    }
    // console.log(sites)
    // console.log(sitePromotion.machine_type)

  }
  const options = [
    { value: 'FC', label: 'FC' },
    { value: 'OS', label: 'OS' },
    { value: 'none', label: 'none' },
  ];
  const options_machinetype = [
    { value: "1", label: 'เครื่องซักผ้า' },
    { value: "2", label: 'เครื่องอบผ้า' },
    { value: "3", label: 'เครื่องทุกประเภท' },
  ];
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedSiteOption, setSelectedSiteOption] = useState(null);
  const [selectedOptions_machinetype, setoptions_machinetype] = useState(null);
  const handleSelectChange = async (option) => {
    let siteAll = await siteall()

    let datasite = siteAll.message.getSites;
    let sitedata = []
    for (let i = 0; i < datasite.length; i++) {
      if (datasite[i].side_type === option) {
        sitedata.push(datasite[i])
      }
    }
    setSitePromotion({
      ...sitePromotion,
      site_code: option
    })
    setSelectedOption(option);
    setSites(sitedata || [])
  };
  const handleSelectChange_machinetype = async (option) => {
    setSitePromotion({
      ...sitePromotion,
      machine_type: option
    })
    if (selectedSiteOption) {
      let postdata =
      {
        "site_code": selectedSiteOption + ""
      }
      let machineType = await machinebysite(postdata)
      console.log(machineType.data)
      let datamachine = []
      for (let i = 0; i < machineType.data.length; i++) {
        if (machineType.data[i].machine_group_id == option || machineType.data[i].machine_group == option) {
          datamachine.push(machineType.data[i])
        }
        else if ("3" == option) {
          datamachine.push(machineType.data[i])
        }
      }


      setIsAlertmachine_type(false)
      setMachineTypes(datamachine || [])
    }
    setoptions_machinetype(option);
  };
  const selectChangeMachine = async (site_id) => {
    setSelectedSiteOption(site_id);
  }
  return (
    <div>
      <div className="mg-bt-20 h-d-t-p">Promotion - Collect</div>
      <div className="t-m-c-i-p-g">
        <div className="f-s-c">Site type</div>
        <div className={Alertsite_id && 'antd-sl-al-bd' || ''}>
          <Select style={{ width: '100%' }}
            options={options}
            value={selectedOption}
            onChange={e => {
              handleSelectChange(e)
              setIsAlertsite_id(false)
            }}
          />
        </div>
        <div className="f-s-c">Site Name</div>
        <div className={AlertSiteName && 'antd-sl-al-bd' || ''}>
          <Select style={{ width: '100%' }}
            placeholder="Site Name"
            showSearch
            value={sitePromotion && sitePromotion.site_name}
            onChange={(value, data) => {
              setIsAlertSiteName(false)
              selectChangeMachine(value)
              // selectChangeMachine(value.site_name)
              setSitePromotion({
                ...sitePromotion,
                site_name: data?.children,
                site_id: value
              })
            }}

            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {sites.map((site, idx) => <Option key={idx} value={site.siteCode}>{site.site_name}</Option>)}
          </Select>
        </div>
        <div className="f-s-c">Machine type</div>
        <div className={machine_type && 'antd-sl-al-bd' || ''}>
          <Select style={{ width: '100%' }}
            options={options_machinetype}
            value={options_machinetype.value}
            onChange={e => {
              handleSelectChange_machinetype(e)
              setIsAlertmachine_type(false)
            }}

          />
        </div>
        <div className="f-s-c">Machine Name</div>
        <div className={Alertmachine_use_promotion && 'antd-sl-al-bd' || ''}>
          <Select style={{ width: '100%' }}
            dropdownRender={menu => <div>
              <div className="f-s-c antd-chb-fz" style={{ padding: '5px 10px' }}>
                <Checkbox
                  checked={selectAllMachine}
                  onChange={e => {
                    setSelectAllMachine(e.target.checked)
                    setIsAlertmachine_use_promotion(false)

                    if (e.target.checked) {
                      let machine = machineTypes.map(mid => mid.deviceName)
                      setSitePromotion({ ...sitePromotion, machine_use_promotion: machine })
                    } else setSitePromotion({ ...sitePromotion, machine_use_promotion: [] })
                  }}
                >Select All</Checkbox>
              </div>
              {menu}
            </div>}
            placeholder="Machine Name"
            disabled={(checkIfIdEqualToZero(sitePromotion && sitePromotion.site_id))}
            mode="multiple"
            showArrow
            value={sitePromotion && sitePromotion.machine_use_promotion || []}
            onChange={value => {
              setIsAlertmachine_use_promotion(false)
              setSitePromotion({
                ...sitePromotion,
                machine_use_promotion: value,
              })
              setSelectAllMachine(machineTypes.length === value.length)
            }}
          >
            {machineTypes.map((machine, idx) => <Option key={idx} value={machine.deviceName}>{machine.machine_group} {machine.brand}  No .{machine.machine_name}</Option>)}
          </Select>
        </div>
        <div className="f-s-c">Collect Creadit ( THB )</div>
        <div>
          <InputNumber min={0}
            step={0.01}
            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
            style={{ width: '100%', borderColor: AlertCollect_count ? '#EC1C24' : null }}
            placeholder="ReturnCreadit"
            value={sitePromotion && sitePromotion.Collect_money}
            onChange={value => {
              setIsAlertCollect_count(false)
              setSitePromotion({ ...sitePromotion, Collect_money: Math.round(value * 100) / 100 || 0 })
            }}
          />
        </div>

        <div className="f-s-c">Period Promotion</div>
        <div>
          <RangePicker
            style={{ width: '100%', borderColor: AlertCollect_money ? '#EC1C24' : null }}
            placeholder={["Period Start", "Period End"]}
            format={date => moment(date).add(543, 'years').format('D MMM YYYY')}
            allowClear={false}
            value={[
              sitePromotion && sitePromotion.period_promotion && sitePromotion.period_promotion.start_date && moment(sitePromotion.period_promotion.start_date, 'YYYY-MM-DD'),
              sitePromotion && sitePromotion.period_promotion && sitePromotion.period_promotion.end_date && moment(sitePromotion.period_promotion.end_date, 'YYYY-MM-DD')
            ]}
            onChange={date => {
              setIsAlertCollect_money(false)
              let newSitePromotion = { ...sitePromotion }
              if (!newSitePromotion.period_promotion) newSitePromotion.period_promotion = {}
              newSitePromotion.period_promotion.start_date = moment(date[0]).format('YYYY-MM-DD')
              newSitePromotion.period_promotion.end_date = moment(date[1]).format('YYYY-MM-DD')
              setSitePromotion(newSitePromotion)
            }}
            disabledDate={current => current && current < moment().startOf('day')}
          />
        </div>
        <div className="f-s-c">จำนวนครั้งเครื่องซัก</div>
        <div>
          <InputNumber min={0} max={1000}
            step={1}
            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
            style={{ width: '100%', borderColor: isAlertLimit ? '#EC1C24' : null }}
            placeholder="จำนวนครั้งเครื่องซัก"
            value={sitePromotion && sitePromotion.Collect_count_machine}
            onChange={value => {
              setIsAlertLimit(false)
              setSitePromotion({ ...sitePromotion, Collect_count_machine: Math.round(value) || 0 })
            }}
          />
        </div>
        <div className="f-s-c">จำนวนครั้งโปรโมชั่น</div>
        <div>
          <InputNumber min={0} max={1000}
            step={1}
            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
            style={{ width: '100%', borderColor: isAlertLimit ? '#EC1C24' : null }}
            placeholder="จำนวนครั้ง"
            value={sitePromotion && sitePromotion.Collect_count}
            onChange={value => {
              setIsAlertLimit(false)
              setSitePromotion({ ...sitePromotion, Collect_count: Math.round(value) || 0 })
            }}
          />
        </div>
      </div>
      <div className="f-c-c s-e-b">
        <div className="f-c-c d-b-t-n-s d-bg-c-t"
          style={{ width: '190px', pointerEvents: isLoading ? 'none' : null }}
          onClick={() => addEditPromotion()}
        >{isLoading ? <LoadingOutlined /> : `${siteId ? 'Edit' : 'Add Promotion'} `}</div>
      </div>
      <div className="f-c-c s-e-b">
        <div className="f-c-c d-b-t-n-s d-bg-c-t"
          style={{ width: '190px', pointerEvents: isLoading ? 'none' : null }}
          onClick={() => addPromotionallsite()}
        >{isLoading ? <LoadingOutlined /> : `${siteId ? 'Edit' : 'Add promotion'} all site `}</div>
      </div>
    </div>
  )
}

export default SitePromotionCollect
