import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { DatePicker, Select } from "antd";
import "../Dashboard.css";

import moment from "moment";
import "moment/locale/th";
import Loading from "../../../components/Loading/Loading";
import { numberWithCommas } from "../../../utils/function";
import { getSearchParams } from "../../../utils/function";
import { apiDBGetReportSuperAdmin, apiDashboard_transation, apiDashboard_register, apiDashboard_site, apibalance_bydate, apiDashboard_useractive } from "../../../services/apis/dashboard";
import ProgressBath from "../../../components/Tools/Progress/Progress";
import Progresscount from "../../../components/Tools/Progress/Progresscount";
import Report from "./Report_customer";
import ChartColumnDashboardNew from "../../../components/Tools/Chart/ChartColumnDashboardNew";
import ChartColumnStackDashboardNew from "../../../components/Tools/Chart/ChartColumnStackDashboardNew";
import ChartPie from "../../../components/Tools/Chart/ChartPie";
import TableNewUser from "./Table/TableNewUser";
import TableActiveUsers from "./Table/TableActiveUsers";
import TableMaximumUsage from "./Table/TableMaximumUsage";
import TableInactiveUsers from "./Table/TableInactiveUsers";
import TableMachineAverage from "./Table/TableMachineAverage";
import { QueryGraphQLByPeriod, GET_HISTORY, QueryGraphQL, GET_SITES } from "../../../resolvers/Query";
import { siteall, machinebysite, CreateCollectSite } from '../../../services/apis/tw-promotion'
const { RangePicker } = DatePicker;
const { Option } = Select;

const DashboardCustomer = () => {
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const start = params.get("start");
  const end = params.get("end");
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadinguser, setIsLoadinguser] = useState(true);
  const [isLoadchart, setIsLoadchart] = useState(true);
  const [sites, setSites] = useState([]);
  const [sites_type, setSites_type] = useState([]);
  const dataSiteList = QueryGraphQL(GET_SITES);
  const dataSites = dataSiteList?.data?.getSites;
  const [site, setsite] = useState([]);



  const [isAlertSiteName, setIsAlertSiteName] = useState(false);
  const [startDate, setStartDate] = useState(
    start
      ? moment(start).startOf("day").format("YYYY-MM-DD HH:mm:ss")
      : moment().startOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [endDate, setEndDate] = useState(
    end
      ? moment(end).endOf("day").format("YYYY-MM-DD HH:mm:ss")
      : moment().endOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [chartReport, setChartReport] = useState(undefined);
  const [searchButton, setSearchButton] = useState(false);
  // const dataHistory = QueryGraphQLByPeriod(GET_HISTORY, startDate, endDate);
  // const dataList = dataHistory?.data?.getHistory ?? [];
  const report_transation = [];
  const [datamachine_wash, setDatamachine_wash] = useState(null);
  const [datamachine_dry, setDatamachine_dry] = useState(null);
  const [datamachine_FC_wash, setDatamachine_FC_wash] = useState(null);
  const [datamachine_FC_dry, setDatamachine_FC_dry] = useState(null);

  const [data_count_qrcode, setdata_count_qrcode] = useState(null);
  const [data_value_qrcode, setdata_value_qrcode] = useState(null);
  const [data__qrcodeValue, setdata__qrcodeValue] = useState(null);
  const [data__qrcodeValueFS, setdata__qrcodeValueFS] = useState(null);
  const [data_machine_wash_qrcode, setdata_machine_wash_qrcode] = useState(null);
  const [data_machine_dry_qrcode, setdata_machine_dry_qrcode] = useState(null);
  const [data_machine_FS_wash_qrcode, setdata_machine_FS_wash_qrcode] = useState(null);
  const [data_machine_FS_dry_qrcode, setdata_machine_FS_dry_qrcode] = useState(null);

  const [data_value_coin, setdata_value_coin] = useState(null);
  const [data_count_coin, setdata_count_coin] = useState(null);
  const [data_coinValue, setdata_coinValue] = useState(null);
  const [data_coinValueFS, setdata_coinValueFS] = useState(null);
  const [data_machine_washcoin, setdata_machine_washcoin] = useState(null);
  const [data_machine_drycoin, setdata_machine_drycoin] = useState(null);
  const [data_machine_FS_washcoin, setdata_machine_FS_washcoin] = useState(null);
  const [data_machine_FS_drycoin, setdata_machine_FS_drycoin] = useState(null);


  const [data_value_mobile, setdata_value_mobile] = useState(null);
  const [data_count_mobile, setdata_count_mobile] = useState(null);
  const [data_mobileValue, setdata_mobileValue] = useState(null);
  const [data_mobileValueFS, setdata_mobileValueFS] = useState(null);
  const [data_machine_FS_washmobile, setdata_machine_FS_washmobile] = useState(null);
  const [data_machine_FS_drymobile, setdata_machine_FS_drymobile] = useState(null);
  const [data_machine_washmobile, setdata_machine_washmobile] = useState(null);
  const [data_machine_drymobile, setdata_machine_drymobile] = useState(null);


  const [Chart_Day_daycoin, setChart_Day_daycoin] = useState(null);
  const [Chart_Day_daymobile, setChart_Day_daymobile] = useState(null);
  const [Chart_Day_dayqrcode, setChart_Day_dayqrcode] = useState(null);
  const [Chart_Day_monthcoin, setChart_Day_monthcoin] = useState(null);
  const [Chart_Day_monthmobile, setChart_Day_monthmobile] = useState(null);
  const [Chart_Day_monthqrcode, setChart_Day_monthqrcode] = useState(null);

  const [day, setday] = useState([]);
  const [month, setmonth] = useState([]);

  const [data_total_site, setdata_total_site] = useState(null);
  const [data_total_siteFS, setdata_total_siteFS] = useState(null);
  const [data_total_siteOS, setdata_total_siteOS] = useState(null);

  const [data_totaluser, setdata_totaluser] = useState(null);
  const [data_useractive, setdata_useractive] = useState(null);
  const [data_Newregister, setdata_Newregister] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [report, setReport] = useState(undefined)
  //console.log("dataList00000---🙂🙂", dataList);
  const [topup_SCB, settopup_SCB] = useState(null);
  const [topup_truemoney, settopup_truemoney] = useState(null);
  const [topup_omise, settopup_omise] = useState(null);
  const [topup_ktbcard, settopup_ktbcard] = useState(null);
  const [usage_credit, setusage_credit] = useState(null);
  const [data_balance, setdata_balance] = useState(null);
  useEffect(async () => {
    setsite({ side_type: "All", siteCode: "" });

    // if (dataSites?.length > 0) {
    //   const dataSiteNew = [];
    //   dataSites.map((item) => {
    //     dataSiteNew.push({ site_id: item.id, site_name: item.site_name, siteCode: item.siteCode });
    //   });
    //   setSites(dataSiteNew);
    // }
    test();
  }, [dataSites]);

  async function test() {
    setIsLoading(true)
    setIsLoadchart(true)
    console.log(selectedOption)
    let data;
    if (selectedOption == null) {
      data = {
        "side_type": "All",
        "siteCode": site.siteCode || "",
        "startdate": startDate,
        "stopdate": endDate
      }
    } else {
      data = {
        "side_type": selectedOption,
        "siteCode": site.siteCode,
        "startdate": startDate,
        "stopdate": endDate
      }
    }
    let balance_bydate = await apibalance_bydate(startDate, endDate)
    if (balance_bydate) {
      setIsLoading(false)
      settopup_SCB(balance_bydate.topup_SCB)
      settopup_truemoney(balance_bydate.topup_truemoney)
      settopup_omise(balance_bydate.topup_omise)
      settopup_ktbcard(balance_bydate.topup_ktbcard)
      setusage_credit(balance_bydate.usage_credit)
      setdata_balance(balance_bydate.Balance)
    }
    let report_site = await apiDashboard_site()
    if (report_site) {
      setIsLoading(false)
      setdata_total_site(report_site.siteFSlenght + report_site.siteOSlenght)
      setdata_total_siteFS(report_site.siteFSlenght)
      setdata_total_siteOS(report_site.siteOSlenght)
    }
    let report_user = await apiDashboard_register(data)
    if (report_user) {
      setIsLoadinguser(false)
      setdata_totaluser(report_user.totaluser)
      setdata_useractive(report_user.useractive)
      setdata_Newregister(report_user.Newregister)
    }
    let report_transation = await apiDashboard_transation(data)
    if (report_transation) {

      setDatamachine_wash(report_transation.machine_wash)
      setDatamachine_dry(report_transation.machine_dry)

      setdata_count_qrcode(report_transation.actionQr)
      setdata_value_qrcode(report_transation.qrCodeValue)
      setdata__qrcodeValue(report_transation.qrCodeValue)
      setdata_machine_wash_qrcode(report_transation.machine_washQR)
      setdata_machine_dry_qrcode(report_transation.machine_dryQR)

      setdata_value_mobile(report_transation.mobileValue)
      setdata_count_mobile(report_transation.actionMobile)
      setdata_mobileValue(report_transation.mobileValue)
      setdata_machine_washmobile(report_transation.machine_washmobile)
      setdata_machine_drymobile(report_transation.machine_drymobile)

      setdata_count_coin(report_transation.adcionCoin)
      setdata_value_coin(report_transation.coinValue)
      setdata_coinValue(report_transation.coinValue)
      setdata_machine_washcoin(report_transation.machine_washcoin)
      setdata_machine_drycoin(report_transation.machine_drycoin)

      setChart_Day_daycoin(report_transation.daycoin)
      setChart_Day_daymobile(report_transation.daymobile)
      setChart_Day_dayqrcode(report_transation.dayqrcode)
      setChart_Day_monthcoin(report_transation.monthcoin)
      setChart_Day_monthmobile(report_transation.monthmobile)
      setChart_Day_monthqrcode(report_transation.monthqrcode)

      if (Chart_Day_monthcoin?.length > 0) {
        const monthdata = {
          "isLoading": false,
          "chartReport": [
            {
              "id": 2271,
              "name": "Jan",
              "count": 3,
              "total": 1170,
              "data": [
                {
                  "name": "Coin",
                  "value": Chart_Day_monthcoin[0]
                },
                {
                  "name": "Mobile",
                  "value": Chart_Day_monthmobile[0]
                },
                {
                  "name": "QR Code",
                  "value": Chart_Day_monthqrcode[0]
                }
              ]
            },
            {
              "id": 135517,
              "name": "Feb",
              "count": 2,
              "total": 900,
              "data": [
                {
                  "name": "Coin",
                  "value": Chart_Day_monthcoin[1]
                },
                {
                  "name": "Mobile",
                  "value": Chart_Day_monthmobile[1]
                },
                {
                  "name": "QR Code",
                  "value": Chart_Day_monthqrcode[1]
                }
              ]
            },
            {
              "id": 144034,
              "name": "Mar",
              "count": 3,
              "total": 900,
              "data": [
                {
                  "name": "Coin",
                  "value": Chart_Day_monthcoin[2]
                },
                {
                  "name": "Mobile",
                  "value": Chart_Day_monthmobile[2]
                },
                {
                  "name": "QR Code",
                  "value": Chart_Day_monthqrcode[2]
                }
              ]
            },
            {
              "id": 4608357,
              "name": "Apr",
              "count": 3,
              "total": 1220,
              "data": [
                {
                  "name": "Coin",
                  "value": Chart_Day_monthcoin[3]
                },
                {
                  "name": "Mobile",
                  "value": Chart_Day_monthmobile[3]
                },
                {
                  "name": "QR Code",
                  "value": Chart_Day_monthqrcode[3]
                }
              ]
            },
            {
              "id": 5781432,
              "name": "May",
              "count": 3,
              "total": 1260,
              "data": [
                {
                  "name": "Coin",
                  "value": Chart_Day_monthcoin[4]
                },
                {
                  "name": "Mobile",
                  "value": Chart_Day_monthmobile[4]
                },
                {
                  "name": "QR Code",
                  "value": Chart_Day_monthqrcode[4]
                }
              ]
            },
            {
              "id": 7176157,
              "name": "Jun",
              "count": 3,
              "total": 850,
              "data": [
                {
                  "name": "Coin",
                  "value": Chart_Day_monthcoin[5]
                },
                {
                  "name": "Mobile",
                  "value": Chart_Day_monthmobile[5]
                },
                {
                  "name": "QR Code",
                  "value": Chart_Day_monthqrcode[5]
                }
              ]
            },
            {
              "id": 7188417,
              "name": "July",
              "count": 3,
              "total": 1140,
              "data": [
                {
                  "name": "Coin",
                  "value": Chart_Day_monthcoin[6]
                },
                {
                  "name": "Mobile",
                  "value": Chart_Day_monthmobile[6]
                },
                {
                  "name": "QR Code",
                  "value": Chart_Day_monthqrcode[6]
                }
              ]
            },
            {
              "id": 7304755,
              "name": "Aug",
              "count": 3,
              "total": 900,
              "data": [
                {
                  "name": "Coin",
                  "value": Chart_Day_monthcoin[7]
                },
                {
                  "name": "Mobile",
                  "value": Chart_Day_monthmobile[7]
                },
                {
                  "name": "QR Code",
                  "value": Chart_Day_monthqrcode[7]
                }
              ]
            },
            {
              "id": 7305129,
              "name": "Sep",
              "count": 3,
              "total": 850,
              "data": [
                {
                  "name": "Coin",
                  "value": Chart_Day_monthcoin[8]
                },
                {
                  "name": "Mobile",
                  "value": Chart_Day_monthmobile[8]
                },
                {
                  "name": "QR Code",
                  "value": Chart_Day_monthqrcode[8]
                }
              ]
            },
            {
              "id": 7305678,
              "name": "Oct",
              "count": 3,
              "total": 790,
              "data": [
                {
                  "name": "Coin",
                  "value": Chart_Day_monthcoin[9]
                },
                {
                  "name": "Mobile",
                  "value": Chart_Day_monthmobile[9]
                },
                {
                  "name": "QR Code",
                  "value": Chart_Day_monthqrcode[9]
                }
              ]
            },
            {
              "id": 7308956,
              "name": "Nov",
              "count": 2,
              "total": 1300,
              "data": [
                {
                  "name": "Coin",
                  "value": Chart_Day_monthcoin[10]
                },
                {
                  "name": "Mobile",
                  "value": Chart_Day_monthmobile[10]
                },
                {
                  "name": "QR Code",
                  "value": Chart_Day_monthqrcode[10]
                }
              ]
            },
            {
              "id": 7308962,
              "name": "Dec",
              "count": 3,
              "total": 850,
              "data": [
                {
                  "name": "Coin",
                  "value": Chart_Day_monthcoin[11]
                },
                {
                  "name": "Mobile",
                  "value": Chart_Day_monthmobile[11]
                },
                {
                  "name": "QR Code",
                  "value": Chart_Day_monthqrcode[11]
                }
              ]
            }
          ],
          "chartTitle": "กราฟการใช้รายเดือน  ( ครั้ง )",
          "dataLabels": false,
          "stackLabels": false,
          "tooltipEnabled": true,
          "digits": 2
        }
        if (monthdata) {
          console.log("dataList---🙂🙂", monthdata);
          setmonth(monthdata)

        }

      }
      if (Chart_Day_daycoin?.length > 0 && Chart_Day_daymobile?.length > 0 && Chart_Day_dayqrcode?.length > 0) {
        const daydata = {
          "isLoading": false,
          "chartReport": [
            {
              "id": 2271,
              "name": "monday",
              "count": 3,
              "total": 1170,
              "data": [
                {
                  "name": "Coin",
                  "value": Chart_Day_daycoin[0]
                },
                {
                  "name": "Mobile",
                  "value": Chart_Day_daymobile[0]
                },
                {
                  "name": "QR Code",
                  "value": Chart_Day_dayqrcode[0]
                }
              ]
            },
            {
              "id": 135517,
              "name": "tuesday",
              "count": 2,
              "total": 900,
              "data": [
                {
                  "name": "Coin",
                  "value": Chart_Day_daycoin[1]
                },
                {
                  "name": "Mobile",
                  "value": Chart_Day_daymobile[1]
                },
                {
                  "name": "QR Code",
                  "value": Chart_Day_dayqrcode[1]
                }
              ]
            },
            {
              "id": 144034,
              "name": "wednesday",
              "count": 3,
              "total": 900,
              "data": [
                {
                  "name": "Coin",
                  "value": Chart_Day_daycoin[2]
                },
                {
                  "name": "Mobile",
                  "value": Chart_Day_daymobile[2]
                },
                {
                  "name": "QR Code",
                  "value": Chart_Day_dayqrcode[2]
                }
              ]
            },
            {
              "id": 4608357,
              "name": "thursday",
              "count": 3,
              "total": 1220,
              "data": [
                {
                  "name": "Coin",
                  "value": Chart_Day_daycoin[3]
                },
                {
                  "name": "Mobile",
                  "value": Chart_Day_daymobile[3]
                },
                {
                  "name": "QR Code",
                  "value": Chart_Day_dayqrcode[3]
                }
              ]
            },
            {
              "id": 5781432,
              "name": "Friday",
              "count": 3,
              "total": 1260,
              "data": [
                {
                  "name": "Coin",
                  "value": Chart_Day_daycoin[4]
                },
                {
                  "name": "Mobile",
                  "value": Chart_Day_daymobile[4]
                },
                {
                  "name": "QR Code",
                  "value": Chart_Day_dayqrcode[4]
                }
              ]
            },
            {
              "id": 7176157,
              "name": "Saturday",
              "count": 3,
              "total": 850,
              "data": [
                {
                  "name": "Coin",
                  "value": Chart_Day_daycoin[5]
                },
                {
                  "name": "Mobile",
                  "value": Chart_Day_daymobile[5]
                },
                {
                  "name": "QR Code",
                  "value": Chart_Day_dayqrcode[5]
                }
              ]
            },
            {
              "id": 7188417,
              "name": "Sunday",
              "count": 3,
              "total": 1140,
              "data": [
                {
                  "name": "Coin",
                  "value": Chart_Day_daycoin[6]
                },
                {
                  "name": "Mobile",
                  "value": Chart_Day_daymobile[6]
                },
                {
                  "name": "QR Code",
                  "value": Chart_Day_dayqrcode[6]
                }
              ]
            }
          ],
          "chartTitle": "กราฟการใช้รายวัน  ( ครั้ง )",
          "dataLabels": false,
          "stackLabels": false,
          "tooltipEnabled": true,
          "digits": 2
        }
        if (daydata) {
          console.log("dataList---🙂🙂", daydata);
          setday(daydata)
          // console.log("dataList---🙂🙂", month);
          setIsLoadchart(false)
        }

      }
      setIsLoading(false)
    }
  }
  const gettransation = async () => {
    // setIsLoading(true)


    // let report_register = await apiDashboard_register(data)
    // console.log(report_register)
    // let report_useractive = await apiDashboard_useractive(data)
    // console.log(report_useractive)


    // if (report && report.status) {
    //   setIsLoading(false)
    //   console.log(report.result.summary)
    //   console.log(report.result.graph)
    //   // setReport(report.result.summary)
    //   // setChartReport(report.result.graph)
    // }
  }

  const options = [
    { value: 'FS', label: 'FS' },
    { value: 'OS', label: 'OS' },
    { value: 'All', label: 'All' },
  ];
  const handleSelectChange = async (option) => {
    let siteAll = await siteall()

    let datasite = siteAll.message.getSites;
    let sitedata = []
    for (let i = 0; i < datasite.length; i++) {
      if (datasite[i].side_type === option) {
        sitedata.push(datasite[i])
      }
    }
    // setSites({
    //   ...site,
    //   site_code: option
    // })
    setSelectedOption(option);
    setSites(sitedata || [])
  };
  return (
    <div>
      <div isLoading={isLoading} className="mg-bt-20 h-d-t-p">Dashboard - ผู้ใช้งาน</div>
      <div className="dash-report-payment-grid">

        <div className="f-c-c dash-report-payment-text">ประเภท :
          <Select style={{ width: '80%' }}
            options={options}
            value={selectedOption}
            onChange={e => {
              handleSelectChange(e)
            }}
          />
          {/* <Select
            style={{ width: "80%" }}
            placeholder="ประเภทสาขา"
            value={(site && site.side_type) || undefined}
            onChange={(value) => setsite({ ...site, side_type: value })}
          >
            <Option value="OS">OS</Option>
            <Option value="FS">FS</Option>
            <Option value="All">All</Option>
          </Select> */}
        </div>
        <div className="f-c-c dash-report-payment-text">สาขา :
          <Select
            style={{ width: "80%" }}
            showSearch
            placeholder="สถานที่"
            // value={machine && machine.site_name}
            onChange={(value, data) => {
              setIsAlertSiteName(false);
              setsite({
                ...site,
                site_name: data?.siteName,
                siteId: value,
                siteCode: data?.siteCode,
                // zone_id: undefined,
              });
            }}
            optionFilterProp="children"
          // filterOption={(input, option) =>
          //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          // }
          >
            {sites.map((site, idx) => (
              <Option key={idx} value={site.site_id} siteName={site.site_name} siteCode={site.siteCode}>
                {site.site_name}
              </Option>
            ))}
          </Select></div>
        <div
          className="f-c-c d-b-t-n-s d-bg-c-t dash-select-date-search"
          onClick={() => {
            setSearchButton(!searchButton)
            test()
          }
          }
        >
          <i className="bi bi-search" style={{ lineHeight: "0" }}></i>
        </div>
      </div>
      <div className="dash-select-date-container-grid">

      </div>
      <div className="dash-select-date-container-grid">
        <div className="f-c-c">เลือกวันที่ : </div>
        <div>
          <RangePicker
            style={{ width: "100%" }}
            format={(date) =>
              moment(date).add(543, "years").format("D MMM YYYY HH:mm")
            }
            allowClear={false}
            showTime={{ format: "HH:mm" }}
            value={[
              moment(startDate, "YYYY-MM-DD HH:mm:ss"),
              moment(endDate, "YYYY-MM-DD HH:mm:ss"),
            ]}
            onChange={(date) => {
              setStartDate(
                moment(date[0]).startOf("minute").format("YYYY-MM-DD HH:mm:ss")
              );
              setEndDate(
                moment(date[1]).endOf("minute").format("YYYY-MM-DD HH:mm:ss")
              );
            }}
          />
        </div>
        {/* <div
          className="f-c-c d-b-t-n-s d-bg-c-t dash-select-date-search"
          onClick={() => {
            setSearchButton(!searchButton)
            test()
          }
          }
        >
          <i className="bi bi-search" style={{ lineHeight: "0" }}></i>
        </div> */}
      </div>
      <div>
        <div className="dash-report-card-payment-grid">
          <div className="c-c-t-s">
            <div className="pst-rlt-h-100pc">
              {isLoading && <Loading />}
              <div className="dash-report-card-padding">
                <div className="dash-report-title">เครดิตคงเหลือ </div>
                <div className="dash-report-card-detail-grid">
                  <div className="f-c-c">
                    <i className="bi bi-book-fill"></i>
                  </div>
                  <div>Qrcode</div>

                  <div>  {topup_SCB ? topup_SCB : ''} บาท</div>
                </div>
                <div className="dash-report-card-detail-grid">
                  <div className="f-c-c">
                    <i className="bi bi-book"></i>
                  </div>
                  <div>truemoney</div>
                  <div>  {topup_truemoney ? topup_truemoney : ''} บาท</div>
                </div>
                <div className="dash-report-card-detail-grid">
                  <div className="f-c-c">
                    <i className="bi bi-book"></i>
                  </div>
                  <div>credit card</div>
                  <div>  {topup_omise + topup_ktbcard} บาท</div>
                </div>
                <div className="dash-report-card-detail-grid">
                  <div className="f-c-c">
                    <i className="bi bi-book"></i>
                  </div>
                  <div>usage </div>
                  <div>{usage_credit ? usage_credit : ''} บาท</div>
                </div>
                <div className="dash-report-card-detail-grid">
                  <div className="f-c-c">
                    <i className="bi bi-book"></i>
                  </div>
                  <div>balance</div>
                  <div> {data_balance ? data_balance : ''} บาท</div>
                </div>
               
              </div>

            </div>
          </div>
          <div className="c-c-t-s">
            <div className="pst-rlt-h-100pc">
              {isLoadinguser && <Loading />}
              <div className="dash-report-card-padding">
                <div className="dash-report-title">รายงานผู้ใช้งาน</div>
                <div className="dash-report-card-detail-grid">
                  <div className="f-c-c">
                    <i className="bi bi-person-circle"></i>
                  </div>
                  <div>Total </div>

                  <div> {data_totaluser ? data_totaluser : 'Loading...'} ราย</div>
                </div>
                <div className="dash-report-card-detail-grid">
                  <div className="f-c-c">
                    <i className="bi bi-person-check"></i>
                  </div>
                  <div>Active</div>
                  <div>{data_useractive ? data_useractive : 'Loading...'} ครั้ง</div>
                </div>
                <div className="dash-report-card-detail-grid">
                  <div className="f-c-c">
                    <i className="bi bi-person-plus"></i>
                  </div>
                  <div>Register</div>
                  <div>{data_Newregister ? data_Newregister : 'Loading...'} ครั้ง</div>
                </div>
                {/* <div className="dash-report-card-detail-grid">
                  <div className="f-c-c">
                    <i className="bi bi-upc-scan"></i>
                  </div>
                  <div>QR-Code</div>
                  <div>3</div>
                </div> */}

                <div>
                  {/* <ProgressBath value={data_totaluser} /> */}
                </div>
              </div>

            </div>
          </div>
          <div className="c-c-t-s">
            <div className="pst-rlt-h-100pc">
              {isLoading && <Loading />}
              <div className="dash-report-card-padding">
                <div className="dash-report-title">สาขาทั้งหมด  {data_total_site + 26} สาขา</div>
                <div className="dash-report-card-detail-grid">
                  <div className="f-c-c">
                    <i className="bi bi-shop-window"></i>
                  </div>
                  <div>สาขา OS</div>
                  <div>{data_total_siteOS}</div>
                </div>
                <div className="dash-report-card-detail-grid">
                  <div className="f-c-c">
                    <i className="bi bi-shop"></i>
                  </div>
                  <div>สาขา FC</div>
                  <div>{data_total_siteFS}</div>
                </div>
                <div className="dash-report-card-detail-grid">
                  <div className="f-c-c">
                    <i className="bi bi-shop"></i>
                  </div>
                  <div>Coinonly </div>
                  <div>26</div>
                </div>
                {/* <div className="dash-report-card-detail-grid">
                  <div className="f-c-c">
                    <i className="bi bi-upc-scan"></i>
                  </div>
                  <div>QR-Code</div>
                  <div>3</div>
                </div> */}

                {/* <div>
                  <ProgressBath value={datamachine_FC_dry + datamachine_FC_wash} />
                </div> */}
              </div>

            </div>
          </div>
        </div>
        <div className="dash-big-title">ช่องทางการชำระเงินจากการใช้งาน    ยอดขายรวม {data_coinValue + data__qrcodeValue + data_mobileValue} บาท</div>
        <div className="dash-report-card-payment-grid">
          <div className="c-c-t-s">
            <div className="pst-rlt-h-100pc">
              {isLoading && <Loading />}
              <div className="p-d-p-ch-10-20">
                <div className="dash-report-card-payment-detail-grid">
                  <div className="f-c-c">
                    <i className="bi bi-layers"></i>
                  </div>
                  <div>การใช้งานเหรียญ</div>
                  <div> {data_count_coin ? data_count_coin : 'Loading...'} ครั้ง</div>
                </div>
                <div className="dash-report-card-payment-detail-grid">
                  <div className="f-c-c">
                    <i className="bi bi-layers"></i>
                  </div>
                  <div>ยอดเหรียญ  </div>
                  <div> {data_coinValue ? data_coinValue : 'Loading...'} บาท</div>
                </div>
                <div className="dash-report-card-detail-grid">
                  <div className="f-c-c">
                    <i className="bi bi-book-fill"></i>
                  </div>
                  <div>เครื่องซักผ้า</div>

                  <div> {data_machine_washcoin ? data_machine_washcoin : 'Loading...'} ครั้ง</div>
                </div>
                <div className="dash-report-card-detail-grid">
                  <div className="f-c-c">
                    <i className="bi bi-book"></i>
                  </div>
                  <div>เครื่องอบผ้า</div>
                  <div>{data_machine_drycoin ? data_machine_drycoin : 'Loading...'} ครั้ง</div>
                </div>
                {/* <div className="dash-report-card-detail-grid">
                  <div className="f-c-c">
                    <i className="bi bi-upc-scan"></i>
                  </div>
                  <div>QR-Code</div>
                  <div>3</div>
                </div> */}

                <div>
                  <Progresscount value={data_machine_washcoin + data_machine_drycoin} />
                </div>

                {/* <div>
                  <ProgressBath value={data_value_coin} />
                </div> */}
              </div>
            </div>
          </div>
          <div className="c-c-t-s">
            <div className="pst-rlt-h-100pc">
              {isLoading && <Loading />}
              <div className="p-d-p-ch-10-20">
                <div className="dash-report-card-payment-detail-grid">
                  <div className="f-c-c">
                    <i className="bi bi-phone"></i>
                  </div>
                  <div>การใช้งาน App</div>
                  <div> {data_count_mobile ? data_count_mobile : 'Loading...'} ครั้ง</div>
                </div>
                <div className="dash-report-card-payment-detail-grid">
                  <div className="f-c-c">
                    <i className="bi bi-phone"></i>
                  </div>
                  <div>ยอด App  </div>
                  <div> {data_mobileValue ? data_mobileValue : 'Loading...'} บาท</div>
                </div>
                <div className="dash-report-card-detail-grid">
                  <div className="f-c-c">
                    <i className="bi bi-book-fill"></i>
                  </div>
                  <div>เครื่องซักผ้า</div>

                  <div> {data_machine_washmobile ? data_machine_washmobile : 'Loading...'} ครั้ง</div>
                </div>
                <div className="dash-report-card-detail-grid">
                  <div className="f-c-c">
                    <i className="bi bi-book"></i>
                  </div>
                  <div>เครื่องอบผ้า</div>
                  <div>{data_machine_drymobile ? data_machine_drymobile : 'Loading...'} ครั้ง</div>
                </div>
                <div>
                  <Progresscount value={data_machine_washmobile + data_machine_drymobile} />
                </div>

              </div>
            </div>
          </div>
          <div className="c-c-t-s">
            <div className="pst-rlt-h-100pc">
              {isLoading && <Loading />}
              <div className="p-d-p-ch-10-20">
                <div className="dash-report-card-payment-detail-grid">
                  <div className="f-c-c">
                    <i className="bi bi-upc-scan"></i>
                  </div>
                  <div>การใช้งาน QR-Code</div>
                  <div> {data_count_qrcode ? data_count_qrcode : 'Loading...'} ครั้ง</div>
                </div>

                <div className="dash-report-card-payment-detail-grid">
                  <div className="f-c-c">
                    <i className="bi bi-upc-scan"></i>
                  </div>
                  <div>ยอด QR-Code  </div>
                  <div> {data__qrcodeValue ? data__qrcodeValue : 'Loading...'} บาท</div>
                </div>
                <div className="dash-report-card-detail-grid">
                  <div className="f-c-c">
                    <i className="bi bi-book-fill"></i>
                  </div>
                  <div>เครื่องซักผ้า</div>

                  <div> {data_machine_wash_qrcode ? data_machine_wash_qrcode : 'Loading...'} ครั้ง</div>
                </div>
                <div className="dash-report-card-detail-grid">
                  <div className="f-c-c">
                    <i className="bi bi-book"></i>
                  </div>
                  <div>เครื่องอบผ้า</div>
                  <div>{data_machine_dry_qrcode ? data_machine_dry_qrcode : 'Loading...'} ครั้ง</div>
                </div>
                <div>
                  <Progresscount value={data_machine_wash_qrcode + data_machine_dry_qrcode} />
                </div>
              </div>
            </div>
          </div>

          {/* <div className="c-c-t-s">
            <div className="pst-rlt-h-100pc">
              {isLoading && <Loading />}
              <div className="dash-report-card-padding">
                <div className="dash-report-card-payment-detail-grid">
                  <div className="f-c-c">
                    <i className="bi bi-layers"></i>
                  </div>
                  <div>การใช้งานเหรียญ  </div>
                  <div> {data_coinValue ? data_coinValue : 'Loading...'} บาท</div>
                </div>
                <div className="dash-report-card-detail-grid">
                  <div className="f-c-c">
                    <i className="bi bi-book-fill"></i>
                  </div>
                  <div>เครื่องซักผ้า</div>

                  <div> {data_machine_washcoin ? data_machine_washcoin : 'Loading...'} ครั้ง</div>
                </div>
                <div className="dash-report-card-detail-grid">
                  <div className="f-c-c">
                    <i className="bi bi-book"></i>
                  </div>
                  <div>เครื่องอบผ้า</div>
                  <div>{data_machine_drycoin ? data_machine_drycoin : 'Loading...'} ครั้ง</div>
                </div>

                <div>
                  <Progresscount value={data_machine_washcoin + data_machine_drycoin} />
                </div>
              </div>

            </div>
          </div>
          <div className="c-c-t-s">
            <div className="pst-rlt-h-100pc">
              {isLoading && <Loading />}
              <div className="dash-report-card-padding">
                <div className="dash-report-card-payment-detail-grid">
                  <div className="f-c-c">
                    <i className="bi bi-phone"></i>
                  </div>
                  <div>การใช้งาน App  </div>
                  <div> {data_mobileValue ? data_mobileValue : 'Loading...'} บาท</div>
                </div>
                <div className="dash-report-card-detail-grid">
                  <div className="f-c-c">
                    <i className="bi bi-book-fill"></i>
                  </div>
                  <div>เครื่องซักผ้า</div>

                  <div> {data_machine_washmobile ? data_machine_washmobile : 'Loading...'} ครั้ง</div>
                </div>
                <div className="dash-report-card-detail-grid">
                  <div className="f-c-c">
                    <i className="bi bi-book"></i>
                  </div>
                  <div>เครื่องอบผ้า</div>
                  <div>{data_machine_drymobile ? data_machine_drymobile : 'Loading...'} ครั้ง</div>
                </div>
                <div>
                  <Progresscount value={data_machine_washmobile + data_machine_drymobile} />
                </div>
              </div>

            </div>
          </div>
          <div className="c-c-t-s">
            <div className="pst-rlt-h-100pc">
              {isLoading && <Loading />}
              <div className="dash-report-card-padding">
                <div className="dash-report-card-payment-detail-grid">
                  <div className="f-c-c">
                    <i className="bi bi-upc-scan"></i>
                  </div>
                  <div>การใช้งาน QR-Code  </div>
                  <div> {data__qrcodeValue ? data__qrcodeValue : 'Loading...'} บาท</div>
                </div>
                <div className="dash-report-card-detail-grid">
                  <div className="f-c-c">
                    <i className="bi bi-book-fill"></i>
                  </div>
                  <div>เครื่องซักผ้า</div>

                  <div> {data_machine_wash_qrcode ? data_machine_wash_qrcode : 'Loading...'} ครั้ง</div>
                </div>
                <div className="dash-report-card-detail-grid">
                  <div className="f-c-c">
                    <i className="bi bi-book"></i>
                  </div>
                  <div>เครื่องอบผ้า</div>
                  <div>{data_machine_dry_qrcode ? data_machine_dry_qrcode : 'Loading...'} ครั้ง</div>
                </div>
                <div>
                  <Progresscount value={data_machine_wash_qrcode + data_machine_dry_qrcode} />
                </div>
              </div>

            </div>
          </div> */}
        </div>
        {/* 
        <div className="dash-big-title">ผู้ใช้งาน</div>
        <div className="dash-report-card-payment-grid">


        </div> */}
        <div className="dash-duo-chart-grid">
          {isLoading && <Loading />}
          <ChartColumnStackDashboardNew
            isLoading={isLoadchart}
            chartReport={month.chartReport || []}
            chartTitle='กราฟการใช้รายเดือน  ( ครั้ง )'
            dataLabels={false}
            stackLabels={false}
            tooltipEnabled={true}
            digits={2}
          />
          <ChartColumnStackDashboardNew
            isLoading={isLoadchart}
            chartReport={day.chartReport || []}
            chartTitle='กราฟการใช้รายวัน  ( ครั้ง )'
            dataLabels={false}
            stackLabels={false}
            tooltipEnabled={true}
            digits={2}
          />

        </div>

        {/* <div className="dash-duo-chart-grid">
          {isLoading && <Loading />}
          <ChartColumnStackDashboardNew
            isLoading={isLoadchart}
            chartReport={monthFS.chartReport || []}
            chartTitle='กราฟการใช้รายเดือน FS ( ครั้ง )'
            dataLabels={false}
            stackLabels={false}
            tooltipEnabled={true}
            digits={2}
          />

          <ChartColumnStackDashboardNew
            isLoading={isLoadchart}
            chartReport={dayFS.chartReport || []}
            chartTitle='กราฟการใช้รายวัน FS ( ครั้ง )'
            dataLabels={false}
            stackLabels={false}
            tooltipEnabled={true}
            digits={2}
          />
        </div> */}

        {/* <div className="dash-duo-chart-grid">
          <ChartColumnDashboardNew
            // isLoading={isLoadingDay}
            chartReport={
              (dayFScoin) || []
            }
            dataLabels={true}
            valueData="value"
            tooltipEnabled={false}
          />

          <ChartColumnDashboardNew
            // isLoading={isLoadingDay}
            chartReport={
              (dayOScoin) || []
            }
            dataLabels={true}
            valueData="value"
            tooltipEnabled={false}
          />
        </div>
        <div className="dash-duo-chart-grid">
          <ChartColumnDashboardNew
            // isLoading={isLoadingDay}
            chartReport={
              (dayFSmobile) || []
            }
            dataLabels={true}
            valueData="value"
            tooltipEnabled={false}
          />

          <ChartColumnDashboardNew
            // isLoading={isLoadingDay}
            chartReport={
              (dayOSmobile) || []
            }
            dataLabels={true}
            valueData="value"
            tooltipEnabled={false}
          />
        </div>
        <div className="dash-duo-chart-grid">
          <ChartColumnDashboardNew
            // isLoading={isLoadingDay}
            chartReport={
              (dayFSqrcode) || []
            }
            dataLabels={true}
            valueData="value"
            tooltipEnabled={false}
          />

          <ChartColumnDashboardNew
            // isLoading={isLoadingDay}
            chartReport={
              (dayOSqrcode) || []
            }
            dataLabels={true}
            valueData="value"
            tooltipEnabled={false}
          />
        </div> */}



      </div>


      {/* <Report  isLoading={isLoading} /> */}

      {/* <div className="dash-duo-chart-grid">
        <ChartColumn
          isLoading={isLoading}
          chartReport={(chartReport && chartReport.graph_users) || []}
          chartTitle="* กราฟแสดงข้อมูลผู้ใช้งาน"
          nameSeries="จำนวนผู้ใช้งาน"
          dataLabels={true}
          valueData="value"
          tooltipEnabled={false}
        />
        <ChartPie
          isLoading={isLoading}
          chartReport={
            (chartReport && chartReport.graph_percent_payment_bath) || []
          }
          chartTitle="กราฟแสดงเปอร์เซนต์ประเภทการจ่าย ( บาท )"
        />
      </div>

      <ChartColumn
        isLoading={isLoading}
        chartReport={(chartReport && chartReport.graph_usage_last_year) || []}
        chartTitle="กราฟแสดงการใช้งาน 1 ปีย้อนหลัง"
        nameSeries="จำนวนผู้ใช้งาน"
        dataLabels={true}
        valueData="value"
        tooltipEnabled={false}
      />

      <ChartColumnStack
       
        chartReport={(chartReport && chartReport.graph_usage) || []}
        chartTitle="กราฟแสดงการใช้งาน"
        dataLabels={false}
        stackLabels={false}
        tooltipEnabled={true}
        digits={2}
      />

      <ChartColumn
        isLoading={isLoading}
        chartReport={
          (chartReport && chartReport.graph_usage_last_month_bath) || []
        }
        chartTitle="กราฟแสดงการใช้งานแบ่งตามวัน 1 เดือนย้อนหลัง (บาท)"
        nameSeries="จำนวนเงิน"
        dataLabels={true}
        valueData="value"
        tooltipEnabled={false}
      />

      <ChartColumn
        isLoading={isLoading}
        chartReport={
          (chartReport && chartReport.graph_usage_last_month_count) || []
        }
        chartTitle="กราฟแสดงการใช้งานแบ่งตามวัน 1 เดือนย้อนหลัง (ครั้ง)"
        nameSeries="จำนวนครั้ง"
        dataLabels={true}
        valueData="value"
        tooltipEnabled={false}
      />

      <ChartColumn
        isLoading={isLoading}
        chartReport={(chartReport && chartReport.graph_percent_each_use) || []}
        chartTitle="กราฟแสดงเปอร์เซนต์การใช้แต่ละสถานที่"
        nameSeries="%"
        dataLabels={false}
        valueData="percent"
        tooltipEnabled={true}
      /> */}

      {/* <TableNewUser
      startDate={startDate}
      endDate={endDate}
      searchButton={searchButton}
    />

    <TableMaximumUsage
      startDate={startDate}
      endDate={endDate}
      searchButton={searchButton}
    />

    <TableActiveUsers />

    <TableInactiveUsers />

    <TableMachineAverage
      startDate={startDate}
      endDate={endDate}
      searchButton={searchButton}
    /> */}
    </div>
  );
};

export default DashboardCustomer;
