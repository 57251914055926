import React, { useState, useEffect } from 'react'
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { Input, Select, DatePicker } from 'antd';
import './CallSupport.css'
import { LoadingOutlined } from '@ant-design/icons';

import moment from 'moment';
import 'moment/locale/th'

import { openNotification, getSearchParams } from '../../utils/function'
import { apiCSPAddInquiry, apiCSPGetInquiryById, apiCSPEditInquiry, apiCSPGetSale } from '../../services/apis/call'

const { Option } = Select;

const InquiryAddEdit = () => {
  const { callMaintenanceId } = useParams();
  const location = useLocation();
  const history = useHistory();

  const params = new URLSearchParams(location.search)
  const p = params.get('p')
  const ttl = params.get('ttl')
  const start = params.get('start')
  const end = params.get('end')
  const sw = params.get('sw')

  const [isLoading, setIsLoading] = useState(false)
  const [call, setCall] = useState(undefined)
  const [sales, setSales] = useState([])
  const [time, setTime] = useState(moment())

  const [isAlertName, setIsAlertName] = useState(false)
  const [isAlertStatus, setIsAlertStatus] = useState(false)
  const [isAlertStatusCus, setIsAlertStatusCus] = useState(false)

  useEffect(() => {
    if (callMaintenanceId) getData()
    else {
      setCall(undefined)
      setIsAlertName(false)
      setIsAlertStatus(false)
      setIsAlertStatusCus(false)
    }
  }, [callMaintenanceId])

  useEffect(() => {
    if (!call) setCall({ ...call, have_space: false })
  }, [call])

  useEffect(() => {
    if (!call) setCall({ ...call, call_channel: 'Line OA' })
  }, [call])

  const getData = async () => {
    let data = await apiCSPGetInquiryById(callMaintenanceId)
    if (data && data.status) setCall(data.result)

  }

  useEffect(() => {
    const getStaticDropdown = async () => {
      let sale = await apiCSPGetSale()
      if (sale && sale.status) setSales(sale.result || [])
    }
    getStaticDropdown()
  }, [])

  useEffect(() => {
    if (callMaintenanceId) {
      setTime()
    } else {
      const timer = setInterval(() => setTime(moment()), 1000);
      return () => clearInterval(timer);
    }
  }, [callMaintenanceId]);

  const addEditData = async () => {
    if (!(call && call.name)||
    !(call && call.status)
    ) {
      if (!(call && call.name)) setIsAlertName(true)
      if (!(call && call.status)) setIsAlertStatus(true)
      
    }
    else {
      setIsLoading(true)

      let data = { ...call }
      console.log(data)
      if (!callMaintenanceId) {
        data.admin_id = Number(localStorage.getItem('user_id'))
        data.create_date = moment(time).format('YYYY-MM-DD HH:mm:ss')
      }
      if (call && call.open_date) data.open_date = moment(call.open_date).format('YYYY-MM-DD HH:mm:ss')

      console.log(data)

      let newData = callMaintenanceId ? await apiCSPEditInquiry(data) : await apiCSPAddInquiry(data)
      if (newData && newData.status) {
        setIsLoading(false)
        openNotification('success', 'Success !', `${callMaintenanceId ? 'Edit' : 'Add new'} call support inquiry successful.`)
        setTimeout(() => history.push(`/call-support/list-inquiry${getSearchParams(['p', 'ttl', 'start', 'end', 'sw'], [p, ttl, start, end, sw])}`), 1000);
      } else {
        setIsLoading(false)
        openNotification('error', 'Failed !', `Failed to ${callMaintenanceId ? 'edit' : 'add new'} call support inquiry.`)
      }
    }
  }

  return (
    <div>
      <div className="mg-bt-20 h-d-t-p">Call Support Inquiry - {callMaintenanceId ? 'Edit' : 'Add'}</div>

      <div className="t-m-c-i-p-g">
        <div className="f-s-c">ผู้รับ</div>
        <div>{callMaintenanceId ? call && call.admin_name : localStorage.getItem('user_name') || '-'}</div>

        <div className="f-s-c">วันเดือนปี</div>
        <div>{callMaintenanceId
          ? call && call.create_date
            ? moment(call.create_date).add(543, 'years').format('D MMM YYYY')
            : '-'
          : moment(time).add(543, 'years').format('D MMM YYYY')}</div>

        <div className="f-s-c">เวลา</div>
        <div>{callMaintenanceId
          ? call && call.create_date
            ? moment(call.create_date).add(543, 'years').format('HH:mm:ss')
            : '-'
          : moment(time).add(543, 'years').format('HH:mm:ss')}</div>

        <div className="f-s-c">ช่องทางการแจ้ง</div>
        <div>
          <Select style={{ width: '100%' }}
            placeholder="ช่องทางการแจ้ง"
            value={call && call.call_channel || undefined}
            onChange={value => setCall({ ...call, call_channel: value })}
          >
            <Option value="Line OA">Line OA</Option>
            <Option value="Line Group">Line Group</Option>
            <Option value="Facebook">Facebook</Option>
            <Option value="Call">Call</Option>
            <Option value="Email">Email</Option>
          </Select>
        </div>

        <div className="f-s-c">Sale</div>
        <div>
          <Select style={{ width: '100%' }}
            placeholder="Sale"
            showSearch
            value={call && call.sale_id}
            onChange={value => setCall({ ...call, sale_id: value })}
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {sales.map((sale, idx) => <Option key={idx} value={sale.admin_id}>{sale.admin_name}</Option>)}
          </Select>
        </div>

        <div className="f-s-c">ชื่อลูกค้า</div>
        <div>
          <Input
            style={{ borderColor: isAlertName ? '#EC1C24' : null }}
            placeholder="ชื่อลูกค้า"
            value={call && call.name || ''}
            onChange={e => {
              setIsAlertName(false)
              setCall({ ...call, name: e.target.value })
            }}
          />
        </div>

        <div className="f-s-c">เบอร์โทร</div>
        <div>
          <Input
            placeholder="เบอร์โทร"
            value={call && call.phone || ''}
            onChange={e => setCall({ ...call, phone: e.target.value.replace(/[^0-9]+/g, '') })}
          />
        </div>

        <div className="f-s-c">E-mail</div>
        <div>
          <Input
            placeholder="E-mail"
            value={call && call.email || ''}
            onChange={e => setCall({ ...call, email: e.target.value })}
          />
        </div>

        <div className="f-s-c">Line ID</div>
        <div>
          <Input
            placeholder="Line ID"
            value={call && call.line || ''}
            onChange={e => setCall({ ...call, line: e.target.value })}
          />
        </div>

        <div className="f-s-c">มีพื้นที่หรือไม่</div>
        <div>
          <Select style={{ width: '100%' }}
            placeholder="มีพื้นที่หรือไม่"
            value={call && call.have_space}
            onChange={value => setCall({ ...call, have_space: value })}
          >
            <Option value={true}>มี</Option>
            <Option value={false}>ไม่มี</Option>
          </Select>
        </div>

        <div className="f-s-c">สถานที่ที่สนใจ</div>
        <div>
          <Input
            placeholder="สถานที่ที่สนใจ"
            value={call && call.area_interest || ''}
            onChange={e => setCall({ ...call, area_interest: e.target.value })}
          />
        </div>

        <div className="f-s-c">งบการลงทุน</div>
        <div>
          <Select style={{ width: '100%' }}
            placeholder="งบการลงทุน"
            value={call && call.budget || undefined}
            onChange={value => setCall({ ...call, budget: value })}
          >
            <Option value="< 250k">{'< 250k'}</Option>
            <Option value="250k - 600k">250k - 600k</Option>
            <Option value="600k - 1M">600k - 1M</Option>
            <Option value="1M - 2M">1M - 2M</Option>
            <Option value="> 2M">{'> 2M'}</Option>
          </Select>
        </div>

        <div className="f-s-c">แพ็กเกจที่เหมาะสม</div>
        <div>
          <Select style={{ width: '100%' }}
            placeholder="แพ็กเกจที่เหมาะสม"
            value={call && call.package || undefined}
            onChange={value => setCall({ ...call, package: value })}
          >
            <Option value="Eco">Eco</Option>
            <Option value="Standard">Standard</Option>
            <Option value="Plus">Plus</Option>
            <Option value="Max">Max</Option>
          </Select>
        </div>

        <div className="f-s-c">Note จาก Call Support</div>
        <div>
          <Input.TextArea
            autoSize={{ minRows: 3 }}
            placeholder="Note จาก Call Support"
            value={call && call.call_note || ''}
            onChange={e => setCall({ ...call, call_note: e.target.value })}
          />
        </div>

        <div className="f-s-c">Note จาก Sale</div>
        <div>
          <Input.TextArea
            autoSize={{ minRows: 3 }}
            placeholder="Note จาก Sale"
            value={call && call.sale_note || ''}
            onChange={e => setCall({ ...call, sale_note: e.target.value })}
          />
        </div>

        <div className="f-s-c">วันที่คาดว่าจะเปิด</div>
        <div>
          <DatePicker style={{ width: '100%' }}
            placeholder="วันที่คาดว่าจะเปิด"
            value={call && call.open_date && moment(call.open_date)}
            onChange={date => setCall({ ...call, open_date: date })}
            format={date => moment(date).add(543, 'years').format('D MMM YYYY')}
          />
        </div>

        <div className="f-s-c">สถานะการติดตามลูกค้า</div>
        <div className={isAlertStatusCus && 'antd-sl-al-bd' || ''}>
          <Select style={{ width: '100%' }}
            placeholder="สถานะ"
            value={call && call.relation_status || undefined}
            onChange={value => {
              setIsAlertStatusCus(false)
              setCall({ ...call, relation_status: value })}
            }     
          >
            <Option value="HOT">Hot</Option>
            <Option value="WARM">Warm</Option>
            <Option value="COLD">Cold</Option>
          </Select>
        </div>



        <div className="f-s-c">สถานะ</div>
        <div className={isAlertStatus && 'antd-sl-al-bd' || ''}>
          <Select style={{ width: '100%' }}
            placeholder="สถานะ"
            value={call && call.status || undefined}
            onChange={value => {
              setIsAlertStatus(false)
              setCall({ ...call, status: value })}
            }     
          >
            <Option value="Call Center ให้ข้อมูล">Call Center ให้ข้อมูล</Option>
            <Option value="รอฝ่ายขายติดต่อกลับ">รอฝ่ายขายติดต่อกลับ</Option>
            <Option value="รอตัดสินใจ">รอตัดสินใจ</Option>
            <Option value="ปิดการขาย">ปิดการขาย</Option>
            <Option value="Loss Sale">Loss Sale</Option>
            <Option value="เสนอพื้นที่ให้เช่า">เสนอพื้นที่ให้เช่า</Option>
          </Select>
        </div>
      </div>

      

      <div className="f-c-c s-e-b">
        <div className="f-c-c d-b-t-n-s d-bg-c-t"
          style={{ width: '100px', pointerEvents: isLoading ? 'none' : null }}
          onClick={() => addEditData()}
        >{isLoading ? <LoadingOutlined /> : 'Submit'}</div>
      </div>

    </div>
  )
}

export default InquiryAddEdit
