import React, { useState, useEffect } from 'react'
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { Input, InputNumber, Select, DatePicker, Checkbox } from 'antd';
import './Promotion.css'
import { LoadingOutlined } from '@ant-design/icons';

import moment from 'moment';
import 'moment/locale/th'

import { openNotification, getSearchParams } from '../../utils/function'
import { apiPMTAddPromoCode, apiPMTGetPromoCodeById, apiPMTEditPromoCodeById } from '../../services/apis/promotion'

const { Option } = Select;
const { RangePicker } = DatePicker;

const PromotionPromoAddEditPage = () => {
  const { promotionId } = useParams();
  const location = useLocation();
  const history = useHistory();

  const params = new URLSearchParams(location.search)
  const p = params.get('p')
  const ttl = params.get('ttl')
  const sw = params.get('sw')

  const [isLoading, setIsLoading] = useState(false)
  const [promotion, setPromotion] = useState(undefined)

  const [isAlertPeriodPromotion, setIsAlertPeriodPromotion] = useState(false)
  const [isAlertCode, setIsAlertCode] = useState(false)
  const [isAlertType, setIsAlertType] = useState(false)
  const [isAlertValue, setIsAlertValue] = useState(false)
  const [isAlertLimit, setIsAlertLimit] = useState(false)

  useEffect(() => {
    if (promotionId) getData()
  }, [promotionId])

  const getData = async () => {
    let data = await apiPMTGetPromoCodeById(promotionId)
    if (data && data.status) setPromotion(data.result)
  }

  const addEditPromotion = async () => {
    if (
      !(promotion && promotion.start_date) ||
      !(promotion && promotion.promo_code) ||
      !(promotion && promotion.return_type) ||
      isNaN(promotion && promotion.return_value) ||
      isNaN(promotion && promotion.total_limit)
    ) {
      if (!(promotion && promotion.start_date)) setIsAlertPeriodPromotion(true)
      if (!(promotion && promotion.promo_code)) setIsAlertCode(true)
      if (!(promotion && promotion.return_type)) setIsAlertType(true)
      if (isNaN(promotion && promotion.return_value)) setIsAlertValue(true)
      if (isNaN(promotion && promotion.total_limit)) setIsAlertLimit(true)

    } else {
      setIsLoading(true)

      let data = { ...promotion }
      if (!promotionId) data.multiple = promotion.multiple && true || false

      let newData = promotionId ? await apiPMTEditPromoCodeById(data) : await apiPMTAddPromoCode(data)
      if (newData && newData.status) {
        setIsLoading(false)
        openNotification('success', 'Success !', `${promotionId ? 'Edit' : 'Add new'} promotion code successful.`)
        setTimeout(() => history.push(`/promotion/promotion-code${getSearchParams(['p', 'ttl', 'sw'], [p, ttl, sw])}`), 1000);
      } else {
        setIsLoading(false)
        openNotification('error', 'Failed !', `Failed to ${promotionId ? 'edit' : 'add new'} promotion code.`)
      }
    }
  }

  return (
    <div>
      <div className="mg-bt-20 h-d-t-p">Promotion - {promotionId ? 'Edit' : 'Add'} Promo Code</div>

      <div className="t-m-c-i-p-g">
        <div className="f-s-c">Period Promotion</div>
        <div>
          <RangePicker
            style={{ width: '100%', borderColor: isAlertPeriodPromotion ? '#EC1C24' : null }}
            placeholder={["Period Start", "Period End"]}
            format={date => moment(date).add(543, 'years').format('D MMM YYYY')}
            allowClear={false}
            value={[
              promotion && promotion.start_date && moment(promotion.start_date, 'YYYY-MM-DD HH:mm:ss'),
              promotion && promotion.end_date && moment(promotion.end_date, 'YYYY-MM-DD HH:mm:ss')
            ]}
            onChange={date => {
              setIsAlertPeriodPromotion(false)
              setPromotion({
                ...promotion,
                start_date: moment(date[0]).format('YYYY-MM-DD HH:mm:ss'),
                end_date: moment(date[1]).format('YYYY-MM-DD HH:mm:ss')
              })
            }}
            disabledDate={current => current && current < moment().startOf('day')}
          />
        </div>

        <div className="f-s-c">Code</div>
        <div>
          <Input
            style={{ borderColor: isAlertCode ? '#EC1C24' : null }}
            placeholder="Code"
            value={promotion && promotion.promo_code || ''}
            onChange={e => {
              setIsAlertCode(false)
              setPromotion({ ...promotion, promo_code: e.target.value })
            }}
          />
        </div>

        <div className="f-s-c">Type</div>
        <div className={isAlertType && 'antd-sl-al-bd' || ''}>
          <Select style={{ width: '100%' }}
            placeholder="Type"
            value={promotion && promotion.return_type || undefined}
            onChange={value => {
              setIsAlertType(false)
              setPromotion({ ...promotion, return_type: value })
            }}
          >
            <Option value="DISCOUNT">Discount</Option>
            <Option value="FREE">Free</Option>
            <Option value="POINT">Point</Option>
          </Select>
        </div>

        <div className="f-s-c">Value</div>
        <div>
          <InputNumber min={0}
            step={1}
            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
            style={{ width: '100%', borderColor: isAlertValue ? '#EC1C24' : null }}
            placeholder="Value"
            value={promotion && promotion.return_value}
            onChange={value => {
              setIsAlertValue(false)
              setPromotion({ ...promotion, return_value: Math.round(value) || 0 })
            }}
          />
        </div>

        <div className="f-s-c">Limit</div>
        <div>
          <InputNumber min={0} max={1000000}
            step={1}
            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
            style={{ width: '100%', borderColor: isAlertLimit ? '#EC1C24' : null }}
            placeholder="Limit"
            value={promotion && promotion.total_limit}
            onChange={value => {
              setIsAlertLimit(false)
              setPromotion({ ...promotion, total_limit: Math.round(value) || 0 })
            }}
          />
        </div>

        <div className="f-s-c">Multiple</div>
        <div className="f-s-c">
          <Checkbox
            checked={promotion && promotion.multiple}
            onChange={e => setPromotion({ ...promotion, multiple: e.target.checked })}
          />
        </div>
      </div>

      <div className="f-c-c s-e-b">
        <div className="f-c-c d-b-t-n-s d-bg-c-t"
          style={{ width: '210px', pointerEvents: isLoading ? 'none' : null }}
          onClick={() => addEditPromotion()}
        >{isLoading ? <LoadingOutlined /> : `${promotionId ? 'Edit' : 'Add New'} Promo Code`}</div>
      </div>

    </div>
  )
}

export default PromotionPromoAddEditPage
