import axiostw from '../../config/axios-tw';
import axiostw_promotion from '../../config/axios-twpromotion';
const siteall = async (data) => {
  try {
    const result = await axiostw.get('/site/site-all')
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}
const machinebysite = async (data) => {
    try {
      const result = await axiostw.post('/machine/machine-BYsiteCode',data)
      return result && result.data
    } catch (error) {
      return error && error.response && error.response.data
    }
  }
  const CreateCollectSite = async (data) => {
    try {
      const result = await axiostw_promotion.post('/Collect/CreateCollectSite',data)
      return result && result.data
    } catch (error) {
      return error && error.response && error.response.data
    }
  }
  const getListCollectSite = async (data) => {
    try {
      const result = await axiostw_promotion.get('/Collect/get_dataCollect')
      return result && result.data
    } catch (error) {
      return error && error.response && error.response.data
    }
  }
  const getListCollecBytSite = async (data) => {
    try {
      const result = await axiostw_promotion.post('/Collect/get_dataCollectBysite',data)
      return result && result.data
    } catch (error) {
      return error && error.response && error.response.data
    }
  }
  

  const UpdateCollectActive = async (data) => {
    try {
      const result = await axiostw_promotion.post('/Collect/UpdateSiteCollectActive',data)
      return result && result.data
    } catch (error) {
      return error && error.response && error.response.data
    }
  }
  const getCollectmanage = async (data) => {
    try {
      const result = await axiostw_promotion.post('/CollectUser/Collectdatamanage',data)
      return result && result.data
    } catch (error) {
      return error && error.response && error.response.data
    }
  }
  const DelectCollectdata = async (data) => {
    try {
      const result = await axiostw_promotion.post('/CollectUser/DelectCollectdata',data)
      return result && result.data
    } catch (error) {
      return error && error.response && error.response.data
    }
  }
  const addCreatePromocode = async (data) => {
    try {
      const result = await axiostw_promotion.post('/Promocode/createCode_discount',data)
      return result && result.data
    } catch (error) {
      return error && error.response && error.response.data
    }
  }
  const getPromocode = async (data) => {
    try {
      const result = await axiostw_promotion.post('/Promocode/HistoryPromocodeSite')
      return result && result.data
    } catch (error) {
      return error && error.response && error.response.data
    }
  }
  const getPromocodeBysite = async (data) => {
    try {
      const result = await axiostw_promotion.post('/Promocode/HistoryPromocodeSite',data)
      return result && result.data
    } catch (error) {
      return error && error.response && error.response.data
    }
  }
  
  const updatestatusPromocode = async (data) => {
    try {
      const result = await axiostw_promotion.post('/Promocode/updateStatuPromocode',data)
      return result && result.data
    } catch (error) {
      return error && error.response && error.response.data
    }
  }
  
export {
    siteall,machinebysite,CreateCollectSite,getListCollectSite,UpdateCollectActive,getCollectmanage,DelectCollectdata,
    addCreatePromocode,getPromocode,updatestatusPromocode,getPromocodeBysite,getListCollecBytSite
}