import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom';
import { Select } from 'antd';
import './SiteManagement.css'

import { downloadQR } from '../../utils/function'
import { apiMCHGetControllerMachine } from '../../services/apis/machine'
import { apiPRDGetProductByMachineSubTypeId } from '../../services/apis/product'
import { apiSGetSite, apiSGetSiteManagementQR } from '../../services/apis/site'

import Loading from '../../components/Loading/Loading'

var QRCode = require('qrcode.react');
const { Option } = Select;

const SiteManagementQR = () => {
  const history = useHistory();
  const { siteId } = useParams();
  const [isLoading, setIsLoading] = useState(false)
  const [site, setSite] = useState('')
  const [dataForGen, setDataForGen] = useState(undefined)
  const [machines, setMachines] = useState([])
  const [products, setProducts] = useState([])
  const [linkQR, setLinkQR] = useState('')

  useEffect(() => {
    const getStaticDropdown = async () => {
      let type = await apiMCHGetControllerMachine()
      if (type && type.status) setMachines(type.result || [])

      let site = await apiSGetSite()
      if (site && site.status) {
        let siteById = (site.result || []).find(list => list.site_id === Number(siteId))
        if (siteById) setSite(siteById.site_name || '')
        else history.push('/site-management/list')
      }
    }
    getStaticDropdown()
  }, [])

  useEffect(() => {
    if (dataForGen && dataForGen.machine_id) {
      let machine = machines.find(list => list.machine_id === dataForGen.machine_id)
      const getProducts = async () => {
        let product = await apiPRDGetProductByMachineSubTypeId(machine.machine_type_id, machine.machine_sub_type_id)
        if (product && product.status) setProducts(product.result || [])
      }
      getProducts()
    }
  }, [dataForGen && dataForGen.machine_id])

  useEffect(() => {
    if (dataForGen && dataForGen.machine_id && dataForGen.product_id) {
      const getQR = async () => {
        setIsLoading(true)
        let qr = await apiSGetSiteManagementQR(dataForGen.machine_id, dataForGen.product_id)
        if (qr && qr.status) {
          setLinkQR(qr.result || '')
          setIsLoading(false)
        }
      }
      getQR()
    }
  }, [dataForGen])

  return (
    <div>
      <div className="mg-bt-20 h-d-t-p">Site Management - Generate QR</div>

      <div className="t-m-c-i-p-g">
        <div className="f-s-c">เครื่อง</div>
        <div className="ovf-hd">
          <Select style={{ width: '100%' }}
            placeholder="เครื่อง"
            showSearch
            value={dataForGen && dataForGen.machine_id}
            onChange={value => {
              setLinkQR('')
              setDataForGen({
                ...dataForGen,
                machine_id: value,
                product_id: undefined
              })
            }}
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {machines.map((machine, idx) => <Option key={idx} value={machine.machine_id}>{machine.machine_name}</Option>)}
          </Select>
        </div>

        <div className="f-s-c">Product</div>
        <div>
          <Select style={{ width: '100%' }}
            placeholder="Product"
            disabled={!(dataForGen && dataForGen.machine_id)}
            value={dataForGen && dataForGen.product_id}
            onChange={value => setDataForGen({ ...dataForGen, product_id: value })}
          >
            {products.map((product, idx) => <Option key={idx} value={product.product_id}>{product.product_name}</Option>)}
          </Select>
        </div>
      </div>

      <div className="f-c-c mg-t-50">
        <div className="pst-rlt-h-100pc">
          {isLoading && <Loading />}
          {linkQR && <QRCode id="gen-qr" size={300} value={linkQR} includeMargin={true} />}
        </div>
      </div>

      {linkQR && <div className="f-c-c s-e-b">
        <div className="f-c-c d-b-t-n-s d-bg-c-t"
          style={{ width: '140px', pointerEvents: isLoading ? 'none' : null }}
          onClick={() => downloadQR(site, 'gen-qr')}
        >Download</div>
      </div>}

    </div>
  )
}

export default SiteManagementQR
