import React, { useState, useEffect } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { DatePicker } from "antd";
import "../Dashboard.css";

import moment from "moment";
import "moment/locale/th";

import { getSearchParams } from "../../../utils/function";
import {
  apiDBGetReportNewBranchAdmin,
  apiDBGetChartUsageNewBySiteId,
  apiDBGetChartDayNewBySiteId,
  apiDBGetUsageTimeNewBySiteId,
  apiDBGetUsageYearNewBySiteId,
} from "../../../services/apis/dashboard";

import Report from "./Report";
import ChartColumn from "../../../components/Tools/Chart/ChartColumn";
import ChartColumnStack from "../../../components/Tools/Chart/ChartColumnStack";
import ChartPie from "../../../components/Tools/Chart/ChartPie";
import TableReportMachine from "./Table/TableReportMachine";
import TableReportGroup from "./Table/TableReportGroup";
import TableShare from "./Table/TableShare";
import TableSlipUpload from "./Table/TableSlipUpload";

const { RangePicker } = DatePicker;

const DashboardNewBranchAdmin = () => {
  const { siteId } = useParams();
  const history = useHistory();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const start = params.get("start");
  const end = params.get("end");

  const role = (localStorage.getItem("user_role") || "").toUpperCase();
  const [isLoadingReport, setIsLoadingReport] = useState(false);
  const [isLoadingUsage, setIsLoadingUsage] = useState(false);
  const [isLoadingDay, setIsLoadingDay] = useState(false);
  const [isLoadingTime, setIsLoadingTime] = useState(false);
  const [isLoadingYear, setIsLoadingYear] = useState(false);

  const [chartUsage, setChartUsage] = useState(undefined);
  const [chartDay, setChartDay] = useState(undefined);
  const [chartTime, setChartTime] = useState([]);
  const [chartYear, setChartYear] = useState([]);

  const [startDate, setStartDate] = useState(
    start
      ? moment(start).startOf("day").format("YYYY-MM-DD HH:mm:ss")
      : moment().startOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [endDate, setEndDate] = useState(
    end
      ? moment(end).endOf("day").format("YYYY-MM-DD HH:mm:ss")
      : moment().endOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [report, setReport] = useState(undefined);
  const [searchButton, setSearchButton] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoadingReport(true);
      setReport(undefined);
      let report = await apiDBGetReportNewBranchAdmin(
        startDate,
        endDate,
        siteId
      );
      if (report && report.status) {
        setIsLoadingReport(false);
        setReport(report.result);
      }
      // console.log("report-apiDBGetReportBranchAdmin-🐳", report);

      setIsLoadingUsage(true);
      setChartUsage(undefined);
      let usage = await apiDBGetChartUsageNewBySiteId(
        startDate,
        endDate,
        siteId
      );
      if (usage && usage.status) {
        setIsLoadingUsage(false);
        setChartUsage(usage.result);
      }
      // console.log("usage-apiDBGetChartUsageBySiteId-🐳", usage);

      setIsLoadingDay(true);
      setChartDay(undefined);
      let day = await apiDBGetChartDayNewBySiteId(startDate, endDate, siteId);
      if (day && day.status) {
        setIsLoadingDay(false);
        setChartDay(day.result);
      }
      // console.log("day-apiDBGetChartDayBySiteId-🐳", day);

      setIsLoadingTime(true);
      setChartTime([]);
      let time = await apiDBGetUsageTimeNewBySiteId(startDate, endDate, siteId);
      if (time && time.status) {
        setIsLoadingTime(false);
        setChartTime(time.result || []);
      }
      // console.log("time-apiDBGetUsageTimeBySiteId-🐳", time);

      setIsLoadingYear(true);
      setChartYear([]);
      let year = await apiDBGetUsageYearNewBySiteId(startDate, endDate, siteId);
      if (year && year.status) {
        setIsLoadingYear(false);
        setChartYear(year.result || []);
      }
      // console.log("year-apiDBGetUsageYearBySiteId-🐳", year);
    };
    getData();
    history.push(
      `${location.pathname}${getSearchParams(
        ["start", "end"],
        [startDate, endDate]
      )}`
    );
  }, [searchButton]);

  return (
    <div>
      <div className="mg-bt-20 h-d-t-p">Dashboard - รายได้ / การใช้งาน</div>

      <div className="dash-select-date-container-grid">
        <div className="f-c-c">เลือกวันที่ :</div>
        <div>
          <RangePicker
            style={{ width: "100%" }}
            format={(date) =>
              moment(date).add(543, "years").format("D MMM YYYY HH:mm")
            }
            allowClear={false}
            showTime={{ format: "HH:mm" }}
            value={[
              moment(startDate, "YYYY-MM-DD HH:mm:ss"),
              moment(endDate, "YYYY-MM-DD HH:mm:ss"),
            ]}
            onChange={(date) => {
              setStartDate(
                moment(date[0]).startOf("minute").format("YYYY-MM-DD HH:mm:ss")
              );
              setEndDate(
                moment(date[1]).endOf("minute").format("YYYY-MM-DD HH:mm:ss")
              );
            }}
            disabledDate={(current) =>
              role === "BRANCHADMIN"
                ? current && current < moment("2021-06-25").startOf("day")
                : null
            }
          />
        </div>
        <div
          className="f-c-c d-b-t-n-s d-bg-c-t dash-select-date-search"
          onClick={() => setSearchButton(!searchButton)}
        >
          <i className="bi bi-search" style={{ lineHeight: "0" }}></i>
        </div>
      </div>

      <Report report={report} isLoading={isLoadingReport} />

      <div className="dash-duo-chart-grid">
        <ChartColumnStack
          isLoading={isLoadingUsage}
          chartReport={(chartUsage && chartUsage.usage_graph) || []}
          chartTitle="กราฟการใช้ ( บาท )"
          dataLabels={false}
          stackLabels={false}
          tooltipEnabled={true}
          digits={2}
        />
        <ChartPie
          isLoading={isLoadingUsage}
          chartReport={(chartUsage && chartUsage.graph_baht) || []}
          chartTitle="กราฟแสดงเปอร์เซนต์การใช้เครื่อง ( ครั้ง )"
        />
      </div>

      <ChartColumn
        isLoading={isLoadingDay}
        chartReport={(chartDay && chartDay.total_baht) || []}
        chartTitle="กราฟแสดงการใช้แบ่งตามวัน ( บาท )"
        nameSeries="วัน"
        dataLabels={true}
        valueData="value"
        tooltipEnabled={false}
      />

      {role === "SUPERADMIN" && (
        <ChartColumn
          isLoading={isLoadingYear}
          chartReport={chartYear}
          chartTitle="กราฟแสดงการใช้งานย้อนหลัง 1 ปี"
          nameSeries="วัน"
          dataLabels={true}
          valueData="value"
          tooltipEnabled={false}
        />
      )}

      <ChartColumnStack
        isLoading={isLoadingTime}
        chartReport={chartTime}
        chartTitle="กราฟแสดงการใช้งานหน่วยเป็นช่วงเวลา ( บาท )"
        dataLabels={false}
        stackLabels={false}
        tooltipEnabled={true}
        digits={2}
      />

      <TableReportMachine
        startDate={startDate}
        endDate={endDate}
        searchButton={searchButton}
        siteId={siteId}
      />

      <TableReportGroup
        startDate={startDate}
        endDate={endDate}
        searchButton={searchButton}
        siteId={siteId}
      />

      <TableShare
        startDate={startDate}
        endDate={endDate}
        searchButton={searchButton}
        siteId={siteId}
      />

      <TableSlipUpload siteId={siteId} />
    </div>
  );
};

export default DashboardNewBranchAdmin;
