import React, { useState, useEffect } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { Input, DatePicker, Checkbox } from "antd";
import "./Customer.css";
import { LoadingOutlined } from "@ant-design/icons";

import moment from "moment";
import "moment/locale/th";

import {
  openNotification,
  checkReqFieldWithId,
  getSearchParams,
} from "../../utils/function";
import { ADD_CUSTOMER, EDIT_CUSTOMER } from "../../resolvers/Mutation";
import { QueryGraphQLPageLimit, GET_CUSTOMERS } from "../../resolvers/Query";
import { useMutation } from "@apollo/client";

const CustomerAddEditV2 = () => {
  const { customerId } = useParams();
  const location = useLocation();
  const history = useHistory();

  const params = new URLSearchParams(location.search);
  const p = params.get("p");
  const ttl = params.get("ttl");
  const sw = params.get("sw");

  const [isLoading, setIsLoading] = useState(false);
  const [customer, setCustomer] = useState(undefined);

  const genders = ["Male", "Female", "Hermaphrodite"];

  const [isAlertName, setIsAlertName] = useState(false);
  const [isAlertTel, setIsAlertTel] = useState(false);
  const [isAlertEmail, setIsAlertEmail] = useState(false);
  const [isAlertPassword, setIsAlertPassword] = useState(false);

  const [addCustomer, dataAddCustomer] = useMutation(ADD_CUSTOMER);
  const [editCustomer, dataEditCustomer] = useMutation(EDIT_CUSTOMER);
  // const { data } = QueryGraphQL(GET_CUSTOMERS);
  const { data } = QueryGraphQLPageLimit(GET_CUSTOMERS, 1, 1000);
  const dataCustomers = data?.getCustomers;
  console.log("customer--🐁🐁", customer, dataEditCustomer, dataCustomers);
  useEffect(() => {
    if (!customer) {
      setCustomer({
        ...customer,
        customer_bday: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        customer_gender: "male",
      });
    }
  }, [customer]);

  useEffect(() => {
    if (customerId) {
      getData();
    } else {
      setCustomer(undefined);
      setIsAlertName(false);
      setIsAlertTel(false);
      setIsAlertEmail(false);
      setIsAlertPassword(false);
    }
  }, [customerId]);

  useEffect(() => {
    if (isLoading) {
      if (customerId) {
        if (dataEditCustomer?.error) {
          setIsLoading(false);
          openNotification("error", "Failed !", `Failed to edit customer.`);
        }
        if (dataEditCustomer?.data?.updateCustomer) {
          setIsLoading(false);
          openNotification("success", "Success !", `Edit customer successful.`);
          setTimeout(
            () =>
              history.push(
                `/customer/list/v2${getSearchParams(
                  ["p", "ttl", "sw"],
                  [p, ttl, sw]
                )}`
              ),
            1000
          );
        }
      } else {
        if (dataAddCustomer?.error) {
          setIsLoading(false);
          openNotification("error", "Failed !", `Failed to add new customer.`);
        }
        if (dataAddCustomer?.data?.addCustomer) {
          setIsLoading(false);
          openNotification(
            "success",
            "Success !",
            `Add new customer successful.`
          );
          setTimeout(
            () =>
              history.push(
                `/customer/list/v2${getSearchParams(
                  ["p", "ttl", "sw"],
                  [p, ttl, sw]
                )}`
              ),
            1000
          );
        }
      }
    }
  }, [dataAddCustomer, dataEditCustomer]);

  const getData = () => {
    if (dataCustomers?.length > 0) {
      dataCustomers?.map((item) => {
        let dataItem = { ...item };

        if (item?.id == customerId) {
          const dateNew = moment(dataItem.customer_bday).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          dataItem.customer_bday = dateNew;
          setCustomer(dataItem);
        }
      });
    }
  };

  const addEditData = async () => {
    let passPW = checkReqFieldWithId(
      customerId,
      customer && customer.customer_password
    );

    if (
      !(customer && customer.customer_name) ||
      !(customer && customer.customer_tel) ||
      !(customer && customer.customer_email) ||
      !passPW
    ) {
      if (!(customer && customer.customer_name)) setIsAlertName(true);
      if (!(customer && customer.customer_tel)) setIsAlertTel(true);
      if (!(customer && customer.customer_email)) setIsAlertEmail(true);
      if (!passPW) setIsAlertPassword(true);
    } else {
      setIsLoading(true);
      let data = { ...customer };
      if (customerId) {
        editCustomer({
          variables: {
            customerId: customerId ?? data?.id,
            name: data?.customer_name ?? "",
            tel: data?.customer_tel ?? "",
            email: data?.customer_email ?? "",
            bday: data?.customer_bday ?? "",
            gender: data?.customer_gender ?? "",
            username: data?.customer_username ?? "",
            password: data?.customer_password ?? "",
          },
        });
      } else {
        addCustomer({
          variables: {
            name: data?.customer_name ?? "",
            tel: data?.customer_tel ?? "",
            email: data?.customer_email ?? "",
            bday: data?.customer_bday ?? "",
            gender: data?.customer_gender ?? "",
            username: data?.customer_name ?? "",
            password: data?.customer_password ?? "",
          },
        });
      }
    }
  };

  return (
    <div>
      <div className="mg-bt-20 h-d-t-p">
        Customer - {customerId ? "Edit" : "Add"}
      </div>

      <div className="t-m-c-i-p-g">
        <div className="f-s-c">Name</div>
        <div>
          <Input
            style={{ borderColor: isAlertName ? "#EC1C24" : null }}
            placeholder="Name"
            value={(customer && customer.customer_name) || ""}
            onChange={(e) => {
              setIsAlertName(false);
              setCustomer({ ...customer, customer_name: e.target.value });
            }}
          />
        </div>

        <div className="f-s-c">Tel.</div>
        <div>
          <Input
            style={{ borderColor: isAlertTel ? "#EC1C24" : null }}
            placeholder="Tel."
            value={(customer && customer.customer_tel) || ""}
            onChange={(e) => {
              setIsAlertTel(false);
              setCustomer({
                ...customer,
                customer_tel: e.target.value.replace(/[^0-9]+/g, ""),
              });
            }}
          />
        </div>

        <div className="f-s-c">E-mail</div>
        <div>
          <Input
            style={{ borderColor: isAlertEmail ? "#EC1C24" : null }}
            placeholder="E-mail"
            value={(customer && customer.customer_email) || ""}
            onChange={(e) => {
              setIsAlertEmail(false);
              setCustomer({ ...customer, customer_email: e.target.value });
            }}
          />
        </div>

        <div className="f-s-c">Menu</div>
        <div>
          {genders.map((gender, idx) => (
            <div key={idx} className="f-s-c antd-chb-fz">
              <Checkbox
                checked={
                  customer && customer.customer_gender === gender.toLowerCase()
                }
                onChange={() =>
                  setCustomer({
                    ...customer,
                    customer_gender: gender.toLowerCase(),
                  })
                }
              >
                {gender}
              </Checkbox>
            </div>
          ))}
        </div>

        <div className="f-s-c">Birthday</div>
        <div>
          <DatePicker
            style={{ width: "100%" }}
            placeholder="Birthday"
            format={(date) =>
              moment(date).add(543, "years").format("D MMM YYYY")
            }
            allowClear={false}
            value={
              customer &&
              customer.customer_bday &&
              moment(customer.customer_bday)
            }
            onChange={(date) =>
              setCustomer({
                ...customer,
                customer_bday: date
                  .startOf("day")
                  .format("YYYY-MM-DD HH:mm:ss"),
              })
            }
            disabledDate={(current) =>
              current && current >= moment().endOf("day")
            }
          />
        </div>

        <div className="f-s-c">Password</div>
        <div>
          <Input.Password
            style={{ borderColor: isAlertPassword ? "#EC1C24" : null }}
            placeholder="Password"
            value={(customer && customer.customer_password) || ""}
            onChange={(e) => {
              setIsAlertPassword(false);
              setCustomer({ ...customer, customer_password: e.target.value });
            }}
          />
        </div>
      </div>

      <div className="f-c-c s-e-b">
        <div
          className="f-c-c d-b-t-n-s d-bg-c-t"
          style={{ width: "170px", pointerEvents: isLoading ? "none" : null }}
          onClick={() => addEditData()}
        >
          {isLoading ? (
            <LoadingOutlined />
          ) : (
            `${customerId ? "Edit" : "Add New"} Customer`
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomerAddEditV2;
