import React from "react";
import { Switch, BrowserRouter, Route, Redirect } from "react-router-dom";

import WithNav from "./WithNav";
import WithNavV2 from "./WithNav-v2";

import PublicRoute from "./PublicRoute";

import Navigation from "../components/Navigation/Navigation";
import NavigationV2 from "../components/Navigation/Navigation-v2";

import SignIn from "../pages/SignIn/SignIn";
import SignInV2 from "../pages/SignIn/SignIn-v2";

const Router = () => {
  const withNavBar = () => {
    return localStorage.getItem("version_web") === "v2" ? (
      <div>
        <NavigationV2 />
        <WithNavV2 />
      </div>
    ) : (
      <div>
        <Navigation />
        <WithNav />
      </div>
    );
  };

  return (
    <div>
      <BrowserRouter>
        <Switch>
          {localStorage.getItem("user_id") ? (
            <Switch>
              <Route component={withNavBar} />
            </Switch>
          ) : (
            <Switch>
              <PublicRoute exact path="/sign-in" component={SignIn} />
              <PublicRoute exact path="/sign-in/v2" component={SignInV2} />
              <Redirect to="/sign-in" />
            </Switch>
          )}
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default Router;
