import axios from '../../config/axios';

const apiMTNGetType = async () => {
  try {
    const result = await axios.get('/maintenance/type/dropdown')
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiMTNGetMaintainer = async () => {
  try {
    const result = await axios.get('/maintenance/maintainer/dropdown')
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiMTNGetStatus = async () => {
  try {
    const result = await axios.get('/maintenance/status/dropdown')
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiMTNGetMachineBySiteId = async (site_id) => {
  try {
    const result = await axios.get(`/maintenance/machine/dropdown?site_id=${site_id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiMTNGetTicket = async (get_all, cur_page, per_page, keyword, maintenance_type_id, machine_type_id, site_id) => {
  try {
    const result = await axios.get(`/maintenance/ticket/list?get_all=${get_all}&cur_page=${cur_page}&per_page=${per_page}&keyword=${keyword}&maintenance_type_id=${maintenance_type_id}&machine_type_id=${machine_type_id}&site_id=${site_id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiMTNGetTicketByMaintenanceId = async (maintenance_id) => {
  try {
    const result = await axios.get(`/maintenance/ticket?maintenance_id=${maintenance_id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiMTNAddTicket = async (data) => {
  try {
    const result = await axios.post('/maintenance/ticket', data)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiMTNEditTicketById = async (data) => {
  try {
    const result = await axios.put('/maintenance/ticket', data)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiMTNDeleteTicketById = async (user_id, maintenance_id) => {
  try {
    const result = await axios.delete(`/maintenance/ticket?admin_id=${user_id}&maintenance_id=${maintenance_id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiMTNGetHistory = async (get_all, start_date, end_date, cur_page, per_page, keyword, maintenance_type_id, machine_type_id, site_id) => {
  try {
    const result = await axios.get(`/maintenance/history?get_all=${get_all}&start_date=${start_date}&end_date=${end_date}&cur_page=${cur_page}&per_page=${per_page}&keyword=${keyword}&maintenance_type_id=${maintenance_type_id}&machine_type_id=${machine_type_id}&site_id=${site_id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiMTNGetSupportOffline = async (get_all, cur_page, per_page, keyword, machine_type_id, site_id) => {
  try {
    const result = await axios.get(`/maintenance/offline?get_all=${get_all}&cur_page=${cur_page}&per_page=${per_page}&keyword=${keyword}&machine_type_id=${machine_type_id}&site_id=${site_id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

export {
  apiMTNGetType,
  apiMTNGetMaintainer,
  apiMTNGetStatus,
  apiMTNGetMachineBySiteId,
  apiMTNGetTicket,
  apiMTNGetTicketByMaintenanceId,
  apiMTNAddTicket,
  apiMTNEditTicketById,
  apiMTNDeleteTicketById,
  apiMTNGetHistory,
  apiMTNGetSupportOffline,
}