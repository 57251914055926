import { createAction, createReducer } from '@reduxjs/toolkit';

const initialState = {
  hideNav: Boolean(localStorage.getItem('hnv')), // hide navbar
}

export const changehideNav = createAction('CHANGE_NAV_DISPLAY');

export default createReducer(initialState, {
  [changehideNav]: (state, action) => {
    state.hideNav ? localStorage.removeItem('hnv') : localStorage.setItem('hnv', !state.hideNav)
    state.hideNav = !state.hideNav
    return state;
  },
});
