import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import { Table, Input, Pagination, Tooltip, Modal } from 'antd';
import './SitePromotion.css'
import XLSX from "xlsx";
import { LoadingOutlined } from '@ant-design/icons';

import moment from 'moment';
import 'moment/locale/th'

import { numberWithCommas, openNotification, getSearchParams } from '../../utils/function'
import { apiSGetSitePromotion, apiSDeleteSitePromotion } from '../../services/apis/site'
import { getListCollectSite, UpdateCollectActive } from '../../services/apis/tw-promotion'

const SitePromotionCollectListPage = () => {
    const history = useHistory();
    const location = useLocation();

    const params = new URLSearchParams(location.search)
    const p = params.get('p')
    const ttl = params.get('ttl')
    const sw = params.get('sw')

    const [isLoading, setIsLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(Number(p) || 1)
    const [perPage, setPerPage] = useState(Number(ttl) || 20)
    const [tableData, setTableData] = useState(undefined)
    const [searchWord, setSearchWord] = useState(sw || '')
    const [searchButton, setSearchButton] = useState(false)
    const [waitingForCSV, setWaitingForCSV] = useState(false)

    const [visible, setVisible] = useState(false)
    const [modalId, setModalId] = useState(undefined)
    const [isLoadingConfirm, setIsLoadingConfirm] = useState(false)

    useEffect(() => {
        getTableData()
        // history.push(`${location.pathname}${getSearchParams(['p', 'ttl', 'sw'], [currentPage, perPage, searchWord])}`)
    }, [currentPage, perPage, searchButton])

    const getTableData = async () => {
        // setIsLoading(true)
        let Collect = await getListCollectSite()
        console.log(Collect)
        if (Collect) {

            //setIsLoading(false)
            setTableData(Collect)
        }
    }

    const columns = [
        {
            title: '#',
            ellipsis: true,
            align: 'center',
            width: '5%',
            render: (data, record, index) => <div>{(index + 1) + ((currentPage - 1) * perPage)}</div>
        },
        {
            title: 'สาขา',
            ellipsis: true,
            render: (data) => <div>{data.site_name || '-'}</div>
        },
       {
            title: 'ซักครบ',
            ellipsis: true,
            align: 'center',
            render: (data) => <div>{data.Collect_count_machine || '-'}</div>
        },
        {
            title: 'คืนเครดิต',
            ellipsis: true,
            align: 'center',
            render: (data) => <div>{numberWithCommas(data.CollectSet_money || 0, 0)}</div>
        },
        {
            title: 'คืนเครดิตทั้งหมด',
            ellipsis: true,
            align: 'center',
            render: (data) => <div>{data.Collect_count_returnCredit || '-'}</div>
        },
        {
            title: 'จำนวนที่ตั้ง',
            ellipsis: true,
            align: 'center',
            render: (data) => <div>{data.Collect_count || '-'}</div>
        },
        {
            title: 'วันที่เริ่ม',
            ellipsis: true,
            align: 'center',
            render: (data) => <div>{data.Collect_start_date ? moment(data.Collect_start_date).add(543, 'years').format('D MMM YYYY HH:mm:ss') : '-'}</div>
        },
        {
            title: 'วันที่สิ้นสุด',
            ellipsis: true,
            align: 'center',
            render: (data) => <div>{data.Collect_end_date ? moment(data.Collect_end_date).add(543, 'years').format('D MMM YYYY HH:mm:ss') : '-'}</div>
        },

        {
            title: 'Manage',
            ellipsis: true,
            align: 'center',
            fixed: 'right',
            // render: (data) => <div className="f-c-c tb-cl-a">
            //   <Tooltip placement="top" title="Edit">
            //     <div className="f-c-c d-b-t-n-s b-c-o-i" style={{ backgroundColor: '#00ADEF' }}
            //     // onClick={() => window.open(`/machine/type/${typeId}/sub-type/edit/${data.machine_sub_type_id}`, '_blank')}
            //     ><i className="bi bi-pencil-fill"></i></div>
            //   </Tooltip>
            //   <Tooltip placement="top" title="Delete">
            //     <div className="f-c-c d-b-t-n-s b-c-o-i" style={{ backgroundColor: '#ED2228' }}><i className="fas fa-trash"></i></div>
            //   </Tooltip>
            // </div>
            render: (data) => {
                if (data.Active) {
                    return <Tooltip placement="top" title="Delete">
                        <div className="f-c-c d-b-t-n-s b-c-o-i" style={{ backgroundColor: '#ED2228' }}
                            onClick={(e) => {
                                e.stopPropagation()
                                setModalId(data._id)
                                setVisible(true)
                            }}
                        ><i className="fas fa-trash"></i></div>
                    </Tooltip>;
                } else {
                    return <div>Not Active</div>;
                }
            }

        },
    ];

    const deleteData = async () => {
        if (!modalId) {
            openNotification('error', 'Failed !', 'Failed to delete site promotion.')
            return
        }
        let data = {
            id: modalId,
            Active: false
        }
        setIsLoadingConfirm(true)
        let action = await UpdateCollectActive(data)
        if (action) {
            openNotification('success', 'Success !', 'Delete site promotion successful.')
            setVisible(false)
            setIsLoadingConfirm(false)
            getTableData()
        } else {
            openNotification('error', 'Failed !', 'Failed to delete site promotion.')
            setIsLoadingConfirm(false)
        }
    }

    const toCSVFile = async () => {
        setWaitingForCSV(true)
        let data = await getListCollectSite()
        let csvData = [];

        if (data) {
            (data || []).map((data, idx) => {
                let resultRow = {}
                resultRow['#'] = idx + 1
                resultRow['Site Name'] = data.site_code || '-'
                resultRow['machine use promotion'] = data.machine_use_promotion || '-'
                resultRow['Return money'] = data.CollectSet_money || 0
                resultRow['Count machine'] = data.Collect_count_machine || '-'
                resultRow['Count'] = data.Collect_count || '-'
                resultRow['Start Date'] = data.Collect_start_date || '-'
                resultRow['end date'] = data.Collect_end_date || '-'
                csvData.push(resultRow);
            })

            const toCSV = XLSX.utils.json_to_sheet(csvData);
            const wb = XLSX.utils.book_new();

            const fileName = 'PROMOTION_LIST'
            XLSX.utils.book_append_sheet(wb, toCSV, fileName);
            XLSX.writeFile(wb, fileName + ".csv");

            setWaitingForCSV(false)
        }
    }

    return (
        <div>
            <div className="mg-bt-20 h-d-t-p">Collect Promotion List</div>

            <div className="pro-reward-header-grid pro-reward-header-grid-column-true">
                <div className="pro-reward-header-grid-search-true">
                    <Input
                        placeholder="search .."
                        value={searchWord}
                        onChange={e => setSearchWord(e.target.value)}
                        onPressEnter={() => {
                            setCurrentPage(1)
                            setSearchButton(!searchButton)
                        }}
                    />
                </div>
                <div className="f-c-c d-b-t-n-s d-bg-c-t"
                    onClick={() => {
                        setCurrentPage(1)
                        setSearchButton(!searchButton)
                    }}>
                    <i className="bi bi-search" style={{ lineHeight: '0' }}></i>
                </div>
                <div className="f-c-c d-b-t-n-s d-bg-c-t h-32 pro-reward-header-grid-export-true"
                    style={{ pointerEvents: waitingForCSV ? 'none' : null }}
                    onClick={() => toCSVFile()}
                >
                    {waitingForCSV ? <LoadingOutlined /> : 'Export CSV'}
                </div>
            </div>

            <Table bordered
                size="small"
                loading={isLoading}
                rowKey={record => record._id}
                dataSource={tableData || []}
                columns={columns}
                pagination={false}
                scroll={{ x: true }}
            />

            {/* <div className="pgnt-st">
        <Pagination
          size="small"
          current={currentPage}
          onChange={page => setCurrentPage(page)}
          total={tableData && tableData.max_total_rows}
          pageSize={perPage}
          showSizeChanger={false}
        />
      </div> */}

            <Modal
                title=""
                visible={visible}
                closable={false}
                footer={false}
                centered
            >
                <div className="m-t-i-c" style={{ color: '#FFCB05' }}>
                    <i className="bi bi-exclamation-triangle-fill"></i>
                </div>
                <div className="m-t-t-q">Do you want to delete ?</div>
                <div className="m-b-y-n-g">
                    <div className="f-c-c d-b-t-n-s b-c-o-i" style={{ backgroundColor: '#cccccc' }} onClick={() => setVisible(false)}>Cancel</div>
                    <div className="f-c-c d-b-t-n-s b-c-o-i"
                        style={{ backgroundColor: '#3f4d67', pointerEvents: isLoadingConfirm ? 'none' : null }}
                        onClick={() => deleteData()}
                    >{isLoadingConfirm ? <LoadingOutlined /> : 'Confirm'}</div>
                </div>
            </Modal>

        </div>
    )
}

export default SitePromotionCollectListPage
