import React, { useState, useEffect } from "react";
import "./SignIn.css";

import { openNotification, logOut } from "../../utils/function";
import { LOGIN_ADMIN } from "../../resolvers/Mutation";
import { useMutation } from "@apollo/client";

const SignIn = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginAdmin, dataLoginAdmin] = useMutation(LOGIN_ADMIN);

 // console.log("dataLoginAdmin-🐙🐙->", dataLoginAdmin);

  useEffect(() => {
    if (dataLoginAdmin?.error) {
      openNotification(
        "error",
        "Failed !",
        "Username or password is incorrect."
      );
      logOut();
    } else if (dataLoginAdmin?.data?.login) {
      const login = { ...dataLoginAdmin?.data?.login };
      localStorage.setItem("version_web", "v2");
      localStorage.setItem("user_id", login.id);
      localStorage.setItem("user_name", login.admin_name);
      localStorage.setItem("user_role", "branchAdmin");
      // localStorage.setItem("user_role", login.__typename);
      localStorage.setItem("site_id", login.site_id);
      localStorage.setItem("list", login.menu_list || []);
      // console.log("login00🤟🤟", login);
      window.location.href = "/";

      // localStorage.setItem("user_id", 212);
      // localStorage.setItem("user_name", "Peerawat Mix");
      // localStorage.setItem("user_role", "superAdmin");
      // localStorage.setItem("site_id", 362);
      // localStorage.setItem("list", menu_list);
    }
  }, [dataLoginAdmin]);

  const login = async () => {
    loginAdmin({
      variables: {
        username: username ?? "",
        password: password ?? "",
      },
    });
  };

  return (
    <div className="f-c-c sign-in-container">
      <div className="sign-in-container-box">
        <div className="f-c-c sign-in-logo">
          <img src="/assets/image/logo/logo_fullname_description.png" alt="" />
        </div>

        <div className="mg-t-40">
          <div className="pst-rlt">
            <div className="f-c-c sign-in-input-icon">
              <i className="fas fa-user"></i>
            </div>
            <input
              className="sign-in-input"
              placeholder="Username"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              onKeyPress={(e) =>
                e.key.toUpperCase() === "ENTER" ? login() : null
              }
            />
          </div>

          <div className="pst-rlt mg-t-30">
            <div className="f-c-c sign-in-input-icon">
              <i className="fas fa-lock"></i>
            </div>
            <input
              className="sign-in-input"
              placeholder="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyPress={(e) =>
                e.key.toUpperCase() === "ENTER" ? login() : null
              }
            />
          </div>

          <div className="mg-t-30">
            <div className="f-c-c sign-in-button" onClick={() => login()}>
              Sign In
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
