import React, { useState, useEffect } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Input, InputNumber, Select, DatePicker, Avatar, Modal } from "antd";
import "./SiteManagement.css";
import { LoadingOutlined } from "@ant-design/icons";

import moment from "moment";
import "moment/locale/th";

import { locationLatLng } from "../../redux/place";
import {
  checkImageSize,
  openNotification,
  checkReqFieldWithId,
  getSearchParams,
} from "../../utils/function";
import { apiAMGetAdmin } from "../../services/apis/admin";
import {
  apiSGetSiteManagementGroups,
  apiSAddSiteManagement,
  apiSGetSiteManagementById,
  apiSEditSiteManagement,
} from "../../services/apis/site";
import { apiARGetProvince } from "../../services/apis/area";

import SiteManagementMaps from "./SiteManagementMaps";

const { Option } = Select;
const { RangePicker } = DatePicker;

const SiteManagementAddEdit = () => {
  const dispatch = useDispatch();
  const place = useSelector((state) => state.place);
  const { siteId } = useParams();
  const location = useLocation();
  const history = useHistory();

  const params = new URLSearchParams(location.search);
  const p = params.get("p");
  const ttl = params.get("ttl");
  const sw = params.get("sw");

  const [isLoading, setIsLoading] = useState(false);
  const [site, setSite] = useState(undefined);
  const [admins, setAdmins] = useState([]);
  const [branchGroups, setBranchGroups] = useState([]);
  const [provinces, setProvinces] = useState([]);

  const [modalImageIdx, setModalImageIdx] = useState(0);
  const [visible, setVisible] = useState(false);

  const [isAlertNameTH, setIsAlertNameTH] = useState(false);
  const [isAlertNameEN, setIsAlertNameEN] = useState(false);
  const [isAlertAdmin, setIsAlertAdmin] = useState(false);
  const [isAlertPayAmount, setIsAlertPayAmount] = useState(false);
  const [isAlertProvince, setIsAlertProvince] = useState(false);
  const [isAlertImage, setIsAlertImage] = useState(false);

  useEffect(() => {
    if (siteId) getData();
    else {
      dispatch(locationLatLng({ lat: 0, lng: 0 }));
      setSite(undefined);
      setIsAlertNameTH(false);
      setIsAlertNameEN(false);
      setIsAlertAdmin(false);
      setIsAlertPayAmount(false);
      setIsAlertProvince(false);
      setIsAlertImage(false);
    }
  }, [siteId]);

  useEffect(() => {
    if (!site) {
      setSite({
        ...site,
        images: [],
        type_pay: "RENT",
        site_type: "FRANCHISE",
        site_sub_type: "CONDO",
        zone: 1,
        machine_group_status: 0,
      });
    }
  }, [site]);

  const getData = async () => {
    let data = await apiSGetSiteManagementById(siteId);
    if (data && data.status) {
      let newData = { ...data.result };
      newData.images = (data.result.site_image || []).map((image) => {
        return {
          site_image: image,
          site_image_file: undefined,
        };
      });

      dispatch(
        locationLatLng({
          lat: Number(newData.machine_group_lat),
          lng: Number(newData.machine_group_long),
        })
      );
      setSite(newData);
    }
  };

  useEffect(() => {
    const getStaticDropdown = async () => {
      let admin = await apiAMGetAdmin();
      if (admin && admin.status) setAdmins(admin.result || []);

      let group = await apiSGetSiteManagementGroups();
      if (group && group.status) setBranchGroups(group.result || []);

      let province = await apiARGetProvince();
      if (province && province.status) setProvinces(province.result || []);
    };
    getStaticDropdown();
  }, []);

  const addEditSiteManagement = async () => {
    let passIMF = checkReqFieldWithId(
      siteId,
      site && site.machine_group_icon_file
    );

    if (
      !(site && site.site_name_th) ||
      !(site && site.site_name_en) ||
      !(site && site.admin_list && site.admin_list.length) ||
      isNaN(site && site.pay_amount) ||
      !(site && site.province_id) ||
      !passIMF
    ) {
      if (!(site && site.site_name_th)) setIsAlertNameTH(true);
      if (!(site && site.site_name_en)) setIsAlertNameEN(true);
      if (!(site && site.admin_list && site.admin_list.length))
        setIsAlertAdmin(true);
      if (isNaN(site && site.pay_amount)) setIsAlertPayAmount(true);
      if (!(site && site.province_id)) setIsAlertProvince(true);
      if (!passIMF) setIsAlertImage(true);
    } else {
      setIsLoading(true);

      let data = new FormData();
      if (siteId) data.append("site_id", siteId);
      if (site.machine_group_icon_file)
        data.append("machine_group_icon", site.machine_group_icon_file);
      data.append("site_name_th", site.site_name_th);
      data.append("site_name_en", site.site_name_en);
      data.append("admin_list", site.admin_list.join(","));
      data.append("type_pay", site.type_pay);
      data.append("pay_amount", site.pay_amount);
      data.append("site_type", site.site_type);
      data.append("site_sub_type", site.site_sub_type);
      data.append("group_site_id", site.group_site_id);
      data.append("unit_size", site.unit_size || 0);
      data.append("province_id", site.province_id);
      data.append("zone", site.zone);
      data.append("water_type", site.water_type || "");
      data.append("water_amount", site.water_amount || 0);
      data.append("elec_type", site.elec_type || "");
      data.append("elec_amount", site.elec_amount || 0);
      data.append("contact_start", site.contact_start);
      data.append("contact_end", site.contact_end);

      site.images.map((image) => {
        if (image.site_image_file)
          data.append("site_image", image.site_image_file);
      });

      data.append("machine_group_lat", place.lat);
      data.append("machine_group_long", place.lng);
      data.append("machine_group_status", site.machine_group_status);
      data.append("comment", site.comment);

      let newData = siteId
        ? await apiSEditSiteManagement(data)
        : await apiSAddSiteManagement(data);
      if (newData && newData.status) {
        setIsLoading(false);
        openNotification(
          "success",
          "Success !",
          `${siteId ? "Edit" : "Add new"} site successful.`
        );
        setTimeout(
          () =>
            history.push(
              `/site-management/list${getSearchParams(
                ["p", "ttl", "sw"],
                [p, ttl, sw]
              )}`
            ),
          1000
        );
      } else {
        setIsLoading(false);
        openNotification(
          "error",
          "Failed !",
          `Failed to ${siteId ? "edit" : "add new"} site.`
        );
      }
    }
  };

  return (
    <div>
      <div className="mg-bt-20 h-d-t-p">
        Site Management - {siteId ? "Edit" : "Add"}
      </div>

      <div className="t-m-c-i-p-g">
        <div className="f-s-c">ชื่อสาขา ( ไทย )</div>
        <div>
          <Input
            style={{ borderColor: isAlertNameTH ? "#EC1C24" : null }}
            placeholder="ชื่อสาขา ( ไทย )"
            value={(site && site.site_name_th) || ""}
            onChange={(e) => {
              setIsAlertNameTH(false);
              setSite({ ...site, site_name_th: e.target.value });
            }}
          />
        </div>

        <div className="f-s-c">ชื่อสาขา ( English )</div>
        <div>
          <Input
            style={{ borderColor: isAlertNameEN ? "#EC1C24" : null }}
            placeholder="ชื่อสาขา ( English )"
            value={(site && site.site_name_en) || ""}
            onChange={(e) => {
              setIsAlertNameEN(false);
              setSite({ ...site, site_name_en: e.target.value });
            }}
          />
        </div>

        <div className="f-s-c">ผู้ดูแลสถานที่</div>
        <div className={(isAlertAdmin && "antd-sl-al-bd") || ""}>
          <Select
            style={{ width: "100%" }}
            placeholder="ผู้ดูแลสถานที่"
            mode="multiple"
            showArrow
            value={(site && site.admin_list) || []}
            onChange={(value) => {
              setIsAlertAdmin(false);
              setSite({ ...site, admin_list: value });
            }}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {admins.map((admin, idx) => (
              <Option key={idx} value={admin.admin_id}>
                {admin.admin_name}
              </Option>
            ))}
          </Select>
        </div>

        <div className="f-s-c">การเก็บค่าเช่า</div>
        <div>
          <Select
            style={{ width: "100%" }}
            placeholder="การเก็บค่าเช่า"
            value={(site && site.type_pay) || undefined}
            onChange={(value) => setSite({ ...site, type_pay: value })}
          >
            <Option value="RENT">THB</Option>
            <Option value="PERCENT">%</Option>
          </Select>
        </div>

        <div className="f-s-c">จำนวน ( THB or % )</div>
        <div>
          <InputNumber
            min={0}
            step={0.01}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            style={{
              width: "100%",
              borderColor: isAlertPayAmount ? "#EC1C24" : null,
            }}
            placeholder="จำนวน ( THB or % )"
            value={site && site.pay_amount}
            onChange={(value) => {
              setIsAlertPayAmount(false);
              setSite({
                ...site,
                pay_amount: Math.round(value * 100) / 100 || 0,
              });
            }}
          />
        </div>

        <div className="f-s-c">ประเภทสาขา</div>
        <div>
          <Select
            style={{ width: "100%" }}
            placeholder="ประเภทสาขา"
            value={(site && site.site_type) || undefined}
            onChange={(value) => setSite({ ...site, site_type: value })}
          >
            <Option value="FRANCHISE">Franchise</Option>
            <Option value="OWNER">Owner</Option>
            <Option value="RENT">Rent</Option>
            <Option value="CLOUDFUND">Cloud Fund</Option>
          </Select>
        </div>

        <div className="f-s-c">ประเภทสาขาย่อย</div>
        <div>
          <Select
            style={{ width: "100%" }}
            placeholder="ประเภทสาขาย่อย"
            value={(site && site.site_sub_type) || undefined}
            onChange={(value) => setSite({ ...site, site_sub_type: value })}
          >
            <Option value="CONDO">Condo</Option>
            <Option value="STANDALONE">Standalone</Option>
          </Select>
        </div>

        <div className="f-s-c">กลุ่มสาขา</div>
        <div>
          <Select
            style={{ width: "100%" }}
            placeholder="กลุ่มสาขา"
            value={site && site.group_site_id}
            onChange={(value) => setSite({ ...site, group_site_id: value })}
          >
            {branchGroups.map((group, idx) => (
              <Option key={idx} value={group.group_site_id}>
                {group.group_site_name}
              </Option>
            ))}
          </Select>
        </div>

        <div className="f-s-c">จำนวนยูนิต</div>
        <div>
          <InputNumber
            min={0}
            step={0.01}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            style={{ width: "100%" }}
            placeholder="จำนวนยูนิต"
            value={site && site.unit_size}
            onChange={(value) =>
              setSite({
                ...site,
                unit_size: Math.round(value * 100) / 100 || 0,
              })
            }
          />
        </div>

        <div className="f-s-c">จังหวัด</div>
        <div className={(isAlertProvince && "antd-sl-al-bd") || ""}>
          <Select
            style={{ width: "100%" }}
            placeholder="จังหวัด"
            showSearch
            value={site && site.province_id}
            onChange={(value) => {
              setIsAlertProvince(false);
              setSite({ ...site, province_id: value });
            }}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {provinces.map((province, idx) => (
              <Option key={idx} value={province.province_id}>
                {province.province_name}
              </Option>
            ))}
          </Select>
        </div>

        <div className="f-s-c">เขตพื้นที่</div>
        <div>
          <Select
            style={{ width: "100%" }}
            placeholder="เขตพื้นที่"
            value={(site && site.zone) || undefined}
            onChange={(value) => setSite({ ...site, zone: value })}
          >
            <Option value={1}>1</Option>
            <Option value={2}>2</Option>
            <Option value={3}>3</Option>
            <Option value={4}>4</Option>
            <Option value={5}>5</Option>
            <Option value={6}>6</Option>
          </Select>
        </div>

        <div className="f-s-c">ค่าน้ำ</div>
        <div className="site-manage-get-more-grid">
          <div>
            <Select
              style={{ width: "100%" }}
              placeholder="ค่าน้ำ"
              value={(site && site.water_type) || undefined}
              onChange={(value) => setSite({ ...site, water_type: value })}
            >
              <Option value="BAHT">THB</Option>
              <Option value="PERUNIT">Per Unit</Option>
            </Select>
          </div>
          <div>
            <InputNumber
              min={0}
              step={0.01}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              style={{ width: "100%" }}
              placeholder="ค่าน้ำ"
              value={site && site.water_amount}
              onChange={(value) =>
                setSite({
                  ...site,
                  water_amount: Math.round(value * 100) / 100 || 0,
                })
              }
            />
          </div>
        </div>

        <div className="f-s-c">ค่าไฟ</div>
        <div className="site-manage-get-more-grid">
          <div>
            <Select
              style={{ width: "100%" }}
              placeholder="ค่าไฟ"
              value={(site && site.elec_type) || undefined}
              onChange={(value) => setSite({ ...site, elec_type: value })}
            >
              <Option value="BAHT">THB</Option>
              <Option value="PERUNIT">Per Unit</Option>
            </Select>
          </div>
          <div>
            <InputNumber
              min={0}
              step={0.01}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              style={{ width: "100%" }}
              placeholder="ค่าไฟ"
              value={site && site.elec_amount}
              onChange={(value) =>
                setSite({
                  ...site,
                  elec_amount: Math.round(value * 100) / 100 || 0,
                })
              }
            />
          </div>
        </div>

        <div className="f-s-c">สัญญา</div>
        <div>
          <RangePicker
            style={{ width: "100%" }}
            placeholder={["วันเริ่มต้น", "วันสิ้นสุด"]}
            format={(date) =>
              moment(date).add(543, "years").format("D MMM YYYY")
            }
            allowClear={false}
            value={[
              (site &&
                site.contact_start &&
                site.contact_start !== "0000-00-00 00:00:00" &&
                moment(site.contact_start, "YYYY-MM-DD HH:mm:ss")) ||
                undefined,
              (site &&
                site.contact_end &&
                site.contact_end !== "0000-00-00 00:00:00" &&
                moment(site.contact_end, "YYYY-MM-DD HH:mm:ss")) ||
                undefined,
            ]}
            onChange={(date) =>
              setSite({
                ...site,
                contact_start: moment(date[0]).format("YYYY-MM-DD HH:mm:ss"),
                contact_end: moment(date[1]).format("YYYY-MM-DD HH:mm:ss"),
              })
            }
          />
        </div>

        <div className="f-s-c">ภาพสถานที่ (ขนาด 40*40)</div>
        <div>
          <input
            id="site-manage-place-image"
            accept="image/*"
            type="file"
            style={{ display: "none" }}
            onChange={(e) => {
              setIsAlertImage(false);
              let passSize = checkImageSize(true, e.target.files[0]);
              if (passSize) {
                const reader = new FileReader();
                reader.readAsDataURL(e.target.files[0]);
                reader.onload = () =>
                  setSite({
                    ...site,
                    machine_group_icon: reader.result,
                    machine_group_icon_file: e.target.files[0],
                  });
              }
            }}
          />
          <label htmlFor="site-manage-place-image">
            <div
              className={`antd-avt-al ${
                (isAlertImage && "antd-avt-al-bd") || ""
              }`}
            >
              <Avatar
                shape="square"
                size={110}
                className="cs-pt"
                src={(site && site.machine_group_icon) || ""}
                icon={<i className="bi bi-image"></i>}
              />
            </div>
          </label>
        </div>

        <div className="f-s-c">ภาพประกอบ</div>
        <div className="dpl-im-l-a-ip-f">
          {((site && site.images) || []).map((image, idx) => (
            <div
              key={idx}
              className="pst-rlt"
              onClick={() => {
                setVisible(true);
                setModalImageIdx(idx);
              }}
            >
              {image.site_image_file && (
                <div
                  className="f-c-c dpl-im-l-dl-ic"
                  onClick={(e) => {
                    e.stopPropagation();
                    let newSite = { ...site };
                    newSite.images = site.images.filter(
                      (img) => img.site_image !== image.site_image
                    );
                    setSite(newSite);
                  }}
                >
                  <i className="bi bi-x"></i>
                </div>
              )}
              <Avatar
                shape="square"
                size={110}
                className="cs-pt"
                src={image.site_image || ""}
              />
            </div>
          ))}
          <div>
            <input
              id="site-manage-illustration-image"
              accept="image/*"
              type="file"
              multiple
              style={{ display: "none" }}
              onChange={(e) => {
                let files = [...e.target.files];
                let passSize = files.map((file) => {
                  return { file, canUse: checkImageSize(false, file) };
                });
                let found = passSize.find((element) => !element.canUse);
                if (found)
                  openNotification(
                    "warning",
                    "ไม่สามารถอัพโหลดบางภาพได้",
                    "รูปภาพที่มีขนาดเกิน 2MB จะไม่ถูกอัพโหลด"
                  );

                passSize.map((image) => {
                  if (image.canUse) {
                    const reader = new FileReader();
                    reader.readAsDataURL(image.file);
                    reader.onload = () => {
                      let newSite = { ...site };
                      newSite.images.push({
                        site_image: reader.result,
                        site_image_file: image.file,
                      });
                      setSite(newSite);
                    };
                  }
                });
              }}
            />
            <label htmlFor="site-manage-illustration-image">
              <div>
                <Avatar
                  shape="square"
                  size={110}
                  className="cs-pt"
                  icon={<i className="bi bi-image"></i>}
                />
              </div>
            </label>
          </div>
        </div>

        <div className="f-s-c">แผนที่</div>
        <SiteManagementMaps />

        <div className="f-s-c">สถานะ</div>
        <div>
          <Select
            style={{ width: "100%" }}
            placeholder="สถานะ"
            value={(site && site.machine_group_status) || undefined}
            onChange={(value) =>
              setSite({ ...site, machine_group_status: value })
            }
          >
            <Option value={0}>แสดง</Option>
            <Option value={-1}>ไม่แสดง</Option>
          </Select>
        </div>

        <div className="f-s-c">Comment</div>
        <div>
          <Input.TextArea
            autoSize={{ minRows: 3 }}
            placeholder="Comment"
            value={(site && site.comment) || ""}
            onChange={(e) => setSite({ ...site, comment: e.target.value })}
          />
        </div>
      </div>

      <div className="f-c-c s-e-b">
        <div
          className="f-c-c d-b-t-n-s d-bg-c-t"
          style={{ width: "160px", pointerEvents: isLoading ? "none" : null }}
          onClick={() => addEditSiteManagement()}
        >
          {isLoading ? (
            <LoadingOutlined />
          ) : (
            `${siteId ? "Edit" : "Add New"} Site`
          )}
        </div>
      </div>

      <Modal
        title=""
        visible={visible}
        closable={false}
        footer={false}
        centered
      >
        <div className="md-ctn-crs-im-l">
          <div
            className="f-c-c md-ctn-crs-im-ar"
            onClick={() =>
              modalImageIdx > 0 ? setModalImageIdx(modalImageIdx - 1) : null
            }
          >
            <i
              className="fas fa-chevron-left"
              style={
                modalImageIdx > 0
                  ? null
                  : { opacity: "0.7", cursor: "not-allowed" }
              }
            ></i>
          </div>
          <div className="f-c-c md-ctn-crs-im">
            <img
              src={
                site &&
                site.images &&
                site.images.length > 0 &&
                site.images[modalImageIdx] &&
                site.images[modalImageIdx].site_image
              }
            />
          </div>
          <div
            className="f-c-c md-ctn-crs-im-ar"
            onClick={() =>
              modalImageIdx < (site && site.images && site.images.length) - 1
                ? setModalImageIdx(modalImageIdx + 1)
                : null
            }
          >
            <i
              className="fas fa-chevron-right"
              style={
                modalImageIdx < (site && site.images && site.images.length) - 1
                  ? null
                  : { opacity: "0.7", cursor: "not-allowed" }
              }
            ></i>
          </div>
        </div>

        <div
          className="f-c-c d-b-t-n-s d-bg-c-t md-btn-ol-cc"
          onClick={() => setVisible(false)}
        >
          Cancel
        </div>
      </Modal>
    </div>
  );
};

export default SiteManagementAddEdit;
