import axios from '../../config/axios';

const apiBNGetHomeBanner = async (get_all, cur_page, per_page, keyword) => {
  try {
    const result = await axios.get(`/banner/home/list?get_all=${get_all}&cur_page=${cur_page}&per_page=${per_page}&keyword=${keyword}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiBNGetSiteBanner = async (get_all, cur_page, per_page, keyword, site_id) => {
  try {
    const result = await axios.get(`/banner/site/list?get_all=${get_all}&cur_page=${cur_page}&per_page=${per_page}&keyword=${keyword}&site_id=${site_id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiBNAddBanner = async (data) => {
  try {
    const result = await axios.post('/banner', data)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiBNGetBannerById = async (banner_id) => {
  try {
    const result = await axios.get(`/banner?banner_id=${banner_id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiBNEditBannerById = async (data) => {
  try {
    const result = await axios.put('/banner', data)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiBNDeleteBannerById = async (banner_id) => {
  try {
    const result = await axios.delete(`/banner?banner_id=${banner_id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

export {
  apiBNGetHomeBanner,
  apiBNGetSiteBanner,
  apiBNAddBanner,
  apiBNGetBannerById,
  apiBNEditBannerById,
  apiBNDeleteBannerById,
}