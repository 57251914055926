import axios from '../../config/axios';

const apiWGetWasher = async (get_all, cur_page, per_page, keyword) => {
  try {
    const result = await axios.get(`/washer/user/list?get_all=${get_all}&cur_page=${cur_page}&per_page=${per_page}&keyword=${keyword}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}


const apiWSetWasher = async (data) => {
    try {
      const result = await axios.put(`/washer/user`,data)
      return result && result.data
    } catch (error) {
      return error && error.response && error.response.data
    }
  }

  const apiWGetTaskWasher = async (get_all, cur_page, per_page, keyword) => {
    try {
      const result = await axios.get(`/washer/transaction/pending?cur_page=${cur_page}&per_page=${per_page}&keyword=${keyword}&get_all=${get_all}`)
      return result && result.data
    } catch (error) {
      return error && error.response && error.response.data
    }
  }

  const apiWUpdateTask = async (transaction_id, status_id, admin_id) => {
    try {
      const result = await axios.put(`/washer/transaction/status?transaction_id=${transaction_id}&status_id=${status_id}&admin_id=${admin_id}`)
      return result && result.data
    } catch (error) {
      return error && error.response && error.response.data
    }
  }



export {
    apiWGetWasher,
    apiWSetWasher,
    apiWGetTaskWasher,
    apiWUpdateTask
}