import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom';
import { InputNumber, Select, DatePicker, TimePicker, Checkbox } from 'antd';
import './SitePromotion.css'
import { LoadingOutlined } from '@ant-design/icons';

import moment from 'moment';
import 'moment/locale/th'

import { checkIfIdEqualToZero, openNotification } from '../../utils/function'
import { siteall,  addCreatePromocode } from '../../services/apis/tw-promotion'
const role = (localStorage.getItem("user_role") || "").toUpperCase();
const { Option } = Select;
const { RangePicker } = DatePicker;

const CreatePromocode = () => {
    const history = useHistory();
    const { siteId } = useParams();
    const [isLoading, setIsLoading] = useState(false)
    const [sites, setSites] = useState([])
    const [machineTypes, setMachineTypes] = useState([])
    const [sitePromotion, setSitePromotion] = useState(undefined)
    const [Promocode, setPromocode] = useState(undefined)
    console.log(Promocode)
    const [selectAllMachine, setSelectAllMachine] = useState(false)
    const [Alertsite_id, setIsAlertsite_id] = useState(false)
    const [AlertSiteName, setIsAlertSiteName] = useState(false)
    const [machine_type, setIsAlertmachine_type] = useState(false)
    const [Alertmachine_use_promotion, setIsAlertmachine_use_promotion] = useState(false)
    const [AlertCollect_count, setIsAlertCollect_count] = useState(false)
    const [AlertCollect_money, setIsAlertCollect_money] = useState(false)
    const [isAlertLimit, setIsAlertLimit] = useState(false)

    useEffect(() => {
    }, [])
    useEffect(() => {
    }, [])

    const addEditPromotion = async () => {
        console.log(Promocode)
            let data = {
                promotype: Promocode.promotype,
                siteCode: Promocode.siteCode,
                site_name: Promocode.site_name,
                countAction: Promocode.countAction,
                discount: Promocode.discount,
                sum_price: 0,
                device_startAt: Promocode.period_promotion.device_startAt,
                device_stopAt: Promocode.period_promotion.device_stopAt
            }
            console.log(data)
            let newData = await addCreatePromocode(data)
            console.log(newData.message)
            if (newData.message) {
                //openNotification('Success Create  !')
                setIsLoading(true)
                setTimeout(() => history.push('/site-promotion/listPromocode'), 1000);
            } else {
                setIsLoading(false)
                openNotification('error', 'Failed !', 'Failed to add new discount.')
            }
        
    }
    const options = [
        { value: 'FREE', label: 'FREE' },
        { value: 'DISS', label: 'DISS' },
        { value: 'POIN', label: 'POIN' },
    ];
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedSiteOption, setSelectedSiteOption] = useState(null);
    const handleSelectChange = async (selectedOption) => {
     //   console.log(selectedOption)
        setPromocode({
            ...Promocode,
            promotype: selectedOption
          })
        let siteAll = await siteall()
        let datasite = siteAll.message.getSites;
        setSelectedOption(selectedOption);
        setSites(datasite || [])
    };
    const selectChangeMachine = async (site_id) => {
        setSelectedSiteOption(site_id);
    }
    return (
        <div>
            <div className="mg-bt-20 h-d-t-p">Create - Promocode</div>
            <div className="t-m-c-i-p-g">
                <div className="f-s-c">Promocode type</div>
                <div className={Alertsite_id && 'antd-sl-al-bd' || ''}>
                    <Select style={{ width: '100%' }}
                        options={options}
                        value={selectedOption}
                        onChange={e => {
                            handleSelectChange(e)
                            setIsAlertsite_id(false)
                        }}
                    />
                </div>
                <div className="f-s-c">Site Name</div>
                <div className={AlertSiteName && 'antd-sl-al-bd' || ''}>
                    <Select style={{ width: '100%' }}
                        placeholder="Site Name"
                        showSearch
                        value={sitePromotion && sitePromotion.site_name}
                        onChange={(value, data) => {
                            setIsAlertSiteName(false)
                            selectChangeMachine(value)
                            setPromocode({
                                ...Promocode,
                                site_name: data?.children,
                                siteCode: value
                              })
                        }}

                        optionFilterProp="children"
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                        {sites.map((site, idx) => <Option key={idx} value={site.siteCode}>{site.site_name}</Option>)}
                    </Select>
                </div> 
                <div className="f-s-c">Count Action</div>
                <div>
                    <InputNumber min={0} max={1000}
                        step={1}
                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                        style={{ width: '100%', borderColor: isAlertLimit ? '#EC1C24' : null }}
                        placeholder="จำนวนครั้ง"
                        value={Promocode && Promocode.countAction}
                        onChange={value => {
                            setIsAlertLimit(false)
                            setPromocode({ ...Promocode, countAction: Math.round(value) || 0 })
                        }}
                    />
                </div>
                <div className="f-s-c">Diss Creadit ( THB )</div>
                <div>
                    <InputNumber min={0}
                        step={0.01}
                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                        style={{ width: '100%', borderColor: AlertCollect_count ? '#EC1C24' : null }}
                        placeholder="ลดราคา"
                        value={Promocode && Promocode.discount}
                        onChange={value => {
                            setIsAlertCollect_count(false)
                            setPromocode({ ...Promocode, discount: Math.round(value * 100) / 100 || 0 })
                        }}
                    />
                </div>
               
                <div className="f-s-c">Period Promotion</div>
                <div>
                    <RangePicker
                        style={{ width: '100%', borderColor: AlertCollect_money ? '#EC1C24' : null }}
                        placeholder={["Period Start", "Period End"]}
                        format={date => moment(date).add(543, 'years').format('D MMM YYYY')}
                        allowClear={false}
                        value={[
                            Promocode && Promocode.period_promotion && Promocode.period_promotion.device_startAt && moment(Promocode.period_promotion.device_startAt, 'YYYY-MM-DD'),
                            Promocode && Promocode.period_promotion && Promocode.period_promotion.device_stopAt && moment(Promocode.period_promotion.device_stopAt, 'YYYY-MM-DD')
                        ]}
                        onChange={date => {
                            setIsAlertCollect_money(false)
                            let newPromocode = { ...Promocode }
                            if (!newPromocode.period_promotion) newPromocode.period_promotion = {}
                            newPromocode.period_promotion.device_startAt = moment(date[0]).format('YYYY-MM-DD')
                            newPromocode.period_promotion.device_stopAt = moment(date[1]).format('YYYY-MM-DD')
                            setPromocode(newPromocode)
                        }}
                        disabledDate={current => current && current < moment().startOf('day')}
                    />
                </div>
               
               
            </div>
            <div className="f-c-c s-e-b">
                <div className="f-c-c d-b-t-n-s d-bg-c-t"
                    style={{ width: '190px', pointerEvents: isLoading ? 'none' : null }}
                    onClick={() => addEditPromotion()}
                >{isLoading ? <LoadingOutlined /> : `${siteId ? 'Edit' : 'Add Promotion'} `}</div>
            </div>
        </div>
    )
}

export default CreatePromocode
