import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import { Table, DatePicker } from 'antd';
import './Transaction.css'
import XLSX from "xlsx";

import moment from 'moment';

import { numberWithCommas, getSearchParams } from '../../utils/function'
import { apiTRSTGetReconcile } from '../../services/apis/transaction'

import Loading from '../../components/Loading/Loading'

const { RangePicker } = DatePicker;

const TransactionReconcile = () => {
  const history = useHistory();
  const location = useLocation();

  const params = new URLSearchParams(location.search)
  const start = params.get('start')
  const end = params.get('end')

  const [isLoading, setIsLoading] = useState(false)
  const [startDate, setStartDate] = useState(start ? moment(start).startOf('day').format('YYYY-MM-DD HH:mm:ss') : moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'))
  const [endDate, setEndDate] = useState(end ? moment(end).endOf('day').format('YYYY-MM-DD HH:mm:ss') : moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'))
  const [tableData, setTableData] = useState(undefined)
  const [searchButton, setSearchButton] = useState(false)

  useEffect(() => {
    getReport()
    history.push(`${location.pathname}${getSearchParams(['start', 'end'], [startDate, endDate])}`)
  }, [searchButton])

  const getReport = async () => {
    setIsLoading(true)
    let transaction = await apiTRSTGetReconcile(false, startDate, endDate)
    if (transaction && transaction.status) {
      setIsLoading(false)
      setTableData(transaction.result)
    }
  }

  const columns = [
    {
      table: ['customer_credit_list'],
      title: 'ช่องทาง',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{data.type || '-'}</div>
    },
    {
      table: ['used_credit_list'],
      title: 'ประเภท',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{data.type || '-'}</div>
    },
    {
      table: ['customer_credit_list', 'used_credit_list'],
      title: 'จำนวน ( บาท )',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{numberWithCommas(data.amount || 0, 2)}</div>
    },
  ];

  const toCSVFile = async table => {
    let csvData = [];

    (tableData && tableData[table] || []).map((data, idx) => {
      let resultRow = {}
      resultRow['ช่วงเวลาที่เลือก'] = `${moment(startDate).add(543, 'years').format('D MMM YYYY')} - ${moment(endDate).add(543, 'years').format('D MMM YYYY')}`
      resultRow[table === 'customer_credit_list' ? 'ช่องทาง' : 'ประเภท'] = data.type || '-'
      resultRow['จำนวน ( บาท )'] = data.amount || 0
      csvData.push(resultRow);
    })

    const toCSV = XLSX.utils.json_to_sheet(csvData);
    const wb = XLSX.utils.book_new();

    const fileName = table === 'customer_credit_list' ? 'CUSTOMER_CREDIT_LIST' : 'USED_CREDIT_LIST'
    XLSX.utils.book_append_sheet(wb, toCSV, fileName);
    XLSX.writeFile(wb, fileName + ".csv");
  }

  return (
    <div>
      <div className="mg-bt-20 h-d-t-p">Transaction Reconcile</div>

      <div className="h-d-i-b">
        <div>
          <RangePicker
            style={{ width: '100%' }}
            format={date => moment(date).add(543, 'years').format('D MMM YYYY HH:mm')}
            allowClear={false}
            showTime={{ format: 'HH:mm' }}
            value={[moment(startDate, 'YYYY-MM-DD HH:mm:ss'), moment(endDate, 'YYYY-MM-DD HH:mm:ss')]}
            onChange={date => {
              setStartDate(moment(date[0]).startOf('minute').format('YYYY-MM-DD HH:mm:ss'))
              setEndDate(moment(date[1]).endOf('minute').format('YYYY-MM-DD HH:mm:ss'))
            }}
            disabledDate={current => current && current >= moment().endOf('day')}
          />
        </div>
        <div className="f-c-c d-b-t-n-s d-bg-c-t"
          onClick={() => setSearchButton(!searchButton)}>
          <i className="bi bi-search" style={{ lineHeight: '0' }}></i>
        </div>
      </div>

      <div className="transaction-reconcile-card-container-grid">
        <div className="c-c-t-s">
          <div className="f-c-c pst-rlt-h-100pc">
            {isLoading && <Loading />}
            <div className="transaction-reconcile-card">
              <div className="transaction-reconcile-card-title">Total Customer Credit</div>
              <div className="transaction-reconcile-card-value">{numberWithCommas(tableData && tableData.total_customer_credit || 0, 2)}</div>
            </div>
          </div>
        </div>
        <div className="c-c-t-s">
          <div className="f-c-c pst-rlt-h-100pc">
            {isLoading && <Loading />}
            <div className="transaction-reconcile-card">
              <div className="transaction-reconcile-card-title">Total Used Credit</div>
              <div className="transaction-reconcile-card-value">{numberWithCommas(tableData && tableData.total_used_credit || 0, 2)}</div>
            </div>
          </div>
        </div>
        <div className="c-c-t-s">
          <div className="f-c-c pst-rlt-h-100pc">
            {isLoading && <Loading />}
            <div className="transaction-reconcile-card">
              <div className="transaction-reconcile-card-title">Total Balance</div>
              <div className="transaction-reconcile-card-value">{numberWithCommas(tableData && tableData.total_credit_balance || 0, 2)}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="mg-t-20">
        <div className="h-d-t-g">
          <div className="f-c-c"><i className="bi bi-credit-card-2-back-fill" style={{ lineHeight: '0' }}></i></div>
          <div className="f-s-c">Customer Credit</div>
          <div className="f-c-c d-b-t-n-s d-bg-c-t h-32 table-export-btn-width table-export-btn-responsive" onClick={() => toCSVFile('customer_credit_list')}>Export CSV</div>
        </div>

        <Table bordered
          size="small"
          loading={isLoading}
          rowKey={record => record.payment_type_id}
          dataSource={tableData && tableData.customer_credit_list || []}
          columns={columns.filter(column => column.table.includes('customer_credit_list'))}
          pagination={false}
          scroll={{ x: true }}
        />
      </div>

      <div className="mg-t-20">
        <div className="h-d-t-g">
          <div className="f-c-c"><i className="bi bi-credit-card-2-back" style={{ lineHeight: '0' }}></i></div>
          <div className="f-s-c">Used Credit</div>
          <div className="f-c-c d-b-t-n-s d-bg-c-t table-export-btn-width table-export-btn-responsive" onClick={() => toCSVFile('used_credit_list')}>Export CSV</div>
        </div>
        <Table bordered
          size="small"
          loading={isLoading}
          rowKey={record => record.machine_type_id}
          dataSource={tableData && tableData.used_credit_list || []}
          columns={columns.filter(column => column.table.includes('used_credit_list'))}
          pagination={false}
          scroll={{ x: true }}
        />
      </div>

    </div>
  )
}

export default TransactionReconcile
