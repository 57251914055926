import React, { useState, useEffect } from 'react'

import { Table, DatePicker, Anchor, Input, Pagination, Button, Card, Modal } from 'antd';

import { apiGetSlip } from '../../../../services/apis/slip';

import moment from 'moment';
import 'moment/locale/th'
import { baseURL } from '../../../../config/config';

const TableSlipUpload = (props) => {

    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState(undefined);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(20)

    useEffect(() => {
        getTableData()
    }, [currentPage, perPage])

    const getTableData = async () => {
        setIsLoading(true)
        let slip = await apiGetSlip(currentPage, perPage, props.siteId);
        if (slip && slip.status) {
          setIsLoading(false)
          console.log('Slip Result: ', slip.result);
          setTableData(slip.result);
        }
    }

    const columns = [
        {
            title: '#',
            ellipsis: true,
            width: '5%',
            render: (data) => <div>{data.id || '-'}</div>
        },
        {
            title: 'ชื่อไฟล์อัพโหลด',
            ellipsis: true,
            className: 'cs-pt',
            render: (data) => <div onClick={() => {
                if (data.path_file) {
                    window.open(baseURL + '/file/' + data.path_file)
                } else {
                    console.log('No Path file');
                }
            }}>{data.file_name || '-'}</div>
        },
        {
            title: 'ยอดโอน (บาท)',
            ellipsis: true,
            width: '15%',
            render: (data) => <div>{data.price || '-'}</div>
        },
        {
            title: 'วันที่ชำระเงิน',
            ellipsis: true,
            width: '15%',
            render: (data) => <div>{moment(data.payment_date).format('L') || '-'}</div>
        },
    ];

    return (
        <div className="dash-chart-box-container">
            <div className="dash-space-between">
                <div className="h-d-t-g">
                    <div className="f-c-c"><i className="bi bi-file-earmark-arrow-up" style={{ lineHeight: '0' }}></i></div>
                    <div className="f-s-c">Slip Upload ( Recent {perPage} Lists )</div>
                </div>

                <Table bordered
                    size="small"
                    loading={isLoading}
                    rowKey={record => record.payment_id}
                    dataSource={tableData && tableData.data_list || []}
                    columns={columns}
                    pagination={false}
                    scroll={{ x: true }}
                />

                <div className="pgnt-st">
                    <Pagination
                        size="small"
                        current={currentPage}
                        onChange={page => setCurrentPage(page)}
                        total={tableData && tableData.max_total_rows}
                        pageSize={perPage}
                        showSizeChanger={false}
                    />
                </div>  
            </div>       
        </div>
    )
};

export default TableSlipUpload