import React, { useState, useEffect } from 'react'
import { Table, Pagination } from 'antd';
import '../../Table.css'
// import XLSX from "xlsx";
// import { LoadingOutlined } from '@ant-design/icons';

// import moment from 'moment';
// import 'moment/locale/th'

import { apiDBGetReportGroupBySiteId } from '../../../../services/apis/dashboard'
import { numberWithCommas } from '../../../../utils/function'

const TableReportGroup = props => {
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [perPage, setPerPage] = useState(20)
  const [tableData, setTableData] = useState(undefined)
  // const [waitingForCSV, setWaitingForCSV] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    const getTableData = async () => {
      let data = await apiDBGetReportGroupBySiteId(false, props.startDate, props.endDate, currentPage, perPage, props.siteId)
      if (data && data.status) {
        setIsLoading(false)
        setTableData(data.result)
      }
    }
    getTableData()
  }, [props.searchButton, currentPage, perPage])

  const columns = [
    {
      title: '#',
      ellipsis: true,
      align: 'center',
      width: '5%',
      render: (data, record, index) => <div>{(index + 1) + ((currentPage - 1) * perPage)}</div>
    },
    {
      title: 'เครื่อง',
      ellipsis: true,
      render: (data) => <div>{data.machine_name || '-'}</div>
    },
    {
      title: 'จำนวนครั้ง',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{numberWithCommas(data.count || 0, 0)}</div>
    },
    {
      title: 'Coin',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{numberWithCommas(data.coin || 0, 2)}</div>
    },
    {
      title: 'APP',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{numberWithCommas(data.mobile || 0, 2)}</div>
    },
    {
      title: 'QR Code',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{numberWithCommas(data.qrcode || 0, 2)}</div>
    },
    {
      title: 'รวม',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{numberWithCommas(data.total || 0, 2)}</div>
    },
  ];

  // const toCSVFile = async () => {
  //   setWaitingForCSV(true)
  //   let data = await apiDBGetReportGroupBySiteId(true, props.startDate, props.endDate, '', '')
  //   let csvData = [];

  //   if (data && data.status) {
  //     (data.result.data_list || []).map((data, idx) => {
  //       let resultRow = {}
  //       resultRow['ช่วงเวลาที่เลือก'] = `${moment(props.startDate).add(543, 'years').format('D MMM YYYY')} - ${moment(props.endDate).add(543, 'years').format('D MMM YYYY')}`
  //       resultRow['#'] = idx + 1
  //       resultRow['เครื่อง'] = data.machine_name || '-'
  //       resultRow['จำนวนครั้ง'] = data.count || 0
  //       resultRow['Coin'] = data.coin || 0
  //       resultRow['APP'] = data.mobile || 0
  //       resultRow['QR Code'] = data.qrcode || 0
  //       resultRow['รวม'] = data.total || 0
  //       csvData.push(resultRow);
  //     })

  //     const toCSV = XLSX.utils.json_to_sheet(csvData);
  //     const wb = XLSX.utils.book_new();

  //     const fileName = 'REPORT_GROUP'
  //     XLSX.utils.book_append_sheet(wb, toCSV, fileName);
  //     XLSX.writeFile(wb, fileName + ".csv");

  //     setWaitingForCSV(false)
  //   }
  // }

  return (
    <div className="dash-chart-box-container">
      <div className="dash-space-between">
        <div className="h-d-t-g">
          <div className="f-c-c"><i className="bi bi-journal-text" style={{ lineHeight: '0' }}></i></div>
          <div className="f-s-c">รายงานการใช้งานแยกตามราคา</div>
          {/* <div className="f-c-c d-b-t-n-s d-bg-c-t table-export-btn-width table-export-btn-responsive" style={{ pointerEvents: waitingForCSV ? 'none' : null }} onClick={() => toCSVFile()}>
            {waitingForCSV ? <LoadingOutlined /> : 'Export CSV'}
          </div> */}
        </div>

        <div className="dash-table-container">
          <Table bordered
            size="small"
            rowKey={record => record.id}
            loading={isLoading}
            dataSource={tableData && tableData.data_list || []}
            columns={columns}
            pagination={false}
            scroll={{ x: true }}
          />
        </div>

        <div className="pgnt-st">
          <Pagination
            size="small"
            current={currentPage}
            onChange={page => setCurrentPage(page)}
            total={tableData && tableData.max_total_rows}
            pageSize={perPage}
            showSizeChanger={false}
          />
        </div>

      </div>
    </div>
  )
}

export default TableReportGroup
