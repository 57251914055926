import React, { useState, useEffect } from "react";
import "../Report.css";
import { useHistory, useLocation } from "react-router-dom";
import Loading from "../../../components/Loading/Loading";
import ProgressPercent from "../../../components/Tools/Progress/ProgressPercent";
import moment from "moment";
import "moment/locale/th";
import { numberWithCommas } from "../../../utils/function";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;
const Reportcustomer = (props) => {
  const location = useLocation();
  const [report, setReport] = useState(undefined);
  const [coin, setCoin] = useState(0);
  const [mobile, setMobile] = useState(0);
  const [qrCode, setQrCode] = useState(0);
  const [runTime, setRunTime] = useState(0);
  const [incomeTotal, setIncomeTotal] = useState(0);
  const [searchButton, setSearchButton] = useState(false);
  const params = new URLSearchParams(location.search);
  const start = params.get("start");
  const end = params.get("end");
  const [startDate, setStartDate] = useState(
    start
      ? moment(start).startOf("day").format("YYYY-MM-DD HH:mm:ss")
      : moment().startOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [endDate, setEndDate] = useState(
    end
      ? moment(end).endOf("day").format("YYYY-MM-DD HH:mm:ss")
      : moment().endOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  useEffect(() => {
    setReport(props.report);
  }, [props]);
  useEffect(async () => {
    let data = {
      "startdate": startDate,
      "stopdate": endDate
    }
  }, []);
  useEffect(() => {
    // if (report?.length > 0) {
    //   let coinValue = 0;
    //   let mobileValue = 0;
    //   let qrCodeValue = 0;
    //   let incomeTotalValue = 0;

    //   report.map((item) => {
    //     if (item?.action_by == "NOP") {
    //       if (item?.run_value) {
    //         coinValue += parseInt(item?.run_value);
    //       }
    //       setCoin(coinValue);
    //     }
    //     if (item?.action_by == "Mobile") {
    //       if (item?.run_value) {
    //         mobileValue += parseInt(item?.run_value);
    //       }
    //       setMobile(mobileValue);
    //     }
    //     if (item?.action_by == "QR") {
    //       if (item?.run_value) {
    //         qrCodeValue += parseInt(item?.run_value);
    //       }
    //       setQrCode(qrCodeValue);
    //     }
    //     incomeTotalValue += parseInt(item?.run_value);
    //     setRunTime(report?.length ?? 0);
    //     setIncomeTotal(incomeTotalValue ?? 0);
    //   });
    // }
  }, [report]);

  return (
    <div>
       <div className="dash-select-date-container-grid">
        
        <div className="f-c-c">เลือกวันที่ :</div>
        <div>
          <RangePicker
            style={{ width: "100%" }}
            format={(date) =>
              moment(date).add(543, "years").format("D MMM YYYY HH:mm")
            }
            allowClear={false}
            showTime={{ format: "HH:mm" }}
            value={[
              moment(startDate, "YYYY-MM-DD HH:mm:ss"),
              moment(endDate, "YYYY-MM-DD HH:mm:ss"),
            ]}
            onChange={(date) => {
              setStartDate(
                moment(date[0]).startOf("minute").format("YYYY-MM-DD HH:mm:ss")
              );
              setEndDate(
                moment(date[1]).endOf("minute").format("YYYY-MM-DD HH:mm:ss")
              );
            }}
          />
        </div>
        <div
          className="f-c-c d-b-t-n-s d-bg-c-t dash-select-date-search"
          onClick={() => setSearchButton(!searchButton)}
        >
          <i className="bi bi-search" style={{ lineHeight: "0" }}></i>
        </div>
      </div>
      <div className="dash-report-card-payment-grid">
        <div className="c-c-t-s">
          <div className="pst-rlt-h-100pc">
            {props.isLoading && <Loading />}
            <div className="dash-report-card-padding">
              <div className="dash-report-title">รอบการทำงานเครื่อง OS</div>
              <div className="dash-report-card-detail-grid">
                <div className="f-c-c">
                  <i className="bi bi-layers"></i>
                </div>
                <div>เหรียญ</div>
                <div>{numberWithCommas(coin, 2)}</div>
              </div>
              <div className="dash-report-card-detail-grid">
                <div className="f-c-c">
                  <i className="bi bi-phone"></i>
                </div>
                <div>App</div>
                <div>{numberWithCommas(mobile, 2)}</div>
              </div>
              <div className="dash-report-card-detail-grid">
                <div className="f-c-c">
                  <i className="bi bi-upc-scan"></i>
                </div>
                <div>QR-Code</div>
                <div>{numberWithCommas(qrCode, 2)}</div>
              </div>
              <div
                className="dash-report-total-credit"
                style={{ margin: "10px 0 0" }}
              >
                {numberWithCommas(incomeTotal, 2)}
              </div>
              <div>
                <ProgressPercent value={incomeTotal} />
              </div>
            </div>
          </div>
        </div>

        <div className="c-c-t-s">
          <div className="pst-rlt-h-100pc">
            {props.isLoading && <Loading />}
            <div className="dash-report-card-padding">
            <div className="dash-report-title">รอบการทำงานเครื่อง <i class="fa fa-sellsy" aria-hidden="true"></i></div>
              <div className="dash-report-card-detail-grid">
                <div className="f-c-c">
                  <i className="bi bi-layers"></i>
                </div>
                <div>เหรียญ</div>
                <div>{numberWithCommas(coin, 2)}</div>
              </div>
              <div className="dash-report-card-detail-grid">
                <div className="f-c-c">
                  <i className="bi bi-phone"></i>
                </div>
                <div>App</div>
                <div>{numberWithCommas(mobile, 2)}</div>
              </div>
              <div className="dash-report-card-detail-grid">
                <div className="f-c-c">
                  <i className="bi bi-upc-scan"></i>
                </div>
                <div>QR-Code</div>
                <div>{numberWithCommas(qrCode, 2)}</div>
              </div>
              <div
                className="dash-report-total-credit"
                style={{ margin: "10px 0 0" }}
              >
                {numberWithCommas(incomeTotal, 2)}
              </div>
              <div>
                <ProgressPercent value={incomeTotal} />
              </div>
            </div>
          </div>
        </div>

        <div className="c-c-t-s">
          <div className="pst-rlt-h-100pc">
            {props.isLoading && <Loading />}
            <div className="dash-report-card-padding">
              <div className="dash-report-title">
                จำนวนใช้งานทั้งหมด ( ครั้ง )
              </div>
              <div className="dash-report-total-credit">{runTime}</div>
              <div>
                <ProgressPercent value={runTime} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="dash-big-title">ช่องทางการชำระเงินจากการใช้งาน</div>
      <div className="dash-report-card-payment-grid">
        <div className="c-c-t-s">
          <div className="pst-rlt-h-100pc">
            {props.isLoading && <Loading />}
            <div className="p-d-p-ch-10-20">
              <div className="dash-report-card-payment-detail-grid">
                <div className="f-c-c">
                  <i className="bi bi-layers"></i>
                </div>
                <div>เหรียญ</div>
                <div>{numberWithCommas(coin, 2)}</div>
              </div>
              <div>
                <ProgressPercent value={coin} />
              </div>
            </div>
          </div>
        </div>

        <div className="c-c-t-s">
          <div className="pst-rlt-h-100pc">
            {props.isLoading && <Loading />}
            <div className="p-d-p-ch-10-20">
              <div className="dash-report-card-payment-detail-grid">
                <div className="f-c-c">
                  <i className="bi bi-phone"></i>
                </div>
                <div>App</div>
                <div>{numberWithCommas(mobile, 2)}</div>
              </div>
              <div>
                <ProgressPercent value={mobile} />
              </div>
            </div>
          </div>
        </div>

        <div className="c-c-t-s">
          <div className="pst-rlt-h-100pc">
            {props.isLoading && <Loading />}
            <div className="p-d-p-ch-10-20">
              <div className="dash-report-card-payment-detail-grid">
                <div className="f-c-c">
                  <i className="bi bi-upc-scan"></i>
                </div>
                <div>QR-Code</div>
                <div>{numberWithCommas(qrCode, 2)}</div>
              </div>
              <div>
                <ProgressPercent value={qrCode} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="dash-big-title">ช่องทางการเติมเงิน</div>
      <div className="dash-report-card-payment-grid">
        {((report && report.payment_type) || []).map((type, idx) => (
          <div key={idx} className="c-c-t-s">
            <div className="pst-rlt-h-100pc">
              {props.isLoading && <Loading />}
              <div className="p-d-p-ch-10-20">
                <div className="dash-report-card-payment-detail-grid">
                  <div className="f-c-c">
                    {type.payty_image ? (
                      <img src={type.payty_image} alt="" />
                    ) : (
                      <i className="bi bi-wallet2"></i>
                    )}
                  </div>
                  <div>{type.payty_name || "-"}</div>
                  <div>{numberWithCommas(type.value || 0, 2)}</div>
                </div>
                <div>
                  <ProgressPercent value={type.percent || 0} />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Reportcustomer;
