import React, { useState, useEffect } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import "./Machine.css";
import {
    QueryGraphQLById,
    GET_MACHINE,
  } from "../../resolvers/Query";

import LgWasherSettings from "./LgWasherSettings";
import LgDryerSettings from "./LgDryerSettings";

const MachineLgSettings = () => {

    const { machineId, lgDeviceId } = useParams();
    const dataMachine = QueryGraphQLById(GET_MACHINE, machineId);
    const machineFound = dataMachine?.data?.getMachine;

    const [machineType, setMachineType] = useState("")
    
    useEffect(() => {
        if (machineFound?.lg_device.deviceId) {
            if (machineFound.lg_device.deviceType == "211") {
                setMachineType("washer");
            } else {
                setMachineType("dryer");
            }
        }
    }, [machineFound]);
    
    
    return (
        <div>
            <div className="mg-bt-20 h-d-t-p pt-3">
                Set Config LG Machine  {" "}
               
            </div>
            {
                machineType == "washer" &&
                <LgWasherSettings deviceId={lgDeviceId} />
            }
            {
                 machineType == "dryer" &&
                 <LgDryerSettings deviceId={lgDeviceId} />
            }
        </div>
    )

};

export default MachineLgSettings;