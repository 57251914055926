import axios from '../../config/axios';

const apiCSPGetMaintenance = async (get_all, start_date, end_date, cur_page, per_page, keyword) => {
  try {
    const result = await axios.get(`/call_support/maintenance/list?get_all=${get_all}&start_date=${start_date}&end_date=${end_date}&cur_page=${cur_page}&per_page=${per_page}&keyword=${keyword}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiCSPGetInquiry = async (get_all, start_date, end_date, cur_page, per_page, keyword) => {
  try {
    const result = await axios.get(`/call_support/inquiry/list?get_all=${get_all}&start_date=${start_date}&end_date=${end_date}&cur_page=${cur_page}&per_page=${per_page}&keyword=${keyword}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiCSPGetStatus = async () => {
  try {
    const result = await axios.get('/call_support/status/dropdown')
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiCSPGetSale = async () => {
  try {
    const result = await axios.get('/call_support/sale/dropdown')
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiCSPAddMaintenance = async (data) => {
  try {
    const result = await axios.post('/call_support/maintenance', data)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiCSPEditMaintenance = async (data) => {
  try {
    const result = await axios.put('/call_support/maintenance', data)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiCSPGetMaintenanceById = async (id) => {
  try {
    const result = await axios.get(`/call_support/maintenance?id=${id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiCSPAddInquiry = async (data) => {
  try {
    const result = await axios.post('/call_support/inquiry', data)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiCSPEditInquiry = async (data) => {
  try {
    const result = await axios.put('/call_support/inquiry', data)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiCSPGetInquiryById = async (id) => {
  try {
    const result = await axios.get(`/call_support/inquiry?id=${id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

export {
  apiCSPGetMaintenance,
  apiCSPGetInquiry,
  apiCSPGetStatus,
  apiCSPGetSale,

  apiCSPAddMaintenance,
  apiCSPEditMaintenance,
  apiCSPGetMaintenanceById,

  apiCSPAddInquiry,
  apiCSPEditInquiry,
  apiCSPGetInquiryById,
}