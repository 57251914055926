import React, { useState, useEffect } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Input, Table, Pagination, Tooltip, Select } from 'antd';
import './Maintenance.css'
import XLSX from "xlsx";
import { LoadingOutlined } from '@ant-design/icons';

import moment from 'moment';
import 'moment/locale/th'

import { numberWithCommas, getSearchParams } from '../../utils/function'
import { apiSGetSite } from '../../services/apis/site'
import { apiMCHGetTypeBySiteId } from '../../services/apis/machine'
import { apiMTNGetSupportOffline } from '../../services/apis/maintenance'

const { Option } = Select;

const SupportOffline = () => {
  const history = useHistory();
  const location = useLocation();

  const params = new URLSearchParams(location.search)
  const p = params.get('p')
  const ttl = params.get('ttl')
  const mch = params.get('mch')
  const s = params.get('s')
  const sw = params.get('sw')

  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(Number(p) || 1)
  const [perPage, setPerPage] = useState(Number(ttl) || 100)
  const [tableData, setTableData] = useState(undefined)
  const [searchWord, setSearchWord] = useState(sw || '')
  const [searchButton, setSearchButton] = useState(false)
  const [waitingForCSV, setWaitingForCSV] = useState(false)

  const [machines, setMachines] = useState([])
  const [machine, setMachine] = useState(Number(mch) || '')
  const [sites, setSites] = useState([])
  const [site, setSite] = useState(Number(s) || '')

  useEffect(() => {
    const getStaticDropdown = async () => {
      let machine = await apiMCHGetTypeBySiteId()
      if (machine && machine.status) setMachines(machine.result || [])

      let site = await apiSGetSite()
      if (site && site.status) setSites(site.result || [])
    }
    getStaticDropdown()
  }, [])

  useEffect(() => {
    getTableData()
    history.push(`${location.pathname}${getSearchParams(['p', 'ttl', 'mch', 's', 'sw'], [currentPage, perPage, machine, site, searchWord])}`)
  }, [currentPage, perPage, searchButton])

  const getTableData = async () => {
    setIsLoading(true)
    let table = await apiMTNGetSupportOffline(false, currentPage, perPage, searchWord, machine, site)
    if (table && table.status) {
      setIsLoading(false)
      setTableData(table.result)
    }
  }

  const columns = [
    {
      title: '#',
      ellipsis: true,
      align: 'center',
      width: '5%',
      render: (data, record, index) => <div>{(index + 1) + ((currentPage - 1) * perPage)}</div>
    },
    {
      title: 'ประเภท',
      ellipsis: true,
      render: (data) => <div>{data.machine_type || '-'}</div>
    },
    {
      title: 'สถานที่',
      ellipsis: true,
      render: (data) => <div>{data.site_name || '-'}</div>
    },
    {
      title: 'ประเภทย่อย',
      ellipsis: true,
      render: (data) => <div>{data.machine_sub_type || '-'}</div>
    },
    {
      title: 'ชื่อ',
      ellipsis: true,
      render: (data) => <div>{data.machine_name || '-'}</div>
    },
    {
      title: 'รหัส',
      ellipsis: true,
      render: (data) => <div>{data.machine_mac_address || '-'}</div>
    },
    {
      title: 'ออนไลน์ล่าสุด',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{data.lastupdate ? moment(data.lastupdate).fromNow() : '-'}</div>
    },
    {
      title: 'สถานะการซ่อม',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{data.maintenance_status || '-'}</div>
    },
    {
      title: 'ตั้งค่า',
      ellipsis: true,
      align: 'center',
      fixed: 'right',
      render: (data) => <Tooltip placement="top" title="แจ้งซ่อม">
        <div className="f-c-c d-b-t-n-s b-c-o-i" style={{ backgroundColor: '#FFCB05' }}
          onClick={e => {
            e.stopPropagation()
            history.push(`/maintenance/create-ticket${getSearchParams(['p', 'ttl', 'mch', 's', 'sw', 'ats', 'atmch'], [currentPage, perPage, machine, site, searchWord, String(data.site_id), String(data.machine_id)])}`)
          }}
        ><i className="fas fa-comments"></i></div>
      </Tooltip>
    },
  ];

  const toCSVFile = async () => {
    setWaitingForCSV(true)
    let data = await apiMTNGetSupportOffline(true, '', '', searchWord, '', '')
    let csvData = [];

    if (data && data.status) {
      (data.result.data_list || []).map((data, idx) => {
        let resultRow = {}
        resultRow['#'] = idx + 1
        resultRow['ประเภท'] = data.machine_type || '-'
        resultRow['สถานที่'] = data.site_name || '-'
        resultRow['ประเภทย่อย'] = data.machine_sub_type || '-'
        resultRow['ชื่อ'] = data.machine_name || '-'
        resultRow['รหัส'] = data.machine_mac_address || '-'
        resultRow['สถานะการซ่อม'] = data.maintenance_status || '-'
        csvData.push(resultRow);
      })

      const toCSV = XLSX.utils.json_to_sheet(csvData);
      const wb = XLSX.utils.book_new();

      const fileName = 'MAINTENANCE_OFFINE'
      XLSX.utils.book_append_sheet(wb, toCSV, fileName);
      XLSX.writeFile(wb, fileName + ".csv");

      setWaitingForCSV(false)
    }
  }

  return (
    <div>
      <div className="mg-bt-20 coin-collect-title">
        <div className="h-d-t-p">Maintenance - Offine</div>
        <div className="coin-collect-card-list">
          {(tableData && tableData.sumary || []).map((list, idx) => <div key={idx} className="c-c-t-s">
            <div className="coin-collect-card">
              <div className="coin-collect-card-grid">
                <div>{list.name}</div>
                <div>{numberWithCommas(list.value || 0, 0)}</div>
              </div>
            </div>
          </div>)}
        </div>
      </div>

      <div className="maintenance-header-grid maintenance-header-grid-offline">
        <div className="ovf-hd maintenance-header-grid-offline-machine">
          <Select style={{ width: '100%' }}
            value={machine}
            onChange={value => setMachine(value)}
          >
            <Option value="">All Machine Type</Option>
            {machines.map((machine, idx) => <Option key={idx} value={machine.machine_type_id}>{machine.machine_type_name}</Option>)}
          </Select>
        </div>
        <div className="ovf-hd maintenance-header-grid-offline-site">
          <Select style={{ width: '100%' }}
            showSearch
            value={site}
            onChange={value => setSite(value)}
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            <Option value="">All Site</Option>
            {sites.map((site, idx) => <Option key={idx} value={site.site_id}>{site.site_name}</Option>)}
          </Select>
        </div>
        <div className="maintenance-header-grid-offline-search">
          <Input
            placeholder="search .."
            value={searchWord}
            onChange={e => setSearchWord(e.target.value)}
            onPressEnter={() => {
              setCurrentPage(1)
              setSearchButton(!searchButton)
            }}
          />
        </div>
        <div className="f-c-c d-b-t-n-s d-bg-c-t"
          onClick={() => {
            setCurrentPage(1)
            setSearchButton(!searchButton)
          }}>
          <i className="bi bi-search" style={{ lineHeight: '0' }}></i>
        </div>
        {/* <Link to={`/maintenance/offline${getSearchParams(['p', 'ttl', 'mch', 's', 'sw'], [currentPage, perPage, machine, site, searchWord])}`}> */}
        <div className="f-c-c d-b-t-n-s d-bg-c-t h-32 maintenance-header-grid-offline-open" style={{ height: '100%' }}>เปิดงานซ่อม</div>
        {/* </Link> */}
        <div className="f-c-c d-b-t-n-s d-bg-c-t h-32 maintenance-header-grid-offline-export"
          style={{ pointerEvents: waitingForCSV ? 'none' : null }}
          onClick={() => toCSVFile()}
        >
          {waitingForCSV ? <LoadingOutlined /> : 'Export CSV'}
        </div>
      </div>

      <div>เครื่องออฟไลน์</div>

      <Table bordered
        size="small"
        rowKey={record => record.machine_id}
        loading={isLoading}
        dataSource={tableData && tableData.data_list || []}
        columns={columns}
        pagination={false}
        scroll={{ x: true }}
      />

      {/* <div className="pgnt-st">
        <Pagination
          size="small"
          current={currentPage}
          onChange={page => setCurrentPage(page)}
          total={tableData && tableData.max_total_rows}
          pageSize={perPage}
          showSizeChanger={false}
        />
      </div> */}

    </div>
  )
}

export default SupportOffline
