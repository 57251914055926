import React, { useState, useEffect } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Input, Table, Pagination, Tooltip, Modal, DatePicker, Checkbox } from 'antd';
import './CoinCollection.css'
import XLSX from "xlsx";
import { LoadingOutlined } from '@ant-design/icons';

import moment from 'moment';
import 'moment/locale/th'

import { numberWithCommas, openNotification, getSearchParams } from '../../utils/function'
import { apiCGetCoinHistory, apiCDeleteById, apiCCheckCoinById } from '../../services/apis/coin'

const { RangePicker } = DatePicker;

const CoinReconcile = () => {
  const history = useHistory();
  const location = useLocation();

  const params = new URLSearchParams(location.search)
  const p = params.get('p')
  const ttl = params.get('ttl')
  const start = params.get('start')
  const end = params.get('end')
  const sw = params.get('sw')
  const userid = localStorage.getItem("user_id")
  const menuList = (localStorage.getItem('list') || '').split(',') || ''

  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(Number(p) || 1)
  const [perPage, setPerPage] = useState(Number(ttl) || 20)
  const [startDate, setStartDate] = useState(start ? moment(start).startOf('day').format('YYYY-MM-DD HH:mm:ss') : moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'))
  const [endDate, setEndDate] = useState(end ? moment(end).endOf('day').format('YYYY-MM-DD HH:mm:ss') : moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'))
  const [tableData, setTableData] = useState(undefined)
  const [searchWord, setSearchWord] = useState(sw || '')
  const [searchButton, setSearchButton] = useState(false)
  const [waitingForCSV, setWaitingForCSV] = useState(false)

  const [visibleDelete, setVisibleDelete] = useState(false)
  const [modalTitleDelete, setModalTitleDelete] = useState('')
  const [modalIdDelete, setModalIdDelete] = useState(undefined)
  const [isLoadingConfirmDelete, setIsLoadingConfirmDelete] = useState(false)

  const [visibleCheck, setVisibleCheck] = useState(false)
  const [modalTitleCheck, setModalTitleCheck] = useState('')
  const [modalIdCheck, setModalIdCheck] = useState(undefined)
  const [isLoadingConfirmCheck, setIsLoadingConfirmCheck] = useState(false)

  useEffect(() => {
    getTableData()
    history.push(`${location.pathname}${getSearchParams(['p', 'ttl', 'start', 'end', 'sw'], [currentPage, perPage, startDate, endDate, searchWord])}`)
  }, [currentPage, perPage, searchButton])

  const getTableData = async () => {
    setIsLoading(true)
    let table = await apiCGetCoinHistory(false, startDate, endDate, currentPage, perPage, searchWord)

    console.log("ddd",table)
    if (table && table.status) {
      setIsLoading(false)
      setTableData(table.result)
    }
  }


  const columns = [
    {
      title: '#',
      ellipsis: true,
      align: 'center',
      width: '5%',
      render: (data, record, index) => <div>{(index + 1) + ((currentPage - 1) * perPage)}</div>
    },
    {
      title: 'สถานะ',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{data.status || '-'}</div>
    },
    {
      title: 'วันที่เริ่มต้น',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{data.start_date ? moment(data.start_date).add(543, 'years').format('D MMM YYYY HH:mm:ss') : '-'}</div>
    },
    {
      title: 'วันที่สิ้นสุด',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{data.end_date ? moment(data.end_date).add(543, 'years').format('D MMM YYYY HH:mm:ss') : '-'}</div>
    },
    {
      title: 'วันที่นับ',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{data.counted_date ? moment(data.counted_date).add(543, 'years').format('D MMM YYYY HH:mm:ss') : '-'}</div>
    },
    {
      title: 'สถานที่',
      ellipsis: true,
      render: (data) => <div>{data.site_name || '-'}</div>
    },
    {
      title: 'เครื่อง',
      ellipsis: true,
      render: (data) => <div>{data.machine_name || '-'}</div>
    },
    {
      title: 'รหัสกล่อง',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{data.box_code || '-'}</div>
    },
    {
      title: 'ยอดนับได้',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{numberWithCommas(data.total || 0, 2)}</div>
    },
    {
      title: 'ยอดระบบ',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{numberWithCommas(data.total_system || 0, 2)}</div>
    },
    {
      title: 'Diff.',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{numberWithCommas(data.diff || 0, 2)}</div>
    },
    {
      title: 'ตรวจสอบ',
      ellipsis: true,
      align: 'center',
      render: (data) => <div
        className="f-c-c"
        style={{ pointerEvents: data.is_check ? 'none' : null }}
      >
        <Checkbox
          checked={data.is_check}
          onChange={() => {
            setModalIdCheck(data.coin_collect_id)
            setModalTitleCheck(data.box_code)
            setVisibleCheck(true)
          }}
          disabled={data.status === 'ยังไม่นับ'}
        />
      </div>
    },
    {
      title: 'App',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{numberWithCommas(data.total_app || 0, 2)}</div>
    },
    {
      title: 'Qr',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{numberWithCommas(data.total_qr || 0, 2)}</div>
    },
    {
      title: 'คนใส่',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{data.admin_checkin || '-'}</div>
    },
    {
      title: 'คนถอด',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{data.admin_checkout || '-'}</div>
    },
    {
      title: 'คนนับ',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{data.admin_count || '-'}</div>
    },
    {
      title: 'ตั้งค่า',
      ellipsis: true,
      align: 'center',
      fixed: 'right',
      render: (data) => <div className="f-c-c tb-cl-a">
        <Tooltip placement="top" title="Edit">
          <div className="f-c-c d-b-t-n-s b-c-o-i" style={{ backgroundColor: '#FFCB05' }}
            onClick={e => {
              e.stopPropagation()
              history.push(`/coin/history/edit/${data.coin_collect_id}${getSearchParams(['p', 'ttl', 'start', 'end', 'sw'], [currentPage, perPage, startDate, endDate, searchWord])}`)
            }}
          ><i className="bi bi-pencil-fill"></i></div>
        </Tooltip>
        <Tooltip placement="top" title="Delete">
          <div className="f-c-c d-b-t-n-s b-c-o-i" style={{ backgroundColor: '#ED2228' }}
            onClick={(e) => {
              e.stopPropagation()
              setModalIdDelete(data.coin_collect_id)
              setModalTitleDelete(data.box_code)
              setVisibleDelete(true)
            }}
          ><i className="fas fa-trash"></i></div>
        </Tooltip>
      </div>
    },
  ];

  const checkData = async () => {
    if (!modalIdCheck) {
      openNotification('error', 'Failed !', 'Failed to check this coin.')
      return
    }

    setIsLoadingConfirmCheck(true)
    let check = await apiCCheckCoinById(modalIdCheck)
    if (check && check.status) {
      openNotification('success', 'Success !', 'Check this coin successful.')
      setVisibleCheck(false)
      setIsLoadingConfirmCheck(false)
      getTableData()
    } else {
      openNotification('error', 'Failed !', 'Failed to check this coin.')
      setIsLoadingConfirmCheck(false)
    }
  }

  const deleteData = async () => {
    if (!modalIdDelete) {
      openNotification('error', 'Failed !', 'Failed to delete this coin.')
      return
    }

    setIsLoadingConfirmDelete(true)
    let action = await apiCDeleteById(modalIdDelete)
    if (action && action.status) {
      openNotification('success', 'Success !', 'Delete this coin successful.')
      setVisibleDelete(false)
      setIsLoadingConfirmDelete(false)
      getTableData()
    } else {
      openNotification('error', 'Failed !', 'Failed to delete this coin.')
      setIsLoadingConfirmDelete(false)
    }
  }

  const toCSVFile = async () => {
    setWaitingForCSV(true)
    let data = await apiCGetCoinHistory(true, startDate, endDate, '', '', searchWord)
    let csvData = [];

    if (data && data.status) {
      (data.result.data_list || []).map((data, idx) => {
        let resultRow = {}
        resultRow['#'] = idx + 1
        resultRow['วันที่เริ่มต้น'] = data.start_date || '-'
        resultRow['วันที่สิ้นสุด'] = data.end_date || '-'
        resultRow['วันที่นับ'] = data.counted_date || '-'
        resultRow['สถานที่'] = data.site_name || '-'
        resultRow['เครื่อง'] = data.machine_name || '-'
        resultRow['รหัสกล่อง'] = data.box_code || '-'
        resultRow['Collector'] = data.collector || '-'
        resultRow['ยอด ( บาท )'] = data.total || 0
        resultRow['Diff.'] = data.diff || 0
        resultRow['สถานะ'] = data.status || '-'
        resultRow['ตรวจสอบ'] = data.is_check ? 'ตรวจสอบแล้ว' : 'ยังไม่ได้ตรวจสอบ'
        resultRow['App'] = data.total_app || 0
        resultRow['Qr.'] = data.total_qr || 0
        csvData.push(resultRow);
      })

      const toCSV = XLSX.utils.json_to_sheet(csvData);
      const wb = XLSX.utils.book_new();

      const fileName = 'COIN_COLLECT_HISTORY'
      XLSX.utils.book_append_sheet(wb, toCSV, fileName);
      XLSX.writeFile(wb, fileName + ".csv");

      setWaitingForCSV(false)
    }
  }

  return (
    <div>
      <div className="mg-bt-20 coin-collect-title">
        <div className="h-d-t-p">Coin Collection - History</div>
        <div className="coin-collect-card-list">
          {(tableData && tableData.sumary || []).map((list, idx) => <div key={idx} className="c-c-t-s">
            <div className="coin-collect-card">
              <div className="coin-collect-card-grid">
                <div>{list.name}</div>
                <div>{numberWithCommas(list.value || 0, 0)}</div>
              </div>
            </div>
          </div>)}
        </div>
      </div>

      <div className="coin-collect-search-bar-all-grid">
        <div>
          <RangePicker
            style={{ width: '100%' }}
            format={date => moment(date).add(543, 'years').format('D MMM YYYY')}
            allowClear={false}
            value={[moment(startDate, 'YYYY-MM-DD'), moment(endDate, 'YYYY-MM-DD')]}
            onChange={date => {
              setCurrentPage(1)
              setStartDate(moment(date[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss'))
              setEndDate(moment(date[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss'))
            }}
            disabledDate={current => current && current >= moment().endOf('day')}
          />
        </div>

        <div className="coin-collect-search-bar-grid">
          <div className="coin-collect-search-bar-grid-search">
            <Input
              placeholder="search .."
              value={searchWord}
              onChange={e => setSearchWord(e.target.value)}
              onPressEnter={() => {
                setCurrentPage(1)
                setSearchButton(!searchButton)
              }}
            />
          </div>
          <div className="f-c-c d-b-t-n-s d-bg-c-t"
            onClick={() => {
              setCurrentPage(1)
              setSearchButton(!searchButton)
            }}>
            <i className="bi bi-search" style={{ lineHeight: '0' }}></i>
          </div>
          <Link to="/coin/history/add-site">
            <div style={{ backgroundColor: "#45D88E" }} className="f-c-c d-b-t-n-s h-32">ใส่กล่อง</div>
          </Link>
          <Link to="/coin/history/check-out" className="coin-collect-search-bar-grid-check">
            <div style={{ backgroundColor: "#ED2228" }} className="f-c-c d-b-t-n-s h-32">ถอดกล่อง</div>
          </Link>
          <Link to="/coin/reconcile/count" className="coin-collect-search-bar-grid-count">
            <div className="f-c-c d-b-t-n-s d-bg-c-t h-32" style={{ height: '100%' }}>นับเหรียญ</div>
          </Link>
          {
            menuList.includes('EXPORT') ? <div className="f-c-c d-b-t-n-s d-bg-c-t h-32 coin-collect-search-bar-grid-export"
            style={{ pointerEvents: waitingForCSV ? 'none' : null }}
            onClick={() => toCSVFile()}
          >
            {waitingForCSV ? <LoadingOutlined /> : 'Export CSV'}
          </div> : null
          }
          
        </div>
      </div>

      <Table bordered
        size="small"
        rowKey={record => record.coin_collect_id}
        loading={isLoading}
        dataSource={tableData && tableData.data_list || []}
        columns={columns}
        pagination={false}
        scroll={{ x: true }}
      />

      <div className="pgnt-st">
        <Pagination
          size="small"
          current={currentPage}
          onChange={page => setCurrentPage(page)}
          total={tableData && tableData.max_total_rows}
          pageSize={perPage}
          showSizeChanger={false}
        />
      </div>

      <Modal
        title=""
        visible={visibleDelete}
        closable={false}
        footer={false}
        centered
      >
        <div className="m-t-i-c" style={{ color: '#FFCB05' }}>
          <i className="bi bi-exclamation-triangle-fill"></i>
        </div>
        <div className="m-t-t-q">Do you want to delete '{modalTitleDelete}' ?</div>
        <div className="m-b-y-n-g">
          <div className="f-c-c d-b-t-n-s b-c-o-i" style={{ backgroundColor: '#cccccc' }} onClick={() => setVisibleDelete(false)}>Cancel</div>
          <div className="f-c-c d-b-t-n-s b-c-o-i"
            style={{ backgroundColor: '#3f4d67', pointerEvents: isLoadingConfirmDelete ? 'none' : null }}
            onClick={() => deleteData()}
          >{isLoadingConfirmDelete ? <LoadingOutlined /> : 'Confirm'}</div>
        </div>
      </Modal>

      <Modal
        title=""
        visible={visibleCheck}
        closable={false}
        footer={false}
        centered
      >
        <div className="m-t-i-c" style={{ color: '#FFCB05' }}>
          <i className="bi bi-exclamation-triangle-fill"></i>
        </div>
        <div className="m-t-t-q">Do you want to check '{modalTitleCheck}' ?</div>
        <div className="m-b-y-n-g">
          <div className="f-c-c d-b-t-n-s b-c-o-i" style={{ backgroundColor: '#cccccc' }} onClick={() => setVisibleCheck(false)}>Cancel</div>
          <div className="f-c-c d-b-t-n-s b-c-o-i"
            style={{ backgroundColor: '#3f4d67', pointerEvents: isLoadingConfirmCheck ? 'none' : null }}
            onClick={() => checkData()}
          >{isLoadingConfirmCheck ? <LoadingOutlined /> : 'Confirm'}</div>
        </div>
      </Modal>

    </div>
  )
}

export default CoinReconcile
