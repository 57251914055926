import React, { useState, useEffect } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Input, Table, Pagination, Tooltip, Select, Modal } from 'antd';
import './Maintenance.css'
import XLSX from "xlsx";
import { LoadingOutlined } from '@ant-design/icons';

import moment from 'moment';
import 'moment/locale/th'

import { colors } from '../../config/config'
import { numberWithCommas, getSearchParams, diffTime, openNotification } from '../../utils/function'
import { apiSGetSite } from '../../services/apis/site'
import { apiMCHGetTypeBySiteId } from '../../services/apis/machine'
import { apiMTNGetType, apiMTNGetTicket, apiMTNDeleteTicketById } from '../../services/apis/maintenance'

const { Option } = Select;

const Ticket = () => {
  const history = useHistory();
  const location = useLocation();

  const params = new URLSearchParams(location.search)
  const p = params.get('p')
  const ttl = params.get('ttl')
  const mtn = params.get('mtn')
  const mch = params.get('mch')
  const s = params.get('s')
  const sw = params.get('sw')

  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(Number(p) || 1)
  const [perPage, setPerPage] = useState(Number(ttl) || 100)
  const [tableData, setTableData] = useState(undefined)
  const [searchWord, setSearchWord] = useState(sw || '')
  const [searchButton, setSearchButton] = useState(false)
  const [waitingForCSV, setWaitingForCSV] = useState(false)

  const [maintenances, setMaintenances] = useState([])
  const [maintenance, setMaintenance] = useState(Number(mtn) || '')
  const [machines, setMachines] = useState([])
  const [machine, setMachine] = useState(Number(mch) || '')
  const [sites, setSites] = useState([])
  const [site, setSite] = useState(Number(s) || '')

  const [visible, setVisible] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalId, setModalId] = useState(undefined)
  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false)

  useEffect(() => {
    const getStaticDropdown = async () => {
      let type = await apiMTNGetType()
      if (type && type.status) setMaintenances(type.result || [])

      let machine = await apiMCHGetTypeBySiteId()
      if (machine && machine.status) setMachines(machine.result || [])

      let site = await apiSGetSite()
      if (site && site.status) setSites(site.result || [])
    }
    getStaticDropdown()
  }, [])

  useEffect(() => {
    console.log('User ID : ', localStorage.getItem('user_id'));
    getTableData()
    history.push(`${location.pathname}${getSearchParams(['p', 'ttl', 'mtn', 'mch', 's', 'sw'], [currentPage, perPage, maintenance, machine, site, searchWord])}`)
  }, [currentPage, perPage, searchButton])

  const getTableData = async () => {
    setIsLoading(true)
    let table = await apiMTNGetTicket(false, currentPage, perPage, searchWord, maintenance, machine, site)
    if (table && table.status) {
      setIsLoading(false)
      setTableData(table.result)
      console.log(table.result);
    }
  }

  const columns = [
    {
      title: '#',
      ellipsis: true,
      align: 'center',
      width: '5%',
      render: (data) => <div>{data.maintenance_id || '-'}</div>
    },
    {
      title: 'Flag',
      ellipsis: true,
      align: 'center',
      width: '80px',
      render: (data) => <div className="f-c-c d-b-t-n-s b-c-o-i"
        style={{
          backgroundColor: data.maintenance_type_name === 'บำรุงรักษา'
            ? colors.general.green
            : getFlagColor(moment().diff(moment(data.start_date), 'hours'))
        }}
      />
    },
    {
      title: 'สถานะ',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{data.status || '-'}</div>
    },
    {
      title: 'วันที่แจ้ง',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{data.start_date ? moment(data.start_date).add(543, 'years').format('D MMM YYYY HH:mm:ss') : '-'}</div>
    },
    {
      title: 'ประเภท',
      ellipsis: true,
      render: (data) => <div>{data.maintenance_type_name || '-'}</div>
    },
    {
      title: 'สถานที่',
      ellipsis: true,
      render: (data) => <div>{data.site_name || '-'}</div>
    },
    {
      title: 'ชื่อเครื่อง',
      ellipsis: true,
      render: (data) => <div>
        {data.machine_name
          ? data.machine_name.split(',').map((list, idx) => <div key={idx}>{list}</div>)
          : '-'}
      </div>
    },
    {
      title: 'ประเภทเครื่อง',
      ellipsis: true,
      render: (data) => <div>{data.machine_type || '-'}</div>
    },
    {
      title: 'ประเภทเครื่องย่อย',
      ellipsis: true,
      render: (data) => <div>{data.machine_sub_type || '-'}</div>
    },
    {
      title: 'จำนวนครั้ง',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{numberWithCommas(data.used_count || 0, 0)}</div>
    },
    {
      title: 'อาการ',
      // ellipsis: true,
      width: '500px',
      render: (data) => <div>{data.detail || '-'}</div>
    },
    {
      title: 'แก้ไขโดย',
      ellipsis: true,
      render: (data) => <div>
        {data.assignee
          ? data.assignee.split(',').map((list, idx) => <div key={idx}>{list}</div>)
          : '-'}
      </div>
    },
    {
      title: 'ระยะเวลา',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{data.start_date ? diffTime(data.start_date, moment()) : '-'}</div>
    },
    {
      title: 'ตั้งค่า',
      ellipsis: true,
      align: 'center',
      fixed: 'right',
      render: (data) => 
        <div className="f-c-c">
          <div className={"f-c-c tb-cl-a"}> {/* "tb-cl-a-g tb-cl-a-g-rp-2" */}
          <Tooltip placement="top" title="Update">
            <div className="f-c-c d-b-t-n-s b-c-o-i" style={{ backgroundColor: '#00ADEF' }}
              onClick={e => {
                e.stopPropagation()
                history.push(`/maintenance/ticket/update/${data.maintenance_id}${getSearchParams(['p', 'ttl', 'mtn', 'mch', 's', 'sw'], [currentPage, perPage, maintenance, machine, site, searchWord])}`)
              }}
            ><i className="fas fa-user-edit"></i></div>
          </Tooltip>
          <Tooltip placement="top" title="Delete">
            <div className="f-c-c d-b-t-n-s b-c-o-i" style={{ backgroundColor: '#ED2228' }}
                onClick={(e) => {
                  e.stopPropagation()
                  setModalId(data.maintenance_id)
                  setModalTitle(data.maintenance_id)
                  setVisible(true)
                }}
            ><i className="fas fa-trash"></i></div>
          </Tooltip>
          </div>
        </div>,
    },
  ];

  const deleteData = async () => {
    if (!modalId) {
      openNotification('error', 'Failed !', 'Failed to delete this ticket.')
      return
    }

    setIsLoadingConfirm(true)

    let user_id = localStorage.getItem('user_id')

    // let data = new FormData()
    // data.set('admin_id', user_id);
    // data.set('maintenance_id', modalId);

    let action = await apiMTNDeleteTicketById(user_id, modalId)
    if (action && action.status) {
      openNotification('success', 'Success !', 'Delete this user successful.')
      setVisible(false)
      setIsLoadingConfirm(false)
      getTableData()
    } else {
      openNotification('error', 'Failed !', 'Failed to delete this user.')
      setIsLoadingConfirm(false)
    }
  }

  const getFlagColor = hours => {
    if (hours > 48) return colors.general.red
    else if (hours > 24) return colors.general.orange
    else return colors.general.green
  }

  const getFlagWord = hours => {
    if (hours > 48) return 'Red'
    else if (hours > 24) return 'Orange'
    else return 'Green'
  }

  const toCSVFile = async () => {
    setWaitingForCSV(true)
    let data = await apiMTNGetTicket(true, '', '', searchWord, '', '', '')
    let csvData = [];

    if (data && data.status) {
      (data.result.data_list || []).map((data, idx) => {
        let resultRow = {}
        resultRow['#'] = idx + 1
        resultRow['Flag'] = data.maintenance_type_name === 'บำรุงรักษา'
          ? 'Green'
          : getFlagWord(moment().diff(moment(data.start_date), 'hours'))
        resultRow['วันที่แจ้ง'] = data.start_date || '-'
        resultRow['ประเภท'] = data.maintenance_type_name || '-'
        resultRow['สถานที่'] = data.site_name || '-'
        resultRow['ชื่อเครื่อง'] = data.machine_name || '-'
        resultRow['ประเภทเครื่อง'] = data.machine_type || '-'
        resultRow['ประเภทเครื่องย่อย'] = data.machine_sub_type || '-'
        resultRow['จำนวนครั้ง'] = data.used_count || 0
        resultRow['อาการ'] = data.detail || '-'
        resultRow['สถานะ'] = data.status || '-'
        resultRow['แก้ไขโดย'] = data.assignee || '-'
        resultRow['ระยะเวลา'] = data.start_date ? diffTime(data.start_date, moment()) : '-'
        csvData.push(resultRow);
      })

      const toCSV = XLSX.utils.json_to_sheet(csvData);
      const wb = XLSX.utils.book_new();

      const fileName = 'MAINTENANCE_TICKET'
      XLSX.utils.book_append_sheet(wb, toCSV, fileName);
      XLSX.writeFile(wb, fileName + ".csv");

      setWaitingForCSV(false)
    }
  }

  return (
    <div>
      <div className="mg-bt-20 coin-collect-title">
        <div className="h-d-t-p">Maintenance - Ticket</div>
        <div className="coin-collect-card-list">
          {(tableData && tableData.sumary || []).map((list, idx) => <div key={idx} className="c-c-t-s">
            <div className="coin-collect-card">
              <div className="coin-collect-card-grid">
                <div>{list.name}</div>
                <div>{numberWithCommas(list.value || 0, 0)}</div>
              </div>
            </div>
          </div>)}
        </div>
      </div>

      <div className="maintenance-header-grid maintenance-header-grid-ticket">
        <div className="ovf-hd maintenance-header-grid-ticket-select">
          <Select style={{ width: '100%' }}
            value={maintenance}
            onChange={value => setMaintenance(value)}
          >
            <Option value="">All Ticket Type</Option>
            {maintenances.map((maintenance, idx) => <Option key={idx} value={maintenance.maintenance_type_id}>{maintenance.maintenance_type_name}</Option>)}
          </Select>
        </div>
        <div className="ovf-hd maintenance-header-grid-ticket-machine">
          <Select style={{ width: '100%' }}
            value={machine}
            onChange={value => setMachine(value)}
          >
            <Option value="">All Machine Type</Option>
            {machines.map((machine, idx) => <Option key={idx} value={machine.machine_type_id}>{machine.machine_type_name}</Option>)}
          </Select>
        </div>
        <div className="ovf-hd maintenance-header-grid-ticket-select">
          <Select style={{ width: '100%' }}
            showSearch
            value={site}
            onChange={value => setSite(value)}
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            <Option value="">All Site</Option>
            {sites.map((site, idx) => <Option key={idx} value={site.site_id}>{site.site_name}</Option>)}
          </Select>
        </div>
        <div className="maintenance-header-grid-ticket-search">
          <Input
            placeholder="search .."
            value={searchWord}
            onChange={e => setSearchWord(e.target.value)}
            onPressEnter={() => {
              setCurrentPage(1)
              setSearchButton(!searchButton)
            }}
          />
        </div>
        <div className="f-c-c d-b-t-n-s d-bg-c-t"
          onClick={() => {
            setCurrentPage(1)
            setSearchButton(!searchButton)
          }}>
          <i className="bi bi-search" style={{ lineHeight: '0' }}></i>
        </div>
        <Link to={`/maintenance/create-ticket${getSearchParams(['p', 'ttl', 'mtn', 'mch', 's', 'sw'], [currentPage, perPage, maintenance, machine, site, searchWord])}`}>
          <div className="f-c-c d-b-t-n-s d-bg-c-t h-32" style={{ height: '100%' }}>แจ้งซ่อม</div>
        </Link>
        <div className="f-c-c d-b-t-n-s d-bg-c-t h-32 maintenance-header-grid-ticket-export"
          style={{ pointerEvents: waitingForCSV ? 'none' : null }}
          onClick={() => toCSVFile()}
        >
          {waitingForCSV ? <LoadingOutlined /> : 'Export CSV'}
        </div>
      </div>

      <Table bordered
        size="small"
        rowKey={record => record.maintenance_id}
        loading={isLoading}
        dataSource={tableData && tableData.data_list || []}
        columns={columns}
        pagination={false}
        scroll={{ x: true }}
      />

      {/* <div className="pgnt-st">
        <Pagination
          size="small"
          current={currentPage}
          onChange={page => setCurrentPage(page)}
          total={tableData && tableData.max_total_rows}
          pageSize={perPage}
          showSizeChanger={false}
        />
      </div> */}

      <Modal
        title=""
        visible={visible}
        closable={false}
        footer={false}
        centered
      >
        <div className="m-t-i-c" style={{ color: '#FFCB05' }}>
          <i className="bi bi-exclamation-triangle-fill"></i>
        </div>
        <div className="m-t-t-q">Do you want to delete '{modalTitle}' ?</div>
        <div className="m-b-y-n-g">
          <div className="f-c-c d-b-t-n-s b-c-o-i" style={{ backgroundColor: '#cccccc' }} onClick={() => setVisible(false)}>Cancel</div>
          <div className="f-c-c d-b-t-n-s b-c-o-i"
            style={{ backgroundColor: '#3f4d67', pointerEvents: isLoadingConfirm ? 'none' : null }}
            onClick={() => deleteData()}
          >{isLoadingConfirm ? <LoadingOutlined /> : 'Confirm'}</div>
        </div>
      </Modal>

    </div>
  )
}

export default Ticket