
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, role, ...rest }) => {
  return (
    <Route {...rest} render={props => (
      role.includes((localStorage.getItem('user_role') || '').toUpperCase()) ?
        <Component {...props} />
        : <Redirect to="/" />
    )} />
  );
};

export default PrivateRoute;