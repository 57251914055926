import React, { useState, useEffect } from 'react'
import { Progress } from 'antd';

const ProgressPercent = props => {
  const [value, setValue] = useState(0)

  useEffect(() => {
    setValue(props.value)
  }, [props])

  return (
    <Progress
      style={{ width: '90%' }}
      status="active"
      strokeColor={{
        '0%': value < 0 ? '#EC1C24' : '#1B1E64',
        '100%': value < 0 ? '#FFCB05' : '#00ADEF',
      }}
      percent={Math.abs(value)}
      format={() => `${value.toFixed(2)}%`}
    />
  )
}

export default ProgressPercent
